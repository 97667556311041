import { useRef, useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import useApiRequest from 'hooks/useApiRequest';
import organizationStore from 'stores/Organization';
import type Misc from 'types/misc';

type Props = {
  filtersList: Misc.FilterDeclarationItem[],
  name: string,
  value: Misc.FilterValue,
};

const FilterValue = (props: Props): JSX.Element => {
  const { filtersList, name, value } = props;
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const defaultValue = Array.isArray(value) ? value.join(', ') : (value || '');
  const [displayValue, setDisplayValue] = useState<string>(defaultValue);
  const clientValueRequested = useRef<string | null>(null);
  const optionsValueRequested = useRef<string | null>(null);
  const { get, cancel, isLoading } = useApiRequest();

  const getSelectOption = useCallback((filterDeclared: Misc.FilterDeclarationItem) => {
    if (!filterDeclared || !filterDeclared.selectData) {
      return;
    }
    const label = filterDeclared.selectData.find(
      ({ value: selectValue }) => value?.toString() === selectValue,
    );
    if (!label) {
      setDisplayValue(defaultValue);
      return;
    }
    setDisplayValue(label?.label);
  }, [setDisplayValue, defaultValue, value]);

  const getAsyncOptions = useCallback(async (filter: Misc.FilterDeclarationItem) => {
    if (!currentOrganization || Array.isArray(value) || !value) {
      return;
    }
    if (optionsValueRequested.current === value) {
      return;
    }

    const results = await get<Misc.IdRefName[]>(
      `organization/${currentOrganization.reference}/${filter.fetchEntity}?search=${value}`,
    );
    if (!results) {
      return;
    }

    setDisplayValue(results.find(({ id, reference }) => (
      (id.toString() === value.toString()) || (reference === value.toString())
    ))?.name ?? '--');

    optionsValueRequested.current = value;
  }, [get, currentOrganization, value]);

  const getClientOption = useCallback(async () => {
    if (clientValueRequested.current === defaultValue) {
      return;
    }
    cancel();

    if (!currentOrganization || !defaultValue || isLoading) {
      return;
    }
    clientValueRequested.current = defaultValue;
    const url = `client/${currentOrganization.reference}/id/${defaultValue}`;
    const result = await get<Misc.IdRefName>(url);

    setDisplayValue(result?.name || defaultValue || '--');
  }, [cancel, currentOrganization, defaultValue, get, isLoading]);

  const getFilterValue = useCallback(() => {
    const filterDeclared = filtersList.find(({ key }) => key === name);
    if (!filterDeclared) {
      setDisplayValue(defaultValue);
      return;
    }
    switch (filterDeclared.type) {
      case 'selectAsync':
      case 'customAsync':
        getAsyncOptions(filterDeclared);
        break;
      case 'selectClient':
        getClientOption();
        break;
      case 'select':
        getSelectOption(filterDeclared);
        break;
      case 'onOff':
        setDisplayValue(value?.toString() ?? '');
        break;
      case 'yesNo':
        setDisplayValue(value === '1' ? t<string>('common:yes') : t<string>('common:no'));
        break;
      default:
        setDisplayValue(Array.isArray(value) ? value.join(', ') : value as string);
        break;
    }
  }, [
    filtersList,
    t,
    getAsyncOptions,
    getClientOption,
    name,
    value,
    getSelectOption,
    defaultValue,
  ]);

  useEffect(() => {
    getFilterValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, isLoading]);

  return <>{displayValue}</>;
};

export default observer(FilterValue);
