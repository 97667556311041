import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import Button from 'components/Button';
import useApiRequest from 'hooks/useApiRequest';
import Loading from 'components/Loading';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';

interface Props {
  selection?: (number | string)[],
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
}

const CustomersBulkActions: React.FC<Props> = (props) => {
  const { linesOfBusiness } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);

  const { selection, onActionDone, onActionError } = props;

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const { remove, error, isLoading } = useApiRequest();

  const handleConfirmDelete = React.useCallback(() => {
    showConfirm(
      t('common:remove'),
      ct('clients:actions.confirm.bulk-remove', { count: selection?.length }),
    );
  }, [showConfirm, t, ct, selection]);

  const handleSubmitDelete = React.useCallback(async () => {
    if (!selection) {
      return;
    }

    const response = await remove(`client/bulk-manage?selection=${selection.join(',')}`);

    hideConfirm();
    if (response) {
      onActionDone(
        t('clients:actions.toast.bulk-deleted', { count: selection?.length }),
      );
    }
  }, [selection, remove, hideConfirm, onActionDone, t]);

  React.useEffect(() => {
    if (error) {
      hideConfirm();
      onActionError(error.message || t('errors:unknown-retry'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  if (!selection) {
    return null;
  }

  return (
    <div className="CustomersBulkActions">
      {isLoading && <Loading text={t('common:saving')} isOverlay />}
      <Button
        variant="outline-light"
        onClick={handleConfirmDelete}
        className="CustomersBulkActions__delete"
        disabled={isLoading}
      >
        {t('common:remove')}
      </Button>
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitDelete}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={t('common:remove')}
      />
    </div>
  );
};

export default observer(CustomersBulkActions);
