import React from 'react';
import { observer } from 'mobx-react';
import type Misc from 'types/misc';
import type Errors from 'types/errors';
import type { Currency } from 'types/models';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import Button from 'components/Button';
import Icon from 'components/Icon';
import RowActionCreditNoteForm from './Form';

interface Props {
  id: number,
  reference: string,
  currency: Currency | undefined,
  onLoadingChange: (isLoading: boolean) => void,
  onDone: (title: string, message: string) => void,
}

const RowActionCreditNote: React.FC<Props> = (props) => {
  const { linesOfBusiness } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);

  const isMountedRef = useIsMountedRef();
  const {
    id,
    reference,
    currency,
    onLoadingChange,
    onDone,
  } = props;

  const [isOpened, setIsOpened] = React.useState<boolean>(false);
  const [validationErrors, setValidationErrors] = React.useState<Errors.Validation | null>(null);

  const { post, error, isLoading } = useApiRequest();

  const handleInputAction = React.useCallback(
    () => { setIsOpened(true); },
    [],
  );

  const handleSubmit = React.useCallback(
    async (data: ModalFormData | null) => {
      if (!data) {
        return;
      }

      const result = await post<Misc.IdRef>(`credit-note/${id}`, data);

      if (!isMountedRef.current) {
        return;
      }

      if (result?.errors) {
        setValidationErrors(result.errors);
        return;
      }

      if (result?.id) {
        setIsOpened(false);
        onDone(
          t('payment-requests:credit-note-added'),
          ct('payment-requests:actions.toast.credit-note-added', { reference }),
        );
      }
    },
    [post, id, onDone, t, ct, reference, isMountedRef],
  );

  const handleCancel = React.useCallback(
    () => { setIsOpened(false); },
    [],
  );

  React.useEffect(
    () => { onLoadingChange(isLoading); },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading],
  );

  return (
    <>
      <Button
        variant="list"
        onClick={handleInputAction}
        className="RowActionCreditNote"
      >
        <Icon name="bookmark" />
        {t('payment-requests:add-credit-note')}
      </Button>
      <ModalForm
        isOpened={isOpened}
        onSave={handleSubmit}
        onCancel={handleCancel}
        title={t('payment-requests:add-credit-note')}
        isLoading={isLoading}
      >
        <RowActionCreditNoteForm
          currency={currency}
          validationErrors={validationErrors}
          error={error}
        />
      </ModalForm>
    </>
  );
};

export default observer(RowActionCreditNote);
