import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import getRelativeDaysCount from 'utils/getRelativeDaysCount';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import formatIntlDate from 'utils/formatIntlDate';
import Dropdown from 'components/Dropdown';
import Icon from 'components/Icon';

type Props = {
  payRequestId?: number,
  isGroup?: boolean,
  data: {
    nextSending: string | null,
    promiseToPayAt?: string | null,
    lastSending: string | null,
  },
};

const ListHistorySummary = ({ payRequestId, data, isGroup = false }: Props): JSX.Element | null => {
  const { linesOfBusiness } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);
  const { nextSending, promiseToPayAt, lastSending } = data;

  const daysCount = React.useMemo(
    () => getRelativeDaysCount(nextSending),
    [nextSending],
  );

  const nextNumber = React.useMemo(
    () => (lastSending ? 2 : 1),
    [lastSending],
  );

  const dateNext = React.useMemo(
    () => formatIntlDate(nextSending, 'dateLong'),
    [nextSending],
  );

  const datePromise = React.useMemo(
    () => formatIntlDate(promiseToPayAt, 'dateLong'),
    [promiseToPayAt],
  );

  const dateLast = React.useMemo(
    () => formatIntlDate(lastSending, 'dateLong'),
    [lastSending],
  );

  if (!nextSending && !promiseToPayAt && !lastSending) {
    return null;
  }

  return (
    <div className="ListHistorySummary">
      <Dropdown
        variant="light"
        noIcon
        toggle={(
          <>
            {nextSending && (
              <div className="ListHistorySummary__next-send-pr">
                {daysCount <= 10 ? t('common:in-days', { count: daysCount }) : dateNext}
              </div>
            )}
            {(promiseToPayAt || lastSending) && (
              <div className="ListHistorySummary__other">
                <Icon name="dots-small" />
              </div>
            )}
          </>
        )}
      >
        <div className="ListHistorySummary__content">
          {nextSending && (
            <div className="ListHistorySummary__content__next-send-pr">
              <h5 className="ListHistorySummary__content__title">
                {t('payment-requests:upcoming')}
                {isGroup && ` (${t('plans:grouped-reminder')})`}
              </h5>
              <span className="ListHistorySummary__content__item-index">
                {nextNumber}
              </span>
              <span className="ListHistorySummary__content__important-value">
                {dateNext}
              </span>
            </div>
          )}
          {!nextSending && (
            <div className="ListHistorySummary__content__no-next-send-pr">
              {payRequestId
                ? ct('payment-requests:no-upcoming-action-for-this-bill')
                : ct('payment-requests:no-upcoming-action-for-this-customer')}
            </div>
          )}
          {promiseToPayAt && (
            <div className="ListHistorySummary__content__promise">
              <h5 className="ListHistorySummary__content__title">
                {t('payment-requests:promise')}
              </h5>
              <span className="ListHistorySummary__content__important-value">
                {datePromise}
              </span>
            </div>
          )}
          {lastSending && (
            <div className="ListHistorySummary__content__past">
              <h5 className="ListHistorySummary__content__title">
                {t('payment-requests:past')}
              </h5>
              <span className="ListHistorySummary__content__item-index">
                1
              </span>
              {dateLast}
            </div>
          )}
        </div>
      </Dropdown>
    </div>
  );
};

export default observer(ListHistorySummary);
