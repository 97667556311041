import './index.scss';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Category, CategoryValue } from 'types/models';
import Button from 'components/Button';
import FormFieldset from 'components/FormFieldset';
import FormSelect from 'components/FormSelect';
import FormGroup from 'components/FormGroup';
import Icon from 'components/Icon';

type Props = {
  onRemoveCondition(categoryID: number | null): void,
  onAddCondition?(categoryID: number): void,
  onChangeConditionValues?(categoryId: number, valuesId: string[]): void,
  availableCategories: Category[],
  selectedCategoryId?: number | null,
  selectedValuesIds?: Array<CategoryValue['id']>,
};

const FormPerimeterItem = (props: Props) => {
  const {
    availableCategories,
    selectedCategoryId,
    selectedValuesIds,
    onRemoveCondition,
    onChangeConditionValues,
    onAddCondition,
  } = props;
  const { t } = useTranslation();

  const availableValues = useMemo(() => {
    return availableCategories
      ?.find(({ id }) => id === selectedCategoryId)
      ?.values.map(({ id, value }) => ({ value: id, label: value }))
      ?? [];
  }, [availableCategories, selectedCategoryId]);

  const availableCategoryInput = useMemo(() => (
    availableCategories?.map(({ id, name }) => ({ value: id, label: name })) ?? []
  ), [availableCategories]);

  const handleChangeCategory = useCallback((name: string, newValue: string | null) => {
    if (!newValue) {
      return;
    }

    if (onAddCondition) {
      onAddCondition(Number.parseInt(newValue, 10));
    }
  }, [onAddCondition]);

  const handleChangeValues = useCallback((name: string, newValue: string | null) => {
    if (!onChangeConditionValues || !selectedCategoryId) {
      return;
    }

    onChangeConditionValues(selectedCategoryId, newValue?.split(',') ?? []);
  }, [onChangeConditionValues, selectedCategoryId]);

  return (
    <div className="FormPerimeterItem">
      <Button
        className="FormPerimeterItem__remove"
        variant="link"
        onClick={() => { onRemoveCondition(selectedCategoryId ?? null); }}
      >
        <Icon name="minus-circle" />
      </Button>
      <FormFieldset>
        <FormGroup label={t('perimeters:category')}>
          <FormSelect
            disabled={!!selectedCategoryId}
            selectOptions={availableCategoryInput}
            value={selectedCategoryId?.toString() ?? null}
            name={`perimeter-name[${selectedCategoryId}]`}
            placeholder={t('perimeters:select-category')}
            onSelect={handleChangeCategory}
            withClearButton={false}
          />
        </FormGroup>
        <FormGroup label={t('perimeters:values')}>
          <FormSelect
            withClearButton
            selectOptions={availableValues}
            value={selectedValuesIds?.map((id) => `${id}`)}
            name={`perimeter-values[${selectedCategoryId}]`}
            placeholder={t('perimeters:select-values')}
            isMultiple
            onSelect={handleChangeValues}
          />
        </FormGroup>
      </FormFieldset>
    </div>
  );
};

export default FormPerimeterItem;
