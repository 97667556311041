import './index.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { observer } from 'mobx-react';
import { fetchAll } from 'api/plans';
import type { FetchAllParams } from 'api/plans';
import { fetchAll as fetchAllUsers } from 'api/users';
import type { FetchAllParams as FetchAllUsersParams } from 'api/users';
import type Misc from 'types/misc';
import type { Selectors } from 'types/models';
import organizationStore from 'stores/Organization';
import useFetch from 'hooks/useFetch';
import DataTable from 'components/DataTable';
import SelectorColumns from '../Columns';

type Props = {
  selectors: Selectors[],
  isGroup?: boolean,
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const SelectorListing = (props: Props): JSX.Element => {
  const { onActionDone, onActionError, selectors, isGroup = false } = props;
  const { t } = useTranslation();
  const { currentOrganization, eavs } = organizationStore;

  const { data: plans } = useFetch<FetchAllParams, Misc.IdentifierName[]>(
    {
      cacheKey: 'organizationPlans',
      organization: currentOrganization?.reference,
    },
    fetchAll,
    { enabled: !!currentOrganization },
  );

  const { data: managersList } = useFetch<FetchAllUsersParams, Misc.IdRefName[]>(
    { cacheKey: 'managers', organization: currentOrganization?.reference },
    fetchAllUsers,
    {
      enabled: !!currentOrganization?.reference,
      retry: 1,
      refetchOnWindowFocus: false,
    },
  );

  const columns = useMemo<Column<Selectors>[]>(
    () => SelectorColumns(t, onActionError, onActionDone, isGroup, plans, eavs, managersList),
    [t, onActionError, onActionDone, isGroup, plans, eavs, managersList],
  );

  return (
    <div className="SelectorsListing">
      <p className="SelectorsListing__description">
        {isGroup ? t('selectors:description-group') : t('selectors:description')}
      </p>
      {selectors.length === 0 && (
        <p className="SelectorsListing__no-data">{t('selectors:no-data')}</p>
      )}
      {selectors.length > 0 && (
        <DataTable<Selectors>
          data={selectors}
          serverPagination={null}
          columns={columns}
          isLoading={false}
          defaultSortBy={{ id: 'identifier', desc: false }}
          error={null}
          fetchData={() => { }}
          noHeader
          withActions={false}
        />
      )}
    </div>
  );
};

export default observer(SelectorListing);
