import { autorun, runInAction, makeAutoObservable } from 'mobx';
import queryString from 'query-string';
import type Misc from 'types/misc';

class FilterStore {
  filters: Misc.Filter[] = [];

  firstRun: boolean = false;

  storageName: string;

  shouldSaveToStorage: boolean;

  constructor(storageName: string, shouldSaveToStorage: boolean) {
    this.storageName = storageName;
    this.shouldSaveToStorage = shouldSaveToStorage;
    makeAutoObservable(this);
    this.load();
    this.autoSave();
  }

  load = () => {
    const urlFilters = this.getUrlFilters();
    if (urlFilters.length) {
      runInAction(() => {
        this.filters = urlFilters;
      });
      return;
    }

    const storedFilters = localStorage.getItem(this.storageName);
    if (storedFilters) {
      runInAction(() => {
        this.filters = JSON.parse(storedFilters);
      });
    }
  };

  autoSave = () => {
    autorun(() => {
      if (!this.shouldSaveToStorage) {
        return;
      }
      const json = JSON.stringify(this.filters);
      if (!this.firstRun) {
        this.save(json);
      }
      runInAction(() => {
        this.firstRun = false;
      });
    });
  };

  save = (json: string) => {
    localStorage.setItem(this.storageName, json);
  };

  updateUrl = (filters: Misc.Filter[]) => {
    const filterObject: Record<string, Misc.FilterValue> = {};
    filters.forEach(({ name, value }) => {
      if (value === null || value.toString().length === 0) {
        return;
      }
      filterObject[name] = value.toString();
    });

    const query = queryString.stringify(filterObject);
    window.history.replaceState(null, '', `?${query}`);
  };

  getUrlFilters = (): Misc.Filter[] => {
    const urlParams = queryString.parse(window.location.search, { arrayFormat: 'comma' });
    const urlFilters: Misc.Filter[] = Object.keys(urlParams).map((name) => {
      const parsedValue = urlParams[name] || '';
      const value = Array.isArray(parsedValue)
        ? parsedValue.filter((subValue) => subValue !== null) as string[]
        : parsedValue.split(',');
      return { name, value };
    });

    return urlFilters;
  };

  addOrUpdateFilters = (newFilters: Misc.Filter[]) => {
    const filters = [...this.filters];
    newFilters.forEach(({ name, value }) => {
      if (value === null || value.length === 0) {
        const foundFilterIndex = filters.findIndex(
          ({ name: updatedName }) => name === updatedName,
        );

        if (foundFilterIndex > -1) {
          filters.splice(foundFilterIndex, 1);
        }

        return;
      }

      const foundFilterIndex = filters.findIndex(
        ({ name: tempName }) => name === tempName,
      );

      if (foundFilterIndex !== -1) {
        filters[foundFilterIndex] = { name, value };
      } else {
        filters.push({ name, value });
      }
    });

    runInAction(() => {
      this.filters = filters;
      this.updateUrl(filters);
    });
  };

  removeFilter = (nameToRemove: string) => {
    const filters = [...this.filters];
    const foundFilterIndex = filters.findIndex(
      ({ name }) => name === nameToRemove,
    );
    if (foundFilterIndex > -1) {
      filters.splice(foundFilterIndex, 1);
    }

    runInAction(() => {
      this.filters = filters;
      this.updateUrl(filters);
    });
  };

  resetAllFilters = (givenFilters?: Record<string, string | string[]>) => {
    const prepareFilters = () => {
      const defaultFilters: Misc.Filter[] = [];
      if (givenFilters) {
        Object.keys(givenFilters ?? {}).forEach((name) => {
          defaultFilters.push({ name, value: givenFilters[name] });
        });
      }

      const urlFilters = this.getUrlFilters();
      if (!urlFilters.length) {
        return defaultFilters;
      }

      const defaultWithoutUrl = defaultFilters.filter(({ name }) => (
        !urlFilters.find(({ name: urlName }) => name === urlName)
      ));
      return [...urlFilters, ...defaultWithoutUrl];
    };

    const filters: Misc.Filter[] = prepareFilters();

    runInAction(() => {
      this.filters = filters;
      this.updateUrl(filters);
    });
  };
}

export default FilterStore;
