import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import Button from 'components/Button';
import Loading from 'components/Loading';

type Props = {
  onSubmit: (email: string) => void,
  isLoading?: boolean,
  hasError?: boolean,
};

const ForgottenPasswordForm: React.FC<Props> = (props) => {
  const {
    onSubmit,
    isLoading = false,
    hasError = false,
  } = props;

  const { t } = useTranslation();
  const [email, setEmail] = React.useState('');

  const handleEmailChange = React.useCallback((value: string) => {
    setEmail(value);
  }, []);

  const handleSubmit = React.useCallback((e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    onSubmit(email);
  }, [email, isLoading, onSubmit]);

  return (
    <form className="ForgottenPasswordForm" onSubmit={handleSubmit}>
      <FormGroup controlId="ForgottenPasswordEmail" label={t('common:email')}>
        <FormControl
          type="email"
          id="loginEmail"
          placeholder={t('login:enter-email')}
          value={email}
          onChange={handleEmailChange}
          isInvalid={hasError}
        />
      </FormGroup>
      {hasError && (
        <h4 className="ForgottenPasswordForm__error">{t('errors:unknown-retry')}</h4>
      )}
      <Button
        variant="primary"
        type="submit"
        className="ForgottenPasswordForm__submit"
      >
        {isLoading && <Loading />}
        {!isLoading && t('forgotten-password:reset-password')}
      </Button>
    </form>
  );
};

export default ForgottenPasswordForm;
