import { Fragment } from 'react';
import type { TFunction } from 'i18next';
import type { Column } from 'react-table';
import type { ImportsHistory } from 'types/models';
import ImportStatus from 'components/ImportStatus';
import formatIntlDate from 'utils/formatIntlDate';
import getI18nTypeImport from 'utils/getI18nTypeImport';
import RowActions from '../Actions/Row';

const ImportsHistoryColumns = (t: TFunction): Column<ImportsHistory>[] => [
  {
    Header: t('imports:file') as string,
    accessor: 'file',
    id: 'file',
    Cell: ({ value, row }) => (
      <div className="ImportsHistory__columns__name">
        {value}
        <div className="ImportsHistory__columns__ref">
          {t<string>(getI18nTypeImport(row.original.type))}
        </div>
      </div>
    ),
  },
  {
    Header: t('imports:date') as string,
    accessor: 'date',
    id: 'date',
    sortType: 'datetime',
    sortDescFirst: true,
    Cell: ({ value }) => (
      <div className="ImportsHistory__columns__date">
        {formatIntlDate(value, 'dateLong')}, {formatIntlDate(value, 'time')}
      </div>
    ),
  },
  {
    Header: t('imports:status') as string,
    accessor: 'status',
    id: 'status',
    Cell: ({ value, row }) => (
      // FIXME: Retirer le format quand le back sera corrigé
      <ImportStatus status={value} format={row.original.file.endsWith('.pdf') ? 'pdf' : 'other'} />
    ),
  },
  {
    Header: t('imports:nb-records-created') as string,
    accessor: 'nbRecordsCreated',
    id: 'nbRecordsCreated',
    Cell: ({ value }) => <Fragment>{value}</Fragment>,
  },
  {
    Header: t('imports:nb-records-updated') as string,
    accessor: 'nbRecordsUpdated',
    Cell: ({ value }) => <Fragment>{value}</Fragment>,
  },
  {
    Header: t('imports:nb-records-error') as string,
    accessor: 'nbRecordsError',
    Cell: ({ value }) => <Fragment>{value}</Fragment>,
  },
  {
    Header: t('common:commands') as string,
    accessor: 'id',
    disableSortBy: true,
    Cell: ({ value: id }) => (
      <RowActions id={id} />
    ),
  },
];

export default ImportsHistoryColumns;
