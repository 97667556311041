import type Misc from 'types/misc';
import type { Payment } from 'types/models';

const ExportLink = (
  fetchOptions: Misc.PaginatedFetchArgs<Payment>,
  filtering: Misc.Filter[],
  organizationReference: string | null,
): string => {
  const { sort } = fetchOptions;

  if (!organizationReference) {
    return '';
  }

  const queryData = ['withUser=1'];

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }) => {
      queryData.push(`filters[${name}]=${value}`);
    });
  }

  if (sort) {
    const key = sort.id === 'client' ? 'clientName' : sort.id;
    const direction = sort.desc ? 'desc' : 'asc';
    queryData.push(`orderBy=${key}&orderDirection=${direction}`);
  }

  return `payments/export/${organizationReference}?${queryData.join('&')}`;
};

export default ExportLink;
