import './index.scss';
import { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import organizationStore from 'stores/Organization';
import type { Client, NoteSummary } from 'types/models';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useApiRequest from 'hooks/useApiRequest';
import Button from 'components/Button';
import Loading from 'components/Loading';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import NoteIcon from 'components/NoteIcon';
import DropdownActions from 'components/DropdownActions';
import CustomerMuteButton from 'components/CustomerMuteButton';

type Props = {
  id: number,
  name: string,
  lastNote: NoteSummary | null,
  customerData?: Client,
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const CustomersRowActions = (props: Props): JSX.Element => {
  const { id, name, lastNote, customerData, onActionDone, onActionError } = props;
  const { linesOfBusiness } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);
  const history = useHistory();
  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();
  const { remove, error, isLoading } = useApiRequest();

  const handleConfirmDelete = useCallback(() => {
    showConfirm(ct('clients:remove'), ct('clients:actions.confirm.remove'));
  }, [showConfirm, ct]);

  const handleSubmitDelete = useCallback(async () => {
    hideConfirm();
    const response = await remove(`client/manage/${id}`);
    if (response) {
      onActionDone(ct('clients:actions.toast.deleted', { name }));
    }
  }, [hideConfirm, remove, id, onActionDone, ct, name]);

  useEffect(() => {
    if (error) {
      onActionError(error.message || t('errors:unknown-retry'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const actions = [
    <Button
      variant="list"
      onClick={() => history.push(`/customers/edit/${id}`)}
      title={ct('common:edit')}
      disabled={isLoading}
    >
      {isLoading && <Loading hasNoText />}
      {!isLoading && t('common:edit')}
    </Button>,
    <Button
      variant="list-danger"
      onClick={handleConfirmDelete}
      title={ct('common:remove')}
      disabled={isLoading}
    >
      {isLoading && <Loading hasNoText />}
      {!isLoading && t('common:remove')}
    </Button>,
  ];

  return (
    <div className="CustomersRowActions">
      {lastNote && <NoteIcon note={lastNote} />}
      {customerData && (
        <CustomerMuteButton
          onActionDone={onActionDone}
          onActionError={onActionError}
          customerData={customerData} />
      )}
      <DropdownActions
        actions={actions}
      />
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        entityName={name}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitDelete}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={ct('clients:remove')}
      />
    </div>
  );
};

export default observer(CustomersRowActions);
