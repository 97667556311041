import './index.scss';
import PublicLayout from 'layout/PublicLayout';
import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Request from 'utils/request';
import ForgottenPasswordForm from './Form';

const ForgottenPassword: React.FC = () => {
  const { t } = useTranslation();

  const [sentEmail, setSentEmail] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>('');
  const [hasError, setHasError] = React.useState<boolean>(false);

  const handleSubmit = React.useCallback(async (emailToSend: string) => {
    setEmail(emailToSend);
    try {
      await Request.post('account/resetting-password', { email: emailToSend });
      setSentEmail(true);
    } catch (err) {
      setHasError(true);
    }
  }, []);

  return (
    <PublicLayout>
      <div className="ForgottenPassword">
        <div className="ForgottenPassword__form">
          <h2 className="ForgottenPassword__title">{t('forgotten-password:title')}</h2>
          {sentEmail && (
            <div className="ForgottenPassword__description">
              <h3>{t('forgotten-password:email-sent', { email })}</h3>
              <p className="ForgottenPassword__description__text">
                {t('forgotten-password:email-sent-description')}
              </p>
              <p className="ForgottenPassword__description__text">
                {t('forgotten-password:if-email-not-received')}
              </p>
              <Link to="/login">{t('forgotten-password:go-to-login')}</Link>
            </div>
          )}
          {!sentEmail && (
            <ForgottenPasswordForm
              onSubmit={handleSubmit}
              hasError={hasError}
            />
          )}
        </div>
      </div>
    </PublicLayout>
  );
};

export default observer(ForgottenPassword);
