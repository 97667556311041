import type Misc from 'types/misc';
import type { User } from 'types/models';

export interface ReducerAction {
  type: 'add' | 'remove',
  filesUpload?: File[],
  user: User,
  idsToDelete?: string[],
}

export type FilesReducer = React.Reducer<Misc.FileImport[], ReducerAction>;

const filesReducer: FilesReducer = (prevState, payload) => {
  const {
    type,
    filesUpload,
    user,
    idsToDelete,
  } = payload;

  const add = () => {
    if (!filesUpload) {
      throw new Error('La fonction add de filesReducer a besoin d’un array filesUpload non vide pour fonctionner correctement');
    }
    const listOfFiles: Misc.FileImport[] = [...prevState];
    filesUpload.forEach((fileUpload) => {
      const {
        name,
        lastModified,
        size,
        type: fileType,
      } = fileUpload;

      if (!listOfFiles.find((file) => file.name === name && file.lastModified === lastModified)) {
        listOfFiles.push({
          id: `${name}-${lastModified}`,
          name,
          lastModified,
          status: 'WAITING',
          format: fileType as 'pdf' | 'csv' | 'xlsx',
          size,
          user: { id: user.id, name: `${user.firstName} ${user.lastName}` },
          originalFileData: fileUpload,
        });
      }
    });
    return listOfFiles;
  };

  const deleteId = () => {
    if (!idsToDelete) {
      throw new Error(
        'La fonction remove de filesReducer a besoin d’un array d\'id à supprimer non vide pour fonctionner correctement',
      );
    }

    return prevState.filter(({ id }) => !idsToDelete.includes(id));
  };

  switch (type) {
    case 'add':
      return add();
    case 'remove':
      return deleteId();
    default:
      throw new Error('Unhandled action type');
  }
};

export default filesReducer;
