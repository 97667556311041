import './index.scss';
import { useCallback, useEffect, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { observer } from 'mobx-react';
import type { Plan as PlanType } from 'types/models';
import type { FetchAllParams } from 'api/plans';
import { fetchAll } from 'api/plans';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useFetch from 'hooks/useFetch';
import organizationStore from 'stores/Organization';
import ToastNotification from 'components/ToastNotification';
import useToast from 'components/ToastNotification/useToast';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import NoOrganizationMessage from 'components/NoOrganizationMessage';
import Loading from 'components/Loading';
import SettingsSection from '../Section';
import ModalAddPlan from './ModalAddPlan';
import ModalEditYAML from './ModalEditYAML';
import Plan from './Plan';

const SettingsPlans = (): JSX.Element => {
  const { linesOfBusiness, currentOrganization } = organizationStore;
  const { ct, t } = useContextualTranslation(linesOfBusiness);

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const { data, isLoading, refetch } = useFetch<FetchAllParams, PlanType[]>(
    { cacheKey: 'organizationPlans', organization: currentOrganization?.reference },
    fetchAll,
    { enabled: !!currentOrganization, refetchOnWindowFocus: false },
  );

  const [planToDuplicate, setPlanToDuplicate] = useState<string | undefined>();

  const handleActionDone = useCallback((message: string) => {
    showToast(message, 'success');
    setPlanToDuplicate(undefined);
    refetch();
  }, [refetch, showToast]);

  const [showModalAddPlan, hideModalAddPlan] = useModal(() => (
    <ModalAddPlan
      onClose={hideModalAddPlan}
      planToDuplicate={planToDuplicate}
      onActionDone={handleActionDone}
      onActionError={(message: string) => showToast(message, 'error')}
    />
  ), [handleActionDone, planToDuplicate, showToast]);

  const [showModalEditYAML, hideModalEditYAML] = useModal(() => (
    <ModalEditYAML
      onClose={hideModalEditYAML}
      onActionDone={handleActionDone}
      onActionError={(message: string) => showToast(message, 'error')}
    />
  ), [handleActionDone, showToast]);

  useEffect(() => {
    if (planToDuplicate) {
      showModalAddPlan();
    }
  }, [planToDuplicate, showModalAddPlan]);

  return (
    <div className="Plans">
      <SettingsSection
        title={ct('plans:plans')}
        action={(
          <ButtonWithUserRights
            action="CREATE"
            category="PREFERENCES_PLANS"
            onClick={showModalAddPlan}
            variant="link"
            withCaretIcon
          >
            {ct('plans:add-plan')}
          </ButtonWithUserRights>
        )}
        secondaryAction={(
          <ButtonWithUserRights
            category="PREFERENCES_PLANS"
            action="CREATE"
            onClick={showModalEditYAML}
            variant="link"
            withCaretIcon
          >
            {t('plans:yaml-edit')}
          </ButtonWithUserRights>
        )}
      >
        <p className="Plans__description">{t('plans:plans-description')}</p>
        {!currentOrganization && (
          <NoOrganizationMessage />
        )}
        {isLoading && <Loading />}
        {data?.map(({ name, identifier, isGroup }) => (
          <Plan
            name={name}
            onShowModalDuplicatePlan={setPlanToDuplicate}
            identifier={identifier}
            key={`${identifier}-${name}`}
            isGroup={isGroup}
            onActionDone={handleActionDone}
            onActionError={(message: string) => { showToast(message, 'error'); }}
          />
        ))}
      </SettingsSection>
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </div>
  );
};

export default observer(SettingsPlans);
