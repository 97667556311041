import type Misc from 'types/misc';

const getI18nPaymentType = (paymentType: Misc.PaymentType): string => {
  const i18nPaymentType = {
    PAYMENT: 'payments:type.payment',
    REFUND: 'payments:type.refund',
    CREDIT_NOTE: 'payments:type.credit-note',
  };

  return i18nPaymentType[paymentType] || 'common:unknown';
};

export default getI18nPaymentType;
