import { Link } from 'react-router-dom';
import type { PayRequestSummaryStep } from 'types/models';
import PaymentRequestDiffDays from 'components/PaymentRequestDiffDays';

type Props = {
  data: PayRequestSummaryStep,
  withDiffDays?: boolean,
};

const HistoryStepPayRequest = ({ data, withDiffDays }: Props): JSX.Element => (
  <div className="HistoryStepPayRequest">
    <Link to={`/payment-request/${data.id}`}>{data.reference}</Link>
    {withDiffDays && (
      <PaymentRequestDiffDays dueAt={data.dueAt} isActive={data.isActive} />
    )}
  </div>
);

export default HistoryStepPayRequest;
