import type { Client, CustomerFullNameData } from 'types/models';
import recomposeName from 'utils/recomposeName';

type Props = {
  client: Client | CustomerFullNameData,
  showReference?: boolean,
};

const ClientName = ({ client, showReference = false }: Props): JSX.Element => (
  <span className="ClientName">
    {recomposeName(client, { showReference })}
  </span>
);

export default ClientName;
