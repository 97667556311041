import type { TFunction } from 'i18next';
import type { HistoryStep } from 'types/models';
import getHistoryStepType from 'utils/getHistoryStepType';

const getI18nHistoryStepExtraInfo = (t: TFunction, step: HistoryStep): string | null => {
  const type = getHistoryStepType(step);
  const { validation, remove, isInitialActive, payRequest, at } = step;

  const isWaiting = (
    validation === null
    && remove === null
    && isInitialActive === false
    && payRequest !== null
    && new Date(at.at).getTime() < new Date().getTime()
  );

  if (type === 'task') {
    if (isWaiting) {
      return t('actions:history-step-type.task-waiting');
    }

    if (remove !== null) {
      return t('by-name', { name: remove.userName });
    }

    if (validation !== null) {
      return t('by-name', { name: validation.userName });
    }

    return t('actions:history-step-type.task-to-do');
  }

  if (type === 'reminder') {
    if (isWaiting) {
      return t('actions:history-step-type.reminder-waiting');
    }

    if (isInitialActive) {
      return t('actions:history-step-type.automated-reminder');
    }

    if (remove !== null) {
      return t('by-name', { name: remove.userName });
    }

    if (validation !== null) {
      return t('by-name', { name: validation.userName });
    }

    return t('actions:history-step-type.reminder-to-validate');
  }

  if (type === 'note') {
    return t('done-by-name', { name: at.userName });
  }

  return null;
};

export default getI18nHistoryStepExtraInfo;
