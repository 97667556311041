import './index.scss';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import type Misc from 'types/misc';
import Button from 'components/Button';
import Icon from 'components/Icon';
import FiltersModal from './Modal';

type Props = {
  filtersList: Misc.FilterDeclarationItem[],
  currentFilters: Misc.Filter[],
  onValidate(): void,
  onChange(filter: Misc.Filter): void,
  onCancel(): void,
  filtersSettings?: Misc.FiltersSettings,
};

const DataTableFilters = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  const [showModal, hideModal] = useModal(() => (
    <FiltersModal
      {...props}
      hideModal={hideModal}
    />
  ), [props]);

  return (
    <Button
      variant="link"
      className="DataTableFilters__open-button"
      onClick={showModal}
    >
      <Icon name="plus-small" /> {t('common:more-filters')}
    </Button>
  );
};

export default DataTableFilters;
