import './index.scss';

type Props = {
  sidebar: JSX.Element,
  children: JSX.Element
};

const CustomerMainLayout = ({ sidebar, children }: Props): JSX.Element => (
    <div className="CustomerMainLayout">
      <div className="CustomerMainLayout__side-view">
        {sidebar}
      </div>
      <div className="CustomerMainLayout__main-view">
        {children}
      </div>
    </div>
);

export default CustomerMainLayout;
