import type { Eav, OrganizationEavs } from 'types/models';

type DisplayAttribute = {
  id: number,
  label: string,
  value: string,
  position: number | null,
};

const getAllCustomerEavs = (
  customerEavs: Eav[] | null | undefined,
  organizationEavs: OrganizationEavs,
): DisplayAttribute[] => {
  const { client: allEavs } = organizationEavs;

  if (!customerEavs) {
    const requiredEavs = allEavs.filter(({ required }) => required);
    return requiredEavs.map(({ id, label, defaultValue, position }) => ({
      id,
      label,
      value: defaultValue,
      position,
    }));
  }

  const getValue = (identifier: Eav['identifier']) => {
    const organizationEav = allEavs.find(({ identifier: _id }) => _id === identifier);
    if (!organizationEav) {
      return null;
    }

    const customerEav = customerEavs.find(({ identifier: _id }) => _id === identifier);
    if (!customerEav) {
      return organizationEav.required ? organizationEav.defaultValue : null;
    }

    return customerEav.value;
  };

  const eavsList: DisplayAttribute[] = allEavs.map(({ id, identifier, label, position }) => {
    const value = getValue(identifier);
    return { id, label, value, position };
  });

  eavsList.sort(({ label: labelA, position: posA }, { label: labelB, position: posB }) => {
    if (posA === posB) {
      return labelA.localeCompare(labelB);
    }
    return (posA ?? 0) > (posB ?? 0) ? 1 : -1;
  });

  return eavsList;
};

const getOverviewCustomerEavs = (
  customerEavs: Eav[] | null | undefined,
  organizationEavs: OrganizationEavs,
): DisplayAttribute[] => {
  const { client: allEavs } = organizationEavs;

  if (!customerEavs) {
    const requiredEavs = allEavs.filter(({ required }) => required).slice(0, 4);
    return requiredEavs.map(({ id, label, defaultValue, position }) => ({
      id,
      label,
      value: defaultValue,
      position,
    }));
  }

  const getValue = (identifier: Eav['identifier']) => {
    const organizationEav = allEavs.find(({ identifier: _id }) => _id === identifier);
    if (!organizationEav) {
      return null;
    }

    const customerEav = customerEavs.find(({ identifier: _id }) => _id === identifier);
    if (!customerEav) {
      return organizationEav.required ? organizationEav.defaultValue : null;
    }

    return customerEav.value;
  };

  const eavsList: DisplayAttribute[] = allEavs
    .filter(({ overviewDisplayed }) => overviewDisplayed)
    .map(({ id, identifier, label, position }) => {
      const value = getValue(identifier);
      return { id, label, value, position };
    });

  eavsList.sort(({ label: labelA, position: posA }, { label: labelB, position: posB }) => {
    if (posA === posB) {
      return labelA.localeCompare(labelB);
    }
    return (posA ?? 0) > (posB ?? 0) ? 1 : -1;
  });

  return eavsList;
};

export { getAllCustomerEavs, getOverviewCustomerEavs };
