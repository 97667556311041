import './index.scss';
import { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Column } from 'react-table';
import type Misc from 'types/misc';
import type { LastCustomerMainDebtor } from 'types/models';
import { fetchMainDebtors, FetchMainDebtorsParams } from 'api/customers';
import useFetchPaginated from 'hooks/useFetchPaginated';
import useContextualTranslation from 'hooks/useContextualTranslation';
import organizationStore from 'stores/Organization';
import DataTable from 'components/DataTable';
import MainDebtorsColumns from './Columns';

const MainDebtors = (): JSX.Element => {
  const { currentOrganization, walletSelection, currency, linesOfBusiness } = organizationStore;
  const { ct } = useContextualTranslation(linesOfBusiness);

  const defaultFetchOptions = {
    pageIndex: 0,
    pageSize: 10,
    sort: { id: 'totalToCollect', desc: true },
  };

  const filtering = useMemo(() => {
    if (walletSelection.length === 0) {
      return [];
    }
    return [{ name: 'categories', value: walletSelection }];
  }, [walletSelection]);

  const [fetchOptions, setFetchOptions] = useState<Misc.PaginatedFetchArgs<LastCustomerMainDebtor>>(
    defaultFetchOptions,
  );

  const {
    status: fetchStatus,
    data,
    serverPagination,
    error,
    isFetching,
  } = useFetchPaginated<FetchMainDebtorsParams, LastCustomerMainDebtor>(
    {
      cacheKey: 'customers',
      organization: currentOrganization?.reference,
      filtering,
      fetchOptions,
    },
    fetchMainDebtors,
  );

  const columns = useMemo<Column<LastCustomerMainDebtor>[]>(
    () => MainDebtorsColumns(ct, currency),
    [ct, currency],
  );

  return (
    <div className="MainDebtors">
      <DataTable<LastCustomerMainDebtor>
        columns={columns}
        data={data}
        defaultSortBy={defaultFetchOptions.sort}
        serverPagination={serverPagination}
        withPagination={false}
        fetchData={setFetchOptions}
        isLoading={isFetching && fetchStatus === 'loading'}
        error={error}
        withActions={false}
        noHeader
        noDataFoundMessage={ct('clients:no-data-was-found')}
        defaultPageSize={defaultFetchOptions.pageSize}
      />
    </div>
  );
};

export default observer(MainDebtors);
