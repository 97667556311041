import type { Category } from 'types/models';
import Request from 'utils/request';

export type FetchAllParams = {
  organization: string | undefined,
  reloadTick: number,
};

const fetchAll = async ({ organization }: FetchAllParams) => {
  if (!organization) {
    throw new Error('FetchAllCategories: Missing organization.');
  }

  const result = await Request.get<Category[]>(`/category/${organization}`);
  return result;
};

export { fetchAll };
