import './index.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { DefaultTFuncReturn } from 'i18next';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import SelectSearch, { fuzzySearch, SelectSearchOption } from 'react-select-search';
import type { PlanForList } from 'types/models';
import organizationStore from 'stores/Organization';
import useFetch from 'hooks/useFetch';
import useIsMountedRef from 'hooks/useIsMountedRef';
import { fetchList } from 'api/plans';
import type { FetchListParams } from 'api/plans';
import Loading from 'components/Loading';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { DefaultValue, getDefaultValue } from './utils';

export type Props = {
  name: string,
  onSelect?(name: string, newValue: string | null): void,
  placeholder?: DefaultTFuncReturn,
  defaultValue?: DefaultValue,
  value?: string | string[] | null,
  disabled?: boolean,
  className?: string,
  isMultiple?: boolean,
  isInvalid?: boolean,
  withClearButton?: boolean,
};

const FormSelectScenario = (props: Props): JSX.Element => {
  const {
    name,
    onSelect,
    placeholder,
    defaultValue,
    value: controlValue,
    disabled = false,
    className,
    isMultiple = false,
    isInvalid = false,
    withClearButton = true,
  } = props;
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const isMountedRef = useIsMountedRef();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [value, setValue] = useState<string | string[]>('');
  const [cleared, setCleared] = useState<boolean>(false);
  const [options, setOptions] = useState<SelectSearchOption[] | null>(null);
  const [isFocus, setIsFocus] = useState<boolean>(false);

  const isSearchable = useMemo(() => (
    (options && options.length > 3) || false
  ), [options]);

  const { isLoading, data } = useFetch<FetchListParams, PlanForList[]>(
    { cacheKey: 'plansList', organization: currentOrganization?.reference },
    fetchList,
  );

  useEffect(() => {
    if (!isMountedRef.current) {
      return;
    }

    if ((!data || data.length === 0) && !isLoading && !options) {
      setOptions([{ name: '', value: '', disabled: true }]);
      return;
    }

    if (data !== null) {
      const optionsToSet = data.map(({ id, name: planName, group }: PlanForList) => ({
        name: `${planName}${group ? ` (${t('common:group')})` : ''}`,
        value: id,
      }));
      setOptions(optionsToSet);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  useEffect(() => {
    if (defaultValue && value.length === 0) {
      const initValue = getDefaultValue(defaultValue, options || []);
      setValue(initValue);
      if (onSelect) {
        onSelect(name, Array.isArray(initValue) ? initValue.join(',') : initValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, options]);

  useEffect(() => {
    if (controlValue !== undefined && value !== controlValue) {
      if (cleared) {
        setCleared(false);
      }
      setValue(controlValue === null ? '' : controlValue);
    }
  }, [value, controlValue, cleared]);

  const handleChange = useCallback(
    (selectedValue: string | string[]) => {
      setValue(selectedValue);
      const newValue = (Array.isArray(selectedValue)) ? selectedValue.join(',') : selectedValue;

      if (inputRef?.current) {
        // - Ceci permet de déclencher un onChange au niveau du <form> parent
        const inputValSetter = Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype,
          'value',
        )?.set;
        inputValSetter?.call(inputRef.current, newValue);
        inputRef.current.dispatchEvent(
          new Event('change', { bubbles: true }),
        );
      }

      if (onSelect) {
        onSelect(name, newValue);
      }
    },
    [name, onSelect],
  );

  const handleClear = useCallback(() => {
    setCleared(true);
    setIsFocus(false);
    setValue('');
    if (onSelect) {
      onSelect(name, null);
    }
  }, [name, onSelect]);

  const classNames = classnames('FormSelect', className, {
    'FormSelect--has-value': withClearButton && value.length > 0,
    'FormSelect--is-loading': isLoading,
    'FormSelect--is-invalid': isInvalid,
    'FormSelect--with-flying-label-opened': isFocus,
  });

  return (
    <div
      className={classNames}
      onFocus={() => { setIsFocus(true); }}
      onBlur={() => { setIsFocus(false); }}
    >
      {options === null && (
        <Loading />
      )}
      {options !== null && (
        <SelectSearch
          onChange={handleChange}
          disabled={disabled}
          value={value}
          options={options}
          search={isSearchable}
          placeholder={placeholder ?? undefined}
          multiple={isMultiple}
          printOptions="on-focus"
          filterOptions={fuzzySearch}
        />
      )}
      <input
        type="text"
        value={value}
        name={name}
        ref={inputRef}
        onChange={() => {}}
        className="FormSelect__hidden-field"
      />
      {withClearButton && value.length > 0 && (
        <Button
          variant="light"
          onClick={handleClear}
          className="FormSelect__clear"
        >
          <Icon name="close-small" />
        </Button>
      )}
    </div>
  );
};

export default observer(FormSelectScenario);
