import type Misc from 'types/misc';

const getI18nPaymentMethod = (paymentMethod: Misc.PaymentMethod): string => {
  const i18nPaymentMethod = {
    OTHER: 'payments:method.other',
    CARD: 'payments:method.card',
    CHECK: 'payments:method.check',
    TRANSFER: 'payments:method.transfer',
    DIRECT_DEBIT: 'payments:method.direct-debit',
    CASH: 'payments:method.cash',
  };

  return i18nPaymentMethod[paymentMethod] || 'common:unknown';
};

export default getI18nPaymentMethod;
