import React, { useCallback, useMemo, useReducer } from 'react';
import { Row } from 'react-table';

interface ReducerAction {
  type: 'toggleRow' | 'toggleAll' | 'reset',
  allRows?: SelectionState,
  rowId?: number | string,
}

export type SelectionState = (number | string)[];

type SelectionReducer = React.Reducer<SelectionState, ReducerAction>;

const selectionReducer: SelectionReducer = (prevState, { type, allRows, rowId }) => {
  switch (type) {
    case 'reset':
      return [];
    case 'toggleAll':
      if (allRows === undefined) {
        throw new Error('allRows must be defined in order to toggle all selection');
      }
      return prevState.length === allRows.length ? [] : allRows;
    case 'toggleRow':
      if (!rowId) {
        throw new Error('Missing row ID');
      }
      if (prevState.includes(rowId)) {
        return [...prevState.filter((id) => id !== rowId)];
      }
      return [...prevState, rowId];
    default:
      throw new Error('Unhandled action type');
  }
};

export interface SelectionHookResult {
  selection: SelectionState,
  toggleSelectAll: () => void,
  toggleSelect: (rowId: string | number) => (() => void),
  clearSelection: () => void,
  isSelected: (rowId: string | number) => boolean,
  hasSelection: boolean,
  isAllSelected: boolean,
}

const useSelection = (
  rows: Row<any>[],
  hasAction: (row: Row<any>) => boolean,
): SelectionHookResult => {
  const [selection, updateSelection] = useReducer(selectionReducer, []);
  const selectableList = rows.filter((row) => hasAction(row)).map((row) => (
    row.values.id || row.values.importId
  ));

  const toggleSelectAll = useCallback(() => {
    updateSelection({ type: 'toggleAll', allRows: selectableList });
  }, [selectableList]);

  const toggleSelect = useCallback((rowId: number | string) => (
    () => { updateSelection({ type: 'toggleRow', rowId }); }
  ), []);

  const clearSelection = useCallback(
    () => { updateSelection({ type: 'reset' }); },
    [],
  );

  const isSelected = useCallback(
    (rowId: string | number) => selection.includes(rowId),
    [selection],
  );

  const hasSelection = useMemo(
    () => selection.length > 0,
    [selection],
  );

  const isAllSelected = useMemo(
    () => selection.length === selectableList.length && selectableList.length !== 0,
    [selection, selectableList],
  );

  return {
    selection,
    toggleSelectAll,
    toggleSelect,
    clearSelection,
    isSelected,
    hasSelection,
    isAllSelected,
  };
};

export default useSelection;
