import './index.scss';
import { useCallback, useMemo, useState } from 'react';
import type Misc from 'types/misc';
import useMountedEffect from 'hooks/useMountedEffect';
import FormGroup from 'components/FormGroup';
import FormSelect from 'components/FormSelect';
import FormControl from 'components/FormControl';
import FormSelectCustomer from 'components/FormSelectCustomer';
import FormSelectScenario from 'components/FormSelectScenario';
import FormSwitch from 'components/FormSwitch';
import FilterDateInput from './DateInput';
import FilterNumberInput from './NumberInput';
import FilterYesNo from './YesNo';

type Props = {
  filter: Misc.FilterDeclarationItem,
  currentFilters: Misc.Filter[],
  getFilterValue(filterName: string): Misc.FilterValue,
  onChange(filter: Misc.Filter): void,
};

const DataTableFilterItem = (props: Props): JSX.Element | null => {
  const { filter, currentFilters, getFilterValue, onChange } = props;
  const { key, type, title, emptyText, selectData, isNotRemovable, custom: FilterCustom } = filter;

  const defaultValue = useMemo(() => (
    getFilterValue(key)
    // - Ici on veut garder en mémoire la première valeur du filtre
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  const [value, setValue] = useState<Misc.FilterValue>(() => defaultValue);

  useMountedEffect(() => {
    const currentKeys = currentFilters.map(({ name }) => name);
    if (!currentKeys.includes(key)) {
      setValue(null);
      return;
    }

    const newFilterData = currentFilters.find(({ name }) => name === key);
    if (!newFilterData || newFilterData.value === defaultValue) {
      return;
    }
    setValue(newFilterData.value);
  }, [currentFilters]);

  const handleItemChange = useCallback((name: string, newValue: string | null) => {
    setValue(newValue);
    onChange({ name, value: newValue });
  }, [onChange]);

  switch (type) {
    case 'select':
      return (
        <FormGroup className="DataTableFiltersItem" label={title}>
          <FormSelect
            name={key}
            // Ici on sait que defaultValue sera toujours une string
            defaultValue={defaultValue as string}
            value={value}
            onSelect={handleItemChange}
            placeholder={emptyText}
            selectOptions={selectData}
            withClearButton={!isNotRemovable}
          />
        </FormGroup>
      );
    case 'selectAsync':
      return (
        <FormGroup className="DataTableFiltersItem" label={filter.title}>
          <FormSelect
            name={filter.key}
            defaultValue={defaultValue as string}
            value={value}
            onSelect={handleItemChange}
            placeholder={filter.emptyText}
            fetchEntity={filter.fetchEntity}
            isAsync
          />
        </FormGroup>
      );
    case 'selectClient':
      return (
        <FormGroup className="DataTableFiltersItem" label={filter.title}>
          <FormSelectCustomer
            name={filter.key}
            defaultValue={filter.defaultValue as string}
            value={value}
            onSelect={handleItemChange}
            placeholder={filter.emptyText}
            withId
          />
        </FormGroup>
      );
    case 'selectScenario':
      return (
        <FormGroup className="DataTableFiltersItem" label={filter.title}>
          <FormSelectScenario
            name={filter.key}
            defaultValue={filter.defaultValue as string}
            value={value}
            placeholder={filter.emptyText}
            onSelect={handleItemChange}
          />
        </FormGroup>
      );
    case 'dateInput':
      return (
        <FilterDateInput
          filter={filter}
          defaultValue={defaultValue as string}
          value={value?.toString() ?? ''}
          onChange={handleItemChange}
          className="DataTableFiltersItem"
          maxDateToday={false}
        />
      );
    case 'textInput':
      return (
        <FormGroup label={filter.title} className="DataTableFiltersItem">
          <FormControl
            type="text"
            aria-label={filter.title}
            name={filter.key}
            value={value?.toString() ?? ''}
            onChange={(newValue) => { handleItemChange(filter.key, newValue); }}
          />
        </FormGroup>
      );
    case 'numberInput':
      return (
        <FilterNumberInput
          filter={filter}
          defaultValue={defaultValue as string}
          onChange={handleItemChange}
          className="DataTableFiltersItem"
        />
      );
    case 'onOff':
      return (
        <FormSwitch
          value={value?.toString() === '1'}
          onChange={(newValue: boolean) => { handleItemChange(filter.key, newValue ? '1' : '0'); }}
          label={filter.title}
        />
      );
    case 'yesNo':
      return (
        <FilterYesNo
          filter={filter}
          defaultValue={defaultValue as string | null}
          onChange={handleItemChange}
          className="DataTableFiltersItem"
        />
      );
    case 'custom':
    case 'customAsync':
      return FilterCustom ? (
        <div className="FormGroup DataTableFiltersItem">
          {filter.title && (
            <div className="FormGroup__header">
              <label className="FormGroup__header__label">{filter.title}</label>
            </div>
          )}
          <FilterCustom
            filter={filter}
            getFilterValue={getFilterValue}
            onChange={onChange}
          />
        </div>
      ) : null;
    default:
      return null;
  }
};

export default DataTableFilterItem;
