import './index.scss';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useParams, useLocation } from 'react-router-dom';
import type { Client } from 'types/models';
import { fetchOne, FetchOneParams } from 'api/customers';
import organizationStore from 'stores/Organization';
import useFetch from 'hooks/useFetch';
import ContactEditPage from 'pages/ContactEdit';
import PaymentRequestEditPage from 'pages/PaymentRequestEdit';
import ToastNotification from 'components/ToastNotification';
import useToast from 'components/ToastNotification/useToast';
import CustomerContext from './customerContext';
import CustomerHeader from './Header';
import CustomerMain from './Main';

export type RouteParams = {
  customerId: string,
};

type QueryDialog = 'new-pay-request' | 'new-contact' | null;

const Customer = (): JSX.Element => {
  const { customerId } = useParams<RouteParams>();
  const { search, state: routeState } = useLocation();
  const id = Number.parseInt(customerId, 10);
  const { currentOrganization } = organizationStore;
  const { role: defaultRole } = (routeState || { role: '' }) as { role: string };
  const [reloadTick, setReloadTick] = useState<number>(1);
  const { isToastShowed, showToast, hideToast, toastStatus, toastText } = useToast();

  const editType = useMemo<QueryDialog>(() => {
    if (!search || search.length < 1) {
      return null;
    }
    const searchParams = new URLSearchParams(search);
    const dialog = searchParams.get('dialog');
    if (dialog && ['new-pay-request', 'new-contact'].includes(dialog)) {
      return dialog as QueryDialog;
    }
    return null;
  }, [search]);

  const { isLoading, data, error, refetch } = useFetch<FetchOneParams, Client>(
    {
      cacheKey: 'customer',
      organization: currentOrganization?.reference,
      id,
      options: { withEav: 1, withUser: 1 },
    },
    fetchOne,
  );

  useEffect(() => {
    if (data?.id !== id) {
      refetch();
    }
  }, [id, data, refetch]);

  const handleHeaderActionsDone = useCallback((title: string, message: string) => {
    showToast(message || title, 'success');
    refetch();
    setReloadTick(reloadTick + 1);
  }, [showToast, refetch, reloadTick]);

  const handleActionDone = useCallback((message: string) => {
    showToast(message, 'success');
    refetch();
    setReloadTick(reloadTick + 1);
  }, [showToast, refetch, reloadTick]);

  const currentContextValue = {
    data,
    refetch,
    onActionDone: handleActionDone,
    reloadTick,
  };

  return (
    <div className="Customer">
      <CustomerContext.Provider value={currentContextValue}>
        <CustomerHeader
          isLoading={isLoading}
          error={error}
          onDone={handleHeaderActionsDone}
          onActionError={(message) => showToast(message, 'error')}
        />
        <CustomerMain />
        <ToastNotification
          text={toastText}
          status={toastStatus}
          isShow={isToastShowed}
          onClose={hideToast}
        />
        {editType === 'new-pay-request' && (
          <PaymentRequestEditPage
            onClose={() => {}}
            onDone={handleActionDone}
            finallyRedirectTo={`/customer/${id}/summary`}
            customerId={id}
            isCustomerInGroup={data?.useGroup}
          />
        )}
        {editType === 'new-contact' && (
          <ContactEditPage
            defaultCustomerId={id}
            customerId={id}
            finallyRedirectTo={`/customer/${id}/infos-contacts`}
            onDone={handleActionDone}
            defaultRole={defaultRole}
          />
        )}
      </CustomerContext.Provider>
    </div>
  );
};

export default observer(Customer);
