import type { OrganizationStripe } from 'types/models';
import Request from 'utils/request';

export type FetchOneParams = {
  organization: string | undefined,
};

const fetchOne = async ({ organization }: FetchOneParams) => {
  if (!organization) {
    throw new Error('FetchOneStripe: Missing organization.');
  }

  const result = await Request.get<OrganizationStripe>(
    `organization/${organization}/stripe`,
  );
  return result;
};

// eslint-disable-next-line import/prefer-default-export
export { fetchOne };
