import './index.scss';
import type { ReactNode } from 'react';
import type { DefaultTFuncReturn } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useLocation } from 'react-router-dom';
import Button from 'components/Button';
import Icon from 'components/Icon';
import type { ButtonVariant } from 'components/Button';

type Props = {
  toggle: ReactNode,
  toggleTitle?: DefaultTFuncReturn,
  className?: string,
  variant?: ButtonVariant,
  noIcon?: boolean,
  children: ReactNode,
};

const Dropdown = (props: Props): JSX.Element => {
  const {
    toggle,
    toggleTitle,
    className,
    variant = 'primary',
    noIcon = false,
    children,
  } = props;

  const [open, setOpen] = useState<boolean>(false);

  const ref = useOnclickOutside(() => { setOpen(false); });
  const { pathname } = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  const classNames = classnames('Dropdown', {
    'Dropdown--open': open,
  }, className);

  const handleClickToggle = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <div className={classNames}>
      <Button
        variant={variant}
        className="Dropdown__toggle"
        title={toggleTitle}
        onClick={handleClickToggle}
      >
        <div className="Dropdown__toggle__content">
          {toggle}
        </div>
        {!noIcon && (
          <Icon
            className="Dropdown__toggle__icon"
            name={open ? 'caret-up' : 'caret-down'}
          />
        )}
      </Button>
      <div className="Dropdown__menu" ref={ref}>
        {children}
      </div>
    </div>
  );
};

export default Dropdown;
