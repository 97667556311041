import './index.scss';
import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Config from 'config';
import type Misc from 'types/misc';
import type { OrganizationStats } from 'types/models';
import organizationStore from 'stores/Organization';
import formatIntlNumber from 'utils/formatIntlNumber';
import StatBlock from 'components/StatBlock';
import useTooltip from 'components/Tooltip/useTooltip';
import ListStatsByPeriod from 'components/ListStatsByPeriod';

type Props = {
  data: OrganizationStats,
};

const HomeStats = ({ data }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { currency } = organizationStore;
  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());

  const currentTotalRecovered = useMemo(() => {
    if (!data.totalRecovered) {
      return null;
    }

    const currentYearTotals = data.totalRecovered.filter((period) => period.year === currentYear);

    return currentYearTotals.length > 0 ? currentYearTotals[0] : null;
  }, [currentYear, data]);

  const monthlyValues = useMemo(() => (
    currentTotalRecovered?.months.map((period) => period.value)
  ), [currentTotalRecovered]);

  const monthlyLabels = useMemo(() => {
    if (!currentTotalRecovered) {
      return [];
    }

    return currentTotalRecovered.months.map(
      ({ month }: Misc.TotalRecoveredByMonth) => t(`months:short.${month}`),
    );
  }, [currentTotalRecovered, t]);

  const periodMoreThan30DaysLate = useMemo<Misc.ListStatItem[]>(() => [
    {
      id: 'INF6030',
      total: data.countUnpaidDebitSup30,
      count: data.countUnpaidDebitPeriods.inf6030,
      percentage: data.ratioUnpaidDebitPeriods.inf6030,
    },
    {
      id: 'INF9060',
      total: data.countUnpaidDebitSup30,
      count: data.countUnpaidDebitPeriods.inf9060,
      percentage: data.ratioUnpaidDebitPeriods.inf9060,
    },
    {
      id: 'INF90',
      total: data.countUnpaidDebitSup30,
      count: data.countUnpaidDebitPeriods.inf90,
      percentage: data.ratioUnpaidDebitPeriods.inf90,
    },
  ], [data]);

  const periodRecent = useMemo<Misc.ListStatItem[]>(() => [
    {
      id: 'INF100',
      total: data.countUnpaidDebitRecent,
      count: data.countUnpaidDebitPeriods.inf100,
      percentage: data.ratioUnpaidDebitPeriods.inf100,
    },
    {
      id: 'INF3010',
      total: data.countUnpaidDebitRecent,
      count: data.countUnpaidDebitPeriods.inf3010,
      percentage: data.ratioUnpaidDebitPeriods.inf3010,
    },
  ], [data]);

  const periodFuture = useMemo<Misc.ListStatItem[]>(() => {
    const {
      countUnpaidDebitPeriods,
      countUnpaidDebitFuture,
      countUnpaidDebitSup30,
      ratioUnpaidDebitPeriods,
    } = data;
    const countTotalFutures = countUnpaidDebitFuture + countUnpaidDebitSup30;

    return [
      {
        id: 'SUP010',
        total: countTotalFutures,
        count: countUnpaidDebitPeriods.sup010,
        percentage: ratioUnpaidDebitPeriods.sup010,
      },
      {
        id: 'SUP1030',
        total: countTotalFutures,
        count: countUnpaidDebitPeriods.sup1030,
        percentage: ratioUnpaidDebitPeriods.sup1030,
      },
      {
        id: 'SUP30',
        total: countTotalFutures,
        count: countUnpaidDebitPeriods.sup30,
        percentage: ratioUnpaidDebitPeriods.sup30,
      },
    ];
  }, [data]);

  const tooltipMore30DaysLate = useTooltip(
    <ListStatsByPeriod
      title={t('home:more-than-30-days-late.title')}
      count={data.countUnpaidDebitSup30}
      percent={Math.round(data.sumUnpaidDebitSup30Ratio * 100)}
      periods={periodMoreThan30DaysLate}
    />,
    'statBlock',
  );

  const tooltipLess30DaysLate = useTooltip(
    <ListStatsByPeriod
      title={t('home:less-than-30-days-late.title')}
      count={data.countUnpaidDebitRecent}
      percent={Math.round(data.sumUnpaidDebitRecentRatio * 100)}
      periods={periodRecent}
    />,
    'statBlock',
  );

  const tooltipTotalFuture = useTooltip(
    <ListStatsByPeriod
      title={t('home:total-future')}
      count={data.countUnpaidDebitFuture}
      percent={Math.round(data.sumUnpaidDebitFutureRatio * 100)}
      periods={periodFuture}
    />,
    'statBlock',
  );

  return (
    <div className="HomeStats">
      <div className="HomeStats__group HomeStats__group--with-tooltip">
        <StatBlock
          ref={tooltipMore30DaysLate}
          title={t('home:more-than-30-days-late.tooltip-title')}
          colors={[
            Config.PERIODS_COLORS.INF6030,
            Config.PERIODS_COLORS.INF9060,
            Config.PERIODS_COLORS.INF90,
          ]}
        >
          <h3 className="HomeStats__value">
            {formatIntlNumber(data.sumUnpaidDebitSup30, currency, 0)}
          </h3>
        </StatBlock>
        <StatBlock
          ref={tooltipLess30DaysLate}
          title={t('home:less-than-30-days-late.tooltip-title')}
          colors={[
            Config.PERIODS_COLORS.INF100,
            Config.PERIODS_COLORS.INF3010,
          ]}
        >
          <h3 className="HomeStats__value">
            {formatIntlNumber(data.sumUnpaidDebitRecent, currency, 0)}
          </h3>
        </StatBlock>
        <StatBlock
          ref={tooltipTotalFuture}
          title={t('home:total-future')}
          colors={[
            Config.PERIODS_COLORS.SUP010,
            Config.PERIODS_COLORS.SUP1030,
            Config.PERIODS_COLORS.SUP30,
          ]}
        >
          <h3 className="HomeStats__value">
            {formatIntlNumber(data.sumUnpaidDebitFuture, currency, 0)}
          </h3>
        </StatBlock>
      </div>
      <div className="HomeStats__group">
        <StatBlock
          title={t('home:total-recovered')}
          className="HomeStats__recovered"
          chartData={monthlyValues}
          chartLabels={monthlyLabels}
          chartFilter={{
            defaultValue: currentYear.toString(),
            values: data.totalRecovered.map(({ year }) => year.toString()),
            onChange: (value: string) => setCurrentYear(Number(value)),
          }}
        >
          <Fragment>
            {currentTotalRecovered && (
              <h3 className="HomeStats__value">
                {formatIntlNumber(currentTotalRecovered.total, currency, 0)}
                {currentTotalRecovered.evolution > 0 && (
                  <span className="HomeStats__value--positive">
                    +{currentTotalRecovered.evolution}%
                  </span>
                )}
                {currentTotalRecovered.evolution < 0 && (
                  <span className="HomeStats__value--negative">
                    {currentTotalRecovered.evolution}%
                  </span>
                )}
              </h3>
            )}
          </Fragment>
        </StatBlock>
        <StatBlock title={t('home:average-payment-time')}>
          <Fragment>
            <h3 className="HomeStats__value">
              {data.dso.total > 0 ? t('common:days-count', { count: Math.round(data.dso.total) }) : '-'}
              {data.dso.evolution > 0 && (
                <span className="HomeStats__value--positive">
                  +{formatIntlNumber(data.dso.evolution * 100, null, 2)}%
                </span>
              )}
              {data.dso.evolution < 0 && (
                <span className="HomeStats__value--negative">
                  {formatIntlNumber(data.dso.evolution * 100, null, 2)}%
                </span>
              )}
            </h3>
            <div className="HomeStats__subTitle">
              {data.unpaidRate > 0 ? (
                <Fragment>
                  {t('home:rate-unpaid-bills')}{' '}
                  <span className="HomeStats__subTitle__value">
                    {formatIntlNumber(data.unpaidRate * 100, null, 2)}%
                  </span>
                </Fragment>
              ) : '-'}
            </div>
          </Fragment>
        </StatBlock>
      </div>
    </div>
  );
};

export default HomeStats;
