import './index.scss';
import { Fragment } from 'react';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import type { PayRequest } from 'types/models';
import formatIntlNumber from 'utils/formatIntlNumber';
import formatIntlDate from 'utils/formatIntlDate';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import Button from 'components/Button';
import Badge from 'components/Badge';
import EditPlan from '../../Modals/EditPlan';
import InfoCard from './Card';

type Props = {
  payRequest: PayRequest,
  onActionDone: (message: string) => void,
};

const PaymentRequestInfos = ({ payRequest, onActionDone }: Props): JSX.Element => {
  const { linesOfBusiness, currency, userRight } = organizationStore;
  const { t } = useContextualTranslation(linesOfBusiness);
  const isUserReader = userRight === 'ROLE_READER';

  const [showEditPlanModal, hideEditPlanModal] = useModal(() => (
    <EditPlan
      plan={payRequest.plan}
      payrequestId={payRequest.id}
      payrequestReference={payRequest.reference}
      onDone={onActionDone}
      onClose={hideEditPlanModal}
      groupId={payRequest.group?.id || null}
    />
  ), [payRequest, onActionDone]);

  return (
    <div className="PaymentRequestInfos">
      <div className="PaymentRequestInfos__content">
        <InfoCard
          title={t('common:initial-amount')}
          value={formatIntlNumber(payRequest.initialCost, payRequest.currency || currency)}
        />
        <InfoCard
          title={t('common:remaining-balance')}
          value={formatIntlNumber(payRequest.amount, payRequest.currency || currency)}
        />
        <InfoCard
          title={t('payment-requests:subject')} tooltip={payRequest.subject || undefined}
          value={<div className="PaymentRequestInfos__subject">{payRequest.subject || '-'}</div>}
        />
        <InfoCard
          title={t('common:deadline')}
          value={(payRequest.dueAt && formatIntlDate(payRequest.dueAt, 'dateLong')) || '-'}
        />
        <InfoCard
          title={t('payment-requests:promise-to-pay')}
          value={(payRequest.promiseToPayAt && formatIntlDate(payRequest.promiseToPayAt, 'dateLong')) || '-'}
        />
        <InfoCard
          title={t('payment-requests:dunning-plan')}
          value={
            <Fragment>
              {payRequest.group && (
                <Badge variant="GROUP">{t('common:group')}</Badge>
              )}
              {(isUserReader || payRequest.muted) && (
                <span className="PaymentRequestInfos__muted-group">
                  {payRequest.group ? payRequest.group.plan : payRequest.plan}
                  {payRequest.planIdentifier ? `(${payRequest.planIdentifier})` : ''}
                </span>
              )}
              {(!isUserReader && !payRequest.muted) && (
                <Button variant="link" onClick={showEditPlanModal}>
                  {payRequest.group ? payRequest.group.plan : payRequest.plan}
                  {payRequest.planIdentifier ? `(${payRequest.planIdentifier})` : ''}
                </Button>
              )}
            </Fragment>
          }
        />
      </div>
    </div>
  );
};

export default observer(PaymentRequestInfos);
