import './index.scss';
import { useState, useCallback, KeyboardEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Badge from 'components/Badge';
import FormControl from 'components/FormControl';
import Icon from 'components/Icon';
import Button from 'components/Button';

type Props = {
  name: string,
  initialValues?: string[],
  onChange(values: string[]): void,
};

const FormBadgeSelect = ({ name, initialValues = [] }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [newValue, setNewValue] = useState('');
  const [values, setValues] = useState(initialValues || []);
  const [error, setError] = useState<string | null>(null);
  const valuesRef = useRef<HTMLInputElement>(null);

  const handleInputAddChange = useCallback((newTempValue: string) => {
    const lowercaseValues = values.map((value) => value.toLowerCase());
    setError(lowercaseValues.includes(newTempValue.toLowerCase()) ? t('perimeters:errors.duplicate-value') : null);
    setNewValue(newTempValue);
  }, [setError, setNewValue, t, values]);

  const dispatchChange = useCallback(() => {
    if (!valuesRef.current) {
      return;
    }

    // https://coryrylan.com/blog/trigger-input-updates-with-react-controlled-inputs
    const event = new Event('change', { bubbles: true });
    const PropDescriptor = Object.getOwnPropertyDescriptor( window.HTMLInputElement.prototype, 'value');
    PropDescriptor?.set?.call(valuesRef.current, null);

    valuesRef.current.dispatchEvent(event);
  }, [valuesRef]);

  const handleKeyDown = useCallback((event: KeyboardEvent): void => {
    const illegalCharacters = [','];
    if (illegalCharacters.includes(event.key)) {
      event.preventDefault();
      setError(t('perimeters:errors.illegal-character', { char: event.key }));
      return;
    }

    if (event.key === 'Enter') {
      event.preventDefault();
      if (error) {
        return;
      }
      setValues([...values, newValue.trim()]);
      dispatchChange();
      setNewValue('');
      setError(null);
    }
  }, [setNewValue, setError, t, error, values, newValue, dispatchChange]);

  const handleDeleteTag = useCallback((valueToDelete: string) => {
    setValues(values.filter((searchValue) => searchValue !== valueToDelete));
    dispatchChange();
  }, [values, dispatchChange]);

  return (
    <div className="FormBadgeSelect">
      <FormControl
        className="FormBadgeSelect__input-add"
        type="text"
        autoComplete="off"
        placeholder={t<string>('common:add-value-press-enter')}
        onKeyDown={handleKeyDown}
        onChange={handleInputAddChange}
        value={newValue}
      />
      {error && <div className="FormBadgeSelect__input-error">{error}</div>}
      <input
        ref={valuesRef}
        name={name}
        type="text"
        value={values.join(',')}
        hidden
        readOnly
      />
      <div className="FormBadgeSelect__values">
        {values.map((value) => (
          <Badge variant="NOTE" key={value}>
            {value}
              <Button
                variant="outline"
                className="FormBadgeSelect__value__delete"
                onClick={() => { handleDeleteTag(`${value}`); }}
              >
                <Icon name="close" />
              </Button>
          </Badge>
        ))}
      </div>
    </div>
  );
};

export default FormBadgeSelect;
