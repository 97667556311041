import type { Models, OrganizationPlanTemplate } from 'types/models';
import Request from 'utils/request';

export type FetchOneParams = {
  organization: string | undefined,
  type: 'dispatches' | 'reminders' | 'tasks',
  identifier: string,
};

export type FetchAllParams = {
  organization: string | undefined,
};

const fetchAll = async ({ organization }: FetchAllParams) => {
  if (!organization) {
    throw new Error('FetchAllModels: Missing organization.');
  }

  const result = await Request.get<Models>(
    `organization/${organization}/templates/list`,
  );
  return result;
};

const fetchOne = async ({
  organization,
  type,
  identifier,
}: FetchOneParams) => {
  if (!organization) {
    throw new Error('FetchOneModel: Missing organization.');
  }

  const result = await Request.get<OrganizationPlanTemplate[] | OrganizationPlanTemplate>(
    `organization/${organization}/templates/${type}?identifier=${identifier}`,
  );
  return result;
};

export { fetchAll, fetchOne };
