import './index.scss';
import React from 'react';
import getUploadImageUrl from 'utils/getUploadImageUrl';
import Notification from 'components/Notification';

interface Props {
  username: string,
  size?: number,
  notificationCount?: number,
  userAvatar?: string | null,
}

const Avatar: React.FC<Props> = (props) => {
  const {
    username,
    size = 32,
    notificationCount = 0,
    userAvatar,
  } = props;

  return (
    <div className="Avatar">
      <div className="Avatar__container" style={{ width: size, height: size }}>
        {userAvatar && (
          <img
            src={getUploadImageUrl(`/uploads/profile/${userAvatar}`)}
            className="Avatar__container__image"
            alt={username}
          />
        )}
        {!userAvatar && (
          <div className="Avatar__container__initials">
            {username[0]} {size > 50 && username[1]}
          </div>
        )}
      </div>
      {!!notificationCount && (
        <Notification count={notificationCount} />
      )}
    </div>
  );
};

export default Avatar;
