import './index.scss';
import { useCallback, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { observer } from 'mobx-react';
import useContextualTranslation from 'hooks/useContextualTranslation';
import organizationStore from 'stores/Organization';
import ToastNotification from 'components/ToastNotification';
import useToast from 'components/ToastNotification/useToast';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import SettingsSection from '../Section';
import ModalEdit from './ModalEdit';
import AttributesModalEditYAML from './ModalEditYAML';
import AttributesListing from './AttributesListing';

const SettingsAttributes = (): JSX.Element => {
  const { linesOfBusiness } = organizationStore;
  const { ct } = useContextualTranslation(linesOfBusiness);
  const [attributesRenderTick, setAttributesRenderTick] = useState(1);

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const handleActionDone = useCallback(async (message: string) => {
    await organizationStore.refresh();
    setAttributesRenderTick((lastTick) => lastTick + 1);
    showToast(message, 'success');
  }, [showToast]);

  const handleActionError = useCallback((message: string) => {
    showToast(message, 'error');
  }, [showToast]);

  const [showModalClients, hideModalClients] = useModal(() => (
    <ModalEdit
      type="CLIENT"
      onClose={hideModalClients}
      onActionDone={handleActionDone}
      onActionError={handleActionError}
    />
  ), [handleActionDone, handleActionError]);

  const [showModalPaymentRequests, hideModalPaymentRequests] = useModal(() => (
    <ModalEdit
      type="PAY_REQUEST"
      onClose={hideModalPaymentRequests}
      onActionDone={handleActionDone}
      onActionError={handleActionError}
    />
  ), [handleActionDone, handleActionError]);

  const [showModalClientEditYAML, hideModalClientEditYAML] = useModal(() => (
    <AttributesModalEditYAML
      onClose={hideModalClientEditYAML}
      onActionDone={handleActionDone}
      onActionError={(message: string) => showToast(message, 'error')}
    />
  ), [handleActionDone, showToast]);

  return (
    <div className="Attributes">
      <SettingsSection
        title={ct('attributes:clients-attributes')}
        action={(
          <ButtonWithUserRights
            onClick={showModalClients}
            variant="link"
            withCaretIcon
            action="CREATE"
            category="PREFERENCES_ATTRIBUTES"
          >
            {ct('attributes:add-attribute-client')}
          </ButtonWithUserRights>
        )}
        secondaryAction={(
          <ButtonWithUserRights
            onClick={showModalClientEditYAML}
            variant="link"
            withCaretIcon
            action="CREATE"
            category="PREFERENCES_ATTRIBUTES"
          >
            {ct('attributes:yaml-edit')}
          </ButtonWithUserRights>
        )}
      >
        <AttributesListing
          key={attributesRenderTick}
          type="CLIENT"
          onActionDone={handleActionDone}
          onActionError={handleActionError}
        />
      </SettingsSection>
      <SettingsSection
        title={ct('attributes:payment-requests-attributes')}
        action={(
          <ButtonWithUserRights
            onClick={showModalPaymentRequests}
            variant="link"
            withCaretIcon
            action="CREATE"
            category="PREFERENCES_ATTRIBUTES"
          >
            {ct('attributes:add-attribute-payment-request')}
          </ButtonWithUserRights>
        )}
      >
        <AttributesListing
          key={attributesRenderTick}
          type="PAY_REQUEST"
          onActionDone={handleActionDone}
          onActionError={handleActionError}
        />
      </SettingsSection>
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </div>
  );
};

export default observer(SettingsAttributes);
