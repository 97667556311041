import './index.scss';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loading from 'components/Loading';
import type { PayRequest } from 'types/models';
import Badge from 'components/Badge';
import getI18nPhase from 'utils/getI18nPhase';
import getRelativeDaysCount from 'utils/getRelativeDaysCount';
import getI18nStatus from 'utils/getI18nStatus';
import GoBackButton from 'components/GoBackButton';
import Icon from 'components/Icon';

interface Props {
  payRequest: PayRequest | null,
}

const PaymentRequestHeader = ({ payRequest }: Props): JSX.Element => {
  const { t } = useTranslation();

  const dayDiff = useMemo<string>(() => {
    if (!payRequest) {
      return '';
    }
    const diffDay = 0 - getRelativeDaysCount(payRequest.dueAt);
    const diffDayText = diffDay > 0 ? `+${diffDay}` : diffDay;
    return `(J${diffDayText})`;
  }, [payRequest]);

  if (!payRequest) {
    return (
      <div className="PaymentRequestHeader">
        <div className="PaymentRequestHeader__main">
          <div className="PaymentRequestHeader__links"><GoBackButton /></div>
          <div className="PaymentRequestHeader__infos"><Loading /></div>
        </div>
      </div>
    );
  }

  const { reference, phase, status, muted, client } = payRequest;

  return (
    <div className="PaymentRequestHeader">
      <div className="PaymentRequestHeader__main">
        <div className="PaymentRequestHeader__links">
          <GoBackButton />
        </div>
        <div className="PaymentRequestHeader__infos">
            <h1 className="PaymentRequestHeader__infos__name">
              {reference}
            </h1>
            {(phase && status === 'IN_PROGRESS') ? (
              <Badge variant={phase} title={t(getI18nPhase(phase))}>
                {t(getI18nPhase(phase))} {dayDiff}
              </Badge>
            ) : (
              <Badge variant={status} title={t(getI18nStatus(status))}>
                {t(getI18nStatus(status))}
              </Badge>
            )}
            {muted && (
              <div className="PaymentRequestHeader__infos__extra">
                <Icon name="bell-muted" />
              </div>
            )}
        </div>
      </div>
      <div className="PaymentRequestHeader__customer">
        <Link
          to={`/customer/${client.id}`}
          className="PaymentRequestHeader__customer__link"
        >
          <p className="PaymentRequestHeader__customer__name">
            {client.name}
          </p>
          <p className="PaymentRequestHeader__customer__reference">
            {client.reference}
          </p>
        </Link>
      </div>
    </div>
  );
};

export default PaymentRequestHeader;
