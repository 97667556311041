import type Misc from 'types/misc';

const getI18nAttributeType = (attributeType: Misc.AttributeType): string => {
  const i18nAttributeType = {
    free: 'selectors:attributes.free',
    clientAttribute: 'selectors:attributes.client',
    payrequestAttribute: 'selectors:attributes.payrequest',
    slidingDate: 'selectors:attributes.sliding-date',
    attributeSuggest: 'selectors:attributes.attribute-suggest',
    payment: 'selectors:attributes.payment',
    debt: 'selectors:attributes.customer-debt',
    managers: 'selectors:attributes.managers',
  };

  return i18nAttributeType[attributeType] || 'common:unknown';
};

export default getI18nAttributeType;
