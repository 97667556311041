import './index.scss';
import type { ContactSummaryStep } from 'types/models';
import type Misc from 'types/misc';
import HistoryStepContactsSentTo from './SentTo';
import HistoryStepContactsRecipients from './Recipients';
import HistoryStepContactsMissingRoles from './MissingRoles';

type Props = {
  contacts: ContactSummaryStep[] | null,
  missingRoles: string[] | null,
  type: Misc.TypeOfHistoryStep,
  action: 'missing-roles' | 'recipients' | 'sent-to',
  channel: Misc.Channel | null,
};

const HistoryStepContacts = (props: Props): JSX.Element => {
  const { contacts, missingRoles, type, action, channel } = props;

  return (
    <div className="HistoryStepContacts">
      {((!contacts || contacts?.length === 0) && action !== 'missing-roles') && (
        '--'
      )}
      {(action === 'recipients') && (
        <HistoryStepContactsRecipients
          contacts={contacts}
          type={type}
        />
      )}
      {(action === 'sent-to') && (
        <HistoryStepContactsSentTo
          contacts={contacts}
          channel={channel}
        />
      )}
      {action === 'missing-roles' && (
        <HistoryStepContactsMissingRoles
          missingRoles={missingRoles}
        />
      )}
    </div>
  );
};

export default HistoryStepContacts;
