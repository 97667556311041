import './index.scss';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import type { ReactPortal } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
import useIsMountedRef from 'hooks/useIsMountedRef';
import SearchInput from 'components/SearchInput';
import DataTableFilters from 'components/DataTable/Filters';
import Export from '../../Export';
import getFilterValueFunc from '../getFilterValue';
import FilterItems from '../Items';

type Props = {
  filtersList: Misc.FilterDeclarationItem[],
  currentFilters: Misc.Filter[],
  onSearchChange(newTerm: string | null): void,
  onApplyFilters(): void,
  onFilterChange(filter: Misc.Filter): void,
  onCancel(): void,
  container: Element,
  filtersSettings?: Misc.FiltersSettings,
  exportLink?: string,
  initialSearchTerm?: string,
};

const DataTableFiltersSidebar = (props: Props): ReactPortal => {
  const { t } = useTranslation();
  const {
    filtersList,
    currentFilters,
    onSearchChange,
    onApplyFilters,
    onCancel,
    onFilterChange,
    container,
    filtersSettings,
    exportLink,
    initialSearchTerm,
  } = props;

  const moreFiltersKeys = filtersSettings?.sideKeys;
  const apply = useRef<boolean>(false);
  const isMounted = useIsMountedRef();

  const handleChangeFilters = useCallback((filter: Misc.Filter) => {
    if (isMounted.current) {
      onFilterChange(filter);
      apply.current = true;
    }
  }, [onFilterChange, isMounted]);

  useEffect(() => {
    if (apply.current) {
      onApplyFilters();
      apply.current = false;
    }
  }, [apply, onApplyFilters]);

  const sidebarFilters = useMemo(() => (
    filtersList.filter((filter) => !moreFiltersKeys || !moreFiltersKeys.includes(filter.key))
  ), [filtersList, moreFiltersKeys]);

  const moreFilters = useMemo(() => (
    filtersList.filter((filter) => moreFiltersKeys && moreFiltersKeys.includes(filter.key))
  ), [filtersList, moreFiltersKeys]);

  const filterRenderer = useMemo((): Misc.FiltersRenderer => (
    {
      filters: sidebarFilters,
      currentFilters,
      getFilterValue: getFilterValueFunc(currentFilters),
      onChange: handleChangeFilters,
    }
  ), [currentFilters, handleChangeFilters, sidebarFilters]);

  return (
    ReactDOM.createPortal((
      <div className="FiltersSidebar">
        <div className="FiltersSidebar__search">
          <SearchInput
            placeholder={t('common:search-in-table')}
            initialSearchTerm={initialSearchTerm}
            onChange={onSearchChange}
          />
        </div>
        <div className="FiltersSidebar__filters">
          <FilterItems
            filtersSettings={filtersSettings}
            {...filterRenderer}
          />
        </div>
        {moreFilters.length > 0 && (
          <DataTableFilters
            filtersList={moreFilters}
            currentFilters={currentFilters}
            onValidate={onApplyFilters}
            onChange={onFilterChange}
            onCancel={onCancel}
          />
        )}
        {exportLink && <Export exportLink={exportLink} />}
      </div>
    ), container)
  );
};

export default DataTableFiltersSidebar;
