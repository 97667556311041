import config from 'config';

const getI18nActionSelectStatus = (status: string): string => {
  const statusToTranslate = config.STATUSES_ID.find((elem) => status === elem);
  if (!statusToTranslate) {
    return status;
  }

  return `common:select-status.${statusToTranslate.split('_').join('-')}`;
};

export default getI18nActionSelectStatus;
