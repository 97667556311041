import './index.scss';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import type { PayRequest } from 'types/models';
import { fetchOne, FetchOneParams } from 'api/pay-requests';
import organizationStore from 'stores/Organization';
import useFetch from 'hooks/useFetch';
import useToast from 'components/ToastNotification/useToast';
import ToastNotification from 'components/ToastNotification';
import PaymentRequestSide from './Side';
import PayRequestHeader from './Header';
import PaymentRequestContent from './Content';
import PaymentRequestContentGrouped from './Content/Grouped';

export type RouteParams = {
  payRequestRef?: string,
};

const PaymentRequest = (): JSX.Element | null => {
  const { payRequestRef } = useParams<RouteParams>();
  const { currentOrganization } = organizationStore;
  const [reloadTick, setReloadTick] = useState<number>(0);

  const { data: payRequestData, refetch } = useFetch<FetchOneParams, PayRequest>(
    {
      cacheKey: 'payRequest',
      organization: currentOrganization?.reference,
      reference: payRequestRef || '',
      withEav: true,
    },
    fetchOne,
  );

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const handleActionDone = useCallback(
    (message: string) => {
      showToast(message, 'success');
      setReloadTick(reloadTick + 1);
      refetch();
    },
    [showToast, reloadTick, refetch],
  );

  const handleActionError = useCallback(
    (message: string) => {
      showToast(message, 'error');
    },
    [showToast],
  );

  if (!payRequestRef) {
    return null;
  }

  return (
    <div className="PaymentRequest">
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
      <div className="PaymentRequest__content">
        <div className="PaymentRequest__content__main-view">
          <PayRequestHeader payRequest={payRequestData} />
          {payRequestData && !!payRequestData?.group && (
            <PaymentRequestContentGrouped
              payRequest={payRequestData}
              reloadTick={reloadTick}
              onActionDone={handleActionDone}
              onActionError={handleActionError}
            />
          )}
          {payRequestData && !payRequestData?.group && (
            <PaymentRequestContent
              payRequest={payRequestData}
              reloadTick={reloadTick}
              onActionDone={handleActionDone}
              onActionError={handleActionError}
            />
          )}
        </div>
        <PaymentRequestSide
          reloadTick={reloadTick}
          onActionDone={handleActionDone}
          onActionError={handleActionError}
          payRequestData={payRequestData}
        />
      </div>
    </div>
  );
};

export default observer(PaymentRequest);
