import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormSelect from 'components/FormSelect';
import FormControl from 'components/FormControl';

interface Props {
  isGroup: boolean,
  defaultName?: string,
  onChangeIsGroup: (newValue: boolean) => void,
}

const GroupedNamedInput: React.FC<Props> = ({ isGroup, onChangeIsGroup, defaultName }) => {
  const { t } = useTranslation();

  return (
    <FormFieldset>
      <FormGroup label={t('plans:plan-name')} mandatory className="GroupedNamedInput__is-group">
        <FormSelect
          name="group"
          className="GroupedNamedInput__is-group__input"
          defaultValue="0"
          withClearButton={false}
          value={isGroup ? '1' : '0'}
          selectOptions={[
            { label: t('plans:solo-plan'), value: '0' },
            { label: t('plans:grouped-plan'), value: '1' },
          ]}
          onSelect={(name, value) => { onChangeIsGroup(value === '1'); }}
        />
      </FormGroup>
      <FormGroup className="GroupedNamedInput__name">
        <FormControl
          placeholder={t('plans:placeholder.name')}
          className="GroupedNamedInput__name__text"
          name="name"
          defaultValue={defaultName}
        />
      </FormGroup>
    </FormFieldset>
  );
};

export default observer(GroupedNamedInput);
