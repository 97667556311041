import Request from 'utils/request';

export type FetchAllParams = {
  organization: string | undefined,
  isGroup: boolean,
};

const fetchAll = async ({ organization, isGroup }: FetchAllParams) => {
  if (!organization) {
    throw new Error('FetchAllSelectors: Missing organization.');
  }

  const result = await Request.get(
    `/organization/${organization}/association-plans/list?group=${isGroup ? 'true' : 'false'}`,
  );
  return result;
};

// eslint-disable-next-line import/prefer-default-export
export { fetchAll };
