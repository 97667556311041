import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { fetchAllFinances, FetchAllParams } from 'api/analysis';
import useFetch from 'hooks/useFetch';
import organizationStore from 'stores/Organization';
import type { AnalysisFinanceChartData } from 'types/models';
import Loading from 'components/Loading';
import GraphSection from '../GraphSection';
import NoDataGraph from '../NoDataGraph';
import TotalCollectedChart from './TotalCollectedChart';
import ReceivableChart from './ReceivableChart';

const Finance = (): JSX.Element => {
  const { currentOrganization } = organizationStore;
  const { t } = useTranslation();
  const { data, isLoading } = useFetch<FetchAllParams, AnalysisFinanceChartData>(
    {
      cacheKey: 'financeStat',
      organization: currentOrganization?.reference,
    },
    fetchAllFinances,
  );

  const totalCollectedIsEmpty = useMemo(() => {
    if (!data) {
      return false;
    }
    return !(
      data.totalCollectedByMonth.find((elem) => elem.amount !== 0)
      && data.totalCollectedForecastByMonth.find((elem) => elem.amount !== 0)
    );
  }, [data]);

  return (
    <div>
      <GraphSection
        title={t('analysis:finance.receivables-distribution')}
        description={t('analysis:finance.receivables-distribution-description')}
      >
        {isLoading && <Loading />}
        {data && data.amountByPhase.length > 0 && (
          <ReceivableChart data={data.amountByPhase} />
        )}
        {(!data?.amountByPhase || data.amountByPhase.length === 0) && !isLoading && (
          <NoDataGraph />
        )}
      </GraphSection>
      <GraphSection
        title={t('analysis:finance.total-collected')}
        description={t('analysis:finance.total-collected-description')}
      >
        {isLoading && <Loading />}
        {data && !totalCollectedIsEmpty && (
          <TotalCollectedChart data={data} />
        )}
        {(!data || totalCollectedIsEmpty) && !isLoading && (
          <NoDataGraph />
        )}
      </GraphSection>
    </div>
  );
};

export default observer(Finance);
