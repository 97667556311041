import type { TFunction } from 'i18next';
import type { Column } from 'react-table';
import type { PayRequestOrPayment } from 'types/models';
import formatIntlDate from 'utils/formatIntlDate';
import DocumentCell from './DocumentCell';
import SubjectCell from './SubjectCell';
import AmountCell from './AmountCell';
import BalanceCell from './BalanceCell';
import StatusPayRequestCell from './StatusPayRequestCell';
import StatusPaymentCell from './StatusPaymentCell';

type RowType = {
  row: { original: PayRequestOrPayment },
};

const DetailsColumns = (
  ct: TFunction,
  showEditPaymentModal: (reference: string) => void,
  showPaymentModal: (reference: string) => void,
  filterBalanced?: boolean | null,
): Column<PayRequestOrPayment>[] => ([
  {
    Header: ct('common:date') as string,
    accessor: 'date',
    disableSortBy: true,
    Cell: ({ row }: RowType) => (
      <div className="CustomerAccountDetails__columns__date">
        {formatIntlDate(row.original.date, 'dayMonthYearShort')}
      </div>
    ),
  },
  {
    Header: ct('common:reference') as string,
    accessor: 'reference',
    disableSortBy: true,
    Cell: ({ row }: RowType) => (
      <DocumentCell
        data={row.original}
        showPaymentModal={showPaymentModal}
      />
    ),
  },
  {
    Header: ct('payments:subject') as string,
    accessor: 'subject',
    disableSortBy: true,
    Cell: ({ row }: RowType) => (
      <SubjectCell data={row.original} />
    ),
  },
  {
    Header: ct('common:amount') as string,
    accessor: 'amount',
    disableSortBy: true,
    Cell: ({ row }: RowType) => !filterBalanced ? (
      <AmountCell data={row.original} withoutTooltip />
    ) : <AmountCell data={row.original} />,
  },
  {
    Header: ct('common:remaining') as string,
    accessor: 'amountNotAssigned',
    disableSortBy: true,
    Cell: ({ row }: RowType) => (
      <AmountCell data={row.original} withoutTooltip useAmountNotAssigned />
    ),
  },
  {
    Header: ct('common:status') as string,
    accessor: 'status',
    disableSortBy: true,
    Cell: ({ row }: RowType) => (
      row.original.entity === 'payRequest'
        ? <StatusPayRequestCell data={row.original} />
        : <StatusPaymentCell data={row.original} showEditPaymentModal={showEditPaymentModal} />
    ),
  },
  {
    Header: ct('common:balance') as string,
    accessor: 'balance',
    disableSortBy: true,
    Cell: ({ row }: RowType) => <BalanceCell data={row.original} />,
  },
]);

export default DetailsColumns;
