import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import Button from 'components/Button';
import Icon from 'components/Icon';
import PaymentEditModal from 'components/PaymentEditModal';

interface Props {
  reference: string,
  id: number,
  maxAmount: number,
  customerReference: string,
  onDone: (title: string, message: string) => void,
}

const RowActionAddPayment: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const {
    reference,
    customerReference,
    onDone,
    maxAmount,
    id,
  } = props;

  const [showEditPaymentModal, hideEditModal] = useModal(() => (
    <PaymentEditModal
      customerReference={customerReference}
      onDone={onDone}
      onClose={hideEditModal}
      maxAmount={maxAmount}
      assignedTo={reference}
      payRequestId={id.toString()}
    />
  ), [customerReference, id, maxAmount, onDone, reference]);

  return (
    <Button
      variant="list"
      onClick={showEditPaymentModal}
      className="RowActionAddPayment"
    >
      <Icon name="plus-circle" />
      {t('payment-requests:add-payment')}
    </Button>
  );
};

export default RowActionAddPayment;
