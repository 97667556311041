import './index.scss';
import { useTranslation } from 'react-i18next';
import type Errors from 'types/errors';
import type { PaymentPayRequest, Currency } from 'types/models';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import Button from 'components/Button';
import Icon from 'components/Icon';
import SelectPayRequestForCustomer from 'components/SelectPayRequestForCustomer';
import FormAmountCurrency from 'components/FormAmountCurrency';

type Props = {
  customerReference: string | null,
  errors?: Errors.Validation | null,
  index: number,
  defaultValue: PaymentPayRequest,
  amount: string | number | null,
  payRequestId?: string,
  currentCurrency?: Currency,
  onChangePayRequest(newValue: string, type: string, index: number): void,
  onRemovePayRequest(refOrindex: string | number): void,
};

const PayRequestItem = (props: Props): JSX.Element => {
  const {
    customerReference,
    index,
    errors,
    defaultValue,
    amount,
    payRequestId,
    currentCurrency,
    onChangePayRequest,
    onRemovePayRequest,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="PayRequestItem">
      <Button
        className="PayRequestItem__remove"
        variant="link"
        onClick={() => onRemovePayRequest(defaultValue.reference || index)}
      >
        <Icon name="minus-circle" />
      </Button>
      <FormFieldset>
        <FormGroup label={t('common:bill')}>
          {customerReference ? (
            <SelectPayRequestForCustomer
              name={`payRequests-reference-${index}`}
              defaultValue={defaultValue.reference || ''}
              onChange={(newReference: string) => {
                onChangePayRequest(newReference, 'reference', index);
              }}
              customer={customerReference}
              payRequestId={payRequestId}
              isInvalid={!!errors?.payRequest}
            />
          ) : (
            <div className="PayRequestItem__wrapper">
              <div className="PayRequestItem__wrapper__form">
                <FormControl
                  type="text"
                  name={`payRequests-reference-${index}`}
                  autoComplete="off"
                  defaultValue={defaultValue.reference || ''}
                  onChange={(newValue) => {
                    onChangePayRequest(newValue, 'reference', index);
                  }}
                  isInvalid={!!errors?.payRequest}
                />
              </div>
            </div>
          )}
        </FormGroup>
        <FormAmountCurrency
          amountFormName={`payRequests-amountAssigned-${index}`}
          defaultAmount={defaultValue.amountAssigned || ''}
          onChangeAmount={(newValue) => {
            onChangePayRequest(newValue, 'amountAssigned', index);
          }}
          currency={currentCurrency?.code}
          hasCurrencyDisabled
          amount={amount || ''}
        />
      </FormFieldset>
    </div>
  );
};

export default PayRequestItem;
