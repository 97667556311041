import type { TFunction } from 'i18next';

const getToggleActionsI18n = (t: TFunction, ct: TFunction, reference: string, hasGroup: boolean) => ({
  mute: {
    title: hasGroup ? t('payment-requests:actions.mute-group') : t('payment-requests:actions.mute'),
    confirmText: hasGroup ? t('payment-requests:actions.confirm.mute-group') : ct('payment-requests:actions.confirm.mute', { reference }),
    toastText: hasGroup ? ct('payment-requests:actions.toast.mute-group', { reference }) : ct('payment-requests:actions.toast.mute', { reference }),
  },
  unmute: {
    title: hasGroup ? t('payment-requests:actions.unmute-group') : t('payment-requests:actions.unmute'),
    confirmText: hasGroup ? ct('payment-requests:actions.confirm.unmute-group', { reference }) : ct('payment-requests:actions.confirm.unmute', { reference }),
    toastText: hasGroup ? ct('payment-requests:actions.toast.unmute-group', { reference }) : ct('payment-requests:actions.toast.unmute', { reference }),
  },
  remove: {
    title: t('payment-requests:remove'),
    confirmText: ct('payment-requests:actions.confirm.remove', { reference }),
    toastText: ct('payment-requests:actions.toast.remove', { reference }),
  },
  'mark-as-paid': {
    title: t('payment-requests:mark-as-paid'),
    confirmText: ct('payment-requests:actions.confirm.mark-as-paid', { reference }),
    toastText: ct('payment-requests:actions.toast.mark-as-paid', { reference }),
  },
  'cancel-mark-as-paid': {
    title: t('payment-requests:cancel-mark-as-paid'),
    confirmText: ct('payment-requests:actions.confirm.cancel-mark-as-paid', { reference }),
    toastText: ct('payment-requests:actions.toast.cancel-mark-as-paid', { reference }),
  },
  litigate: {
    title: t('payment-requests:set-litigate'),
    confirmText: ct('payment-requests:actions.confirm.set-litigate', { reference }),
    toastText: ct('payment-requests:actions.toast.set-litigate', { reference }),
  },
  'cancel-litigate': {
    title: t('payment-requests:cancel-litigate'),
    confirmText: ct('payment-requests:actions.confirm.cancel-litigate', { reference }),
    toastText: ct('payment-requests:actions.toast.cancel-litigate', { reference }),
  },
  cancel: {
    title: t('payment-requests:disable'),
    confirmText: ct('payment-requests:actions.confirm.disable', { reference }),
    toastText: ct('payment-requests:actions.toast.disable', { reference }),
  },
  activate: {
    title: t('payment-requests:reactivate'),
    confirmText: ct('payment-requests:actions.confirm.reactivate', { reference }),
    toastText: ct('payment-requests:actions.toast.reactivate', { reference }),
  },
});

export default getToggleActionsI18n;
