import './index.scss';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { PayRequestOrPayment } from 'types/models';
import type Misc from 'types/misc';
import getI18nPaymentType from 'utils/getI18nPaymentType';
import getI18nPaymentMethod from 'utils/getI18nPaymentMethod';
import getI18nPaymentStatus from 'utils/getI18nPaymentStatus';
import dateIsLastTwoDays from 'utils/dateIsLastTwoDays';
import useTooltip from 'components/Tooltip/useTooltip';
import Icon from 'components/Icon';
import Badge from 'components/Badge';

type Props = {
  data: PayRequestOrPayment,
  showPaymentModal(reference: string): void,
};

const CustomerAccountDetailsDocumentCell = ({ data, showPaymentModal }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { id, entity, date, reference, status } = data;
  const isMuted = entity === 'payRequest' && !!data.muted;
  const isRecent = useMemo(() => dateIsLastTwoDays(date), [date]);
  const isRejected = useMemo(() => (status === 'FAILED'), [status]);

  const tooltipPayRequest = useTooltip(
    <div className="CustomerAccountDetailsDocumentCell__tooltip">
      {t('common:open-invoice')}<br />{reference}
    </div>,
    'center',
    reference.length < 30,
  );

  const tooltipPayment = useTooltip(
    <div className="CustomerAccountDetailsDocumentCell__tooltip">
      {t('common:open-payment')}<br />{reference}
    </div>,
    'center',
    reference.length < 30,
  );

  return (
    <div className="CustomerAccountDetailsDocumentCell">
      <div className="CustomerAccountDetailsDocumentCell__reference">
        {isMuted && <Icon name="bell-muted" />}
        {entity === 'payRequest' && (
          <Link
            to={`/payment-request/${id}`}
            className="CustomerAccountDetailsDocumentCell__link"
            ref={tooltipPayRequest}
          >
            {reference}
          </Link>
        )}
        {entity === 'payment' && (
          <a
            role="button"
            onClick={() => { showPaymentModal(reference); }}
            className="CustomerAccountDetailsDocumentCell__link"
            ref={tooltipPayment}
          >
            {reference}
          </a>
        )}
      </div>
      {entity === 'payRequest' && (
        <div className="CustomerAccountDetailsDocumentCell__pay-request">
          {isRecent && <Badge variant="RECENT">{t('common:recent')}</Badge>}
          {isRejected ? t('common:reject') : t('common:debit')}
          {!data.group && (
            <span>{' '}- {t('payment-requests:solo')}</span>
          )}
        </div>
      )}
      {entity === 'payment' && (
        <div className="CustomerAccountDetailsDocumentCell__payment">
          {isRecent && <Badge variant="RECENT">{t('common:recent')}</Badge>}
          {isRejected ? t('common:reject') : t('common:credit')}
          {data.mean && (
            <span>{' '}- {t(getI18nPaymentMethod(data.mean ?? 'OTHER'))}</span>
          )}
          {data.type && t(getI18nPaymentType(data.type))}
          {data.type === 'PAYMENT' && (
            <span>{' '}- {t(getI18nPaymentStatus(status as Misc.PaymentStatus))}</span>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomerAccountDetailsDocumentCell;
