import './index.scss';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import type { CategoryValue } from 'types/models';
import organizationStore from 'stores/Organization';
import Button from 'components/Button';

type Props = {
  isSelected: boolean;
  data: CategoryValue,
};

const OrganizationItemWalletItem = ({ data, isSelected }: Props): JSX.Element => {
  const { toggleSelectedWallet } = organizationStore;
  const { id, value } = data;

  const classNames = classnames('OrganizationItemWalletItem', {
    'OrganizationItemWalletItem--selected': isSelected,
  });

  return (
    <Button
      variant="list"
      className={classNames}
      onClick={() => { toggleSelectedWallet(id); }}
    >
      {value}
    </Button>
  );
};

export default observer(OrganizationItemWalletItem);
