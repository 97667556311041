import './index.scss';
import React from 'react';
import type Errors from 'types/errors';
import FormControl from 'components/FormControl';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import type Misc from 'types/misc';

interface Props {
  listData: Misc.ValueLabel[],
  errors?: Errors.Validation | null,
}

const ModalRolesForm: React.FC<Props> = ({ listData, errors }) => (
  <div className="ModalRolesForm">
    <FormFieldset>
      {listData.map(({ value, label }) => (
        <FormGroup label={label} key={label} error={errors?.name}>
          <FormControl type="text" name={label} defaultValue={value !== label ? value : ''} />
        </FormGroup>
      ))}
    </FormFieldset>
  </div>
);

export default ModalRolesForm;
