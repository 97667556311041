import { runInAction, makeAutoObservable } from 'mobx';
import type Errors from 'types/errors';
import { fetchRights } from 'api/users';
import type { UserRights } from 'types/models';
import ResponseError from 'utils/errors';

const initialRights: UserRights[] | null = JSON.parse(
  window.localStorage.getItem('rights') || 'null',
);

class UserRightsStore {
  rights: UserRights[] | null = initialRights;

  error: ResponseError | null = null;

  constructor() {
    makeAutoObservable(this);
    this.getAll();
  }

  async getAll() {
    try {
      const userRights = await fetchRights();
      if (!userRights) {
        return;
      }

      window.localStorage.setItem('rights', JSON.stringify(userRights));

      runInAction(() => {
        this.rights = userRights;
      });
    } catch (error) {
      const { code, message, details } = error as Errors.Request;
      runInAction(() => {
        this.error = new ResponseError(code, message, details);
      });
    }
  }
}

export default new UserRightsStore();
export { UserRightsStore };
