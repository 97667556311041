import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Config from 'config';
import type Errors from 'types/errors';
import Alert from 'components/Alert';
import Button from 'components/Button';

interface Props {
  error: Errors.Request,
  onClose?: () => void,
  withDetails?: boolean,
}

const ErrorMessage: React.FC<Props> = ({ error, onClose, withDetails = false }) => {
  const { t } = useTranslation();

  const [showDetails, setShowDetails] = React.useState<boolean>(withDetails);
  const {
    code,
    message,
    details,
    trace,
  } = error;

  let displayMessage = t('errors:unknown-retry');
  if (code !== 500) {
    displayMessage = message;
  }
  if (code === 400) {
    displayMessage = t('errors:form-contains-errors');
  }
  if (code === 403) {
    displayMessage = t('errors:unauthorized');
  }
  if (code === 404 || code === 2) {
    displayMessage = t('errors:resource-not-found');
  }

  return (
    <div className="ErrorMessage">
      <Alert variant="error" onClose={onClose}>
        {displayMessage}
      </Alert>
      {((message || details) && showDetails) && (
        <div className="ErrorMessage__details">
          {(message && message !== displayMessage) && <p>{message}</p>}
          {details && <p>{details}</p>}
          <ol>
            {trace?.map((traceline) => (
              <li key={traceline.line}>
                {traceline.class && (
                  <code>{traceline.class}::{traceline.function}() on line {traceline.line}</code>
                )}
                {!traceline.class && (
                  <code>{traceline.file} on line {traceline.line}</code>
                )}
              </li>
            ))}
          </ol>
          <Button
            className="ErrorMessage__toggle-details"
            variant="link"
            onClick={() => { setShowDetails(false); }}
          >
            {t('common:close')}
          </Button>
        </div>
      )}
      {(!Config.IS_PROD && (message || details) && code !== 400 && !showDetails) && (
        <Button
          className="ErrorMessage__toggle-details"
          variant="link"
          onClick={() => { setShowDetails(true); }}
        >
          {t('errors:show-details')}
        </Button>
      )}
    </div>
  );
};

export default ErrorMessage;
