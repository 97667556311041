import './index.scss';
import { Switch } from 'react-router-dom';
import { AnalyzableRoute } from 'components/Routes';
import ReservedAccess from '../ReservedAccess';
import SettingsHeader from './Header';
import SettingsNav from './Nav';
import SettingsProfile from './Profile';
import SettingsCurrentOrganization from './CurrentOrganization';
import SettingsCollaborators from './Collaborators';
import SettingsAttributes from './Attributes';
import SettingsPlans from './Plans';
import SettingsSelectors from './Selectors';
import SettingsModels from './Models';
import SettingsCustomization from './Customization';

const Settings = (): JSX.Element => (
  <div className="Settings">
    <SettingsHeader />
    <div className="Settings__content">
      <div className="Settings__side-view">
        <SettingsNav />
      </div>
      <div className="Settings__main-view">
        <Switch>
          <AnalyzableRoute
            path="/preferences"
            component={SettingsProfile}
            exact
            routeName="Settings"
          />
          <AnalyzableRoute
            path="/preferences/organization"
            component={SettingsCurrentOrganization}
            exact
            routeName="Organization settings"
          />
          <AnalyzableRoute
            path="/preferences/collaborators"
            component={SettingsCollaborators}
            exact
            routeName="Collaborators settings"
          />
          <AnalyzableRoute
            path="/preferences/attributes"
            component={SettingsAttributes}
            exact
            routeName="Attributes settings"
          />
          <AnalyzableRoute
            path="/preferences/plans"
            component={SettingsPlans}
            exact
            routeName="Plans settings"
          />
          <AnalyzableRoute
            path="/preferences/selectors"
            component={SettingsSelectors}
            exact
            routeName="Selectors settings"
          />
          <AnalyzableRoute
            path="/preferences/models"
            component={SettingsModels}
            exact
            routeName="Models settings"
          />
          <AnalyzableRoute
            path="/preferences/customization"
            component={SettingsCustomization}
            exact
            routeName="Customization settings"
          />
          <AnalyzableRoute component={ReservedAccess} routeName="Reserved access" />
        </Switch>
      </div>
    </div>
  </div>
);

export default Settings;
