import './index.scss';
import { useContext, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type Misc from 'types/misc';
import type { HistoryStep } from 'types/models';
import { fetchHistory } from 'api/customers';
import type { FetchHistoryParams } from 'api/customers';
import useFetch from 'hooks/useFetch';
import organizationStore from 'stores/Organization';
import customerContext from 'pages/Customer/customerContext';
import Loading from 'components/Loading';
import Icon from 'components/Icon';
import CustomerLastActivitiesItem from './Item';

const CustomerLastActivities = (): JSX.Element => {
  const { data: customer, reloadTick } = useContext(customerContext);
  const customerId = customer?.id;
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;

  const {
    isLoading,
    data,
    refetch,
  } = useFetch<FetchHistoryParams, Misc.Listing<HistoryStep>>(
    {
      cacheKey: 'customerHistory',
      organization: currentOrganization?.reference,
      customerId,
      fetchOptions: {
        pageIndex: 0,
        pageSize: 5,
        filtering: [{
          name: 'type',
          value: 'sent_reminder,task_processed,sent_error_reminder,pay_request,payment,data',
        }],
      },
    },
    fetchHistory,
    { enabled: !!customerId, staleTime: 5000 },
  );

  useEffect(() => {
    refetch();
  }, [refetch, reloadTick]);

  const count = useMemo<number>(() => data?.pagination.totalRecords ?? 0, [data]);

  return (
    <div className="CustomerLastActivities">
      <div className="CustomerLastActivities__header">
        <h3 className="CustomerLastActivities__title">
          {t('common:last-activities')}
        </h3>
        {isLoading && <Loading hasNoText />}
        {count > 0 && (
          <div className="CustomerLastActivities__count">{count}</div>
        )}
        <Link
          to={`/customer/${customerId}/history`}
          className="CustomerLastActivities__link"
        >
          {t('common:all-activities')}
          <Icon name="chevron-right-small" className="CustomerLastActivities__link__icon" />
        </Link>
      </div>
      {!isLoading && count === 0 && (
        <p className="CustomerLastActivities__no-data">
          {t('common:no-activity')}
        </p>
      )}
      {(data && data.records.length > 0) && (
        <ul className="CustomerLastActivities__list">
          {data?.records.map((item) => (
            <CustomerLastActivitiesItem key={item.id} data={item} />
          ))}
        </ul>
      )}
    </div>
  );
};

export default observer(CustomerLastActivities);
