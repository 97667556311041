import './index.scss';
import { useCallback, useEffect, useMemo, useContext } from 'react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
import type { HistoryStep as HistoryStepType } from 'types/models';
import type { FetchActionsParams } from 'api/customers';
import { fetchActions } from 'api/customers';
import parseIsoDate from 'utils/parseIsoDate';
import organizationStore from 'stores/Organization';
import useFetch from 'hooks/useFetch';
import Collapse from 'components/Collapse';
import BlockSection from 'components/BlockSection';
import ErrorMessage from 'components/ErrorMessage';
import useToast from 'components/ToastNotification/useToast';
import ToastNotification from 'components/ToastNotification';
import HistoryStep from 'components/HistoryStep';
import customerContext from '../../../customerContext';

const CustomerActions = (): JSX.Element => {
  const { data, reloadTick, onActionDone } = useContext(customerContext);
  const customerId = data?.id;
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;

  const {
    data: steps,
    isLoading,
    error,
    refetch,
  } = useFetch<FetchActionsParams, Misc.Listing<HistoryStepType>>({
    cacheKey: 'customerActions',
    organization: currentOrganization?.reference,
    id: customerId,
  }, fetchActions);

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const pendingSteps = useMemo<HistoryStepType[]>(() => {
    if (!steps) {
      return [];
    }

    return steps.records.filter((step: HistoryStepType): boolean => {
      const stepAt = parseIsoDate(step.at.at);
      const now = new Date();
      return stepAt !== null && stepAt <= now;
    });
  }, [steps]);

  const futureSteps = useMemo<HistoryStepType[]>(() => {
    if (!steps) {
      return [];
    }

    return steps.records.filter((step: HistoryStepType): boolean => {
      const stepAt = parseIsoDate(step.at.at);
      const now = new Date();
      return stepAt === null || stepAt > now;
    });
  }, [steps]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, currentOrganization, reloadTick]);

  const handleActionDone = useCallback((message: string) => {
    if (onActionDone) {
      onActionDone(message);
    }
    refetch();
  }, [onActionDone, refetch]);

  const handleActionError = useCallback((message: string) => {
    showToast(message, 'error');
  }, [showToast]);

  const linkToPendingActions = useMemo(() => (
    queryString.stringify({
      client: customerId,
      period: 'today',
      type: ['task_to_do', 'reminder_to_do', 'contact_to_do', 'planned_reminder'],
    }, { arrayFormat: 'comma' })
  ), [customerId]);

  const linkToFuturesActions = useMemo(() => (
    queryString.stringify({
      client: customerId,
      period: 'futur',
      type: ['task_to_do', 'reminder_to_do', 'contact_to_do', 'planned_reminder'],
    }, { arrayFormat: 'comma' })
  ), [customerId]);

  return (
    <div className="CustomerActions">
      <Collapse
        isDefaultOpen={pendingSteps.length > 0}
        updateOpenWhenDefaultIsUpdated
        header={(
          <BlockSection
            title={t('clients:pending-actions')}
            count={pendingSteps.length}
            isLoading={isLoading}
          >
            <Link
              to={`/actions-future?${linkToPendingActions}`}
              className="CustomerActions__link"
            >
              {t('common:see-all-actions')}
            </Link>
          </BlockSection>
        )}
      >
        {error && <ErrorMessage error={error} />}
        <ul className="CustomerActions__list">
          {pendingSteps.slice(0, 5).map((step) => (
            <HistoryStep
              data={step}
              key={step.id}
              customerId={customerId}
              showRelativeDateRef
              onActionDone={handleActionDone}
              onActionError={handleActionError}
            />
          ))}
          {!isLoading && pendingSteps.length === 0 && (
            <li className="CustomerActions__no-data">
              {t('payment-requests:no-data-actions')}
            </li>
          )}
        </ul>
      </Collapse>
      <Collapse
        isDefaultOpen={false}
        updateOpenWhenDefaultIsUpdated
        header={(
          <BlockSection
            title={t('clients:future-actions')}
            count={futureSteps.length}
            isLoading={isLoading}
          >
            <Link to={`/actions-future?${linkToFuturesActions}`} className="CustomerActions__link">
              {t('common:see-all-actions')}
            </Link>
          </BlockSection>
        )}
      >
        {error && <ErrorMessage error={error} />}
        <ul className="CustomerActions__list">
          {futureSteps.map((step) => (
            <HistoryStep
              data={step}
              key={step.id}
              customerId={customerId}
              showRelativeDateRef
              onActionDone={handleActionDone}
              onActionError={handleActionError}
            />
          ))}
          {!isLoading && futureSteps.length === 0 && (
            <li className="CustomerActions__no-data">
              {t('payment-requests:no-data-actions')}
            </li>
          )}
        </ul>
      </Collapse>
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </div>
  );
};

export default observer(CustomerActions);
