const getI18nSelectorOption = (selector: string): string => {
  const i18nSelectorOption = [
    { value: '{PAYREQUEST_dishonouredAt}', label: 'selectors:select.dishonoured-at' },
    { value: '{PAYREQUEST_dueAt}', label: 'selectors:select.due-at' },
    { value: '{PAYREQUEST_amount}', label: 'selectors:select.amount' },
    { value: '{PAYREQUEST_reference}', label: 'selectors:select.payrequest-reference' },
    { value: '{PAYREQUEST_currency}', label: 'selectors:select.currency' },
    { value: '{PAYREQUEST_createdAt}', label: 'selectors:select.created-at' },
    { value: '{CLIENT_type}', label: 'selectors:select.type' },
    { value: '{CLIENT_reference}', label: 'selectors:select.client-reference' },
    { value: '{CLIENT_createdAt}', label: 'selectors:select.created-at' },
    { value: '{CLIENT_city}', label: 'selectors:select.city' },
    { value: '{CLIENT_country}', label: 'selectors:select.country' },
    { value: '{PAYREQUEST_groupDunning}', label: 'selectors:select.group-dunning' },
    { value: '{PAYREQUEST_totalDebtDue}', label: 'selectors:select.debt-total-due' },
    { value: '{PAYREQUEST_totalDebt}', label: 'selectors:select.debt-total' },
    { value: '{PAYMENT_status}', label: 'selectors:select.payment-status' },
  ];

  return i18nSelectorOption.find(({ value }) => value === selector)?.label || 'common:unknown';
};

export default getI18nSelectorOption;
