import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import type Misc from 'types/misc';
import normalizeActionName from 'utils/normalizeActionName';
import Modal from 'components/Modal';
import ErrorMessage from 'components/ErrorMessage';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import Loading from 'components/Loading';
import CountCharactersHelper from 'components/CountCharactersHelper';
import PDFViewer from 'components/PDFViewer';
import DownloadLink from 'components/DownloadLink';
import Icon from 'components/Icon';

type ReminderProps = {
  id: number,
  channel: Misc.Channel | null,
  group: boolean,
};

type Props = {
  contactId: number,
  reminder: ReminderProps,
  onClose(): void,
};

type ReminderData = {
  subject?: string | null,
  content: string,
  channel: string,
};

const ReminderPreview = ({ contactId, reminder, onClose }: Props): JSX.Element => {
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const nbCharactersSmsMax = 1072;
  const [reminderData, setReminderData] = useState<ReminderData>();
  const { get, isLoading, error, cancel } = useApiRequest();

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const fetchReminder = useCallback(async () => {
    const { id, channel, group } = reminder;

    if (!channel) {
      cancel();
      throw new Error('Action non définie.');
    }

    const response = await get<ReminderData | any>(
      `task/${group ? 'group/' : ''}${normalizeActionName(channel)}/${id}/${contactId}`,
    );

    if (isMountedRef.current && response) {
      setReminderData(response);
    }
  }, [get, isMountedRef, reminder, contactId, cancel]);

  useEffect(() => {
    fetchReminder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const channelAttributes = useMemo(() => {
    if (!reminder.channel) {
      throw new Error('Action non définie.');
    }

    const attributes = {
      EMAIL: {
        subTitle: t('clients:reminder-preview.email'),
        modifier: 'email',
      },
      SMS: {
        subTitle: t('clients:reminder-preview.sms'),
        modifier: 'sms',
      },
      LETTER: {
        subTitle: t('clients:reminder-preview.letter'),
        modifier: 'letter',
      },
      REGISTERED_LETTER: {
        subTitle: t('clients:reminder-preview.registered-letter'),
        modifier: 'letter',
      },
    };

    return attributes[reminder.channel];
  }, [t, reminder.channel]);

  const sanitizedContent = useMemo(() => (
    reminderData?.content ?? ''
  ), [reminderData]);

  const wrapperClassName = useMemo(() => classnames(
    'ReminderPreview__wrapper',
    `ReminderPreview__wrapper--${channelAttributes.modifier}`,
  ), [channelAttributes]);

  const isLetter = normalizeActionName(reminder.channel) === 'letter';

  return (
    <Modal
      isOpened
      className="ReminderPreview"
      title={`${t('common:preview')}`}
      subTitle={channelAttributes.subTitle}
      headerActions = {
        isLetter && (
          <DownloadLink withDownload={false} path={`task/download/letter/${reminder.id}/${contactId}`}>
            <Icon name='download' /> {t('common:download')}
          </DownloadLink>
        )
      }
      onClose={handleClose}
    >
      {error && (<ErrorMessage error={error} />)}
      {isLoading && <Loading />}
      {(!isLoading && reminderData && reminderData.content && isLetter) && (
        <div className={wrapperClassName}>
          <PDFViewer base64PDF={reminderData.content} />
        </div>
      )}
      {(!isLoading && reminderData && reminderData.content && !isLetter) && (
        <div className={wrapperClassName}>
          {reminder.channel === 'EMAIL' && (
            <div className="ReminderPreview__subject">
              {reminderData.subject ? reminderData.subject : t('common:object')}
            </div>
          )}
          <div
            className="ReminderPreview__content"
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{ __html: sanitizedContent }}
          />
          {reminder.channel === 'SMS' && (
            <CountCharactersHelper
              number={reminderData.content.length}
              max={nbCharactersSmsMax}
              className="ReminderPreview__helper"
            />
          )}
        </div>
      )}
    </Modal>
  );
};

export default observer(ReminderPreview);
