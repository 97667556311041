import React from 'react';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import type Misc from 'types/misc';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useApiRequest from 'hooks/useApiRequest';
import InputModal from 'components/InputModal';
import Button from 'components/Button';
import Icon from 'components/Icon';

interface Props {
  id: number,
  reference: string,
  defaultValue: string | null,
  onLoadingChange: (isLoading: boolean) => void,
  onDone: (title: string, message: string) => void,
}

const RowActionPromiseToPay: React.FC<Props> = (props) => {
  const { linesOfBusiness } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);

  const {
    id,
    reference,
    defaultValue,
    onLoadingChange,
    onDone,
  } = props;

  const { put, error, isLoading } = useApiRequest();

  const handleSubmit = React.useCallback(
    async (inputModalValue: { [field: string]: string }) => {
      const result = await put<Misc.IdRef>(
        `pay-requests/manage/${id}/promise-to-pay`,
        inputModalValue,
      );

      if (!result) {
        return;
      }

      onDone(
        t('payment-requests:promise-to-pay-defined'),
        ct('payment-requests:actions.toast.promise-to-pay-defined', { reference }),
      );
    },
    [put, id, onDone, t, ct, reference],
  );

  React.useEffect(
    () => { onLoadingChange(isLoading); },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading],
  );

  const [showInputModal, hideInputModal] = useModal(() => (
    <InputModal
      isShow
      title={t('payment-requests:edit-promise-to-pay')}
      help={ct('payment-requests:edit-promise-to-pay-for-ref', { reference })}
      type="date"
      name="date"
      defaultValue={defaultValue}
      isLoading={isLoading}
      error={error}
      minDate={new Date()}
      onSubmit={handleSubmit}
      onClose={hideInputModal}
      onCancel={hideInputModal}
    />
  ), [t, ct, reference, defaultValue, isLoading, error, handleSubmit]);

  return (
    <Button
      variant="list"
      onClick={showInputModal}
      className="RowActionPromiseToPay"
    >
      <Icon name="flash" />
      {t('payment-requests:edit-promise-to-pay')}
    </Button>
  );
};

export default observer(RowActionPromiseToPay);
