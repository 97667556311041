import type Misc from 'types/misc';

const getI18nRole = (roles: Misc.Role[]): string => {
  const i18nRoles = {
    ROLE_SUPER_ADMIN: 'common:roles.super-admin',
    ROLE_USER: 'common:roles.user',
    ROLE_DEMO: 'common:roles.demo',
    RECOVERY_ADIVSOR: 'common:roles.recovery-advisor',
    INTERNAL_ACCOUNTANT: 'common:roles.internal-accountant',
    INTERNAL_SALES: 'common:roles.internal-sales',
    ADMINISTRATOR: 'common:roles.administrator',
  };

  const firstRole = roles[0];
  return i18nRoles[firstRole];
};

export default getI18nRole;
