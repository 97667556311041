import './index.scss';
import { useMemo, useCallback } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import type { ReportItem } from 'types/models';
import useContextualTranslation from 'hooks/useContextualTranslation';
import organizationStore from 'stores/Organization';
import authStore from 'stores/Auth';
import getI18nReportType from 'utils/getI18nReportType';
import formatIntlDate from 'utils/formatIntlDate';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import Status from './Status';
import ShowErrors from './ShowErrors';

type Props = {
  variant?: 'error' | 'warning' | 'in-progress' | 'default',
  className?: string,
  report: ReportItem,
  onCancel?(importLogId: number): void,
};

const ImportsReportsItem = ({ variant, className, report, onCancel }: Props): JSX.Element => {
  const { date, files, format, type, nbErrorsByFile, nbErrorsByLine } = report;
  const { linesOfBusiness, isSageOrganization } = organizationStore;
  const { isSuperAdmin } = authStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);
  const isInProgress = variant === 'in-progress';

  const classNames = classnames('ImportsReportsItem', {
    'ImportsReportsItem--warning': variant === 'warning',
    'ImportsReportsItem--in-progress': isInProgress,
    'ImportsReportsItem--error': variant === 'error',
  }, className);

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const handleShowCancel = useCallback(() => {
    showConfirm(
      t('reports:actions.confirm.cancel.title'),
      t('reports:actions.confirm.cancel.text'),
    );
  }, [showConfirm, t]);

  const handleCancelImport = useCallback(() => {
    if (onCancel) {
      const importLogId = files[0].id;
      onCancel(importLogId);
    }
    hideConfirm();
  }, [files, onCancel, hideConfirm]);

  const canCancel = useMemo(() => (
    isInProgress
    && isSuperAdmin
    && onCancel
  ), [isInProgress, isSuperAdmin, onCancel]);

  const getButtonVariant = useMemo(() => {
    if (variant === 'default') {
      return 'outline';
    }
    return (variant === 'error') ? 'danger' : 'black';
  }, [variant]);

  const reportIcon = useMemo<JSX.Element>(() => {
    if (variant === 'error') {
      return <Icon name="warning-circle" />;
    }
    if (isInProgress) {
      return <Loading hasNoText />;
    }
    return <Icon name="check-circle" />;
  }, [variant, isInProgress]);

  const reportDate = useMemo(() => {
    // - Cette ligne sert à supprimer le suffixe introduit par l'API
    //   @see https://gitlab.com/dunforce/api/-/issues/1055#note_1124775346
    const safeDate = date.replace(/_\d+$/, '');
    return formatIntlDate(safeDate, 'dateTimeLong');
  }, [date]);

  const [countCreated, countUpdated, countTotal] = useMemo(() => (
    files.reduce(
      ([_countCreated, _countUpdated, _countTotal], { nbLineCreated, nbLineUpdated, nbLine }) => [
        (_countCreated + nbLineCreated),
        (_countUpdated + nbLineUpdated),
        (_countTotal + nbLine),
      ],
      [0, 0, 0],
    )
  ), [files]);

  const hasDraft = useMemo(() => (
    (variant === 'error' || variant === 'warning')
      && format === 'pdf'
      && files.some(({ nbLineDraft }) => nbLineDraft)
  ), [format, files, variant]);

  // Logique sage a retirer quand le back sera fixé
  const shouldShowError = useMemo(() => (
    format === 'pdf'
    && files.length === 1
    && (variant !== 'in-progress' && variant !== 'default')
  ), [format, files, variant]);

  return (
    <div className={classNames}>
      <div className="ImportsReportsItem__icon">
        {reportIcon}
      </div>
      <div className="ImportsReportsItem__infos">
        <p className="ImportsReportsItem__infos__type">
          {[
            t('reports:import'),
            t(getI18nReportType(type, 'short')),
            format === 'pdf' ? 'PDF' : 'CSV',
          ].join(' ')}
        </p>
        <p className="ImportsReportsItem__infos__subtitle">
          {ct(getI18nReportType(type))},{' '}
          {t('reports:file', { count: files.length })}
          {files.length === 1 && `, ${t('reports:line', { count: files[0].nbLine })}`}
        </p>
      </div>
      <div className="ImportsReportsItem__filename">
        <p className="ImportsReportsItem__filename__text">
          {files.length === 1 ? files[0]?.name : ''}
        </p>
      </div>
      <div className="ImportsReportsItem__status">
        <Status
          // TODO: isSageOrganization et type à retirer quand l'API sera corrigé
          countCriticalError={isSageOrganization && format === 'pdf' ? 0 : nbErrorsByFile}
          // TODO: isSageOrganization et type à retirer quand l'API sera corrigé
          countError={isSageOrganization && format === 'pdf' ? 0 : nbErrorsByLine}
          countCreated={countCreated}
          countUpdated={countUpdated}
          countTotal={countTotal}
          type={type}
          inProgress={isInProgress}
        />
        {shouldShowError && (
          <ShowErrors fileId={files[0].id} />
        )}
      </div>
      <div className="ImportsReportsItem__action">
        <p className="ImportsReportsItem__action__date">
          {isInProgress && t('reports:started-at') + ' '}
          {reportDate}
        </p>
        {hasDraft && (
          <Button href="/payment-requests/draft" variant={(variant === 'error' && 'outline') || undefined}>
            {t('common:complete')}
          </Button>
        )}
        {canCancel && (
          <Button variant="outline" onClick={handleShowCancel}>
            {t('common:cancel')}
          </Button>
        )}
        {!isInProgress && (
          <Link
            to={{ pathname: '/imports/report', state: { report } }}
            className="ImportsReportsItem__action__link"
          >
            <Button variant={getButtonVariant}>
              {hasDraft ? t('reports:report') : t('reports:see-report')}
            </Button>
          </Link>
        )}
      </div>
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        isShow={isConfirmShowed}
        onConfirm={handleCancelImport}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={t('')}
      />
    </div>
  );
};

export default observer(ImportsReportsItem);
