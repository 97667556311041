import type Misc from 'types/misc';
import { WebHookEmail, WebHookLetter, WebHookSMS } from 'types/models';
type I18nEmail = Record<Misc.ActionWebHookEmail, string>;
type I18nLetter = Record<Misc.ActionWebHookLetter, string>;
type I18nSMS = Record<Misc.ActionWebHookSMS, string>;

const getI18nActionWebHook = (
  channel: Misc.Channel | null,
  event: WebHookSMS | WebHookLetter | WebHookEmail,
): string => {
  if (channel === null) {
    return '';
  }

  const i18nEmail: I18nEmail = {
    Bounce: 'actionsWebHooks:email.bounce',
    Click: 'actionsWebHooks:email.click',
    Delivery: 'actionsWebHooks:email.delivery',
    Open: 'actionsWebHooks:email.open',
    Reject: 'actionsWebHooks:email.reject',
    Send: 'actionsWebHooks:email.sent',
  };

  const i18nLetter: I18nLetter = {
    'letter.created': 'actionsWebHooks:letter.created',
    'letter.filing_proof': 'actionsWebHooks:letter.filing_proof',
    'letter.sent': 'actionsWebHooks:letter.sent',
    'letter.error': 'actionsWebHooks:letter.error',
    'letter.in_transit': 'actionsWebHooks:letter.in_transit',
    'letter.waiting_to_be_withdrawn': 'actionsWebHooks:letter.waiting_to_be_withdrawn',
    'letter.distributed': 'actionsWebHooks:letter.distributed',
    'letter.returned_to_sender': 'actionsWebHooks:letter.returned_to_sender',
    'letter.delivery_proof': 'actionsWebHooks:letter.delivery_proof',
    'letter.wrong_address': 'actionsWebHooks:letter.wrong_address',
    'send_error': 'actionsWebHooks:letter.send_error',
  };

  const i18nSMS: I18nSMS = {
    ERROR: 'actionsWebHooks:sms.error',
    EXPIRED: 'actionsWebHooks:sms.expired',
    FAILED: 'actionsWebHooks:sms.failed',
    RECEIVED: 'actionsWebHooks:sms.received',
    SENT: 'actionsWebHooks:sms.sent',
  };

  switch (channel) {
    case 'EMAIL':
      return i18nEmail[event.type as Misc.ActionWebHookEmail] ?? '';
    case 'REGISTERED_LETTER':
    case 'LETTER':
      return i18nLetter[event.type as Misc.ActionWebHookLetter] ?? '';
    case 'SMS':
      if (event.type === 'sms.mt.status_update') {
        return i18nSMS[event.status as Misc.ActionWebHookSMS] ?? '';
      }
      return '';
    default:
      return '';
  }
};

export default getI18nActionWebHook;
