import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import type { TFunction } from 'i18next';
import type { Column } from 'react-table';
import type { CreditNote } from 'types/models';
import organizationStore from 'stores/Organization';
import formatIntlDate from 'utils/formatIntlDate';
import formatIntlNumber from 'utils/formatIntlNumber';
import getI18nPaymentMethod from 'utils/getI18nPaymentMethod';

const CreditNotesColumns = (
  t: TFunction,
): Column<CreditNote>[] => [
  {
    Header: t<string>('common:credit-note-reference'),
    accessor: 'reference',
    Cell: ({ value }) => (
      <div className="CreditNotes__columns__reference">
        {value}
      </div>
    ),
  },
  {
    Header: t<string>('common:bill'),
    accessor: 'payRequest',
    Cell: ({ value }) => (
      <div className="CreditNotes__columns__pay-request">
        <Link
          to={`/payment-request/${value.id}`}
          className="CreditNotes__columns__link"
        >
          {value.reference}
        </Link>
      </div>
    ),
  },
  {
    Header: t<string>('common:client'),
    accessor: 'client',
    Cell: ({ value }) => (
      <div className="CreditNotes__columns__client">
        <Link to={`/customer/${value.id}`}>{value.name}</Link>
        {(value.name !== value.reference && `${value.reference} ${value.reference}` !== value.name) && (
          <div className="CreditNotes__columns__client__ref">
            {value.reference}
          </div>
        )}
      </div>
    ),
  },
  {
    Header: t<string>('common:amount'),
    accessor: 'amount',
    sortType: 'basic',
    sortDescFirst: true,
    Cell: ({ row, value }) => (
      <Fragment>
        {formatIntlNumber(value, row.original.currency || organizationStore.currency)}
      </Fragment>
    ),
  },
  {
    Header: t<string>('payments:payment-method'),
    accessor: 'mean',
    sortDescFirst: true,
    Cell: ({ value }) => (
      <Fragment>
        {t(getI18nPaymentMethod(value))}
      </Fragment>
    ),
  },
  {
    Header: t<string>('common:created-at'),
    accessor: 'at',
    sortType: 'datetime',
    sortDescFirst: true,
    Cell: ({ value }) => (
      <Fragment>
        {formatIntlDate(value, 'dateLong')}
      </Fragment>
    ),
  },
];

export default CreditNotesColumns;
