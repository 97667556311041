import './index.scss';
import { Fragment } from 'react';
import type { PayRequest } from 'types/models';
import PaymentRequestSideActions from './Actions';
import PaymentRequestNotes from './Notes';
import PaymentRequestContacts from './Contacts';
import PaymentRequestInfos from './Infos';

export type Props = {
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
  payRequestData: PayRequest | null,
  reloadTick: number,
};

const PaymentRequestSide = (props: Props): JSX.Element => {
  const { onActionDone, onActionError, payRequestData, reloadTick } = props;

  return (
    <div className="PaymentRequestSide">
      {payRequestData && (
        <Fragment>
          <PaymentRequestSideActions
            data={payRequestData}
            onError={onActionError}
            onDone={onActionDone}
          />
          <PaymentRequestContacts customerId={payRequestData.client.id} />
          <PaymentRequestNotes
            paymentRef={payRequestData.reference}
            reloadTick={reloadTick}
            paymentId={payRequestData.id}
            onDone={onActionDone}
          />
          <PaymentRequestInfos data={payRequestData} />
        </Fragment>
      )}
    </div>
  );
};

export default PaymentRequestSide;
