import type { TFunction } from 'i18next';
import type Misc from 'types/misc';

const ContactsFilters = (
  t: TFunction,
  ct: TFunction,
  allRoles: Misc.IdentifierValue[] | null,
  defaultClient?: string,
): Misc.FilterDeclarationItem[] => [
  {
    title: t('contacts:role'),
    emptyText: t('contacts:all-roles'),
    key: 'role',
    type: 'select',
    isFullWidth: true,
    selectData: allRoles?.map(({ identifier, value }) => (
      { value: identifier, label: value }
    )),
  },
  {
    title: ct('common:client'),
    emptyText: ct('payment-requests:filters.all-clients'),
    key: 'client',
    type: 'selectClient',
    isFullWidth: true,
    defaultValue: defaultClient,
  },
  {
    title: t('common:searching'),
    key: 'search',
    type: 'custom',
    isHidden: true,
  },
];

export default ContactsFilters;
