import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type Errors from 'types/errors';
import type { ActionPost } from 'types/models';
import getI18nBeforeAndAfterDateReference from 'utils/getI18nBeforeAndAfterDateReference';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormGroupWithActions from 'components/FormGroupWithActions';
import TagLink from 'components/TagLink';
import type Misc from 'types/misc';
import FormSelect from 'components/FormSelect';
import getComparableString from '../../utils';

interface Props {
  defaultData: ActionPost | null,
  errors?: Errors.Validation | null,
  dateReference: Misc.PlanDateReference,
}

const PlanModalTaskForm: React.FC<Props> = ({ defaultData, errors, dateReference }) => {
  const { t } = useTranslation();

  const [name, setName] = React.useState<string | undefined>(defaultData?.name || '');
  const [description, setDescription] = React.useState<string | undefined>(
    defaultData?.description || '',
  );
  const oldDefaultData = React.useRef(getComparableString(defaultData));

  React.useEffect(() => {
    if (!defaultData || oldDefaultData.current === getComparableString(defaultData)) {
      return;
    }
    oldDefaultData.current = getComparableString(defaultData);
    setName(defaultData.name || '');
    setDescription(defaultData.description || '');
  }, [defaultData]);

  const selectOptions = React.useMemo(() => [
    {
      label: t(getI18nBeforeAndAfterDateReference('after', dateReference)),
      value: `after-${dateReference}`,
    },
    {
      label: t(getI18nBeforeAndAfterDateReference('before', dateReference)),
      value: `before-${dateReference}`,
    },
  ], [dateReference, t]);

  return (
    <div className="PlanModalTaskForm">
      <FormFieldset>
        <div className="PlanModalTaskForm__days-group">
          <FormGroup label={t('plans:planned')} mandatory error={errors?.startAt}>
            <FormControl
              type="number"
              min={0}
              name="days"
              defaultValue={defaultData?.day !== undefined ? Math.abs(defaultData?.day) : undefined}
              isInvalid={!!errors?.startAt}
            />
            <p className="PlanModalTaskForm__days-group__label">{t('common:days')}</p>
          </FormGroup>
        </div>
        <FormGroup mandatory error={errors?.date}>
          <FormSelect
            name="date"
            selectOptions={selectOptions}
            isInvalid={!!errors?.date}
            defaultValue={defaultData && defaultData.day < 0 ? `before-${dateReference}` : `after-${dateReference}`}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('common:name')} mandatory error={errors?.name}>
          <FormControl
            type="text"
            name="name"
            autoComplete="off"
            value={name}
            onChange={setName}
            isInvalid={!!errors?.name}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroupWithActions
          label={t('reminders:description')}
          mandatory
          error={errors?.description}
          action={<TagLink />}
        >
          <FormControl
            type="text"
            name="description"
            autoComplete="off"
            value={description}
            onChange={setDescription}
            isInvalid={!!errors?.description}
          />
        </FormGroupWithActions>
      </FormFieldset>
    </div>
  );
};

export default PlanModalTaskForm;
