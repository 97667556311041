import './index.scss';
import { useTranslation } from 'react-i18next';
import { Category } from 'types/models';
import DropdownActions from 'components/DropdownActions';

type Props = {
  perimeter: Category[],
  actions?: React.ReactNode[],
};

const Perimeter = ({ perimeter, actions }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="Perimeter">
      <div className="Perimeter__categories">
        {perimeter.map(({ identifier, name, values: possibleValues }, index) => (
          <div key={identifier} className="Perimeter__category">
            <div className="Perimeter__category__operator">{ index > 0 && t('common:and')}</div>
            <div className="Perimeter__category__title">{name}</div>
            <div className="Perimeter__category__values">
              {possibleValues.map(({ value }) => value).join(' / ') }
            </div>
          </div>
        ))}
      </div>
      {actions && (
        <div className="Perimeter__menu">
          <DropdownActions actions={actions} />
        </div>
      )}
    </div>
  );
};

export default Perimeter;
