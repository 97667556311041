import { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useModal } from 'react-modal-hook';
import CustomerEditModal from 'components/CustomerEdit';

export interface RouteParams {
  editType?: 'new' | 'edit',
  customerId: string,
}

type Props = {
  onDone(message: string, reference: string): void,
  finallyRedirectTo?: string,
};

const CustomerEdit = (props: Props): JSX.Element | null => {
  const history = useHistory();
  const { editType, customerId } = useParams<RouteParams>();

  const handleClose = useCallback(() => {
    if (customerId) {
      history.replace(`/customer/${customerId}`);
    }
  }, [customerId, history]);

  const [showModal, hideModal] = useModal(() => (
    <CustomerEditModal
      {...props}
      editType={editType}
      customerId={Number.parseInt(customerId, 10)}
      onClose={() => { hideModal(); handleClose(); }}
    />
  ), [editType, customerId, props, handleClose]);

  useEffect(() => {
    showModal();

    return () => {
      hideModal();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default CustomerEdit;
