import type { TFunction } from 'i18next';
import type Misc from 'types/misc';

const getI18nLineOfBusiness = (t: TFunction, lineOfBusiness: Misc.LinesOfBusiness) => {
  if (lineOfBusiness === 'DEFAULT') {
    return '--';
  }
  return t(`organizations:lines-of-business.${lineOfBusiness}`);
};

export default getI18nLineOfBusiness;
