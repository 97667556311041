import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import config from 'config';
import useApiRequest from 'hooks/useApiRequest';
import formatEavsValues from 'utils/formatEavsValues';
import type Error from 'types/errors';
import type { PayRequestDraft } from 'types/models';
import organizationStore from 'stores/Organization';
import Loading from 'components/Loading';
import Button from 'components/Button';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import ErrorMessage from 'components/ErrorMessage';
import DraftInvoiceForm from './Form';

interface Props {
  onClose: () => void,
  data: PayRequestDraft,
  isLoading?: boolean,
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
}

const DraftInvoiceModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { currentOrganization, eavs } = organizationStore;

  const {
    put,
    post,
    error,
  } = useApiRequest();

  const [validationErrors, setValidationErrors] = React.useState<Error.Validation | null>(
    null,
  );
  const [pdf, setPDF] = React.useState<string | null>(null);

  const {
    onClose,
    data,
    isLoading,
    onActionDone,
  } = props;

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const handleConfirmDelete = React.useCallback(() => {
    showConfirm(t('common:remove'), t('payrequests-draft:actions.confirm.remove'));
  }, [showConfirm, t]);

  const handleSubmitDelete = React.useCallback(async () => {
    hideConfirm();
    const response = await put(
      `/import/manage/${currentOrganization?.reference}/${data.importId}/disable-draft`,
    );
    if (response?.errors) {
      setValidationErrors(response.errors);
      return;
    }
    if (response) {
      onClose();
      onActionDone(t('payrequests-draft:actions.toast.deleted'));
    }
  }, [hideConfirm, put, currentOrganization, data, onClose, onActionDone, t]);

  const handleSubmit = React.useCallback(async (formData: ModalFormData | null) => {
    if (!formData || !currentOrganization) {
      return;
    }
    const body = {
      customerReference: formData.customerReference,
      currency: formData.currency,
      startAt: formData.startAt,
      dueAt: formData.dueAt,
      active: true,
      reference: formData.reference,
      subject: formData.subject,
      templateDunningPlan: formData.templateDunningPlan,
      importId: data.importId,
      receipt: pdf,
      eavs: formatEavsValues(eavs.payRequest, formData),
      groupDunning: formData.groupDunning !== undefined && formData.groupDunning !== '0',
      sendDispatches: formData.sendDispatches !== undefined && formData.sendDispatches !== '0',
      users: (formData.users as string).split(',').map((user) => ({ identifier: user })),
      amount: formData.amount ? Number(formData.amount) : null,
    };
    const response = await post(
      `/pay-requests/${currentOrganization.reference}/import/create`,
      body,
    );
    if (response?.errors) {
      setValidationErrors(response.errors);
      return;
    }
    if (response) {
      onClose();
      onActionDone(t('payrequests-draft:actions.toast.reimported'));
    }
  }, [currentOrganization, data, pdf, eavs, post, onClose, onActionDone, t]);

  const getPDF = React.useCallback(async () => {
    const authToken = window.localStorage.getItem('token');
    const url = `${config.API_URL}download/import/${data?.importId}?access_token=${authToken}`;
    const result = await axios.get<{ data: { content: string } } | null>(url);
    setPDF(result?.data?.data?.content || '');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    getPDF();
  }, [getPDF]);

  return (
    <>
      <ModalForm
        isOpened
        title={t('payrequests-draft:invoice-to-complete')}
        isLoading={isLoading}
        onCancel={onClose}
        className="DraftInvoiceModal"
        onSave={handleSubmit}
        headerActions={(
          <Button variant="link" onClick={handleConfirmDelete}>
            {t('payrequests-draft:delete-invoice')}
          </Button>
        )}
      >
        {error && <ErrorMessage error={error} />}
        {isLoading && <Loading />}
        {!isLoading && (
          <DraftInvoiceForm
            data={data}
            errors={validationErrors}
            pdf={pdf}
            onActionDone={onActionDone}
          />
        )}
      </ModalForm>
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitDelete}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={t<string>('common:remove')}
      />
    </>
  );
};

export default observer(DraftInvoiceModal);
