import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useModal } from 'react-modal-hook';
import PaymentEditModal from 'components/PaymentEditModal';

export type RouteParams = {
  editType: 'new' | 'edit',
  reference?: string,
};

type Props = {
  onDone: (title: string, message: string) => void,
  onClose(): void,
};

const PaymentEdit = ({ onDone, onClose }: Props): JSX.Element | null => {
  const { reference } = useParams<RouteParams>();

  const [showModal, hideModal] = useModal(() => {
    const handleClose = () => {
      hideModal();
      onClose();
    };

    return (
      <PaymentEditModal
        reference={reference}
        onDone={onDone}
        onClose={handleClose}
      />
    );
  }, [reference, onDone, onClose]);

  useEffect(() => {
    showModal();
    return () => {
      hideModal();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default PaymentEdit;
