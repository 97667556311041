import './index.scss';
import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PublicLayout from 'layout/PublicLayout';
import type Errors from 'types/errors';
import useApiRequest from 'hooks/useApiRequest';
import type { ModalFormData } from 'components/ModalForm';
import Alert from 'components/Alert';
import ErrorMessage from 'components/ErrorMessage';
import authStore from 'stores/Auth';
import SignUpForm from './Form';

const SignUp = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isLogged, isBusy } = authStore;
  const [validationError, setValidationError] = useState<Errors.Validation | null>(null);

  const { post, error, resetError } = useApiRequest();

  useEffect(() => {
    if (isLogged) {
      history.replace('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  const handleSubmit = useCallback(async (formData: ModalFormData | null) => {
    setValidationError(null);
    if (!formData) {
      return;
    }
    resetError();
    const result = await post('/account/new', formData);
    if (error || !result || result?.errors) {
      setValidationError(result?.errors || null);
      return;
    }
    history.push(`/confirm-email?email=${formData?.email}`);
  }, [error, history, post, resetError]);

  return (
    <PublicLayout withLanguageSelection>
      <div className="SignUp">
        <div className="SignUp__form">
          <h2 className="SignUp__title">{t('register:title')}</h2>
          <p className="SignUp__login">
            {t('register:already-have-account')}
            <Link to="/login" className="SignUp__login__link">
              {t('register:login')}
            </Link>
          </p>
          {(error && error.code === 7) && (
            <Alert variant="error">
              {t('register:error-wrong-credentials')}
            </Alert>
          )}
          {(error && error.code !== 401 && error.code !== 403 && error.code !== 7) && (
            <ErrorMessage error={error} />
          )}
          <SignUpForm
            onSubmit={handleSubmit}
            isLoading={isBusy}
            errors={validationError}
          />
        </div>
      </div>
    </PublicLayout>
  );
};

export default observer(SignUp);
