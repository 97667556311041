import './index.scss';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import type { ReportItem } from 'types/models';
import Header from './Header';
import Infos from './Infos';
import Details from './Details';

type RouteState = {
  report?: ReportItem,
};

type Props = {
  onChangeReport(report: ReportItem): void,
};

const Report = ({ onChangeReport }: Props): JSX.Element | null => {
  const { state } = useLocation<RouteState>();
  const { report } = state ?? { state: { report: null } };

  useEffect(() => {
    if (report) {
      onChangeReport(report);
    }
  }, [onChangeReport, report]);

  return (
      <div className="Report">
        <Header />
        <Infos />
        <Details />
      </div>
  );
};

export default Report;
