import type { TFunction } from 'i18next';
import DataTableFilterItem from 'components/DataTable/Filters/Item';
import type Misc from 'types/misc';
import getI18nActionSelectStatus from 'utils/getI18nActionSelectStatus';
import CollaboratorsFilters, { COLLABORATORS_FILTERS } from './CollaboratorsFilters';
import ReminderFilters, { REMINDERS_FILTERS } from './ReminderFilters';

const showAllOrFilters = (
  filter: Misc.Filter,
  badgeAllValues: string[],
  filterValues: string[],
  label: string,
  allSelectedText: string,
  key: string,
  t: TFunction,
): Misc.CurrentFilterItem | null => {
  const badgeValues = [...filterValues.filter(
    (filterValue) => badgeAllValues.includes(filterValue),
  )];

  const badgeLabel = Array.isArray(filter.value)
    ? filter.value?.map((value) => t(getI18nActionSelectStatus(value))).join(', ')
    : t(getI18nActionSelectStatus(filter.value || 'reminder_to_do'));

  if (badgeAllValues.every((value) => badgeValues.includes(value))) {
    return {
      value: allSelectedText,
      name: filter.name,
      getRemovedValue: () => [...filterValues.filter(
        (filterValue) => !badgeAllValues.includes(filterValue),
      )],
      key: `${filter.name}-${key}`,
    };
  }

  if (badgeAllValues.some((value) => badgeValues.includes(value))) {
    return {
      label,
      value: badgeLabel,
      name: filter.name,
      getRemovedValue: () => [...filterValues.filter(
        (filterValue) => !badgeAllValues.includes(filterValue),
      )],
      key: `${filter.name}-${key}`,
    };
  }

  return null;
};

const actionsFiltersSettings: Misc.FiltersSettings = {
  sideKeys: [
    'channel',
    'dunningPlan',
    'amountDueFrom',
    'amountDueTo',
    'dueDebtFrom',
    'dueDebtTo',
    'attribute',
    'attributeValue',
  ],
  renderFilters: (filtersRenderer) => {
    const { filters, getFilterValue } = filtersRenderer;

    const showDate = getFilterValue('period') === 'customized';
    const typeFilters = filters.find((filter) => filter.key === 'type');

    if (!typeFilters) {
      return <></>;
    }

    const filtersRendered = [
      (
        <CollaboratorsFilters
          key={`${typeFilters.key}-collaborators`}
          filter={typeFilters}
          {...filtersRenderer}
        />
      ),
      (
        <ReminderFilters
          key={`${typeFilters.key}-reminder`}
          filter={typeFilters}
          {...filtersRenderer}
        />
      ),
      ...filters.filter(
        (filter) => (((filter.key !== 'periodA' && filter.key !== 'periodB') || showDate) && filter.key !== 'type'),
      ).map((filter) => (
        <DataTableFilterItem
          key={filter.key}
          filter={filter}
          {...filtersRenderer}
        />
      )),
    ];

    return <>{filtersRendered}</>;
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCurrentFilters: (getFilterLabel, filtersList, activeFilters, t) => {
    let filters = activeFilters;
    if (!activeFilters.find(({ name, value }) => (name === 'period' && value === 'customized'))) {
      filters = activeFilters.filter(({ name }) => !(name === 'periodA' || name === 'periodB'));
    }
    const badges = filters.filter(
      ({ name, value }) => (name !== 'type' && (name !== 'period' || value !== 'customized')),
    ).map(({ name, value }): Misc.CurrentFilterItem => ({
      label: `${getFilterLabel(name)}`,
      value,
      name,
      key: name,
      getRemovedValue: () => null,
    }));

    const typeFilter = activeFilters.find((filter) => filter.name === 'type');

    if (!typeFilter) {
      return badges;
    }

    const arrayValue = typeFilter.value as string[];

    const typeBadge = showAllOrFilters(
      typeFilter,
      [...REMINDERS_FILTERS, ...COLLABORATORS_FILTERS],
      arrayValue,
      t('actions:type'),
      t('actions:actions-all'),
      'type',
      t,
    );

    if (typeBadge) {
      badges.push(typeBadge);
    }
    return badges;
  },
};

export default actionsFiltersSettings;
