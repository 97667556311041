import './index.scss';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FormRadios from 'components/FormRadios';
import type { FormRadioValue } from 'components/FormRadios/Item';

type Props  = {
  text: string,
  reference?: string,
  mutedDays?: number | null,
};

const CustomerUnMuteForm = ({ text, mutedDays = 0 }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [scenario, setScenario] = useState<FormRadioValue>('standard');

  const radioItems = useMemo(() =>[
    {
      label: mutedDays
        ? t('payment-requests:unmuted-actions.move-days', { mutedDays })
        : t('payment-requests:unmuted-actions.move'),
      value: 'move',
      id: 'unmuted-scenarios-move',
      disabled: (!!mutedDays && mutedDays < 1),
    },
    {
      label: t('payment-requests:unmuted-actions.standard'),
      value: 'standard',
      id: 'unmuted-scenarios-standard',
    },
  ], [t, mutedDays]);

  return (
    <div className="CustomerUnMuteForm">
      <p className="CustomerUnMuteForm__text">{text}</p>
      <FormRadios
        name="scenario"
        items={radioItems}
        value={scenario}
        onChange={setScenario}
      />
    </div>
  );
};

export default CustomerUnMuteForm;
