import './index.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import SelectSearch, { SelectSearchOption } from 'react-select-search';
import type Misc from 'types/misc';
import organizationStore from 'stores/Organization';
import { fetchAllPayRequestsClients } from 'api/selectOptions';
import useApiRequest from 'hooks/useApiRequest';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { getDefaultValue, DefaultValue } from 'components/FormSelect/utils';

type Props = {
  name: string,
  customer: string,
  defaultValue?: DefaultValue,
  onChange?(reference: string): void,
  payRequestId?: string,
  isInvalid?: boolean,
};

const SelectPayRequestForCustomer = (props: Props): JSX.Element => {
  const {
    name,
    customer,
    defaultValue,
    onChange,
    payRequestId,
    isInvalid = false,
  } = props;
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const [options, setOptions] = useState<SelectSearchOption[]>([]);
  const [currentValue, setCurrentValue] = useState<string | string[]>('');
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const previousQuery = useRef<string>('');

  const { get } = useApiRequest();

  const getOptions = useCallback(async (query: string): Promise<SelectSearchOption[]> => {
    if (!query || query === previousQuery.current) {
      return options;
    }
    previousQuery.current = query;

    try {
      const result = await fetchAllPayRequestsClients(
        'selectClientPayRequestOptions',
        currentOrganization?.reference,
        customer,
        query,
      );

      if (!result) {
        return [];
      }

      const { data } = result;
      const optionsData = data?.map(({ name: entryName, reference }) => ({
        name: entryName,
        value: reference,
      }));

      setOptions(optionsData);
      return optionsData;
    } catch {
      return [];
    }
  }, [customer, currentOrganization, options]);

  const getDefaultOption = useCallback(async () => {
    const defaultName = `${defaultValue}` || '--';
    if (!currentOrganization || !payRequestId) {
      setOptions([{ name: defaultName, value: `${defaultValue}` }]);
      return;
    }

    const url = `pay-requests/${currentOrganization.reference}/${payRequestId}`;
    const result = await get<Misc.IdRefName>(url);
    setOptions([{
      name: result?.reference || defaultName,
      value: result?.reference || `${defaultValue}`,
    }]);
  }, [currentOrganization, defaultValue, get, payRequestId]);

  useEffect(() => {
    if (!defaultValue || currentValue.length > 0) {
      return;
    }

    if (options.length === 0) {
      getDefaultOption();
      return;
    }

    const initValue = getDefaultValue(defaultValue, options);
    setCurrentValue(initValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, options]);

  const handleChange = useCallback((selectedValue: string | string[]) => {
    setCurrentValue(selectedValue);

    const newValue = (Array.isArray(selectedValue)) ? selectedValue.join(',') : selectedValue;

    if (onChange) {
      onChange(newValue);
    }

    if (!inputRef?.current) {
      return;
    }
    // - Ceci permet de déclencher un onChange au niveau du <form> parent
    const inputValSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value',
    )?.set;
    inputValSetter?.call(inputRef.current, newValue);
    inputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
  }, [onChange]);

  const handleClear = useCallback(() => {
    setCurrentValue('');
    setIsFocus(false);
  }, []);

  const classNames = classnames('SelectPayRequestForCustomer', {
    'SelectPayRequestForCustomer--has-value': currentValue.length > 0,
    'SelectPayRequestForCustomer--is-invalid': isInvalid,
    'SelectPayRequestForCustomer --with-flying-label-opened': isFocus,
  });

  return (
    <div
      className={classNames}
      onFocus={() => { setIsFocus(true); }}
      onBlur={() => { setIsFocus(false); }}
    >
      <SelectSearch
        onChange={handleChange}
        value={currentValue}
        options={options}
        getOptions={getOptions}
        search
        placeholder={t<string>('common:search-in-select')}
        multiple={false}
        printOptions="on-focus"
      />
      <input
        type="text"
        value={currentValue}
        name={name}
        ref={inputRef}
        onChange={() => {}}
        className="SelectPayRequestForCustomer__hidden-field"
      />
      {currentValue.length > 0 && (
        <Button
          variant="light"
          onClick={handleClear}
          className="SelectPayRequestForCustomer__clear"
        >
          <Icon name="close-small" />
        </Button>
      )}
    </div>
  );
};

export default observer(SelectPayRequestForCustomer);
