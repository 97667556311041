import './index.scss';
import { useContext, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import type { Contact } from 'types/models';
import organizationStore from 'stores/Organization';
import ListContacts from 'components/ListContacts';
import useFetch from 'hooks/useFetch';
import { fetchAllForListContact, FetchAllForListContactParams } from 'api/contacts';
import customerContext from '../../customerContext';
import CustomerMainLayout from '../Layout';
import CustomerInfos from './Infos';

const CustomerInfosContacts = (): JSX.Element | null => {
  const { data: customer, refetch: fetchCustomer, onActionDone, reloadTick } = useContext(customerContext);
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const customerId = customer?.id;
  const {
    isLoading,
    data: contacts,
    refetch,
    error,
  } = useFetch<FetchAllForListContactParams, Contact[]>(
    { cacheKey: 'contactsForCustomer', organization: currentOrganization?.reference, customerId },
    fetchAllForListContact,
  );

  const handleRefetch = useCallback(() => {
    if (fetchCustomer) {
      fetchCustomer();
    }
    refetch();
  }, [fetchCustomer, refetch]);

  useEffect(() => {
    refetch();
  }, [refetch, reloadTick]);

  if (!customer) {
    return null;
  }

  return (
    <CustomerMainLayout
      sidebar={(
        <div className="CustomerInfosContacts__side-view">
          <ListContacts
            customerId={customer.id}
            nbContacts={customer.contactsCount}
            contacts={contacts}
            fetchContacts={handleRefetch}
            error={error}
            isLoading={isLoading}
            onActionDone={onActionDone}
            managers={customer.users}
          />
        </div>
      )}
    >
      <div className="CustomerInfosContacts">
        <h2 className="CustomerInfosContacts__title">
          {t('common:informations')}
        </h2>
        <CustomerInfos data={customer} />
      </div>
    </CustomerMainLayout>
  );
};

export default observer(CustomerInfosContacts);
