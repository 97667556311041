import {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import type Misc from 'types/misc';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import Loading from 'components/Loading';
import Button from 'components/Button';
import Icon from 'components/Icon';
import type { ButtonVariant } from 'components/Button';
import type { ToggleAction } from '../../actions';
import getToggleActionsI18n from '../getToggleActionI18n';
import UnMuted from '../UnMuted';

type ButtonIcon =
  | 'bell-muted'
  | 'bell'
  | 'check-circle'
  | 'warning-circle'
  | 'undo';

interface Props {
  id: number,
  reference: string,
  hasGroup: boolean,
  muted: Misc.PayRequestMuted,
  action: ToggleAction,
  buttonVariant?: ButtonVariant,
  isOnlyIcon?: boolean,
  buttonIcon?: ButtonIcon | null,
  onDone: (action: ToggleAction) => void,
  onError: (message: string) => void,
}

const RowActionsToggle = (props: Props): JSX.Element => {
  const {
    id,
    reference,
    hasGroup,
    muted,
    action,
    buttonVariant = 'list',
    isOnlyIcon = false,
    buttonIcon = null,
    onDone,
    onError,
  } = props;

  const { linesOfBusiness } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);
  const [isShowUnmutedModal, setIsShowUnmutedModal] = useState(false);
  const isMountedRef = useIsMountedRef();

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const {
    put,
    remove,
    error,
    isLoading,
  } = useApiRequest();

  const actionI18n = useMemo(
    () => getToggleActionsI18n(t, ct, reference, hasGroup)[action],
    [t, ct, reference, hasGroup, action],
  );

  const handleClickAction = useCallback(() => {
    const { title, confirmText: text } = actionI18n;
    if (action === 'unmute') {
      setIsShowUnmutedModal(true);
      return;
    }

    showConfirm(title, text);
  }, [actionI18n, showConfirm, action]);

  const doActionPut = useCallback(async () => {
    const result = await put<Misc.IdRef>(
      `pay-requests/manage/${id}/${action}`,
    );
    if (result && isMountedRef.current) {
      onDone(action);
    }
  }, [action, id, put, isMountedRef, onDone]);

  const doActionDelete = useCallback(async () => {
    const response = await remove(`pay-requests/manage/${id}`);
    if (response && isMountedRef.current) {
      onDone('remove');
    }
  }, [id, remove, isMountedRef, onDone]);

  const handleSubmit = useCallback(() => {
    hideConfirm();
    if (action === 'remove') {
      doActionDelete();
    } else {
      doActionPut();
    }
  }, [hideConfirm, action, doActionPut, doActionDelete]);

  const handleClose = () => {
    if (isMountedRef.current) {
      setIsShowUnmutedModal(false);
    }
  };

  useEffect(() => {
    if (error) { onError(error.message || t('errors:unknown-retry')); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <>
      <Button
        variant={buttonVariant}
        onClick={handleClickAction}
        title={isOnlyIcon ? actionI18n.title : undefined}
        className="RowActionsToggle"
        disabled={isLoading}
      >
        {isLoading && <Loading hasNoText />}
        {(!isLoading && buttonIcon) && <Icon name={buttonIcon} />}
        {!isOnlyIcon && actionI18n.title}
      </Button>
      <UnMuted
        onDone={onDone}
        onClose={handleClose}
        isShow={isShowUnmutedModal}
        title={actionI18n.title}
        text={actionI18n.confirmText}
        reference={reference}
        action={action}
        id={id}
        mutedAt={muted?.at}
        hasGroup={hasGroup}
      />
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        entityName={reference}
        isShow={isConfirmShowed}
        onConfirm={handleSubmit}
        onCancel={hideConfirm}
        variant={(action === 'remove' || action === 'cancel') ? 'danger' : 'primary'}
        confirmButtonText={actionI18n.title}
        helpHeaderText={
          (action === 'mute' && hasGroup)
            ? t('payment-requests:actions.confirm.mute-group-tooltip')
            : undefined
        }
      />
    </>
  );
};

export default RowActionsToggle;
