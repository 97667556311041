import './index.scss';
import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import SelectSearch from 'react-select-search';
import Button from 'components/Button';
import Avatar from 'components/Avatar';
import Config from 'config';
import type { User } from 'types/models';
import useApiRequest from 'hooks/useApiRequest';
import authStore from 'stores/Auth';
import ErrorMessage from 'components/ErrorMessage';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import FormControl from 'components/FormControl';
import Loading from 'components/Loading';
import SettingsList from '../../List';
import ModalEditPassword from './ModalEditPassword';

type Props = {
  user: User,
  isBusy: boolean,
  onShowToast(message: string, type: ToastStatus): void,
};

const SettingsProfileMyProfile = ({ user, isBusy, onShowToast }: Props): JSX.Element => {
  const { t } = useTranslation();
  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const [showModalEditPassword, hideModalEditPassword] = useModal(() => (
    <ModalEditPassword
      onClose={hideModalEditPassword}
      onShowToast={onShowToast}
    />
  ), [onShowToast]);

  const {
    put,
    postFormData,
    error,
    resetError,
    isLoading,
  } = useApiRequest();

  const handleUploadAvatar = useCallback(() => {
    if (inputFileRef?.current) {
      inputFileRef.current.click();
    }
  }, []);

  const handleAvatarChange = useCallback(
    async (newAvatar: File) => {
      const formDataFile = new FormData();
      formDataFile.append('avatar', newAvatar);

      const response = await postFormData('current-user', formDataFile);

      if (!response) {
        onShowToast(t('users:toast.error.settings-update'), 'error');
        return;
      }

      authStore.fetchUser();
      onShowToast(t('users:toast.success.settings-update'), 'success');
    },
    [onShowToast, t, postFormData],
  );

  const handlePreferedLanguageChange = useCallback(
    async (newLanguage: string | string[]) => {
      const data = {
        language: Array.isArray(newLanguage) ? newLanguage[0] : newLanguage,
      };

      const response = await put('current-user/language', data);

      if (!response) {
        onShowToast(t('users:toast.error.settings-update'), 'error');
        return;
      }

      authStore.fetchUser();
      onShowToast(t('users:toast.success.settings-update'), 'success');
    },
    [onShowToast, t, put],
  );

  const listData = useMemo(() => {
    const { email, phone, locale } = user;

    return [
      {
        label: t('common:phone'),
        value: phone || '-',
      },
      {
        label: t('common:email'),
        value: email,
      },
      {
        label: t('common:password'),
        value: (<Button onClick={showModalEditPassword} variant="link">{t('users:edit-password')}</Button>),
      },
      {
        label: t('users:prefered-language'),
        value: (
          <SelectSearch
            onChange={handlePreferedLanguageChange}
            value={locale}
            options={Config.LANGUAGES.map(({ label, value }) => ({
              name: label,
              value,
            }))}
          />
        ),
        isLoading: (isLoading || isBusy),
      },
    ];
  }, [user, t, handlePreferedLanguageChange, isLoading, isBusy, showModalEditPassword]);

  const {
    avatar,
    firstName,
    lastName,
    email,
  } = user;

  return (
    <div className="SettingsProfileMyProfile">
      {error && <ErrorMessage error={error} onClose={resetError} />}
      <div className="SettingsProfileMyProfile__header">
        <Button
          variant="outline"
          onClick={handleUploadAvatar}
          className="SettingsProfileMyProfile__header__image"
        >
          <div className="SettingsProfileMyProfile__header__image__text">
            {t('common:edit')}
          </div>
          <FormControl
            ref={inputFileRef}
            onFileChange={handleAvatarChange}
            type="file"
            name="avatar"
            className="SettingsProfileMyProfile__header__image__form"
          />
          {isLoading && (<Loading hasNoText />)}
          {!isLoading && (<Avatar username={`${firstName} ${lastName}`} userAvatar={avatar} size={128} />)}
        </Button>
        <h3 className="SettingsProfileMyProfile__header__name">
          {(`${firstName} ${lastName}`) || email }
        </h3>
      </div>
      <SettingsList data={listData} />
    </div>
  );
};

export default SettingsProfileMyProfile;
