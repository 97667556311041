import type Misc from 'types/misc';

const isDefaultCondition = (conditions: Misc.SelectorCondition[]): boolean => {
  if (conditions.length !== 1) {
    return false;
  }

  const condition = conditions[0];
  return (
    condition.comparisonOperator === '=='
    && condition.select1 === '1'
    && condition.select2 === '1'
  );
};

export default isDefaultCondition;
