import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import type { CustomerPayRequest } from 'types/models';
import formatIntlDate from 'utils/formatIntlDate';
import Badge from 'components/Badge';

type Props = {
  data: CustomerPayRequest,
};

const CustomerAccountDetailsStatusPayRequestCell = ({ data }: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { status, dueAt, phase, litigatedAt } = data;

  if (status === 'PAID') {
    return (
      <Fragment>
        {t<string>('payment-requests:status.paid')}
      </Fragment>
    );
  }

  if (status === 'CANCELLED') {
    return (
      <Fragment>
        {t<string>('payment-requests:status.cancelled')}
      </Fragment>
    );
  }

  if (status === 'FAILED') {
    return (
      <Fragment>
        {t<string>('payments:status.failed')}
      </Fragment>
    );
  }

  if (litigatedAt) {
    return (
      <Badge variant="LITIGATED" title={formatIntlDate(litigatedAt, 'dateLong')}>
        {t('payment-requests:status.litigated')}
      </Badge>
    );
  }

  return (
    <Badge variant={phase!}>
      {t('payment-requests:due-at')} {formatIntlDate(dueAt, 'dateLong')}
    </Badge>
  );
};

export default CustomerAccountDetailsStatusPayRequestCell;
