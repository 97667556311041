import './index.scss';

type Props = {
  portalID: string,
};

const ActionsSide = ({ portalID }: Props): JSX.Element => (
  <div className="ActionsDoneSide">
    <div id={portalID} />
  </div>
);

export default ActionsSide;
