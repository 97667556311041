import './index.scss';
import type { ReactNode } from 'react';
import classnames from 'classnames';
import type { DefaultTFuncReturn } from 'i18next';
import type { PhaseId } from 'types/models';
import type Misc from 'types/misc';

type Props = {
  variant: PhaseId | Misc.ImportStatusId | Misc.BillingStatusId | 'INACTIVE' | 'GROUP' | 'RECENT' | 'NOTE' | 'FAILED',
  /** Pour afficher une infobulle (native) au survol de la souris */
  title?: DefaultTFuncReturn,
  isRecent?: boolean,
  children: ReactNode,
};

const Badge = ({ variant, title, isRecent = false, children }: Props): JSX.Element => {
  const classNames = classnames('Badge', {
    'Badge--upcoming': variant === 'UPCOMING',
    'Badge--pending': variant === 'PENDING',
    'Badge--almost-due': variant === 'ALMOST_DUE',
    'Badge--due': variant === 'DUE',
    'Badge--overdue': variant === 'OVERDUE',
    'Badge--late': variant === 'LATE',
    'Badge--default': variant === 'DEFAULT',
    'Badge--file-created': variant === 'FILE_CREATED',
    'Badge--file-processing': variant === 'FILE_PROCESSING',
    'Badge--file-processed': variant === 'FILE_PROCESSED',
    'Badge--file-error': variant === 'FILE_ERROR',
    'Badge--file-skipped': variant === 'FILE_SKIPPED',
    'Badge--billing-litigated': variant === 'LITIGATED',
    'Badge--billing-paid': variant === 'PAID',
    'Badge--billing-cancelled': variant === 'CANCELLED',
    'Badge--inactive': variant === 'INACTIVE',
    'Badge--group': variant === 'GROUP',
    'Badge--recent': variant === 'RECENT' || isRecent,
    'Badge--note': variant === 'NOTE',
  });

  return (
    <span className={classNames} title={title ?? undefined}>
      {children}
    </span>
  );
};

export default Badge;
