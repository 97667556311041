import { useCallback, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { observer } from 'mobx-react';
import type Misc from 'types/misc';
import type { Models } from 'types/models';
import type { FetchAllParams } from 'api/models';
import { fetchAll } from 'api/models';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useFetch from 'hooks/useFetch';
import organizationStore from 'stores/Organization';
import ToastNotification from 'components/ToastNotification';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import useToast from 'components/ToastNotification/useToast';
import SettingsSection from '../Section';
import ModalTask from './ModalTask';
import ModalChooseDunning from './ModalChooseReminder';
import ModalReminderModel from './ModalReminderModel';
import Action from './Action';
import ModelsModalEditYAML from './ModalEditYAML';

const SettingsModels = (): JSX.Element => {
  const { linesOfBusiness, currentOrganization } = organizationStore;
  const { ct } = useContextualTranslation(linesOfBusiness);

  const { data, refetch } = useFetch<FetchAllParams, Models>(
    { cacheKey: 'organizationModels', organization: currentOrganization?.reference },
    fetchAll,
    { enabled: !!currentOrganization },
  );

  const [channel, setChannel] = useState<Misc.Channel>('SMS');

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const handleActionDone = useCallback((message: string) => {
    showToast(message, 'success');
    refetch();
  }, [refetch, showToast]);

  const handleActionError = useCallback((message: string) => {
    showToast(message, 'error');
  }, [showToast]);

  const [showModalReminderModel, hideModalReminderModel] = useModal(() => (
    <ModalReminderModel
      onDone={handleActionDone}
      onError={handleActionError}
      channel={channel}
      onClose={hideModalReminderModel}
    />
  ), [channel, handleActionDone, handleActionError]);

  const handleChannelChosen = useCallback((newChannel: Misc.Channel) => {
    setChannel(newChannel);
    showModalReminderModel();
  }, [showModalReminderModel]);

  const [showModalChooseDunning, hideModalChooseDunning] = useModal(() => (
    <ModalChooseDunning
      onClose={hideModalChooseDunning}
      onChoose={handleChannelChosen}
    />
  ), [handleChannelChosen]);

  const [showModalTask, hideModalTask] = useModal(() => (
    <ModalTask
      onClose={hideModalTask}
      onDone={handleActionDone}
      onError={handleActionError}
    />
  ), [handleActionDone, handleActionError]);

  const [showYAMLModal, hideYAMLModal] = useModal(() => (
    <ModelsModalEditYAML
      onClose={hideYAMLModal}
      onActionDone={handleActionDone}
      onActionError={handleActionError}
    />
  ), [handleActionDone, handleActionError]);

  return (
    <div className="Models">
      <SettingsSection
        title={ct('models:reminders-models')}
        action={(
          <ButtonWithUserRights
            action="CREATE"
            category="PREFERENCES_MODELS"
            onClick={showModalChooseDunning}
            variant="link"
            withCaretIcon
          >
            {ct('models:add-model')}
          </ButtonWithUserRights>
        )}
        secondaryAction={(
          <ButtonWithUserRights
            action="CREATE"
            category="PREFERENCES_MODELS"
            onClick={showYAMLModal}
            variant="link"
            withCaretIcon
          >
            {ct('models:yaml-edit')}
          </ButtonWithUserRights>
        )}
      >
        {data?.templates?.reminders.map((action) => (
          <Action
            action={action}
            key={action.name}
            type="reminder"
            onActionDone={handleActionDone}
            onActionError={handleActionError}
          />
        ))}
      </SettingsSection>
      <SettingsSection
        title={ct('models:tasks-models')}
        action={(
          <ButtonWithUserRights
            action="CREATE"
            category="PREFERENCES_MODELS"
            onClick={showModalTask}
            variant="link"
            withCaretIcon
          >
            {ct('models:add-model')}
          </ButtonWithUserRights>
        )}
      >
        {data?.templates?.tasks.map((action) => (
          <Action
            action={action}
            key={action.name}
            type="task"
            onActionDone={handleActionDone}
            onActionError={handleActionError}
          />
        ))}
      </SettingsSection>
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </div>
  );
};

export default observer(SettingsModels);
