import './index.scss';
import { useCallback, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PublicLayout from 'layout/PublicLayout';
import type { FetchAnswerParams } from 'api/collaborator';
import { fetchAnswer } from 'api/collaborator';
import authStore from 'stores/Auth';
import useFetch from 'hooks/useFetch';
import Loading from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';
import type Errors from 'types/errors';
import useApiRequest from 'hooks/useApiRequest';
import AnswerForm from './Form';

export type RouteParams = {
  token: string,
};

export type FormDataAnswer = {
  firstName: string,
  lastName: string,
  identifier: string,
  password: string,
  language: string,
};

const Answer = (): JSX.Element => {
  const { t } = useTranslation();
  const { token } = useParams<RouteParams>();
  const history = useHistory();
  const [validationErrors, setValidationErrors] = useState<Errors.Validation | null>(null);

  const {
    data: answerData,
    error: fetchError,
    isLoading: isFetchLoading,
  } = useFetch<FetchAnswerParams, string>(
    { cacheKey: 'answerCollaborator', token },
    fetchAnswer,
    { retry: false },
  );

  const {
    post,
    error: submitError,
    isLoading: isSubmitLoading,
  } = useApiRequest();

  const handleSubmit = useCallback(async (sendData: FormDataAnswer) => {
    setValidationErrors(null);

    const result = await post<{ email: string }>(`collaborators/manage/answer/${token}`, sendData);
    if (!result) {
      return;
    }

    if (result.errors) {
      setValidationErrors(result.errors);
      return;
    }

    if (!result.email) {
      return;
    }

    await authStore.login(result.email, sendData.password);

    history.replace('/');
  }, [history, post, token]);

  useEffect(() => {
    if (fetchError?.code === 2 || fetchError?.code === 404) {
      history.replace('/login');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchError]);

  return (
    <PublicLayout>
      <div className="Answer">
        <h2 className="Answer__title">{t('answer:title')}</h2>
        {(!isFetchLoading && !fetchError && answerData) && (
          <AnswerForm
            onSubmit={handleSubmit}
            isLoading={isSubmitLoading}
            errors={validationErrors}
          />
        )}
        {isFetchLoading && <Loading hasNoText />}
        <div className="Answer__errors">
          {fetchError && <ErrorMessage error={fetchError} />}
          {submitError && <ErrorMessage error={submitError} />}
        </div>
      </div>
    </PublicLayout>
  );
};

export default Answer;
