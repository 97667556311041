import './index.scss';
import React from 'react';
import { StripeIbanElementChangeEvent } from '@stripe/stripe-js';
import { IbanElement } from '@stripe/react-stripe-js';

interface Props {
  onChange: (ibanEvent: StripeIbanElementChangeEvent) => void,
}

const IbanInput: React.FC<Props> = ({ onChange }) => {
  const options = React.useMemo(() => ({
    supportedCountries: ['SEPA'],
    style: {
      base: {
        color: '#32325d',
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
        ':-webkit-autofill': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
        ':-webkit-autofill': {
          color: '#fa755a',
        },
      },
    },
  }), []);

  return (
    <IbanElement
      id="iban-element"
      options={options}
      onChange={onChange}
    />
  );
};

export default IbanInput;
