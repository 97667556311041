import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import type Misc from 'types/misc';
import useApiRequest from 'hooks/useApiRequest';
import organizationStore from 'stores/Organization';
import useIsMountedRef from 'hooks/useIsMountedRef';
import getI18nChannel from 'utils/getI18nChannel';
import DropdownActions from 'components/DropdownActions';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import Button from 'components/Button';
import Confirm from 'components/Confirm';
import StepIcon from 'components/StepIcon';
import ModalTask from '../ModalTask';
import ModalReminderModel from '../ModalReminderModel';

interface Props {
  action: Misc.PlanTemplateReminder,
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
  type: 'reminder' | 'task' | 'dispatch',
}

const ModelsAction: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;

  const isMountedRef = useIsMountedRef();

  const {
    action,
    onActionError,
    onActionDone,
    type,
  } = props;

  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState<boolean>(false);

  const { remove } = useApiRequest();

  const [showModalReminderModel, hideModalReminderModel] = useModal(() => (
    <ModalReminderModel
      edit
      onDone={onActionDone}
      onError={onActionError}
      channel={action.channel || 'EMAIL'}
      onClose={hideModalReminderModel}
      defaultData={action}
    />
  ), [action, onActionDone, onActionError]);

  const [showModalTask, hideModalTask] = useModal(() => (
    <ModalTask
      edit
      onDone={onActionDone}
      onError={onActionError}
      onClose={hideModalTask}
      defaultData={action}
    />
  ), [action, onActionDone, onActionError]);

  const handleEdit = React.useCallback(() => {
    if (type === 'reminder') {
      showModalReminderModel();
      return;
    }
    showModalTask();
  }, [showModalReminderModel, showModalTask, type]);

  const handleDelete = React.useCallback(async () => {
    setShowDeleteConfirm(false);
    if (!currentOrganization) {
      return;
    }

    const response = await remove<{ errors: { models: string } }>(
      `organization/templates/${currentOrganization.reference}/${type}?identifier=${action.identifier}`,
    );
    if (isMountedRef.current && response && !response.errors) {
      onActionDone(t('models:toast.success.delete-model', { name: action.name }));
      return;
    }
    if (response?.errors.models === 'Can\'t delete a default template') {
      onActionError(t('models:toast.error.delete-default-model', { name: action.name }));
      return;
    }
    onActionError(t('models:toast.error.delete-model', { name: action.name }));
  }, [action, currentOrganization, isMountedRef, onActionDone, onActionError, remove, t, type]);

  const actions = React.useMemo(() => [
    <Button variant="list" onClick={handleEdit}>{t('common:edit')}</Button>,
    <ButtonWithUserRights
      action="DELETE"
      category="PREFERENCES_MODELS"
      variant="list-danger"
      onClick={() => { setShowDeleteConfirm(true); }}
    >
      {t('common:remove')}
    </ButtonWithUserRights>,
  ], [handleEdit, t]);

  return (
    <div key={`${action.name}-day${action.day}`} className="ModelsAction">
      <div className="ModelsAction__main">
        <div className="ModelsAction__main__icon">
          <StepIcon
            data={{
              entity: type === 'task' ? 'FreeTask' : 'Reminder',
              channel: action.channel || null,
            }}
          />
        </div>
        <div className="ModelsAction__main__description">
          <h4 className="ModelsAction__main__description__title">{action.name}</h4>
          <p className="ModelsAction__main__description__channel">
            {action.channel ? t(getI18nChannel(action?.channel)) : ''}
          </p>
        </div>
      </div>
      <DropdownActions
        actions={actions}
      />
      <Confirm
        titleModal={t('common:remove')}
        text={t('models:confirm-delete')}
        variant="danger"
        confirmButtonText={t('common:remove')}
        cancelButtonText={t('common:cancel')}
        entityName={action.name}
        isShow={showDeleteConfirm}
        onConfirm={handleDelete}
        onCancel={() => { setShowDeleteConfirm(false); }}
      />
    </div>
  );
};

export default observer(ModelsAction);
