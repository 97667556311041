import type Misc from 'types/misc';

const getI18nStatus = (statusId: string): string => {
  const statusI18n = {
    IN_PROGRESS: 'payment-requests:status.active',
    PAID: 'payment-requests:status.paid',
    CANCELLED: 'payment-requests:status.cancelled',
    LITIGATED: 'payment-requests:status.litigated',
    FAILED: 'common:reject',
  };

  if (!Object.keys(statusI18n).includes(statusId)) {
    return 'phases:unknown-phase';
  }

  return statusI18n[statusId as Misc.PayRequestStatus];
};

export default getI18nStatus;
