import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import useApiRequest from 'hooks/useApiRequest';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import organizationStore from 'stores/Organization';
import FormRadios from 'components/FormRadios';
import config from 'config';
import { FormRadioValue } from 'components/FormRadios/Item';
import getI18nPaymentAttribution from 'utils/getI18nPaymentAttribution';
import SettingsSection from '../../Section';

interface Props {
  onShowToast: (message: string, status: ToastStatus) => void,
}

const Sending: React.FC<Props> = ({ onShowToast }) => {
  const { t } = useTranslation();
  const { currentOrganization, paymentAttribution, refresh } = organizationStore;

  const { put } = useApiRequest();

  const handleSubmitPaymentAttribution = React.useCallback(async (newValue: FormRadioValue) => {
    const result = await put(
      `/organization/${currentOrganization?.reference}/update-payment-attribution`,
      { type: newValue.toString() },
    );
    if (result?.errors) {
      onShowToast(t('errors:unknown-retry'), 'error');
      return;
    }
    onShowToast(t('customization:payment-attribution-updated'), 'success');
    await refresh();
  }, [put, currentOrganization?.reference, onShowToast, t, refresh]);

  return (
    <SettingsSection title={t('customization:payments-imputation')} className="PaymentsImputation">
      <FormRadios
        name="paymentAttribution"
        defaultValue={paymentAttribution || undefined}
        items={config.ORGANIZATION_PAYMENT_ATTRIBUTION.map((attribution) => ({
          value: attribution, label: t(getI18nPaymentAttribution(attribution)),
        }))}
        onChange={handleSubmitPaymentAttribution}
      />
    </SettingsSection>
  );
};

export default observer(Sending);
