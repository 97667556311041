import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { OrganizationStripe } from 'types/models';
import Icon from 'components/Icon';

interface Props {
  data: OrganizationStripe | null,
}

const SepaMandateLink: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  const mandateUrl = (data?.sourceId && data?.clientSecret)
    ? `https://hooks.stripe.com/adapter/sepa_debit/file/${data.sourceId}/${data.clientSecret}`
    : null;

  return (
    <div className="SepaMandateLink">
      {(!data || (!data.customerId && !data.sourceId)) && (
        <em>{t('organizations:sepa-mandate.not-eligible')}</em>
      )}
      {data && data.sourceId && (
        <>
          <Icon name="check" />
          {t('organizations:sepa-mandate.accepted')}
          {mandateUrl && (
            <a
              className="SepaMandateLink__read-link"
              href={mandateUrl}
              target="blank"
              rel="noopener noreferrer"
            >
              {t('organizations:sepa-mandate.read')}
            </a>
          )}
        </>
      )}
    </div>
  );
};

export default SepaMandateLink;
