import './index.scss';
import { useCallback, useContext, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import customerContext from 'pages/Customer/customerContext';
import SplitButtonDropdown from 'components/SplitButtonDropdown';
import {
  ExternallyMonitoredSwitch,
  ExternallyMonitoredController,
} from 'components/ExternallyMonitored';
import CustomerEditModal from 'components/CustomerEdit';
import useTooltip from 'components/Tooltip/useTooltip';
import CustomerActionCreateReminder from './CreateReminder';
import CustomerActionCreateNote from './CreateNote';
import CustomerActionCreateTask from './CreateTask';
import CustomerActionNewContact from './NewContact';
import CustomerActionNewPayRequest from './NewPayRequest';
import CustomerActionNewPayment from './NewPayment';
import CustomerActionDelete from './Delete';
import CustomerActionMute from './Mute';

type Props = {
  onActionError(message: string): void,
};

const CustomerHeaderActions = ({ onActionError }: Props): JSX.Element | null => {
  const { data: customer, onActionDone } = useContext(customerContext);
  const { t } = useTranslation();

  const handleEditDone = useCallback((message: string) => {
    if (onActionDone) {
      onActionDone(message);
    }
  }, [onActionDone]);

  const [showEditModal, hideEditModal] = useModal(() => (
    <CustomerEditModal
      onDone={handleEditDone}
      editType="edit"
      customerId={customer?.id}
      onClose={hideEditModal}
    />
  ), [handleEditDone, customer]);

  const tooltipNoPayRequest = useTooltip((
    <Fragment>
      {customer?.allowCreateTasks === 'not-at-all' && (
        <p className="CustomerHeaderActions__tooltip-content">
          {t('clients:action-require-active-debt')}
        </p>
      )}
      {customer?.allowCreateTasks === 'not-for-customer' && (
        <p className="CustomerHeaderActions__tooltip-content">
          {t('clients:action-only-possible-on-pay-request')}
        </p>
      )}
    </Fragment>
  ), 'center');

  if (!customer) {
    return null;
  }

  const { allowCreateTasks, users } = customer;

  return (
    <div className="CustomerHeaderActions">
      <ExternallyMonitoredSwitch>
        <ExternallyMonitoredController isDisplayedWhenMonitored>
          <CustomerActionCreateNote variant="outline" />
        </ExternallyMonitoredController>
        <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
          <SplitButtonDropdown
            onClick={showEditModal}
            title={t('common:edit')}
            action="UPDATE"
            categoryType="CLIENTS"
          >
            <div ref={tooltipNoPayRequest} className="CustomerHeaderActions__grouped-actions">
              <CustomerActionCreateReminder disabled={allowCreateTasks !== 'yes'} />
              <CustomerActionCreateTask disabled={allowCreateTasks !== 'yes'} />
            </div>
            <CustomerActionCreateNote variant="list" />
            <CustomerActionMute managers={users} onError={onActionError} />
            <hr className="CustomerHeaderActions__separator" />
            <CustomerActionNewContact managers={users} />
            <CustomerActionNewPayRequest managers={users} />
            <CustomerActionNewPayment managers={users} />
            <hr className="CustomerHeaderActions__separator" />
            <CustomerActionDelete managers={users} onError={onActionError} />
          </SplitButtonDropdown>
        </ExternallyMonitoredController>
      </ExternallyMonitoredSwitch>
    </div>
  );
};

export default CustomerHeaderActions;
