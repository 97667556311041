import type { Plan, PlanForList, OrganizationPlanTemplate } from 'types/models';
import Request from 'utils/request';

export type FetchAllParams = {
  organization: string | undefined,
  isDefault?: boolean,
};

export type FetchListParams = {
  organization: string | undefined,
};

export type FetchOneParams = {
  organization: string | undefined,
  identifier: string,
};

const fetchAll = async ({ organization, isDefault }: FetchAllParams) => {
  if (!organization) {
    throw new Error('FetchAllPlans: Missing organization.');
  }

  const queryData = [];

  if (isDefault !== undefined) {
    queryData.push(`default=${isDefault ? 'true' : 'false'}`);
  }

  const result = await Request.get<Plan[]>(
    `manage-plans/${organization}/list?${queryData.join('&')}`,
  );
  return result;
};

const fetchList = async ({ organization }: FetchListParams) => {
  if (!organization) {
    throw new Error('FetchAllOptions: Missing organization.');
  }
  const result = await Request.get<PlanForList[]>(
    `organization/${organization}/plans`,
  );
  return result;
};

const fetchOne = async ({ organization, identifier }: FetchOneParams) => {
  if (!organization) {
    throw new Error('FetchOnPlan: Missing organization.');
  }

  const result = await Request.get<OrganizationPlanTemplate>(
    `manage-plans/${organization}/template/${identifier}`,
  );
  return result;
};

export { fetchAll, fetchList, fetchOne };
