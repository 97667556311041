import './index.scss';
import { Fragment, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { fetchStats, FetchStatsParams } from 'api/organization';
import useFetch from 'hooks/useFetch';
import type { OrganizationStats } from 'types/models';
import organizationStore from 'stores/Organization';
import Loading from 'components/Loading/index';
import ErrorMessage from 'components/ErrorMessage';
import HomeHeader from './Header';
import HomeContent from './Content';
import NoOrganizationSelected from './NoOrganizationSelected';

const Home = () => {
  const { t } = useTranslation();
  const { currentOrganization, walletSelection } = organizationStore;

  const filtering = useMemo(() => {
    if (walletSelection.length === 0) {
      return [];
    }
    return [{ name: 'categories', value: walletSelection }];
  }, [walletSelection]);

  const {
    data: statsData,
    error,
    isLoading,
  } = useFetch<FetchStatsParams, OrganizationStats>(
    {
      cacheKey: 'organizationStat',
      organization: currentOrganization?.reference,
      filtering,
    },
    fetchStats,
    { enabled: !!currentOrganization?.reference },
  );

  useEffect(() => {
    localStorage.removeItem('sentConfirmations');
  }, []);

  return (
    <div className="Home">
      {error && currentOrganization && <ErrorMessage error={error} />}
      {(isLoading && currentOrganization?.name) && (
        <Loading
          text={t(
            'home:loading-stats',
            { organizationName: currentOrganization?.name },
          )}
        />
      )}
      {!currentOrganization && <NoOrganizationSelected />}
      {(!isLoading && !error && statsData) && (
        <Fragment>
          <HomeHeader data={statsData} />
          <HomeContent stats={statsData} />
        </Fragment>
      )}
    </div>
  );
};

export default observer(Home);
