import React from 'react';
import { useTranslation } from 'react-i18next';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import Button from 'components/Button';
import Loading from 'components/Loading';

type Props = {
  onSubmit: (password: string, repeatPassword: string) => void,
  isLoading?: boolean,
  error: string | null,
};

const ResetPasswordForm: React.FC<Props> = (props) => {
  const {
    onSubmit,
    isLoading = false,
    error,
  } = props;

  const { t } = useTranslation();
  const [password1, setPassword1] = React.useState('');
  const [password2, setPassword2] = React.useState('');

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    onSubmit(password1, password2);
  };

  return (
    <form className="ResetPasswordForm" onSubmit={handleSubmit}>
      <FormGroup controlId="resetPassword1" label={t('forgotten-password:new-password')}>
        <FormControl
          type="password"
          id="resetPassword1"
          placeholder={t('login:enter-password')}
          value={password1}
          onChange={setPassword1}
          isInvalid={error !== null}
        />
      </FormGroup>
      <FormGroup controlId="resetPassword2" label={t('forgotten-password:new-password-confirm')}>
        <FormControl
          type="password"
          id="resetPassword2"
          placeholder={t('login:enter-password')}
          value={password2}
          onChange={setPassword2}
          isInvalid={error !== null}
        />
      </FormGroup>
      {error !== null && (
        <h4 className="ResetPasswordForm__error">{error}</h4>
      )}
      <Button
        variant="primary"
        type="submit"
        className="ResetPasswordForm__submit"
      >
        {isLoading && <Loading />}
        {!isLoading && t('forgotten-password:reset-password')}
      </Button>
    </form>
  );
};

export default ResetPasswordForm;
