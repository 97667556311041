import { useCallback, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import equal from 'deep-equal';
import type { HistoryStep, FreeTaskPost } from 'types/models';
import type Errors from 'types/errors';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import organizationStore from 'stores/Organization';
import Confirm from 'components/Confirm';
import TaskForm from './Form';

type Props = {
  task: HistoryStep,
  onDone(title: string, message: string): void,
  onClose(): void,
};

const FreeTaskEdit = ({ task, onDone, onClose }: Props): JSX.Element => {
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const { currentOrganization } = organizationStore;
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Errors.Validation | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);
  const { put, isLoading, error } = useApiRequest();

  const initialData = useRef<FreeTaskPost>({
    name: task.name as string,
    description: task.description as string,
    startAt: task.at.at,
    type: 'task',
  });

  const mapFormData = useCallback((rawData: ModalFormData): FreeTaskPost | null => {
    if (!currentOrganization) {
      return null;
    }

    const data: FreeTaskPost = {
      name: rawData.name as string,
      description: rawData.description as string,
      startAt: rawData.startAt as string,
      type: 'task',
    };

    if (rawData.withMove === 'move') {
      data.scenario = 'move';
    }

    return data;
  }, [currentOrganization]);

  const closeSelf = useCallback(() => {
    if (!isMountedRef.current) {
      return;
    }

    setShowCancelConfirm(false);
    setHasChanges(false);
    setValidationErrors(null);
    onClose();
  }, [isMountedRef, onClose]);

  const handleCancel = useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirm(true);
    } else {
      closeSelf();
    }
  }, [hasChanges, closeSelf]);

  const handleChange = useCallback((formData: ModalFormData | null) => {
    const newData = formData ? mapFormData(formData) : null;
    setHasChanges(!equal(initialData.current, newData));
  }, [initialData, mapFormData]);

  const handleSubmit = useCallback(async (formData: ModalFormData | null) => {
    if (!formData || !currentOrganization) {
      return;
    }

    const data = mapFormData(formData);
    if (!data) {
      return;
    }

    const result = await put<FreeTaskPost>(`task/manage/${task.id}/edit`, data);
    if (!isMountedRef.current) {
      return;
    }

    if (result?.errors) {
      setValidationErrors(result.errors);
      return;
    }

    if (!result?.name) {
      return;
    }

    closeSelf();

    const { name } = result;
    onDone(
      t('reminders:actions.toast.edited'),
      t('reminders:actions.toast.edited-name', { name }),
    );
  }, [currentOrganization, put, mapFormData, isMountedRef, onDone, t, closeSelf, task]);

  return (
    <ModalForm
      isOpened
      className="ReminderEdit"
      title={t('reminders:freetask.edit')}
      subTitle={task.name ?? ''}
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
    >
      {error && <ErrorMessage error={error} />}
      <TaskForm
        defaultData={{
          name: task.name || '',
          description: task.description || '',
          startAt: task.at.at,
          payRequestId: task.payRequest?.id || 0,
          group: (task?.group != null) || false,
          groupId: task.group?.id || undefined,
        }}
        errors={validationErrors}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={closeSelf}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(FreeTaskEdit);
