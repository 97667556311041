import './index.scss';
import { useContext, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getOverviewCustomerEavs } from 'utils/getCustomerEavs';
import organizationStore from 'stores/Organization';
import customerContext from 'pages/Customer/customerContext';
import AddressDetails from 'components/AddressDetails';
import Icon from 'components/Icon';

const CustomerAttributes = (): JSX.Element | null => {
  const { data: customer } = useContext(customerContext);
  const { eavs } = organizationStore;
  const { t } = useTranslation();

  const attributes = useMemo(() => (
    getOverviewCustomerEavs(customer?.eavs, eavs)
  ), [customer, eavs]);

  if (!customer) {
    return null;
  }

  return (
    <dl className="CustomerAttributes">
      <div className="CustomerAttributes__header">
        <h3 className="CustomerAttributes__title">
          {t('common:informations')}
        </h3>
        <Link
          to={`/customer/${customer.id}/infos-contacts`}
          className="CustomerAttributes__link"
        >
          {t('common:all-informations')}
          <Icon name="chevron-right-small" className="CustomerAttributes__link__icon" />
        </Link>
      </div>
      <div className="CustomerAttributes__item">
        <dt className="CustomerAttributes__label">
          {t('common:managers')}
        </dt>
        <dd className="CustomerAttributes__value">
          {(customer.users && customer.users.length > 0)
            ? customer.users.map(({ name }) => name).join(', ')
            : '-'}
        </dd>
      </div>
      <div className="CustomerAttributes__item">
        <dt className="CustomerAttributes__label">
          {t('common:address')}
        </dt>
        <dd className="CustomerAttributes__value">
          <AddressDetails data={customer} />
        </dd>
      </div>
      <div className="CustomerAttributes__item">
        <dt className="CustomerAttributes__label">
          {t('common:perimeter')}
        </dt>
        <dd className="CustomerAttributes__value">
          {customer.categories.map(({ value }) => value.value).join(', ')}
        </dd>
      </div>
      {attributes.map(({ id, label, value }) => (
        <div key={id} className="CustomerAttributes__item">
          <dt className="CustomerAttributes__label">{label}</dt>
          <dd className="CustomerAttributes__value">{value ?? '-'}</dd>
        </div>
      ))}
    </dl>
  );
};

export default observer(CustomerAttributes);
