import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';

const BackOfficeNav: React.FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const getLinkClassName = React.useCallback((to: string, className: string) => (
    classnames(className, {
      [`${className}--active`]: to === pathname,
    })
  ), [pathname]);

  const navLinks = React.useMemo(() => ([
    {
      title: t('users:nav.title.global'),
      links: [
        { to: '/back-office', label: t('users:nav.link.parser') },
        { to: '/organization/new', label: t('organization-create:create-organization') },
      ],
    },
    {
      title: t('users:nav.title.by-organization'),
      links: [
        { to: '/back-office/organization', label: t('users:nav.title.my-organization') },
      ],
    },
  ]), [t]);

  return (
    <div className="BackOfficeNav">
      {navLinks.map(({ title, links }) => (
        <div className="BackOfficeNav__item" key={title}>
          <div className="BackOfficeNav__item__title">{title}</div>
          <ul className="BackOfficeNav__item__list">
            {links.map(({ to, label }) => (
              <li className={getLinkClassName(to, 'BackOfficeNav__item__list__item')} key={to}>
                <Link to={to} className="BackOfficeNav__item__list__item__link">
                  {label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default BackOfficeNav;
