import './index.scss';
import { useState, useCallback, useMemo, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import type { Category } from 'types/models';
import Button from 'components/Button';
import FormPerimeterItem from './Item';
import categoryReducer, { CategoryActions } from './reducer';

type Props = {
  perimeter?: Category[],
  availableCategories: Category[],
  onClose(): void,
  onChange?(categories: Category[]): void,
};

const FormPerimeter = (props: Props) => {
  const { perimeter = [], availableCategories = [], onClose, onChange } = props;
  const { t } = useTranslation();
  const [showNewLine, setShowNewLine] = useState<boolean>(() => { return perimeter.length === 0; });
  const [selectedCategories, dispatch] = useReducer(categoryReducer, perimeter);

  const remainingCategories = useMemo(() => (
    availableCategories
      ?.filter(({ id }) => !selectedCategories.map(({ id: usedIds }) => usedIds).includes(id))
      ?? []
  ), [availableCategories, selectedCategories]);

  const canAddCondition = useMemo(() => {
    if (!remainingCategories) {
      return false;
    }
    return (
      (remainingCategories?.length > 1 && !showNewLine)
      || (remainingCategories.length === 1 && !showNewLine)
    );
  }, [remainingCategories, showNewLine]);

  const handleRemoveCondition = useCallback((categoryId: number | null) => {
    if (!categoryId) {
      setShowNewLine(false);
      return;
    }

    dispatch({
      type: CategoryActions.DELETE_CATEGORY,
      categoryId: categoryId,
    });
  }, []);

  const handleAddCondition = useCallback((categoryId: number) => {
    const selectedCategory = availableCategories?.find(({ id }) => id === categoryId);
    if (!selectedCategory) {
      return;
    }

    dispatch({
      type: CategoryActions.ADD_CATEGORY,
      category: selectedCategory,
    });
    setShowNewLine(false);
  }, [setShowNewLine, availableCategories]);

  const handleChangeConditionValues = useCallback((categoryId: number, valuesId: string[]) => {
    const baseCategory = availableCategories?.find(({ id }) => id === categoryId);
    if (!baseCategory) {
      return;
    }

    const updatedValues = baseCategory.values.filter(({ id }) => valuesId.includes(`${id}`));
    dispatch({
      type: CategoryActions.SET_VALUES,
      categoryId: categoryId,
      values: updatedValues,
    });
  }, [availableCategories]);

  const handleValidatePerimeter = () => {
    if (onChange) {
      onChange(selectedCategories);
    }
    onClose();
  };

  useEffect(() => {
    if (onChange) {
      onChange(selectedCategories);
    }
  }, [onChange, selectedCategories]);

  return (
    <div className="FormPerimeter">
      {selectedCategories.map((category) => (
        <FormPerimeterItem
          key={category.id}
          selectedCategoryId={category.id}
          selectedValuesIds={category.values.map(({ id }) => id)}
          availableCategories={availableCategories}
          onRemoveCondition={handleRemoveCondition}
          onChangeConditionValues={handleChangeConditionValues}
        />
      ))}
      {showNewLine && (
        <FormPerimeterItem
          availableCategories={remainingCategories}
          onRemoveCondition={() => { setShowNewLine(false); }}
          onAddCondition={handleAddCondition}
        />
      )}
      <div className="FormPerimeter__actions">
        <Button
          disabled={!canAddCondition}
          className="FormPerimeter__action"
          variant="link"
          onClick={() => { setShowNewLine(true); }}
        >
          {t('perimeters:add-condition')}
        </Button>
        <Button
          className="FormPerimeter__action"
          variant="link"
          onClick={handleValidatePerimeter}
        >
          {t('perimeters:validate-perimeter')}
        </Button>
        <Button
          className="FormPerimeter__action" variant="link"
          onClick={onClose}
        >
          {t('perimeters:cancel-modification')}
        </Button>
      </div>
    </div>
  );
};

export default FormPerimeter;
