import './index.scss';
import type { ReactNode, ReactElement, SyntheticEvent } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import type { DefaultTFuncReturn } from 'i18next';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import checkEmailFormat from 'utils/checkEmailFormat';
import authStore from 'stores/Auth';
import Alert from 'components/Alert';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Loading from 'components/Loading';

export type ModalFormData = {
  [key: string]: FormDataEntryValue
};

type Props = {
  className?: string,
  title: string,
  subTitle?: string,
  isOpened: boolean,
  onInit?(formData: ModalFormData | null): void,
  onChange?(formData: ModalFormData | null): void,
  hasWarning?: boolean,
  onSave(formData: ModalFormData | null): void,
  onEmailError?(): void,
  onCancel(): void,
  isFetched?: boolean,
  isLoading?: boolean,
  children: ReactNode,
  saveText?: DefaultTFuncReturn,
  hasDeleteButton?: boolean,
  onDelete?(): void,
  buttonsDisabled?: boolean,
  headerActions?: ReactElement,
  helpHeaderText?: DefaultTFuncReturn,
};

const ModalForm = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { isDemo } = authStore;
  const formRef = useRef<HTMLFormElement | null>(null);

  const {
    className,
    title,
    subTitle,
    isOpened,
    onInit,
    onChange,
    hasWarning = false,
    onSave,
    onEmailError,
    onCancel,
    isFetched,
    isLoading,
    children,
    saveText,
    hasDeleteButton = false,
    onDelete,
    buttonsDisabled = false,
    headerActions,
    helpHeaderText,
  } = props;

  const newClassName = classnames('ModalForm', className);

  const getFormData = useCallback(
    (): ModalFormData | null => {
      if (!formRef.current) {
        return null;
      }

      const formData = new FormData(formRef.current);
      return Object.fromEntries(formData.entries());
    },
    [],
  );

  const handleSubmit = useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (isDemo) {
        return;
      }

      const data = getFormData();

      if (onEmailError && data && 'email' in data) {
        const { email } = data;
        if (typeof email === 'string' && email.length > 0 && !checkEmailFormat(email)) {
          onEmailError();
          return;
        }
      }

      onSave(data);
    },
    [isDemo, onEmailError, onSave, getFormData],
  );

  const handleChange = useCallback(
    (e: SyntheticEvent<HTMLFormElement>) => {
      e.persist();
      e.preventDefault();
      if (onChange) {
        onChange(getFormData());
      }
    },
    [onChange, getFormData],
  );

  useEffect(() => {
    if (onInit) {
      onInit(getFormData());
    }
  }, [onInit, isFetched, getFormData]);

  return (
    <Modal
      isOpened={isOpened}
      title={title}
      subTitle={subTitle}
      isLoading={isLoading}
      hasWarning={hasWarning}
      onClose={onCancel}
      className={newClassName}
      headerActions={headerActions}
      helpHeaderText={helpHeaderText}
    >
      {isDemo && (
        <Alert variant="warning">
          {t('common:demo.warning')} {t('common:demo.form-cannot-be-submitted')}
        </Alert>
      )}
      <form
        className="ModalForm__form"
        onSubmit={handleSubmit}
        ref={formRef}
        onChange={handleChange}
      >
        <div className="ModalForm__content">
          {children}
        </div>
        <div className="ModalForm__footer">
          {!isLoading && isDemo && (
            <Alert variant="warning">
              {t('common:demo.warning')} {t('common:demo.form-cannot-be-submitted')}
            </Alert>
          )}
          <Button variant="outline-black" onClick={onCancel}>{t('common:cancel')}</Button>
          {hasDeleteButton && (
            <Button variant="danger" disabled={buttonsDisabled} onClick={onDelete}>
              {t('common:remove')}
            </Button>
          )}
          <Button type="submit" disabled={isDemo || buttonsDisabled}>
            {isLoading ? <Loading text={t('common:saving')} /> : (saveText || t('common:save'))}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalForm;
