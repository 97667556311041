import './index.scss';
import { useMemo } from 'react';
import type { DefaultTFuncReturn } from 'i18next';
import { useTranslation } from 'react-i18next';
import Config from 'config';
import type Errors from 'types/errors';

export type ErrorValidationMessageType = Errors.ValidationItem | string | undefined | null;

type Props = {
  error: ErrorValidationMessageType,
  fieldName?: DefaultTFuncReturn,
};

const ErrorValidationMessage = ({ error, fieldName }: Props): JSX.Element | null => {
  const { t } = useTranslation();

  const text = useMemo(() => {
    if (!error) {
      return null;
    }

    if (typeof error === 'string') {
      return error;
    }

    const { EMPTY, ERROR, MISSING, DUPLICATED } = Config.ERRORS_CODES;
    const { code, message } = error;

    if (message === 'unknown_client_entity') {
      return t('errors:validation.unknown-customer');
    }

    if (message === 'Amount cant be 0 or less') {
      return t('errors:validation.amount-negative');
    }

    switch (code) {
      case MISSING:
        return t('errors:validation.required-field');
      case ERROR:
        return t('errors:validation.value-not-valid');
      case EMPTY:
        return t('errors:validation.value-does-not-exist');
      case DUPLICATED:
        return t('errors:validation.resource-already-exists');
      default:
        return message;
    }
  }, [t, error]);

  if (!text) {
    return null;
  }

  return (
    <div className="ErrorValidationMessage">
      {fieldName !== undefined && `${fieldName}: `}{text}
    </div>
  );
};

export default ErrorValidationMessage;
