import './index.scss';
import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import type { Column } from 'react-table';
import type { CollaboratorInvite } from 'types/models';
import organizationStore from 'stores/Organization';
import useApiRequest from 'hooks/useApiRequest';
import formatIntlDate from 'utils/formatIntlDate';
import useIsMountedRef from 'hooks/useIsMountedRef';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import DataTable from 'components/DataTable';
import InvitedRowActions from './RowActions';

type Props = {
  onShowToast(message: string, status: ToastStatus): void,
  reloadTick: number,
};

const CollaboratorsInvitedTable = ({ onShowToast, reloadTick }: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { get, isLoading } = useApiRequest();
  const { currentOrganization } = organizationStore;

  const [collaborators, setCollaborators] = useState<CollaboratorInvite[] | null>(null);

  const isMountedRef = useIsMountedRef();

  const fetchCollaborators = useCallback(async () => {
    if (!currentOrganization) {
      return;
    }
    const response = await get<CollaboratorInvite[]>(
      `/collaborators/${currentOrganization.reference}/invitations`,
    );
    if (!response) {
      return;
    }
    if (isMountedRef) {
      setCollaborators(response);
    }
  }, [currentOrganization, get, setCollaborators, isMountedRef]);

  useEffect(() => {
    fetchCollaborators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization, reloadTick]);

  const onActionDone = useCallback((message: string) => {
    fetchCollaborators();
    onShowToast(message, 'success');
  }, [fetchCollaborators, onShowToast]);

  const columns: Column<CollaboratorInvite>[] = [
    {
      Header: t<string>('common:sent-at'),
      accessor: 'createdAt',
      disableSortBy: true,
      Cell: ({ value }) => (
        <div className="CollaboratorsInvitedTable__created-at">
          {formatIntlDate(value)}
        </div>
      ),
    },
    {
      Header: t<string>('common:email'),
      accessor: 'email',
      disableSortBy: true,
    },
    {
      Header: t<string>('common:commands'),
      accessor: 'id',
      disableSortBy: true,
      Cell: ({ row, value: id }) => (
        <div className="CollaboratorsInvitedTable__actions">
          <InvitedRowActions
            id={id}
            reference={id.toString()}
            name={row.original.email}
            onActionDone={onActionDone}
            onActionError={(message: string) => onShowToast(message, 'error')}
          />
        </div>
      ),
    },
  ];

  if (!currentOrganization) {
    return null;
  }

  return (
    <div className="CollaboratorsInvitedTable">
      { (!collaborators || collaborators?.length === 0) && (
        <p>{t('collaborators:no-collaborator-invitations')}</p>
      )}
      { (collaborators && collaborators.length > 0) && (
        <DataTable<CollaboratorInvite>
          columns={columns}
          data={collaborators}
          serverPagination={null}
          defaultSortBy={{ id: 'id', desc: false }}
          fetchData={() => { }}
          isLoading={isLoading}
          error={null}
          noHeader
          withActions={false}
        />
      )}
    </div>
  );
};

export default observer(CollaboratorsInvitedTable);
