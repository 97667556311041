import type Misc from 'types/misc';

const ExportLink = (
  filtering: Misc.Filter[],
  organizationReference: string | null,
): string => {
  if (!organizationReference) {
    return '';
  }

  const queryData: string[] = [];

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }) => {
      queryData.push(`filters[${name}]=${value}`);
    });
  }

  return `organization/export/${organizationReference}/steps?${queryData.join('&')}`;
};

export default ExportLink;
