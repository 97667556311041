import './index.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
import getI18nDayFromReference from 'utils/getI18nDayFromReference';
import getI18nChannel from 'utils/getI18nChannel';
import StepIcon from 'components/StepIcon';

type Props = {
  action: Misc.PlanTemplateDispatch | Misc.PlanTemplateTask | Misc.PlanTemplateReminder,
  type: Misc.ActionType,
  isGroup: boolean,
  dateReference: Misc.PlanDateReference,
};

const ScenarioDetailsActionsItem = (props: Props): JSX.Element => {
  const { action, type, isGroup, dateReference } = props;
  const { name, channel } = action;
  const { t } = useTranslation();

  const entity = useMemo(() => {
    if (type === 'DISPATCH') {
      return 'Reminder';
    }
    return type === 'TASK' ? 'FreeTask' : 'Reminder';
  }, [type]);

  const needsValidation = useMemo(() => (
    type === 'REMINDER'
      ? (action as Misc.PlanTemplateReminder).needsValidation
      : null
  ), [type, action]);

  return (
    <div className="ScenarioDetailsActionsItem">
      <div className="ScenarioDetailsActionsItem__icon">
        <StepIcon data={{ entity, channel: channel ?? 'EMAIL' }} />
      </div>
      <div className="ScenarioDetailsActionsItem__details">
        <h4 className="ScenarioDetailsActionsItem__details__title">
          {name}
          {(type === 'DISPATCH' && isGroup) && (
            <span className="ScenarioDetailsActionsItem__details__title__solo-badge">
              {t('plans:solo-action')}
            </span>
          )}
        </h4>
        <p className="ScenarioDetailsActionsItem__details__days">
          {type !== 'DISPATCH' && t(
            getI18nDayFromReference(action.day, dateReference),
            { count: Math.abs(action.day || 0) },
          )}
          <span className="ScenarioDetailsActionsItem__details__days__way">
            {action?.channel && `${type === 'DISPATCH' ? t('plans:dispatch') : ', '} ${t(
              getI18nChannel(action?.channel),
            )}`}
          </span>
        </p>
      </div>
      {needsValidation && (
        <div className="ScenarioDetailsActionsItem__to-validate">
          {t('common:to-validate')}
        </div>
      )}
    </div>
  );
};

export default ScenarioDetailsActionsItem;
