import './index.scss';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import type { CustomerResult } from 'api/globalSearch';
import type { CustomerFullNameData } from 'types/models';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import ClientName from 'components/ClientName';

type Props = {
  data: CustomerResult[],
  search: string,
};

const SearchResultCustomers = ({ data, search }: Props): JSX.Element => {
  const { linesOfBusiness } = organizationStore;
  const { ct } = useContextualTranslation(linesOfBusiness);

  return (
    <ul className="SearchResultCustomers">
      {data.map((customer) => {
        const { id, reference, clientFirstName, clientLastName, clientCorporateName } = customer;
        const customerNameData: CustomerFullNameData = {
          id,
          reference,
          type: !!clientCorporateName ? 'company' : 'individual',
          firstName: clientFirstName,
          lastName: clientLastName,
          corporateName: clientCorporateName,
        };

        return (
          <li key={id} className="SearchResultCustomers__item">
            <Link
              to={`/customer/${id}`}
              title={ct<string>('common:open-customer-page')}
              className="SearchResultCustomers__item__link"
            >
              <ClientName client={customerNameData} />
              <span>{reference !== clientCorporateName && ` (${reference})`}</span>
            </Link>
          </li>
        );
      })}
      <li className="SearchResultCustomers__link-all">
        <Link
          to={`/customers${search ? `?search=${search}` : ''}`}
          className="SearchResultCustomers__link-all__link"
        >
          {ct('common:menu.search.all-customers-contacts')}
        </Link>
      </li>
    </ul>
  );
};

export default observer(SearchResultCustomers);
