import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import organizationStore from 'stores/Organization';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormSelect from 'components/FormSelect';
import useIsMountedRef from 'hooks/useIsMountedRef';
import useApiRequest from 'hooks/useApiRequest';
import GroupedNameInput from '../GroupedNameInput';

interface Props {
  onClose: () => void,
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
  planToDuplicate?: string,
}

const ModalAddPlan: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();

  const {
    onClose,
    onActionDone,
    onActionError,
    planToDuplicate,
  } = props;

  const { currentOrganization } = organizationStore;

  const [isGroupChecked, setIsGroupChecked] = React.useState<boolean>(false);
  const [selectReferenceDate, setSelectReferenceDate] = React.useState<string | null>('invoice-deadline');

  const { post } = useApiRequest();

  React.useEffect(() => {
    if (selectReferenceDate !== 'plan-setup') {
      setSelectReferenceDate(isGroupChecked ? 'invoice-oldest-deadline' : 'invoice-deadline');
    }
  }, [isGroupChecked, selectReferenceDate]);

  const handleSubmit = React.useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData || !currentOrganization || !isMountedRef.current) {
        return;
      }

      const { referenceDate, ...params } = formData;

      const result = await post(`/manage-plans/${currentOrganization.reference}`, {
        ...params,
        dateReferenceIsToday: referenceDate === 'plan-setup',
        group: isGroupChecked,
      });
      if (!result) {
        onActionError(t('plans:toast.error.add-plan'));
        return;
      }

      onActionDone(t('plans:toast.success.added-plan', { name: formData.name }));
      onClose();
    },
    [
      currentOrganization,
      isGroupChecked,
      isMountedRef,
      onActionDone,
      onActionError,
      onClose,
      post,
      t,
    ],
  );

  const selectOptions = React.useMemo(() => {
    const planSetup = { label: t('plans:reference-date-options.plan-setup'), value: 'plan-setup' };
    return isGroupChecked
      ? [{ label: t('plans:reference-date-options.invoice-oldest-deadline'), value: 'invoice-oldest-deadline' }, planSetup]
      : [{ label: t('plans:reference-date-options.invoice-deadline'), value: 'invoice-deadline' }, planSetup];
  }, [isGroupChecked, t]);

  return (
    <ModalForm
      isOpened
      className="PlansModalAddPlan"
      onSave={handleSubmit}
      onCancel={onClose}
      title={t('plans:title-add-plan')}
    >
      <GroupedNameInput
        isGroup={isGroupChecked}
        onChangeIsGroup={setIsGroupChecked}
      />
      <FormFieldset>
        <FormGroup label={t('plans:duplicate-existing-plan')}>
          <FormSelect
            name="models"
            isAsync
            fetchEntity={`manage-plans/${currentOrganization?.reference}/list`}
            hasFetchOrganizationPrefix={false}
            entityValue="identifier"
            defaultValue={planToDuplicate}
            placeholder={t('plans:choose-plan-to-duplicate')}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('plans:select-reference-date')} mandatory>
          <FormSelect
            name="referenceDate"
            selectOptions={selectOptions}
            value={selectReferenceDate}
            onSelect={(_name, newValue) => { setSelectReferenceDate(newValue); }}
            placeholder={t('plans:choose-reference-date')}
          />
        </FormGroup>
      </FormFieldset>
    </ModalForm>
  );
};

export default observer(ModalAddPlan);
