import './index.scss';
import { useCallback, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import type { CustomerAccountDetailsFilters } from 'api/payments-pay-requests';
import FormCheck from 'components/FormCheck';
import customerContext from '../../../../customerContext';

type Props = {
  filters: CustomerAccountDetailsFilters,
  onChange(filters: CustomerAccountDetailsFilters): void,
};

const AccountDetailsFilters = ({ filters, onChange }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { debits, credits, current, balanced, future } = filters;
  const { data: customer } = useContext(customerContext);

  const hasFuture = useMemo(() => (
    customer?.hasFutureDebitsOrCredits
  ), [customer]);

  const handleCheckFilter = useCallback(
    (name: keyof CustomerAccountDetailsFilters) => (checked: boolean) => {
      const newFilters = { ...filters, [name]: checked };
      onChange(newFilters);
    },
    [filters, onChange],
  );

  return (
    <div className="AccountDetailsFilters">
      <div className="AccountDetailsFilters__item">
        <FormCheck
          label={t('common:debits')}
          checked={debits ?? undefined}
          onChange={handleCheckFilter('debits')}
          disabled={!credits}
        />
      </div>
      <div className="AccountDetailsFilters__item">
        <FormCheck
          label={t('common:credits')}
          checked={credits ?? undefined}
          onChange={handleCheckFilter('credits')}
          disabled={!debits}
        />
      </div>
      <div className="AccountDetailsFilters__item">
        <FormCheck
          label={t('common:in-progress')}
          checked={current ?? undefined}
          onChange={handleCheckFilter('current')}
          disabled={!balanced}
        />
      </div>
      <div className="AccountDetailsFilters__item">
        <FormCheck
          label={t('common:balanced-plural')}
          checked={balanced ?? undefined}
          onChange={handleCheckFilter('balanced')}
          disabled={!current}
        />
      </div>
      {hasFuture && (
        <div className="AccountDetailsFilters__item">
          <FormCheck
            label={t('common:futures')}
            checked={future ?? undefined}
            onChange={handleCheckFilter('future')}
          />
        </div>
      )}
    </div>
  );
};

export default observer(AccountDetailsFilters);
