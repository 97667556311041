import type Misc from 'types/misc';
import type {
  HistoryStep,
  HistoryStepEmail,
  HistoryStepLetter,
  HistoryStepSMS,
  HistoryStepSummary,
  HistoryStepPreviewContent,
  PayRequest,
} from 'types/models';
import Request from 'utils/request';

export type FetchAllParams = {
  organization: string | undefined,
  filtering: Misc.Filter[],
  withUser?: boolean,
  fetchOptions: Misc.PaginatedFetchArgs<HistoryStep>,
};

export type FetchLastParams = {
  organization: string | undefined,
  filtering: Misc.Filter[],
};

export type FetchReminderContentParams = {
  reminderId: HistoryStep['id'] | null,
};

export type LastStepsResult = {
  count: number,
  records: HistoryStepSummary[],
};

export type FetchAllForPayRequestParams = {
  organization: string | undefined,
  payRequestId: PayRequest['id'],
  type: 'waiting' | 'future',
};

export type FetchHistoryParams = {
  organization: string | undefined,
  payRequestId: PayRequest['id'],
};

export type FetchAllForPreviewContentParams = {
  organization: string | undefined,
  channel: Misc.Channel,
  content: string,
};

const fetchAll = async (params: FetchAllParams) => {
  const { organization, filtering, fetchOptions, withUser = false } = params;
  if (!organization) {
    throw new Error('FetchAllHistorySteps: Missing organization.');
  }

  const { pageIndex, pageSize, sort } = fetchOptions;

  const queryData = new URLSearchParams();
  queryData.append('recordsPerPage', pageSize.toString());
  queryData.append('page', (pageIndex + 1).toString());

  if (filtering && filtering.length > 0) {
    let hasCustomPeriod = true;
    filtering.forEach(({ name, value }) => {
      if (name === 'period' && value?.toString() !== 'customized') {
        hasCustomPeriod = false;
      }
      if (!hasCustomPeriod && ['periodA', 'periodB'].includes(name)) {
        return;
      }
      const safeValue = Array.isArray(value) ? value.join(',') : value;
      if (!safeValue) {
        return;
      }
      queryData.append(`filters[${name}]`, safeValue);
    });
  }

  if (sort) {
    queryData.append('orderBy', sort.id);
    queryData.append('orderDirection', sort.desc ? 'desc' : 'asc');
  }

  if (withUser) {
    queryData.append('withUser', '1');
  }

  const result = await Request.get<Misc.Listing<HistoryStep | HistoryStepEmail | HistoryStepSMS | HistoryStepLetter>>(
    `organization/${organization}/steps?${queryData.toString()}`,
  );
  return result;
};

const fetchLast = async ({ organization, filtering }: FetchLastParams) => {
  if (!organization) {
    throw new Error('FetchLastHistorySteps: Missing organization.');
  }

  const queryData = new URLSearchParams();

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }) => {
      queryData.append(
        `filters[${name}]`,
        (Array.isArray(value) ? value.join(',') : value) || '',
      );
    });
  }

  const result = await Request.get<LastStepsResult>(
    `organization/${organization}/last-steps?${queryData.toString()}`,
  );
  return result;
};

const fetchReminderContent = async ({ reminderId }: FetchReminderContentParams) => {
  if (!reminderId) {
    throw new Error('FetchReminderContent: Missing reminderId.');
  }

  const result = await Request.get<string>(
    `task/reminders/${reminderId}/edit-content`,
  );
  return result;
};

const fetchAllForPayRequest = async ({
  organization,
  payRequestId,
  type,
}: FetchAllForPayRequestParams) => {
  if (!organization) {
    throw new Error('FetchAllForPayRequestHistorySteps: Missing organization.');
  }

  const queryData = [
    `filters[status]=${type}`,
  ];

  const result = await Request.get<Misc.Listing<HistoryStep>>(
    `pay-requests/${organization}/${payRequestId}/steps?${queryData.join('&')}`,
  );
  return result;
};

const fetchHistory = async ({
  organization,
  payRequestId,
}: FetchHistoryParams) => {
  if (!organization) {
    throw new Error('FetchHistoryHistorySteps: Missing organization.');
  }
  if (!payRequestId) {
    throw new Error('FetchHistoryHistorySteps: Missing payment request ID.');
  }

  const result = await Request.get<Misc.Listing<HistoryStep>>(
    `pay-requests/${organization}/${payRequestId}/history`,
  );
  return result;
};

const fetchPreviewContent = async ({
  organization,
  content,
  channel,
}: FetchAllForPreviewContentParams) => {
  if (!organization) {
    throw new Error('FetchPreviewContentHistoryStep: Missing organization.');
  }

  const result = await Request.post<HistoryStepPreviewContent>(
    `/reminder-preview/${channel === 'REGISTERED_LETTER' ? 'letter' : channel.toLowerCase()}/${organization}`,
    { content },
  );
  return result;
};

export {
  fetchAll,
  fetchLast,
  fetchReminderContent,
  fetchAllForPayRequest,
  fetchHistory,
  fetchPreviewContent,
};
