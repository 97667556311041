import './index.scss';
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';
import sanitizeHtmlConfig from 'config/sanitizeHtml';
import type Misc from 'types/misc';
import type { HistoryStepPreviewContent } from 'types/models';
import type { FetchAllForPreviewContentParams } from 'api/historySteps';
import { fetchPreviewContent } from 'api/historySteps';
import Modal from 'components/Modal';
import useFetch from 'hooks/useFetch';
import organizationStore from 'stores/Organization';
import PDFViewer from 'components/PDFViewer';
import Loading from 'components/Loading';

type Props = {
  content: string,
  channel: Misc.Channel,
  onClose(): void,
};

const HistoryStepContentPreview = ({ onClose, channel, content }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;

  const { data, isLoading } = useFetch<FetchAllForPreviewContentParams, HistoryStepPreviewContent>(
    {
      cacheKey: 'previewContent',
      organization: currentOrganization?.reference,
      channel,
      content,
    },
    fetchPreviewContent,
  );

  const sanitizedData = useMemo(() => (
    data?.content ? sanitizeHtml(data?.content, sanitizeHtmlConfig) : ''
  ), [data]);

  return (
    <Modal
      isOpened
      className="HistoryStepContentPreview"
      title={`${t('common:preview')}`}
      onClose={onClose}
    >
      <p className="HistoryStepContentPreview__warning">
        {t('reminders:warning-missing-parsing')}
      </p>
      {isLoading && <Loading />}
      {(channel === 'EMAIL' || channel === 'SMS') && (
        <div
          className="HistoryStepContentPreview__email-sms"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: sanitizedData }}
        />
      )}
      {(channel === 'REGISTERED_LETTER' || channel === 'LETTER') && data?.content && (
        <PDFViewer base64PDF={data.content} scale={1.1} />
      )}
    </Modal>
  );
};

export default observer(HistoryStepContentPreview);
