import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import type { Column, Row } from 'react-table';
import config from 'config';
import type Misc from 'types/misc';
import type { HistoryStep } from 'types/models';
import { fetchAll } from 'api/historySteps';
import type { FetchAllParams } from 'api/historySteps';
import useFetchPaginated from 'hooks/useFetchPaginated';
import useContextualTranslation from 'hooks/useContextualTranslation';
import organizationStore from 'stores/Organization';
import actionsFutureFiltersStore from 'stores/FilterStores/ActionsFutureFilters';
import useToast from 'components/ToastNotification/useToast';
import ToastNotification from 'components/ToastNotification';
import SmallSelect from 'components/SmallSelect';
import DataTable from 'components/DataTable';
import PageList from 'components/PageList';
import LinkSwitch from 'components/LinkSwitch';
import getFiltersQueryString from 'utils/getFiltersQueryString';
import filtersSettings from './Filters/settings';
import ActionsSide from './Side';
import ActionsFutureFilters from './Filters';
import ActionsFutureColumns from './Columns';
import BulkActions from './Bulk';
import ExportLink from './ExportLink';

type SortByOption = 'at' | 'totalAmount' | 'totalAmountDue';

const DEFAULT_FILTERS = {
  period: 'today',
  type: ['planned_reminder', 'task_to_do', 'reminder_to_do', 'contact_to_do'],
};

const ActionsFuture = (): JSX.Element => {
  const history = useHistory();
  const { currentOrganization, walletSelection, linesOfBusiness } = organizationStore;
  const { ct } = useContextualTranslation(linesOfBusiness);
  const [sortBy, setSortBy] = useState('at');

  const defaultFetchOptions = {
    pageIndex: 0,
    pageSize: config.DEFAULT_PAGE_SIZE,
    sort: { id: 'at', desc: false },
  };
  const [fetchOptions, setFetchOptions] = useState<Misc.PaginatedFetchArgs<HistoryStep>>(
    defaultFetchOptions,
  );

  const {
    filters,
    addOrUpdateFilters,
    removeFilter,
    resetAllFilters,
  } = actionsFutureFiltersStore;

  useEffect(() => {
    resetAllFilters(DEFAULT_FILTERS);
  }, [resetAllFilters]);

  const filtering = useMemo(() => {
    if (walletSelection.length === 0) {
      return filters;
    }
    return [...filters, { name: 'categories', value: walletSelection }];
  }, [filters, walletSelection]);

  const {
    data: steps,
    serverPagination,
    error,
    isFetching,
    isLoading,
    refetch,
  } = useFetchPaginated<FetchAllParams, HistoryStep>(
    {
      cacheKey: 'actionsFuture',
      organization: currentOrganization?.reference,
      filtering,
      withUser: true,
      fetchOptions,
    },
    fetchAll,
    { enabled: !!currentOrganization, usePrefetching: false },
  );

  const sortByOptions = useMemo<Misc.ValueLabel[]>(() => [
    { value: 'at', label: ct('actions:order-by.at') },
    { value: 'totalAmount', label: ct('actions:order-by.totalAmount') },
    { value: 'totalAmountDue', label: ct('actions:order-by.totalAmountDue') },
  ], [ct]);

  const handleSortChange = useCallback((name: string, newSortBy: SortByOption) => {
    setSortBy(newSortBy);

    setFetchOptions((prevOptions) => ({
      ...prevOptions,
      sort: { id: newSortBy, desc: newSortBy !== 'at' },
    }));
  }, []);

  const handleFetchData = useCallback((options: Misc.PaginatedFetchArgs<HistoryStep>) => {
    setFetchOptions({
      ...options,
      sort: { id: sortBy, desc: sortBy !== 'at' },
    });
  }, [sortBy]);

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const handleActionDone = useCallback((message: string) => {
    showToast(message, 'success');
    refetch();
  }, [showToast, refetch]);

  const handleActionError = useCallback((message: string) => {
    showToast(message, 'error');
  }, [showToast]);

  const columns = useMemo<Column<HistoryStep>[]>(() => (
    ActionsFutureColumns(ct, handleActionDone, handleActionError)
  ), [ct, handleActionDone, handleActionError]);

  const handleSwitchList = useCallback((listing: string) => {
    if (listing !== 'actions-done') {
      return;
    }
    const queryFilters = getFiltersQueryString(
      filters,
      ['channel', 'manager', 'search', 'client', 'user'],
    );
    history.push(`/${listing}?${queryFilters}`);
  }, [filters, history]);

  const hasActions = useCallback(({ original }: Row<HistoryStep>) => (
    ['Reminder', 'FreeTask', 'ProvideContactInformationsTask'].includes(original.entity)
    && original.doneAt === null
    && original.validation === null
    && original.remove === null
  ), []);

  const classNames = classnames('ActionsFuture', {
    'ActionsFuture--sorted-by-total-amount-due': sortBy === 'totalAmountDue',
  });

  return (
    <div className={classNames}>
      <div className="ActionsFuture__content">
        <ToastNotification
          text={toastText}
          status={toastStatus}
          isShow={isToastShowed}
          onClose={hideToast}
        />
        <ActionsSide
          portalID="ActionsFutureSidePortal"
        />
        <PageList
          title={ct('actions:actions-future')}
          className="ActionsFutureContent"
          count={serverPagination?.totalRecords}
          actions={(
            <LinkSwitch
              valueLabels={[
                { value: 'actions-future', label: ct('actions:future') },
                { value: 'actions-done', label: ct('actions:done') },
              ]}
              defaultValue="actions-future"
              onChange={handleSwitchList}
            />
          )}
        >
          {(!isFetching && !isLoading && !!steps && steps.length > 0) && (
            <div className="ActionsFuture__sorting">
              <SmallSelect
                name="orderBy"
                options={sortByOptions}
                onChange={handleSortChange}
                value={sortBy}
              />
            </div>
          )}
          <DataTable<HistoryStep>
            columns={columns}
            serverPagination={serverPagination}
            data={steps}
            defaultSortBy={defaultFetchOptions.sort}
            filtersList={ActionsFutureFilters(ct, filters)}
            fetchData={handleFetchData}
            isLoading={isFetching || isLoading}
            error={error}
            sidePortal={document.getElementById('ActionsFutureSidePortal')}
            filtersSettings={filtersSettings}
            filtering={filters}
            onChangeFilters={addOrUpdateFilters}
            onRemoveFilter={removeFilter}
            hasActions={hasActions}
            withNoDataDrawing
            bulkActions={(
              <BulkActions
                onActionDone={handleActionDone}
                onActionError={handleActionError}
              />
            )}
            exportLink={ExportLink(filters, currentOrganization?.reference ?? null)}
            withPortalExportLink
          />
        </PageList>
      </div>
    </div>
  );
};

export default observer(ActionsFuture);
