import { useContext } from 'react';
import type { ManagerType } from 'types/models';
import customerContext from 'pages/Customer/customerContext';
import { UserRightsManagerController } from 'components/UserRights';
import CustomerMuteButton from 'components/CustomerMuteButton';

type Props = {
  managers: ManagerType[] | null | undefined,
  onError(message: string): void,
};

const CustomerActionMute = (props: Props): JSX.Element | null => {
  const { data, onActionDone } = useContext(customerContext);
  const { managers, onError } = props;

  if (!data) {
    return null;
  }

  return (
    <UserRightsManagerController
      action="UPDATE"
      categoryType="CLIENTS"
      managers={managers}
    >
      <CustomerMuteButton
        onActionError={onError}
        onActionDone={onActionDone}
        customerData={data}
        showText
      />
    </UserRightsManagerController>
  );
};

export default CustomerActionMute;
