import './index.scss';
import { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import type Errors from 'types/errors';
import type { PayRequest, PayRequestPost, OrganizationEavs } from 'types/models';
import { ACCEPT_FILES_TYPE } from 'config';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormSelect from 'components/FormSelect';
import FormSelectCustomer from 'components/FormSelectCustomer';
import FormDatePicker from 'components/FormDatePicker';
import FormRadios from 'components/FormRadios';
import FormSwitch from 'components/FormSwitch';
import FormEavs from 'components/FormEavs';
import Dropzone from 'components/Dropzone';
import PDFViewer from 'components/PDFViewer';
import FormAmountCurrency from 'components/FormAmountCurrency';
import FormSelectUsersWithRights from 'components/FormSelectUsersWithRights';
import Button from 'components/Button';

type Props = {
  defaultData?: PayRequest,
  data?: PayRequestPost,
  customerId?: number,
  isBalanceMode: boolean,
  isCustomerInGroup: boolean,
  eavs?: OrganizationEavs,
  errors?: Errors.Validation | null,
  receipt: string | null,
  receiptName: string | null,
  onChangeReceipt(base64: string | null): void,
  onChangeReceiptName(name: string | null): void,
};

const PaymentRequestForm = ((props: Props): JSX.Element => {
  const {
    defaultData,
    customerId,
    isBalanceMode,
    isCustomerInGroup,
    eavs,
    errors = null,
    onChangeReceipt,
    data,
    receiptName,
    onChangeReceiptName,
    receipt,
  } = props;

  const {
    currency: organizationCurrency,
    useGroupByDefault,
    linesOfBusiness,
    useDynamicPlanSwitch,
  } = organizationStore;

  const { t, ct } = useContextualTranslation(linesOfBusiness);

  const [isGroup, setIsGroup] = useState<boolean>(() => (
    data?.groupDunning ?? isCustomerInGroup ?? useGroupByDefault
  ));
  const [plan, setPlan] = useState<string>(data?.dunningPlan || defaultData?.plan || '');
  const [sendDispatches, setSendDispatches] = useState<boolean>(
    data?.sendDispatches === undefined ? true : data.sendDispatches,
  );

  const updateModeOptions = useMemo(() => [
    {
      value: 'BALANCE_INCREMENT',
      label: t('payment-requests:balance-mode-increment'),
      id: 'radio-balanceMode-increment',
    },
    {
      value: 'BALANCE_UPDATE',
      label: t('payment-requests:balance-mode-update'),
      id: 'radio-balanceMode-update',
    },
  ], [t]);

  const handleRemove = useCallback(() => {
    onChangeReceiptName(null);
    onChangeReceipt(null);
  }, [onChangeReceipt, onChangeReceiptName]);

  const getBase64 = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onChangeReceiptName(!reader.result ? null : file.name);
      onChangeReceipt(!reader.result ? null : reader.result as string);
    };
  };

  return (
    <div className="PaymentRequestForm">
      <FormFieldset>
        <FormGroup label={ct('common:client')} mandatory error={errors?.client}>
          <FormSelectCustomer
            name="customerId"
            defaultValue={customerId}
            isInvalid={!!errors?.client}
          />
        </FormGroup>
        <FormGroup label={t('payment-requests:reference')} mandatory error={errors?.reference}>
          <FormControl
            name="reference"
            autoComplete="off"
            defaultValue={data?.reference || defaultData?.reference || ''}
            isInvalid={!!errors?.reference}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormAmountCurrency
          defaultAmount={data?.amount || defaultData?.amount || ''}
          defaultCurrency={data?.currency || defaultData?.currency || organizationCurrency}
          amountError={errors?.amount || null}
          currencyError={errors?.currency || null}
        />
        <FormGroup label={t('common:due-at')} mandatory error={errors?.dueAt}>
          <FormDatePicker
            name="dueAt"
            defaultValue={data?.dueAt || defaultData?.dueAt}
            isInvalid={!!errors?.dueAt}
          />
        </FormGroup>
        <FormGroup label={t('payment-requests:issued-on')} error={errors?.issueAt}>
          <FormDatePicker
            name="issueAt"
            defaultValue={data?.issueAt || defaultData?.issueAt}
            isInvalid={!!errors?.issueAt}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('payment-requests:subject')} error={errors?.subject}>
          <FormControl
            name="subject"
            autoComplete="off"
            defaultValue={data?.subject || ''}
            isInvalid={!!errors?.subject}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup
          label={t('payment-requests:dispatch')}
          mandatory
          className="PaymentRequestForm__check"
          error={errors?.sendDispatches}
        >
          <FormSwitch
            name="sendDispatches"
            value={sendDispatches}
            onChange={setSendDispatches}
          />
        </FormGroup>
        <FormGroup
          label={t('payment-requests:add-to-dunning-group')}
          mandatory
          className="PaymentRequestForm__check"
          error={errors?.groupDunning}
        >
          <FormSwitch
            name="groupDunning"
            value={isGroup}
            onChange={(value) => { setIsGroup(value); setPlan(''); }}
          />
        </FormGroup>
        {isBalanceMode && (
          <FormGroup
            label={t('payment-requests:update-mode')}
            className="PaymentRequestForm__check"
            error={errors?.balanceMode}
          >
            <FormRadios
              items={updateModeOptions}
              name="balanceMode"
              defaultValue={data?.balanceMode || 'BALANCE_INCREMENT'}
            />
          </FormGroup>
        )}
      </FormFieldset>
      <div className="PaymentRequestForm__check-help">
        <span className="PaymentRequestForm__check-help__information">
          {isGroup && sendDispatches && t('payment-requests:checkbox-help.both-on')}
          {isGroup && !sendDispatches && t('payment-requests:checkbox-help.group-on')}
          {!isGroup && sendDispatches && t('payment-requests:checkbox-help.dispatch-on')}
          {!isGroup && !sendDispatches && t('payment-requests:checkbox-help.none-on')}
        </span>
        <a
          href="https://dunforce.freshdesk.com/support/solutions/articles/6000250454"
          target="blank"
        >
          <Button
            variant="link"
            className="PaymentRequestForm__check-help__link"
          >
            {t('common:learn-more')}
          </Button>
        </a>
      </div>
      <FormFieldset>
        <FormGroup label={t('payment-requests:dunning-plan')} error={errors?.dunningPlan}>
          <FormSelect
            name="dunningPlan"
            placeholder={t('common:please-choose')}
            fetchEntity="plans"
            value={plan}
            fetchEntityQuerystring={isGroup ? 'group=1' : ''}
            isInvalid={!!errors?.dunningPlan}
            onSelect={(name, value) => setPlan(value || '')}
            addToFetchedOptions={useDynamicPlanSwitch ? [
              { value: '', label: t('payment-requests:plan-assigned-automatically') },
            ] : undefined}
            isAsync
          />
        </FormGroup>
        <FormGroup label={t('payment-requests:plan-start-at')} mandatory error={errors?.startAt}>
          <FormDatePicker
            name="startAt"
            defaultValue={data?.startAt || defaultData?.startAt}
            isInvalid={!!errors?.startAt}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('common:managers')} error={errors?.users}>
          <FormSelectUsersWithRights
            name="users"
            placeholder={t('common:please-choose')}
            fetchEntity="users"
            entityValue="reference"
            defaultValue={data?.users}
            isInvalid={!!errors?.users}
            isMultiple
            isAsync
            action="CREATE"
            categoryType="PAYREQUESTS"
          />
          <p className="PaymentRequestForm__helper">{t('payment-requests:leave-user-empty')}</p>
        </FormGroup>
      </FormFieldset>
      <FormGroup label={t('payment-requests:receipt')} error={errors?.receipt}>
        {!receipt && (
          <Dropzone
            maxFiles={1}
            onDrop={(files: File[]) => getBase64(files[0])}
            accept={ACCEPT_FILES_TYPE.PDF}
            customInstructions={receiptName || t('common:dropzone.bill-content')}
          />
        )}
        {receipt && (
          <PDFViewer base64PDF={receipt} scale={0.3} onRemove={handleRemove} />
        )}
      </FormGroup>
      {eavs && eavs.payRequest.length > 0 && (
        <FormEavs
          eavs={eavs.payRequest}
          defaultValues={data?.eavs}
          errors={errors?.eavs as unknown as Errors.Validation}
        />
      )}
    </div>
  );
});

export default observer(PaymentRequestForm);
