import './index.scss';
import { useMemo, useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import type { Column, Row } from 'react-table';
import type { EavConstructor } from 'types/models';
import useApiRequest from 'hooks/useApiRequest';
import useContextualTranslation from 'hooks/useContextualTranslation';
import organizationStore from 'stores/Organization';
import SortableList from 'components/SortableList';
import AttributesColumns from '../Columns';

type Props = {
  type: 'CLIENT' | 'PAY_REQUEST',
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const AttributesListing = ({ onActionDone, onActionError, type }: Props): JSX.Element => {
  const {
    linesOfBusiness,
    eavs,
    currentOrganization,
    refresh: refetchOrganization,
  } = organizationStore;
  const { ct, t } = useContextualTranslation(linesOfBusiness);
  const { put, isLoading: isReordering } = useApiRequest();
  const [renderTick, setRenderTick] = useState(1);

  const sortedData = useMemo<EavConstructor[]>(() => {
    const data = (type === 'CLIENT' ? [...eavs.client] : [...eavs.payRequest]) ?? [];
    data.sort(({ label: labelA, position: posA }, { label: labelB, position: posB }) => {
      if (posA === posB) {
        return labelA.localeCompare(labelB);
      }
      return (posA ?? 0) > (posB ?? 0) ? 1 : -1;
    });
    return data;
  }, [eavs, type]);

  const handleActionDone = useCallback((message: string) => {
    setRenderTick((lastTick) => lastTick + 1);
    onActionDone(message);
  }, [onActionDone]);

  const columns = useMemo<Column<EavConstructor>[]>(() => (
    AttributesColumns(t, onActionError, handleActionDone, type)
  ), [t, onActionError, handleActionDone, type]);

  const getRowClassName = useCallback((row: Row<EavConstructor>) => {
    const { overviewDisplayed } = row.original;
    if (overviewDisplayed) {
      return 'AttributesListing__row AttributesListing__row--overview-displayed';
    }
    return 'AttributesListing__row';
  }, []);

  const handleReorder = useCallback(async (newDataList: EavConstructor[]) => {
    if (!currentOrganization) {
      return;
    }

    const saveData = {
      subject: type,
      list: newDataList.map(({ id, identifier }, index) => ({
        id,
        identifier,
        position: index + 1,
      })),
    };

    try {
      const response = await put(
        `organization/${currentOrganization.reference}/eav/position`,
        saveData,
      );
      if (!response) {
        throw new Error();
      }
      refetchOrganization();
      onActionDone(t('attributes:toast.success.reordering'));
    } catch {
      onActionError(t('attributes:toast.error.reordering'));
    }
  }, [currentOrganization, type, put, refetchOrganization, onActionDone, t, onActionError]);

  const hasAttributes = sortedData.length > 0;

  return (
    <div className="AttributesListing">
      <p className="AttributesListing__description">
        {type === 'CLIENT' ? ct('attributes:description-client') : ct('attributes:description-payment-request')}
      </p>
      {!hasAttributes && (
        <p className="AttributesListing__no-data">{ct('attributes:no-data')}</p>
      )}
      {hasAttributes && (
        <SortableList<EavConstructor, 'id'>
          key={renderTick}
          savedData={sortedData}
          columns={columns}
          onReorder={handleReorder}
          getRowClassName={getRowClassName}
          isSaving={isReordering}
        />
      )}
      {hasAttributes && (
        <p className="AttributesListing__help">{t('attributes:you-can-reorder-to-sort')}</p>
      )}
    </div>
  );
};

export default observer(AttributesListing);
