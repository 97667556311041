import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
import getI18nPlanChangeCondition from 'utils/getI18nPlanChangeCondition';

interface Props {
  group?: boolean,
  dateReference: Misc.PlanDateReference,
  planChange?: {
    conditions: string[],
    newPlan: string,
  },
}

const PlanDetailsInfo: React.FC<Props> = (props) => {
  const { group = false, dateReference, planChange } = props;
  const { t } = useTranslation();

  return (
    <div className="PlanDetailsInfo">
      <div className="PlanDetailsInfo__group">
        <h4 className="PlanDetailsInfo__group__title">{t('plans:reference-date')}</h4>
        <div className="PlanDetailsInfo__group__container">
          {dateReference === 'plan-setup' && t('plans:reference-date-options.plan-setup')}
          {dateReference === 'invoice-deadline' && t('plans:reference-date-options.invoice-deadline')}
          {dateReference === 'invoice-oldest-deadline' && t('plans:reference-date-options.invoice-oldest-deadline')}
        </div>
      </div>
      <div className="PlanDetailsInfo__group">
        <h4 className="PlanDetailsInfo__group__title">{t('plans:grouped-reminder')}</h4>
        <div className="PlanDetailsInfo__group__container">
          {group ? t('common:yes') : t('common:no')}
        </div>
      </div>
      <div className="PlanDetailsInfo__group">
        <h4 className="PlanDetailsInfo__group__title">{t('plans:conditional-plan-change')}</h4>
        <div className="PlanDetailsInfo__group__container">
          <div className="PlanDetailsInfo__group__container__plan-change">
            {!planChange && (
              <p className="PlanDetailsInfo__group__container__plan-change__no-data">{t('common:none')}</p>
            )}
            {planChange && (
              <>
                <p className="PlanDetailsInfo__group__container__plan-change__new-plan">{planChange.newPlan}</p>
                {planChange.conditions.map((condition) => (
                  <p className="PlanDetailsInfo__group__container__plan-change__badge" key={condition}>
                    {t(getI18nPlanChangeCondition(condition))}
                  </p>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanDetailsInfo;
