import './index.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
import Action from './Action';

interface Props {
  identifier: string,
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
  tasks: Misc.PlanTemplateTask[],
  reminders: Misc.PlanTemplateReminder[],
  dispatches: Misc.PlanTemplateDispatch[],
  dateReference: Misc.PlanDateReference,
  isGroup: boolean,
}

const PlanDetailsActions: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const {
    identifier,
    tasks,
    reminders,
    dispatches,
    dateReference,
    isGroup,
    onActionError,
    onActionDone,
  } = props;

  const orderedActions = useMemo(() => (
    [
      ...tasks.map((task) => ({ ...task, type: 'TASK' as Misc.ActionType })),
      ...reminders.map((reminder) => ({ ...reminder, type: 'REMINDER' as Misc.ActionType })),
    ].sort((elem1, elem2) => elem1.day - elem2.day)
  ), [reminders, tasks]);

  return (
    <div className="PlanDetailsActions">
      <div className="PlanDetailsActions__section">
        <h4 className="PlanDetailsActions__section__title">{t('plans:dispatch')}</h4>
        <div className="PlanDetailsActions__section__container">
          {!dispatches.length && (
            <p className="PlanDetailsActions__section__container__no-data">{t('common:none_f')}</p>
          )}
          {dispatches.map((action) => (
            <Action
              onActionDone={onActionDone}
              onActionError={onActionError}
              identifier={identifier}
              action={action}
              type="DISPATCH"
              isGroup={isGroup}
              key={`${action.name}-${action.day}`}
              dateReference={dateReference}
            />
          ))}
        </div>
      </div>
      <div className="PlanDetailsActions__section">
        <h4 className="PlanDetailsActions__section__title">{t('common:actions')}</h4>
        <div className="PlanDetailsActions__section__container">
          {!tasks.length && !reminders.length && (
            <p className="PlanDetailsActions__section__container__no-data">{t('common:none_f')}</p>
          )}
          {orderedActions.map((action) => (
            <Action
              onActionDone={onActionDone}
              onActionError={onActionError}
              identifier={identifier}
              action={action}
              isGroup={isGroup}
              type={action.type as Misc.ActionType}
              key={`${action.name}-${action.day}`}
              dateReference={dateReference}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlanDetailsActions;
