import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import type { RightsAction, RightsCategoryType } from 'types/models';
import organizationStore from 'stores/Organization';
import authStore from 'stores/Auth';
import { UserRightsController, UserRightsSwitch } from 'components/UserRights';
import FormSelect, { Props as FormSelectProps } from 'components/FormSelect';
import { getCategoriesFromCategoryType } from './utils';

interface Props extends FormSelectProps {
  action: RightsAction,
  categoryType: RightsCategoryType,
}

const FormSelectUsersWithRights = (props: Props): JSX.Element => {
  const { action, categoryType, name } = props;
  const categories = getCategoriesFromCategoryType(categoryType);
  const { userIdentifier } = organizationStore;
  const { user } = authStore;

  return (
    <div className="FormSelectUsersWithRights">
      <UserRightsSwitch>
        <UserRightsController action={action} category={categories.assignedToSomeone}>
          <FormSelect {...props} />
        </UserRightsController>
        <UserRightsController action={action} category={categories.unassigned}>
          <FormSelect
            {...props}
            selectOptions={[
              { label: `${user?.firstName} ${user?.lastName}`, value: userIdentifier || '' },
            ]}
            isAsync={false}
          />
        </UserRightsController>
        <UserRightsController action={action} category={categories.assignedToMe}>
          <div className="FormSelectUsersWithRights__unavailable">
            {`${user?.firstName} ${user?.lastName}`}
          </div>
          <input
            type="text"
            value={userIdentifier || ''}
            name={name}
            onChange={() => {}}
            hidden
          />
        </UserRightsController>
      </UserRightsSwitch>
    </div>
  );
};

export default observer(FormSelectUsersWithRights);
