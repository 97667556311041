import { useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import type { ManagerType } from 'types/models';
import { UserRightsManagerController } from 'components/UserRights';
import customerContext from 'pages/Customer/customerContext';
import Button from 'components/Button';
import ContactEditModal from 'components/ContactEditModal';

type Props = {
  managers: ManagerType[] | null | undefined,
};

const CustomerActionNewContact = ({ managers }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { data, onActionDone } = useContext(customerContext);

  const handleActionDone = useCallback((message: string) => {
    if (onActionDone) {
      onActionDone(message);
    }
  }, [onActionDone]);

  const [showContactEditModal, hideContactEditModal] = useModal(() => (
    <ContactEditModal
      editType="new-contact"
      customerId={data?.id}
      defaultCustomerId={data?.id}
      onClose={hideContactEditModal}
      onDone={(message) => { hideContactEditModal(); handleActionDone(message); }}
    />
  ), [data, handleActionDone]);

  return (
    <UserRightsManagerController
      action="CREATE"
      categoryType="CONTACTS"
      managers={managers}
    >
      <Button
        variant="list"
        className="CustomerActionNewContact"
        onClick={showContactEditModal}
      >
        {t('common:new-contact')}
      </Button>
    </UserRightsManagerController>
  );
};

export default CustomerActionNewContact;
