import { useEffect, useState } from 'react';
import useSelectCustomer from '../useSelectCustomer';
import FormSelectCustomerShell, { CommonProps } from '../Shell';

type Props = CommonProps & {
  customerReference?: string,
  refreshTick?: number,
};

const FormSelectCustomerWithDefault = (props: Props): JSX.Element => {
  const { customerReference, refreshTick = 0 } = props;
  const [selectedValue, setSelectedValue] = useState<string | undefined>(undefined);

  const { getOptions, findDefaultOption, options } = useSelectCustomer(
    setSelectedValue,
    { withId: false, defaultValue: customerReference, refreshTick },
  );

  useEffect(() => {
    const shouldSearchValue = (
      refreshTick === 0
      && options.length === 0
      && !!customerReference
      && selectedValue === undefined
    );

    if (shouldSearchValue) {
      findDefaultOption();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTick, options, customerReference, selectedValue]);

  useEffect(() => {
    if (refreshTick > 0) {
      findDefaultOption();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTick]);

  return (
    <FormSelectCustomerShell
      {...props}
      getOptions={getOptions}
      options={options}
      valueToSelect={selectedValue || ''}
    />
  );
};

export default FormSelectCustomerWithDefault;
