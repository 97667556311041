import './index.scss';
import { useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import type { PayRequest, HistoryStep } from 'types/models';
import type Misc from 'types/misc';
import type { FetchHistoryParams } from 'api/historySteps';
import { fetchHistory } from 'api/historySteps';
import organizationStore from 'stores/Organization';
import useFetch from 'hooks/useFetch';
import Icon from 'components/Icon';
import PaymentRequestInfos from '../Infos';
import ActionsCollapse from '../Actions';

interface Props {
  payRequest: PayRequest,
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
  reloadTick: number,
}

const PaymentRequestContentGrouped = (props: Props): JSX.Element => {
  const { payRequest, onActionDone, onActionError, reloadTick } = props;
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;

  const {
    data: historySteps,
    refetch,
    isFetching,
  } = useFetch<FetchHistoryParams, Misc.Listing<HistoryStep>>(
    {
      cacheKey: 'organizationHistorySteps',
      organization: currentOrganization?.reference,
      payRequestId: payRequest.id,
    },
    fetchHistory,
  );

  useEffect(() => {
    refetch();
  }, [refetch, reloadTick]);

  const linkToPendingActions = useMemo(() => (
    queryString.stringify({
      client: payRequest.client.id,
      period: 'today',
      type: ['task_to_do', 'reminder_to_do', 'contact_to_do', 'planned_reminder'],
    }, { arrayFormat: 'comma' })
  ), [payRequest.client.id]);

  const linkToFuturesActions = useMemo(() => (
    queryString.stringify({
      client: payRequest.client.id,
      period: 'futur',
      type: ['task_to_do', 'reminder_to_do', 'contact_to_do', 'planned_reminder'],
    }, { arrayFormat: 'comma' })
  ), [payRequest.client.id]);

  return (
    <div className="PaymentRequestContentGrouped">
      <PaymentRequestInfos
        payRequest={payRequest}
        onActionDone={onActionDone}
      />
      <div className="PaymentRequestContentGrouped__actions">
        <h3 className="PaymentRequestContentGrouped__actions__title">
          {t('payment-requests:steps-group-future')}
        </h3>
        <div className="PaymentRequestContentGrouped__actions__links">
          <Link to={`/actions-future?${linkToFuturesActions}`}>
            {t('payment-requests:go-to-list')}
            <Icon name="chevron-right" />
          </Link>
        </div>
      </div>
      <div className="PaymentRequestContentGrouped__actions">
        <h3 className="PaymentRequestContentGrouped__actions__title">
          {t('payment-requests:steps-group-waiting')}
        </h3>
        <div className="PaymentRequestContentGrouped__actions__links">
          <Link to={`/actions-future?${linkToPendingActions}`}>
            {t('payment-requests:go-to-list')}
            <Icon name="chevron-right" />
          </Link>
        </div>
      </div>
      <ActionsCollapse
        type="history"
        isDefaultOpen
        steps={historySteps}
        payRequest={payRequest}
        onActionDone={onActionDone}
        onActionError={onActionError}
        isLoading={isFetching}
      />
    </div>
  );
};

export default observer(PaymentRequestContentGrouped);
