import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import type { AnalysisActivityChartData } from 'types/models';
import type { FetchAllParams } from 'api/analysis';
import { fetchAllActivity } from 'api/analysis';
import useFetch from 'hooks/useFetch';
import organizationStore from 'stores/Organization';
import getYearMonthISO from 'utils/getYearMonthIso';
import Loading from 'components/Loading';
import GraphSection from '../GraphSection';
import NoDataGraph from '../NoDataGraph';
import ReminderSentChart from './ReminderSentChart';

const Activity = (): JSX.Element => {
  const { currentOrganization } = organizationStore;
  const { t } = useTranslation();

  const {
    data,
    isLoading,
  } = useFetch<FetchAllParams, AnalysisActivityChartData[]>({
    cacheKey: 'activityStat',
    organization: currentOrganization?.reference,
  }, fetchAllActivity);

  const reminderSentIsEmpty = useMemo(() => {
    if (!data) {
      return false;
    }
    const today = new Date();
    const periodBeginning = new Date(
      today.getFullYear() - 1, today.getMonth() + 1, today.getDate(),
    );

    while (periodBeginning < today) {
      const dateFormat = getYearMonthISO(periodBeginning);
      const foundElement = data.find(({ date }) => (date === dateFormat));
      if (foundElement) {
        return false;
      }
      periodBeginning.setMonth(periodBeginning.getMonth() + 1);
    }
    return true;
  }, [data]);

  return (
    <GraphSection
      title={t('analysis:activity.reminder-sent')}
      description={t('analysis:activity.description')}
    >
      {isLoading && <Loading />}
      {data && !reminderSentIsEmpty && (
        <ReminderSentChart data={data} />
      )}
      {(!data || reminderSentIsEmpty) && !isLoading && (
        <NoDataGraph variant="activity" />
      )}
    </GraphSection>
  );
};

export default observer(Activity);
