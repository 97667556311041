import './index.scss';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import type Misc from 'types/misc';
import type { PayRequest } from 'types/models';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import CreateReminder from 'components/CreateReminder/Edit';
import CreateTaskForm from 'pages/PaymentRequest/Modals/CreateTask';

interface Props {
  payRequest: PayRequest,
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
}

const SuggestCreateAction = ({ onActionDone, payRequest }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [channel, setChannel] = useState<Misc.Channel>('SMS');

  const [showCreateTaskModal, hideCreateTaskModal] = useModal(() => (
    <CreateTaskForm
      onDone={onActionDone}
      onClose={hideCreateTaskModal}
      payRequestData={payRequest}
    />
  ), [onActionDone, payRequest]);

  const [showCreateReminder, hideCreateReminder] = useModal(() => (
    <CreateReminder
      channel={channel}
      onDone={onActionDone}
      onClose={hideCreateReminder}
      payRequestData={payRequest}
    />
  ), [payRequest, channel, onActionDone]);

  const handleChooseChannel = useCallback((channelChosen: Misc.Channel) => {
    setChannel(channelChosen);
    showCreateReminder();
  }, [showCreateReminder]);

  return (
    <div className="SuggestCreateAction">
      <div className="SuggestCreateAction__warning">
        <Icon name="warning-circle" />
        <h4 className="SuggestCreateAction__warning__text">{t('actions:no-planned-action')}</h4>
      </div>
      <div>
        <Dropdown toggle={t('actions:create-action')} variant="outline">
          <Button variant="list" onClick={showCreateTaskModal}>
            {t('payment-requests:add-task')}
          </Button>
          <Button variant="list" onClick={() => { handleChooseChannel('SMS'); }}>
            {t('payment-requests:add-reminder.sms')}
          </Button>
          <Button variant="list" onClick={() => { handleChooseChannel('EMAIL'); }}>
            {t('payment-requests:add-reminder.email')}
          </Button>
          <Button variant="list" onClick={() => { handleChooseChannel('LETTER'); }}>
            {t('payment-requests:add-reminder.letter')}
          </Button>
          <Button variant="list" onClick={() => { handleChooseChannel('REGISTERED_LETTER'); }}>
            {t('payment-requests:add-reminder.registered-letter')}
          </Button>
        </Dropdown>
      </div>
    </div>
  );
};

export default SuggestCreateAction;
