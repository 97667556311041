import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
import Modal from 'components/Modal';
import Button from 'components/Button';
import getFilterValue from '../getFilterValue';
import FilterItems from '../Items';

type Props = {
  filtersList: Misc.FilterDeclarationItem[],
  currentFilters: Misc.Filter[],
  onValidate(): void,
  onCancel(): void,
  onChange(filter: Misc.Filter): void,
  hideModal(): void,
};

const DataTableFiltersModal = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    filtersList,
    currentFilters,
    onValidate,
    onChange,
    onCancel,
    hideModal,
  } = props;

  const handleValidateModal = useCallback(() => {
    onValidate();
    hideModal();
  }, [onValidate, hideModal]);

  const handleCancel = useCallback(() => {
    hideModal();
    onCancel();
  }, [hideModal, onCancel]);

  return (
    <Modal
      isOpened
      title={t('common:filter-by')}
      className="DataTableFilters__modal"
      onClose={handleCancel}
    >
      <div className="DataTableFilters__modal__container">
        <FilterItems
          filters={filtersList}
          currentFilters={currentFilters}
          getFilterValue={getFilterValue(currentFilters)}
          onChange={onChange}
          isModal
        />
      </div>
      <div className="DataTableFilters__modal__footer">
        <Button variant="primary" onClick={handleValidateModal}>
          {t('common:validate')}
        </Button>
      </div>
    </Modal>
  );
};

export default DataTableFiltersModal;
