import { Link } from 'react-router-dom';
import { Fragment } from 'react';
import type { TFunction } from 'i18next';
import type { Column } from 'react-table';
import type { PayRequestForList } from 'types/models';
import formatIntlDate from 'utils/formatIntlDate';
import formatIntlNumber from 'utils/formatIntlNumber';
import organizationStore from 'stores/Organization';
import NoteIcon from 'components/NoteIcon';
import PhaseDate from 'components/PhaseDate';
import { UserRightsManagerController } from 'components/UserRights';
import ListHistorySummary from 'components/ListHistorySummary';
import { ExternallyMonitoredController } from 'components/ExternallyMonitored';
import ClientName from 'components/ClientName';
import Badge from 'components/Badge';
import RowActions from '../Actions/Row';

const PayRequestColumns = (
  ct: TFunction,
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
): Column<PayRequestForList>[] => [
  {
    Header: ct<string>('payment-requests:reference'),
    accessor: 'reference',
    Cell: ({ row, value }) => (
      <>
        <div className="PaymentRequests__columns__reference">
          <Link
            to={`/payment-request/${row.original.id}`}
            className="PaymentRequests__columns__link"
          >
            {value}
          </Link>
        </div>
        <div className="PaymentRequests__columns__reference__plan">
          {row.original.group && (
            <Badge variant="GROUP">{ct('common:group')}</Badge>
          )}
          {row.original.group?.id ? row.original.group.plan : row.original.plan}
          {row.original.planIdentifier ? `(${row.original.planIdentifier})` : ''}
        </div>
      </>
    ),
  },
  {
    Header: ct<string>('common:client'),
    accessor: 'customer',
    Cell: ({ value }) => (
      <div className="PaymentRequests__columns__client">
        <Link to={`/customer/${value.id}`}>
          <ClientName client={value} />
        </Link>
        {value.corporateName !== value.reference && (
          <div className="PaymentRequests__columns__client__ref">
            {value.reference}
          </div>
        )}
      </div>
    ),
  },
  {
    Header: ct<string>('common:created-at-f'),
    accessor: 'startAt',
    sortType: 'datetime',
    sortDescFirst: true,
    Cell: ({ value }) => <Fragment>{formatIntlDate(value, 'dateLong')}</Fragment>,
  },
  {
    Header: ct<string>('common:amount'),
    accessor: 'amount',
    sortType: 'basic',
    sortDescFirst: true,
    Cell: ({ row, value }) => (
      <Fragment>
        {formatIntlNumber(
          row.original.status === 'PAID' ? row.original.initialCost : value,
          row.original.currency || organizationStore.currency,
        )}
      </Fragment>
    ),
  },
  {
    Header: ct<string>('common:deadline'),
    accessor: 'dueAt',
    sortType: 'datetime',
    sortDescFirst: true,
    Cell: ({ row, value }) => {
      const { status } = row.original;
      const statusId = (status !== 'IN_PROGRESS') ? status : undefined;
      return <PhaseDate date={value} phaseId={row.original.phase} statusId={statusId} />;
    },
  },
  {
    Header: ct<string>('common:next-action'),
    accessor: 'nextSending',
    sortType: 'datetime',
    sortDescFirst: true,
    Cell: ({ row }) => {
      const { id, nextSending, promiseToPayAt, lastSending, group } = row.original;
      return (
        <ListHistorySummary
          payRequestId={id}
          data={{ nextSending, promiseToPayAt, lastSending }}
          isGroup={!!group}
        />
      );
    },
  },
  {
    Header: '',
    accessor: 'note',
    disableSortBy: true,
    Cell: ({ value }) => (
      <div className="PaymentRequests__columns__note">
        {value?.id && <NoteIcon note={value} />}
      </div>
    ),
  },
  {
    Header: ct<string>('common:commands'),
    accessor: 'id',
    disableSortBy: true,
    Cell: ({ row, value: id }) => (
      <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
        <UserRightsManagerController action="UPDATE" managers={row.original.managers} categoryType="PAYREQUESTS">
          <RowActions
            id={id}
            downloadPath={
              row.original.invoiceName
                ? `${row.original.id}/${row.original.token}`
                : undefined
            }
            reference={row.original.reference}
            hasGroup={!!row.original.group}
            muted={row.original.muted}
            defaultPromiseToPay={row.original.promiseToPayAt}
            currency={row.original.currency}
            onActionDone={onActionDone}
            onActionError={onActionError}
            status={row.original.status}
          />
        </UserRightsManagerController>
      </ExternallyMonitoredController>
    ),
  },
];

export default PayRequestColumns;
