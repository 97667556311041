import './index.scss';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import organizationStore from 'stores/Organization';
import type Misc from 'types/misc';
import DropdownActions from 'components/DropdownActions';
import Button from 'components/Button';
import Icon from 'components/Icon';
import useConfirm from 'components/Confirm/useConfirm';
import Confirm from 'components/Confirm';
import useApiRequest from 'hooks/useApiRequest';
import ModalEditPlan from './ModalEditPlan';
import SettingsPlansPlanActionButtons from './Buttons';
import PlanDetails from './Details';
import AddActionButton from './AddActionButton';

type Props = {
  name: string,
  identifier: string,
  isGroup: boolean,
  onActionDone(message: string): void,
  onActionError(message: string): void,
  onShowModalDuplicatePlan(planName: string): void,
};

const SettingsPlan = (props: Props): JSX.Element => {
  const {
    name,
    isGroup,
    identifier,
    onActionDone,
    onActionError,
    onShowModalDuplicatePlan,
  } = props;
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const { remove } = useApiRequest();
  const [detailsOpened, setDetailsOpened] = useState<boolean>(false);
  const [dateReference, setDateReference] = useState<Misc.PlanDateReference>('plan-setup');
  const [reloadTick, setReloadTick] = useState<number>(0);

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const toggleDetails = useCallback(() => {
    setDetailsOpened(!detailsOpened);
  }, [detailsOpened]);

  const handleSubmitDelete = useCallback(async () => {
    hideConfirm();
    if (!currentOrganization) {
      return;
    }

    const response = await remove(`manage-plans/${currentOrganization?.reference}/${identifier}`);
    if (response) {
      onActionDone(t('plans:toast.success.deleted-plan', { name }));
      return;
    }
    onActionError(t('plans:toast.error.delete-plan', { name }));
  }, [hideConfirm, currentOrganization, remove, identifier, onActionError, t, name, onActionDone]);

  const handleActionDone = useCallback((message: string) => {
    setReloadTick(reloadTick + 1);
    onActionDone(message);
  }, [onActionDone, reloadTick]);

  const [showModalEditPlan, hideModalEditPlan] = useModal(() => (
    <ModalEditPlan
      name={name}
      identifier={identifier}
      onClose={hideModalEditPlan}
      onActionDone={handleActionDone}
      onActionError={onActionError}
    />
  ), [name, identifier, handleActionDone, onActionError]);

  const showModalDeletePlan = useCallback(() => {
    showConfirm(t('plans:remove'), t('plans:confirm-remove'));
  }, [showConfirm, t]);

  const handleDuplicate = useCallback(() => {
    onShowModalDuplicatePlan(identifier);
  }, [identifier, onShowModalDuplicatePlan]);

  const actions = useMemo(() => (
    SettingsPlansPlanActionButtons(t, showModalEditPlan, showModalDeletePlan, handleDuplicate)
  ), [handleDuplicate, showModalDeletePlan, showModalEditPlan, t]);

  return (
    <div className="Plan">
      <div className="Plan__header">
        <div className="Plan__header__titles">
          <h4 className="Plan__header__titles__name">{name}</h4>
          <div className="Plan__header__titles__subtitles">
            <p className="Plan__header__titles__subtitles__identifier">{identifier}</p>
            {!isGroup && (
              <span className="Plan__header__titles__subtitles__solo">{t('common:solo')}</span>
            )}
            {isGroup && (
              <span className="Plan__header__titles__subtitles__group">{t('common:group')}</span>
            )}
          </div>
        </div>
        <div className="Plan__header__buttons">
          {detailsOpened && (
            <AddActionButton
              name={name}
              identifier={identifier}
              onActionDone={handleActionDone}
              onActionError={onActionError}
              dateReference={dateReference}
            />
          )}
          <DropdownActions actions={actions} />
          <Button onClick={toggleDetails} variant="light" className="Plan__header__buttons__button">
            <Icon name={detailsOpened ? 'caret-up' : 'caret-down'} />
          </Button>
        </div>
      </div>
      {detailsOpened && (
        <div className="Plan__details">
          <PlanDetails
            onActionDone={handleActionDone}
            onActionError={onActionError}
            identifier={identifier}
            reloadTick={reloadTick}
            onGetDateReference={setDateReference}
          />
        </div>
      )}
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        entityName={name}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitDelete}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={t('common:remove')}
      />
    </div>
  );
};

export default SettingsPlan;
