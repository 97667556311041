import type Misc from 'types/misc';

const getI18nClientError = (
  value: string,
  message: string,
) => {
  switch (message) {
    case 'The value you selected is not a valid choice.':
      return 'reports:error-line.client-invalid';
    case 'client_payer_contact_control':
      return 'reports:error-line.client-contact-role';
    case 'unknown_client_entity':
      return 'reports:error-line.client-unknown';
    default:
      break;
  }
  return `${message} client`;
};

const getI18nReportLineError = (
  field: string,
  value: string,
  message: string,
  type: Misc.ReportItemType,
  withFormat: boolean,
) => {
  const defaultError = `${message} ${field}`;

  switch (field) {
    case 'import_line':
      return 'reports:error-line.import-line';
    case 'dueAt':
      return 'reports:error-line.due-at';
    case 'email':
      return 'reports:error-line.email';
    case 'firstName':
      return 'reports:error-line.first-name';
    case 'paidAt':
      return 'reports:error-line.paid-at';
    case 'cellPhone':
      return 'reports:error-line.cell-phone';
    case 'contact':
      return 'reports:error-line.contact';
    case 'corporateName':
      return 'reports:error-line.corporate-name';
    case 'pay_request':
      if (message === 'pay request not found') {
        return value
          ? 'reports:error-line.pay-request-ref'
          : 'reports:error-line.pay-request-blank';
      }
      return 'reports:error-line.pay-request';
    case 'phone':
      return 'reports:error-line.phone';
    case 'startAt':
      return 'reports:error-line.start-at';
    case 'templateDunningPlan':
      return 'reports:error-line.template-dunning';
    case 'country':
      return 'reports:error-line.country';
    case 'currency':
      return 'reports:error-line.currency';
    case 'users':
      return 'reports:error-line.users';
    case 'client':
      return getI18nClientError(value, message);
    case 'amount':
      if (message === 'invalid amount value') {
        return type === 'PAYMENT'
          ? 'reports:error-line.amount-invalid'
          : 'reports:error-line.amount-invalid';
      }
      if (message === 'This value is not valid.') {
        return 'reports:error-line.amount-invalid';
      }
      return (message === 'This value should not be blank.' || !value)
        ? `reports:error-line.amount-blank${type === 'PAYMENT' ? '-payment' : ''}`
        : defaultError;
    case 'contactRole':
      if (message === 'This value is not valid.') {
        return 'reports:error-line.contact-role-invalid';
      }
      return message === 'contact_exist_for_client'
        ? 'reports:error-line.contact-role-exists'
        : defaultError;
    case 'reference':
      if (type === 'PAYMENT') {
        return 'reports:error-line.ref-payment';
      }
      if (message === 'used_client_ref') {
        return 'reports:error-line.ref-client-used';
      }
      if (message === 'pay_request_not_exist') {
        return 'reports:error-line.pay-request-not-exists';
      }
      return message === 'This value should not be blank.'
        ? 'reports:error-line.ref-blank'
        : defaultError;
    default:
      break;
  }

  switch (message) {
    case 'wrong_client_type':
      return 'reports:error-line.eav-wrong-client-type';
    case 'eav_wrong_value':
    case 'eav_wrong_format':
      return withFormat ? 'reports:error-line.eav-wrong-format' : 'reports:error-line.eav-wrong';
    case 'eav_should_not_be_blank':
      return 'reports:error-line.eav-blank';
    default:
      return defaultError;
  }
};

export default getI18nReportLineError;
