import React from 'react';
import equal from 'deep-equal';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import type Errors from 'types/errors';
import type { Occupation } from 'types/models';
import organizationStore from 'stores/Organization';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import type { ModalFormData } from 'components/ModalForm';
import ModalForm from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import Confirm from 'components/Confirm';
import SignatureEditForm from './SignatureEditForm';

export type UserLetterSignature = {
  signatureLetter: string,
};

export type UserEmailSignature = {
  signatureEmail: string,
};

type UserSignature = UserLetterSignature | UserEmailSignature;

export type SignatureType = 'email' | 'letter';

interface Props {
  signatureType: SignatureType,
  occupation: Occupation,
  onClose: () => void,
  onShowToast: (message: string, type: ToastStatus) => void,
}

const ModalEditSignature: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const isMountedRef = useIsMountedRef();
  const {
    signatureType,
    occupation,
    onClose,
    onShowToast,
  } = props;

  const mapFormData = React.useCallback(
    (rawData: ModalFormData): UserSignature => ({
      signatureLetter: rawData?.signatureLetter as string,
      signatureEmail: rawData?.signatureEmail as string,
    }),
    [],
  );

  const initialData = React.useRef<UserSignature | null>(null);

  const [hasChanges, setHasChanges] = React.useState<boolean>(false);
  const [validationErrors, setValidationErrors] = React.useState<Errors.Validation | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = React.useState<boolean>(false);

  const handleInit = React.useCallback((formData: ModalFormData | null) => {
    initialData.current = formData ? mapFormData(formData) : null;
  }, [initialData, mapFormData]);

  const closeSelf = React.useCallback(() => {
    onClose();
    setShowCancelConfirm(false);
    setHasChanges(false);
  }, [onClose]);

  const handleChange = React.useCallback(
    (formData: ModalFormData | null) => {
      setHasChanges(!!formData && !equal(initialData.current, mapFormData(formData)));
    },
    [initialData, mapFormData],
  );

  const handleCancel = React.useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirm(true);
    } else {
      closeSelf();
    }
  }, [hasChanges, closeSelf]);

  const {
    put,
    isLoading,
    cancel,
    error,
  } = useApiRequest();

  const handleSubmit = React.useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData || !currentOrganization) {
        return;
      }

      const result = await put<Occupation>(
        `occupation/manage/${currentOrganization.id}`,
        mapFormData(formData),
      );

      if (!isMountedRef.current) {
        return;
      }

      if (result?.errors) {
        setValidationErrors(result.errors);
        return;
      }

      if (result?.identifier) {
        organizationStore.refresh();
        onShowToast(t('users:toast.success.settings-update'), 'success');
        onClose();
      }
    },
    [currentOrganization, mapFormData, put, isMountedRef, onShowToast, onClose, t],
  );

  const handleSignatureChange = React.useCallback((content: string) => {
    if (!initialData.current) {
      return;
    }

    let initialContent;
    if ('signatureLetter' in initialData.current) {
      initialContent = initialData.current.signatureLetter;
    } else if ('signatureEmail' in initialData.current) {
      initialContent = initialData.current.signatureEmail;
    }

    setHasChanges(initialContent !== content);
  }, [initialData]);

  React.useEffect(() => (
    () => { cancel(); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [currentOrganization]);

  const modalTitle = React.useMemo(() => (
    signatureType === 'letter' ? t('users:signature-letter') : t('users:signature-email')
  ), [signatureType, t]);

  return (
    <ModalForm
      title={modalTitle}
      className="ModalEditSignature"
      isOpened
      onInit={handleInit}
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
    >
      {error && <ErrorMessage error={error} />}
      {signatureType === 'email' && (
        <SignatureEditForm
          name="signatureEmail"
          defaultData={occupation.signatureEmail}
          errors={validationErrors}
          onChange={handleSignatureChange}
        />
      )}
      {signatureType === 'letter' && (
        <SignatureEditForm
          name="signatureLetter"
          defaultData={occupation.signatureLetter}
          errors={validationErrors}
          onChange={handleSignatureChange}
        />
      )}
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={() => { closeSelf(); }}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(ModalEditSignature);
