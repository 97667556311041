import './index.scss';
import { useCallback, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import useApiRequest from 'hooks/useApiRequest';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import type { CustomerMuteButtonProps } from '../index';

const MuteButton = (props: CustomerMuteButtonProps): JSX.Element => {
  const { customerData, onActionDone, onActionError, showText = false } = props;
  const { t } = useTranslation();
  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();
  const { put } = useApiRequest();

  const handleConfirmMuteToggle = useCallback(() => {
    showConfirm(t('clients:set-mute'), t('clients:actions.confirm.mute', { reference: customerData?.reference }));
  }, [showConfirm, t, customerData]);

  const handleMuteToggle = useCallback(async () => {
    hideConfirm();
    const response = await put(`client/manage/${customerData?.id}/mute`);
    if (!response) {
      if (onActionError) {
        onActionError(t('errors:unknown-retry'));
      }
      return;
    }

    if (onActionDone) {
      onActionDone(t('clients:actions.toast.muted-success', { reference: customerData?.reference }), '');
    }
  }, [customerData, put, onActionError, onActionDone, t, hideConfirm]);

  const classNames = classnames('CustomerMuteButtonMute', {
    'CustomerMuteButtonMute--with-text': showText,
  });

  return (
    <Fragment>
      <Button
        className={classNames}
        variant="list"
        title={t('clients:set-mute')}
        onClick={handleConfirmMuteToggle}
      >
        <Icon name="bell" className="CustomerMuteButtonMute__icon" />
        {showText && t('clients:set-mute')}
      </Button>
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        isShow={isConfirmShowed}
        onConfirm={handleMuteToggle}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={t('clients:set-mute')}
      />
    </Fragment>
  );
};

export default MuteButton;
