import './index.scss';
import type { DetailPhases } from 'types/models';
import getColorPhase from 'utils/getColorPhase';
import PercentBar from 'components/PercentBar';

type Props = {
  phase: DetailPhases,
};

const ListStatsByPhaseItemBar = ({ phase }: Props): JSX.Element => (
  <div className="ListStatsByPhaseItemBar">
    <span
      className="ListStatsByPhaseItemBar__item-index"
      style={{ color: getColorPhase(phase.id) }}
    >
      {phase.count}
    </span>
    <PercentBar percentage={phase.percentage} color={getColorPhase(phase.id)} />
  </div>
);

export default ListStatsByPhaseItemBar;
