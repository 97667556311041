import './index.scss';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type Errors from 'types/errors';
import type { EavConstructor } from 'types/models';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormSelect from 'components/FormSelect';
import Button from 'components/Button';
import ErrorValidationMessage from 'components/ErrorValidationMessage';
import Icon from 'components/Icon';
import PossibleValueItem from './PossibleValueItem';

type Props = {
  errors?: Errors.Validation | null,
  defaultData?: EavConstructor,
};

const ValueForm = ({ errors, defaultData }: Props): JSX.Element => {
  const { t } = useTranslation();

  const [possibleValues, setPossibleValues] = useState<string[]>(
    defaultData?.possibleValues?.map(({ value }) => value) || [],
  );
  const [numberOfPossibleValues, setNumberOfPossibleValues] = useState<number>(
    possibleValues.length,
  );

  const [defaultValue, setDefaultValue] = useState<string>(defaultData?.defaultValue);

  useEffect(() => {
    if (!possibleValues.includes(defaultValue)) {
      setDefaultValue('');
    }
  }, [possibleValues, defaultValue]);

  const handleAddPossibleValue = useCallback(() => {
    const tmpPossibleValues = [...possibleValues];
    tmpPossibleValues.push('');
    setNumberOfPossibleValues(numberOfPossibleValues + 1);
    setPossibleValues(tmpPossibleValues);
  }, [possibleValues, numberOfPossibleValues]);

  const handleRemovePossibleValue = useCallback((index: number) => {
    const tmpPossibleValues = [...possibleValues];
    setNumberOfPossibleValues(numberOfPossibleValues > 0 ? numberOfPossibleValues - 1 : 0);
    tmpPossibleValues.splice(index, 1);
    setPossibleValues(tmpPossibleValues);
  }, [possibleValues, numberOfPossibleValues]);

  const handleChangePossibleValue = useCallback((value: string, index: number) => {
    const tmpPossibleValues = [...possibleValues];
    tmpPossibleValues[index] = value;
    setPossibleValues(tmpPossibleValues);
  }, [possibleValues]);

  return (
    <div className="AddEditAttributesValueForm">
      {[...Array(numberOfPossibleValues)].map((_value, index) => (
        <PossibleValueItem
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          displayRemoveButton={index === numberOfPossibleValues - 1 && index > 0}
          index={index}
          defaultValue={possibleValues[index]}
          onChangePossibleValue={handleChangePossibleValue}
          onRemovePossibleValue={handleRemovePossibleValue}
          errors={errors}
        />
      ))}
      <ErrorValidationMessage error={errors?.possibleValues} />
      <Button variant="outline" onClick={handleAddPossibleValue}>
        <Icon name="plus-circle" />
      </Button>
      <FormFieldset>
        <FormGroup
          label={t('attributes:default-value')}
          className="AddEditAttributesValueForm__default-value"
          error={errors?.defaultValue}
        >
          <FormSelect
            name="defaultValue"
            value={defaultValue}
            onSelect={(_name, newValue) => { setDefaultValue(newValue || ''); }}
            selectOptions={[
              ...possibleValues.map((value) => ({ value, label: value })),
              { value: '', label: t('common:none_f') },
            ]}
          />
        </FormGroup>
      </FormFieldset>
    </div>
  );
};

export default ValueForm;
