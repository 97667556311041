import type { TFunction } from 'i18next';

const getToggleActionsI18n = (t: TFunction, ct: TFunction, count: number) => ({
  mute: {
    title: t('payment-requests:actions.mute'),
    confirmText: ct('payment-requests:actions.confirm.bulk-mute', { count }),
    toastText: ct('payment-requests:actions.toast.bulk-mute', { count }),
  },
  unmute: {
    title: t('payment-requests:actions.unmute'),
    confirmText: ct('payment-requests:actions.confirm.bulk-unmute', { count }),
    toastText: ct('payment-requests:actions.toast.bulk-unmute', { count }),
  },
  remove: {
    title: t('payment-requests:remove'),
    confirmText: ct('payment-requests:actions.confirm.bulk-remove', { count }),
    toastText: ct('payment-requests:actions.toast.bulk-remove', { count }),
  },
  'mark-as-paid': {
    title: t('payment-requests:mark-as-paid'),
    confirmText: ct('payment-requests:actions.confirm.bulk-mark-as-paid', { count }),
    toastText: ct('payment-requests:actions.toast.bulk-mark-as-paid', { count }),
  },
  'cancel-mark-as-paid': {
    title: t('payment-requests:cancel-mark-as-paid'),
    confirmText: ct('payment-requests:actions.confirm.bulk-cancel-mark-as-paid', { count }),
    toastText: ct('payment-requests:actions.toast.bulk-cancel-mark-as-paid', { count }),
  },
  litigate: {
    title: t('payment-requests:set-litigate'),
    confirmText: ct('payment-requests:actions.confirm.bulk-set-litigate', { count }),
    toastText: ct('payment-requests:actions.toast.bulk-set-litigate', { count }),
  },
  'cancel-litigate': {
    title: t('payment-requests:cancel-litigate'),
    confirmText: ct('payment-requests:actions.confirm.bulk-cancel-litigate', { count }),
    toastText: ct('payment-requests:actions.toast.bulk-cancel-litigate', { count }),
  },
  cancel: {
    title: t('common:disable'),
    confirmText: ct('payment-requests:actions.confirm.bulk-disable', { count }),
    toastText: ct('payment-requests:actions.toast.bulk-disable', { count }),
  },
  activate: {
    title: t('payment-requests:reactivate'),
    confirmText: ct('payment-requests:actions.confirm.bulk-reactivate', { count }),
    toastText: ct('payment-requests:actions.toast.bulk-reactivate', { count }),
  },
});

export default getToggleActionsI18n;
