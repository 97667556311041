import React from 'react';
import Axios, { Canceler } from 'axios';
import type Errors from 'types/errors';
import Request from 'utils/request';
import ResponseError from 'utils/errors';
import useIsMountedRef from './useIsMountedRef';

const { CancelToken } = Axios;

const useApiRequest = () => {
  const cancelRef = React.useRef<Canceler | null>(null);
  const isMountedRef = useIsMountedRef();

  const [error, setError] = React.useState<Errors.Request | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => (
    () => { // - ComponentWillUnmount
      if (cancelRef.current) {
        cancelRef.current('Cancel while unmount component');
      }
    }
  ), []);

  const cancel = React.useCallback(
    () => {
      setError(null);
      if (cancelRef.current) {
        cancelRef.current('Manual cancel');
      }
    },
    [],
  );

  const resetError = React.useCallback(
    () => {
      setError(null);
      setIsLoading(false);
    },
    [],
  );

  const createCanceler = React.useCallback(
    (cancelFn: Canceler) => (message: string | undefined) => {
      cancelFn(message);
    }, [],
  );

  const get = React.useCallback(
    async <ResultType>(url: string) => {
      if (isLoading) {
        return null;
      }

      setError(null);
      setIsLoading(true);
      let result = null;

      try {
        const { data, status } = await Request.get<ResultType>(
          url,
          {
            cancelToken: new CancelToken((cancelFn) => {
              cancelRef.current = createCanceler(cancelFn);
            }),
          },
        );
        result = data;
        if (status === 403) {
          throw new ResponseError(403, 'unauthorized');
        }
      } catch (err: unknown) {
        if (isMountedRef.current) {
          setError(err as ResponseError);
        }
      } finally {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      }

      return result;
    },
    [isLoading, isMountedRef, createCanceler],
  );

  const post = React.useCallback(
    async <ResultType = {}>(url: string, payload: {}) => {
      if (isLoading) {
        return null;
      }

      setError(null);
      setIsLoading(true);
      let result = null;

      try {
        const { data, status } = await Request.post<ResultType & { errors?: Errors.Validation }>(
          url,
          payload,
          {
            cancelToken: new CancelToken((cancelFn) => {
              cancelRef.current = createCanceler(cancelFn);
            }),
          },
        );
        result = data;
        if (status === 403) {
          throw new ResponseError(403, 'unauthorized');
        }
        if (data?.errors) {
          throw new ResponseError(400, 'validation-error');
        }
      } catch (err) {
        if (isMountedRef.current) {
          setError(err as Errors.Request);
        }
      } finally {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      }

      return result;
    },
    [isLoading, isMountedRef, createCanceler],
  );

  const postFormData = React.useCallback(
    async <ResultType = {}>(url: string, formData: FormData) => {
      if (isLoading) {
        return null;
      }

      setError(null);
      setIsLoading(true);
      let result = null;

      try {
        const { data, status } = await Request.post<ResultType & { errors?: Errors.Validation }>(
          url,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
            cancelToken: new CancelToken((cancelFn) => {
              cancelRef.current = createCanceler(cancelFn);
            }),
          },
        );
        result = data;
        if (status === 403) {
          throw new ResponseError(403, 'unauthorized');
        }
        if (data.errors) {
          throw new ResponseError(400, 'validation-error');
        }
      } catch (err) {
        if (isMountedRef.current) {
          setError(err as Errors.Request);
        }
      } finally {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      }

      return result;
    },
    [isLoading, isMountedRef, createCanceler],
  );

  const put = React.useCallback(
    async <ResultType>(url: string, payload?: {}) => {
      if (isLoading) {
        return null;
      }

      setError(null);
      setIsLoading(true);
      let result = null;

      try {
        const { data, status } = await Request.put<ResultType & { errors?: Errors.Validation }>(
          url,
          payload,
          {
            cancelToken: new CancelToken((cancelFn) => {
              cancelRef.current = createCanceler(cancelFn);
            }),
          },
        );
        result = data;
        if (status === 403) {
          throw new ResponseError(403, 'unauthorized');
        }
        if (data.errors) {
          throw new ResponseError(400, 'validation-error');
        }
      } catch (err) {
        if (isMountedRef.current) {
          setError(err as Errors.Request);
        }
      } finally {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      }

      return result;
    },
    [isLoading, isMountedRef, createCanceler],
  );

  const remove = React.useCallback(
    async <ResultType>(url: string) => {
      if (isLoading) {
        return null;
      }

      setError(null);
      setIsLoading(true);
      let result = null;

      try {
        const { data, status } = await Request.delete<ResultType>(
          url,
          {
            cancelToken: new CancelToken((cancelFn) => {
              cancelRef.current = createCanceler(cancelFn);
            }),
          },
        );
        result = data;
        if (status === 403) {
          throw new ResponseError(403, 'unauthorized');
        }
      } catch (err) {
        if (isMountedRef.current) {
          setError(err as Errors.Request);
        }
      } finally {
        if (isMountedRef.current) {
          setIsLoading(false);
        }
      }

      return result;
    },
    [isLoading, isMountedRef, createCanceler],
  );

  return {
    get,
    post,
    postFormData,
    put,
    remove,
    cancel,
    error,
    resetError,
    isLoading,
  };
};

export default useApiRequest;
