import type Misc from 'types/misc';
import type { Contact } from 'types/models';

const ExportLink = (
  fetchOptions: Misc.PaginatedFetchArgs<Contact>,
  filtering: Misc.Filter[],
  organizationReference: string | null,
  withClient: boolean,
): string => {
  const { sort } = fetchOptions;

  if (!organizationReference) {
    return '';
  }

  const queryData = [
    `withClient=${withClient ? '1' : '0'}`,
  ];

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }) => {
      queryData.push(`filters[${name}]=${value}`);
    });
  }

  if (sort) {
    const direction = sort.desc ? 'desc' : 'asc';
    queryData.push(`orderBy=${sort.id === 'contactRole' ? 'role' : sort.id}&orderDirection=${direction}`);
  }

  return `export/contact/${organizationReference}?${queryData.join('&')}`;
};

export default ExportLink;
