import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Column } from 'react-table';
import type Misc from 'types/misc';
import type { PayRequestDraft } from 'types/models';
import type { FetchAllParams, FetchCountParams } from 'api/pay-requests-draft';
import { fetchAll, fetchCount } from 'api/pay-requests-draft';
import Config from 'config';
import organizationStore from 'stores/Organization';
import useFetchPaginated from 'hooks/useFetchPaginated';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useFetch from 'hooks/useFetch';
import PayRequestsToCompleteFilters from 'stores/FilterStores/PayRequestsToCompleteFilters';
import PageList from 'components/PageList';
import DataTable from 'components/DataTable';
import ToastNotification from 'components/ToastNotification';
import useToast from 'components/ToastNotification/useToast';
import PayRequestToCompleteColumns from './Columns';
import Bulk from './Actions/Bulk';

const PaymentRequestsToComplete = (): JSX.Element => {
  const { linesOfBusiness, currentOrganization } = organizationStore;
  const { ct } = useContextualTranslation(linesOfBusiness);

  const defaultFetchOptions = {
    pageIndex: 0,
    pageSize: Config.DEFAULT_PAGE_SIZE,
    sort: { id: 'paidAt', desc: true },
  };

  // eslint-disable-next-line max-len
  const [fetchOptions, setFetchOptions] = useState<Misc.PaginatedFetchArgs<PayRequestDraft>>(
    defaultFetchOptions,
  );

  const {
    filters,
    addOrUpdateFilters,
    resetAllFilters,
    removeFilter,
  } = PayRequestsToCompleteFilters;

  useEffect(() => {
    resetAllFilters();
  }, [resetAllFilters]);

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const {
    data,
    error,
    serverPagination,
    isLoading,
    refetch,
  } = useFetchPaginated<FetchAllParams, PayRequestDraft>(
    {
      cacheKey: 'payRequestsDraft',
      organization: currentOrganization?.reference,
      filtering: filters,
      fetchOptions,
    },
    fetchAll,
  );

  const { refetch: refetchCount } = useFetch<FetchCountParams, Misc.CountRecords>(
    { cacheKey: 'draftCount', organization: currentOrganization?.reference }, fetchCount,
  );

  const handleActionDone = useCallback(
    (message: string) => {
      showToast(message, 'success');
      refetch();
      refetchCount();
    },
    [refetch, showToast, refetchCount],
  );

  const handleActionError = useCallback(
    (message: string) => {
      showToast(message, 'error');
    },
    [showToast],
  );

  const columns = useMemo<Column<PayRequestDraft>[]>(
    () => PayRequestToCompleteColumns(ct, handleActionDone, handleActionError),
    [ct, handleActionDone, handleActionError],
  );

  return (
    <PageList
      className="PaymentRequestsToComplete"
      title={ct('payrequests-draft:title')}
    >
      <DataTable<PayRequestDraft>
        columns={columns}
        data={data}
        serverPagination={serverPagination}
        isLoading={isLoading}
        error={error}
        withNoDataDrawing
        defaultSortBy={defaultFetchOptions.sort}
        filtering={filters}
        fetchData={setFetchOptions}
        onRemoveFilter={removeFilter}
        onChangeFilters={addOrUpdateFilters}
        withActions
        idAccessor="importId"
        bulkActions={(
          <Bulk
            onActionDone={handleActionDone}
            onActionError={handleActionError}
          />
        )}
      />
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </PageList>
  );
};

export default observer(PaymentRequestsToComplete);
