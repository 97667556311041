import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useModal } from 'react-modal-hook';
import organizationStore from 'stores/Organization';
import DownloadLink from 'components/DownloadLink';
import Button from 'components/Button';
import Modal from 'components/Modal';

const ImportsFileNav: React.FC = () => {
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;

  const getNavLink = React.useCallback(
    (type: string) => `${currentOrganization?.reference}/empty/${type}`,
    [currentOrganization],
  );

  const [showModal, hideModal] = useModal(() => (
    <Modal
      title={t('imports-payment:title')}
      isOpened
      onClose={hideModal}
      className="ImportsFileNav__modal"
    >
      <p className="ImportsFileNav__modal__content">
        {t('imports-payment:content')}
      </p>
      <a href={`mailto:${currentOrganization?.reference}@dunforce.com`} className="ImportsFileNav__modal__content__email">
        {`${currentOrganization?.reference}@dunforce.com`}
      </a>
      <p className="ImportsFileNav__modal__info">
        {t('imports-payment:info')}
      </p>
    </Modal>
  ), [currentOrganization, t]);

  if (!currentOrganization) {
    return null;
  }

  return (
    <div className="ImportsFileNav">
      <h2 className="ImportsFileNav__title">{t('imports:nav.title.manage')}</h2>
      <div className="ImportsFileNav__list">
        <NavLink to="/imports/reports" className="ImportsFileNav__list__link">
          {t('imports:nav.link.reports')}
        </NavLink>
        <NavLink to="/imports/history" className="ImportsFileNav__list__link">
          {t('imports:nav.link.history')}
        </NavLink>
      </div>
      <h2 className="ImportsFileNav__title">{t('imports:nav.title.support')}</h2>
      <div className="ImportsFileNav__list">
        <DownloadLink path={getNavLink('CLIENT')} className="ImportsFileNav__list__link">
          {t('imports:nav.link.template-client')}
        </DownloadLink>
        <DownloadLink path={getNavLink('CONTACT')} className="ImportsFileNav__list__link">
          {t('imports:nav.link.template-contact')}
        </DownloadLink>
        <DownloadLink path={getNavLink('PAY_REQUEST')} className="ImportsFileNav__list__link">
          {t('imports:nav.link.template-pay-request-create')}
        </DownloadLink>
        <DownloadLink path={getNavLink('PAY_REQUEST_STATUSES')} className="ImportsFileNav__list__link">
          {t('imports:nav.link.template-pay-request-status')}
        </DownloadLink>
        <DownloadLink path={getNavLink('CLIENT_CONTACT')} className="ImportsFileNav__list__link">
          {t('imports:nav.link.template-client-contact')}
        </DownloadLink>
        <Button variant="link" className="ImportsFileNav__list__link" onClick={showModal}>
          {t('imports:nav.link.email')}
        </Button>
      </div>
    </div>
  );
};

export default ImportsFileNav;
