import React from 'react';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import useApiRequest from 'hooks/useApiRequest';
import useContextualTranslation from 'hooks/useContextualTranslation';
import organizationStore from 'stores/Organization';
import type { Selectors } from 'types/models';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import Loading from 'components/Loading';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import DropdownActions from 'components/DropdownActions';
import ModalSelector from '../../ModalSelector';

interface Props {
  id: string,
  isGroup: boolean,
  defaultData: Selectors,
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
}

const SelectorsRowActions: React.FC<Props> = (props) => {
  const { linesOfBusiness, currentOrganization } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);

  const {
    id,
    isGroup,
    defaultData,
    onActionDone,
    onActionError,
  } = props;

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const { error, isLoading, remove } = useApiRequest();

  const handleConfirmDelete = React.useCallback(() => {
    showConfirm(t('common:remove'), ct('selectors:confirm-remove', { id }));
  }, [showConfirm, ct, t, id]);

  const [showModalEditSelector, hideModalEditSelector] = useModal(() => (
    <ModalSelector
      onClose={hideModalEditSelector}
      onActionDone={onActionDone}
      onActionError={onActionError}
      defaultData={defaultData}
      edit
      isGroup={isGroup}
    />
  ), [defaultData, isGroup, onActionDone, onActionError]);

  const handleSubmitDelete = React.useCallback(async () => {
    hideConfirm();
    const result = await remove(
      `/organization/${currentOrganization?.reference}/association-plans?identifier=${id}&group=${
        isGroup ? 'true' : 'false'
      }`,
    );
    if (!result) {
      onActionError(t('selectors:toast.error.delete'));
      return;
    }
    onActionDone(ct('selectors:toast.success.delete', { name: id }));
  }, [hideConfirm, remove, currentOrganization, id, isGroup, onActionDone, ct, onActionError, t]);

  React.useEffect(() => {
    if (error) {
      onActionError(error.message || t('selectors:toast.error.delete', { id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const actions = React.useMemo(() => [
    <ButtonWithUserRights
      action="UPDATE"
      category="PREFERENCES_SELECTORS"
      variant="list"
      onClick={showModalEditSelector}
      title={t('common:edit')}
    >
      {isLoading && <Loading hasNoText />}
      {!isLoading && t('common:edit')}
    </ButtonWithUserRights>,
    <ButtonWithUserRights
      action="DELETE"
      category="PREFERENCES_SELECTORS"
      variant="list-danger"
      onClick={handleConfirmDelete}
      title={t('common:remove')}
    >
      {isLoading && <Loading hasNoText />}
      {!isLoading && t('common:remove')}
    </ButtonWithUserRights>,
  ], [handleConfirmDelete, isLoading, showModalEditSelector, t]);

  return (
    <div className="Selectors__rowActions">
      <DropdownActions actions={actions} />
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        entityName={id}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitDelete}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={t('common:remove')}
      />
    </div>
  );
};

export default observer(SelectorsRowActions);
