import './index.scss';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import type { ReportItem } from 'types/models';
import type Misc from 'types/misc';
import { fetchAllReports, cancelImport } from 'api/imports';
import type { FetchAllReportsParams } from 'api/imports';
import useApiRequest from 'hooks/useApiRequest';
import organizationStore from 'stores/Organization';
import useFetch from 'hooks/useFetch';
import Loading from 'components/Loading';
import GoBackButton from 'components/GoBackButton';
import ToastNotification from 'components/ToastNotification';
import useToast from 'components/ToastNotification/useToast';
import extractReport from './extractReport';
import ButtonDatePicker from './ButtonDatePicker';
import Item from './Item';
import SelectReportsFormat from './SelectReportsFormat';

type RouterState = {
  doneUploading?: number,
};

const Reports = (): JSX.Element => {
  const { t } = useTranslation();
  const { state: routerState } = useLocation<RouterState | undefined>();
  const { doneUploading } = routerState || { doneUploading: 0 };
  const { currentOrganization, isSageOrganization, notifications } = organizationStore;

  const [reports, setReports] = useState<ReportItem[]>([]);
  const [filteredReports, setFilteredReports] = useState<ReportItem[]>([]);
  const [recentCount, setRecentCount] = useState<number | undefined>(0);
  const [dateFilter, setDateFilter] = useState<string | null>(null);

  const { isLoading, data, refetch } = useFetch<FetchAllReportsParams, Misc.ReportsDataObject>(
    {
      cacheKey: 'reports',
      organization: currentOrganization?.reference,
      dateFilter: dateFilter || '',
      limit: dateFilter ? 0 : 15,
    },
    fetchAllReports,
  );

  const { isToastShowed, showToast, hideToast, toastStatus, toastText } = useToast();

  useEffect(() => {
    refetch();
  }, [notifications, refetch]);

  useEffect(() => {
    if (doneUploading) {
      showToast(t('imports:file-started-processing', { count: doneUploading }), 'primary');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doneUploading]);

  const { put, cancel } = useApiRequest();

  useEffect(() => {
    const resetNotifications = async () => {
      if (currentOrganization) {
        await put(`/notification/manage/${currentOrganization.id}`);
      }
    };

    resetNotifications();

    return () => { cancel(); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization]);

  useEffect(() => {
    const extractedData: ReportItem[] = [
      ...extractReport(data, 'pdf'),
      ...extractReport(data, 'csv/xlsx'),
      ...extractReport(data, 'other'),
    ];
    if (!dateFilter) {
      setRecentCount(extractedData.length);
    }
    setReports(extractedData);
    setFilteredReports(extractedData);
  }, [data, dateFilter]);

  const handleChange = useCallback((value: string | null) => {
    setDateFilter(value);
  }, []);

  const handleCancelReport = useCallback(async (importLogId: number) => {
    try {
      await cancelImport(importLogId);
    } finally {
      refetch();
    }
  }, [refetch]);

  const filterBy = useCallback((name: string, newValue: string | null) => {
    if (!newValue) {
      setFilteredReports(reports);
      return;
    }
    setFilteredReports(reports.filter(({ format }) => format === newValue));
  }, [setFilteredReports, reports]);

  const getStatus = useCallback((report: ReportItem) => {
    const { status, nbErrorsByFile, nbErrorsByLine, format } = report;
    if (status === 'IN_PROGRESS') {
      return 'in-progress';
    }
    // TODO: isSageOrganisation et PDF pour corriger le problème d'API -> À retirer quand ça sera réglé !
    if (nbErrorsByFile && (!isSageOrganization || format !== 'pdf')) {
      return 'error';
    }
    // TODO: isSageOrganisation et PDF pour corriger le problème d'API -> À retirer quand ça sera réglé !
    return (nbErrorsByLine && (!isSageOrganization || format !== 'pdf')) ? 'warning' : 'default';
  }, [isSageOrganization]);

  const inProgressReports = useMemo(() => (
    filteredReports.filter((report) => getStatus(report) === 'in-progress')
      .sort((report1, report2) => Date.parse(report2.date) - Date.parse(report1.date))
  ), [filteredReports, getStatus]);

  const finishedReports = useMemo(() => (
    filteredReports.filter((report) => getStatus(report) !== 'in-progress')
      .sort((report1, report2) => Date.parse(report2.date) - Date.parse(report1.date))
  ), [filteredReports, getStatus]);

  return (
    <div className="ImportReports">
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
      <div className="ImportReports__header">
        <div className="ImportReports__header__links">
          <GoBackButton />
        </div>
        <h1 className="ImportReports__header__title">{t('reports:reports')}</h1>
      </div>
      <div className="ImportReports__filters">
        <ButtonDatePicker onChange={handleChange} recentCount={recentCount} />
        <SelectReportsFormat onChange={filterBy} />
      </div>
      {(inProgressReports.length !== 0 || isLoading) && (
        <h2 className="ImportReports__section">{t('reports:in-progress')}</h2>
      )}
      {inProgressReports.map((report) => {
        const { date, nbErrorsByFile, nbErrorsByLine, type } = report;
        return (
          <Item
            key={`${date}-${nbErrorsByFile}-${nbErrorsByLine}-${type}`}
            variant={getStatus(report)}
            report={report}
            onCancel={handleCancelReport}
          />
        );
      })}
      <h2 className="ImportReports__section">
        {t('reports:history')}
      </h2>
      {isLoading && <Loading />}
      {(finishedReports.length === 0 && !isLoading) && (
        <p className="ImportReports__no-data">
          {dateFilter ? t('reports:no-filtered-report') : t('reports:no-recent-report')}
        </p>
      )}
      {finishedReports.map((report) => {
        const { date, nbErrorsByFile, nbErrorsByLine, type } = report;
        return (
          <Item
            key={`${date}-${nbErrorsByFile}-${nbErrorsByLine}-${type}`}
            variant={getStatus(report)}
            report={report}
          />
        );
      })}
    </div>
  );
};

export default observer(Reports);
