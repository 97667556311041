import {
  runInAction,
  makeAutoObservable,
} from 'mobx';
import Cookies from 'js-cookie';
import Config from 'config';
import i18n from 'config/i18n';
import history from 'utils/history';
import { analyticsLogin } from 'utils/analytics';
import ResponseError from 'utils/errors';
import Request from 'utils/request';
import type Errors from 'types/errors';
import type { User } from 'types/models';
import organizationStore from './Organization';
import userRightsStore from './UserRights';

const initialUser: User | null = (() => {
  try {
    return JSON.parse(window.localStorage.getItem('user') || 'null');
  } catch {
    return null;
  }
})();

class AuthStore {
  isFetched: boolean = !!initialUser;

  isBusy: boolean = false;

  user: User | null = initialUser;

  error: Errors.Request | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get isLogged() {
    return !!this.user;
  }

  get isDemo() {
    return this.user?.roles?.includes('ROLE_DEMO');
  }

  get isSuperAdmin() {
    return this.user?.roles?.includes('ROLE_SUPER_ADMIN');
  }

  // ------------------------------------------------------
  // -
  // -    Actions
  // -
  // ------------------------------------------------------

  async login(email: string, password: string) {
    if (this.isBusy) {
      throw new Error('Une opération est déjà en cours.');
    }

    this.isBusy = true;
    this.error = null;

    const requestData = {
      client_id: Config.API_AUTH_ID,
      client_secret: Config.API_AUTH_SECRET,
      grant_type: 'password',
      username: email,
      password,
    };

    try {
      const { data } = await Request.post('token', requestData);
      const { refresh_token: refreshToken, access_token: token } = data;

      window.localStorage.setItem('token', token);
      window.localStorage.setItem('refreshToken', refreshToken);

      runInAction(() => { this.isBusy = false; });
      this.fetchUser();
      if (!userRightsStore.rights) {
        userRightsStore.getAll();
      }
    } catch (error) {
      const { code, message, details } = error as Errors.Request;
      runInAction(() => {
        this.error = new ResponseError(code, message, details);
        this.isBusy = false;
      });
    }
  }

  logout() {
    window.localStorage.clear();
    organizationStore.resetCurrent();
    Cookies.remove('JWTAuthorization', { path: '/' });
    runInAction(() => {
      this.user = null;
      this.error = null;
      this.isFetched = false;
    });
    history.push('/login');
  }

  async fetchUser() {
    if (this.isBusy) {
      return;
    }
    this.isBusy = true;
    this.error = null;

    try {
      const { data } = await Request.get<User>('current-user');

      if (!data) {
        throw new Error('No current user found');
      }

      window.localStorage.setItem('user', JSON.stringify(data));
      i18n.changeLanguage(data.locale);

      runInAction(() => {
        this.user = data;
        this.isFetched = true;
      });
      if (data.organizations.length === 1) {
        organizationStore.setCurrent(data.organizations[0].reference);
      }
      analyticsLogin(data.id, `${data.firstName} ${data.lastName}`, data.email);
    } catch (err) {
      const { code, message, details } = err as { code: number, message: string, details?: string };
      runInAction(() => {
        this.error = new ResponseError(code, message, details);
      });

      if (code !== 401) {
        // eslint-disable-next-line no-console
        console.error('Error:', code, message, details);
      }
    } finally {
      runInAction(() => {
        this.isBusy = false;
        this.isFetched = true;
      });
    }
  }
}

export default new AuthStore();
export { AuthStore };
