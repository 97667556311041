import './index.scss';
import type { HistoryStep as HistoryStepType } from 'types/models';
import HistoryStep from 'components/HistoryStep';

type Props = {
  title: string,
  items: HistoryStepType[],
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const CustomerHistoryDayList = ({ title, items, onActionDone, onActionError }: Props): JSX.Element => (
  <div className="CustomerHistoryDayList">
    <h3 className="CustomerHistoryDayList__title">
      {title}
    </h3>
    <ul className="CustomerHistoryDayList__list">
      {items.map((historyItem) => (
        <HistoryStep
          key={`${historyItem.id}-${historyItem.entity}`}
          data={historyItem}
          onActionDone={onActionDone}
          onActionError={onActionError}
        />
      ))}
    </ul>
  </div>
);

export default CustomerHistoryDayList;
