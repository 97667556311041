import { useCallback, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import organizationStore from 'stores/Organization';
import type { Category } from 'types/models';
import ErrorMessage from 'components/ErrorMessage';
import Button from 'components/Button';
import Loading from 'components/Loading';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import DropdownActions from 'components/DropdownActions';

type Props = {
  category: Category
  onActionDone(message: string): void,
  onActionError(message: string): void,
  onReload(): void,
  showModal(): void,
};

const CategoryRowActions = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const isMountedRef = useIsMountedRef();

  const { category, onActionDone, onActionError, showModal, onReload } = props;
  const { error, isLoading } = useApiRequest();
  const { id, name } = category;
  const { remove } = useApiRequest();
  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const handleConfirmDelete = useCallback(() => {
    showConfirm(t('perimeters:confirm.category-delete-title'), t('perimeters:confirm.category-delete', { name }));
  }, [showConfirm, t, name]);

  const handleSubmitDelete = useCallback(async () => {
    if (! currentOrganization || !isMountedRef) {
      return;
    }

    const response = await remove(`category/${currentOrganization.reference}/${id}`);
    if (!response) {
      return;
    }

    onActionDone(t('perimeters:toast.success.category-deleted', { name }));
    onReload();
    hideConfirm();
  }, [hideConfirm, onActionDone, t, id, name, remove, isMountedRef, currentOrganization, onReload]);

  useEffect(() => {
    if (error) {
      onActionError(error.message || t('perimeters:toast.error.category-deleted', { name }));
    }
  }, [error, onActionError, name, t]);

  return (
    <Fragment>
      <DropdownActions
        actions={[
          <Button
            variant="list"
            onClick={showModal}
            title={t('common:edit')}
            disabled={isLoading}
          >
            {isLoading && <Loading hasNoText />}
            {!isLoading && t('common:edit')}
          </Button>,
          <Button
            variant="list-danger"
            onClick={handleConfirmDelete}
            title={t('common:remove')}
            disabled={isLoading}
          >
            {isLoading && <Loading hasNoText />}
            {!isLoading && t('common:remove')}
          </Button>,
        ]}
      />
      {error && <ErrorMessage error={error} />}
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        entityName={name}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitDelete}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={t('common:remove')}
      />
    </Fragment>
  );
};

export default observer(CategoryRowActions);
