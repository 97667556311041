import { useEffect, useRef } from 'react';
import {
  Switch,
  Route as ReactRouterRoute,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { observer } from 'mobx-react';
import { AnalyzableRoute, PrivateRoute, PrivateAdminRoute } from 'components/Routes';
import authStore from './stores/Auth';
import Login from './pages/Login';
import ForgottenPassword from './pages/ForgottenPassword';
import ResetPassword from './pages/ResetPassword';
import Home from './pages/Home';
import PaymentRequest from './pages/PaymentRequest';
import PaymentRequests from './pages/PaymentRequests';
import Customers from './pages/Customers';
import Customer from './pages/Customer';
import Contacts from './pages/Contacts';
import Settings from './pages/Settings';
import ReservedAccess from './pages/ReservedAccess';
import ActionsDone from './pages/ActionsDone';
import ActionsFuture from './pages/ActionsFuture';
import Imports from './pages/Imports';
import Analysis from './pages/Analysis';
import Answer from './pages/Answer';
import Payments from './pages/Payments';
import FirstTime from './pages/FirstTime';
import ConfirmEmail from './pages/ConfirmEmail';
import BackOffice from './pages/BackOffice';
import ConfirmedEmail from './pages/ConfirmedEmail';
import SignUp from './pages/SignUp';
import Payer from './pages/Payer';
import OrganizationCreate from './pages/OrganizationCreate';
import PaymentRequestsToComplete from './pages/PaymentRequestsToComplete';
import CreditNotes from './pages/CreditNotes';

export const PUBLIC_ROUTES = [
  '/forgotten-password',
  '/reset-password/',
  '/login',
  '/confirm-email',
  '/sign-up',
  '/confirm/',
  '/answer/',
  '/payer/',
];

const AppRoutes = (): JSX.Element => {
  const { user: authUser } = authStore;
  const hasOrganizations = authUser?.organizations && authUser.organizations.length !== 0;
  const location = useLocation();
  const oldLocation = useRef(location.pathname);

  useEffect(() => {
    oldLocation.current = location.pathname;
  }, [location]);

  return (
    <Switch>
      <AnalyzableRoute path="/login" exact component={Login} routeName="Login" />
      <AnalyzableRoute path="/confirm-email" exact component={ConfirmEmail} routeName="Confirm email" />
      <AnalyzableRoute path="/sign-up" exact component={SignUp} routeName="Sign up" />
      <AnalyzableRoute
        path="/forgotten-password"
        exact
        component={ForgottenPassword}
        routeName="Forgotten password"
      />
      <AnalyzableRoute
        path="/reset-password/:token"
        exact
        component={ResetPassword}
        routeName="Reset password"
      />
      <AnalyzableRoute path="/payer/:token" component={Payer} routeName="Payer" />
      <ReactRouterRoute path="/actions" render={(props) => <Redirect {...props} to="/actions-future" />} />
      <AnalyzableRoute path="/answer/:token" exact component={Answer} routeName="Answer invitation" />
      <AnalyzableRoute
        path="/confirm/:token"
        exact
        component={ConfirmedEmail}
        routeName="Confirmed email"
      />
      <PrivateRoute path="/" exact component={hasOrganizations ? Home : FirstTime} routeName="Dashboard" />
      <PrivateRoute path="/organization/new" exact component={OrganizationCreate} routeName="Create organization" />
      <PrivateRoute path="/payment-requests/draft" component={PaymentRequestsToComplete} routeName="Payment requests draft" />
      <PrivateRoute path="/payment-requests/:status?/:payRequestRef?" component={PaymentRequests} routeName="Payment requests listing" />
      <PrivateRoute path="/payment-request/:payRequestRef" component={PaymentRequest} routeName="Payment request" />
      <PrivateRoute path="/payments/:modalType?/:reference?" component={Payments} routeName="Payments listing" />
      <PrivateRoute path="/customers/status/:status" component={Customers} routeName="Customers listing" />
      <PrivateRoute path="/customers/:editType?/:customerId?" component={Customers} routeName="Customer edit" />
      <PrivateRoute path="/customer/:customerId/:editType?" component={Customer} routeName="Customer view" />
      <PrivateRoute path="/credit-notes" component={CreditNotes} routeName="Credit notes listing" />
      <PrivateRoute path="/contacts/:editType?/:contactId?" component={Contacts} routeName="Contacts listing" />
      <ReactRouterRoute path="/preferences" component={Settings} />
      <PrivateRoute path="/actions-future" component={ActionsFuture} routeName="Actions future" />
      <PrivateRoute path="/actions-done" component={ActionsDone} routeName="Actions done" />
      <ReactRouterRoute path="/imports" component={Imports} />
      <PrivateRoute path="/analysis" component={Analysis} routeName="Analysis" />
      <PrivateAdminRoute path="/back-office" component={BackOffice} routeName="Back office" />
      <PrivateRoute component={ReservedAccess} routeName="Reserved access" />
    </Switch>
  );
};

export default observer(AppRoutes);
