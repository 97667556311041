import type Misc from 'types/misc';
import request from 'utils/request';

export type FetchAllOptions = {
  isAsync: boolean,
  fetchEntity: string | undefined,
  hasFetchOrganizationPrefix: boolean,
  fetchEntityQuerystring: string,
  requestPrefix: string,
};

export type FetchAllParams = {
  organization: string | undefined,
  options: FetchAllOptions,
};

const fetchAll = async ({ organization, options }: FetchAllParams) => {
  const {
    isAsync,
    fetchEntity,
    hasFetchOrganizationPrefix,
    fetchEntityQuerystring,
    requestPrefix,
  } = options;

  if (!organization) {
    throw new Error('FetchAllOptions: Missing organization.');
  }

  if (!isAsync || !fetchEntity) {
    throw new Error('FetchAllOptions: Must be async, and/or missing entity to fetch.');
  }

  let url = `${fetchEntity}?${fetchEntityQuerystring}`;
  if (hasFetchOrganizationPrefix) {
    url = `${requestPrefix}/${organization}/${fetchEntity}?${fetchEntityQuerystring}`;
  }

  const result = await request.get<Misc.IdRefName[]>(url);

  return result;
};

const fetchAllClients = (
  cacheKey: 'selectClientOptions',
  organization: string | undefined,
  searchTerm: string,
) => {
  if (!organization || searchTerm.length < 2) {
    return null;
  }

  const url = `organization/${organization}/clients?search=${encodeURIComponent(searchTerm)}&limit=10`;
  return request.get<Misc.IdRefName[]>(url);
};

const fetchAllPayRequestsClients = (
  cacheKey: 'selectClientPayRequestOptions',
  organization: string | undefined,
  client: string,
  searchTerm: string,
) => {
  if (!organization || searchTerm.length < 2) {
    return null;
  }

  const query = `filters[search]=${encodeURIComponent(searchTerm)}&filters[status]=IN_PROGRESS&filters[lite]=1`;
  const url = `client/${organization}/${client}/pay-requests?${query}`;

  return request.get<Misc.IdRefName[]>(url);
};

export { fetchAll, fetchAllClients, fetchAllPayRequestsClients };
