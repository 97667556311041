import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import type { Client } from 'types/models';
import type Misc from 'types/misc';
import type Errors from 'types/errors';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import ModalModels from 'components/ModalModels';
import Button from 'components/Button';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import organizationStore from 'stores/Organization';
import Confirm from 'components/Confirm';
import CreateTaskForm from './Form';

interface Props {
  customer: Client | null,
  onDone(title: string, message: string): void,
  onClose(): void,
}

const CustomerCreateTask = ({ customer, onDone, onClose }: Props): JSX.Element => {
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const { currentOrganization } = organizationStore;
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Errors.Validation | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);
  const [model, setModel] = useState<Misc.PlanTemplateReminder | undefined>();
  const { post, isLoading, error } = useApiRequest();

  const closeSelf = useCallback(() => {
    if (!isMountedRef.current) {
      return;
    }

    setShowCancelConfirm(false);
    setHasChanges(false);
    setValidationErrors(null);
    onClose();
  }, [isMountedRef, onClose]);

  const handleCancel = useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirm(true);
    } else {
      closeSelf();
    }
  }, [hasChanges, closeSelf]);

  const [showModalModels, hideModalModels] = useModal(() => (
    <ModalModels
      type="task"
      onClose={hideModalModels}
      onSelect={setModel}
    />
  ), []);

  const handleSubmit = useCallback(async (formData: ModalFormData | null) => {
    if (!formData || !currentOrganization) {
      return;
    }

    setValidationErrors(null);

    const data = {
      name: formData.name as string,
      description: formData.description as string,
      startAt: formData.startAt as string,
      group: !!customer?.group,
      groupId: customer?.group?.id,
    };

    const result = await post<Misc.IdName>('task/new/freeTask', data);

    if (!isMountedRef.current) {
      return;
    }

    if (result?.errors) {
      setValidationErrors(result.errors);
      return;
    }

    closeSelf();

    if (result?.name) {
      const { name } = result;
      onDone('', t('reminders:freetask.toast.created', { name }));
    }
  }, [currentOrganization, post, customer, isMountedRef, onDone, t, closeSelf]);

  return (
    <ModalForm
      isOpened
      className="CustomerCreateTask"
      title={t('reminders:freetask.create-group')}
      helpHeaderText={t('reminders:freetask.create-group-help')}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
      headerActions={(
        <Button variant="link" onClick={showModalModels}>{t('models:import')}</Button>
      )}
    >
      <CreateTaskForm
        errors={validationErrors}
        defaultData={model}
      />
      {error && (<ErrorMessage error={error} />)}
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={closeSelf}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(CustomerCreateTask);
