import './index.scss';
import { useState, useCallback, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import type { ModalFormData } from 'components/ModalForm';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import ErrorMessage from 'components/ErrorMessage';
import Button from 'components/Button';
import Icon from 'components/Icon';
import ModalForm from 'components/ModalForm';
import type Misc from 'types/misc';
import type { CustomerMuteButtonProps } from '../index';
import CustomerUnMuteForm from './Form';

const UnMuteButton = (props: CustomerMuteButtonProps): JSX.Element => {
  const { customerData, onActionDone, showText = false } = props;
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const { put, error } = useApiRequest();
  const [isModalOpened, setIsModalOpened] = useState(false);

  const handleSubmit = useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData) {
        return;
      }

      const data = { scenario : formData.scenario as Misc.UnmutedScenario };
      const result = await put(`client/manage/${customerData?.id}/unmute`, data);
      if (!result || !isMountedRef.current) {
        return;
      }

      if (onActionDone) {
        onActionDone(t('clients:actions.toast.un-muted-success', { reference: customerData.reference }), '');
        setIsModalOpened(false);
      }
    },
    [customerData, isMountedRef, put, onActionDone, setIsModalOpened, t],
  );

  const classNames = classnames('CustomerMuteButtonUnMute', {
    'CustomerMuteButtonUnMute--with-text': showText,
  });

  return (
    <Fragment>
      <Button
        className={classNames}
        variant="list"
        title={showText ? '' : t('clients:un-mute')}
        onClick={() => setIsModalOpened(true)}
      >
        <Icon name="bell-muted" className="CustomerMuteButtonUnMute__icon" />
        {showText && t('clients:un-mute')}
      </Button>
      <ModalForm
        isOpened={isModalOpened}
        title={t('clients:un-mute')}
        onSave={handleSubmit}
        onCancel={() => setIsModalOpened(false)}
      >
        {error && <ErrorMessage error={error} />}
        <CustomerUnMuteForm
          text={t('clients:actions.confirm.un-mute', { reference: customerData.reference })}
          reference={customerData.reference} />
      </ModalForm>
    </Fragment>
  );
};

export default UnMuteButton;
