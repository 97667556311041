import './index.scss';
import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Filler,
} from 'chart.js';
import organizationStore from 'stores/Organization';
import type { AnalysisFinanceChartData } from 'types/models';
import formatIntlDate from 'utils/formatIntlDate';
import defaultChartOptions from '../../defaultChartOptions';

type Props = {
  data: AnalysisFinanceChartData,
};

ChartJS.register(LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Filler);

const TotalCollectedChart = ({ data }: Props): JSX.Element => {
  const { currency } = organizationStore;
  const { t } = useTranslation();

  const formatLabel = useCallback((month: string) => {
    const date = formatIntlDate(`${month}-01`, 'monthLong');
    return `${date.charAt(0).toUpperCase()}${date.slice(1)}`;
  }, []);

  const chart = useMemo(() => ({
    labels: data?.totalCollectedByMonth.map(({ month }) => formatLabel(month)),
    datasets: [
      {
        label: `${t('analysis:graph.total-collected-by-month')} (${currency})`,
        data: data?.totalCollectedByMonth.map(({ amount }) => Math.round(amount)),
        fill: true,
        borderJoinStyle: 'round' as const,
        pointRadius: 7,
        pointHoverRadius: 10,
        pointBorderWidth: 2,
        borderColor: '#0076FF',
        borderWidth: 2,
        backgroundColor: '#F7F7F7',
      },
      {
        label: `${t('analysis:graph.total-collected-forecast-by-month')} (${currency})`,
        data: data?.totalCollectedForecastByMonth.map(({ amount }) => Math.round(amount)),
        fill: true,
        borderJoinStyle: 'round' as const,
        pointRadius: 7,
        pointHoverRadius: 10,
        pointBorderWidth: 2,
        borderColor: '#00FFDE',
        borderWidth: 2,
        backgroundColor: '#FAFAFA',
      },
    ],
  }), [currency, data?.totalCollectedByMonth, data?.totalCollectedForecastByMonth, formatLabel, t]);

  return (
    <div className="TotalCollectedChart">
      <Line data={chart} options={defaultChartOptions} />
    </div>
  );
};

export default observer(TotalCollectedChart);
