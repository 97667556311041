import type Misc from 'types/misc';

const getI18nPaymentStatus = (paymentStatus: Misc.PaymentStatus): string => {
  const i18nPaymentStatus = {
    SUCCESS: 'payments:status.success',
    FAILED: 'payments:status.failed',
    REFUNDED: 'payments:status.refunded',
    PENDING: 'payments:status.pending',
  };

  return i18nPaymentStatus[paymentStatus] || 'common:unknown';
};

export default getI18nPaymentStatus;
