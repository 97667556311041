import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import type { Column } from 'react-table';
import Config from 'config';
import type Misc from 'types/misc';
import type { PaymentForList } from 'types/models';
import type { FetchAllParams } from 'api/payments';
import { fetchAll } from 'api/payments';
import organizationStore from 'stores/Organization';
import paymentsFilters from 'stores/FilterStores/PaymentsFilters';
import useFetchPaginated from 'hooks/useFetchPaginated';
import useContextualTranslation from 'hooks/useContextualTranslation';
import PageList from 'components/PageList';
import DataTable from 'components/DataTable';
import ToastNotification from 'components/ToastNotification';
import useToast from 'components/ToastNotification/useToast';
import FormSelect from 'components/FormSelect';
import LinkSwitch from 'components/LinkSwitch';
import getFiltersQueryString from 'utils/getFiltersQueryString';
import PaymentEditPage from '../PaymentEdit';
import PaymentViewPage from '../Payment';
import PaymentsColumns from './Columns';
import ExportLink from './ExportLink';

type RouteParams = {
  modalType?: 'new' | 'edit' | 'view',
  reference?: string,
};

const Payments = (): JSX.Element => {
  const { modalType } = useParams<RouteParams>();
  const history = useHistory();
  const { currentOrganization, walletSelection, linesOfBusiness } = organizationStore;
  const { ct } = useContextualTranslation(linesOfBusiness);
  const defaultFetchOptions = {
    pageIndex: 0,
    pageSize: Config.DEFAULT_PAGE_SIZE,
    sort: { id: 'paidAt', desc: true },
  };
  const [fetchOptions, setFetchOptions] = useState<Misc.PaginatedFetchArgs<PaymentForList>>(
    defaultFetchOptions,
  );

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const {
    filters,
    addOrUpdateFilters,
    removeFilter,
    resetAllFilters,
  } = paymentsFilters;

  useEffect(() => {
    resetAllFilters();
  }, [resetAllFilters]);

  const filtering = useMemo(() => {
    if (walletSelection.length === 0) {
      return filters;
    }
    return [...filters, { name: 'categories', value: walletSelection }];
  }, [filters, walletSelection]);

  const {
    data,
    serverPagination,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useFetchPaginated<FetchAllParams, PaymentForList>({
    cacheKey: 'payments',
    organization: currentOrganization?.reference,
    filtering,
    fetchOptions,
  }, fetchAll, { enabled: !!currentOrganization });

  const handleActionDone = useCallback((title: string, message: string) => {
    showToast(message, 'success');
    refetch();
  }, [showToast, refetch]);

  const handleViewEditModalsClose = useCallback(() => {
    history.replace('/payments');
  }, [history]);

  const handleActionError = useCallback((message: string) => {
    showToast(message, 'error');
  }, [showToast]);

  const handleSelectCreditNotes = useCallback((name: string, value: string | null) => {
    if (value && value !== '/payments') {
      history.replace(value);
    }
  }, [history]);

  const columns = useMemo<Column<PaymentForList>[]>(
    () => PaymentsColumns(ct, handleActionDone, handleActionError, history.push),
    [ct, handleActionDone, handleActionError, history],
  );

  const totalResults = useMemo(() => (
    filters.length > 0 ? serverPagination?.totalRecords : null
  ), [filters, serverPagination]);

  useEffect(() => {
    setFetchOptions(fetchOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exportLink = useMemo<string>(
    () => ExportLink(fetchOptions, filters, currentOrganization?.reference || null),
    [currentOrganization, fetchOptions, filters],
  );

  const handleEdit = useCallback((reference: string) => {
    history.push(`/payments/edit/${reference}`);
  }, [history]);

  const handleSwitchChange = useCallback((listing: string) => {
    if (!listing.startsWith('payments')) {
      history.push(`/${listing}?${getFiltersQueryString(filters, ['search', 'client'])}`);
    }
  }, [filters, history]);

  const switchListing = (
    <LinkSwitch
      valueLabels={[
        { value: 'payment-requests', label: ct('payment-requests:bills') },
        { value: 'payments', label: ct('payment-requests:payments') },
      ]}
      defaultValue="payments"
      onChange={handleSwitchChange}
    />
  );

  return (
    <PageList
      className="Payments"
      count={totalResults}
      isFetching={isFetching}
      title={ct('payments:title')}
      actions={switchListing}
    >
      <DataTable<PaymentForList>
        columns={columns}
        data={data}
        serverPagination={serverPagination}
        defaultSortBy={defaultFetchOptions.sort}
        filtering={filters}
        onRemoveFilter={removeFilter}
        onChangeFilters={addOrUpdateFilters}
        fetchData={setFetchOptions}
        isLoading={isLoading}
        error={error}
        statusSelector={(
          <FormSelect
            selectOptions={[
              { label: ct('common:payments'), value: '/payments' },
              { label: ct('common:credit-notes'), value: '/credit-notes' },
            ]}
            defaultValue="/payments"
            className="Payments__page-select"
            name="select"
            onSelect={handleSelectCreditNotes}
            withClearButton={false}
          />
        )}
        exportLink={exportLink}
        withNoDataDrawing
        withActions={false}
      />
      {modalType && ['new', 'edit'].includes(modalType) && (
        <PaymentEditPage onDone={handleActionDone} onClose={handleViewEditModalsClose} />
      )}
      {modalType && modalType === 'view' && (
        <PaymentViewPage onClickEdit={handleEdit} onClose={handleViewEditModalsClose} />
      )}
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </PageList>
  );
};

export default observer(Payments);
