import type { TFunction } from 'i18next';
import type Misc from 'types/misc';
import type { OrganizationEavs, EavConstructor } from 'types/models';
import Config from 'config';
import getI18nSelectorOption from 'utils/getI18nSelectorOption';
import getEavTypeFromFormat from 'utils/getEavTypeFromFormat';
import isEavValueValid from 'utils/isEavValueValid';

/**
 * Récupère un constructeur d'EAV pour une valeur donnée (customer ou payRequest)
 *
 * @param value La valeur (devant commencer par "EAV_")
 * @param eavs Les EAVs de l'organisation
 * @returns Le constructeur d'EAV pour la valeur donnée
 */
const getAttributeFromEAVValue = (
  value: string | null | undefined,
  eavs: OrganizationEavs,
): EavConstructor | null => {
  if (!value || !value.startsWith('{EAV_')) {
    return null;
  }

  if (value.startsWith('{EAV_CLIENT_')) {
    const valueIdentifier = value.substring(12, value.length - 1);
    return eavs.client.find(({ identifier }) => identifier === valueIdentifier) ?? null;
  }

  const valueIdentifier = value.substring(16, value.length - 1);
  return eavs.payRequest.find(({ identifier }) => identifier === valueIdentifier) ?? null;
};

/**
 * Récupère le type d'attribut pour une condition, selon la valeur de cet attribut
 *
 * @param select La valeur de l'attribut
 * @param select1Type L'éventuel type de l'attribut lié
 * @param attribute1 L'éventuelle valeur de l'attribut lié
 * @param eav Les EAVs pour quand il s'agit d'un customer ou une payRequest
 * @returns Le type d'attribut à utiliser pour la condition
 */
const defaultConditionType = (
  select: string | number | undefined,
  select1Type?: Misc.AttributeType | null,
  attribute1?: string | null,
  eav?: OrganizationEavs,
): Misc.AttributeType | null => {
  if (!select || typeof select !== 'string') {
    return null;
  }

  if (select.startsWith('{date_')) {
    return 'slidingDate';
  }
  if (Config.CLIENT_SELECTOR_OPTIONS.includes(select) || select.startsWith('{EAV_CLIENT_')) {
    return 'clientAttribute';
  }
  if (Config.PAYREQUEST_SELECTOR_OPTIONS.includes(select) || select.startsWith('{EAV_PAYREQUEST_')) {
    return 'payrequestAttribute';
  }
  if (Config.PAYMENT_SELECTOR_OPTIONS.includes(select)) {
    return 'payment';
  }
  if (Config.DEBT_SELECTOR_OPTIONS.includes(select)) {
    return 'debt';
  }
  if (Config.MANAGERS_SELECTOR_OPTIONS.includes(select)) {
    return 'managers';
  }

  if (select1Type === 'clientAttribute' || select1Type === 'payrequestAttribute') {
    if (!eav) {
      return 'free';
    }

    const attribute = getAttributeFromEAVValue(attribute1, eav);
    if (!attribute) {
      return 'free';
    }

    const isInPossibleValues = !!attribute.possibleValues?.find(({ value }) => (value === select));
    const isValidEavValue = attribute.format && isEavValueValid(
      getEavTypeFromFormat(new RegExp(attribute.format)),
      select,
      attribute.required,
    );
    if (isInPossibleValues || isValidEavValue) {
      return 'attributeSuggest';
    }
  }

  if (['payment', 'debt'].includes(select1Type ?? '')) {
    return null;
  }

  return 'free';
};

/**
 * Récupère le label de la condition en fonction de la valeur et du type de l'attribut
 *
 * @param value La valeur de l'attribut dont on veut le label de condition
 * @param attributeType Le type de l'attribut dont on veut le label
 * @param t La fonction de traduction
 * @param eavs La liste des EAVs de l'organisation
 * @returns Le label de la condition
 */
const getConditionTypeLabelFromValue = (
  value: string | null,
  attributeType: Misc.AttributeType | null,
  t: TFunction,
  eavs: OrganizationEavs,
): string => {
  if (!value || !attributeType) {
    return '';
  }

  if (attributeType === 'free') {
    return value;
  }

  if (attributeType === 'slidingDate' && value.length > 15) {
    const period = (value[6] === '+') ? '+' : '-';
    const slicedAttribute = value.slice(7, value.length - 5).split(`months${period}`);
    if (slicedAttribute.length === 2) {
      return [
        (period === '+' ? t('common:within') : t('common:prior-to')).toLocaleLowerCase(),
        slicedAttribute[0],
        t('common:months'),
        t('common:and'),
        slicedAttribute[1],
        t('common:days'),
      ].join(' ');
    }
  }

  if (attributeType === 'clientAttribute') {
    if (value.startsWith('{EAV_CLIENT_')) {
      const valueIdentifier = value.substring(12, value.length - 1);
      return eavs.client.find(({ identifier }) => identifier === valueIdentifier)?.label ?? '';
    }

    const selector = Config.CLIENT_SELECTOR_OPTIONS.find((option) => option === value);
    return selector ? t(getI18nSelectorOption(selector)) : value;
  }

  if (value.startsWith('{EAV_')) {
    const valueIdentifier = value.substring(16, value.length - 1);
    return eavs.payRequest.find(({ identifier }) => identifier === valueIdentifier)?.label ?? '';
  }

  const selector = Config.PAYREQUEST_SELECTOR_OPTIONS.find((option) => option === value);
  return selector ? t(getI18nSelectorOption(selector)) : value;
};

export { getAttributeFromEAVValue, defaultConditionType, getConditionTypeLabelFromValue };
