import type { ChosableFormat } from 'utils/isEavValueValid';

const getEavTypeFromFormat: (eavFormat: RegExp | null) => ChosableFormat = (eavFormat) => {
  if (!eavFormat) {
    return 'none' as ChosableFormat;
  }

  const eavFormatTable = [
    { format: /^[0-9]+$/, type: 'number' },
    { format: /^[0-9]+/, type: 'number' },
    { format: /^\d{4}-[0-1]{1}[0-9]{1}-[0-3]{1}[0-9]{1}$/, type: 'date' },
    { format: /^\d{4}-[0-1]{1}[0-9]{1}-[0-3]{1}[0-9]{1}/, type: 'date' },
    { format: /^[0-9]+(\.[0-9][0-9]?)?$/, type: 'decimalNumber' },
    { format: /[0-9]+(\.[0-9][0-9]?)?/, type: 'decimalNumber' },
  ];

  return eavFormatTable.find(
    (eav) => eav.format.toString() === eavFormat.toString(),
  )?.type as ChosableFormat || 'none' as ChosableFormat;
};

export default getEavTypeFromFormat;
