import './index.scss';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import type { PayRequestResult } from 'api/globalSearch';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';

type Props = {
  data: PayRequestResult[],
  search: string,
};

const SearchResultPayRequests = ({ data, search }: Props): JSX.Element => {
  const { linesOfBusiness } = organizationStore;
  const { ct } = useContextualTranslation(linesOfBusiness);

  return (
    <ul className="SearchResultPayRequests">
      {data.map(({ id, reference }) => (
        <li key={id} className="SearchResultPayRequests__item">
          <Link
            to={`/payment-request/${id}`}
            title={ct<string>('common:open-payment-request-page')}
            className="SearchResultPayRequests__item__link"
          >
            {reference}
          </Link>
        </li>
      ))}
      <li className="SearchResultPayRequests__link-all">
        <Link
          to={`/payment-requests/IN_PROGRESS${search ? `?search=${search}` : ''}`}
          className="SearchResultPayRequests__link-all__link"
        >
          {ct('common:menu.search.all-bills')}
        </Link>
      </li>
    </ul>
  );
};

export default observer(SearchResultPayRequests);
