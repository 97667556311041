import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Filler,
} from 'chart.js';
import type { AnalysisRiskChartData } from 'types/models';
import formatIntlDate from 'utils/formatIntlDate';
import defaultChartOptions from '../../defaultChartOptions';

type Props = {
  data: AnalysisRiskChartData,
};

ChartJS.register(LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Filler);

const DebtChart = ({ data }: Props): JSX.Element => {
  const { t } = useTranslation();
  const formatLabel = React.useCallback((month: string) => {
    const date = formatIntlDate(`${month}-01`, 'monthLong');
    return `${date.charAt(0).toUpperCase()}${date.slice(1)}`;
  }, []);

  const chart = React.useMemo(() => ({
    labels: data.averageDelayOfPaymentByMonth
      .filter(({ day }) => day !== null).map(({ month }) => formatLabel(month)),
    datasets: [{
      data: data.averageDelayOfPaymentByMonth
        .filter(({ day }) => day !== null).map(({ day }) => day),
      label: `${t('home:average-payment-time')} (${t('common:days')})`,
      fill: true,
      borderJoinStyle: 'round' as const,
      pointRadius: 7,
      pointHoverRadius: 10,
      pointBorderWidth: 2,
      borderColor: '#0076FF',
      borderWidth: 2,
      backgroundColor: '#fafafa',
    }],
  }), [data.averageDelayOfPaymentByMonth, formatLabel, t]);

  return (
    <div className="DebtChart">
      <Line data={chart} options={defaultChartOptions} />
    </div>
  );
};

export default DebtChart;
