import './index.scss';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import Config from 'config';
import type Misc from 'types/misc';
import type { PayRequest } from 'types/models';
import getI18nChannel from 'utils/getI18nChannel';
import type { ModalFormData } from 'components/ModalForm';
import ModalForm from 'components/ModalForm';
import useIsMountedRef from 'hooks/useIsMountedRef';
import FormSelect from 'components/FormSelect';
import FormGroup from 'components/FormGroup';
import CreateReminderEdit from './Edit';

type Props = {
  forceGroupId?: number | null,
  payRequestData?: PayRequest | null,
  onDone(message: string): void,
  onClose(): void,
};

const CreateReminder = (props: Props): JSX.Element => {
  const { forceGroupId, payRequestData, onDone, onClose } = props;
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const [channel, setChannel] = useState<Misc.Channel>('SMS');

  const closeSelf = useCallback(() => {
    if (isMountedRef.current) {
      onClose();
    }
  }, [isMountedRef, onClose]);

  const [showReminderEdit, hideReminderEdit] = useModal(() => (
    <CreateReminderEdit
      forceGroupId={forceGroupId}
      payRequestData={payRequestData}
      channel={channel}
      onDone={onDone}
      onClose={() => { hideReminderEdit(); closeSelf(); }}
    />
  ), [forceGroupId, payRequestData, channel, onDone, closeSelf]);

  const handleChooseChannel = useCallback((formData: ModalFormData | null) => {
    if (!formData || !isMountedRef.current) {
      return;
    }

    setChannel(formData.roles as Misc.Channel);
    showReminderEdit();
  }, [isMountedRef, showReminderEdit]);

  const channels = useMemo(() => (
    Config.CHANNELS_ID.map((value) => ({
      value,
      label: t(getI18nChannel(value)),
    }))
  ), [t]);

  const title = useMemo(() => (
    forceGroupId ? t('reminders:reminder.create-group') : t('reminders:reminder.create')
  ), [forceGroupId, t]);

  return (
    <ModalForm
      isOpened
      className="CreateReminder"
      title={title}
      helpHeaderText={forceGroupId ? t<string>('reminders:new-reminder-group-help') : undefined}
      onSave={handleChooseChannel}
      onCancel={closeSelf}
      saveText={t<string>('common:next')}
    >
      <FormGroup
        label={t('reminders:reminder.select-channel')}
        className="CreateReminder__select"
        mandatory
      >
        <FormSelect
          name="roles"
          selectOptions={channels}
          defaultValue={channels[0].value}
        />
      </FormGroup>
    </ModalForm>
  );
};

export default CreateReminder;
