
import Misc from 'types/misc';
import { HistoryStepWebHook, WebHookSMS, WebHookLetter, WebHookEmail } from 'types/models';

// https://docs.aws.amazon.com/ses/latest/dg/notification-contents.html
export const eventsErrorsEmail:  string[] = ['Bounce', 'Complaint'];

// https://docs.mysendingbox.fr/#event-object
export const eventsErrorsLetter = [
  'letter.error',
  'letter.returned_to_sender',
  'letter.wrong_address',
  'letter.canceled',
  'send_error',
];

// https://support.callr.com/hc/en-us/articles/115001130363-SMS-Statuses
export const eventsErrorsSMS = [
  'ERROR',
  'EXPIRED',
  'FAILED',
];

const haveHistoryEventsError = (eventsHistory: HistoryStepWebHook): boolean => {
  if (eventsHistory.channel === 'SMS') {
    return eventsHistory.history?.some(
      (event) => eventsErrorsSMS.includes(event.status),
    ) ?? false;
  }

  if (eventsHistory.channel === 'EMAIL') {
    return eventsHistory.history?.some(
      (event) => eventsErrorsEmail.includes(event.type),
    ) ?? false;
  }

  return eventsHistory.history?.some(
    (event) => eventsErrorsLetter.includes(event.type),
  ) ?? false;
};

const hasEventError = (channel: Misc.Channel,  event: WebHookSMS | WebHookLetter | WebHookEmail): boolean  => {
  if (event.type === 'sms.mt.status_update') {
    return eventsErrorsSMS.includes(event.status);
  }

  if (channel === 'EMAIL') {
    return eventsErrorsEmail.includes(event.type);
  }

  return eventsErrorsLetter.includes(event.type);
};

export { haveHistoryEventsError, hasEventError };
