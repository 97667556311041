import './index.scss';
import { useCallback, useEffect, useState, useRef } from 'react';
import type { SyntheticEvent } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import Config from 'config';
import type Errors from 'types/errors';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormSelect from 'components/FormSelect';
import Button from 'components/Button';
import Loading from 'components/Loading';
import FormFieldset from 'components/FormFieldset';
import ErrorValidationMessage from 'components/ErrorValidationMessage';

import type { FormDataAnswer } from '../';

type Props = {
  onSubmit: (sendData: FormDataAnswer) => void,
  isLoading: boolean,
  errors: Errors.Validation | null,
};

const AnswerForm = ({ onSubmit, isLoading, errors }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [password, setPassword] = useState<string>('');
  const [firstname, setFirstname] = useState<string>('');
  const [lastname, setLastname] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isBadPassword, setIsBadPassword] = useState<boolean>(false);
  const [identifier, setIdentifier] = useState<string>('');
  const oldGeneratedIdentifier = useRef<string>('');
  const locale = i18next.language;

  const handleConfirmPasswordChange = useCallback((value: string) => {
    setConfirmPassword(value);
    setIsBadPassword(false);

    if (password !== value) {
      setIsBadPassword(true);
    }
  }, [password]);

  const normalizeIdentifier = useCallback((value: string) => (
    value.replace(' ', '-').toLowerCase()
  ), []);

  useEffect(() => {
    if (!firstname && !lastname) {
      if (!identifier) {
        oldGeneratedIdentifier.current = '';
      }
      return;
    }
    if (identifier === oldGeneratedIdentifier.current) {
      setIdentifier(`${firstname}-${lastname}`);
    }
    oldGeneratedIdentifier.current = `${firstname}-${lastname}`;
  }, [firstname, lastname, identifier]);

  const handleSubmit = useCallback((e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }

    if (password !== confirmPassword) {
      setIsBadPassword(true);
      return;
    }

    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData.entries());
    onSubmit({
      firstName: data.firstName as string,
      lastName: data.lastName as string,
      identifier: data.identifier as string,
      password,
      language: data.language as string,
    });
  }, [password, confirmPassword, isLoading, onSubmit]);

  return (
    <form className="AnswerForm" onSubmit={handleSubmit}>
      <FormFieldset>
        <FormGroup controlId="Answer__firstName" label={t('common:first-name')}>
          <FormControl
            type="text"
            id="firstName"
            name="firstName"
            placeholder={t('answer:placeholder.name')}
            isInvalid={!!errors?.firstName}
            onChange={(value: string) => { setFirstname(normalizeIdentifier(value)); }}
          />
          <ErrorValidationMessage error={errors?.firstName} />
        </FormGroup>
        <FormGroup controlId="Answer__lastName" label={t('common:last-name')}>
          <FormControl
            type="text"
            id="lastName"
            name="lastName"
            placeholder={t('answer:placeholder.lastName')}
            isInvalid={!!errors?.lastName}
            onChange={(value: string) => { setLastname(normalizeIdentifier(value)); }}
          />
          <ErrorValidationMessage error={errors?.lastName} />
        </FormGroup>
      </FormFieldset>
      <FormGroup label={t('common:langue')}>
        <FormSelect
          name="language"
          placeholder={t('common:please-choose')}
          selectOptions={Config.LANGUAGES}
          defaultValue={locale}
          isAsync
        />
      </FormGroup>
      <FormGroup controlId="Answer__identifier" label={t('common:identifier')}>
        <FormControl
          type="text"
          id="identifier"
          name="identifier"
          autoComplete="off"
          value={identifier}
          onChange={setIdentifier}
          placeholder={t('answer:placeholder.identifier')}
          isInvalid={!!errors?.identifier}
        />
        <ErrorValidationMessage error={errors?.identifier} />
      </FormGroup>
      <FormGroup controlId="Answer__password" label={t('common:password')}>
        <FormControl
          type="password"
          id="password"
          name="password"
          autoComplete="off"
          onChange={(value: string) => { setPassword(value); }}
          isInvalid={!!errors?.password}
        />
        <ErrorValidationMessage error={errors?.password} />
      </FormGroup>
      <FormGroup controlId="Answer__confirmPassword" label={t('common:confirm-password')}>
        <FormControl
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          autoComplete="off"
          onChange={handleConfirmPasswordChange}
          isInvalid={isBadPassword}
        />
        {isBadPassword && (
          <ErrorValidationMessage error={t('answer:bad-password')} />
        )}
      </FormGroup>
      <Button
        variant="primary"
        type="submit"
        className="AnswerForm__submit"
        disabled={isBadPassword}
      >
        {isLoading && <Loading />}
        {!isLoading && t('answer:join')}
      </Button>
    </form>
  );
};

export default AnswerForm;
