import React from 'react';
import { useTranslation } from 'react-i18next';
import FormSelect from 'components/FormSelect';

interface Props {
  onChange: (name: string, newValue: string | null) => void,
}

const SelectReportsFormat: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation();

  const [value, setValue] = React.useState<string>('');

  const handleChange = React.useCallback((name: string, newValue: string | null) => {
    setValue(newValue || '');
    onChange(name, newValue);
  }, [onChange, setValue]);

  return (
    <FormSelect
      className="ImportReports__filters__select-format"
      name="reportsFormat"
      selectOptions={[
        { label: 'CSV/XLSX', value: 'csv/xlsx' },
        { label: 'PDF', value: 'pdf' },
      ]}
      placeholder={t('reports:select-file-format')}
      value={value}
      onSelect={handleChange}
    />
  );
};

export default SelectReportsFormat;
