import type Misc from 'types/misc';
import type { CreditNote } from 'types/models';
import Request from 'utils/request';

export type FetchAllParams = {
  organization: string | undefined,
  fetchOptions: Misc.PaginatedFetchArgs<CreditNote>,
  filters: Misc.Filter[],
};

const fetchAll = async ({ organization, fetchOptions, filters }: FetchAllParams) => {
  if (!organization) {
    throw new Error('FetchAllCreditNotes: Missing organization.');
  }

  const { pageIndex, pageSize } = fetchOptions;

  const queryData = [
    `recordsPerPage=${pageSize}`,
    `page=${pageIndex + 1}`,
  ];

  if (filters && filters.length > 0) {
    filters.forEach(({ name, value }) => {
      queryData.push(`filters[${name}]=${encodeURIComponent(
        (Array.isArray(value) ? value.join(',') : value) || '',
      )}`);
    });
  }

  const result = await Request.get<Misc.Listing<CreditNote>>(
    `credit-note/${organization}?${queryData.join('&')}`,
  );
  return result;
};

export { fetchAll };
