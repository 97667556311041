import './index.scss';
import { useState, useCallback } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import type { PayRequestSummaryStep } from 'types/models';

type Props = {
  data: PayRequestSummaryStep[],
};

const HistoryStepPayRequestsGroup = ({ data }: Props): JSX.Element => {
  const [isFolded, setIsFolded] = useState(data.length >= 4);

  const classNames = classnames('HistoryStepPayRequestsGroup', {
    'HistoryStepPayRequestsGroup--unfolded': !isFolded,
  });

  const handleClickList = useCallback(() => {
    setIsFolded(false);
  }, []);

  return (
    <ul className={classNames} onClick={handleClickList}>
      {data.map((payRequest: PayRequestSummaryStep) => (
        <li key={payRequest.id} className="HistoryStepPayRequestsGroup__item">
          {isFolded && payRequest.reference}
          {!isFolded && (
            <Link to={`/payment-request/${payRequest.id}`}>{payRequest.reference}</Link>
          )}
        </li>
      ))}
    </ul>
  );
};

export default HistoryStepPayRequestsGroup;
