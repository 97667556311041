import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import type { Column } from 'react-table';
import config from 'config';
import type Misc from 'types/misc';
import type { HistoryStep } from 'types/models';
import { fetchAll } from 'api/historySteps';
import type { FetchAllParams } from 'api/historySteps';
import useFetchPaginated from 'hooks/useFetchPaginated';
import useContextualTranslation from 'hooks/useContextualTranslation';
import organizationStore from 'stores/Organization';
import actionsDoneFiltersStore from 'stores/FilterStores/ActionsDoneFilters';
import useToast from 'components/ToastNotification/useToast';
import ToastNotification from 'components/ToastNotification';
import DataTable from 'components/DataTable';
import PageList from 'components/PageList';
import LinkSwitch from 'components/LinkSwitch';
import getFiltersQueryString from 'utils/getFiltersQueryString';
import filtersSettings from './Filters/settings';
import ActionsSide from './Side';
import ActionsDoneFilters from './Filters';
import ActionsDoneColumns from './Columns';
import ExportLink from './ExportLink';

const DEFAULT_FILTERS = {
  period: 'today',
  type: [
    'sent_reminder',
    'sent_error_reminder',
    'activities-clients',
    'task_processed',
    'reminder_processed',
    'contact_processed',
  ],
};

const ActionsDone = () => {
  const history = useHistory();
  const { currentOrganization, walletSelection, linesOfBusiness } = organizationStore;
  const { ct } = useContextualTranslation(linesOfBusiness);

  const defaultFetchOptions = {
    pageIndex: 0,
    pageSize: config.DEFAULT_PAGE_SIZE,
    sort: { id: 'at', desc: false },
  };
  const [fetchOptions, setFetchOptions] = useState<Misc.PaginatedFetchArgs<HistoryStep>>(
    defaultFetchOptions,
  );

  const {
    filters,
    addOrUpdateFilters,
    removeFilter,
    resetAllFilters,
  } = actionsDoneFiltersStore;

  useEffect(() => {
    resetAllFilters(DEFAULT_FILTERS);
  }, [resetAllFilters]);

  const filtering = useMemo(() => {
    if (walletSelection.length === 0) {
      return filters;
    }
    return [...filters, { name: 'categories', value: walletSelection }];
  }, [filters, walletSelection]);

  const {
    data: steps,
    serverPagination,
    error,
    isFetching,
    isLoading,
    refetch,
  } = useFetchPaginated<FetchAllParams, HistoryStep>(
    {
      cacheKey: 'actionsDone',
      organization: currentOrganization?.reference,
      filtering,
      withUser: true,
      fetchOptions,
    },
    fetchAll,
    {
      enabled: !!currentOrganization,
      usePrefetching: false,
    },
  );

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const handleActionDone = useCallback((message: string) => {
    showToast(message, 'success');
    refetch();
  }, [showToast, refetch]);

  const handleActionError = useCallback((message: string) => {
    showToast(message, 'error');
  }, [showToast]);

  const columns = useMemo<Column<HistoryStep>[]>(() => (
    ActionsDoneColumns(ct, handleActionDone, handleActionError)
  ), [ct, handleActionDone, handleActionError]);

  const handleSwitchList = useCallback((listing: string) => {
    if (listing !== 'actions-future') {
      return;
    }
    const queryFilters = getFiltersQueryString(
      filters,
      ['channel', 'manager', 'search', 'client', 'user'],
    );
    history.push(`/${listing}?${queryFilters}`);
  }, [filters, history]);

  return (
    <div className="ActionsDone">
      <div className="ActionsDone__content">
        <ToastNotification
          text={toastText}
          status={toastStatus}
          isShow={isToastShowed}
          onClose={hideToast}
        />
        <ActionsSide
          portalID="ActionsDoneSidePortal"
        />
        <PageList
          title={ct('actions:actions-done')}
          className="ActionsDoneContent"
          count={serverPagination?.totalRecords}
          actions={(
            <LinkSwitch
              valueLabels={[
                { value: 'actions-future', label: ct('actions:future') },
                { value: 'actions-done', label: ct('actions:done') },
              ]}
              defaultValue="actions-done"
              onChange={handleSwitchList}
            />
          )}
        >
          <DataTable<HistoryStep>
            columns={columns}
            serverPagination={serverPagination}
            data={steps}
            defaultSortBy={defaultFetchOptions.sort}
            filtersList={ActionsDoneFilters(ct, filters)}
            filtering={filters}
            onRemoveFilter={removeFilter}
            onChangeFilters={addOrUpdateFilters}
            fetchData={setFetchOptions}
            isLoading={isFetching || isLoading}
            error={error}
            sidePortal={document.getElementById('ActionsDoneSidePortal')}
            filtersSettings={filtersSettings}
            withNoDataDrawing
            withActions={false}
            exportLink={ExportLink(filters, currentOrganization?.reference ?? null)}
            withPortalExportLink
          />
        </PageList>
      </div>
    </div>
  );
};

export default observer(ActionsDone);
