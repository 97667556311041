import queryString from 'query-string';
import type Misc from 'types/misc';
import type { Client, LastCustomerMainDebtor, HistoryStep } from 'types/models';
import Request from 'utils/request';

export type FetchOneOptions = {
  withEav: number,
  withUser: number,
};

export type FetchAllParams = {
  organization: string | undefined,
  filtering: Misc.Filter[],
  fetchOptions: Misc.PaginatedFetchArgs<Client>,
};

export type FetchMainDebtorsParams = {
  organization: string | undefined,
  filtering: Misc.Filter[],
  fetchOptions: Misc.PaginatedFetchArgs<FetchMainDebtorsParams>,
};

export type FetchOneParams = {
  organization: string | undefined,
  id: number | undefined,
  options?: FetchOneOptions,
};

export type FetchRefNameParams = {
  organization: string | undefined,
  id: string,
};

export type FetchCountParams = {
  organization: string | undefined,
  categories?: string[],
};

export type FetchActionsParams = {
  organization: string | undefined,
  id: number | undefined,
  limit?: number,
};

export type FetchHistoryParams = {
  organization: string | undefined,
  customerId: number | undefined,
  fetchOptions: Misc.PaginatedFetchArgs<HistoryStep>,
};

const fetchOne = async ({ organization, id, options }: FetchOneParams) => {
  if (!organization || !id) {
    throw new Error('FetchOneCustomer: Missing organization or id.');
  }

  let url = `client/${organization}/${id}`;
  if (options) {
    const query = queryString.stringify(options);
    url = `${url}?${query}`;
  }

  const result = await Request.get<Client>(url);
  return result;
};

const fetchRefName = async ({ organization, id }: FetchRefNameParams) => {
  if (!organization || id.length === 0) {
    throw new Error('fetchRefName: Missing organization or id.');
  }

  const url = `client/${organization}/id/${id}`;
  const result = await Request.get<Misc.IdRefName>(url);

  return result;
};

const fetchCount = async ({ organization, categories }: FetchCountParams) => {
  if (!organization) {
    throw new Error('FetchCountCustomers: Missing organization.');
  }

  const queryData = new URLSearchParams();
  if (categories && categories.length > 0) {
    queryData.append('filters[categories]', categories.join(','));
  }

  const result = await Request.get<Misc.ClientCountRecords>(
    `client/${organization}/count?${queryData.toString()}`,
  );
  return result;
};

const fetchAll = async ({ organization, filtering, fetchOptions }: FetchAllParams) => {
  if (!organization) {
    throw new Error('FetchAllCustomers: Missing organization.');
  }

  const { pageIndex, pageSize, sort } = fetchOptions;
  const queryData = new URLSearchParams();
  queryData.append('recordsPerPage', pageSize.toString());
  queryData.append('page', (pageIndex + 1).toString());

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }) => {
      queryData.append(
        `filters[${name}]`,
        (Array.isArray(value) ? value.join(',') : value) || '',
      );
    });
  }

  if (sort) {
    const direction = sort.desc ? 'desc' : 'asc';
    queryData.append('orderBy', sort.id);
    queryData.append('orderDirection', direction);
  }

  const result = await Request.get<Misc.Listing<Client>>(
    `client/${organization}?${queryData.toString()}`,
  );
  return result;
};

const fetchMainDebtors = async ({ organization, filtering, fetchOptions }: FetchMainDebtorsParams) => {
  if (!organization) {
    throw new Error('FetchMainDebtorsCustomers: Missing organization.');
  }

  const { sort, pageSize, pageIndex } = fetchOptions;
  const queryData = new URLSearchParams();
  queryData.append('recordsPerPage', pageSize.toString());
  queryData.append('page', (pageIndex + 1).toString());

  if (sort) {
    const direction = sort.desc ? 'desc' : 'asc';
    queryData.append('orderBy', sort.id);
    queryData.append('orderDirection', direction);
  }

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }) => {
      queryData.append(
        `filters[${name}]`,
        (Array.isArray(value) ? value.join(',') : value) || '',
      );
    });
  }

  const result = await Request.get<Misc.Listing<LastCustomerMainDebtor>>(
    `client/${organization}/last-customers?${queryData.toString()}`,
  );
  return result;
};

const fetchActions = async ({ organization, id, limit = 50 }: FetchActionsParams) => {
  if (!organization) {
    throw new Error('FetchCustomerActions: Missing organization.');
  }
  if (!id) {
    throw new Error('FetchCustomerActions: Missing customer ID.');
  }

  const result = await Request.get<Misc.Listing<HistoryStep>>(
    `client/${organization}/${id}/steps?recordsPerPage=${limit}`,
  );
  return result;
};

const fetchHistory = async ({ organization, customerId, fetchOptions }: FetchHistoryParams) => {
  if (!organization) {
    throw new Error('FetchHistoryCustomer: Missing organization.');
  }

  const { pageIndex, pageSize, filtering } = fetchOptions;
  const queryData = new URLSearchParams();
  queryData.append('recordsPerPage', pageSize.toString());
  queryData.append('page', (pageIndex + 1).toString());
  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }) => {
      queryData.append(
        `filters[${name}]`,
        (Array.isArray(value) ? value.join(',') : value) || '',
      );
    });
  }

  const result = await Request.get<Misc.Listing<HistoryStep>>(
    `client/${organization}/${customerId}/history?${queryData.toString()}`,
  );
  return result;
};

export { fetchOne, fetchRefName, fetchCount, fetchAll, fetchMainDebtors, fetchActions, fetchHistory };
