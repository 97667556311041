import './index.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import equal from 'deep-equal';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import type Errors from 'types/errors';
import type Misc from 'types/misc';
import type { PaymentPost, Payment, PayRequest } from 'types/models';
import organizationStore from 'stores/Organization';
import { fetchOne, FetchOneParams } from 'api/payments';
import { fetchAllForCustomer, FetchAllForCustomerParams } from 'api/pay-requests';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useFetch from 'hooks/useFetch';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import ModalForm from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import Confirm from 'components/Confirm';
import type { ModalFormData } from 'components/ModalForm';
import PaymentEditForm from './Form';

type Props = {
  reference?: string,
  customerReference?: string,
  onDone(title: string, message: string): void,
  onClose(): void,
  shouldRedirectToCreatedEntity?: boolean,
  assignedTo?: string,
  maxAmount?: number,
  payRequestId?: string,
};

const PaymentEditModal = (props: Props): JSX.Element => {
  const {
    reference,
    customerReference,
    assignedTo,
    onDone,
    onClose,
    shouldRedirectToCreatedEntity = false,
    maxAmount,
    payRequestId,
  } = props;
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  const { currentOrganization, linesOfBusiness } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);
  const cache = useQueryClient();

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Errors.Validation | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);
  const [selectedCustomerReference, setSelectedCustomerReference] = useState<string | null>(
    customerReference || null,
  );
  const initialData = useRef<PaymentPost | null>(null);

  const isNew = useMemo(() => !reference, [reference]);

  const { post, put, cancel, error } = useApiRequest();

  const { data: paymentData, isLoading } = useFetch<FetchOneParams, Payment>(
    {
      cacheKey: 'payment',
      organization: currentOrganization?.reference,
      reference: reference || '',
    },
    fetchOne,
    { enabled: !!reference },
  );

  const {
    isLoading: isLoadingPossiblePayRequests,
    data: possiblePayRequests,
  } = useFetch<FetchAllForCustomerParams, Misc.Listing<PayRequest>>(
    {
      cacheKey: 'customerPayRequests',
      organization: currentOrganization?.reference,
      customerReference: selectedCustomerReference || '',
      options: { pageIndex: 1, pageSize: 10 },
    },
    fetchAllForCustomer,
    { enabled: !!selectedCustomerReference },
  );

  useEffect(() => (
    () => { cancel(); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  const mapFormData = useCallback(
    (rawData: ModalFormData): PaymentPost | null => {
      if (!currentOrganization) {
        return null;
      }

      return {
        reference: rawData.reference as string,
        amount: rawData.amount as string,
        paidAt: rawData.paidAt as string,
        subject: rawData.subject as string,
        client: rawData.client as string,
        currency: rawData.currency as Misc.CurrencyCode,
        type: rawData.type as Misc.PaymentType,
        mean: rawData.mean as Misc.PaymentMethod,
        origin: rawData.origin as Misc.PaymentOrigin,
      };
    },
    [currentOrganization],
  );

  const handleInit = useCallback((formData: ModalFormData | null) => {
    initialData.current = formData ? mapFormData(formData) : null;
  }, [initialData, mapFormData]);

  const closeSelf = useCallback(() => {
    onClose();
    setShowCancelConfirm(false);
    setHasChanges(false);
    setValidationErrors(null);
  }, [onClose]);

  useEffect(() => {
    const { code } = error || { code: 0 };
    if (code === 404 || code === 2) {
      closeSelf();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleChange = useCallback((formData: ModalFormData | null) => {
    if (!formData) {
      return;
    }

    const data = mapFormData(formData);
    if (!data) {
      return;
    }

    setSelectedCustomerReference(data.client);
    const { ...initData } = initialData.current || { users: [] };
    const { ...changedData } = data;
    setHasChanges(!equal(initData, changedData));
  }, [initialData, mapFormData]);

  const handleCancel = useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirm(true);
      return;
    }

    closeSelf();
  }, [hasChanges, closeSelf]);

  const handleSubmit = async (formData: ModalFormData | null) => {
    if (!formData || !currentOrganization) {
      return;
    }

    const data = mapFormData(formData);
    if (!data) {
      return;
    }

    const payRequestsReference = Object.keys(formData)
      .filter((keyName) => keyName.startsWith('payRequests-reference'))
      .map((keyName) => formData[keyName]);

    const payRequests = payRequestsReference.map((value, index: number) => ({
      reference: value as string,
      amountAssigned: Number.parseFloat(
        formData[`payRequests-amountAssigned-${index}`] as string,
      ),
    }));

    let result;
    if (isNew) {
      result = await post<Misc.IdRef>(
        `payments/manage/${currentOrganization.reference}`,
        { ...data, payRequests },
      );
    } else if (!isNew && paymentData) {
      result = await put<Misc.IdRef>(
        `payments/manage/${currentOrganization.reference}/${paymentData.reference}`,
        { ...data, payRequests, origin: 'FRONT' },
      );
    }

    cache.invalidateQueries({ queryKey: ['payment'] });
    cache.invalidateQueries({ queryKey: ['payments'] });

    if (!isMountedRef.current) {
      return;
    }

    if (result?.errors) {
      setValidationErrors(result.errors);
      return;
    }

    if (!result?.reference) {
      return;
    }

    const { reference: resultReference } = result;
    if (isNew) {
      onDone(
        ct('payments:toast.created'),
        ct('payments:toast.created-reference', { reference: resultReference }),
      );
    } else {
      onDone(
        ct('payments:toast.modified'),
        ct('payments:toast.modified-reference', { reference: resultReference }),
      );
    }
    closeSelf();
    if (shouldRedirectToCreatedEntity) {
      history.push(`/payments/view/${resultReference}`);
    }
  };

  return (
    <ModalForm
      className="PaymentEdit"
      title={isNew ? ct('common:new-payment') : ct('common:edit-payment')}
      isOpened
      onInit={handleInit}
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isFetched={!isLoading}
      isLoading={isLoading && !isNew}
    >
      {error && <ErrorMessage error={error} />}
      <PaymentEditForm
        customerReference={selectedCustomerReference}
        defaultData={paymentData}
        isCreate={isNew}
        assignedTo={assignedTo}
        maxAmount={maxAmount}
        payRequestId={payRequestId}
        isLoadingPossiblePayRequests={isLoadingPossiblePayRequests}
        possiblePayRequests={possiblePayRequests?.records}
        errors={validationErrors}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={() => { closeSelf(); }}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(PaymentEditModal);
