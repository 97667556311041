import './index.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { StepEvent } from 'types/models';
import formatIntlDate from 'utils/formatIntlDate';
import dateIsToday from 'utils/dateIsToday';
import dateIsLastTwoDays from 'utils/dateIsLastTwoDays';
import ClientName from 'components/ClientName';

type Props = {
  data: StepEvent,
};

const HomeHistoryItem = ({ data }: Props): JSX.Element => {
  const { date, name, customer } = data;
  const { t } = useTranslation();

  const dateDisplay = useMemo<string>(() => {
    if (dateIsToday(date)) {
      return t('common:today-shortened');
    }
    if (dateIsLastTwoDays(date)) {
      return t('common:yesterday-shortened');
    }
    return formatIntlDate(date, 'dayMonthShort');
  }, [date, t]);

  return (
    <li className="HomeHistoryItem">
      <div className="HomeHistoryItem__date">
        {dateDisplay}
      </div>
      <div className="HomeHistoryItem__content">
        <div className="HomeHistoryItem__content__title">
          {name}
        </div>
        <div className="HomeHistoryItem__content__data">
          <Link to={`/customer/${customer.id}/summary`} className="HomeHistoryItem__content__link">
            <ClientName client={customer} />
          </Link>
        </div>
      </div>
    </li>
  );
};

export default HomeHistoryItem;
