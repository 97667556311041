import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
} from 'chart.js';
import type Misc from 'types/misc';
import type { AnalysisActivityChartData } from 'types/models';
import getColorChannel from 'utils/getColorChannel';
import formatIntlDate from 'utils/formatIntlDate';
import getI18nChannel from 'utils/getI18nChannel';
import defaultChartOptions from '../../defaultChartOptions';
import getChartData from './utils';

type Props = {
  data: AnalysisActivityChartData[],
};

ChartJS.register(BarElement, PointElement, LinearScale, CategoryScale, Tooltip);

const ReminderSentChart = ({ data }: Props): JSX.Element => {
  const { t } = useTranslation();

  const getLabel = useCallback((date: string): string => {
    const formattedDate = formatIntlDate(`${date}-01`, 'monthLong');
    return `${formattedDate.charAt(0).toUpperCase()}${formattedDate.slice(1)}`;
  }, []);

  const [chartData, setChartData] = useState<Misc.FormattedDataType[]>(
    getChartData(data, getLabel),
  );

  useEffect(() => {
    setChartData(getChartData(data, getLabel));
  }, [data, getLabel]);

  const buildDataset = useCallback((datasetType: Misc.Channel) => (
    {
      data: chartData.filter(({ type }) => type === datasetType).map(
        ({ withValidation, withoutValidation }) => (
          (withoutValidation || 0) + (withValidation || 0)
        ),
      ),
      label: t(getI18nChannel(datasetType)),
      borderJoinStyle: 'round' as const,
      pointRadius: 10,
      pointHoverRadius: 10,
      pointBorderWidth: 0,
      backgroundColor: getColorChannel(datasetType),
      borderWidth: 1,
    }
  ), [chartData, t]);

  const chart = useMemo(() => (
    {
      labels: chartData.filter(({ type }) => type === 'SMS').map(({ label }) => label),
      datasets: [
        buildDataset('EMAIL'),
        buildDataset('SMS'),
        buildDataset('LETTER'),
        buildDataset('REGISTERED_LETTER'),
      ],
    }
  ), [buildDataset, chartData]);

  return (
    <div className="ReminderSentChart">
      <div className="ReminderSentChart__chart">
        <Bar
          data={chart}
          options={{
            ...defaultChartOptions,
            scales: {
              yAxes: {
                beginAtZero: true,
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default observer(ReminderSentChart);
