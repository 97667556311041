import type { ComponentType } from 'react';
import type { RouteProps, RouteComponentProps } from 'react-router-dom';
import { useRef, useCallback } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import authStore from 'stores/Auth';
import { analyticsNavigate } from 'utils/analytics';
import ReservedAccess from 'pages/ReservedAccess';

interface CustomRouteProps extends RouteProps {
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>,
  routeName: string,
}

export const AnalyzableRoute = (props: CustomRouteProps) => {
  const { routeName, component: ChildComponent, ...otherProps } = props;

  const previousLocation = useRef<string>('');

  const render = useCallback((renderProps: RouteComponentProps) => {
    analyticsNavigate(routeName, window.location.toString(), previousLocation.current);
    previousLocation.current = window.location.toString();
    return <ChildComponent {...renderProps} />;
  }, [ChildComponent, routeName]);

  return <Route {...otherProps} render={render} />;
};

export const PrivateRoute = observer((props: CustomRouteProps) => {
  const { user, isLogged, isFetched } = authStore;
  const mayBeLogged = !!user || (isFetched && isLogged);

  return (
    mayBeLogged ? <AnalyzableRoute {...props} /> : <Redirect to="/login" />
  );
});

export const PrivateAdminRoute = observer(({ component, ...props }: CustomRouteProps) => {
  const { user, isLogged, isFetched } = authStore;
  const mayBeLogged = !!user || (isFetched && isLogged);

  return (
    user && mayBeLogged && user.roles.includes('ROLE_SUPER_ADMIN')
      ? <AnalyzableRoute {...props} component={component} />
      : <PrivateRoute component={ReservedAccess} {...props} />
  );
});
