import './index.scss';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { observer } from 'mobx-react';
import config from 'config';
import type { OrganizationPlanTemplate } from 'types/models';
import type { FetchOneParams } from 'api/plans';
import { fetchOne } from 'api/plans';
import organizationStore from 'stores/Organization';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormSelect from 'components/FormSelect';
import useTooltip from 'components/Tooltip/useTooltip';
import Icon from 'components/Icon';
import useIsMountedRef from 'hooks/useIsMountedRef';
import useFetch from 'hooks/useFetch';
import useApiRequest from 'hooks/useApiRequest';
import getI18nPlanChangeCondition from 'utils/getI18nPlanChangeCondition';
import GroupedNameInput from '../../GroupedNameInput';

type Props = {
  name: string,
  identifier: string,
  onClose(): void,
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const ModalEditPlan = (props: Props): JSX.Element => {
  const { onClose, onActionDone, onActionError, name, identifier } = props;
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const { currentOrganization } = organizationStore;
  const [isGroupChecked, setIsGroupChecked] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { put } = useApiRequest();
  const cache = useQueryClient();

  const { data: details, isLoading } = useFetch<FetchOneParams, OrganizationPlanTemplate>(
    {
      cacheKey: 'organizationPlanTemplate',
      organization: currentOrganization?.reference,
      identifier,
    },
    fetchOne,
  );

  useEffect(() => {
    setIsGroupChecked(details?.group || false);
  }, [details]);

  const tooltipDate = useTooltip(
    <div className="PlansModalEditPlan__tooltip">
      {t('plans:select-reference-date-tooltip')}
    </div>,
    'clamped',
  );

  const tooltipConditionnalChange = useTooltip(
    <div className="PlansModalEditPlan__tooltip">
      {t('plans:conditional-plan-change-tooltip')}
    </div>,
    'clamped',
  );

  const handleSubmit = async (formData: ModalFormData | null) => {
    if (!formData || !currentOrganization || !isMountedRef.current) {
      return;
    }
    setIsSaving(true);

    const { referenceDate, condition, newPlan, ...params } = formData;
    const saveData = {
      ...params,
      identifier,
      dateReferenceIsToday: referenceDate === 'plan-setup',
      group: isGroupChecked,
      planChange: active ? { conditions: [condition], newPlan } : null,
    };

    const result = await put(`/manage-plans/${currentOrganization.reference}`, saveData);
    setIsSaving(false);

    cache.invalidateQueries({ queryKey: ['organizationPlanTemplate'] });

    if (!result) {
      onActionError(t('plans:toast.error.edit-plan'));
      return;
    }

    onActionDone(t('plans:toast.success.edited-plan', { name: formData.name }));
    onClose();
  };

  const dateReference = useMemo(() => {
    if (!details) {
      return '';
    }
    if (details.dateReferenceIsToday) {
      return 'plan-setup';
    }
    return details.group ? 'invoice-oldest-deadline' : 'invoice-deadline';
  }, [details]);

  const dateReferenceSelectOptions = useMemo(() => {
    const planSetup = { label: t('plans:reference-date-options.plan-setup'), value: 'plan-setup' };
    return isGroupChecked
      ? [{ label: t('plans:reference-date-options.invoice-oldest-deadline'), value: 'invoice-oldest-deadline' }, planSetup]
      : [{ label: t('plans:reference-date-options.invoice-deadline'), value: 'invoice-deadline' }, planSetup];
  }, [isGroupChecked, t]);

  const activeSelectOptions = useMemo(() => (
    [{ label: t('common:active'), value: '1' }, { label: t('common:inactive'), value: '0' }]
  ), [t]);

  const conditionDefaultValue = useMemo(() => {
    if (isLoading || !details) {
      return '';
    }
    if (!details.planChange) {
      return '0';
    }
    return (Array.isArray(details.planChange))
      ? details.planChange[0].conditions
      : details.planChange.conditions;
  }, [details, isLoading]);

  const newPlanDefaultValue = useMemo(() => {
    if (!details || !details.planChange || !details.planChange) {
      return '';
    }
    return (Array.isArray(details.planChange))
      ? details.planChange[0].newPlan
      : details.planChange.newPlan;
  }, [details]);

  return (
    <ModalForm
      isOpened
      className="PlansModalEditPlan"
      onSave={handleSubmit}
      onCancel={onClose}
      title={t('plans:title-edit-plan')}
      isLoading={isSaving}
    >
      <GroupedNameInput
        isGroup={isGroupChecked}
        defaultName={name}
        onChangeIsGroup={setIsGroupChecked}
      />
      <FormFieldset>
        {!isLoading && (
          <FormGroup label={t('plans:select-reference-date')} mandatory>
            <div ref={tooltipDate} className="PlansModalEditPlan__tooltip-icon">
              <Icon name="help-circle" />
            </div>
            <FormSelect
              name="referenceDate"
              defaultValue={dateReference}
              selectOptions={dateReferenceSelectOptions}
              placeholder={t('plans:choose-reference-date')}
            />
          </FormGroup>
        )}
        <FormGroup label={t('plans:conditional-plan-change')} mandatory>
          {details && (
            <>
              <div ref={tooltipConditionnalChange} className="PlansModalEditPlan__tooltip-icon">
                <Icon name="help-circle" />
              </div>
              <FormSelect
                name="active"
                selectOptions={activeSelectOptions}
                defaultValue={details?.planChange ? '1' : '0'}
                withClearButton={false}
                value={active ? '1' : '0'}
                onSelect={(__name, newValue) => { setActive(newValue === '1'); }}
                placeholder={t('plans:choose-reference-date')}
              />
            </>
          )}
        </FormGroup>
      </FormFieldset>
      {active && (
        <FormFieldset>
          <FormGroup label={t('plans:select-condition')} mandatory>
            {conditionDefaultValue !== '' && (
              <FormSelect
                name="condition"
                selectOptions={config.PLAN_CHANGE_CONDITION.map((condition) => ({
                  label: t(getI18nPlanChangeCondition(condition)), value: condition,
                }))}
                defaultValue={Array.isArray(conditionDefaultValue) ? conditionDefaultValue.join(',') : conditionDefaultValue}
                placeholder={t('plans:choose-reference-date')}
              />
            )}
          </FormGroup>
          <FormGroup label={t('plans:select-new-plan')} mandatory>
            <FormSelect
              name="newPlan"
              isAsync
              fetchEntity={`manage-plans/${currentOrganization?.reference}/list`}
              hasFetchOrganizationPrefix={false}
              defaultValue={newPlanDefaultValue}
              entityValue="identifier"
              placeholder={t('plans:choose-plan-to-duplicate')}
            />
          </FormGroup>
        </FormFieldset>
      )}
    </ModalForm>
  );
};

export default observer(ModalEditPlan);
