import './index.scss';
import { Fragment } from 'react';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import { useParams } from 'react-router-dom';
import authStore from 'stores/Auth';
import organizationStore from 'stores/Organization';
import userRightsStore from 'stores/UserRights';
import useContextualTranslation from 'hooks/useContextualTranslation';
import ToastNotification from 'components/ToastNotification';
import Dropdown from 'components/Dropdown';
import useToast from 'components/ToastNotification/useToast';
import PaymentRequestEdit from 'pages/PaymentRequestEdit';
import CustomerEdit from 'components/CustomerEdit';
import {
  ExternallyMonitoredController,
  ExternallyMonitoredSwitch,
} from 'components/ExternallyMonitored';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import PaymentEditModal from 'components/PaymentEditModal';

type RouteParams = {
  customerId?: string,
};

const CreateMenu = (): JSX.Element => {
  const { userRight, linesOfBusiness } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);
  const { user } = authStore;
  const { rights } = userRightsStore;
  const { customerId } = useParams<RouteParams>();

  const hasOrganizations = (user?.organizations && user.organizations.length !== 0);
  const isUserReader = userRight === 'ROLE_READER';

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const [showPayRequestModal, hidePayRequestModal] = useModal(() => (
    <PaymentRequestEdit
      onDone={(message) => { hidePayRequestModal(); showToast(message, 'success'); }}
      onClose={hidePayRequestModal}
      shouldRedirectToCreatedEntity
    />
  ), [showToast]);

  const [showPaymentModal, hidePaymentModal] = useModal(() => (
    <PaymentEditModal
      onDone={(message) => { hidePaymentModal(); showToast(message, 'success'); }}
      onClose={hidePaymentModal}
      shouldRedirectToCreatedEntity
    />
  ), [showToast]);

  const [showCustomerModal, hideCustomerModal] = useModal(() => (
    <CustomerEdit
      editType="new"
      onDone={(message) => { hideCustomerModal(); showToast(message, 'success'); }}
      customerId={customerId ? Number.parseInt(customerId, 10) : undefined}
      onClose={hideCustomerModal}
      shouldRedirectToCreatedEntity
    />
  ), [customerId, showToast]);

  if (!rights || !hasOrganizations || isUserReader) {
    return <div className="CreateMenu__hidden" />;
  }

  return (
    <Fragment>
      <ExternallyMonitoredSwitch>
        <ExternallyMonitoredController isDisplayedWhenMonitored>
          <div className="CreateMenu__hidden" />
        </ExternallyMonitoredController>
        <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
          <Dropdown
            className="CreateMenu"
            toggle={t('common:menu.current-organization.new')}
          >
            <ButtonWithUserRights
              variant="list"
              className="CreateMenu__link"
              onClick={showCustomerModal}
              action="CREATE"
              category="CLIENTS_ASSIGNED_TO_ME"
            >
              {ct('common:menu.current-organization.new-customer')}
            </ButtonWithUserRights>
            <ButtonWithUserRights
              action="CREATE"
              category="PAYREQUESTS_ASSIGNED_TO_ME"
              className="CreateMenu__link"
              onClick={showPayRequestModal}
              variant="list"
            >
              {ct('common:menu.current-organization.new-manual-bill')}
            </ButtonWithUserRights>
            <ButtonWithUserRights
              variant="list"
              className="CreateMenu__link"
              action="CREATE"
              category="CLIENTS_ASSIGNED_TO_ME"
              onClick={showPaymentModal}
            >
              {ct('common:menu.current-organization.new-payment')}
            </ButtonWithUserRights>
            <ButtonWithUserRights
              className="CreateMenu__link"
              variant="list"
              href="/imports/new/pdf"
              action="CREATE"
              category="IMPORTS"
            >
              {ct('common:menu.current-organization.new-pdf-bill')}
            </ButtonWithUserRights>
            <ButtonWithUserRights
              variant="list"
              className="CreateMenu__link"
              href="/imports/new/csv-xlsx"
              action="CREATE"
              category="IMPORTS"
            >
              {ct('common:menu.current-organization.new-csv-xlsx-file')}
            </ButtonWithUserRights>
          </Dropdown>
        </ExternallyMonitoredController>
      </ExternallyMonitoredSwitch>
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </Fragment>
  );
};

export default observer(CreateMenu);
