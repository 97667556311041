import { useMemo } from 'react';
import { observer } from 'mobx-react';
import organizationStore from 'stores/Organization';
import type { Payment } from 'types/models';
import type { FetchOneParams } from 'api/payments';
import { fetchOne } from 'api/payments';
import useContextualTranslation from 'hooks/useContextualTranslation';
import ErrorMessage from 'components/ErrorMessage';
import useFetch from 'hooks/useFetch';
import Modal from 'components/Modal';
import Button from 'components/Button';
import PaymentViewModalContent from './Content';

type Props = {
  reference: string,
  onClose(): void,
  onClickEdit(reference: string): void,
};

const PaymentViewModal = ({ reference, onClose, onClickEdit }: Props): JSX.Element => {
  const { currentOrganization, linesOfBusiness, userRight } = organizationStore;
  const isUserReader = userRight === 'ROLE_READER';
  const { t, ct } = useContextualTranslation(linesOfBusiness);

  const { data, isLoading, error } = useFetch<FetchOneParams, Payment>({
    cacheKey: 'payment',
    organization: currentOrganization?.reference,
    reference: reference || '',
  }, fetchOne);

  const title = useMemo(() => {
    if (!data) {
      return '';
    }
    const { type, reference: ref } = data;
    if (type === 'CREDIT_NOTE') {
      return ct('payments:credit-note', { reference: ref });
    }
    if (type === 'REFUND') {
      return ct('payments:refund', { reference: ref });
    }
    return ct('payments:payment', { reference: ref });
  }, [data, ct]);

  return (
    <Modal
      className="PaymentViewModal"
      title={title}
      headerActions={!isUserReader && (
        <Button variant="outline" onClick={() => { onClickEdit(reference); }}>
          {t('common:edit')}
        </Button>
      )}
      onClose={onClose}
      isLoading={isLoading}
      isOpened
    >
      <PaymentViewModalContent payment={data} onClickEdit={onClickEdit} />
      {error && <ErrorMessage error={error} />}
    </Modal>
  );
};

export default observer(PaymentViewModal);
