import type Misc from 'types/misc';

const getI18nReportType = (type: Misc.ReportItemType, details?: 'short' | 'long'): string => {
  const i18nTypeLong = {
    PAY_REQUEST_CREATE: 'reports:type-name.pay-request-create',
    CLIENT: 'reports:type-name.client',
    CONTACT: 'reports:type-name.contact',
    PAYMENT: 'reports:type-name.payment',
    PAYMENT_CREATE: 'reports:type-name.payment',
    CLIENT_CONTACT: 'reports:type-name.client-contact',
    PAY_REQUEST_UPDATE: 'reports:type-name.pay-request-update',
    PAY_REQUEST_CREATE_UPDATE: 'reports:type-name.pay-request-create-update',
    CLIENT_COMPANY_CREATE: 'reports:type-name.client-company-create',
    CLIENT_COMPANY_UPDATE: 'reports:type-name.client-company-update',
    CLIENT_CONTACT_COMPANY_CREATE: 'reports:type-name.client-contact-company-create',
    CLIENT_CONTACT_COMPANY_UPDATE: 'reports:type-name.client-contact-company-update',
    CLIENT_INDIVIDUAL_CREATE: 'reports:type-name.client-individual-create',
    CLIENT_INDIVIDUAL_UPDATE: 'reports:type-name.client-individual-update',
    CONTACT_CREATE: 'reports:type-name.contact-create',
    CONTACT_UPDATE: 'reports:type-name.contact-update',
    UNKNOWN: 'reports:type-name.unknown',
  };

  const i18nTypeShort = {
    PAY_REQUEST_CREATE: 'reports:type-name.pay-request',
    CLIENT: 'reports:type-name.client',
    CONTACT: 'reports:type-name.contact',
    PAYMENT: 'reports:type-name.payment',
    PAYMENT_CREATE: 'reports:type-name.payment',
    CLIENT_CONTACT: 'reports:type-name.client-contact',
    PAY_REQUEST_UPDATE: 'reports:type-name.pay-request',
    PAY_REQUEST_CREATE_UPDATE: 'reports:type-name.pay-request',
    CLIENT_COMPANY_CREATE: 'reports:type-name.client',
    CLIENT_COMPANY_UPDATE: 'reports:type-name.client',
    CLIENT_CONTACT_COMPANY_CREATE: 'reports:type-name.client-contact',
    CLIENT_CONTACT_COMPANY_UPDATE: 'reports:type-name.client-contact',
    CLIENT_INDIVIDUAL_CREATE: 'reports:type-name.client',
    CLIENT_INDIVIDUAL_UPDATE: 'reports:type-name.client',
    CONTACT_CREATE: 'reports:type-name.contact',
    CONTACT_UPDATE: 'reports:type-name.contact',
    UNKNOWN: 'reports:type-name.unknown',
  };

  return details !== 'short' ? i18nTypeLong[type] : i18nTypeShort[type];
};

export default getI18nReportType;
