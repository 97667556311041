import type Misc from 'types/misc';

const normalizeActionName = (channel: Misc.Channel | null) => {
  if (!channel) {
    return null;
  }

  return channel === 'REGISTERED_LETTER' ? 'letter' : channel.toLowerCase();
};

export default normalizeActionName;
