import axios from 'axios';
import type { InternalAxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import Config from 'config';
import authStore from 'stores/Auth';
import type Errors from 'types/errors';
import ResponseError from './errors';

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const { headers } = config;
  if (!headers) {
    return config;
  }

  const authToken = window.localStorage.getItem('token');
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  return { ...config, headers };
};

const onResponse = (response: AxiosResponse): AxiosResponse => response.data;

const onResponseError = (error: unknown) => {
  if (axios.isCancel(error)) {
    return Promise.reject();
  }

  if (!axios.isAxiosError(error) || !(error as AxiosError).response) {
    // eslint-disable-next-line no-console
    console.error(error);
    return Promise.reject(new Error());
  }

  const { status, data } = error.response as AxiosResponse<Errors.RawResponse>;
  const { code: responseCode, message, detail: details, trace } = data;

  if (status === 401) {
    authStore.logout();
  }

  if (status === 400 || responseCode === 1 || status === 403) {
    return Promise.resolve({ ...data, status });
  }

  return Promise.reject(
    new ResponseError(responseCode || status, message, details, trace as Errors.Trace[]),
  );
};

const Request = axios.create({
  baseURL: Config.API_URL,
});

Request.interceptors.request.use(onRequest);
Request.interceptors.response.use(onResponse, onResponseError);

export default Request;
