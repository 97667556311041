import './index.scss';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Config from 'config';
import type Errors from 'types/errors';
import type { OrganizationSummary } from 'types/models';
import getI18nTypeOrganization from 'utils/getI18nTypeOrganization';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormSelect from 'components/FormSelect';

interface Props {
  defaultData: OrganizationSummary | null,
  errors?: Errors.Validation | null,
}

const CurrentOrganizationForm: React.FC<Props> = ({ defaultData, errors }) => {
  const { t } = useTranslation();

  const currenciesOptions = React.useMemo(() => (
    Config.CURRENCIES.map((currency) => ({
      label: currency,
      value: currency,
    }))
  ), []);

  const typeOptions = React.useMemo(() => (
    Config.TYPES_ORGANIZATION.map((type) => ({
      label: t(getI18nTypeOrganization(type)),
      value: type,
    }))
  ), [t]);

  return (
    <div className="CurrentOrganizationForm">
      <FormFieldset>
        <FormGroup label={t('organizations:corporate-name')} error={errors?.name}>
          <FormControl
            name="name"
            autoComplete="off"
            defaultValue={defaultData?.name || ''}
            isInvalid={!!errors?.name}
          />
        </FormGroup>
        <FormGroup label={t('organizations:legal-id')} error={errors?.legalId}>
          <FormControl
            name="legalId"
            autoComplete="off"
            defaultValue={defaultData?.legalId || ''}
            isInvalid={!!errors?.legalId}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('organizations:currency')} error={errors?.currency}>
          <FormSelect
            name="currency"
            placeholder={t('common:please-choose')}
            selectOptions={currenciesOptions}
            defaultValue={defaultData?.currency || ''}
            isInvalid={!!errors?.currency}
            isAsync
          />
        </FormGroup>
        <FormGroup label={t('organizations:type')} error={errors?.type}>
          <FormSelect
            name="type"
            placeholder={t('common:please-choose')}
            selectOptions={typeOptions}
            defaultValue={defaultData?.type || ''}
            isInvalid={!!errors?.type}
            isAsync
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('organizations:address')} error={errors?.address}>
          <FormControl
            name="address"
            autoComplete="off"
            defaultValue={defaultData?.address || ''}
            isInvalid={!!errors?.address}
          />
        </FormGroup>
        <FormGroup label={t('organizations:city')} error={errors?.city}>
          <FormControl
            name="city"
            autoComplete="off"
            defaultValue={defaultData?.city || ''}
            isInvalid={!!errors?.city}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('organizations:postal-code')} error={errors?.postalCode}>
          <FormControl
            name="postalCode"
            autoComplete="off"
            defaultValue={defaultData?.postalCode || ''}
            isInvalid={!!errors?.postalCode}
          />
        </FormGroup>
        <FormGroup label={t('organizations:country')} error={errors?.country}>
          <FormControl
            name="country"
            autoComplete="off"
            defaultValue={defaultData?.country || ''}
            isInvalid={!!errors?.country}
          />
        </FormGroup>
      </FormFieldset>
    </div>
  );
};

export default CurrentOrganizationForm;
