import './index.scss';
import { useCallback, useMemo, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useTranslation } from 'react-i18next';
import type Errors from 'types/errors';
import type Misc from 'types/misc';
import type { PayRequest } from 'types/models';
import getI18nChannel from 'utils/getI18nChannel';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import ModalModels from 'components/ModalModels';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import Confirm from 'components/Confirm';
import ReminderEditForm from './Form';

type Props = {
  channel: Misc.Channel,
  forceGroupId?: number | null,
  payRequestData?: PayRequest | null,
  onDone(message: string): void,
  onClose(): void,
};

const CreateReminderEdit = (props: Props): JSX.Element => {
  const { channel, forceGroupId, payRequestData, onDone, onClose } = props;
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Errors.Validation | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);
  const [model, setModel] = useState<Misc.PlanTemplateReminder | undefined>();
  const { post, isLoading, error } = useApiRequest();

  const closeSelf = useCallback(() => {
    if (!isMountedRef.current) {
      return;
    }

    setShowCancelConfirm(false);
    setHasChanges(false);
    setValidationErrors(null);
    onClose();
  }, [isMountedRef, onClose]);

  const handleCancel = useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirm(true);
    } else {
      closeSelf();
    }
  }, [hasChanges, closeSelf]);

  const [showModalModels, hideModalModels] = useModal(() => (
    <ModalModels
      type="reminder"
      onClose={hideModalModels}
      onSelect={setModel}
      channel={channel}
    />
  ), [channel]);

  const handleSubmit = useCallback(async (formData: ModalFormData | null) => {
    if (!formData) {
      return;
    }

    setValidationErrors(null);

    const data = {
      channel,
      roles: (formData.roles as string).split(',').map((role) => ({ identifier: role })),
      name: formData.name as string,
      description: formData.description as string,
      startAt: formData.startAt as string,
      subject: formData.subject as string,
      content: formData.content as string,
      needValidation: formData.needValidation === '1',
      group: !!forceGroupId || formData.group === '1',
      payRequestId: payRequestData?.id,
      groupId: forceGroupId || payRequestData?.group?.id,
    };
    const result = await post<Misc.IdName>('task/new/reminder', data);

    if (!isMountedRef.current) {
      return;
    }

    if (result?.errors) {
      setValidationErrors(result.errors);
      return;
    }

    closeSelf();

    if (result?.name) {
      const { name } = result;
      onDone(t('reminders:reminder.toast.created', { name }));
    }
  }, [channel, forceGroupId, payRequestData, post, isMountedRef, closeSelf, onDone, t]);

  const title = useMemo(() => (
    forceGroupId
      ? t('reminders:new-reminder-group-using-channel', { channel: t(getI18nChannel(channel)) })
      : t('reminders:new-reminder-using-channel', { channel: t(getI18nChannel(channel)) })
  ), [forceGroupId, t, channel]);

  return (
    <ModalForm
      isOpened
      className="CreateReminderEdit"
      title={title}
      helpHeaderText={forceGroupId ? t<string>('reminders:new-reminder-group-help') : undefined}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
    >
      <ReminderEditForm
        channel={channel}
        errors={validationErrors}
        withGroup={!forceGroupId && payRequestData?.group !== null}
        template={model}
        onShowModalModels={showModalModels}
      />
      {error && <ErrorMessage error={error} />}
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t<string>('common:close-form')}
        cancelButtonText={t<string>('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={closeSelf}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default CreateReminderEdit;
