import './index.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import config from 'config';
import authStore from 'stores/Auth';
import Button from 'components/Button';
import Icon from 'components/Icon';
import setNewLang from './setLang';

const FreshDesk = (): JSX.Element => {
  const { t } = useTranslation();
  const { locale } = authStore.user || config.DEFAULT_LOCALE;

  const [isDisplayed, setIsDisplayed] = useState<boolean>(false);

  useEffect(() => {
    setNewLang(locale);
  }, [locale]);

  const mutationObserverCallback = useCallback((event: MutationRecord[]) => {
    if (event[0].removedNodes.length) {
      setIsDisplayed(false);
    }
  }, []);

  const mutationObserver = useRef(new MutationObserver(mutationObserverCallback));

  const toggleWidget = useCallback(() => {
    setIsDisplayed(!isDisplayed);
  }, [isDisplayed]);

  useEffect(() => {
    const mutation = mutationObserver.current;
    const container = document.getElementById('freshworks-container');

    if (container) {
      container.style.display = isDisplayed ? 'block' : 'none';

      const contentDiv = container?.firstElementChild;
      if (contentDiv) {
        mutation.observe(contentDiv, { childList: true });
      }
    }

    if (isDisplayed) {
      // @ts-ignore
      window.FreshworksWidget('open');
    }

    return () => {
      mutation.disconnect();
    };
  }, [isDisplayed]);

  return (
    <Button className="FreshDesk" variant="list" onClick={toggleWidget}>
      <Icon name="comment" /> {t('common:support')}
    </Button>
  );
};

export default observer(FreshDesk);
