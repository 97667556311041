import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import Button from 'components/Button';
import Loading from 'components/Loading';

type Props = {
  onSubmit: () => void,
  isLoading?: boolean,
  disabled?: boolean,
  email: string,
  onChange: (newEmail: string) => void,
  error: string | null,
  cooldown: number,
};

const ConfirmResendForm: React.FC<Props> = (props) => {
  const {
    onSubmit,
    isLoading = false,
    error,
    onChange,
    disabled,
    email,
    cooldown,
  } = props;

  const { t } = useTranslation();

  const handleSubmit = React.useCallback((e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    onSubmit();
  }, [isLoading, onSubmit]);

  return (
    <form className="ConfirmResendForm" onSubmit={handleSubmit}>
      <FormGroup controlId="ConfirmResendEmail" label={t('common:email')}>
        <FormControl
          type="email"
          id="loginEmail"
          placeholder={t('login:enter-email')}
          value={email}
          onChange={onChange}
          isInvalid={error !== null}
        />
      </FormGroup>
      {error !== null && (
        <h4 className="ConfirmResendForm__error">{error}</h4>
      )}
      {disabled && (
        <p className="ConfirmResendForm__info">{t('confirm-email:wait-before-sending', { time: cooldown })}</p>
      )}
      <Button
        variant="primary"
        type="submit"
        className="ConfirmResendForm__submit"
        disabled={disabled}
      >
        {isLoading && <Loading />}
        {!isLoading && t('confirm-email:send-again')}
      </Button>
    </form>
  );
};

export default ConfirmResendForm;
