import './index.scss';
import type { ReactNode } from 'react';
import i18next from 'i18next';
import { observer } from 'mobx-react';
import config from 'config';
import BrandLogo from 'components/BrandLogo';
import FormSelect from 'components/FormSelect';
import Links from './Links';

type Props = {
  withLanguageSelection?: boolean,
  children: ReactNode,
};

const PublicLayout = ({ withLanguageSelection = false, children }: Props): JSX.Element => {
  const currentYear = (new Date()).getFullYear();

  return (
    <div className="PublicLayout">
      <div className="PublicLayout__intro">
        <BrandLogo />
        <Links />
        <div className="PublicLayout__intro__footer">
          <p className="PublicLayout__intro__footer__copyright">
            ©2020-{currentYear}&nbsp;Dunforce
          </p>
          <p className="PublicLayout__intro__footer__version">
            V{config.APP_VERSION}
          </p>
          {withLanguageSelection && (
            <FormSelect
              name="language"
              withClearButton={false}
              selectOptions={config.LANGUAGES}
              onSelect={(name, newValue) => i18next.changeLanguage(newValue || i18next.language)}
              defaultValue={i18next.language || 'en'}
            />
          )}
        </div>
      </div>
      <div className="PublicLayout__container">
        {children}
      </div>
    </div>
  );
};

export default observer(PublicLayout);
