import './index.scss';
import PublicLayout from 'layout/PublicLayout';
import { useHistory, useParams } from 'react-router-dom';
import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import type Errors from 'types/errors';
import Request from 'utils/request';
import ResetPasswordForm from './Form';

export interface RouteParams {
  token: string,
}

const ResetPassword: React.FC = () => {
  const { token } = useParams<RouteParams>();
  const { t } = useTranslation();
  const history = useHistory();

  const [error, setError] = React.useState<Errors.Request | null>(null);

  const handleSubmit = React.useCallback(async (passwordNew: string, passwordNewRepeat: string) => {
    try {
      await Request.post('account/confirm-resetting-password', {
        passwordNew,
        passwordNewRepeat,
        token,
      });
      history.push('/login', { passwordChanged: true });
    } catch (err) {
      setError(err as Errors.Request);
    }
  }, [history, token]);

  return (
    <PublicLayout>
      <div className="ResetPassword">
        <div className="ResetPassword__form">
          <h2 className="ResetPassword__title">{t('forgotten-password:title')}</h2>
          <ResetPasswordForm
            onSubmit={handleSubmit}
            error={error?.message || null}
          />
        </div>
      </div>
    </PublicLayout>
  );
};

export default observer(ResetPassword);
