import { makeAutoObservable, runInAction } from 'mobx';
import { fetchOne as fetchOrganization, fetchOccupation, fetchUserPerimeters } from 'api/organization';
import type Errors from 'types/errors';
import type Misc from 'types/misc';
import type {
  RightsRole,
  Organization,
  OrganizationSummary,
  OrganizationNotifications,
  OrganizationEavs,
  CategoryValue,
} from 'types/models';
import ResponseError from 'utils/errors';
import subscribeToMercure from 'utils/subscribeToMercure';

export type OrganizationUserInfos = {
  userRight: RightsRole,
  userIdentifier: string,
};

export type OrganizationWithRights = Organization & OrganizationUserInfos | null;

const initialOrganization: OrganizationWithRights = JSON.parse(
  window.localStorage.getItem('currentOrganization') || 'null',
);

const initialWalletCategoriesValues: CategoryValue[] = JSON.parse(
  window.localStorage.getItem('walletCategoriesValues') || 'null',
);

const initialWalletSelection: Array<CategoryValue['id']> = JSON.parse(
  window.localStorage.getItem('walletSelectedValues') || 'null',
);

class OrganizationStore {
  currentReference: string | null = initialOrganization?.reference || null;

  currentOrganization: OrganizationSummary | null = initialOrganization ? {
    id: initialOrganization.id,
    reference: initialOrganization.reference,
    logo: initialOrganization.logo,
    name: initialOrganization.corporateName,
    isInProduction: initialOrganization.isInProduction,
    type: initialOrganization.type,
    currency: initialOrganization.currency,
    legalId: initialOrganization.legalId,
    address: initialOrganization.address1,
    postalCode: initialOrganization.postalCode,
    city: initialOrganization.city,
    country: initialOrganization.country,
    sendingEmail: initialOrganization.sendingEmail,
    letterHead: initialOrganization.letterHead,
    signaturePdf: initialOrganization.signature,
    signatureEmail: initialOrganization.signatureEmail,
    signatureLetter: initialOrganization.signatureLetter,
    trackingEmail: initialOrganization.trackingEmail,
    sendViewPayer: initialOrganization.sendViewPayer,
    referent: initialOrganization.referent,
  } : null;

  type: Misc.OrganizationType | null = initialOrganization?.type || null;

  walletCategoriesValues: CategoryValue[] = initialWalletCategoriesValues ?? [];

  walletSelectedValues: Array<CategoryValue['id']> = initialWalletSelection ?? [];

  currency: Misc.CurrencyCode | null = initialOrganization?.currency || null;

  useGroupByDefault: boolean = initialOrganization?.useGroupByDefault || false;

  isEnabled: boolean = initialOrganization?.isEnabled || false;

  useDatafix: boolean = initialOrganization?.useDatafix || false;

  // eslint-disable-next-line max-len
  notifications: OrganizationNotifications | null = initialOrganization?.notifications || null;

  modePayRequest: Misc.PayRequestMode | null = initialOrganization?.modePayRequest || null;

  linesOfBusiness: Misc.LinesOfBusiness = initialOrganization?.linesOfBusiness || 'DEFAULT';

  eavs: OrganizationEavs = initialOrganization?.eavs || { client: [], payRequest: [] };

  tags: Misc.Identifier[] = initialOrganization?.tags || [];

  contactRoles: Misc.IdentifierValue[] | null = initialOrganization?.contactRoles || null;

  taskNotifyByEmail: boolean = initialOrganization?.taskNotifyByEmail || false;

  useDynamicPlanSwitch: boolean = initialOrganization?.useDynamicPlanSwitch || false;

  isBusy: boolean = false;

  error: Errors.Request | null = null;

  notificationsEventSource: EventSource | null = null;

  userRight: RightsRole | null = initialOrganization?.userRight || null;

  userIdentifier: string | null = initialOrganization?.userIdentifier || null;

  // eslint-disable-next-line max-len
  paymentAttribution: Misc.OrganizationPaymentAttribution | null = initialOrganization?.paymentAttribution || null;

  constructor() {
    makeAutoObservable(this);
    this.subscribeToNotifications();
  }

  // Nécessaire pour faire un "fix" en urgence sur les imports PDF des organisations Sage
  get isSageOrganization() {
    return this.currentOrganization?.reference === 'sage'
      || this.currentOrganization?.reference === 'sagea';
  }

  get isExternallyMonitored() {
    // A adapter avec la récupération de la propriété adaptée du back-end lorsqu'elle sera en place
    return this.currentOrganization?.reference === 'sage'
      || this.currentOrganization?.reference === 'sagea';
  }

  get walletSelection() {
    return this.walletSelectedValues.map((value) => value.toString());
  }

  async fetchData() {
    if (!this.currentReference) {
      return;
    }

    this.isBusy = true;
    this.error = null;

    try {
      const { data: organization } = await fetchOrganization(this.currentReference);
      const { data: occupation } = await fetchOccupation(organization.id);

      window.localStorage.setItem('currentOrganization', JSON.stringify({
        ...organization,
        userRight: occupation.roles[0],
        userIdentifier: occupation.identifier,
      }));

      runInAction(() => {
        this.currentOrganization = {
          id: organization.id,
          reference: organization.reference,
          logo: organization.logo,
          name: organization.corporateName,
          isInProduction: organization.isInProduction,
          type: organization.type,
          currency: organization.currency,
          legalId: organization.legalId,
          address: organization.address1,
          postalCode: organization.postalCode,
          city: organization.city,
          country: organization.country,
          sendingEmail: organization.sendingEmail,
          letterHead: organization.letterHead,
          signaturePdf: organization.signature,
          signatureEmail: organization.signatureEmail,
          signatureLetter: organization.signatureLetter,
          trackingEmail: organization.trackingEmail,
          sendViewPayer: organization.sendViewPayer,
          referent: organization.referent,
        };
        this.type = organization.type;
        this.currency = organization.currency;
        this.useGroupByDefault = organization.useGroupByDefault || false;
        this.useDatafix = organization.useDatafix || false;
        this.isEnabled = organization.isEnabled;
        this.modePayRequest = organization.modePayRequest;
        this.linesOfBusiness = organization.linesOfBusiness;
        this.contactRoles = organization.contactRoles;
        this.taskNotifyByEmail = organization.taskNotifyByEmail;
        this.eavs = organization.eavs || { client: [], payRequest: [] };
        this.tags = organization.tags || [];
        this.isBusy = false;
        this.notifications = organization.notifications || null;
        this.useDynamicPlanSwitch = organization.useDynamicPlanSwitch || false;
        this.paymentAttribution = organization.paymentAttribution;

        this.userRight = occupation.roles[0];
        this.userIdentifier = occupation.identifier;

        this.subscribeToNotifications();
      });
    } catch (error) {
      const { code, message, details } = error as Errors.Request;
      runInAction(() => {
        this.error = new ResponseError(code, message, details);
        this.isBusy = false;
      });
    }
  }

  async fetchUserWalletCategories() {
    if (!this.currentReference) {
      return;
    }

    try {
      this.isBusy = true;

      const { data: categories } = await fetchUserPerimeters(this.currentReference);

      const walletCategoriesValues = categories.map(({ values }) => values).flat();
      window.localStorage.setItem('walletCategoriesValues', JSON.stringify(walletCategoriesValues));

      runInAction(() => {
        this.walletCategoriesValues = walletCategoriesValues;
      });
    } catch (error) {
      const { code, message, details } = error as Errors.Request;
      runInAction(() => {
        this.error = new ResponseError(code, message, details);
      });
    } finally {
      runInAction(() => {
        this.isBusy = false;
      });
    }
  }

  refresh = async () => {
    await this.fetchData();
    await this.fetchUserWalletCategories();
  };

  setCurrent = async (reference: Organization['reference']) => {
    if (this.isBusy || reference === this.currentOrganization?.reference) {
      return null;
    }

    this.currentReference = reference;
    this.walletCategoriesValues = [];
    this.walletSelectedValues = [];
    window.localStorage.removeItem('walletCategoriesValues');
    window.localStorage.removeItem('walletSelectedValues');

    await this.fetchData();
    await this.fetchUserWalletCategories();
  };

  resetCurrent() {
    this.currentOrganization = null;
  }

  toggleSelectedWallet = (walletId: CategoryValue['id']) => {
    if (this.walletSelectedValues.includes(walletId)) {
      this.walletSelectedValues = this.walletSelectedValues.filter((id) => id !== walletId);
    } else {
      this.walletSelectedValues.push(walletId);
    }

    window.localStorage.setItem('walletSelectedValues', JSON.stringify(this.walletSelectedValues));
  };

  subscribeToNotifications() {
    if (!this.currentReference) {
      return;
    }

    if (this.notificationsEventSource) {
      this.notificationsEventSource.close();
      this.notificationsEventSource = null;
    }

    this.notificationsEventSource = subscribeToMercure<OrganizationNotifications>(
      `notifications/${this.currentReference}`,
      (data: OrganizationNotifications) => {
        this.notifications = data;
      },
    );
  }
}

export default new OrganizationStore();
export { OrganizationStore };
