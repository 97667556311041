import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import type Errors from 'types/errors';
import type { Currency } from 'types/models';
import organizationStore from 'stores/Organization';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormAmountCurrency from 'components/FormAmountCurrency';
import ErrorMessage from 'components/ErrorMessage';

interface Props {
  currency: Currency | undefined,
  validationErrors?: Errors.Validation | null,
  error?: Errors.Request | null,
}

const RowActionCreditNoteForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const {
    currency,
    validationErrors,
    error,
  } = props;

  const { currency: organizationCurrency } = organizationStore;

  return (
    <div className="RowActionCreditNoteForm">
      {error && <ErrorMessage error={error} />}
      <FormAmountCurrency
        defaultCurrency={currency?.code || organizationCurrency}
        amountError={validationErrors?.amount || null}
        currencyError={validationErrors?.currency || null}
        hasCurrencyDisabled
      />
      <FormGroup label={t('payment-requests:credit-note-mean')} error={validationErrors?.mean}>
        <FormControl name="mean" autoComplete="off" />
      </FormGroup>
      <FormGroup label={t('common:credit-note-reference')} error={validationErrors?.reference}>
        <FormControl name="reference" autoComplete="off" />
      </FormGroup>
    </div>
  );
};

export default observer(RowActionCreditNoteForm);
