import './index.scss';
import { useTranslation } from 'react-i18next';
import type { DetailPhases } from 'types/models';
import getI18nPhase from 'utils/getI18nPhase';
import formatIntlNumber from 'utils/formatIntlNumber';
import getColorPhase from 'utils/getColorPhase';

type Props = {
  phase: DetailPhases,
};

const ListStatsByPhaseItemPercent = ({ phase }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <li className="ListStatsByPhaseItemPercent">
      <span
        className="ListStatsByPhaseItemPercent__bullet"
        style={{ backgroundColor: getColorPhase(phase.id) }}
      />
      <span className="ListStatsByPhaseItemPercent__name">
        {t(getI18nPhase(phase.id))}
      </span>
      <span className="ListStatsByPhaseItemPercent__percent">
        {formatIntlNumber(phase.percentage)}%
      </span>
    </li>
  );
};

export default ListStatsByPhaseItemPercent;
