import type { ReactElement, ReactNode } from 'react';
import { Children, isValidElement } from 'react';
import { observer } from 'mobx-react';
import type { RightsCategory, RightsCategoryType, RightsAction, ManagerType } from 'types/models';
import userRightsStore from 'stores/UserRights';
import organizationStore from 'stores/Organization';
import authStore from 'stores/Auth';
import getCategoryFromCategoryType from './utils';

type ControllerProps = {
  children: JSX.Element | JSX.Element[] | string,
  category: RightsCategory,
  action: RightsAction,
  displayedWhenNotAllowed?: boolean,
};

type ManagerControllerProps = {
  children: JSX.Element | JSX.Element[] | string,
  categoryType: RightsCategoryType,
  action: RightsAction,
  displayedWhenNotAllowed?: boolean,
  managers?: ManagerType[] | null,
};

export const checkIsAllowed = (
  action: RightsAction,
  category: RightsCategory,
) => {
  const { userRight } = organizationStore;
  if (!userRight || category === 'UNKNOWN_UNASSIGNED') {
    return false;
  }

  const { rights } = userRightsStore;
  const { user, isLogged, isFetched } = authStore;
  const mayBeLogged = !!user || (isFetched && isLogged);

  const isSuperAdmin = !!((user && mayBeLogged && user.roles.includes('ROLE_SUPER_ADMIN')));
  const actionRights = rights?.find(
    (right) => (right.category === category),
  )?.rights;

  if (!actionRights) {
    return false;
  }

  const userRole = isSuperAdmin ? 'ROLE_SUPER_ADMIN' : userRight;

  return actionRights[action].includes(userRole);
};

type UserRightsControllerType = (props: ControllerProps) => JSX.Element | null;
type UserRightsManagerControllerType = (props: ManagerControllerProps) => JSX.Element | null;

export const UserRightsManagerController: UserRightsManagerControllerType = observer((props) => {
  const {
    children,
    action,
    categoryType,
    managers,
    displayedWhenNotAllowed = false,
  } = props;

  const { userIdentifier } = organizationStore;
  const category = getCategoryFromCategoryType(managers, userIdentifier, categoryType);
  const isAllowed = checkIsAllowed(action, category);

  return (isAllowed || displayedWhenNotAllowed) ? <>{children}</> : null;
});

export const UserRightsController: UserRightsControllerType = observer((props) => {
  const {
    children,
    action,
    category,
    displayedWhenNotAllowed = false,
  } = props;

  const isAllowed = checkIsAllowed(action, category);

  return (isAllowed || displayedWhenNotAllowed) ? <>{children}</> : null;
});

type SwitchProps = {
  children: Array<ReactElement<
  ControllerProps & ManagerControllerProps,
  UserRightsControllerType & UserRightsManagerControllerType
  >>,
};

export const UserRightsSwitch = observer(({ children }: SwitchProps): JSX.Element | null => {
  let element: ReactNode = null;
  let matched = false;

  const { userIdentifier } = organizationStore;

  Children.forEach(children, (child) => {
    if (!matched && isValidElement(child) && child.type === UserRightsController) {
      element = child;
      matched = child.props.displayedWhenNotAllowed || checkIsAllowed(
        child.props.action,
        child.props.category || getCategoryFromCategoryType(
          child.props.managers,
          userIdentifier,
          child.props.categoryType,
        ),
      );
    }
  });

  return matched ? element : null;
});
