import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import organizationStore from 'stores/Organization';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import type { ModalFormData } from 'components/ModalForm';
import ModalForm from 'components/ModalForm';
import Confirm from 'components/Confirm';
import type Errors from 'types/errors';
import ErrorMessage from 'components/ErrorMessage';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import ModalInviteForm from './Form';

interface Invitation {
  id: number,
  email: string,
  createdAt: string,
  creator: string
}

interface Props {
  onClose: () => void,
  onShowToast: (message: string, type: ToastStatus) => void,
  onInvitationDone: () => void
}

const ModalInvite: React.FC<Props> = ({ onClose, onShowToast, onInvitationDone }) => {
  const { t } = useTranslation();

  const { post, error, isLoading } = useApiRequest();

  const { currentOrganization } = organizationStore;
  const isMountedRef = useIsMountedRef();

  const [hasChanges, setHasChanges] = React.useState<boolean>(false);
  const [validationErrors, setValidationErrors] = React.useState<Errors.Validation | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = React.useState<boolean>(false);

  const closeSelf = React.useCallback(() => {
    onClose();
    setShowCancelConfirm(false);
    setHasChanges(false);
  }, [onClose]);

  const handleChange = React.useCallback(
    (formData: ModalFormData | null) => {
      setHasChanges(!!formData);
    },
    [],
  );

  const handleCancel = React.useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirm(true);
    } else {
      closeSelf();
    }
  }, [hasChanges, closeSelf]);

  const handleSubmit = React.useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData || !currentOrganization) {
        return;
      }

      const result = await post<Invitation>(
        `/collaborators/manage/${currentOrganization?.reference}/invitations`,
        { email: formData.collaboratorEmail, locale: formData.language, role: formData.role },
      );

      if (!isMountedRef.current) {
        return;
      }

      if (!result || result?.errors) {
        setValidationErrors(result?.errors || null);
        return;
      }

      onShowToast(t('collaborators:toast.success.collaborator-add'), 'success');
      onInvitationDone();
      onClose();
    },
    [currentOrganization, post, isMountedRef, onShowToast, t, onClose, onInvitationDone],
  );

  return (
    <ModalForm
      title={t('collaborators:invite')}
      className="ModalInviteForm"
      isOpened
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
    >
      {error && <ErrorMessage error={error} />}
      <ModalInviteForm
        errors={validationErrors}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={() => { closeSelf(); }}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(ModalInvite);
