import { runInAction, makeAutoObservable } from 'mobx';
import type Errors from 'types/errors';
import type { Currency } from 'types/models';
import ResponseError from 'utils/errors';
import Request from 'utils/request';

class CurrenciesStore {
  currencies: Currency[] | null = null;

  isFetched: boolean = false;

  isBusy: boolean = false;

  error: Errors.Request | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get currenciesOptions() {
    return this.currencies?.map((currencyData) => ({
      label: `${currencyData.code} − ${currencyData.symbol}`,
      value: currencyData.code,
    }));
  }

  getAll = async () => {
    if (this.isBusy) {
      return;
    }

    this.isBusy = true;
    this.error = null;

    try {
      const { data } = await Request.get<Currency[]>('currencies/');
      data.reverse();

      runInAction(() => {
        this.currencies = data;
        this.isFetched = true;
        this.isBusy = false;
      });
    } catch (error) {
      const { code, message, details } = error as Errors.Request;
      runInAction(() => {
        this.error = new ResponseError(code, message, details);
        this.isBusy = false;
      });
    }
  };
}

export default new CurrenciesStore();
export { CurrenciesStore };
