import type Misc from 'types/misc';

const getI18nStatusImport = (status: Misc.StatusImport): string => {
  const i18nStatusImport = {
    FILE_CREATED: 'imports:file-status.file-created',
    FILE_PROCESSING: 'imports:file-status.file-processing',
    FILE_PROCESSED: 'imports:file-status.file-processed',
    FILE_ERROR: 'imports:file-status.file-error',
    FILE_SKIPPED: 'imports:file-status.file-skipped',
  };

  return i18nStatusImport[status];
};

export default getI18nStatusImport;
