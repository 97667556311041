import './index.scss';
import { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import getUploadImageUrl from 'utils/getUploadImageUrl';
import organizationStore from 'stores/Organization';
import Icon from 'components/Icon';

import type { OrganizationHeaderSummary } from 'types/models';

type Props = {
  organization: OrganizationHeaderSummary,
};

const NoOrganizationSelectedItem = ({ organization }: Props): JSX.Element => {
  const { reference, logo, name, isInProduction } = organization;
  const { setCurrent } = organizationStore;
  const { t } = useTranslation();

  const handleSelectOrganisation = useCallback(async () => {
    await setCurrent(reference);
  }, [reference, setCurrent]);

  const logoUrl = useMemo(() => !!logo ? getUploadImageUrl(logo) : null, [logo]);

  const classNames = classnames('NoOrganizationSelectedItem', {
    'NoOrganizationSelectedItem--no-logo': !logoUrl,
  });

  return (
    <li
      className={classNames}
      onClick={handleSelectOrganisation}
      role="button"
    >
      <span className="NoOrganizationSelectedItem__logo">
        {!!logoUrl && (
          <img className="NoOrganizationSelectedItem__logo" src={logoUrl} alt="" />
        )}
      </span>
      <span className="NoOrganizationSelectedItem__name">
        {name}
        {!isInProduction && (
          <span className="NoOrganizationSelectedItem__demo">
            {t('common:demo-organization')}
          </span>
        )}
      </span>
      <span className="NoOrganizationSelectedItem__button">
        <Icon name="chevron-right" />
      </span>
    </li>
  );
};

export default NoOrganizationSelectedItem;
