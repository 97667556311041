import './index.scss';
import { useContext, useMemo } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import getI18nReportType from 'utils/getI18nReportType';
import getI18nReportStatus from 'utils/getI18nReportStatus';
import { ReportContext } from '../..';
import SecondaryInfos from './SecondaryInfos';

const ImportsReportInfos = (): JSX.Element | null => {
  const { linesOfBusiness, isSageOrganization } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);
  const report = useContext(ReportContext);

  const className = classnames('ImportsReportInfos', {
    'ImportsReportInfos--warning': (
      report && report.nbErrorsByLine > 0 && report.nbErrorsByFile === 0
      // TODO: Logique Sage a retirer quand l'API sera corrigé
      && (!isSageOrganization || report.format !== 'pdf')
    ),
    'ImportsReportInfos--danger': (
      report && report.nbErrorsByFile > 0
      // TODO: Logique Sage a retirer quand l'API sera corrigé
      && (!isSageOrganization || report.format !== 'pdf')
    ),
  });

  const status = useMemo(() => {
    if (!report) {
      return null;
    }

    // TODO: Logique Sage a retirer quand l'API sera corrigé
    if (isSageOrganization && report.format === 'pdf') {
      return t(getI18nReportStatus('FINALIZED'));
    }

    if (report.nbErrorsByFile !== 0) {
      return t(getI18nReportStatus('ERROR'));
    }

    return t(getI18nReportStatus(report.status));
  }, [isSageOrganization, report, t]);

  if (!report) {
    return null;
  }

  return (
    <div className={className}>
      <div className="ImportsReportInfos__section">
        <h4 className="ImportsReportInfos__section__header">
          {t('reports:type')}
        </h4>
        <p className="ImportsReportInfos__section__title">
          {ct(getI18nReportType(report.type))}
        </p>
      </div>
      <div className="ImportsReportInfos__section">
        <h4 className="ImportsReportInfos__section__header">
          {t('common:status')}
        </h4>
        <p className="ImportsReportInfos__section__status">
          {status}
        </p>
      </div>
      <SecondaryInfos report={report} />
    </div>
  );
};

export default observer(ImportsReportInfos);
