import type Misc from 'types/misc';
import type { Occupation, Organization, OrganizationStats, Category, StepEvent } from 'types/models';
import Request from 'utils/request';

export type FetchStatsParams = {
  organization: string | undefined,
  filtering: Misc.Filter[],
};

export type FetchOrganizationHistoryParams = {
  organization: string | undefined,
  fetchOptions: Misc.PaginatedFetchArgs<StepEvent>,
};

export type FetchOrganizationLastHistoryParams = {
  organization: string | undefined,
  filtering: Misc.Filter[],
};

export type FetchOrganizationCountHistoryParams = {
  organization: string | undefined,
};

const fetchOne = async (reference: Organization['reference']) => {
  if (!reference) {
    throw new Error('FetchOne: Missing organization reference.');
  }

  const result = await Request.get<Organization>(`organization/${reference}`);
  return result;
};

const fetchOccupation = async (id: Organization['id']) => {
  if (!id) {
    throw new Error('FetchOccupation: Missing organization id.');
  }

  const result = await Request.get<Occupation>(`occupation/${id}`);
  return result;
};

const fetchUserPerimeters = async (reference: Organization['reference']) => {
  if (!reference) {
    throw new Error('fetchUserPerimeters: Missing reference.');
  }

  const result = await Request.get<Category[]>(`organization/${reference}/perimeters`);
  return result;
};

const fetchStats = async ({ organization, filtering }: FetchStatsParams) => {
  if (!organization) {
    throw new Error('FetchStats: Missing organization.');
  }

  const queryData = new URLSearchParams();

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }) => {
      queryData.append(
        `filters[${name}]`,
        (Array.isArray(value) ? value.join(',') : value) || '',
      );
    });
  }

  const result = await Request.get<OrganizationStats>(
    `organization/${organization}/stats?${queryData.toString()}`,
  );
  return result;
};

const fetchHistory = async ({ organization, fetchOptions }: FetchOrganizationHistoryParams) => {
  if (!organization) {
    throw new Error('FetchHistory: Missing organization.');
  }

  const { pageIndex, pageSize } = fetchOptions;
  const queryData = new URLSearchParams();
  queryData.append('recordsPerPage', pageSize.toString());
  queryData.append('page', (pageIndex + 1).toString());

  const result = await Request.get<StepEvent[]>(
    `organization/${organization}/events?${queryData.toString()}`,
  );
  return result;
};

const lastHistory = async ({ organization, filtering }: FetchOrganizationLastHistoryParams) => {
  if (!organization) {
    throw new Error('FetchHistory: Missing organization.');
  }

  const queryData = new URLSearchParams();

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }) => {
      queryData.append(
        `filters[${name}]`,
        (Array.isArray(value) ? value.join(',') : value) || '',
      );
    });
  }

  const result = await Request.get<StepEvent[]>(
    `organization/${organization}/last-events`,
  );
  return result;
};

const countHistory = async ({ organization }: FetchOrganizationCountHistoryParams) => {
  if (!organization) {
    throw new Error('FetchHistory: Missing organization.');
  }

  const result = await Request.get<number>(
    `organization/${organization}/last-events/count`,
  );
  return result;
};

export {
  fetchOne,
  fetchOccupation,
  fetchStats,
  fetchUserPerimeters,
  fetchHistory,
  lastHistory,
  countHistory,
};
