import type Misc from 'types/misc';

const getI18nOperatorComparison = (operator: Misc.ComparisonOperator): string => {
  const i18nOperators = [
    { name: 'IS NULL', translation: 'selectors:operators.is-null' },
    { name: 'IS NOT NULL', translation: 'selectors:operators.is-not-null' },
    { name: '===', translation: 'selectors:operators.strictly-equals' },
    { name: '==', translation: 'selectors:operators.equals' },
    { name: '<', translation: 'selectors:operators.inferior-to' },
    { name: '<=', translation: 'selectors:operators.strictly-inferior-to' },
    { name: '>', translation: 'selectors:operators.superior-to' },
    { name: '>=', translation: 'selectors:operators.strictly-superior-to' },
    { name: 'IN', translation: 'selectors:operators.in' },
    { name: 'NOT IN', translation: 'selectors:operators.not-in' },
    { name: '!=', translation: 'selectors:operators.different-from' },
    { name: '<>', translation: 'selectors:operators.different-from' },
  ];

  return i18nOperators.find(({ name }) => name === operator)?.translation || 'common:unknown';
};

export default getI18nOperatorComparison;
