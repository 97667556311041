import './index.scss';
import type { TFunction } from 'i18next';
import type { Column } from 'react-table';
import type { EavConstructor } from 'types/models';
import getEavTypeLabelFromFormat from 'utils/getEavTypeLabelFromFormat';
import AttributesRowActions from './RowActions';

const AttributesColumns = (
  t: TFunction,
  onActionError: (message: string) => void,
  onActionDone: (message: string) => void,
  type: 'CLIENT' | 'PAY_REQUEST',
): Column<EavConstructor>[] => [
  {
    Header: t<string>('attributes:list-headings.attribute'),
    accessor: 'identifier',
    disableSortBy: true,
    Cell: ({ value, row }) => (
      <div>
        <h4 className="AttributesColumn__title">{row.original.label}</h4>
        <p className="AttributesColumn__values">{value}</p>
      </div>
    ),
  },
  {
    Header: t<string>('attributes:list-headings.required'),
    accessor: 'required',
    disableSortBy: true,
    Cell: ({ value }) => (
      <p className="AttributesColumn__title">
        {value ? t<string>('common:yes') : t<string>('common:no')}
      </p>
    ),
  },
  {
    Header: t<string>('attributes:list-headings.format'),
    accessor: 'possibleValues',
    disableSortBy: true,
    Cell: ({ value, row }) => (
      <div>
        <p className="AttributesColumn__values-title">
          {!row.original.format && value && value.length > 0 && (
            t<string>('attributes:list-headings.values-amongst')
          )}
          {row.original.format && (!value || !value?.length) && (
            row.original.format,
            t<string>(getEavTypeLabelFromFormat(new RegExp(row.original.format)))
          )}
        </p>
        <p className="AttributesColumn__values">{value?.map((elem) => elem.value).join(', ')}</p>
      </div>
    ),
  },
  {
    Header: t<string>('attributes:list-headings.default'),
    accessor: 'defaultValue',
    disableSortBy: true,
    Cell: ({ value }) => (value || t('common:none_f')),
  },
  {
    Header: t<string>('common:commands'),
    accessor: 'format',
    disableSortBy: true,
    Cell: ({ row }) => (
      <AttributesRowActions
        attributesData={row.original}
        type={type}
        id={row.original.identifier}
        onActionDone={onActionDone}
        onActionError={onActionError}
      />
    ),
  },
];

export default AttributesColumns;
