import type Misc from 'types/misc';

const getI18nReportLineCreatedUpdated = (type: Misc.ReportItemType, status: 'created' | 'updated'): string => {
  const i18nLineCreated = {
    PAY_REQUEST_CREATE: 'reports:count-created.pay-request',
    CLIENT: 'reports:count-created.client',
    CONTACT: 'reports:count-created.contact',
    PAYMENT: 'reports:count-created.payment',
    PAYMENT_CREATE: 'reports:count-created.payment',
    CLIENT_CONTACT: 'reports:count-created.contact',
    PAY_REQUEST_UPDATE: 'reports:count-created.pay-request',
    PAY_REQUEST_CREATE_UPDATE: 'reports:count-created.pay-request',
    CLIENT_COMPANY_CREATE: 'reports:count-created.client',
    CLIENT_COMPANY_UPDATE: 'reports:count-created.client',
    CLIENT_CONTACT_COMPANY_CREATE: 'reports:count-created.contact',
    CLIENT_CONTACT_COMPANY_UPDATE: 'reports:count-created.contact',
    CLIENT_INDIVIDUAL_CREATE: 'reports:count-created.client',
    CLIENT_INDIVIDUAL_UPDATE: 'reports:count-created.client',
    CONTACT_CREATE: 'reports:count-created.contact',
    CONTACT_UPDATE: 'reports:count-created.contact',
    UNKNOWN: 'reports:count-created.unknown',
  };

  const i18nLineUpdated = {
    PAY_REQUEST_CREATE: 'reports:count-updated.pay-request',
    CLIENT: 'reports:count-updated.client',
    CONTACT: 'reports:count-updated.contact',
    CLIENT_CONTACT: 'reports:count-updated.contact',
    PAYMENT: 'reports:count-updated.payment',
    PAYMENT_CREATE: 'reports:count-updated.payment',
    PAY_REQUEST_UPDATE: 'reports:count-updated.pay-request',
    PAY_REQUEST_CREATE_UPDATE: 'reports:count-updated.pay-request',
    CLIENT_COMPANY_CREATE: 'reports:count-updated.client',
    CLIENT_COMPANY_UPDATE: 'reports:count-updated.client',
    CLIENT_CONTACT_COMPANY_CREATE: 'reports:count-updated.contact',
    CLIENT_CONTACT_COMPANY_UPDATE: 'reports:count-updated.contact',
    CLIENT_INDIVIDUAL_CREATE: 'reports:count-updated.client',
    CLIENT_INDIVIDUAL_UPDATE: 'reports:count-updated.client',
    CONTACT_CREATE: 'reports:count-updated.contact',
    CONTACT_UPDATE: 'reports:count-updated.contact',
    UNKNOWN: 'reports:count-updated.unknown',
  };

  return status === 'created' ? i18nLineCreated[type] : i18nLineUpdated[type];
};

export default getI18nReportLineCreatedUpdated;
