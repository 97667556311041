import './index.scss';
import { useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import type Errors from 'types/errors';
import type { EavConstructor, Eav } from 'types/models';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormSelect from 'components/FormSelect';

type Props = {
  eav: EavConstructor,
  defaultValue?: Eav,
  error?: Errors.ValidationItem | null,
};

const FormEavItem = (props: Props): JSX.Element => {
  const { eav, defaultValue, error } = props;
  const { t } = useTranslation();

  const classNames = classnames('FormEavItem', {
    'FormEavItem--mandatory': eav.required,
  });

  const hasPossibleValues = useMemo(() => (
    eav.possibleValues && eav.possibleValues.length > 0
  ), [eav]);

  const realDefaultValue = useMemo(() => (
    defaultValue?.value ?? eav.defaultValue
  ), [defaultValue, eav]);

  return (
    <FormGroup className={classNames} label={eav.label} mandatory={eav.required} error={error}>
      {eav.format === null && eav.possibleValues && hasPossibleValues && (
        <FormSelect
          name={`eavs[${eav.identifier}]`}
          defaultValue={realDefaultValue}
          placeholder={t('common:please-choose')}
          selectOptions={eav.possibleValues.map(
            ({ value }) => ({ label: value, value }),
          )}
          isInvalid={!!error}
        />
      )}
      {(eav.format !== null || !hasPossibleValues) && (
        <FormControl
          type="text"
          name={`eavs[${eav.identifier}]`}
          autoComplete="off"
          defaultValue={realDefaultValue}
          isInvalid={!!error}
        />
      )}
    </FormGroup>
  );
};

export default FormEavItem;
