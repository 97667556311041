import './index.scss';
import React from 'react';
import type { DefaultTFuncReturn } from 'i18next';
import type Errors from 'types/errors';
import ModalForm from 'components/ModalForm';
import FormControl from 'components/FormControl';
import FormDatePicker from 'components/FormDatePicker';
import ErrorMessage from 'components/ErrorMessage';

type Props = {
  title: string,
  help?: DefaultTFuncReturn,
  type?: 'text' | 'number' | 'textarea' | 'date',
  name: string,
  defaultValue?: string | null,
  isShow: boolean,
  isLoading?: boolean,
  error: Errors.Request | null,
  minDate?: Date,
  onSubmit(data: { [field: string]: string }): void,
  onCancel(): void,
  onClose?(): void,
};

const InputModal = (props: Props): JSX.Element => {
  const {
    title,
    help,
    type = 'text',
    name,
    defaultValue,
    isShow,
    isLoading,
    error,
    minDate,
    onSubmit,
    onCancel,
    onClose,
  } = props;

  const [inputValue, setInputValue] = React.useState<string>(defaultValue || '');

  const handleSubmit = React.useCallback(
    () => {
      onSubmit({ [name]: inputValue });
      if (onClose) {
        onClose();
      }
    },
    [onSubmit, name, inputValue, onClose],
  );

  const handleChangeValue = React.useCallback(
    (newValue: string) => {
      setInputValue(newValue);
    },
    [],
  );

  const handleChangeDate = React.useCallback(
    (noopName: string, value: string) => {
      setInputValue(value);
    },
    [],
  );

  return (
    <ModalForm
      className="InputModal"
      isOpened={isShow}
      onSave={handleSubmit}
      onCancel={onCancel}
      title={title}
      isLoading={isLoading}
    >
      {help && (
        // eslint-disable-next-line react/no-danger
        <div className="InputModal__help" dangerouslySetInnerHTML={{ __html: help }} />
      )}
      {type !== 'date' && (
        <FormControl
          type={type}
          name={name}
          value={inputValue}
          onChange={handleChangeValue}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => { e.stopPropagation(); }}
          autoComplete="off"
        />
      )}
      {type === 'date' && (
        <FormDatePicker
          name={name}
          onChange={handleChangeDate}
          defaultValue={defaultValue || null}
          minDate={minDate}
        />
      )}
      {error && <ErrorMessage error={error} />}
    </ModalForm>
  );
};

export default InputModal;
