import queryString from 'query-string';
import type Misc from 'types/misc';
import { ReportLine } from 'types/models';
import Request from 'utils/request';

export type FetchAllReportsParams = {
  organization: string | undefined,
  dateFilter: string | null,
  limit: number,
};

export type FetchAllLinesFromFileParams = {
  fileId: number,
  fetchOptions: Misc.PaginatedFetchArgs<ReportLine>,
  status?: Misc.ReportLineStatus,
};

type PutResponseData = { success: boolean, code: number, data: number };

const fetchAllReports = async ({
  organization,
  dateFilter,
  limit,
}: FetchAllReportsParams) => {
  if (!organization) {
    throw new Error('FetchAllReportsImports: Missing organization.');
  }

  const filters: { date?: string, limit?: number } = {};

  if (dateFilter) {
    filters.date = dateFilter;
  }

  if (limit) {
    filters.limit = limit;
  }

  const query = queryString.stringify(filters);

  const result = await Request.get<Misc.ReportsDataObject>(
    `import/report/${organization}?${query}`,
  );
  return result;
};

const fetchAllLinesFromFile = async ({ fileId, fetchOptions, status }: FetchAllLinesFromFileParams) => {
  const { pageIndex, pageSize } = fetchOptions;
  const params = {
    recordsPerPage: pageSize,
    page: pageIndex,
    status: status ?? undefined,
  };

  const query = queryString.stringify(params);

  const result = await Request.get<Misc.Listing<ReportLine>>(
    `import/lines/${fileId}?${query}`,
  );
  return result;
};

const cancelImport = async (importLogId: number) => {
  const result = await Request.put<undefined, PutResponseData>(
    `import/manage/import-to-error/${importLogId}`,
  );
  return result;
};

export { fetchAllReports, fetchAllLinesFromFile, cancelImport };
