import type { Note } from 'types/models';
import Request from 'utils/request';

export type FetchAllForPayRequestParams = {
  organization: string | undefined,
  paymentId: string,
};

export type FetchAllForCustomerParams = {
  organization: string | undefined,
  customerId: number,
};

const fetchAllForPayRequest = async ({ organization, paymentId }: FetchAllForPayRequestParams) => {
  if (!organization) {
    throw new Error('FetchAllForPayRequestNotes: Missing organization.');
  }

  const result = await Request.get<Note[]>(
    `pay-requests/${organization}/${paymentId}/notes`,
  );
  return result;
};

const fetchAllForCustomer = async ({ organization, customerId }: FetchAllForCustomerParams) => {
  if (!organization) {
    throw new Error('FetchAllForCustomerNotes: Missing organization.');
  }

  const result = await Request.get<Note[]>(
    `client/${organization}/${customerId}/notes`,
  );
  return result;
};

export { fetchAllForPayRequest, fetchAllForCustomer };
