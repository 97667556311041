import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import equal from 'deep-equal';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import type Errors from 'types/errors';
import type Misc from 'types/misc';
import type { ContactPost, Client, Contact } from 'types/models';
import { fetchOne as fetchOneCustomer, FetchOneParams as FetchOneCustomerParams } from 'api/customers';
import { fetchOne as fetchOneContact, FetchOneParams as FetchOneContactParams } from 'api/contacts';
import useFetch from 'hooks/useFetch';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import organizationStore from 'stores/Organization';
import recomposeName from 'utils/recomposeName';
import ErrorMessage from 'components/ErrorMessage';
import Confirm from 'components/Confirm';
import ModalForm from 'components/ModalForm';
import type { ModalFormData } from 'components/ModalForm';
import ContactForm from './Form';

type Props = {
  editType: 'new-contact' | 'new' | 'edit' | undefined,
  id?: number | null,
  defaultCustomerId?: number,
  onDone(title: string, message: string): void,
  onClose(): void,
  finallyRedirectTo?: string,
  customerId?: number,
  defaultRole?: string,
};

const ContactEditModal = (props: Props): JSX.Element => {
  const {
    editType,
    id,
    defaultCustomerId,
    onDone,
    onClose,
    finallyRedirectTo,
    defaultRole,
    customerId,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  const { currentOrganization, contactRoles } = organizationStore;
  const cache = useQueryClient();

  const isNew = useMemo<boolean>(
    () => ((!!editType && (editType === 'new-contact' || editType === 'new')) || customerId !== undefined),
    [editType, customerId],
  );

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Errors.Validation | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);

  const initialData = useRef<ContactPost | null>(null);

  const {
    isFetching: customerIsFetching,
    data: customerData,
    error: customerError,
  } = useFetch<FetchOneCustomerParams, Client>(
    {
      cacheKey: 'customer',
      organization: currentOrganization?.reference,
      id: customerId,
    },
    fetchOneCustomer,
    { refetchOnWindowFocus: false, enabled: !!customerId },
  );

  const {
    isFetching: contactIsFetching,
    data: contactData,
    error: contactError,
  } = useFetch<FetchOneContactParams, Contact>(
    {
      cacheKey: 'contact',
      organization: currentOrganization?.reference,
      id,
    },
    fetchOneContact,
    { refetchOnWindowFocus: false, enabled: !!id },
  );

  const mapFormData = useCallback(
    (rawData: ModalFormData): ContactPost => ({
      contactRole: rawData.contactRole as string,
      firstName: rawData.firstName as string,
      lastName: rawData.lastName as string,
      civility: rawData.civility as string,
      address1: rawData.address1 as string,
      address2: rawData.address2 as string,
      address3: rawData.address3 as string,
      postalCode: rawData.postalCode as string,
      city: rawData.city as string,
      country: rawData.country as Misc.Country,
      email: rawData.email as string,
      phone: rawData.phone as string,
      cellPhone: rawData.cellPhone as string,
      function: rawData.function as string,
      department: rawData.department as string,
      client: rawData.client as string | undefined,
    }),
    [],
  );

  const handleInit = useCallback((formData: ModalFormData | null) => {
    initialData.current = formData ? mapFormData(formData) : null;
  }, [initialData, mapFormData]);

  const closeSelf = useCallback(() => {
    setShowCancelConfirm(false);
    setHasChanges(false);
    setValidationErrors(null);
    onClose();
    if (finallyRedirectTo) {
      setTimeout(() => {
        history.replace(finallyRedirectTo);
      }, 300);
    }
  }, [onClose, history, finallyRedirectTo]);

  const {
    post,
    put,
    error: saveError,
  } = useApiRequest();

  useEffect(() => {
    const { code } = saveError || { code: 0 };
    if (code === 404 || code === 2) {
      closeSelf();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveError]);

  const handleChange = useCallback(
    (formData: ModalFormData | null) => {
      setHasChanges(
        !!formData && !equal(initialData.current, mapFormData(formData)),
      );
    },
    [initialData, mapFormData],
  );

  const handleCancel = useCallback(
    () => {
      if (hasChanges) {
        setShowCancelConfirm(true);
      } else {
        closeSelf();
      }
    },
    [hasChanges, closeSelf],
  );

  const handleEmailError = useCallback(() => {
    setValidationErrors({
      email: { code: 4, message: t('email-not-valid') },
    });
  }, [t]);

  const handleSubmit = useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData || !currentOrganization || isSaving) {
        return;
      }

      setValidationErrors(null);
      setIsSaving(true);

      const data = mapFormData(formData);

      let result;
      if (isNew) {
        result = await post<Misc.IdName>('contact', { ...data, client: defaultCustomerId });
      } else if (contactData) {
        result = await put<Misc.IdName>(`contact/manage/${contactData.id}`, data);
      }

      if (!isMountedRef.current) {
        return;
      }

      setIsSaving(false);

      if (result?.errors) {
        setValidationErrors(result.errors);
        return;
      }

      if (customerId) {
        cache.invalidateQueries({
          queryKey: ['contactsForCustomer', currentOrganization.reference, customerId],
        });
      }

      if (result?.id) {
        if (isNew) {
          onDone(
            t('contacts:actions.toast.created'),
            t('contacts:actions.toast.created-name', { name: recomposeName(data) }),
          );
        } else {
          onDone(
            t('contacts:actions.toast.modified'),
            t('contacts:actions.toast.modified-name', { name: recomposeName(data) }),
          );
        }

        closeSelf();
      }
    },
    [
      currentOrganization,
      isNew,
      isSaving,
      post,
      put,
      mapFormData,
      contactData,
      isMountedRef,
      onDone,
      t,
      cache,
      customerId,
      closeSelf,
      defaultCustomerId,
    ],
  );

  return (
    <ModalForm
      isOpened
      className="ContactEdit"
      title={isNew ? t('common:new-contact') : t('common:edit-contact')}
      onInit={handleInit}
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onEmailError={handleEmailError}
      onCancel={handleCancel}
      isFetched={!customerIsFetching && (!contactIsFetching || editType !== 'edit')}
      isLoading={customerIsFetching || contactIsFetching || isSaving}
    >
      {customerError && editType !== 'edit' && <ErrorMessage error={customerError} />}
      {editType === 'edit' && contactError && <ErrorMessage error={contactError} />}
      {saveError && <ErrorMessage error={saveError} />}
      <ContactForm
        defaultData={editType === 'edit' ? contactData : undefined}
        isCreate={isNew}
        allRoles={contactRoles}
        errors={validationErrors}
        defaultCustomerId={defaultCustomerId}
        defaultCustomerData={customerData}
        customerId={customerId}
        defaultRole={defaultRole}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t<string>('common:close-form')}
        cancelButtonText={t<string>('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={() => { closeSelf(); }}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(ContactEditModal);
