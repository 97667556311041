import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import type { Organization } from 'types/models';
import useApiRequest from 'hooks/useApiRequest';
import organizationStore from 'stores/Organization';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import FormSwitch from 'components/FormSwitch';
import useIsMountedRef from 'hooks/useIsMountedRef';

interface Props {
  onShowToast: (message: string, status: ToastStatus) => void,
}

const DailyReportSwitch: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();

  const { put } = useApiRequest();

  const { currentOrganization, taskNotifyByEmail } = organizationStore;

  const [dailyReportState, setDailyReportState] = React.useState<boolean>(false);
  const { onShowToast } = props;

  React.useEffect(() => {
    setDailyReportState(taskNotifyByEmail);
  }, [currentOrganization, taskNotifyByEmail]);

  const updateDailyReport = React.useCallback(async (value: boolean) => {
    setDailyReportState(value);
    const result = await put<Organization>(
      `/organization/manage/${currentOrganization?.reference}`,
      { taskNotifyByEmail: value },
    );

    if (!isMountedRef) {
      return;
    }
    if (!result) {
      onShowToast(t('collaborators:toast.error.daily-report-switch'), 'error');
      setDailyReportState(!value);
      return;
    }
    organizationStore.refresh();
    onShowToast(t('collaborators:toast.success.daily-report-switch'), 'success');
  }, [onShowToast, t, currentOrganization, put, isMountedRef]);

  return (
    <div className="DailyReportSwitch">
      <FormSwitch
        name="dailyReportSwitch"
        value={dailyReportState}
        onChange={(value) => updateDailyReport(value)}
        label={t('collaborators:report-switch-indication')}
      />
    </div>
  );
};

export default observer(DailyReportSwitch);
