import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type Errors from 'types/errors';
import type Misc from 'types/misc';
import type { EavConstructor } from 'types/models';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import type { FormRadioValue } from 'components/FormRadios/Item';
import FormRadios from 'components/FormRadios';
import FormCheck from 'components/FormCheck';
import type { ChosableFormat } from 'utils/isEavValueValid';
import FormatForm from './FormatForm';
import ValueForm from './ValueForm';

type Props = {
  errors?: Errors.Validation | null,
  defaultData?: EavConstructor,
};

type ChoiceMode = 'format' | 'value' | undefined;

const AttributesForm = ({ errors, defaultData }: Props): JSX.Element => {
  const { t } = useTranslation();

  const [mode, setMode] = useState<ChoiceMode>(
    // eslint-disable-next-line no-nested-ternary
    defaultData ? (defaultData.possibleValues?.length ? 'value' : 'format') : undefined,
  );
  const [format, setFormat] = useState<ChosableFormat>(
    defaultData?.format ? defaultData?.format as Misc.EavFormats : 'none',
  );
  const [isMandatory, setIsMandatory] = useState<boolean>(defaultData?.required || false);

  const handleChange = useCallback((newValue: FormRadioValue) => {
    setMode(newValue as ChoiceMode);
  }, []);

  const handleSelectFormat = useCallback((_name: string, newValue: string | null) => {
    setFormat(newValue as ChosableFormat);
  }, []);

  return (
    <div className="AddEditAttributesForm">
      <FormFieldset>
        <FormGroup label={t('common:identifier')} mandatory error={errors?.name}>
          <FormControl
            type="text"
            name="identifier"
            autoComplete="off"
            defaultValue={defaultData?.identifier}
            isInvalid={!!errors?.identifier}
          />
        </FormGroup>
        <FormGroup label={t('common:name')} mandatory error={errors?.label}>
          <FormControl
            type="text"
            name="label"
            autoComplete="off"
            defaultValue={defaultData?.label}
            isInvalid={!!errors?.label}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('attributes:mode')} mandatory>
          <FormRadios
            name="mode"
            value={mode || ''}
            onChange={handleChange}
            items={[
              { value: 'format', label: 'Format' },
              { value: 'value', label: t('common:possible-values') },
            ]}
          />
        </FormGroup>
        <FormGroup label={t('attributes:list-headings.required')}>
          <FormCheck name="mandatory" defaultChecked={defaultData?.required} onChange={setIsMandatory} />
        </FormGroup>
      </FormFieldset>
      {mode === 'format' && (
        <FormatForm
          errors={errors}
          defaultData={defaultData}
          onSelectFormat={handleSelectFormat}
          format={format}
          isMandatory={isMandatory}
        />
      )}
      {mode === 'value' && (
        <ValueForm
          errors={errors}
          defaultData={defaultData}
        />
      )}
    </div>
  );
};

export default AttributesForm;
