import type Misc from 'types/misc';
import type { AnalysisActivityChartData } from 'types/models';
import getYearMonthISO from 'utils/getYearMonthIso';

// TODO: Voir si on ne peut pas mieux formater la réponse API pour ne pas avoir à faire ca
// Sert à ajouter des data vides
const fillBlanksChartData = (
  data: Misc.FormattedDataType[],
  getLabel: (date: string) => string,
) => {
  const today = new Date();
  const periodBeginning = new Date(today.getFullYear(), today.getMonth() - 5, today.getDate());
  const formattedData: Misc.FormattedDataType[] = [];

  while (periodBeginning < today) {
    ['EMAIL', 'SMS', 'LETTER', 'REGISTERED_LETTER'].forEach((channel) => {
      const dateFormat = getYearMonthISO(periodBeginning);
      const foundElement = data.find(({ date, type }) => (channel === type && date === dateFormat));
      if (!foundElement) {
        formattedData.push({
          date: dateFormat,
          type: channel as Misc.Channel,
          label: getLabel(dateFormat),
          withValidation: null,
          withoutValidation: null,
        });
      } else {
        formattedData.push(foundElement);
      }
    });
    periodBeginning.setMonth(periodBeginning.getMonth() + 1);
  }
  return formattedData;
};

// TODO: Pareil que plus haut...
const getChartData = (
  data: AnalysisActivityChartData[],
  getLabel: (date: string) => string,
) => {
  let formattedData: Misc.FormattedDataType[] = [];
  data.forEach((dataElement) => {
    const {
      date: originalDate,
      channel: originalChannel,
      nbSent,
      withoutValidation: originalWithoutValidation,
    } = dataElement;
    if (!formattedData.find(({ date, type }) => (
      date === originalDate && type === originalChannel
    ))) {
      formattedData.push({
        label: getLabel(originalDate),
        date: originalDate,
        withValidation: !originalWithoutValidation ? nbSent : null,
        withoutValidation: originalWithoutValidation ? nbSent : null,
        type: originalChannel.toUpperCase() as Misc.Channel,
      });
    } else {
      formattedData = formattedData.map((formattedElem) => {
        const {
          date,
          type,
          withoutValidation,
          withValidation,
        } = formattedElem;
        return (date === originalDate && type === originalChannel) ? ({
          ...formattedElem,
          withValidation: !originalWithoutValidation ? nbSent : withValidation,
          withoutValidation: originalWithoutValidation ? nbSent : withoutValidation,
        }) : formattedElem;
      });
    }
  });
  return fillBlanksChartData(formattedData, getLabel);
};

export default getChartData;
