import './index.scss';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BlockSection from 'components/BlockSection';
import Collapse from 'components/Collapse';
import type Misc from 'types/misc';
import type { PayRequest, HistoryStep as HistoryStepType } from 'types/models';
import getDaysDiff from 'utils/getDaysDiff';
import HistoryStep from 'components/HistoryStep';
import Badge from 'components/Badge';
import SuggestCreateAction from './SuggestCreateAction';

interface Props {
  type: 'history' | 'waiting' | 'future',
  payRequest: PayRequest,
  steps: Misc.Listing<HistoryStepType> | null,
  shouldSuggestCreateAction?: boolean,
  onActionDone?: (message: string) => void,
  onActionError?: (message: string) => void,
  isLoading?: boolean,
  isDefaultOpen?: boolean,
  link?: string,
}

const PaymentRequestActionsCollapse = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    type,
    onActionDone = () => {},
    steps,
    onActionError = () => {},
    payRequest,
    isLoading = false,
    isDefaultOpen = false,
    shouldSuggestCreateAction,
    link,
  } = props;

  const sortStepDate = useCallback(
    (stepA: HistoryStepType, stepB: HistoryStepType) => (
      new Date(stepB.at.at).getTime() - new Date(stepA.at.at).getTime()
    ),
    [],
  );

  return (
    <div className="PaymentRequestActionsCollapse">
      <Collapse
        isDefaultOpen={isDefaultOpen}
        updateOpenWhenDefaultIsUpdated
        header={(
          <BlockSection
            title={t(`payment-requests:steps-group-${type}`)}
            count={steps ? steps.pagination.totalRecords : undefined}
            isLoading={isLoading}
          >
            {shouldSuggestCreateAction && (
              <SuggestCreateAction
                onActionDone={onActionDone}
                onActionError={onActionError}
                payRequest={payRequest}
              />
            )}
            {link && <Link to={link}>{t('payment-requests:go-to-list')}</Link>}
          </BlockSection>
        )}
      >
        <ul className="PaymentRequestActionsCollapse__list">
          {steps && steps.records.sort(sortStepDate).map((step) => (
            <div className="PaymentRequestActionsCollapse__list__step" key={step.id}>
              <div className="PaymentRequestActionsCollapse__list__step__badge">
                <Badge variant={step.phase?.id ?? 'DEFAULT'}>
                  {t('common:days-first-letter')}{getDaysDiff(step.at.at, payRequest.dueAt)}
                </Badge>
              </div>
              <div className="PaymentRequestActionsCollapse__list__step__content">
                <HistoryStep
                  data={{ ...step, payRequest: { ...payRequest, isActive: true } }}
                  onActionDone={onActionDone}
                  onActionError={onActionError}
                />
              </div>
            </div>
          ))}
        </ul>
        {type === 'history' && (
          <p className="PaymentRequestActionsCollapse__bottom-phrase">
            {t('payment-requests:start-of-monitoring')}
          </p>
        )}
      </Collapse>
    </div>
  );
};

export default PaymentRequestActionsCollapse;
