import { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import type Errors from 'types/errors';
import type { EavConstructor } from 'types/models';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useIsMountedRef from 'hooks/useIsMountedRef';
import useApiRequest from 'hooks/useApiRequest';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import type { ChosableFormat } from 'utils/isEavValueValid';
import isEavValueValid from 'utils/isEavValueValid';
import Form from './Form';

type Props = {
  type: 'CLIENT' | 'PAY_REQUEST',
  defaultData?: EavConstructor,
  isEdit?: boolean,
  onActionDone(message: string): void,
  onActionError(message: string): void,
  onClose(): void,
};

const ModalAttributesEdit = (props: Props): JSX.Element => {
  const {
    type,
    defaultData,
    isEdit = false,
    onActionDone,
    onActionError,
    onClose,
  } = props;
  const { linesOfBusiness, currentOrganization } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);
  const [errors, setErrors] = useState<Errors.Validation>();
  const isMountedRef = useIsMountedRef();
  const { post, put } = useApiRequest();

  const handleSubmit = useCallback(async (formData: ModalFormData | null) => {
    if (!formData || !currentOrganization
      || !isEavValueValid(
        formData.format as ChosableFormat,
        formData.defaultValue as string | undefined,
        formData.isMandatory !== undefined,
      )
    ) {
      return;
    }

    if (!formData.mode) {
      onActionError(t('attributes:chose-mode'));
      return;
    }

    const possibleValues = Object.keys(formData)
      .filter((keyName) => keyName.startsWith('value'))
      .map((keyName) => formData[keyName]);

    const body = {
      identifier: formData.identifier,
      label: formData.label,
      possibleValues: possibleValues.length ? possibleValues : null,
      subject: type,
      isRequired: formData.mandatory !== undefined ? 1 : 0,
      defaultValue: formData.defaultValue === '' ? null : formData.defaultValue,
      format: formData.format,
    };

    let result;
    if (isEdit) {
      result = await put(`/organization/${currentOrganization.reference}/eav`, body);
    } else {
      result = await post(`/organization/${currentOrganization.reference}/eav`, body);
    }

    if (!isMountedRef.current || result?.errors) {
      onActionError(t(isEdit ? 'attributes:toast.error.edit' : 'attributes:toast.error.add', {
        id: formData.label,
      }));
      setErrors(result?.errors);
      return;
    }

    setErrors(undefined);
    onClose();
    onActionDone(t(isEdit ? 'attributes:toast.success.edit' : 'attributes:toast.success.add', {
      id: formData.label,
    }));
    organizationStore.refresh();
  }, [
    currentOrganization,
    type,
    isEdit,
    isMountedRef,
    onActionDone,
    onActionError,
    onClose,
    post,
    put,
    t,
  ]);

  const title = useMemo(() => {
    if (type === 'CLIENT') {
      return isEdit ? ct('attributes:edit-customer') : ct('attributes:add-customer');
    }
    return isEdit ? ct('attributes:edit-pay-request') : ct('attributes:add-pay-request');
  }, [type, isEdit, ct]);

  return (
    <ModalForm
      isOpened
      onSave={handleSubmit}
      onCancel={onClose}
      title={title}
    >
      <Form defaultData={defaultData} errors={errors} />
    </ModalForm>
  );
};

export default observer(ModalAttributesEdit);
