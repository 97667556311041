import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import type { EavConstructor } from 'types/models';
import useApiRequest from 'hooks/useApiRequest';
import useContextualTranslation from 'hooks/useContextualTranslation';
import organizationStore from 'stores/Organization';
import Loading from 'components/Loading';
import Button from 'components/Button';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import DropdownActions from 'components/DropdownActions';
import ModalEdit from '../../ModalEdit';

type Props = {
  id: string,
  attributesData: EavConstructor,
  type: 'CLIENT' | 'PAY_REQUEST',
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const AttributesRowActions = (props: Props): JSX.Element => {
  const { id, type, attributesData, onActionDone, onActionError } = props;
  const {
    linesOfBusiness,
    currentOrganization,
    refresh: refetchOrganization,
  } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);
  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();
  const { error, remove, isLoading: isDeleting } = useApiRequest();
  const { put } = useApiRequest();
  const [isSaving, setIsSaving] = useState(false);

  const handleConfirmDelete = useCallback(() => {
    const text = type === 'CLIENT'
      ? ct('attributes:confirm-customer-remove', { name: attributesData.label })
      : ct('attributes:confirm-remove', { name: attributesData.label });
    showConfirm(t('common:remove'), text);
  }, [showConfirm, type, t, ct, attributesData]);

  const handleSubmitDelete = useCallback(async () => {
    hideConfirm();
    if (!currentOrganization) {
      return;
    }
    const result = await remove(
      `/organization/${currentOrganization.reference}/eav?identifier=${attributesData.identifier}&subject=${type}`,
    );
    if (result) {
      onActionDone(ct('attributes:toast.success.deleted', { id }));
      organizationStore.refresh();
    }
  }, [
    hideConfirm,
    currentOrganization,
    remove,
    attributesData,
    type,
    onActionDone,
    ct,
    id,
  ]);

  useEffect(() => {
    if (error) {
      onActionError(error.message || t('attributes:toast.error.deleted', { id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const defaultData = useMemo(() => {
    let format;
    switch (attributesData.format) {
      case '^[0-9]+$':
        format = 'number';
        break;
      case '^\\d{4}-[0-1]{1}[0-9]{1}-[0-3]{1}[0-9]{1}$':
        format = 'date';
        break;
      case '^[0-9]+(\\.[0-9][0-9]?)?$':
        format = 'decimalNumber';
        break;
      default:
        format = 'none';
        break;
    }
    return { ...attributesData, format };
  }, [attributesData]);

  const [showModalClients, hideModalClients] = useModal(() => (
    <ModalEdit
      type="CLIENT"
      onClose={hideModalClients}
      onActionDone={onActionDone}
      onActionError={onActionError}
      defaultData={defaultData}
      isEdit
    />
  ), [defaultData, onActionDone, onActionError]);

  const [showModalPaymentRequests, hideModalPaymentRequests] = useModal(() => (
    <ModalEdit
      type="PAY_REQUEST"
      onClose={hideModalPaymentRequests}
      onActionDone={onActionDone}
      onActionError={onActionError}
      defaultData={defaultData}
      isEdit
    />
  ), [defaultData, onActionDone, onActionError]);

  const handleEdit = useCallback(() => {
    if (type === 'CLIENT') {
      showModalClients();
      return;
    }
    showModalPaymentRequests();
  }, [showModalClients, showModalPaymentRequests, type]);

  const handleToggleOverviewDisplay = useCallback(async () => {
    if (!currentOrganization) {
      return;
    }

    setIsSaving(true);
    const { identifier, label, overviewDisplayed } = attributesData;

    try {
      const response = await put(
        `organization/${currentOrganization.reference}/eav`,
        {
          identifier,
          subject: type,
          overviewDisplayed: !overviewDisplayed,
        },
      );
      if (!response) {
        throw new Error();
      }
      await refetchOrganization();
      onActionDone(t('attributes:toast.success.edit', { id: label }));
    } catch {
      onActionError(t('attributes:toast.error.edit', { id: label }));
    } finally {
      setIsSaving(false);
    }
  }, [onActionError, onActionDone, attributesData, put, currentOrganization, type, refetchOrganization, t]);

  const buttons: ReactNode[] = useMemo(() => {
    const toggleOverviewText = attributesData.overviewDisplayed ?
      t('attributes:dont-display-on-overview') :
      t('attributes:display-on-overview');

    return [
      <ButtonWithUserRights
        action="UPDATE"
        category="PREFERENCES_ATTRIBUTES"
        variant="list"
        onClick={handleEdit}
        title={t('common:edit')}
      >
        {isDeleting && <Loading hasNoText />} {t('common:edit')}
      </ButtonWithUserRights>,
      <Button
        variant="list"
        onClick={handleToggleOverviewDisplay}
        title={toggleOverviewText}
      >
        {isSaving && <Loading hasNoText />} {toggleOverviewText}
      </Button>,
      <ButtonWithUserRights
        action="DELETE"
        category="PREFERENCES_ATTRIBUTES"
        variant="list-danger"
        onClick={handleConfirmDelete}
        title={t('common:remove')}
      >
        {isDeleting ? <Loading hasNoText /> : t('common:remove')}
      </ButtonWithUserRights>,
    ];
  }, [attributesData, handleEdit, handleToggleOverviewDisplay, isSaving, handleConfirmDelete, isDeleting, t]);

  return (
    <div className="AttributesRowActions">
      <DropdownActions actions={buttons} />
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        entityName={id}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitDelete}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={t('common:remove')}
      />
    </div>
  );
};

export default observer(AttributesRowActions);
