import './index.scss';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useModal } from 'react-modal-hook';
import type { FetchAllParams } from 'api/selectors';
import { fetchAll } from 'api/selectors';
import type { Selectors } from 'types/models';
import useFetch from 'hooks/useFetch';
import organizationStore from 'stores/Organization';
import ToastNotification from 'components/ToastNotification';
import useToast from 'components/ToastNotification/useToast';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import Alert from 'components/Alert';
import SettingsSection from '../Section';
import SelectorListing from './SelectorListing';
import ModalSelector from './ModalSelector';
import ModalEditYAML from './ModalEditYAML';
import Defaults from './Defaults';

const SettingsSelectors = (): JSX.Element => {
  const { currentOrganization, useGroupByDefault } = organizationStore;
  const { t } = useTranslation();

  const {
    data: dataSolo,
    refetch: refetchSolo,
    error: fetchErrorSolo,
  } = useFetch<FetchAllParams, Selectors[]>(
    {
      cacheKey: 'organizationSelectors',
      organization: currentOrganization?.reference,
      isGroup: false,
    },
    fetchAll,
    { retry: false, enabled: !!currentOrganization },
  );

  const {
    data: dataGroup,
    refetch: refetchGroup,
    error: fetchErrorGroup,
  } = useFetch<FetchAllParams, Selectors[]>(
    {
      cacheKey: 'organizationSelectors',
      organization: currentOrganization?.reference,
      isGroup: true,
    },
    fetchAll,
    { retry: false, enabled: !!currentOrganization },
  );

  const [showGroup, setShowGroup] = useState<boolean>(false);

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const handleActionDone = useCallback((message: string) => {
    showToast(message, 'success');
    refetchSolo();
    refetchGroup();
  }, [refetchSolo, refetchGroup, showToast]);

  const handleActionError = useCallback((message: string) => {
    showToast(message, 'error');
  }, [showToast]);

  const [showModalCreateSelector, hideModalCreateSelector] = useModal(() => (
    <ModalSelector
      onClose={hideModalCreateSelector}
      onActionDone={handleActionDone}
      onActionError={handleActionError}
      isGroup={showGroup}
    />
  ), [handleActionDone, handleActionError, showGroup]);

  const [showModalEditYAML, hideModalEditYAML] = useModal(() => (
    <ModalEditYAML
      onClose={hideModalEditYAML}
      onActionDone={handleActionDone}
      isGroup={showGroup}
      onActionError={(message: string) => showToast(message, 'error')}
    />
  ), [handleActionDone, showGroup, showToast]);

  const openAddSelectorModal = useCallback((showGroupModal: boolean) => {
    setShowGroup(showGroupModal);
    showModalCreateSelector();
  }, [showModalCreateSelector]);

  const soloClassNames = classNames('Selectors__section', {
    'Selectors__section--is-second': useGroupByDefault,
  });

  return (
    <div className="Selectors">
      <SettingsSection title={t('selectors:default-selectors')}>
        <Defaults onActionDone={handleActionDone} onActionError={handleActionError} />
      </SettingsSection>
      <SettingsSection
        className={soloClassNames}
        title={t('selectors:selectors-solo')}
        action={(
          <ButtonWithUserRights
            action="CREATE"
            category="PREFERENCES_SELECTORS"
            withCaretIcon
            onClick={() => { openAddSelectorModal(false); }}
            variant="link"
          >
            {t('selectors:add-solo-selector')}
          </ButtonWithUserRights>
        )}
        secondaryAction={(
          <ButtonWithUserRights
            action="CREATE"
            category="PREFERENCES_SELECTORS"
            onClick={() => { setShowGroup(false); showModalEditYAML(); }}
            variant="link"
            withCaretIcon
          >
            {t('selectors:yaml-edit')}
          </ButtonWithUserRights>
        )}
      >
      {fetchErrorSolo && (
        <Alert variant="error">
          {fetchErrorSolo.message || t('errors:unknown-retry')}
        </Alert>
      )}
      {(!fetchErrorSolo && !!dataSolo) && (
        <SelectorListing
          selectors={dataSolo}
          onActionDone={handleActionDone}
          onActionError={handleActionError}
        />
      )}
      </SettingsSection>
      <SettingsSection
        title={t('selectors:selectors-group')}
        className="Selectors__section"
        action={(
          <ButtonWithUserRights
            action="CREATE"
            category="PREFERENCES_SELECTORS"
            withCaretIcon
            onClick={() => { openAddSelectorModal(true); }}
            variant="link"
          >
            {t('selectors:add-group-selector')}
          </ButtonWithUserRights>
        )}
        secondaryAction={(
          <ButtonWithUserRights
            action="CREATE"
            category="PREFERENCES_SELECTORS"
            onClick={() => { setShowGroup(true); showModalEditYAML(); }}
            variant="link"
            withCaretIcon
          >
            {t('selectors:yaml-edit')}
          </ButtonWithUserRights>
        )}
      >
        {fetchErrorGroup && (
          <Alert variant="error">
            {fetchErrorGroup.message || t('errors:unknown-retry')}
          </Alert>
        )}
        {(!fetchErrorGroup && !!dataGroup) && (
          <SelectorListing
            selectors={dataGroup}
            isGroup
            onActionDone={handleActionDone}
            onActionError={handleActionError}
          />
        )}
      </SettingsSection>
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </div>
  );
};

export default observer(SettingsSelectors);
