import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import authStore from 'stores/Auth';
import config from 'config';

const TagLink = (): JSX.Element => {
  const { t } = useTranslation();
  const { locale } = authStore?.user || config.DEFAULT_LOCALE;

  const tagLink = useMemo(() => (
    config.FRESHDESK_TAG_LINKS[locale] || config.FRESHDESK_TAG_LINKS.en
  ), [locale]);

  return (
    <a target="__blank" href={tagLink}>{t('models:tag-description')}</a>
  );
};

export default observer(TagLink);
