import type { TFunction } from 'i18next';
import type Misc from 'types/misc';
import Config from 'config';
import getI18nPhase from 'utils/getI18nPhase';

const PaymentRequestFilters = (
  ct: TFunction,
  defaultValues: {
    status: Misc.PayRequestStatus,
    client?: string,
  },
): Misc.FilterDeclarationItem[] => [
  {
    title: ct('common:deadline'),
    emptyText: ct('payment-requests:filters.all-deadlines'),
    key: 'phase',
    type: 'select',
    selectData: Config.PHASES_ID.map((phase) => (
      { value: phase, label: ct(getI18nPhase(phase)) }
    )),
  },
  {
    title: ct('common:manager'),
    emptyText: ct('payment-requests:filters.all-managers'),
    key: 'users',
    type: 'selectAsync',
    fetchEntity: 'users',
  },
  {
    title: ct('common:client'),
    emptyText: ct('payment-requests:filters.all-clients'),
    key: 'client',
    type: 'selectClient',
    defaultValue: defaultValues.client,
  },
  {
    title: ct('common:scenario'),
    emptyText: ct('payment-requests:filters.all-scenarios'),
    key: 'dunningPlan',
    type: 'selectScenario',
  },
  {
    title: ct('common:due-after'),
    key: 'deadline_from',
    type: 'dateInput',
  },
  {
    title: ct('common:due-before'),
    key: 'deadline_to',
    type: 'dateInput',
  },
  {
    title: ct('common:min-amount'),
    key: 'amount_from',
    type: 'numberInput',
  },
  {
    title: ct('common:max-amount'),
    key: 'amount_to',
    type: 'numberInput',
  },
  {
    title: ct('common:muted'),
    key: 'mute',
    type: 'yesNo',
  },
  {
    title: ct('common:status'),
    emptyText: ct('payment-requests:filters.all-deadlines'),
    key: 'status',
    type: 'select',
    defaultValue: defaultValues.status,
    selectData: [
      {
        value: 'IN_PROGRESS',
        label: ct('common:tabs-status-active'),
      },
      {
        value: 'PAID',
        label: ct('common:tabs-status-paid'),
      },
      {
        value: 'CANCELLED',
        label: ct('common:tabs-status-cancel'),
      },
      {
        value: 'LITIGATED',
        label: ct('common:tabs-status-litigated'),
      },
      {
        value: 'ALL',
        label: ct('common:tabs-status-all'),
      },
    ],
    isNotRemovable: true,
    isHidden: true,
  },
  {
    title: ct('common:searching'),
    key: 'search',
    type: 'custom',
    isHidden: true,
  },
];

export default PaymentRequestFilters;
