import './index.scss';
import { useEffect, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useModal } from 'react-modal-hook';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import type { HistoryStep as HistoryStepType, HistoryStepEmail, HistoryStepLetter, HistoryStepSMS } from 'types/models';
import getHistoryStepType from 'utils/getHistoryStepType';
import useApiRequest from 'hooks/useApiRequest';
import organizationStore from 'stores/Organization';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import Button from 'components/Button';
import Icon from 'components/Icon';
import StepIcon from 'components/StepIcon';
import HistoryStepDate from './Date';
import HistoryStepActions from './Actions';
import HistoryStepDetails from './Details';
import ReminderPreview from './ReminderPreview';
import ReminderEdit from './ReminderEdit';
import FreeTaskEdit from './FreeTaskEdit';
import HistoryStepInfosHeader from './InfosHeader';
import { haveHistoryEventsError } from './Details/History/WebHookStatus/Item/utils';

type Props = {
  data: HistoryStepType | HistoryStepEmail | HistoryStepLetter | HistoryStepSMS,
  customerId?: number,
  showClientName?: boolean,
  showRelativeDateRef?: boolean,
  onActionDone(message: string): void,
  onActionError(message: string): void,
  shouldChangePlannedToValidated?: boolean,
};

const HistoryStep = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    data,
    showClientName = false,
    showRelativeDateRef = false,
    customerId,
    shouldChangePlannedToValidated = false,
    onActionDone,
    onActionError,
  } = props;
  const { userRight } = organizationStore;
  const isUserReader = userRight === 'ROLE_READER';
  const [openAction, setOpenAction] = useState<boolean>(false);

  const type = useMemo(() => getHistoryStepType(data), [data]);
  const {
    id,
    name,
    payRequest,
    contacts,
    channel,
    group,
    doneAt,
    missingContactRoles,
    validation,
    remove,
    isInitialActive,
    at,
    entity,
    errorMessage,
  } = data;

  const {
    isConfirmShowed,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const { put, error } = useApiRequest();
  const cache = useQueryClient();

  const handleOpenAction = useCallback(() => {
    if (openAction) {
      setOpenAction(false);
    } else {
      setOpenAction(true);
    }
  }, [openAction]);

  const handleActionDone = useCallback((message: string) => {
    // - Invalide le cache de toutes les actions
    const cacheToInvalidate = [
      'actionsFuture',
      'actionsDone',
      'payRequestHistorySteps',
      'notesForPayRequest',
      'reminderContent',
      'previewContent',
      'customerHistory',
      'customerActions',
      'notesForCustomer',
      'organizationHistorySteps',
      'organizationLastHistory',
      'myActions',
    ];
    cacheToInvalidate.forEach((cacheKey: string) => {
      cache.invalidateQueries({ queryKey: [cacheKey] });
    });

    onActionDone(message);
  }, [cache, onActionDone]);

  const handleSubmitCancelReminder = useCallback(async () => {
    hideConfirm();

    const response = await put(`task/manage/${id}/remove`);
    if (response) {
      const messageProps = {
        reminder: name,
        reference: payRequest?.reference,
      };

      handleActionDone(
        t('clients:actions.toast.history.remove-reminder', messageProps),
      );
    }
  }, [hideConfirm, name, payRequest, id, t, put, handleActionDone]);

  useEffect(() => {
    if (error) {
      onActionError(error.message || t('errors:unknown-retry'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const [showEditModal, hideEditModal] = useModal(() => {
    switch (type) {
      case 'task':
        return (
          <FreeTaskEdit
            task={data}
            onDone={handleActionDone}
            onClose={hideEditModal}
          />
        );
      case 'reminder':
        return (
          <ReminderEdit
            reminder={data}
            onDone={handleActionDone}
            onClose={hideEditModal}
          />
        );
      default:
        return null;
    }
  }, [type, data, handleActionDone]);

  const [showPreviewModal, hidePreviewModal] = useModal(() => (
    (contacts && contacts.length > 0) ? (
      <ReminderPreview
        contactId={contacts[0].id}
        reminder={{ id, channel, group: !!group }}
        onClose={hidePreviewModal}
      />
    ) : null
  ), [contacts, id, channel, group]);

  const isWaiting = useMemo(() => {
    const atDate = new Date(at.at);
    const tonight = new Date();
    // - Pour éviter de mettre du jaune partout le jour des envois
    tonight.setHours(0, 0, 0, 0);

    return (
      ['reminder', 'task'].includes(type)
      && validation === null
      && remove === null
      && isInitialActive === false
      && payRequest !== null
      && atDate < tonight
    );
  }, [type, validation, remove, isInitialActive, at, payRequest]);

  const hasMissingContact = useMemo(() => {
    if (type !== 'reminder') {
      return false;
    }
    if (contacts === null || contacts.length === 0) {
      return true;
    }
    if (!!doneAt && !contacts.find(({ sentAt }) => sentAt !== null)) {
      return true;
    }
    if (!!missingContactRoles && missingContactRoles.length > 0) {
      return contacts.every((contact) => (
        missingContactRoles.includes(contact.contactRole)
      ));
    }
    return false;
  }, [type, contacts, doneAt, missingContactRoles]);

  const isFailedSent = useMemo(() => (
    type === 'reminder'
    && doneAt !== null
    && contacts !== null
    && !contacts.some((contact) => (
      contact.sentAt !== null && contact.errorMessage === null
    ))
  ), [type, doneAt, contacts]);

  const isBill = useMemo(() => [
    'PaymentAssignedAlert',
    'PayRequestCreationMilestone',
    'PayRequestDeleteAlert',
    'DownloadInvoiceAlert',
  ].includes(entity), [entity]);

  const withActions = useMemo(() => (
    type !== 'alert'
    && type !== 'note'
    && entity !== 'ProvideContactInformationsTask'
  ), [type, entity]);

  const actionWebHookFailed = haveHistoryEventsError(data);

  const classNames = classnames('HistoryStep', {
    'HistoryStep--is-alert': type === 'alert' && !isBill,
    'HistoryStep--is-note': type === 'note',
    'HistoryStep--is-waiting': isWaiting,
    'HistoryStep--is-error': hasMissingContact || isFailedSent || actionWebHookFailed,
  });

  return (
    <li className={classNames}>
      <div className="HistoryStep__infos">
        <StepIcon data={data} />
        <HistoryStepInfosHeader
          data={data}
          showCustomer={showClientName}
          onActionDone={handleActionDone}
          onActionError={onActionError}
        />
        <HistoryStepDate
          data={data}
          isWaiting={isWaiting}
          isMissingContact={hasMissingContact}
          isFailedSent={isFailedSent}
          failSentReason={errorMessage}
          isEvent={type === 'alert'}
          showRelativeDateRef={showRelativeDateRef}
          shouldChangePlannedToValidated={shouldChangePlannedToValidated}
        />
        {withActions && (
          <div className="HistoryStep__infos__actions">
            {!isUserReader && (
              <HistoryStepActions
                data={data}
                customerId={customerId}
                isMissingContact={hasMissingContact}
                isRemoved={remove !== null}
                onActionDone={handleActionDone}
                onActionError={onActionError}
                onShowEdit={showEditModal}
              />
            )}
            <Button
              variant="light"
              onClick={handleOpenAction}
              className="HistoryStep__collapse-toggle"
              aria-controls={`open-action-collapse-${id}`}
              aria-expanded={openAction}
            >
              {!openAction ? <Icon name="caret-down" /> : <Icon name="caret-up" />}
            </Button>
          </div>
        )}
      </div>
      {(withActions && openAction) && (
        <div className="HistoryStep__collapse">
          <HistoryStepDetails
            data={data}
            onShowEdit={showEditModal}
            onShowReminder={showPreviewModal}
          />
        </div>
      )}
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        entityName={payRequest?.reference}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitCancelReminder}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={t('common:cancel')}
        key={id}
      />
    </li>
  );
};

export default observer(HistoryStep);
