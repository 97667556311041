import './index.scss';
import React from 'react';
import Loading from 'components/Loading';

interface ListData {
  label: string,
  value: string | React.ReactNode,
  isLoading?: boolean,
}

interface Props {
  data: ListData[]
}

const SettingsList: React.FC<Props> = ({ data }) => (
  <div className="SettingsList">
    <dl className="SettingsList__item">
      {data.map(({ label, value, isLoading }) => (
        <div className="SettingsList__item__delimiter" key={label}>
          <dt className="SettingsList__item__label">
            {label}
            {isLoading && <Loading hasNoText />}
          </dt>
          <dd className="SettingsList__item__value">
            {value}
          </dd>
        </div>
      ))}
    </dl>
  </div>
);

export default SettingsList;
