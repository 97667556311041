import './index.scss';
import type { ReactNode } from 'react';

type Props = {
  title: string,
  value: string | number | ReactNode,
  tooltip?: string,
};

const PaymentRequestInfosCard = ({ title, tooltip, value }: Props): JSX.Element => (
  <div className="PaymentRequestInfosCard" title={tooltip}>
    <h4 className="PaymentRequestInfosCard__title">{title}</h4>
    <div className="PaymentRequestInfosCard__content">
        <div className="PaymentRequestInfosCard__value">
          {value}
        </div>
    </div>
  </div>
);

export default PaymentRequestInfosCard;
