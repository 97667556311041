import './index.scss';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import GoBackButton from 'components/GoBackButton';
import { ReportContext } from '../..';
import LoadingOrRedirect from './LoadingOrRedirect';

const ReportHeader = (): JSX.Element => {
  const { t } = useTranslation();
  const report = useContext(ReportContext);

  return (
    <div className="ReportHeader">
      <div className="ReportHeader__links">
        <GoBackButton />
      </div>
      {!report && (
        <LoadingOrRedirect />
      )}
      {report && (
        <div className="ReportHeader__infos">
          <h1 className="ReportHeader__infos__name">
            {`${t('reports:import')} ${report.format.toUpperCase()}`}
          </h1>
        </div>
      )}
    </div>
  );
};

export default ReportHeader;
