import React from 'react';
import type Misc from 'types/misc';

type AddressInfos = {
  address1?: string,
  address2?: string,
  address3?: string,
  postalCode?: string,
  city?: string,
  country?: Misc.Country,
};

interface Props {
  data: AddressInfos,
}

const AddressDetails: React.FC<Props> = ({ data }) => {
  if (!data.address1 && !data.postalCode && !data.city) {
    return null;
  }

  return (
    <>
      {data.address1}<br />
      {(data.address2 || data.address3) && (
        <>{[data.address2, data.address3].join(', ')}<br /></>
      )}
      {data.postalCode} {data.city}
      {data.country ? (<><br />{data.country}</>) : null}
    </>
  );
};

export default AddressDetails;
