import type Misc from 'types/misc';
import type { Contact } from 'types/models';
import Request from 'utils/request';

export type FetchOneOptions = {
  withClient: boolean,
  withUser?: boolean,
};

export type FetchAllParams = {
  organization: string | undefined,
  withClient: boolean,
  withUser?: boolean,
  filtering: Misc.Filter[],
  fetchOptions: Misc.PaginatedFetchArgs<Contact>,
};

export type FetchAllForListContactParams = {
  organization: string | undefined,
  customerId: number | undefined,
  unlimitedNumberOfContacts?: boolean,
};

export type FetchOneParams = {
  organization: string | undefined,
  id?: number | null,
  options?: FetchOneOptions,
};

const fetchOne = async ({ organization, id, options }: FetchOneParams) => {
  if (!organization) {
    throw new Error('FetchOneContact: Missing organization.');
  }
  if (!id) {
    throw new Error('FetchOneContact: Missing contact ID.');
  }

  const queryData = [];
  if (options?.withClient) {
    queryData.push('withClient=1');
  }

  if (options?.withUser) {
    queryData.push('withUser=1');
  }

  const result = await Request.get<Contact>(
    `contact/${organization}/${id?.toString()}?${queryData.join('&')}`,
  );
  return result;
};

const fetchAll = async (props: FetchAllParams) => {
  const {
    organization,
    withClient,
    withUser = false,
    filtering,
    fetchOptions,
  } = props;
  if (!organization) {
    throw new Error('FetchAllContacts: Missing organization.');
  }

  const {
    pageIndex,
    pageSize,
    search: searchValue,
    sort,
  } = fetchOptions;

  const queryData = [
    `recordsPerPage=${pageSize}`,
    `page=${pageIndex + 1}`,
    `withClient=${withClient ? '1' : '0'}`,
  ];

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }) => {
      queryData.push(`filters[${name}]=${encodeURIComponent(
        (Array.isArray(value) ? value.join(',') : value) || '',
      )}`);
    });
  }

  if (withUser) {
    queryData.push('withUser=1');
  }

  if (searchValue && searchValue.length > 2) {
    queryData.push(`filters[search]=${encodeURIComponent(searchValue || '')}`);
  }

  if (sort) {
    const direction = sort.desc ? 'desc' : 'asc';
    queryData.push(`orderBy=${sort.id === 'contactRole' ? 'role' : sort.id}&orderDirection=${direction}`);
  }

  const result = await Request.get<Misc.Listing<Contact>>(
    `contact/${organization}?${queryData.join('&')}`,
  );
  return result;
};

const fetchAllForListContact = async ({
  organization,
  customerId,
  unlimitedNumberOfContacts,
}: FetchAllForListContactParams) => {
  if (!organization || !customerId) {
    throw new Error('FetchAllForListContact: Missing organization or customer ID.');
  }

  const queryData = [
    'orderBy=role',
    'orderDirection=asc',
  ];

  if (!unlimitedNumberOfContacts) {
    queryData.push('recordsPerPage=5');
  }

  const result = await Request.get<Contact[]>(
    `client/${organization}/${customerId}/contacts?${queryData.join('&')}`,
  );
  return result;
};

export {
  fetchOne,
  fetchAll,
  fetchAllForListContact,
};
