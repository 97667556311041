import { useMemo, useState, useCallback, useRef } from 'react';
import equal from 'deep-equal';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import type Errors from 'types/errors';
import type Misc from 'types/misc';
import type { PayRequest, PayRequestPost } from 'types/models';
import organizationStore from 'stores/Organization';
import formatEavsValues from 'utils/formatEavsValues';
import useContextualTranslation from 'hooks/useContextualTranslation';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import Confirm from 'components/Confirm';
import PayRequestForm from './Form';

type Props = {
  isLoading: boolean,
  data?: PayRequestPost,
  apiError: Errors.Request | null,
  finallyRedirectTo?: string,
  customerId?: number,
  isCustomerInGroup: boolean,
  validationErrors: Errors.Validation | null,
  receipt: string | null,
  receiptName: string | null,
  onChangeReceipt(newReceipt: string | null): void,
  onChangeReceiptName(name: string | null): void,
  onChangeCustomer(newId: number): void,
  onSubmit(data: PayRequestPost): void,
  onClose(): void,
};

const PaymentRequestEditModal = (props: Props): JSX.Element => {
  const history = useHistory();
  const { payRequestRef } = useParams<{ payRequestRef?: string }>();

  const {
    currentOrganization,
    modePayRequest,
    eavs,
    linesOfBusiness,
  } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);

  const {
    onClose,
    finallyRedirectTo,
    onSubmit,
    isLoading,
    validationErrors,
    apiError,
    data,
    onChangeCustomer,
    receiptName,
    onChangeReceiptName,
    receipt,
    customerId,
    isCustomerInGroup,
    onChangeReceipt,
  } = props;

  const isNew = useMemo(
    () => !payRequestRef || payRequestRef === 'new',
    [payRequestRef],
  );

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);

  const [payRequestData] = useState<PayRequest | null>(null);
  const initialData = useRef<PayRequestPost | null>(null);

  const isBalanceMode = useMemo(() => (
    modePayRequest === 'BALANCE'
  ), [modePayRequest]);

  const mapFormData = useCallback(
    (rawData: ModalFormData): PayRequestPost | null => {
      if (!currentOrganization) {
        return null;
      }

      const newCustomerId = Number.parseInt(rawData.customerId as string, 10);
      onChangeCustomer(newCustomerId);

      return {
        organization: currentOrganization.reference,
        customerId: newCustomerId,
        reference: rawData.reference as string,
        subject: rawData.subject as string,
        amount: rawData.amount ? Number(rawData.amount) : null,
        currency: rawData.currency as Misc.CurrencyCode,
        startAt: rawData.startAt as string,
        dueAt: rawData.dueAt as string,
        issueAt: rawData.issueAt as string,
        balanceMode: isBalanceMode ? rawData.balanceMode as Misc.BalanceMode : null,
        dunningPlan: rawData.dunningPlan as string,
        sendDispatches: rawData.sendDispatches === '1',
        groupDunning: rawData.groupDunning === '1',
        active: true,
        users: (rawData.users as string).split(',').map((user) => ({ identifier: user })),
        promiseToPayAt: rawData.promiseToPayAt as string,
        receipt: receipt || '',
        eavs: formatEavsValues(eavs.payRequest, rawData),
      };
    },
    [currentOrganization, onChangeCustomer, isBalanceMode, receipt, eavs],
  );

  const handleInit = useCallback((formData: ModalFormData | null) => {
    initialData.current = formData ? mapFormData(formData) : null;
  }, [initialData, mapFormData]);

  const closeSelf = useCallback(() => {
    onClose();
    setShowCancelConfirm(false);
    setHasChanges(false);
    if (finallyRedirectTo) {
      setTimeout(() => {
        history.push(finallyRedirectTo);
      }, 300);
    }
  }, [onClose, history, finallyRedirectTo]);

  const handleChange = useCallback(
    (formData: ModalFormData | null) => {
      setHasChanges(
        !!formData && !equal(initialData.current, mapFormData(formData)),
      );
    },
    [initialData, mapFormData],
  );

  const handleCancel = useCallback(
    () => {
      if (hasChanges) {
        setShowCancelConfirm(true);
      } else {
        closeSelf();
      }
    },
    [hasChanges, closeSelf],
  );

  const handleSubmit = useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData || !currentOrganization) {
        return;
      }

      const formattedData = mapFormData(formData);
      if (!formattedData) {
        return;
      }

      onSubmit(formattedData);
    },
    [currentOrganization, mapFormData, onSubmit],
  );

  return (
    <ModalForm
      isOpened
      className="PaymentRequestEdit"
      title={isNew ? ct('common:new-bill') : ct('common:edit-bill')}
      onInit={handleInit}
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
      buttonsDisabled={!customerId}
      saveText={t('models:preview')}
    >
      {apiError && (
        <ErrorMessage error={apiError} />
      )}
      <PayRequestForm
        defaultData={payRequestData || undefined}
        data={data}
        isBalanceMode={isBalanceMode}
        isCustomerInGroup={isCustomerInGroup}
        eavs={eavs}
        errors={validationErrors}
        customerId={customerId}
        receipt={receipt}
        receiptName={receiptName}
        onChangeReceipt={onChangeReceipt}
        onChangeReceiptName={onChangeReceiptName}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={() => { closeSelf(); }}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(PaymentRequestEditModal);
