import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import equal from 'deep-equal';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import type Errors from 'types/errors';
import type Misc from 'types/misc';
import type { Client, ClientPost } from 'types/models';
import organizationStore from 'stores/Organization';
import formatEavsValues from 'utils/formatEavsValues';
import { fetchOne, FetchOneParams } from 'api/customers';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useFetch from 'hooks/useFetch';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import ModalForm from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import Confirm from 'components/Confirm';
import { getMultipleIdentifiers } from 'components/FormSelect/utils';
import type { ModalFormData } from 'components/ModalForm';
import CustomerForm from './Form';

type Props = {
  editType: 'new' | 'edit' | undefined,
  customerId: number | undefined,
  onDone(title: string, message: string): void,
  onClose(): void,
  shouldRedirectToCreatedEntity?: boolean,
  finallyRedirectTo?: string,
  defaultClientName?: string,
};

const CustomerEditModal = (props: Props): JSX.Element => {
  const history = useHistory();
  const isMountedRef = useIsMountedRef();
  const { currentOrganization, eavs, linesOfBusiness } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);
  const cache = useQueryClient();
  const {
    editType,
    customerId,
    onDone,
    onClose,
    shouldRedirectToCreatedEntity = false,
    finallyRedirectTo,
    defaultClientName,
  } = props;

  const isNew = useMemo(
    () => !editType || editType === 'new',
    [editType],
  );

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Errors.Validation | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);

  const initialData = useRef<ClientPost | null>(null);

  const { post, put, isLoading: isSaving } = useApiRequest();

  const { data: customerData, error, isLoading } = useFetch<FetchOneParams, Client>(
    {
      cacheKey: 'customer',
      organization: currentOrganization?.reference,
      id: customerId,
      options: { withEav: 1, withUser: 1 },
    },
    fetchOne,
    { enabled: !!customerId },
  );

  const mapFormData = useCallback((rawData: ModalFormData): ClientPost | null => {
    if (!currentOrganization) {
      return null;
    }

    return {
      organization: currentOrganization.reference,
      type: rawData.type as Misc.PersonType,
      attachment: rawData.attachment !== undefined,
      useGroup: rawData.useGroup !== undefined,
      reference: rawData.reference as string,
      firstName: rawData.firstName as string | null,
      lastName: rawData.lastName as string | null,
      corporateName: rawData.corporateName as string | null,
      email: rawData.email as string,
      civility: rawData.civility as string,
      address1: rawData.address1 as string,
      address2: rawData.address2 as string,
      address3: rawData.address3 as string,
      postalCode: rawData.postalCode as string,
      city: rawData.city as string,
      country: rawData.country as Misc.Country,
      iban: rawData.iban as string,
      legalId: rawData.legalId as string,
      phone: rawData.phone as string,
      cellPhone: rawData.cellPhone as string,
      eavs: formatEavsValues(eavs.client, rawData),
      users: getMultipleIdentifiers(rawData.users as string | undefined),
    };
  }, [currentOrganization, eavs]);

  const handleInit = useCallback((formData: ModalFormData | null) => {
    initialData.current = formData ? mapFormData(formData) : null;
  }, [initialData, mapFormData]);

  const closeSelf = useCallback(() => {
    onClose();
    setShowCancelConfirm(false);
    setHasChanges(false);
    setValidationErrors(null);
    if (finallyRedirectTo) {
      setTimeout(() => {
        history.push(finallyRedirectTo);
      }, 300);
    }
  }, [onClose, history, finallyRedirectTo]);

  useEffect(() => {
    const { code } = error || { code: 0 };
    if (code === 404 || code === 2) {
      closeSelf();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleChange = useCallback((formData: ModalFormData | null) => {
    if (!formData) {
      return;
    }

    const data = mapFormData(formData);
    if (!data) {
      return;
    }

    // @TODO: Ici on ignore la clé `users`, car cette clé est toujours vide
    // dans les données initiales, à cause du fetch des users différé, dans le
    // component FormSelect async (se trouvant dans le formulaire).
    const { users: noop1, ...initData } = initialData.current || { users: [] };
    const { users: noop2, ...changedData } = data;
    setHasChanges(!equal(initData, changedData));
  }, [initialData, mapFormData]);

  const handleCancel = useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirm(true);
    } else {
      closeSelf();
    }
  }, [hasChanges, closeSelf]);

  const handleEmailError = useCallback(() => {
    setValidationErrors({
      email: { code: 4, message: t('email-not-valid') },
    });
  }, [t]);

  const handleSubmit = useCallback(async (formData: ModalFormData | null) => {
    if (!formData || !currentOrganization) {
      return;
    }

    setValidationErrors(null);

    const data = mapFormData(formData);
    if (!data) {
      return;
    }

    let result;
    if (isNew) {
      result = await post<Misc.IdRef>('client', data);
    } else if (customerData) {
      result = await put<Misc.IdRef>(`client/manage/${customerData.id}`, data);

      const cacheToInvalidate = [
        ['customer', currentOrganization.reference, customerId],
        ['actionsFuture'],
        ['actionsDone'],
      ];
      cacheToInvalidate.forEach((cacheKey) => {
        cache.invalidateQueries({ queryKey: cacheKey });
      });
    }

    if (!isMountedRef.current) {
      return;
    }

    if (result?.errors) {
      setValidationErrors(result.errors);
      return;
    }

    if (result?.reference) {
      const { reference } = result;
      onDone(
        ct(
          isNew
            ? 'clients:actions.toast.created-reference'
            : 'clients:actions.toast.modified-reference',
          { reference },
        ),
        reference,
      );
      closeSelf();
      if (shouldRedirectToCreatedEntity) {
        history.push(`/customer/${result.id}`);
      }
    }
  }, [
    currentOrganization,
    mapFormData,
    isNew,
    customerData,
    isMountedRef,
    post,
    put,
    cache,
    customerId,
    onDone,
    ct,
    closeSelf,
    shouldRedirectToCreatedEntity,
    history,
  ]);

  return (
    <ModalForm
      className="CustomerEdit"
      title={isNew ? ct('common:new-client') : ct('common:edit-client')}
      isOpened
      onInit={handleInit}
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onEmailError={handleEmailError}
      onCancel={handleCancel}
      isFetched={!isLoading}
      isLoading={(isLoading && !!customerId) || isSaving}
    >
      {error && customerId && <ErrorMessage error={error} />}
      <CustomerForm
        defaultData={customerData}
        defaultClientName={defaultClientName}
        isCreate={isNew}
        eavs={eavs}
        errors={validationErrors}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t<string>('common:close-form')}
        cancelButtonText={t<string>('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={() => { closeSelf(); }}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(CustomerEditModal);
