import './index.scss';
import { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import type Errors from 'types/errors';
import type Misc from 'types/misc';
import Config from 'config';
import organizationStore from 'stores/Organization';
import isEavValueValid from 'utils/isEavValueValid';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import ErrorValidationMessage from 'components/ErrorValidationMessage';
import FormSelect from 'components/FormSelect';
import getI18nAttributeType from 'utils/getI18nAttributeType';
import getEavTypeFromFormat from 'utils/getEavTypeFromFormat';
import getI18nSelectorOption from 'utils/getI18nSelectorOption';
import InputGroup from 'components/InputGroup';
import { getAttributeFromEAVValue } from '../utils';

type Props = {
  errors?: Errors.Validation | null,
  attribute: string | number | null,
  isGroup: boolean,
  attributeType: Misc.AttributeType | null,
  onChangeAttribute(newValue: string | null): void,
  onChangeAttributeType(newValue: Misc.AttributeType | null): void,
  pairedWithAttribute?: string | null,
};

const Condition = (props: Props): JSX.Element => {
  const {
    errors,
    isGroup,
    attribute,
    attributeType,
    onChangeAttribute,
    onChangeAttributeType,
    pairedWithAttribute,
  } = props;
  const { t } = useTranslation();
  const { eavs } = organizationStore;

  const attributeTypeClassname = classNames('SelectorsFormCondition__attribute-type', {
    'SelectorsFormCondition__attribute-type--selected': attributeType !== null,
  });

  const attributePaired = useMemo(() => {
    const foundAttribute = getAttributeFromEAVValue(pairedWithAttribute, eavs);
    if (!foundAttribute) {
      return null;
    }
    return ({
      ...foundAttribute,
      mode: foundAttribute.possibleValues?.length ? 'value' : 'format',
    });
  }, [eavs, pairedWithAttribute]);

  const attributeTypeOptions = useMemo(() => {
    const typeOptions: Misc.AttributeType[] = [
      'clientAttribute',
      'slidingDate',
      'payment',
      'debt',
      'managers',
    ];
    if (!isGroup) {
      typeOptions.unshift('payrequestAttribute');
    }
    if (attributePaired !== null) {
      typeOptions.push('attributeSuggest');
    } else {
      typeOptions.push('free');
    }
    return typeOptions.map((value) => ({ value, label: t(getI18nAttributeType(value)) }));
  }, [attributePaired, isGroup, t]);

  const customerAttributesOptions = useMemo(() => [
    ...eavs.client.map(({ identifier, label }) => (
      { value: `{EAV_CLIENT_${identifier}}`, label }
    )),
    ...Config.CLIENT_SELECTOR_OPTIONS.map((value) => (
      { value, label: t(getI18nSelectorOption(value)) }
    )),
  ], [eavs, t]);

  const payRequestAttributesOptions = useMemo(() => [
    ...eavs.payRequest.map(({ identifier, label }) => (
      { value: `{EAV_PAYREQUEST_${identifier}}`, label }
    )),
    ...Config.PAYREQUEST_SELECTOR_OPTIONS.map((value) => (
      { value, label: t(getI18nSelectorOption(value)) }
    )),
  ], [eavs, t]);

  const slidingDateValues = useMemo(() => {
    if (!attribute || typeof attribute !== 'string' || !attribute.startsWith('{date_') || attribute.length < 15) {
      return { period: '-', months: '0', days: '0' };
    }

    const period = (attribute[6] === '+') ? '+' : '-';
    const slicedAttribute = attribute.slice(7, -5).split(`months${period}`);
    if (slicedAttribute.length === 2) {
      return { period, months: slicedAttribute[0], days: slicedAttribute[1] };
    }
    return { period, months: '0', days: '0' };
  }, [attribute]);

  const handleChangeAttributeType = useCallback((_name: string, newValue: string | null) => {
    onChangeAttributeType(newValue as Misc.AttributeType | null);
    const newAttribute = newValue === 'payment' ? '{PAYMENT_status}' : null;
    onChangeAttribute(newAttribute);
  }, [onChangeAttributeType, onChangeAttribute]);

  const handleSlidingDateChange = useCallback((name: string, value: string | null) => {
    const { period, months, days } = slidingDateValues;
    switch (name) {
      case 'period':
        onChangeAttribute(`{date_${value}${months}months${value}${days}days}`);
        break;
      case 'days':
        onChangeAttribute(`{date_${period}${months}months${period}${value}days}`);
        break;
      case 'months':
        onChangeAttribute(`{date_${period}${value}months${period}${days}days}`);
        break;
      default:
        break;
    }
  }, [onChangeAttribute, slidingDateValues]);

  const isFormatValueValid = useMemo(() => (
    isEavValueValid(
      getEavTypeFromFormat(new RegExp(attributePaired?.format || '')),
      attribute,
      attributePaired?.required || false,
    )
  ), [attribute, attributePaired]);

  return (
    <div className="SelectorsFormCondition">
      <FormFieldset>
        <FormGroup>
          <FormSelect
            name="attributeType"
            value={attributeType}
            className={attributeTypeClassname}
            selectOptions={attributeTypeOptions}
            onSelect={handleChangeAttributeType}
            placeholder={t('selectors:choose-attribute-type')}
          />
          <ErrorValidationMessage error={errors?.position} />
        </FormGroup>
        {attributeType === 'clientAttribute' && (
          <FormGroup>
            <FormSelect
              name="attribute"
              value={attribute?.toString()}
              onSelect={(_name, newValue) => { onChangeAttribute(newValue); }}
              selectOptions={customerAttributesOptions}
              placeholder={t('selectors:choose-attribute')}
            />
            <ErrorValidationMessage error={errors?.select} />
          </FormGroup>
        )}
        {attributeType === 'payrequestAttribute' && (
          <FormGroup>
            <FormSelect
              name="attribute"
              value={attribute?.toString()}
              onSelect={(_name, newValue) => { onChangeAttribute(newValue); }}
              selectOptions={payRequestAttributesOptions}
              placeholder={t('selectors:choose-attribute')}
            />
            <ErrorValidationMessage error={errors?.select} />
          </FormGroup>
        )}
        {attributeType === 'payment' && (
          <FormGroup>
            <FormSelect
              name="attribute"
              value="{PAYMENT_status}"
              onSelect={(_name, newValue) => { onChangeAttribute(newValue); }}
              selectOptions={Config.PAYMENT_SELECTOR_OPTIONS.map((value) => (
                { value, label: t(getI18nSelectorOption(value)) }
              ))}
            />
            <ErrorValidationMessage error={errors?.select} />
          </FormGroup>
        )}
        {attributeType === 'debt' && (
          <FormGroup>
            <FormSelect
              name="attribute"
              defaultValue="{PAYREQUEST_totalDebtDue}"
              onSelect={(_name, newValue) => { onChangeAttribute(newValue); }}
              selectOptions={Config.DEBT_SELECTOR_OPTIONS.map((value) => (
                { value, label: t(getI18nSelectorOption(value)) }
              ))}
            />
            <ErrorValidationMessage error={errors?.select} />
          </FormGroup>
        )}
        {attributeType === 'slidingDate' && (
          <div className="SelectorsFormCondition__sliding-date">
            <FormSelect
              name="period"
              value={slidingDateValues.period}
              defaultValue="-"
              withClearButton={false}
              onSelect={handleSlidingDateChange}
              selectOptions={[
                { value: '-', label: t('common:prior-to') },
                { value: '+', label: t('common:within') },
              ]}
              placeholder={t('selectors:choose-attribute')}
            />
            <InputGroup
              helper={t('common:months')}
              helperPosition="right"
            >
              <FormControl
                type="number"
                value={slidingDateValues.months}
                min={0}
                onChange={(value) => handleSlidingDateChange('months', value)}
              />
            </InputGroup>
            <div className="SelectorsFormCondition__sliding-date__between">
              {t('common:and')}
            </div>
            <InputGroup
              helper={t('common:days')}
              helperPosition="right"
            >
              <FormControl
                type="number"
                value={slidingDateValues.days}
                min={0}
                onChange={(value) => handleSlidingDateChange('days', value)}
              />
            </InputGroup>
            <ErrorValidationMessage error={errors?.select} />
          </div>
        )}
        {attributeType === 'attributeSuggest' && attributePaired?.mode === 'value' && (
          <FormGroup>
            <FormSelect
              name="attribute"
              value={attribute?.toString() ?? ''}
              onSelect={(name, newValue) => onChangeAttribute(newValue)}
              selectOptions={attributePaired.possibleValues?.map(
                ({ value }) => ({ value, label: value }),
              )}
              placeholder={t('selectors:choose-attribute')}
            />
            <ErrorValidationMessage error={errors?.select} />
          </FormGroup>
        )}
        {attributeType === 'attributeSuggest' && attributePaired?.mode === 'format' && (
          <FormGroup>
            <FormControl
              name="attribute"
              value={attribute?.toString() ?? ''}
              onChange={onChangeAttribute}
              isInvalid={!isFormatValueValid}
            />
            <ErrorValidationMessage error={errors?.attribute} />
          </FormGroup>
        )}
        {attributeType === 'free' && (
          <FormGroup>
            <FormControl name="attribute" value={attribute || ''} onChange={onChangeAttribute} />
            <ErrorValidationMessage error={errors?.attribute} />
          </FormGroup>
        )}
      </FormFieldset>
    </div>
  );
};

export default observer(Condition);
