import './index.scss';
import { useCallback, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
// import SearchInput from 'components/SearchInput';
import SelectGroupCheckAll from 'components/SelectGroupCheckAll';
import SelectGroupItem from 'components/SelectGroupItem';
import useDebouncedEffect from 'hooks/useDebouncedEffect';
import typeFiltersReducer from './reducer';
import type { TypeFiltersReducer } from './reducer';

type Props = {
  onChange(filters: Misc.Filter[]): void,
  defaultFilters: string[],
};

const CustomerHistoryFilters = ({ onChange, defaultFilters }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [typeFilters, setTypeFilters] = useReducer<TypeFiltersReducer>(
    typeFiltersReducer,
    defaultFilters,
  );

  useDebouncedEffect(() => {
    onChange(typeFilters.length > 0
      ? [{ name: 'type', value: typeFilters }]
      : []);
  }, 500, [typeFilters]);

  const handleSelectAllActions = useCallback((isChecked: boolean) => {
    setTypeFilters({
      action: isChecked ? 'selectMany' : 'unselectMany',
      value: ['sent_reminder', 'task_processed', 'sent_error_reminder'],
    });
  }, []);

  const handleSelectAllEvents = useCallback((isChecked: boolean) => {
    setTypeFilters({
      action: isChecked ? 'selectMany' : 'unselectMany',
      value: ['pay_request', 'payment', 'data'],
    });
  }, []);

  const handleSelectNotes = useCallback((isChecked: boolean) => {
    setTypeFilters({
      action: isChecked ? 'add' : 'remove',
      value: 'note',
    });
  }, []);

  const handleSelectItemType = useCallback((key: string, isChecked: boolean) => {
    setTypeFilters({
      action: isChecked ? 'add' : 'remove',
      value: key,
    });
  }, []);

  const isCheckedItem = useCallback((itemKey: string): boolean => (
    typeFilters.includes(itemKey)
  ), [typeFilters]);

  return (
    <div className="CustomerHistoryFilters">
      {/* <div className="CustomerHistoryFilters__search">
        <SearchInput
          onChange={handleChangeSearch}
          placeholder={t('common:search-in-table')}
        />
      </div> */}
      <h4 className="CustomerHistoryFilters__title">{t('common:filters')}</h4>
      <div className="CustomerHistoryFilters__data-type">
        <SelectGroupCheckAll
          isAllChecked={
            isCheckedItem('sent_reminder')
            && isCheckedItem('sent_error_reminder')
            && isCheckedItem('task_processed')
          }
          onChange={handleSelectAllActions}
          title={t('clients:history.data-types.actions')}
          subtitle={t('clients:history.data-types.all-actions')}
          name={t('clients:history.data-types.action-type')}
          withoutTitle
          isDefaultOpen
        >
          <SelectGroupItem
            filterKey="sent_reminder"
            title={t('clients:history.data-types.actions-sent')}
            isChecked={isCheckedItem}
            onCheck={handleSelectItemType}
          />
          <SelectGroupItem
            filterKey="sent_error_reminder"
            title={t('clients:history.data-types.actions-sent-error')}
            isChecked={isCheckedItem}
            onCheck={handleSelectItemType}
          />
          <SelectGroupItem
            filterKey="task_processed"
            title={t('clients:history.data-types.actions-done')}
            isChecked={isCheckedItem}
            onCheck={handleSelectItemType}
          />
        </SelectGroupCheckAll>
        <SelectGroupCheckAll
          isAllChecked={
            isCheckedItem('pay_request')
            && isCheckedItem('payment')
          }
          onChange={handleSelectAllEvents}
          title={t('clients:history.data-types.events')}
          subtitle={t('clients:history.data-types.all-events')}
          name={t('clients:history.data-types.event-type')}
          withoutTitle
          isDefaultOpen
        >
          <SelectGroupItem
            filterKey="pay_request"
            title={t('clients:history.data-types.event-debits')}
            isChecked={isCheckedItem}
            onCheck={handleSelectItemType}
          />
          <SelectGroupItem
            filterKey="payment"
            title={t('clients:history.data-types.event-credits')}
            isChecked={isCheckedItem}
            onCheck={handleSelectItemType}
          />
          <SelectGroupItem
            filterKey="data"
            title={t('clients:history.data-types.event-data')}
            isChecked={isCheckedItem}
            onCheck={handleSelectItemType}
          />
        </SelectGroupCheckAll>
        <SelectGroupCheckAll
          isAllChecked={isCheckedItem('note')}
          onChange={handleSelectNotes}
          title={t('clients:history.data-types.notes')}
          subtitle={t('clients:history.data-types.all-notes')}
          name={''}
          withoutTitle
        />
      </div>
    </div>
  );
};

export default CustomerHistoryFilters;
