import './index.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import type { Client } from 'types/models';
import AddressDetails from 'components/AddressDetails';
import formatIntlDate from 'utils/formatIntlDate';
import organizationStore from 'stores/Organization';
import { getAllCustomerEavs } from 'utils/getCustomerEavs';

type Props = {
  data: Client,
};

const CustomerInfos = ({ data }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { eavs } = organizationStore;

  const attributes = useMemo(() => (
    getAllCustomerEavs(data.eavs, eavs)
  ), [data, eavs]);

  return (
    <dl className="CustomerInfos">
      <div className="CustomerInfos__item">
        <dt className="CustomerInfos__label">
          {t('common:managers')}
        </dt>
        <dd className="CustomerInfos__value">
          {data.users && data.users.length > 0
            ? data.users.map(({ name }) => name).join(', ')
            : '-'}
        </dd>
      </div>
      {data.address1 && data.postalCode && data.city && data.country && (
        <div className="CustomerInfos__item">
          <dt className="CustomerInfos__label">
            {t('common:address')}
          </dt>
          <dd className="CustomerInfos__value">
            <AddressDetails data={data} />
          </dd>
        </div>
      )}
      <div className="CustomerInfos__item">
        <dt className="CustomerInfos__label">
          {t('common:email')}
        </dt>
        <dd className="CustomerInfos__value">
          {data.email || t('common:unknown')}
        </dd>
      </div>
      <div className="CustomerInfos__item">
        <dt className="CustomerInfos__label">
          {t('clients:grouped-reminder')}
        </dt>
        <dd className="CustomerInfos__value">
          {data.useGroup ? t('common:yes') : t('common:no')}
        </dd>
      </div>
      {data.iban && (
        <div className="CustomerInfos__item">
          <dt className="CustomerInfos__label">
            {t('common:iban')}
          </dt>
          <dd className="CustomerInfos__value">
            {data.iban}
          </dd>
        </div>
      )}
      {data.legalId && (
        <div className="CustomerInfos__item">
          <dt className="CustomerInfos__label">
            {t('clients:legal-id')}
          </dt>
          <dd className="CustomerInfos__value">
            {data.legalId}
          </dd>
        </div>
      )}
      {attributes.map(({ id, label, value }) => (
        <div className="CustomerInfos__item" key={id}>
          <dt className="CustomerInfos__label">{label}</dt>
          <dd className="CustomerInfos__value">{value ?? '-'}</dd>
        </div>
      ))}
      {data.createdAt && (
        <div className="CustomerInfos__item">
          <dt className="CustomerInfos__label">
            {t('common:created-at')}
          </dt>
          <dd className="CustomerInfos__value">
            {formatIntlDate(data.createdAt, 'dateLong')}
          </dd>
        </div>
      )}
      {data.updatedAt && (
        <div className="CustomerInfos__item">
          <dt className="CustomerInfos__label">
            {t('common:updated-at')}
          </dt>
          <dd className="CustomerInfos__value">
            {formatIntlDate(data.updatedAt, 'dateLong')}
          </dd>
        </div>
      )}
      <div className="CustomerInfos__item">
        <dt className="CustomerInfos__label">
          {t('clients:dispatch')}
        </dt>
        <dd className="CustomerInfos__value">
          {data.attachment ? t('common:yes') : t('common:no')}
        </dd>
      </div>
    </dl>
  );
};

export default observer(CustomerInfos);
