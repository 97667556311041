import './index.scss';
import { useCallback, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type Errors from 'types/errors';
import type { Note } from 'types/models';
import organizationStore from 'stores/Organization';
import ErrorMessage from 'components/ErrorMessage';
import Loading from 'components/Loading';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import ListNotesItem from './Item';

type Props = {
  notes: Note[],
  error: Errors.Request | null,
  resetError(): void,
  isLoading: boolean,
  onOpenEditModal(id?: number): void,
  showAllLinkTo?: string,
  maxDisplayed?: number,
  withInvoice?: boolean,
  canEdit?: boolean,
};

const ListNotes = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    notes,
    error,
    resetError,
    isLoading,
    onOpenEditModal,
    showAllLinkTo = null,
    maxDisplayed = 3,
    withInvoice = false,
    canEdit = true,
  } = props;
  const { userRight } = organizationStore;
  const isUserReader = userRight === 'ROLE_READER';
  const [showAll, setShowAll] = useState<boolean>(false);

  const handleOpenAll = useCallback(() => {
    setShowAll(true);
  }, []);

  const handleCloseAll = useCallback(() => {
    setShowAll(false);
  }, []);

  const notesCount = notes.length;

  const filteredNotes = useMemo(
    () => notes.slice(0, maxDisplayed),
    [notes, maxDisplayed],
  );

  return (
    <div className="ListNotes">
      <div className="ListNotes__header">
        <h3 className="ListNotes__header__title">
          {t('common:notes')}
        </h3>
        {notesCount > 0 && (
          <span className="ListNotes__header__notes-count">
            {notesCount}
          </span>
        )}
        {(notesCount > 0 && showAllLinkTo) && (
          <Link to={showAllLinkTo} className="ListNotes__header__link">
            {t('common:all-notes')}
            <Icon name="chevron-right-small" className="ListNotes__header__link__icon" />
          </Link>
        )}
        {(!showAllLinkTo && notesCount > maxDisplayed) && (
          <Button
            onClick={handleOpenAll}
            variant="link"
            className="ListNotes__header__link"
          >
            {t('common:all-notes')}
            <Icon name="chevron-right-small" className="ListNotes__header__link__icon" />
          </Button>
        )}
      </div>
      {isLoading && <Loading hasNoText />}
      {error && <ErrorMessage error={error} onClose={resetError} />}
      {!isLoading && !error && (
        <div className="ListNotes__content">
          {notesCount > 0 && (
            <ul className="ListNotes__list">
              {filteredNotes.map((note) => (
                <ListNotesItem
                  key={note.id}
                  data={note}
                  withInvoice={withInvoice}
                  canEdit={!isUserReader && canEdit}
                  onEdit={onOpenEditModal}
                />
              ))}
            </ul>
          )}
          {notesCount === 0 && (
            <p className="ListNotes__no-data">
              {t('notes:no-data')}
            </p>
          )}
          {!isUserReader && (
            <Button
              variant="link"
              onClick={() => { onOpenEditModal(); }}
              className="ListNotes__link-create"
            >
              <Icon name="plus-small" className="ListNotes__link-create__icon" />
              {t('notes:create')}
            </Button>
          )}
          {notesCount > maxDisplayed && showAll && (
            <Modal
              isOpened={showAll}
              onClose={handleCloseAll}
              className="ListNotes__all"
              title={t('common:notes')}
              headerActions={(
                <Button
                  variant="link"
                  onClick={() => { onOpenEditModal(); }}
                  className="ListNotes__all__link-create"
                >
                  <Icon name="plus-small" className="ListNotes__all__link-create__icon" />
                  {t('notes:create')}
                </Button>
              )}
            >
              <ul className="ListNotes__all__list">
                {notes.map((note) => (
                  <ListNotesItem
                    key={note.id}
                    data={note}
                    withInvoice={withInvoice}
                    canEdit={canEdit}
                    onEdit={onOpenEditModal}
                  />
                ))}
              </ul>
            </Modal>
          )}
        </div>
      )}
    </div>
  );
};

export default observer(ListNotes);
