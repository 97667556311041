import './index.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import type Errors from 'types/errors';
import type Misc from 'types/misc';
import FormFieldset from 'components/FormFieldset';
import FormGroupWithAction from 'components/FormGroupWithActions';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import Wysiwyg from 'components/Wysiwyg';
import getI18nChannel from 'utils/getI18nChannel';
import Button from 'components/Button';
import TagLink from 'components/TagLink';
import HistoryStepContentPreview from 'components/HistoryStepContentPreview';
import FormControlSMSContent from 'components/FormControlSMSContent';

type Props = {
  channel: Misc.Channel,
  errors?: Errors.Validation | null,
  defaultData?: Misc.PlanTemplateReminder,
};

const ModalReminderModelForm = (props: Props): JSX.Element => {
  const { errors, channel, defaultData } = props;
  const { t } = useTranslation();
  const [content, setContent] = useState<string>(defaultData?.content || '');

  const [showPreviewContentModal, hidePreviewContentModal] = useModal(() => (
    <HistoryStepContentPreview
      content={content}
      channel={defaultData?.channel || 'EMAIL'}
      onClose={hidePreviewContentModal}
    />
  ), [defaultData, content]);

  return (
    <div className="ModalReminderModelForm">
      <FormFieldset>
        <FormGroup label={t('models:short-description')} error={errors?.name}>
          <FormControl
            type="text"
            name="name"
            autoComplete="off"
            placeholder={t('reminders:placeholder.short-description')}
            defaultValue={defaultData?.name}
            isInvalid={!!errors?.name}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('reminders:description')} error={errors?.description}>
          <FormControl
            type="text"
            name="description"
            autoComplete="off"
            defaultValue={defaultData?.description}
            placeholder={t('reminders:placeholder.description', { channel: t(getI18nChannel(channel)) })}
            isInvalid={!!errors?.description}
          />
        </FormGroup>
      </FormFieldset>
      <div className="ModalReminderModelForm__divider" />
      {channel === 'EMAIL' && (
        <FormFieldset>
          <FormGroup label={t('reminders:email-subject')} error={errors?.subject}>
            <FormControl
              type="text"
              name="subject"
              autoComplete="off"
              defaultValue={defaultData?.subject}
              isInvalid={!!errors?.subject}
              placeholder={t('reminders:placeholder.subject')}
            />
          </FormGroup>
        </FormFieldset>
      )}
      <FormFieldset>
        {channel !== 'SMS' && (
          <FormGroupWithAction
            label={channel === 'EMAIL' ? t('reminders:email-content') : t('reminders:content')}
            mandatory
            action={<TagLink />}
            secondaryAction={(
              <Button
                variant="link"
                onClick={showPreviewContentModal}
                className="FormGroupWithActions__titles__actions__action"
              >
                {t('models:preview')}
              </Button>
            )}
          >
            <Wysiwyg
              name="content"
              defaultValue={content}
              toBePrinted={channel !== 'EMAIL'}
              onChange={setContent}
            />
          </FormGroupWithAction>
        )}
        {channel === 'SMS' && (
          <FormGroupWithAction
            label={t('reminders:content')}
            error={errors?.subject}
            action={<TagLink />}
            secondaryAction={(
              <Button
                variant="link"
                onClick={showPreviewContentModal}
                className="FormGroupWithActions__titles__actions__action"
              >
                {t('models:preview')}
              </Button>
            )}
          >
            <FormControlSMSContent
              name="content"
              value={content}
              onChange={setContent}
              isInvalid={!!errors?.content}
            />
          </FormGroupWithAction>
        )}
      </FormFieldset>
    </div>
  );
};

export default ModalReminderModelForm;
