import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useApiRequest from 'hooks/useApiRequest';
import type Misc from 'types/misc';
import type Errors from 'types/errors';
import authStore from 'stores/Auth';
import organizationStore from 'stores/Organization';
import { ModalFormData } from 'components/ModalForm';
import Alert from 'components/Alert';
import ErrorMessage from 'components/ErrorMessage';
import OrganizationForm from './Form';

const OrganizationCreate: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { post, error, isLoading } = useApiRequest();

  const [validationErrors, setValidationErrors] = React.useState<Errors.Validation | null>(null);

  const handleSubmit = React.useCallback(async (formData: ModalFormData | null) => {
    if (!formData) {
      return;
    }
    const result = await post<Misc.IdName>('/organization/manage/new', formData);
    if (!result || result?.errors) {
      setValidationErrors(result?.errors || null);
      return;
    }
    await authStore.fetchUser();
    organizationStore.setCurrent(result.name);
    history.push('/');
  }, [post, history]);

  return (
    <div className="OrganizationCreate">
      <div className="OrganizationCreate__intro" />
      <div className="OrganizationCreate__content">
        <div className="OrganizationCreate__content__form">
          <h2 className="OrganizationCreate__content__title">{t('organization-create:title')}</h2>
          <p className="OrganizationCreate__content__subtitle">{t('organization-create:subtitle')}</p>
          {(error && error.code === 7) && (
            <Alert variant="error">
              {t('login:error-wrong-credentials')}
            </Alert>
          )}
          {(error && error.code !== 401 && error.code !== 403 && error.code !== 7) && (
            <ErrorMessage error={error} />
          )}
          <OrganizationForm
            onSubmit={handleSubmit}
            isLoading={isLoading}
            errors={validationErrors}
          />
        </div>
      </div>
    </div>
  );
};

export default OrganizationCreate;
