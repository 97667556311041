import './index.scss';
import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import type { Payer } from 'types/models';
import formatIntlNumber from 'utils/formatIntlNumber';
import BrandLogo from 'components/BrandLogo';
import FormSelect from 'components/FormSelect';
import config from 'config';

interface Props {
  invoices: Payer[],
}

const PayerSummary = ({ invoices }: Props): JSX.Element => {
  const { t } = useTranslation();

  const totalAmount = useMemo(() => (
    invoices.map(({ amount }) => amount).reduce((prev, current) => prev + current, 0)
  ), [invoices]);

  const handleChangeLanguage = useCallback((name: string, newValue: string | null) => {
    i18next.changeLanguage(newValue || i18next.language);
  }, []);

  return (
    <div className="PayerSummary">
      <div className="PayerSummary__header">
        <p className="PayerSummary__header__text">{t('payer:powered-by')}</p>
        <BrandLogo />
      </div>
      <div className="PayerSummary__content">
        <h3 className="PayerSummary__content__title">{t('payer:summary')}</h3>
        <hr className="PayerSummary__content__separator" />
        {invoices.map(({ reference, amount, currency }) => (
          <div key={reference}>
            <div className="PayerSummary__content__invoice">
              <div className="PayerSummary__content__invoice__reference">{reference}</div>
              <div className="PayerSummary__content__invoice__amount">
                {formatIntlNumber(amount, currency)}
              </div>
            </div>
            <hr className="PayerSummary__content__separator" />
          </div>
        ))}
        {invoices.length > 0 && (
          <div className="PayerSummary__content__total">
            <p className="PayerSummary__content__total__text">{t('common:total')}</p>
            <p className="PayerSummary__content__total__amount">
              {formatIntlNumber(totalAmount, invoices[0].currency)}
            </p>
          </div>
        )}
        <div className="PayerSummary__content__language">
          <FormSelect
            name="language"
            withClearButton={false}
            selectOptions={config.LANGUAGES}
            onSelect={handleChangeLanguage}
            defaultValue={i18next.language || 'en'}
          />
        </div>
      </div>
    </div>
  );
};

export default PayerSummary;
