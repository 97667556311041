import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import getI18nChannel from 'utils/getI18nChannel';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import useIsMountedRef from 'hooks/useIsMountedRef';
import organizationStore from 'stores/Organization';
import FormSelect from 'components/FormSelect';
import FormGroup from 'components/FormGroup';
import type Misc from 'types/misc';
import config from 'config';

interface Props {
  onClose: () => void,
  onSelectChannel: (channel: Misc.Channel) => void,
  type: 'REMINDER' | 'DISPATCH',
}

const ChooseChannel: React.FC<Props> = ({ onClose, onSelectChannel, type }) => {
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();

  const { currentOrganization } = organizationStore;

  const handleSubmit = React.useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData || !currentOrganization) {
        return;
      }

      if (!isMountedRef.current) {
        return;
      }

      onSelectChannel(formData.channel as Misc.Channel);
      onClose();
    },
    [currentOrganization, isMountedRef, onSelectChannel, onClose],
  );

  const channels = React.useMemo(() => config.CHANNELS_ID.map((channel) => ({
    label: t(getI18nChannel(channel)),
    value: channel,
  })), [t]);

  return (
    <ModalForm
      isOpened
      className="ChooseChannel"
      title={type === 'REMINDER' ? t('reminders:reminder.create') : t('plans:create-dispatch')}
      onSave={handleSubmit}
      onCancel={onClose}
      saveText={t('common:next')}
    >
      <FormGroup label={t('reminders:reminder.select-channel')} className="ChooseDunning__select" mandatory>
        <FormSelect
          name="channel"
          selectOptions={channels}
          defaultValue={channels[0].value}
        />
      </FormGroup>
    </ModalForm>
  );
};

export default observer(ChooseChannel);
