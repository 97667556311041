import './index.scss';
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import type { ReportLineError } from 'types/models';
import type Misc from 'types/misc';
import getI18nReportImportLineError from 'utils/getI18nReportImportLineError';
import getEavTypeLabelFromFormat from 'utils/getEavTypeLabelFromFormat';
import getContactRole from 'utils/getContactRole';

type Props = {
  error: ReportLineError,
  customerReference: string,
  payRequestReference: string,
  type: Misc.ReportItemType,
};

const ImportsReportLineItemErrorText = (props: Props): JSX.Element => {
  const { error, customerReference, payRequestReference, type } = props;
  const { linesOfBusiness, contactRoles } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);
  const { message, field, value, format, possibleValues } = error;

  const reportLineError = useMemo(() => (
    getI18nReportImportLineError(field, value, message, type, !!format || !!possibleValues)
  ), [field, value, message, type, format, possibleValues]);

  return (
    <h5 className="ImportsReportLineItemErrorText">
      {ct(
        reportLineError,
        {
          field,
          client: customerReference || t('common:unknown') as string,
          payrequest: payRequestReference || t('common:unknown') as string,
          value: value || t('common:empty') as string,
          format: t(getEavTypeLabelFromFormat(new RegExp(format))) as string,
          possibleValues,
          contact0: getContactRole('CONTACT_0', contactRoles),
        },
      )}
    </h5>
  );
};

export default observer(ImportsReportLineItemErrorText);
