import { useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import equal from 'deep-equal';
import type { Collaborator, RightsRole } from 'types/models';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import organizationStore from 'stores/Organization';
import type { Category } from 'types/models';
import type { FetchAllParams as FetchAllCategoriesParams } from 'api/categories';
import { fetchAll as fetchAllCategories } from 'api/categories';
import useFetch from 'hooks/useFetch';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import ModalEditCollaboratorsForm from './ModalEditCollaboratorsForm';

type Props = {
  onClose(): void,
  onActionDone(message: string): void,
  onActionError(message: string): void,
  collaborator: Collaborator,
};

const CollaboratorEditModal = (props: Props): JSX.Element => {
  const {
    onClose,
    onActionError,
    onActionDone,
    collaborator,
  } = props;

  const { currentOrganization } = organizationStore;
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const { isLoading, put } = useApiRequest();

  const initialData = useRef<Collaborator | null>(null);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [canSave, setCanSave] = useState(false);
  const [perimeter, setPerimeter] = useState(collaborator.categories);

  const {
    data: categoriesData,
  } = useFetch<FetchAllCategoriesParams, Category[]>(
    {
      cacheKey: 'categories',
      organization: currentOrganization?.reference,
      reloadTick: 0,
    },
    fetchAllCategories,
  );

  const mapFormData = useCallback(
    (rawData: ModalFormData | null): any | null => {
      if (!rawData) {
        return;
      }

      return {
        id: collaborator?.id as number,
        name: collaborator?.name as string,
        email: collaborator?.email as string,
        identifier: rawData?.identifier as string,
        roles: rawData?.roles as RightsRole,
        categories: perimeter.map(({ identifier, values }) => ({ identifier, values: values.map(({ value }) => value) })),
      };
    }, [collaborator, perimeter],
  );

  const handleInit = useCallback((formData: ModalFormData | null) => {
    initialData.current = formData ? mapFormData(formData) : null;
  }, [initialData, mapFormData]);

  const handleChange = useCallback(
    (formData: ModalFormData | null) => {
      if (!formData) {
        return;
      }
      const data = mapFormData(formData);
      if (!data) {
        return;
      }
      setHasChanges(!equal(initialData, collaborator));
    },
    [mapFormData, setHasChanges, initialData, collaborator],
  );

  const handleSubmit = useCallback<(data: ModalFormData | null) => void>(
    async (formData: ModalFormData | null) => {
      const result = await put(`/collaborators/manage/${collaborator.id}`, {
        ...mapFormData(formData),
      });
      const i18nParam = { name: collaborator?.name as string };
      if (!isMountedRef) {
        return;
      }
      if (!result) {
        onActionError(t('collaborators:toast.error.collaborator-edit', i18nParam));
        return;
      }
      organizationStore.refresh();
      onClose();
      onActionDone(t('collaborators:toast.success.collaborator-edit', i18nParam));
    }, [
    onClose,
    onActionDone,
    onActionError,
    t,
    mapFormData,
    collaborator,
    put,
    isMountedRef,
  ]);

  return (
    <ModalForm
      isOpened
      title={t('collaborators:modify-collaborator')}
      isLoading={isLoading}
      onSave={handleSubmit}
      onCancel={onClose}
      onInit={handleInit}
      onChange={handleChange}
      buttonsDisabled={canSave}
      hasWarning={hasChanges}
    >
      {collaborator && (
        <ModalEditCollaboratorsForm
          collaborator={collaborator}
          availableCategories={categoriesData ?? []}
          perimeter={perimeter}
          onCanSave={setCanSave}
          onActionDone={onActionDone}
          onActionError={onActionError}
          onCategoriesUpdate={setPerimeter}
        />
      )}
    </ModalForm>
  );
};

export default observer(CollaboratorEditModal);
