import './index.scss';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageList from 'components/PageList';
import FormSelect from 'components/FormSelect';
import Activity from './Activity';
import Risk from './Risk';
import Finance from './Finance';

const Analysis = (): JSX.Element => {
  const { t } = useTranslation();

  const [category, setCategory] = useState('activity');

  const handleChange = useCallback((name: string, newValue: string | null) => {
    setCategory(newValue as string);
  }, []);

  return (
    <PageList title={t('analysis:title')} className="Analysis">
      <FormSelect
        name="analysisCategory"
        value={category}
        withClearButton={false}
        onSelect={handleChange}
        placeholder={t('analysis:select-category')}
        selectOptions={[
          { label: t('analysis:categories.finance'), value: 'finance' },
          { label: t('analysis:categories.activity'), value: 'activity' },
          { label: t('analysis:categories.risk'), value: 'risk' },
        ]}
      />
      {category === 'finance' && (
        <Finance />
      )}
      {category === 'activity' && (
        <Activity />
      )}
      {category === 'risk' && (
        <Risk />
      )}
    </PageList>
  );
};

export default Analysis;
