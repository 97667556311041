import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import Config from 'config';
import type Misc from 'types/misc';
import type { ImportsHistory as ImportsHistoryType } from 'types/models';
import organizationStore from 'stores/Organization';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import PageList from 'components/PageList';
import DataTable from 'components/DataTable';
import ImportsHistoryFilters from './Filters';
import ImportsHistoryColumns from './Columns';

const ImportsHistory: React.FC = () => {
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();

  const { currentOrganization } = organizationStore;
  const {
    get,
    cancel,
    error,
    resetError,
    isLoading,
  } = useApiRequest();

  const defaultFetchOptions = {
    pageIndex: 0,
    pageSize: Config.DEFAULT_PAGE_SIZE,
    sort: { id: 'file', desc: false },
  };

  const [data, setData] = React.useState<ImportsHistoryType[] | null>(null);
  const [pagination, setPagination] = React.useState<Misc.Pagination | null>(null);

  const lastFetchOptions = React.useRef<Misc.PaginatedFetchArgs<ImportsHistoryType>>();

  const filters = React.useMemo<Misc.FilterDeclarationItem[]>(
    () => ImportsHistoryFilters(t),
    [t],
  );

  const fetchImports = React.useCallback(
    async (options: Misc.PaginatedFetchArgs<ImportsHistoryType>) => {
      const {
        pageIndex,
        pageSize,
        search,
        sort,
        filtering,
      } = options;

      if (!currentOrganization) {
        return;
      }

      lastFetchOptions.current = { ...options };

      cancel();
      const queryString = [
        `recordsPerPage=${pageSize}`,
        `page=${pageIndex + 1}`,
      ];

      if (filtering && filtering.length > 0) {
        filtering.forEach(({ name, value }) => {
          queryString.push(`filters[${name}]=${value}`);
        });
      }

      if (search && search.length > 2) {
        queryString.push(`filters[search]=${search}`);
      }

      if (sort) {
        const direction = sort.desc ? 'desc' : 'asc';
        queryString.push(`orderBy=${sort.id}&orderDirection=${direction}`);
      }

      const response = await get<Misc.Listing<ImportsHistoryType>>(
        `import/history/${currentOrganization.reference}?${queryString.join('&')}`,
      );

      if (isMountedRef.current && response) {
        const { pagination: serverPagination, records } = response;
        setPagination(serverPagination);
        setData(records);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cancel, currentOrganization],
  );

  const columns = React.useMemo<Column<ImportsHistoryType>[]>(
    () => ImportsHistoryColumns(t),
    [t],
  );

  React.useEffect(() => {
    fetchImports(
      lastFetchOptions.current || defaultFetchOptions,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization]);

  return (
    <PageList
      className="ImportsHistory"
      title={t('imports:history')}
    >
      <DataTable<ImportsHistoryType>
        columns={columns}
        data={data}
        serverPagination={pagination}
        defaultSortBy={defaultFetchOptions.sort}
        filtersList={filters}
        fetchData={fetchImports}
        isLoading={isLoading}
        error={error}
        onErrorDissmiss={resetError}
        withActions={false}
      />
    </PageList>
  );
};

export default observer(ImportsHistory);
