import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import Config from 'config';
import authStore from 'stores/Auth';
import type Errors from 'types/errors';
import getI18nUserRights from 'utils/getI18nUserRights';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormSelect from 'components/FormSelect';

interface Props {
  errors?: Errors.Validation | null,
}

const ModalInviteForm: React.FC<Props> = ({ errors }) => {
  const { t } = useTranslation();
  const { user } = authStore;

  const userRightsOptions = React.useMemo(() => (
    Config.USER_RIGHTS.map((right) => ({
      label: t(getI18nUserRights(right)),
      value: right,
    }))
  ), [t]);

  return (
    <div className="ModalInviteForm">
      <FormFieldset>
        <FormGroup label={t('collaborators:add-collaborator')} error={errors?.email}>
          <FormControl
            name="collaboratorEmail"
            autoComplete="off"
            type="email"
            isInvalid={!!errors?.email}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('collaborators:invitation-language')} error={errors?.language}>
          <FormSelect
            name="language"
            placeholder={t('common:please-choose')}
            selectOptions={Config.LANGUAGES}
            isInvalid={!!errors?.language}
            defaultValue={user?.locale}
            isAsync
          />
        </FormGroup>
      </FormFieldset>
      <FormGroup label={t('collaborators:level')}>
        <FormSelect
          name="role"
          className="ModalEditCollaboratorsForm"
          placeholder={t('common:please-choose')}
          selectOptions={userRightsOptions}
        />
      </FormGroup>
    </div>
  );
};

export default observer(ModalInviteForm);
