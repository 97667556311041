export default {
  EMPTY: 0,
  ERROR: 1,
  MISSING: 2,
  DUPLICATED: 3,
  CREATED: 4,
  UPDATED: 5,
  DELETED: 6,
  OK: 7,
};
