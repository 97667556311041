import './index.scss';
import { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import SelectSearch from 'react-select-search';
import type Misc from 'types/misc';
import { fetchCountByStatus, FetchCountForStatusParams } from 'api/pay-requests';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useFetch from 'hooks/useFetch';
import organizationStore from 'stores/Organization';

interface Props {
  refreshTick: number,
  onSetFilters: (status: Misc.PayRequestStatus) => void,
  status: Misc.PayRequestStatus | 'ALL'
}

const PaymentRequestStatusSelect = ({ refreshTick, onSetFilters, status }: Props): JSX.Element => {
  const { currentOrganization, walletSelection, linesOfBusiness } = organizationStore;
  const { ct } = useContextualTranslation(linesOfBusiness);

  const {
    data: countInProgress,
    refetch: refetchInProgress,
    isLoading: isLoadingInProgress,
  } = useFetch<FetchCountForStatusParams, Misc.CountRecords>(
    {
      cacheKey: 'payRequest',
      organization: currentOrganization?.reference,
      categories: walletSelection,
      status: 'IN_PROGRESS',
    },
    fetchCountByStatus,
    { enabled: !!currentOrganization, refetchOnWindowFocus: false },
  );

  const {
    data: countPaid,
    refetch: refetchPaid,
    isLoading: isLoadingPaid,
  } = useFetch<FetchCountForStatusParams, Misc.CountRecords>(
    {
      cacheKey: 'payRequest',
      organization: currentOrganization?.reference,
      categories: walletSelection,
      status: 'PAID',
    },
    fetchCountByStatus,
    { enabled: !!currentOrganization, refetchOnWindowFocus: false },
  );

  const {
    data: countCancelled,
    refetch: refetchCancelled,
    isLoading: isLoadingCancelled,
  } = useFetch<FetchCountForStatusParams, Misc.CountRecords>(
    {
      cacheKey: 'payRequest',
      organization: currentOrganization?.reference,
      categories: walletSelection,
      status: 'CANCELLED',
    },
    fetchCountByStatus,
    { enabled: !!currentOrganization, refetchOnWindowFocus: false },
  );

  const {
    data: countLitigated,
    refetch: refetchLitigated,
    isLoading: isLoadingLitigated,
  } = useFetch<FetchCountForStatusParams, Misc.CountRecords>(
    {
      cacheKey: 'payRequest',
      organization: currentOrganization?.reference,
      categories: walletSelection,
      status: 'LITIGATED',
    },
    fetchCountByStatus,
    { enabled: !!currentOrganization, refetchOnWindowFocus: false },
  );

  useEffect(() => {
    refetchInProgress();
    refetchPaid();
    refetchCancelled();
    refetchLitigated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization, walletSelection, refreshTick]);

  const totalCount = useMemo(() => (
    (countCancelled?.totalRecords || 0)
    + (countLitigated?.totalRecords || 0)
    + (countPaid?.totalRecords || 0)
    + (countInProgress?.totalRecords || 0)
  ), [countCancelled, countInProgress, countLitigated, countPaid]);

  const isLoading = useMemo(() => (
    isLoadingCancelled || isLoadingInProgress || isLoadingLitigated || isLoadingPaid
  ), [isLoadingCancelled, isLoadingInProgress, isLoadingLitigated, isLoadingPaid]);

  const statuses = useMemo(() => [
    {
      value: 'IN_PROGRESS',
      name: ct('common:tabs-status-active'),
      count: countInProgress?.totalRecords,
    },
    {
      value: 'PAID',
      name: ct('common:tabs-status-paid'),
      count: countPaid?.totalRecords,
    },
    {
      value: 'CANCELLED',
      name: ct('common:tabs-status-cancel'),
      count: countCancelled?.totalRecords,
    },
    {
      value: 'LITIGATED',
      name: ct('common:tabs-status-litigated'),
      count: countLitigated?.totalRecords,
    },
    {
      value: 'ALL',
      name: ct('common:tabs-status-all'),
      count: totalCount,
    },
  ], [ct, countInProgress, countPaid, countCancelled, countLitigated, totalCount]);

  const handleChangeStatus = useCallback(
    (newStatus: string | string[]) => {
      onSetFilters(
        (Array.isArray(newStatus) ? newStatus[0] : newStatus) as Misc.PayRequestStatus,
      );
    },
    [onSetFilters],
  );

  const renderStatusValue = useCallback(
    // FIXME: Les types sont à corriger ici
    (valueProps: any, { option }: any, className: string) => (
      <button {...valueProps} className={className} type="button">
        {option?.name}
        <span className="PaymentRequestStatusSelect__count">{option?.count || 0}</span>
      </button>
    ),
    [],
  );

  const renderStatusOption = useCallback(
    // FIXME: Les types sont à corriger ici
    (optionProps: any, { name, count }: any, snapshot: any, className: string) => (
      <button {...optionProps} className={className} type="button">
        {name}
        <span className="PaymentRequestStatusSelect__count">{count}</span>
      </button>
    ),
    [],
  );

  return (
    <>
      {!isLoading && (
        <SelectSearch
          onChange={handleChangeStatus}
          value={status}
          options={statuses}
          search={false}
          multiple={false}
          printOptions="on-focus"
          renderValue={renderStatusValue}
          renderOption={renderStatusOption}
        />
      )}
    </>
  );
};

export default observer(PaymentRequestStatusSelect);
