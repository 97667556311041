import './index.scss';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SelectSearch from 'react-select-search';
import type Misc from 'types/misc';
import { fetchCount, FetchCountParams } from 'api/customers';
import organizationStore from 'stores/Organization';
import useFetch from 'hooks/useFetch';

type Props = {
  refreshTick: number,
  status: Misc.CustomerStatus
  onSetFilters(status: Misc.CustomerStatus): void,
};

const CustomersStatusSelect = (props: Props): JSX.Element | null => {
  const { refreshTick, onSetFilters, status } = props;
  const { currentOrganization, walletSelection } = organizationStore;
  const { t } = useTranslation();

  const {
    isLoading,
    data: counts,
    refetch,
  } = useFetch<FetchCountParams, Misc.ClientCountRecords>(
    {
      cacheKey: 'customersCount',
      organization: currentOrganization?.reference,
      categories: walletSelection,
    },
    fetchCount,
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization, walletSelection, refreshTick]);

  const handleChangeStatus = useCallback(
    (newStatus: string | string[]) => {
      onSetFilters(
        (Array.isArray(newStatus) ? newStatus[0] : newStatus) as Misc.CustomerStatus,
      );
    },
    [onSetFilters],
  );

  const statuses = useMemo(() => [
    {
      value: 'ACTIVE',
      name: t('common:actives'),
      count: counts?.totalActiveClients,
    },
    {
      value: 'ALL',
      name: t('common:all'),
      count: counts?.totalClients,
    },
  ], [t, counts]);

  const renderStatusValue = useCallback(
    // FIXME: Corriger les types quand react-select-search fournira un typage corrects.
    (valueProps: any, { option }: any, className: string) => (
      <button {...valueProps} className={className} type="button">
        {option?.name}
        <span className="CustomersStatusSelect__count">{option?.count || 0}</span>
      </button>
    ),
    [],
  );

  const renderStatusOption = useCallback(
    // FIXME: Corriger les types quand react-select-search fournira un typage corrects.
    (optionProps: any, { name, count }: any, snapshot: any, className: string) => (
      <button {...optionProps} className={className} type="button">
        {name}
        <span className="CustomersStatusSelect__count">{count}</span>
      </button>
    ),
    [],
  );

  if (isLoading) {
    return null;
  }

  return (
    <SelectSearch
      onChange={handleChangeStatus}
      value={status}
      options={statuses}
      search={false}
      multiple={false}
      printOptions="on-focus"
      renderValue={renderStatusValue}
      renderOption={renderStatusOption}
    />
  );
};

export default CustomersStatusSelect;
