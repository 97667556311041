import './index.scss';
import { useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import equal from 'deep-equal';
import type { Category } from 'types/models';
import organizationStore from 'stores/Organization';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import type { ModalFormData } from 'components/ModalForm';
import ModalForm from 'components/ModalForm';
import Confirm from 'components/Confirm';
import type Errors from 'types/errors';
import ErrorMessage from 'components/ErrorMessage';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import ModalCategoryForm from './Form';

type CategoryCall = {
  id: number | null,
  name: string,
  identifier: string,
  values: string[],
};

type Props = {
  onClose(): void,
  onShowToast(message: string, type: ToastStatus): void,
  onReload(): void,
  category?: Category,
  categoryData: Category[] | null,
};

const ModalCreateCategory = (props: Props): JSX.Element => {
  const { onClose, onShowToast, onReload, category, categoryData } = props;
  const { t } = useTranslation();
  const { post, put, error, isLoading } = useApiRequest();
  const { currentOrganization } = organizationStore;
  const isMountedRef = useIsMountedRef();
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Errors.Validation | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);

  const closeSelf = useCallback(() => {
    onClose();
    setShowCancelConfirm(false);
    setHasChanges(false);
  }, [onClose]);
  const initialData = useRef<CategoryCall | null>(null);

  const mapFormDataPut = useCallback(
    (rawData: ModalFormData): any | null => {
      const possibleValues = (rawData.possibleValues as string).split(',');
      const foundCategory = categoryData?.find(({ id }) => category?.id === id);
      if (!foundCategory) {
        return;
      }

      const identifiedValues = possibleValues.map((newValue) => {
        const existingValue = foundCategory.values.find(({ value }) => value === newValue);
        return existingValue ?? { value : newValue };
      });

      return {
        id: parseInt(rawData.id as string),
        name: rawData.name as string,
        identifier: rawData.identifier as string,
        values: identifiedValues,
      };
    },
    [categoryData, category],
  );

  const mapFormDataPost = useCallback(
    (rawData: ModalFormData): any | null => {
      const filteredValues = (rawData.possibleValues as string)
        .split(',')
        .filter((value) => value.length > 0);

      return {
        id: parseInt(rawData.id as string),
        name: rawData.name as string,
        identifier: rawData.identifier as string,
        possibleValues: filteredValues,
      };
    },
    [],
  );

  const handleInit = useCallback((formData: ModalFormData | null) => {
    initialData.current = formData ? mapFormDataPost(formData) : null;
  }, [initialData, mapFormDataPost]);

  const handleChange = useCallback(
    (formData: ModalFormData | null) => {
      if (!formData) {
        return;
      }

      setHasChanges(!equal(
        mapFormDataPost(formData),
        { ...category, values: category?.values.map(({ value }) => value) },
      ));
    },
    [setHasChanges, mapFormDataPost, category],
  );

  const handleCancel = useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirm(true);
    } else {
      closeSelf();
    }
  }, [hasChanges, closeSelf]);


  const handleSubmit = useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData || !currentOrganization) {
        return;
      }

      if (!isMountedRef.current) {
        return;
      }
      const formattedData = !!category ? mapFormDataPut(formData) : mapFormDataPost(formData);
      const url = `category/${currentOrganization.reference}`;
      const doRequest = category ? put : post;
      const result = await doRequest<Category>(url, formattedData);

      if (!result || result?.errors) {
        setValidationErrors(result?.errors || null);
        return;
      }

      onShowToast(
        t(
          category
            ? 'perimeters:toast.success.category-add'
            : 'perimeters:toast.success.category-edit'
          , { name: formData.name }),
        'success',
      );
      onReload();
      onClose();
    },
    [
      currentOrganization,
      onReload,
      onShowToast,
      post,
      category,
      isMountedRef,
      put,
      onClose,
      t,
      mapFormDataPost,
      mapFormDataPut,
    ],
  );

  return (
    <ModalForm
      title={category ? t('perimeters:edit-category') : t('perimeters:create')}
      className="ModalAddPerimeterForm"
      isOpened
      onInit={handleInit}
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
    >
      {error && <ErrorMessage error={error} />}
      <ModalCategoryForm
        errors={validationErrors}
        category={category}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={() => { closeSelf(); }}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(ModalCreateCategory);
