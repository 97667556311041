import './index.scss';
import classNames from 'classnames';
import type Misc from 'types/misc';
import Icon from 'components/Icon';

type Props = {
  channel: Misc.Channel | null,
};

const ChannelIcon = ({ channel }: Props): JSX.Element | null => {
  const className = classNames('ChannelIcon', {
    'ChannelIcon--registered-letter': channel === 'REGISTERED_LETTER',
  });

  if (!channel) {
    return null;
  }

  const channelsIcons = {
    EMAIL: 'email' as const,
    LETTER: 'envelope' as const,
    REGISTERED_LETTER: 'envelope' as const,
    SMS: 'sms' as const,
  };

  return <Icon name={channelsIcons[channel]} className={className} />;
};

export default ChannelIcon;
