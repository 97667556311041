import { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import type Errors from 'types/errors';
import type { Category } from 'types/models';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormBadgeSelect from 'components/FormBadgeSelect';

type Props = {
  errors?: Errors.Validation | null,
  category?: Category,
};

const ModalCategoryForm = (props: Props): JSX.Element => {
  const { category, errors } = props;
  const { t } = useTranslation();
  const [values, setValues] = useState<string[]>(
    () => { return category?.values.map(({ value }) => value) || []; },
  );
  const [identifier, setIdentifier] = useState<string>(category?.identifier || '');
  const [name, setName] = useState<string>(category?.name || '');

  const handlePossibleValuesChange = (possibleValues: string[]) => {
    setValues(possibleValues);
  };

  return (
    <Fragment>
      <div className="ModalCreateCategory">
        <FormControl
            name="id"
            type="hidden"
            value={category?.id}
            readOnly
        />
        <FormFieldset>
          <FormGroup mandatory label={t('perimeters:identifier')} error={errors?.identifier}>
            <FormControl
              name="identifier"
              autoComplete="off"
              type="text"
              isInvalid={!!errors?.identifier}
              value={identifier}
              onChange={setIdentifier}
            />
          </FormGroup>
          <FormGroup mandatory label={t('perimeters:label')} error={errors?.name}>
            <FormControl
              name="name"
              autoComplete="off"
              type="text"
              isInvalid={!!errors?.name}
              value={name}
              onChange={setName}
            />
          </FormGroup>
        </FormFieldset>
        <FormGroup mandatory label={t('perimeters:values')} error={errors?.possibleValues}>
          <FormBadgeSelect
            name="possibleValues"
            initialValues={values}
            onChange={handlePossibleValuesChange}
          />
        </FormGroup>
      </div>
    </Fragment>
  );
};

export default ModalCategoryForm;
