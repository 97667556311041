import './index.scss';
import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import config from 'config';
import CountCharactersHelper from 'components/CountCharactersHelper';
import FormControl from 'components/FormControl';
import type { Props as FormControlProps } from 'components/FormControl';

type Props = FormControlProps & {
  maxCharacters?: number,
};

const FormSMSContent = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    value,
    onChange,
    maxCharacters = config.SMS_MAX_LENGTH,
    placeholder = t<string>('reminders:sms-length', { maxCharacters }),
  } = props;

  const handleChange = useCallback((newValue: string) => {
    if (!onChange) {
      return;
    }
    onChange(newValue.substring(0, maxCharacters));
  }, [maxCharacters, onChange]);

  const charCount = useMemo(() => (
    value?.toString().length ?? 0
  ), [value]);

  return (
    <div className="FormSMSContent">
      <FormControl
        {...props}
        onChange={handleChange}
        placeholder={placeholder}
        type="textarea"
      />
      {maxCharacters && (
        <CountCharactersHelper number={charCount} max={maxCharacters} />
      )}
    </div>
  );
};
export default observer(FormSMSContent);
