import './index.scss';
import React, { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import Config from 'config';
import type Misc from 'types/misc';
import type { PayRequest, CreditNote } from 'types/models';
import organizationStore from 'stores/Organization';
import creditNotesFilters from 'stores/FilterStores/CreditNotesFilters';
import { fetchAll, FetchAllParams } from 'api/credit-notes';
import useFetchPaginated from 'hooks/useFetchPaginated';
import PageList from 'components/PageList';
import FormSelect from 'components/FormSelect';
import DataTable from 'components/DataTable';
import CreditNotesColumns from './Columns';

export interface RouteParams {
  status?: Misc.PayRequestStatus,
  payRequestRef?: string,
}

const CreditNotes: React.FC = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const { currentOrganization } = organizationStore;

  const defaultFetchOptions = {
    pageIndex: 0,
    pageSize: Config.DEFAULT_PAGE_SIZE,
    sort: { id: 'dueAt', desc: true },
  };

  const [fetchOptions, setFetchOptions] = useState<Misc.PaginatedFetchArgs<PayRequest>>(
    defaultFetchOptions,
  );

  const {
    filters,
    addOrUpdateFilters,
    removeFilter,
  } = creditNotesFilters;

  const {
    data,
    serverPagination,
    error,
    isLoading,
  } = useFetchPaginated<FetchAllParams, CreditNote>(
    {
      cacheKey: 'creditNotes',
      organization: currentOrganization?.reference,
      fetchOptions,
      filters,
    },
    fetchAll,
  );

  const columns = useMemo<Column<CreditNote>[]>(
    () => CreditNotesColumns(t),
    [t],
  );

  const handleSelectPaymentRequests = useCallback((name: string, value: string | null) => {
    if (value && value !== '/credit-notes') {
      history.push(value);
    }
  }, [history]);

  return (
    <PageList
      className="CreditNotes"
      count={serverPagination?.totalRecords}
      title={t('credit-notes:title')}
    >
      <DataTable<CreditNote>
        columns={columns}
        data={data}
        statusSelector={(
          <FormSelect
            selectOptions={[
              { label: t('common:payments'), value: '/payments' },
              { label: t('common:credit-notes'), value: '/credit-notes' },
            ]}
            defaultValue="/credit-notes"
            name="select"
            className="CreditNotes__page-select"
            onSelect={handleSelectPaymentRequests}
            withClearButton={false}
          />
        )}
        serverPagination={serverPagination}
        defaultSortBy={defaultFetchOptions.sort}
        fetchData={setFetchOptions}
        filtering={filters}
        onChangeFilters={addOrUpdateFilters}
        onRemoveFilter={removeFilter}
        isLoading={isLoading}
        noDataFoundMessage={t('payment-requests:no-data')}
        error={error}
        withNoDataDrawing
        withActions={false}
      />
    </PageList>
  );
};

export default observer(CreditNotes);
