import './index.scss';
import { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import type { Note } from 'types/models';
import { fetchAllForPayRequest, FetchAllForPayRequestParams } from 'api/notes';
import useFetch from 'hooks/useFetch';
import organizationStore from 'stores/Organization';
import ListNotes from 'components/ListNotes';
import CreateNote from './CreateNote';

type Props = {
  paymentRef: string,
  paymentId: number,
  reloadTick: number,
  onDone(title: string, messsage: string): void,
};

const PaymentRequestNotes = (props: Props): JSX.Element => {
  const { paymentRef, paymentId, reloadTick, onDone } = props;
  const { currentOrganization } = organizationStore;

  const { data: notes, isLoading, refetch, error } = useFetch<FetchAllForPayRequestParams, Note[]>(
    {
      cacheKey: 'notesForPayRequest',
      organization: currentOrganization?.reference,
      paymentId: paymentId.toString(),
    },
    fetchAllForPayRequest,
  );

  const handleOnDone = useCallback((message: string) => {
    refetch();
    onDone(message, '');
  }, [onDone, refetch]);

  const [showEditModal, hideEditModal] = useModal(() => (
    <CreateNote
      onClose={hideEditModal}
      onDone={handleOnDone}
      paymentId={paymentId}
      paymentRef={paymentRef}
    />
  ), [handleOnDone, paymentId, paymentRef]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadTick]);

  return (
    <div className="PaymentRequestSideNotes">
      <ListNotes
        onOpenEditModal={showEditModal}
        notes={notes || []}
        error={error}
        resetError={() => {}}
        isLoading={isLoading}
        maxDisplayed={5}
        canEdit={false}
      />
    </div>
  );
};

export default observer(PaymentRequestNotes);
