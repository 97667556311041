import './index.scss';
import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import type Misc from 'types/misc';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useFetch from 'hooks/useFetch';
import { fetchCount, FetchCountParams } from 'api/pay-requests-draft';
import organizationStore from 'stores/Organization';
import authStore from 'stores/Auth';
import Button from 'components/Button';
import { UserRightsController, UserRightsSwitch } from 'components/UserRights';
import BrandLogo from 'components/BrandLogo';
import Icon from 'components/Icon';
import HeaderNavItem from './Item';

type Props = {
  onClose(): void
};

const HeaderNav = ({ onClose }: Props): JSX.Element => {
  const { pathname } = useLocation();
  const { user } = authStore;
  const { linesOfBusiness, notifications, currentOrganization } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);

  const {
    data: draftCount,
    refetch,
  } = useFetch<FetchCountParams, Misc.CountRecords>(
    {
      cacheKey: 'payRequestDraftCount',
      organization: currentOrganization?.reference,
    },
    fetchCount,
    { enabled: !!currentOrganization?.reference, refetchOnWindowFocus: false },
  );

  useEffect(() => {
    if (currentOrganization?.reference) {
      refetch();
    }
  }, [currentOrganization, notifications, refetch]);

  const isPaymentRequestActive = useMemo(() => (
    pathname.startsWith('/payment-requests')
    || pathname.startsWith('/payment-request')
    || pathname.startsWith('/payments')
  ), [pathname]);

  const isCustomersActive = useMemo(() => (
    pathname.startsWith('/contacts') || pathname.startsWith('/customer')
  ), [pathname]);

  const isActionsActive = useMemo(() => (
    pathname.startsWith('/actions-future') || pathname.startsWith('/actions-done')
  ), [pathname]);

  const hasOrganizations = (user?.organizations && user.organizations.length !== 0);

  return (
    <div className="HeaderNav">
      <div className="HeaderNav__header">
        <BrandLogo />
        <Button variant="outline" className="HeaderNav__close" onClick={onClose}>
          <Icon name="close" />
        </Button>
      </div>
      <ul className="HeaderNav__items">
        <HeaderNavItem
          to="/"
          title={t('common:menu.home')}
          isExact
        />
        <HeaderNavItem
          to="/payment-requests"
          title={ct('common:menu.bills')}
          forceActive={isPaymentRequestActive}
          disabled={!hasOrganizations || !currentOrganization}
          textHover={!currentOrganization ? t('common:menu.please-select-organization') : undefined}
          hasNotification={(draftCount && draftCount.totalRecords > 0) || false}
        />
        <HeaderNavItem
          to="/customers"
          title={ct('common:menu.customers')}
          forceActive={isCustomersActive}
          disabled={!hasOrganizations || !currentOrganization}
          textHover={!currentOrganization ? t('common:menu.please-select-organization') : undefined}
        />
        <div className="HeaderNav__items__separator" />
        <HeaderNavItem
          to="/actions-future"
          title={t('common:menu.actions')}
          forceActive={isActionsActive}
          disabled={!hasOrganizations || !currentOrganization}
          textHover={!currentOrganization ? t('common:menu.please-select-organization') : undefined}
        />
        <UserRightsSwitch>
          <UserRightsController action="READ" category="ANALYSES">
            <HeaderNavItem
              to="/analysis"
              title={t('common:menu.analysis')}
              isExact
              disabled={!hasOrganizations || !currentOrganization}
              textHover={!currentOrganization ? t('common:menu.please-select-organization') : undefined}
            />
          </UserRightsController>
          <UserRightsController displayedWhenNotAllowed action="READ" category="ANALYSES">
            <HeaderNavItem
              to="/analysis"
              title={t('common:menu.analysis')}
              textHover={!currentOrganization ? t('common:menu.please-select-organization') : t('common:not-allowed')}
              isExact
              disabled
            />
          </UserRightsController>
        </UserRightsSwitch>
      </ul>
    </div>
  );
};

export default observer(HeaderNav);
