import type Misc from 'types/misc';

const getI18nBeforeAndAfterDateReference = (
  period: 'before' | 'after',
  dateReference: Misc.PlanDateReference,
): string => {
  if (dateReference === 'invoice-deadline') {
    return (period === 'after')
      ? 'plans:from-reference.after-invoice-deadline'
      : 'plans:from-reference.before-invoice-deadline';
  }
  if (dateReference === 'invoice-oldest-deadline') {
    return (period === 'after')
      ? 'plans:from-reference.after-invoice-oldest-deadline'
      : 'plans:from-reference.before-invoice-oldest-deadline';
  }
  return (period === 'after')
    ? 'plans:from-reference.after-plan-setup'
    : 'plans:from-reference.before-plan-setup';
};

export default getI18nBeforeAndAfterDateReference;
