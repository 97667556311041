import type { TFunction } from 'i18next';
import type Misc from 'types/misc';
import type { OrganizationEavs } from 'types/models';

/**
 * Retourne un label traduit pour une valeur donnée
 *
 * @param value La valeur dont on veut le label
 * @param eavs Les EAVs de l'organisation pour quand la valeur est un customer ou une payRequest
 * @param t La fonctione de traduction
 * @returns Le label (mot ou phrase) traduit pour la valeur donnée.
 */
const getLabelFromIdentifier = (value: string, eavs: OrganizationEavs, t: TFunction) => {
  if (!value || (!value.startsWith('{EAV_') && !value.startsWith('{date_'))) {
    return value;
  }
  if (value.startsWith('{EAV_CLIENT')) {
    const eavName = value.substring(12, value.length - 1);
    return eavs.client.find(({ identifier }) => identifier === eavName)?.label || value;
  }
  if (value.startsWith('{EAV_PAYREQUEST')) {
    const eavName = value.substring(16, value.length - 1);
    return eavs.payRequest.find(({ identifier }) => identifier === eavName)?.label || value;
  }

  if (value.startsWith('{date_') && value.length > 15) {
    const period = (value[6] === '+') ? '+' : '-';
    const slicedAttribute = value.slice(7, -5).split(`months${period}`);
    if (slicedAttribute.length === 2) {
      return [
        (period === '+' ? t('common:within') : t('common:prior-to')),
        slicedAttribute[0],
        t('common:months'),
        t('common:and'),
        slicedAttribute[1],
        t('common:days'),
      ].join(' ');
    }
  }
  return value;
};

/**
 * Transforme les IDS de gestionnaires en noms de gestionnaires
 *
 * @param value Les Ids des gestionnaire (dans une string, séparées par des virgules)
 * @returns Les noms des gestionnaires (dans une string, séparées par des virgules)
 */
const getManagersNames = (value: string, allManagers: Misc.IdRefName[] | null): string => {
  const names = value.split(',')
    .map((managerId) => {
      const manager = allManagers?.find(({ id }: Misc.IdRefName) => id.toString() === managerId);
      return manager?.name ?? '?';
    });
  return names.join(', ');
};

export { getLabelFromIdentifier, getManagersNames };
