import type Misc from 'types/misc';

const getI18nReportStatus = (status: Misc.ReportItemStatus): string => {
  const i18nStatus = {
    IN_PROGRESS: 'reports:status:in-progress',
    FINALIZED: 'reports:status:finalized',
    WAITING: 'reports:status:waiting',
    ERROR: 'reports:status:error',
  };

  return i18nStatus[status];
};

export default getI18nReportStatus;
