import './index.scss';
import { useContext, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import type ResponseError from 'utils/errors';
import formatIntlNumber from 'utils/formatIntlNumber';
import organizationStore from 'stores/Organization';
import customerContext from 'pages/Customer/customerContext';
import Loading from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';
import ScenarioDetails from 'components/ScenarioDetails/index';
import Button from 'components/Button';
import CustomerMuteButton from 'components/CustomerMuteButton';
import ClientName from 'components/ClientName';
import GoBackButton from 'components/GoBackButton';
import CustomerHeaderActions from './Actions';

type Props = {
  isLoading?: boolean,
  error?: ResponseError | null,
  onDone: (title: string, message: string) => void,
  onActionError: (message: string) => void,
};

const CustomerHeader = (props: Props): JSX.Element => {
  const { isLoading = false, error, onActionError, onDone } = props;
  const { t } = useTranslation();
  const { data } = useContext(customerContext);
  const { userRight, currency } = organizationStore;
  const isUserReader = userRight === 'ROLE_READER';
  const scenario = useMemo(() => data?.group?.scenario || data?.scenario || null, [data]);

  const [showScenarioDetails, hideScenarioDetails] = useModal(() => (
    scenario ? (
      <ScenarioDetails
        scenario={scenario}
        onClose={hideScenarioDetails}
      />
    ) : null
  ), [scenario]);

  return (
    <div className="CustomerHeader">
      <div className="CustomerHeader__links">
        <GoBackButton />
      </div>
      <div className="CustomerHeader__infos">
        {isLoading && <Loading />}
        {error && <ErrorMessage error={error} />}
        {!isLoading && data && (
          <>
            <h1 className="CustomerHeader__infos__name">
              <ClientName client={data} />
            </h1>
            <span className="CustomerHeader__infos__reference">
              {data.reference}
            </span>
            {!!scenario && (
              <Button
                variant="link"
                className="CustomerHeader__infos__scenario"
                onClick={showScenarioDetails}
              >
                {scenario.name}
              </Button>
            )}
          </>
        )}
      </div>
      <div className="CustomerHeader__mute">
        {(data && data.mute === true) && (
          <CustomerMuteButton
            customerData={data}
            onActionError={onActionError}
            onActionDone={onDone}
          />
        )}
      </div>
      {data?.statistics && (
        <div className="CustomerHeader__totals">
          <h2 className="CustomerHeader__totals__total">
            {formatIntlNumber(data.statistics.totalToCollect, currency)}
          </h2>
          <div className="CustomerHeader__totals__balance">
            {t('clients:debt-due')}
            <span className="CustomerHeader__totals__amount">
              {formatIntlNumber(data.statistics.totalAmountPayRequestDue, currency)}
            </span>
          </div>
        </div>
      )}
      {!isUserReader && (
        <CustomerHeaderActions onActionError={onActionError} />
      )}
    </div>
  );
};

export default observer(CustomerHeader);
