import type { RightsRole } from 'types/models';

const getI18nUserRights = (right: RightsRole): string => {
  const i18nRights = {
    ROLE_SUPER_ADMIN: 'common:rights.super-admin',
    ROLE_OWNER: 'common:rights.owner',
    ROLE_ADMIN: 'common:rights.admin',
    ROLE_SUPERVISOR: 'common:rights.supervisor',
    ROLE_COORDINATOR: 'common:rights.coordinator',
    ROLE_ACCOUNT_MANAGER: 'common:rights.account-manager',
    ROLE_READER: 'common:rights.reader',
    ROLE_DEMO: 'common:rights.demo',
  };

  return i18nRights[right];
};

export default getI18nUserRights;
