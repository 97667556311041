import './index.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import type Errors from 'types/errors';
import type Misc from 'types/misc';
import type { Payment, PayRequest, PaymentPayRequest, Currency } from 'types/models';
import Config from 'config';
import currenciesStore from 'stores/Currencies';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import Loading from 'components/Loading';
import FormSelect from 'components/FormSelect';
import FormDatePicker from 'components/FormDatePicker';
import getI18nPaymentMethod from 'utils/getI18nPaymentMethod';
import getI18nPaymentType from 'utils/getI18nPaymentType';
import Button from 'components/Button';
import Icon from 'components/Icon';
import FormAmountCurrency from 'components/FormAmountCurrency';
import FormSelectCustomerWithDefault from 'components/FormSelectCustomer/WithDefault';
import PayRequestItem from './PayRequestItem';

type Props = {
  defaultData?: Payment | null,
  customerReference: string | null,
  isCreate?: boolean,
  assignedTo?: string,
  maxAmount?: number,
  payRequestId?: string,
  isLoadingPossiblePayRequests?: boolean,
  possiblePayRequests?: PayRequest[],
  errors?: Errors.Validation | null,
};

const PaymentEditForm = (props: Props): JSX.Element => {
  const {
    isCreate = true,
    defaultData = null,
    customerReference,
    assignedTo,
    maxAmount,
    payRequestId,
    isLoadingPossiblePayRequests = false,
    possiblePayRequests,
    errors = null,
  } = props;
  const { currencies } = currenciesStore;
  const { currency: organizationCurrency, linesOfBusiness } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);

  const [currency, setCurrency] = useState<Misc.CurrencyCode | null>(
    defaultData?.currency || organizationCurrency || null,
  );
  const [amount, setAmount] = useState<number | null>(
    defaultData?.totalAmountPayment || maxAmount || null,
  );
  const [payRequests, setPayRequests] = useState<PaymentPayRequest[]>(
    defaultData?.payRequests || [],
  );

  const oldAmount = useRef<number | null>(amount);

  useEffect(() => {
    if (isCreate || !defaultData) {
      return;
    }

    const { totalAmountPayment, payRequests: defaultPayRequests } = defaultData;
    if (totalAmountPayment && !amount) {
      setAmount(totalAmountPayment);
    }

    if (defaultPayRequests && defaultPayRequests.length > 0) {
      setPayRequests((prevPayRequests) => {
        const filteredPayRequests = defaultPayRequests.filter(({ reference }) => (
          !prevPayRequests.map(({ reference: ref }) => ref).includes(reference)
        ));
        return [...prevPayRequests, ...filteredPayRequests];
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreate, defaultData]);

  useEffect(() => {
    if (!assignedTo) {
      return;
    }
    setPayRequests([{ reference: assignedTo, amountAssigned: amount || '' }]);
  }, [amount, assignedTo]);

  useEffect(() => {
    const hasFirstPayRequestChanged = (
      payRequests.length === 1
      && payRequests[0].amountAssigned === oldAmount.current
      && oldAmount.current !== amount
    );
    if (!hasFirstPayRequestChanged) {
      return;
    }

    const firstPayRequest = {
      reference: payRequests[0]?.reference || '',
      amountAssigned: amount || '',
    };
    setPayRequests([firstPayRequest]);
    oldAmount.current = amount;
  }, [amount, payRequests]);

  const currentCurrency = useMemo(() => (
    currencies?.find((currencyData: Currency) => currencyData.code === currency)
  ), [currencies, currency]);

  const handleChangeAmount = useCallback((newAmount: string) => {
    setAmount(Number.parseFloat(newAmount));
  }, []);

  const handleAddPayRequest = useCallback(() => {
    if (payRequests.length === 0) {
      oldAmount.current = amount;
    }

    const newPayRequest: PaymentPayRequest = {
      reference: '',
      amountAssigned: payRequests.length === 0 ? (amount || '') : 0,
    };
    setPayRequests([...payRequests, newPayRequest]);
  }, [payRequests, amount]);

  const handleRemovePayRequest = useCallback((refOrindex: string | number) => {
    setPayRequests((prevPayRequests) => [...prevPayRequests].filter(
      ({ reference }, index) => {
        if (typeof refOrindex === 'string') {
          return reference !== refOrindex;
        }
        return index !== refOrindex;
      },
    ));
  }, []);

  const handleChangePayRequest = useCallback(
    (value: string, type: string, index: number) => {
      const tmpPayRequest = [...payRequests];

      if (tmpPayRequest && type === 'reference') {
        tmpPayRequest[index].reference = value;
      }

      if (tmpPayRequest && type === 'amountAssigned') {
        tmpPayRequest[index].amountAssigned = value;
      }
      setPayRequests(tmpPayRequest);
    }, [payRequests],
  );

  const paymentTypes = useMemo(() => (
    Config.PAYMENT_TYPES.filter((type) => type !== 'REFUND')
  ), []);

  const suggestedPayRequests = useMemo(() => (
    possiblePayRequests?.filter(({ reference }: PayRequest) => (
      !payRequests.map(({ reference: ref }) => ref).includes(reference)
    )) || []
  ), [possiblePayRequests, payRequests]);

  const handleClickSuggestedPayRequest = useCallback((payRequest: PayRequest) => {
    const { id, reference, amount: amountAssigned } = payRequest;
    const newPayRequest: PaymentPayRequest = { id: `${id}`, reference, amountAssigned };
    setPayRequests([...payRequests, newPayRequest]);
  }, [payRequests]);

  if (!isCreate && !defaultData) {
    return <Loading />;
  }

  const classNames = classnames('PaymentEditForm', {
    'PaymentEditForm--no-customer': !!assignedTo,
    'PaymentEditForm--no-pay-requests': !!assignedTo,
  });

  return (
    <div className={classNames}>
      <FormFieldset>
        <div className="PaymentEditForm__customer">
          {customerReference ? (
            <FormGroup label={ct('common:client')} mandatory error={errors?.client}>
              <FormControl
                name="client"
                autoComplete="off"
                defaultValue={customerReference}
                isInvalid={!!errors?.client}
                readOnly={true}
              />
            </FormGroup>
          ) : (
            <FormGroup label={ct('common:client')} mandatory error={errors?.client}>
              <FormSelectCustomerWithDefault
                name="client"
                customerReference={defaultData?.client.reference || customerReference || undefined}
                isInvalid={!!errors?.client}
              />
            </FormGroup>
          )}
        </div>
        <FormGroup
          label={t('payments:payment-reference')}
          mandatory
          error={errors?.reference}
        >
          <FormControl
            name="reference"
            autoComplete="off"
            defaultValue={defaultData?.reference || ''}
            isInvalid={!!errors?.reference}
            readOnly={!isCreate}
            disabled={!isCreate}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormAmountCurrency
          onChangeAmount={handleChangeAmount}
          onChangeCurrency={setCurrency}
          amount={amount || ''}
          maxAmount={maxAmount}
          defaultCurrency={defaultData?.currency || currentCurrency?.code}
          amountError={errors?.amount || null}
          currencyError={errors?.currency || null}
        />
        <div className="PaymentEditForm__date-type">
          <FormGroup label={t('payments:date')} mandatory error={errors?.paidAt}>
            <FormDatePicker
              name="paidAt"
              defaultValue={defaultData?.paidAt}
              isInvalid={!!errors?.paidAt}
            />
          </FormGroup>
          <FormGroup label={t('payments:payment-type')} mandatory error={errors?.type}>
            <FormSelect
              name="type"
              defaultValue={defaultData?.type || 'PAYMENT'}
              placeholder={t('common:please-choose')}
              selectOptions={paymentTypes.map((value) => (
                { value, label: t(getI18nPaymentType(value)) }
              ))}
              isInvalid={!!errors?.type}
              withClearButton={false}
            />
          </FormGroup>
        </div>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('common:label')} error={errors?.subject}>
          <FormControl
            name="subject"
            autoComplete="off"
            defaultValue={defaultData?.subject || ''}
            placeholder={t('payments:example-subject')}
            isInvalid={!!errors?.subject}
          />
        </FormGroup>
        <FormGroup label={t('payments:payment-method')} mandatory error={errors?.mean}>
          <FormSelect
            name="mean"
            defaultValue={defaultData?.mean}
            placeholder={t('common:please-choose')}
            selectOptions={Config.PAYMENT_METHODS.map((value) => (
              { value, label: t(getI18nPaymentMethod(value)) }
            ))}
            isInvalid={!!errors?.mean}
          />
        </FormGroup>
      </FormFieldset>
      {!assignedTo && (
        <div className="PaymentEditForm__imputation">
          <div className="PaymentEditForm__imputation__title">
            {t('payments:imputation')}
          </div>
          {!!errors?.payRequests && (
            <p className="PaymentEditForm__imputation__error">
              {ct('payments:please-choose-at-least-one-invoice')}
            </p>
          )}
          <Button variant="link" onClick={handleAddPayRequest}>
            <Icon name="plus-circle" />
          </Button>
        </div>
      )}
      <div className="PaymentEditForm__pay-requests">
        <div className="PaymentEditForm__pay-requests__suggestions">
          {isLoadingPossiblePayRequests && <Loading />}
          {suggestedPayRequests.length > 0 && (
            <>
              <p className="PaymentEditForm__pay-requests__suggestions__title">
                {ct('common:suggested-invoices')} ({t('common:click-to-add')})
              </p>
              <div className="PaymentEditForm__pay-requests__suggestions__list">
                {suggestedPayRequests.map((payRequest) => (
                  <Button
                    key={payRequest.id}
                    variant="outline"
                    small
                    onClick={() => { handleClickSuggestedPayRequest(payRequest); }}
                  >
                    {payRequest.reference}
                  </Button>
                ))}
              </div>
            </>
          )}
        </div>
        {payRequests.map((payRequest, index) => (
          <PayRequestItem
            key={payRequest.reference || payRequest.id || index}
            customerReference={customerReference}
            index={index}
            defaultValue={payRequest}
            amount={payRequest.amountAssigned}
            payRequestId={payRequestId}
            currentCurrency={currentCurrency}
            onChangePayRequest={handleChangePayRequest}
            onRemovePayRequest={handleRemovePayRequest}
            errors={errors}
          />
        ))}
      </div>
    </div>
  );
};

export default observer(PaymentEditForm);
