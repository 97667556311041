import './index.scss';
import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import type Errors from 'types/errors';
import type Misc from 'types/misc';
import type { ModelPost } from 'types/models';
import getI18nChannel from 'utils/getI18nChannel';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import { checkIsAllowed } from 'components/UserRights';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import organizationStore from 'stores/Organization';
import userRightsStore from 'stores/UserRights';
import Confirm from 'components/Confirm';
import ModalReminderModelForm from './Form';

type Props = {
  onDone(message: string): void,
  onError(message: string): void,
  onClose(): void,
  channel: Misc.Channel,
  edit?: boolean,
  defaultData?: Misc.PlanTemplateReminder,
};

const ModalReminderModel = (props: Props): JSX.Element => {
  const { onDone, onError, onClose, channel, edit = false, defaultData } = props;
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const { currentOrganization } = organizationStore;
  const { rights } = userRightsStore;

  const [validationErrors, setValidationErrors] = useState<Errors.Validation | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);

  const { post, put, remove, isLoading, error } = useApiRequest();

  const closeSelf = useCallback(() => {
    if (!isMountedRef.current) {
      return;
    }

    setShowCancelConfirm(false);
    setValidationErrors(null);
    onClose();
  }, [isMountedRef, onClose]);

  const mapFormData = useCallback((rawData: ModalFormData): ModelPost => ({
    channel,
    name: rawData.name as string,
    description: rawData.description as string,
    subject: rawData.subject as string || rawData.name as string,
    content: rawData.content as string,
    default: true,
    day: 0,
    type: 'reminder',
    identifier: defaultData?.identifier || rawData.name as string,
    contacts: [],
    needsValidation: false,
  }), [channel, defaultData]);

  const handleSubmit = useCallback(async (formData: ModalFormData | null) => {
    if (!formData || !currentOrganization) {
      return;
    }

    const url = `organization/templates/${currentOrganization.reference}`;
    const data = mapFormData(formData);

    const result = edit ? await put(url, data) : await post(url, data);

    if (!isMountedRef.current) {
      return;
    }

    if (!result) {
      return;
    }

    if (result.errors) {
      setValidationErrors(result.errors);
      return;
    }

    closeSelf();
    onDone(t(
      edit ? 'models:toast.success.edit-model' : 'models:toast.success.create-model',
      { name: formData.name as string },
    ));
  }, [closeSelf, currentOrganization, edit, isMountedRef, mapFormData, onDone, post, put, t]);

  const handleDelete = useCallback(async () => {
    setShowDeleteConfirm(false);
    if (!currentOrganization) {
      return;
    }

    const response = await remove(
      `organization/templates/${currentOrganization.reference}/reminder?identifier=${defaultData?.identifier}`,
    );
    if (isMountedRef.current && response) {
      onDone(t('models:toast.success.delete-model', { name: defaultData?.name }));
      return;
    }
    onError(t('models:toast.error.delete-model', { name: defaultData?.name }));
  }, [currentOrganization, defaultData, isMountedRef, onDone, onError, remove, t]);

  return (
    <ModalForm
      isOpened
      className="ModalReminderModel"
      title={t('models:reminder-using-channel', { channel: t(getI18nChannel(channel)) })}
      onSave={handleSubmit}
      onCancel={closeSelf}
      buttonsDisabled={rights === null || !checkIsAllowed('UPDATE', 'PREFERENCES_MODELS')}
      isLoading={isLoading}
      hasDeleteButton={edit}
      onDelete={() => { setShowDeleteConfirm(true); }}
    >
      {error && (<ErrorMessage error={error} />)}
      <ModalReminderModelForm
        channel={channel}
        errors={validationErrors}
        defaultData={defaultData}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={closeSelf}
        onCancel={() => { setShowCancelConfirm(false); }}
      />
      <Confirm
        titleModal={t('common:remove')}
        text={t('models:confirm-delete')}
        variant="danger"
        confirmButtonText={t('common:remove')}
        cancelButtonText={t('common:cancel')}
        entityName={defaultData?.name}
        isShow={showDeleteConfirm}
        onConfirm={handleDelete}
        onCancel={() => { setShowDeleteConfirm(false); }}
      />
    </ModalForm>
  );
};

export default observer(ModalReminderModel);
