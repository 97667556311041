import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Badge from 'components/Badge';
import type Misc from 'types/misc';
import getI18nStatusImport from 'utils/getI18nStatusImport';
import organizationStore from 'stores/Organization';

type Props = {
  status: Misc.StatusImport,
  format: Misc.ReportItemFormat,
};

const ImportStatus = ({ status, format }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { isSageOrganization } = organizationStore;

  // TODO: À retirer quand l'API sera corrigé
  const importStatus = useMemo(() => {
    if (isSageOrganization && format === 'pdf') {
      return 'FILE_PROCESSED';
    }
    return status;
  }, [format, isSageOrganization, status]);

  return (
    <div className="ImportStatus">
      <Badge variant={importStatus}>
        {t(getI18nStatusImport(importStatus))}
      </Badge>
    </div>
  );
};

export default observer(ImportStatus);
