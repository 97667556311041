import type Misc from 'types/misc';

const getI18nOrganizationSector = (organizationSector: Misc.OrganizationSectors): string => {
  const i18nOrganizationSectors = {
    'marketing-creative-agencies': 'common:organization-sector.marketing-creative-agencies',
    'software-internet': 'common:organization-sector.software-internet',
    education: 'common:organization-sector.education',
    health: 'common:organization-sector.health',
    'real-estate-construction': 'common:organization-sector.real-estate-construction',
    'financial-services': 'common:organization-sector.financial-services',
    'media-publishing': 'common:organization-sector.media-publishing',
    industries: 'common:organization-sector.industries',
    consulting: 'common:organization-sector.consulting',
    'retail-wholesale': 'common:organization-sector.retail-wholesale',
    other: 'common:organization-sector.other',
  };

  return i18nOrganizationSectors[organizationSector];
};

export default getI18nOrganizationSector;
