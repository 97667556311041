import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import getI18nActionType from 'utils/getI18nActionType';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import organizationStore from 'stores/Organization';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import FormSelect from 'components/FormSelect';
import FormGroup from 'components/FormGroup';
import type Misc from 'types/misc';

interface Props {
  onClose: () => void,
  onSelectType: (planType: Misc.ActionType) => void,
}

const ChooseType: React.FC<Props> = ({ onClose, onSelectType }) => {
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();

  const { currentOrganization } = organizationStore;

  const [hasChanges, setHasChanges] = React.useState<boolean>(false);

  const { isLoading } = useApiRequest();

  const closeSelf = React.useCallback(() => {
    if (!isMountedRef.current) {
      return;
    }

    setHasChanges(false);
    onClose();
  }, [isMountedRef, onClose]);

  const handleSubmit = React.useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData || !currentOrganization) {
        return;
      }

      if (!isMountedRef.current) {
        return;
      }

      onSelectType(formData.type as Misc.ActionType);
      closeSelf();
    },
    [currentOrganization, isMountedRef, onSelectType, closeSelf],
  );

  const types = React.useMemo(() => ['DISPATCH', 'TASK', 'REMINDER'].map((planType) => ({
    label: t(getI18nActionType(planType as Misc.ActionType)),
    value: planType,
  })), [t]);

  return (
    <ModalForm
      isOpened
      className="ChooseType"
      title={t('plans:add-action')}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={closeSelf}
      isLoading={isLoading}
      saveText={t('common:next')}
    >
      <FormGroup
        label={t('reminders:reminder.select-type')}
        className="ChooseType__select"
        mandatory
      >
        <FormSelect
          name="type"
          selectOptions={types}
          defaultValue={types[0].value}
        />
      </FormGroup>
    </ModalForm>
  );
};

export default observer(ChooseType);
