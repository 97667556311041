import './index.scss';
import { Fragment, useCallback, useState } from 'react';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import FormCheck from 'components/FormCheck';
import Icon from 'components/Icon';
import Button from 'components/Button';

type Props = {
  name: string,
  title: string,
  subtitle: string,
  onChange: (isChecked: boolean) => void,
  isAllChecked: boolean,
  withoutTitle?: boolean,
  withSpacing?: boolean,
  isDefaultOpen?: boolean,
  children?: ReactNode,
};

const SelectGroupCheckAll = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    name,
    title,
    subtitle,
    onChange,
    isAllChecked,
    withoutTitle = false,
    withSpacing = withoutTitle,
    isDefaultOpen = false,
    children,
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(isDefaultOpen);

  const handleIsOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const classnames = classNames('SelectGroupCheckAll', {
    'SelectGroupCheckAll--is-alone': withSpacing,
  });

  return (
    <div className={classnames}>
      {!withoutTitle && (
        <h4 className="SelectGroupCheckAll__title">{t('common:status')}</h4>
      )}
      <div className="SelectGroupCheckAll__checkbox">
        <div className="SelectGroupCheckAll__checkbox__check">
          <FormCheck
            id="all"
            onChange={onChange}
            checked={isAllChecked}
          />
          <div>
            <h4 className="SelectGroupCheckAll__checkbox__check__title">{title}</h4>
            <p className="SelectGroupCheckAll__checkbox__check__subtitle">{subtitle}</p>
          </div>
          {children && (
            <Button variant="list" onClick={handleIsOpen}>
              <Icon name={isOpen ? 'chevron-up' : 'chevron-down'} />
            </Button>
          )}
        </div>
        {(isOpen && children) && (
          <Fragment>
            <p className="SelectGroupCheckAll__checkbox__name">{name}</p>
            <div className="SelectGroupCheckAll__checkbox__items">
              {children}
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default SelectGroupCheckAll;
