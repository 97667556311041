import React from 'react';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import Button from 'components/Button';
import type Misc from 'types/misc';
import useApiRequest from 'hooks/useApiRequest';
import InputModal from 'components/InputModal';
import Icon from 'components/Icon';

interface Props {
  id: number,
  reference: string,
  onLoadingChange: (isLoading: boolean) => void,
  onDone: (title: string, message: string) => void,
}

const RowActionNote: React.FC<Props> = (props) => {
  const { linesOfBusiness } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);

  const {
    id,
    reference,
    onLoadingChange,
    onDone,
  } = props;

  const { post, error, isLoading } = useApiRequest();

  const handleSubmit = React.useCallback(
    async (inputModalValue: { [field: string]: string }) => {
      const result = await post<Misc.IdRef>(
        `pay-requests/manage/${id}/note`,
        inputModalValue,
      );

      if (!result) {
        return;
      }

      onDone(
        t('notes:toast.created'),
        ct('notes:toast.created-invoice', { reference }),
      );
    },
    [post, id, onDone, t, ct, reference],
  );

  React.useEffect(
    () => { onLoadingChange(isLoading); },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading],
  );

  const [showInputModal, hideInputModal] = useModal(() => (
    <InputModal
      isShow
      title={t('payment-requests:add-note')}
      help={ct('payment-requests:add-note-for-ref', { reference })}
      type="textarea"
      name="message"
      isLoading={isLoading}
      error={error}
      onSubmit={(field) => {
        handleSubmit(field);
        hideInputModal();
      }}
      onCancel={hideInputModal}
    />
  ), [t, ct, reference, isLoading, error, handleSubmit]);

  return (
    <Button
      variant="list"
      onClick={showInputModal}
      className="RowActionNote"
    >
      <Icon name="comment" />
      {t('payment-requests:add-note')}
    </Button>
  );
};

export default observer(RowActionNote);
