import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import type { TFunction } from 'i18next';
import type { Column } from 'react-table';
import type Misc from 'types/misc';
import type { Client } from 'types/models';
import recomposeName from 'utils/recomposeName';
import formatIntlNumber from 'utils/formatIntlNumber';
import ClientName from 'components/ClientName/index';
import { UserRightsManagerController } from 'components/UserRights';
import ListHistorySummary from 'components/ListHistorySummary';
import { ExternallyMonitoredController } from 'components/ExternallyMonitored';
import RowActions from '../Actions/Row';

const CustomersColumns = (
  ct: TFunction,
  currency: Misc.CurrencyCode | null,
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
): Column<Client>[] => [
  {
    Header: ct<string>('common:client'),
    accessor: 'reference',
    id: 'reference',
    Cell: ({ value, row }) => (
      <div className="Customers__columns__name">
        <Link to={`/customer/${row.original.id}`}>
          <ClientName client={row.original} />
        </Link>
        {row.original.corporateName !== value && (
          <div className="Customers__columns__ref">{value}</div>
        )}
      </div>
    ),
  },
  {
    Header: ct<string>('common:total-outstanding-amount'),
    accessor: 'statistics',
    id: 'totalToCollect',
    sortType: 'basic',
    sortDescFirst: true,
    Cell: ({ value }) => (
      <div className="Customers__columns__in-progress">
        {formatIntlNumber(value?.totalToCollect, currency)}
        <div className="Customers__columns__count">
          {ct(
            'common:bills-count',
            { count: (value?.nbPayRequestDue || 0) + (value?.nbPayRequestNotDue || 0) },
          )}
        </div>
      </div>
    ),
  },
  {
    Header: ct<string>('common:late'),
    accessor: 'statistics',
    id: 'totalAmountPayRequestDue',
    sortType: 'basic',
    sortDescFirst: true,
    Cell: ({ value }) => (
      <div className="Customers__columns__late">
        {formatIntlNumber(value?.totalAmountPayRequestDue, currency)}
        <div className="Customers__columns__count">
          {ct('common:bills-count', { count: value?.nbPayRequestDue })}
        </div>
      </div>
    ),
  },
  {
    Header: ct<string>('common:evolution'),
    accessor: 'statistics',
    id: 'progression',
    sortType: 'basic',
    sortDescFirst: true,
    Cell: ({ value }) => <Fragment>{value?.progression || 0} %</Fragment>,
  },
  {
    Header: ct<string>('common:total-collected'),
    accessor: 'statistics',
    id: 'totalCollected',
    sortType: 'basic',
    sortDescFirst: true,
    Cell: ({ value }) => (
      <Fragment>
        {formatIntlNumber(value?.totalCollected, currency)}
      </Fragment>
    ),
  },
  {
    Header: ct<string>('common:next-action'),
    accessor: 'statistics',
    disableSortBy: true,
    Cell: ({ value }) => {
      const { nextSendPr, lastSentPr } = value || { nextSendPr: null, lastSentPr: null };
      return (
        <ListHistorySummary data={{ nextSending: nextSendPr, lastSending: lastSentPr }} />
      );
    },
  },
  {
    Header: ct<string>('common:commands'),
    accessor: 'id',
    disableSortBy: true,
    Cell: ({ row, value: id }) => (
      <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
        <UserRightsManagerController action="UPDATE" managers={row.original.users} categoryType="CLIENTS">
          <RowActions
            id={id}
            name={recomposeName(row.original) ?? ''}
            lastNote={row.original.lastCustomerNote ?? null}
            customerData={row.original}
            onActionDone={onActionDone}
            onActionError={onActionError}
          />
        </UserRightsManagerController>
      </ExternallyMonitoredController>
    ),
  },
];

export default CustomersColumns;
