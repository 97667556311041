import React from 'react';
import { observer } from 'mobx-react';
import type { Contact } from 'types/models';
import type { FetchAllForListContactParams } from 'api/contacts';
import { fetchAllForListContact } from 'api/contacts';
import organizationStore from 'stores/Organization';
import ListContacts from 'components/ListContacts';
import useFetch from 'hooks/useFetch';

interface Props {
  nbContacts?: number,
  customerId: number,
}

const PaymentRequestContacts: React.FC<Props> = ({ nbContacts, customerId }) => {
  const { currentOrganization } = organizationStore;

  const {
    data: contacts,
    refetch,
    error,
    isLoading,
  } = useFetch<FetchAllForListContactParams, Contact[]>(
    {
      cacheKey: 'payRequest',
      organization: currentOrganization?.reference,
      customerId,
    },
    fetchAllForListContact,
  );

  if (!customerId) {
    return null;
  }

  return (
    <ListContacts
      customerId={customerId}
      nbContacts={nbContacts}
      contacts={contacts}
      fetchContacts={refetch}
      error={error}
      isLoading={isLoading}
    />
  );
};

export default observer(PaymentRequestContacts);
