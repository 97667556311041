import './index.scss';
import React from 'react';
import type Misc from 'types/misc';
import useContextualTranslation from 'hooks/useContextualTranslation';
import organizationStore from 'stores/Organization';
import ImportsFileWizardStepChoiceTypeButton from './Button';

interface Props {
  onSelectType: (choice: Misc.TypeImport) => () => void,
  type: 'pdf' | 'csv-xlsx',
  choices: Misc.ImportWizardChoices | null,
}

const ImportsFileWizardStepChoiceType: React.FC<Props> = ({ onSelectType, type, choices }) => {
  const { linesOfBusiness } = organizationStore;

  const { t, ct } = useContextualTranslation(linesOfBusiness);

  const types = React.useMemo(() => {
    if (type === 'csv-xlsx') {
      return [
        {
          value: 'CLIENT' as Misc.TypeImport,
          title: t('imports:type-import.client'),
          active: choices?.type === 'CLIENT',
        },
        {
          value: 'CONTACT' as Misc.TypeImport,
          title: t('imports:type-import.contact'),
          active: choices?.type === 'CONTACT',
        },
        {
          value: 'PAY_REQUEST_CREATE' as Misc.TypeImport,
          title: ct('imports:type-import.pay-request-create'),
          breakLine: true,
          active: choices?.type === 'PAY_REQUEST_CREATE',
        },
        {
          value: 'PAY_REQUEST_UPDATE' as Misc.TypeImport,
          title: ct('imports:type-import.pay-request-update'),
          active: choices?.type === 'PAY_REQUEST_UPDATE',
        },
        {
          value: 'CLIENT_CONTACT' as Misc.TypeImport,
          title: t('imports:type-import.client-contact'),
          active: choices?.type === 'CLIENT_CONTACT',
        },
        {
          value: 'PAYMENT' as Misc.TypeImport,
          title: t('imports:type-import.payment'),
          active: choices?.type === 'PAYMENT',
        },
      ];
    }

    return [
      {
        value: 'PAY_REQUEST_CREATE' as Misc.TypeImport,
        title: t('imports:type-import.pay-request-create'),
        active: choices?.type === 'PAY_REQUEST_CREATE',
      },
    ];
  }, [t, ct, type, choices]);

  return (
    <div className="ImportsFileWizardStepChoiceType">
      {types.map((
        {
          value,
          title,
          breakLine,
          active,
        },
      ) => (
        <ImportsFileWizardStepChoiceTypeButton
          key={value}
          value={value}
          title={title}
          onSelectType={onSelectType}
          breakLine={breakLine}
          active={active}
        />
      ))}
    </div>
  );
};

export default ImportsFileWizardStepChoiceType;
