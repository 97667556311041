import type { TFunction } from 'i18next';
import type { HistoryStep } from 'types/models';
import getHistoryStepType from 'utils/getHistoryStepType';
import parseIsoDate from 'utils/parseIsoDate';
import formatIntlDate from 'utils/formatIntlDate';

const getI18nHistoryStepDate = (
  t: TFunction,
  data: HistoryStep,
  shouldChangePlannedToValidated: boolean = false,
): string => {
  const type = getHistoryStepType(data);

  const getIntlFormat = (dateReference: string) => {
    const yearNow = new Date().getFullYear();
    return parseIsoDate(dateReference)?.getFullYear() !== yearNow ? 'dayMonthYearShort' : 'dayMonthShort';
  };

  if (data.remove !== null) {
    // - Tâche annulée
    if (type === 'task') {
      return t('common:task-remove-at-date', {
        date: formatIntlDate(data.remove.at, getIntlFormat(data.remove.at)),
      });
    }

    // - Relance annulée
    return t('common:remove-at-date', {
      date: formatIntlDate(data.remove.at, getIntlFormat(data.remove.at)),
    });
  }

  // - Relance mise en silencieux
  if (data.mute !== null) {
    return t('common:done-at-date', {
      date: formatIntlDate(data.mute.at, getIntlFormat(data.mute.at)),
    });
  }

  // - Relance n'étant plus en silencieux
  if (data.unmute !== null) {
    return t('common:done-at-date', {
      date: formatIntlDate(data.unmute.at, getIntlFormat(data.unmute.at)),
    });
  }

  if (type === 'reminder') {
    const isValidated = (
      shouldChangePlannedToValidated
      && data.doneAt === null
      && data.validation !== null
    );

    // - Relance validée
    if (isValidated) {
      return t('common:validated-at-date', {
        date: formatIntlDate(data.validation?.at, getIntlFormat(data.validation?.at || '')),
      });
    }

    // - Relance envoyée
    if (data.doneAt !== null) {
      return t('common:sent-at-date', {
        date: formatIntlDate(data.doneAt, getIntlFormat(data.doneAt)),
      });
    }
  }

  // - Tâche réalisée
  if (type === 'task' && data.validation !== null) {
    return t('common:task-done-at-date', {
      date: formatIntlDate(data.validation.at, getIntlFormat(data.validation.at)),
    });
  }

  if (['reminder', 'task'].includes(type)) {
    return t('common:planned-at-date', {
      date: formatIntlDate(data.at.at, getIntlFormat(data.at.at)),
    });
  }

  return formatIntlDate(data.at.at, getIntlFormat(data.at.at));
};

export default getI18nHistoryStepDate;
