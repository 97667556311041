import type { ReportItem } from 'types/models';
import type Misc from 'types/misc';

const extractReport = (data: Misc.ReportsDataObject | null, format: Misc.ReportItemFormat) => {
  if (!data || !data[format] || data[format].length === 0) {
    return [];
  }

  const reports = data[format][0];
  const results: ReportItem[] = [];

  // TODO: À modifier quand la réponse de l'API sera améliorée et corrigée
  Object.keys(reports).forEach((date) => {
    reports[date].forEach((report) => {
      const dataTypes = Object.keys(report) as Misc.ReportItemType[];
      dataTypes.forEach((type) => {
        const reportItem = report[type];
        results.push({ ...reportItem, type, format, date });
      });
    });
  });

  return results;
};

export default extractReport;
