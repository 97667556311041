import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { MouseEvent } from 'react';
import queryString from 'query-string';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import Button from 'components/Button';
import type { HistoryStep, Contact } from 'types/models';
import getHistoryStepType from 'utils/getHistoryStepType';
import useApiRequest from 'hooks/useApiRequest';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import Loading from 'components/Loading';
import Icon from 'components/Icon';
import ContactEditModal from 'components/ContactEditModal';

type Props = {
  data: HistoryStep,
  isMissingContact?: boolean,
  isRemoved?: boolean,
  customerId?: number,
  onActionDone(message: string): void,
  onActionError(message: string): void,
  onShowEdit(): void,
};

const HistoryStepActions = (props: Props): JSX.Element => {
  const { linesOfBusiness, currentOrganization } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);
  const {
    data,
    isMissingContact = false,
    isRemoved = false,
    customerId,
    onActionDone,
    onActionError,
    onShowEdit,
  } = props;

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const { put, error, isLoading, get } = useApiRequest();

  const [confirmVariant, setConfirmVariant] = useState<'primary' | 'danger'>();
  const [confirmButtonText, setConfirmButtonText] = useState<string>();
  const [cancelButtonText, setCancelButtonText] = useState<string | undefined>(undefined);
  const [action, setAction] = useState<'activate' | 'validate' | 'remove'>();
  const [contactToEdit, setContactToEdit] = useState<number | null>(null);

  const type = useMemo(() => getHistoryStepType(data), [data]);
  const { id, name, group, payRequest, customer, missingContactRoles } = data;

  const handleShowCancel = useCallback(() => {
    setConfirmVariant('danger');
    setConfirmButtonText(t<string>('clients:actions.confirm.history.title.remove-reminder'));
    setCancelButtonText(t<string>('common:keep-as-is'));
    setAction('remove');
    if (type === 'reminder') {
      showConfirm(
        t('clients:actions.confirm.history.title.remove-reminder'),
        ct('clients:actions.confirm.history.remove-reminder'),
      );
    } else {
      showConfirm(
        t('clients:actions.confirm.history.title.remove'),
        ct('clients:actions.confirm.history.remove'),
      );
    }
  }, [showConfirm, t, ct, type]);

  const handleConfirmValidate = useCallback(() => {
    setConfirmVariant('primary');
    setConfirmButtonText(t<string>('common:validate'));
    setAction('validate');
    showConfirm(
      t('clients:actions.confirm.history.title.validate'),
      ct('clients:actions.confirm.history.validate'),
    );
  }, [showConfirm, t, ct]);

  const handleConfirmActivate = useCallback(() => {
    setConfirmVariant('primary');
    setConfirmButtonText(t<string>('common:validate'));
    setAction('activate');
    showConfirm(
      t('clients:actions.confirm.history.title.activate'),
      ct('clients:actions.confirm.history.activate'),
    );
  }, [showConfirm, t, ct]);

  const displayedReference = useMemo(() => {
    if (group) {
      return t('clients:group-of-reminders');
    }
    return payRequest?.reference;
  }, [t, group, payRequest]);

  const handleSubmit = useCallback(async () => {
    hideConfirm();

    if (!action) {
      throw new Error('Action non définie.');
    }

    const messageProps = {
      name,
      reference: displayedReference,
    };

    const actions = {
      activate: {
        url: `task/manage/${id}/activate`,
        onDoneText: ct('clients:actions.toast.history.activate', messageProps),
      },
      validate: {
        url: `task/manage/${id}/activate`,
        onDoneText: ct('clients:actions.toast.history.validate', messageProps),
      },
      remove: {
        url: `task/manage/${id}/remove`,
        onDoneText: ct('clients:actions.toast.history.remove', messageProps),
      },
    };

    if (!actions[action]) {
      throw new Error('Action non reconnue.');
    }

    const { url, onDoneText } = actions[action];
    const response = await put(url);

    if (response) {
      onActionDone(onDoneText);
    }
  }, [hideConfirm, name, id, displayedReference, ct, action, put, onActionDone]);

  useEffect(() => {
    if (error) {
      onActionError(error.message || t('errors:unknown-retry'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const [showEditContactModal, hideEditContactModal] = useModal(() => (
    <ContactEditModal
      editType={contactToEdit ? 'edit' : 'new'}
      onDone={(message) => { onActionDone(message); hideEditContactModal(); }}
      id={contactToEdit}
      defaultCustomerId={customer.id}
      defaultRole={missingContactRoles ? missingContactRoles[0] : ''}
      onClose={hideEditContactModal}
      customerId={contactToEdit ? undefined : customer.id}
    />
  ), [contactToEdit, onActionDone, customer, missingContactRoles]);

  useEffect(() => {
    if (!!contactToEdit) {
      showEditContactModal();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactToEdit]);

  const handleNavigateToAddContact = useCallback(async (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    const completeRole = missingContactRoles ? missingContactRoles[0] : '';
    setContactToEdit(null);

    const customerIdToFetch = customer.id ?? customerId;
    if (!currentOrganization || !customerIdToFetch) {
      return;
    }

    const url = `client/${currentOrganization.reference}/${customerIdToFetch}/contacts`;
    const query = queryString.stringify({ withEav: 1, withUser: 1 });
    const contacts = await get<Contact[]>(`${url}?${query}`);
    if (!contacts) {
      return;
    }

    const existingContact = contacts.find(
      ({ contactRole }) => contactRole.identifier === completeRole,
    );
    if (existingContact) {
      setContactToEdit(existingContact.id);
      return;
    }
    showEditContactModal();
    return;
  }, [ currentOrganization, customerId, customer, missingContactRoles, get, showEditContactModal ]);

  if (isLoading) {
    return <Loading hasNoText />;
  }

  return (
    <div className="HistoryStepActions">
      {(type === 'reminder' && isMissingContact && !isRemoved) && (
        <Button
          variant="outline"
          small
          onClick={handleNavigateToAddContact}
          disabled={isLoading}
          className="HistoryStepActions__action"
        >
          {t('common:complete')}
        </Button>
      )}
      {(type === 'reminder'
        && !data.isInitialActive
        && !data.isActive
        && !isMissingContact
        && !isRemoved) && (
          <Button
            variant="primary"
            small
            title={t('common:validate')}
            onClick={handleConfirmValidate}
            disabled={isLoading}
            className="HistoryStepActions__action"
          >
            <Icon name="check" />
          </Button>
      )}
      {(type === 'task' && !data.validation?.at && !isRemoved) && (
        <Button
          variant="primary"
          small
          title={t('common:done')}
          onClick={handleConfirmActivate}
          disabled={isLoading}
          className="HistoryStepActions__action"
        >
          <Icon name="check" />
        </Button>
      )}
      {(type !== 'note' && !data.remove && !data.doneAt) && (
        <Button
          variant="outline"
          title={t('common:cancel')}
          onClick={handleShowCancel}
          className="HistoryStepActions__action"
        >
          <Icon name="close" />
        </Button>
      )}
      {(type !== 'note' && !data.remove && !data.doneAt) && (
        <Button
          variant="outline"
          title={t('common:edit')}
          onClick={onShowEdit}
          className="HistoryStepActions__action"
        >
          <Icon name="calendar" />
        </Button>
      )}
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        entityName={displayedReference}
        isShow={isConfirmShowed}
        onConfirm={handleSubmit}
        onCancel={hideConfirm}
        variant={confirmVariant}
        confirmButtonText={confirmButtonText}
        cancelButtonText={cancelButtonText}
        key={data.id}
      />
    </div>
  );
};

export default observer(HistoryStepActions);
