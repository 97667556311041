import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import type { Contact } from 'types/models';
import organizationStore from 'stores/Organization';
import recomposeName from 'utils/recomposeName';
import ContactChannelsIcons from 'components/ContactChannelsIcons';
import Button from 'components/Button';
import ListContactsView from 'components/ListContacts/View';
import getContactRole from 'utils/getContactRole';
import Icon from 'components/Icon';

interface Props {
  contact: Contact,
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
}

const PreviewContact: React.FC<Props> = ({ contact, onActionDone, onActionError }) => {
  const { contactRoles } = organizationStore;

  const [showViewModal, hideViewModal] = useModal(() => (
    <ListContactsView
      contactId={contact.id}
      onClose={hideViewModal}
      onActionDone={onActionDone}
      onActionError={onActionError}
    />
  ), [contact, onActionDone, onActionError]);

  return (
    <Button variant="list" onClick={showViewModal} className="PaymentRequestPreviewContact">
      <div className="PaymentRequestPreviewContact__content">
        <div className="PaymentRequestPreviewContact__content__description">
          {getContactRole(contact.contactRole.identifier, contactRoles)}
          <p className="PaymentRequestPreviewContact__content__description__name">
            {recomposeName(contact)}
          </p>
        </div>
        <ContactChannelsIcons contact={contact} />
      </div>
      <Icon name="chevron-right" />
    </Button>
  );
};

export default observer(PreviewContact);
