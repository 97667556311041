import './index.scss';
import type { ReactNode } from 'react';
import type { DefaultTFuncReturn } from 'i18next';
import { useMemo } from 'react';
import classnames from 'classnames';
import ErrorValidationMessage  from 'components/ErrorValidationMessage';
import type { ErrorValidationMessageType } from 'components/ErrorValidationMessage';

type Props = {
  label?: DefaultTFuncReturn,
  controlId?: string,
  className?: string,
  mandatory?: boolean,
  error?: ErrorValidationMessageType,
  shouldDisplayErrorUnderInput?: boolean,
  children: ReactNode,
};

const FormGroup = (props: Props): JSX.Element => {
  const {
    label,
    controlId,
    className,
    mandatory,
    children,
    shouldDisplayErrorUnderInput = false,
    error,
  } = props;

  const classNames = useMemo(() => (
    classnames('FormGroup', className, {
      'FormGroup--mandatory': mandatory,
      'FormGroup--with-error-under-input': shouldDisplayErrorUnderInput && error,
    })
  ), [className, error, mandatory, shouldDisplayErrorUnderInput]);

  return (
    <div className={classNames}>
      {label && (
        <div className="FormGroup__header">
          <label htmlFor={controlId} className="FormGroup__header__label">
            {label}
          </label>
          {!shouldDisplayErrorUnderInput && <ErrorValidationMessage error={error} />}
        </div>
      )}
      {children}
      {shouldDisplayErrorUnderInput && <ErrorValidationMessage error={error} />}
    </div>
  );
};

export default FormGroup;
