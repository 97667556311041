import config from 'config';
import type Misc from 'types/misc';

export type ChosableFormat = Misc.EavFormats | 'none';

const isEavValueValid = (
  format: ChosableFormat | null,
  value: string | number | undefined | null,
  isMandatory: boolean,
) => {
  if (!format) {
    return true;
  }

  if (!value || typeof value !== 'string') {
    return !isMandatory;
  }

  return format === 'none'
    ? true
    : config.EAV_FORMATS[format].test(value);
};

export default isEavValueValid;
