import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import organizationStore from 'stores/Organization';
import DownloadLink from 'components/DownloadLink';
import Button from 'components/Button';

interface Props {
  id: number,
}

const ImportsHistoryRowActions: React.FC<Props> = ({ id }) => {
  const { currentOrganization } = organizationStore;
  const { t } = useTranslation();

  return (
    <div className="ImportsHistoryRowActions">
      <DownloadLink
        path={`${currentOrganization?.reference}/import/${id}`}
        className="ImportsHistoryRowActions__download-link"
      >
        <Button>
          {t('imports:download')}
        </Button>
      </DownloadLink>
    </div>
  );
};

export default observer(ImportsHistoryRowActions);
