import Request from 'utils/request';
import type { Collaborator } from 'types/models';

export type FetchAllParams = { organization?: string };
export type FetchAnswerParams = { token: string };

const fetchAll = async ({ organization }: FetchAllParams) => {
  if (!organization) {
    throw new Error('FetchAllCollaborators: Missing organization.');
  }

  const result = await Request.get<Collaborator[]>(
    `collaborators/${organization}`,
  );
  return result;
};

const fetchAnswer = async ({ token }: FetchAnswerParams) => {
  if (!token) {
    throw new Error('FetchAnswer: Missing token.');
  }

  const result = await Request.get(`collaborators/answer/${token}`);
  return result;
};

export { fetchAll, fetchAnswer };
