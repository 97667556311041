import './index.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
import type Errors from 'types/errors';
import type { Selectors } from 'types/models';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import Button from 'components/Button';
import FormSelect from 'components/FormSelect';
import FormCheck from 'components/FormCheck';
import Conditions from './Conditions';
import isDefaultCondition from './utils';

type Props = {
  errors?: Errors.Validation | null,
  defaultData?: Selectors,
  edit?: boolean,
  conditions: Misc.SelectorCondition[],
  isGroup?: boolean,
  onUpdateConditions(newCondition: Misc.SelectorCondition[]): void,
  onToggleConditionOpen(newConditionOpen: boolean): void,
  onActionError(message: string): void,
};

const ModalSelectorForm = (props: Props): JSX.Element => {
  const {
    errors,
    defaultData,
    conditions,
    onUpdateConditions,
    onToggleConditionOpen,
    isGroup = false,
    edit = false,
    onActionError,
  } = props;
  const { t } = useTranslation();
  const [numberOfConditions, setNumberOfConditions] = useState<number>(conditions.length);
  const [isDefaultPlan, setIsDefaultPlan] = useState<boolean>(isDefaultCondition(conditions));
  const openedConditionsCount = useRef<number>(0);
  const oldConditions = useRef<Misc.SelectorCondition[]>(conditions);

  useEffect(() => {
    if (!isDefaultPlan) {
      oldConditions.current = conditions;
    }
  }, [conditions, isDefaultPlan, onUpdateConditions]);

  const handleChangeIsDefaultPlan = useCallback((newDefaultPlan: boolean) => {
    if (!newDefaultPlan) {
      onUpdateConditions(oldConditions.current);
      setNumberOfConditions(oldConditions.current.length);
      setIsDefaultPlan(newDefaultPlan);
      return;
    }
    onUpdateConditions([{
      comparisonOperator: '==',
      logicOperator: 'AND',
      select1: '1',
      select2: '1',
    }]);
    setNumberOfConditions(1);
    setIsDefaultPlan(newDefaultPlan);
  }, [onUpdateConditions]);

  const handleAddCondition = useCallback(() => {
    if (openedConditionsCount.current !== 0) {
      onActionError(t('selectors:close-conditions'));
      return;
    }
    const tmpconditions = [...conditions];
    tmpconditions.push({
      comparisonOperator: '==',
      logicOperator: 'AND',
      select1: '',
      select2: '',
    });
    setNumberOfConditions(numberOfConditions + 1);
    onUpdateConditions(tmpconditions);
  }, [conditions, numberOfConditions, onActionError, onUpdateConditions, t]);

  const handleRemoveCondition = useCallback((index: number) => {
    const tmpConditions = [...conditions];
    setNumberOfConditions(numberOfConditions > 0 ? numberOfConditions - 1 : 0);
    tmpConditions.splice(index, 1);
    openedConditionsCount.current -= 1;
    onToggleConditionOpen(openedConditionsCount.current !== 0);
    onUpdateConditions(tmpConditions);
  }, [conditions, numberOfConditions, onToggleConditionOpen, onUpdateConditions]);

  const handleChangeCondition = useCallback((value: Misc.SelectorCondition, index: number) => {
    const tmpConditions = [...conditions];
    tmpConditions[index] = value;
    onUpdateConditions(tmpConditions);
  }, [conditions, onUpdateConditions]);

  const handleConditionsOpen = useCallback((newOpen: boolean) => {
    if (newOpen) {
      openedConditionsCount.current += 1;
      onToggleConditionOpen(openedConditionsCount.current !== 0);
      return;
    }
    if (openedConditionsCount.current > 0) {
      openedConditionsCount.current -= 1;
      onToggleConditionOpen(openedConditionsCount.current !== 0);
    }
  }, [onToggleConditionOpen]);

  return (
    <div className="ModalSelectorForm">
      <FormFieldset>
        <FormGroup label={t('common:name')} mandatory error={errors?.name}>
          <FormControl
            type="text"
            name="name"
            disabled={edit}
            placeholder={t('selectors:placeholder-name')}
            autoComplete="off"
            defaultValue={defaultData?.name}
            isInvalid={!!errors?.label}
          />
        </FormGroup>
        <FormGroup label={t('common:position')} error={errors?.position} className="ModalSelectorForm__position">
          <FormControl
            type="number"
            name="position"
            autoComplete="off"
            defaultValue={defaultData?.position || 0}
            isInvalid={!!errors?.position}
          />
        </FormGroup>
        <FormGroup label={t('plans:default-plan')} className="ModalSelectorForm__default">
          <FormCheck
            checked={isDefaultPlan}
            onChange={handleChangeIsDefaultPlan}
          />
        </FormGroup>
      </FormFieldset>
      <div className="ModalSelectorForm__condition-header">
        <p className="ModalSelectorForm__condition-header__title">{t('selectors:condition')}</p>
        {!isDefaultPlan && (
          <Button variant="link" className="ModalSelectorForm__add-button" onClick={handleAddCondition}>
            {t('selectors:add-condition')}
          </Button>
        )}
      </div>
      {[...Array(numberOfConditions)].map((_value, index) => (
        <div key={`${conditions[index].select1}-${conditions[index].select2}`}>
          <Conditions
            isGroup={isGroup}
            index={index}
            defaultCondition={conditions[index]}
            onChange={handleChangeCondition}
            onRemove={handleRemoveCondition}
            onToggleConditionsOpen={handleConditionsOpen}
            shouldLockConditions={isDefaultPlan}
          />
          {index !== conditions.length - 1 && (
            <p className="ModalSelectorForm__and">{t('selectors:operators.and')}</p>
          )}
        </div>
      ))}
      <FormFieldset>
        <FormGroup
          label={t('selectors:scenario')}
          className="ModalSelectorForm__plan"
          mandatory
          error={errors?.template}
        >
          <FormSelect
            name="template"
            placeholder={t('selectors:select-scenario')}
            isAsync
            fetchEntity="list"
            addToFetchedOptions={[{ label: t('common:random'), value: '{RANDOM}' }]}
            fetchEntityQuerystring={`group=${isGroup ? 'true' : 'false'}`}
            defaultValue={defaultData?.template}
            requestPrefix="manage-plans"
            entityValue="identifier"
            entityNameField="name"
          />
        </FormGroup>
      </FormFieldset>
    </div>
  );
};

export default ModalSelectorForm;
