import type Misc from 'types/misc';

const getI18nDayFromReference = (day: number, dateReference: Misc.PlanDateReference): string => {
  if (day === 0) {
    if (dateReference === 'invoice-deadline') {
      return 'plans:day-from-reference.invoice-deadline';
    }
    return (dateReference === 'invoice-oldest-deadline')
      ? 'plans:day-from-reference.invoice-oldest-deadline'
      : 'plans:day-from-reference.plan-setup';
  }
  if (dateReference === 'invoice-deadline') {
    return (day > 0)
      ? 'plans:day-from-reference.after-invoice-deadline'
      : 'plans:day-from-reference.before-invoice-deadline';
  }
  if (dateReference === 'invoice-oldest-deadline') {
    return (day > 0)
      ? 'plans:day-from-reference.after-invoice-oldest-deadline'
      : 'plans:day-from-reference.before-invoice-oldest-deadline';
  }
  return (day > 0)
    ? 'plans:day-from-reference.after-plan-setup'
    : 'plans:day-from-reference.before-plan-setup';
};

export default getI18nDayFromReference;
