import './index.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ClientStatsTotalPercent } from 'types/models';
import Icon from 'components/Icon';

type Props = {
  totalPercent: ClientStatsTotalPercent | null | undefined,
};

type StatEmotionDebt = 'no-debt' | 'happy' | 'looking' | 'problem';

const CustomerStatsDebt = ({ totalPercent }: Props): JSX.Element | null => {
  const { t } = useTranslation();

  const emotionDebt = useMemo<StatEmotionDebt>(() => {
    if (!totalPercent) {
      return 'no-debt';
    }

    const { notDue, due, late } = totalPercent;
    const total = notDue.totalDue + due.totalDue + late.totalDue;
    if (total === 0) {
      return 'no-debt';
    }

    if (late.totalDue >= (0.2 * total)) {
      return 'problem';
    }

    if (notDue.totalDue >= (0.5 * total)) {
      return 'happy';
    }

    return 'looking';
  }, [totalPercent]);

  if (!totalPercent) {
    return null;
  }

  const { notDue, due, late } = totalPercent;

  return (
    <div className={`CustomerStatsDebt CustomerStatsDebt--${emotionDebt}`}>
      <div className="CustomerStatsDebt__icon">
        <Icon name={`emoji-${emotionDebt === 'no-debt' ? 'happy' : emotionDebt}`} />
      </div>
      <div className="CustomerStatsDebt__details">
        <p className="CustomerStatsDebt__percent">
          {emotionDebt === 'no-debt' && (
            t('clients:statistics.no-debt')
          )}
          {emotionDebt === 'happy' && (
            t('clients:statistics.happy', { percent: notDue.percent })
          )}
          {emotionDebt === 'looking' && (
            t('clients:statistics.looking', { percent: (notDue.percent + due.percent) })
          )}
          {emotionDebt === 'problem' && (
            t('clients:statistics.problem', { percent: late.percent })
          )}
        </p>
        {emotionDebt !== 'no-debt' && (
          <p className="CustomerStatsDebt__count">
            {emotionDebt === 'happy' && (
              t('common:bills-count', { count: notDue.totalPayRequest })
            )}
            {emotionDebt === 'looking' && (
              t('common:bills-count', { count: due.totalPayRequest })
            )}
            {emotionDebt === 'problem' && (
              t('common:bills-count', { count: late.totalPayRequest })
            )}
          </p>
        )}
      </div>
    </div>
  );
};

export default CustomerStatsDebt;
