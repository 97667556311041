import './index.scss';
import { useMemo, useCallback, useEffect, useState, useContext, useRef } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useModal } from 'react-modal-hook';
import type { Column, Row } from 'react-table';
import type { PayRequestOrPayment } from 'types/models';
import type { CustomerAccountDetailsFilters } from 'api/payments-pay-requests';
import Config from 'config';
import useFetchPaginated from 'hooks/useFetchPaginated';
import useContextualTranslation from 'hooks/useContextualTranslation';
import type { FetchAllForCustomerParams } from 'api/payments-pay-requests';
import { fetchAllForCustomer } from 'api/payments-pay-requests';
import organizationStore from 'stores/Organization';
import PaymentEditModal from 'components/PaymentEditModal';
import PaymentViewModal from 'components/PaymentViewModal';
import DataTable from 'components/DataTable';
import Pagination from 'components/Pagination';
import Icon from 'components/Icon';
import customerContext from '../../../customerContext';
import detailsColumns from './Columns';
import DetailsFilters from './Filters';

const DEFAULT_FILTERS: CustomerAccountDetailsFilters = {
  debits: true,
  credits: true,
  current: true,
  balanced: true,
  future: false,
};

const CustomerAccountDetails = (): JSX.Element => {
  const { data: customer, reloadTick, onActionDone } = useContext(customerContext);
  const customerId = customer?.id;
  const { currentOrganization, linesOfBusiness, modePayRequest } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);
  const [pageSize, setPageSize] = useState<number>(Config.PAGE_SIZES[0]);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [filters, setFilters] = useState<CustomerAccountDetailsFilters>(DEFAULT_FILTERS);
  const editPaymentReference = useRef<string | null>(null);
  const viewPaymentReference = useRef<string | null>(null);

  const {
    isLoading,
    data,
    serverPagination,
    error,
    refetch,
  } = useFetchPaginated<FetchAllForCustomerParams, PayRequestOrPayment>(
    {
      cacheKey: 'customerPaymentPayRequests',
      organization: currentOrganization?.reference,
      fetchOptions: { pageIndex, pageSize, filters },
      customerId: customerId ?? 0,
    },
    fetchAllForCustomer,
    { enabled: !!customerId },
  );

  const handleNextPage = useCallback(() => {
    setPageIndex((prevIndex) => prevIndex + 1);
  }, []);

  const handlePreviousPage = useCallback(() => {
    setPageIndex((prevIndex) => prevIndex - 1);
  }, []);

  const handleGotoPage = useCallback((page: number) => {
    setPageIndex(page);
  }, []);

  const handleChangeFilters = useCallback((newFilters: CustomerAccountDetailsFilters) => {
    setFilters(newFilters);
  }, []);

  const pageCount = useMemo(() => serverPagination?.totalPages ?? 1, [serverPagination]);

  useEffect(() => {
    refetch();
  }, [refetch, reloadTick]);

  const getRowClassName = useCallback((row: Row<PayRequestOrPayment>) => {
    const { entity, status } = row.original;
    if (entity === 'payment' && status === 'FAILED') {
      return 'CustomerAccountDetails__list__row--failed-payment';
    }
    if (entity === 'payRequest' && (row.original.litigatedAt || status === 'FAILED')) {
      return 'CustomerAccountDetails__list__row--litigated';
    }
    return '';
  }, []);

  const handleActionDone = useCallback((title: string, message: string) => {
    if (onActionDone) {
      onActionDone(message);
    }
    refetch();
  }, [onActionDone, refetch]);

  const [showEditModal, hideEditModal] = useModal(() => (
    <PaymentEditModal
      reference={editPaymentReference.current ?? undefined}
      onDone={handleActionDone}
      onClose={hideEditModal}
    />
  ), [handleActionDone, editPaymentReference]);

  const showEditPaymentModal = useCallback((reference: string) => {
    editPaymentReference.current = reference;
    showEditModal();
  }, [showEditModal]);

  const [showViewModal, hideViewModal] = useModal(() => {
    if (!viewPaymentReference.current) {
      return null;
    }

    const showEdit = (reference: string) => {
      hideViewModal();
      showEditPaymentModal(reference);
    };

    return (
      <PaymentViewModal
        reference={viewPaymentReference.current}
        onClose={hideViewModal}
        onClickEdit={showEdit}
      />
    );
  }, [showEditPaymentModal]);

  const showPaymentModal = useCallback((reference: string) => {
    viewPaymentReference.current = reference;
    showViewModal();
  }, [showViewModal]);

  const columns = useMemo<Column<PayRequestOrPayment>[]>(() => {
    let displayedColumns = detailsColumns(ct, showEditPaymentModal, showPaymentModal, filters.balanced);

    const hideBalance = (
      modePayRequest === 'BALANCE'
      || filters.debits === false
      || filters.credits === false
      || filters.current === false
      || filters.balanced === false
    );
    if (hideBalance) {
      displayedColumns = displayedColumns.filter(({ accessor }) => accessor !== 'balance');
    }
    const hideRemaining = filters.balanced === false;
    if (!hideRemaining) {
      displayedColumns = displayedColumns.filter(({ accessor }) => accessor !== 'amountNotAssigned');
    }

    return displayedColumns;
  }, [ct, showEditPaymentModal, showPaymentModal, modePayRequest, filters]);

  return (
    <div className="CustomerAccountDetails">
      <div className="CustomerAccountDetails__header">
        <h3 className="CustomerAccountDetails__header__title">
          {t('payment-requests:account-details')}
        </h3>
        <div className="CustomerAccountDetails__header__links">
          <Link
            to={`/payment-requests/IN_PROGRESS?client=${customerId}`}
            className="CustomerAccountDetails__header__link-manage"
          >
            <span className="CustomerAccountDetails__header__link-manage__title">
              {t('common:see-all')}
            </span>
            <span className="CustomerAccountDetails__header__link-manage__icon">
              <Icon name="chevron-right-small" />
            </span>
          </Link>
        </div>
      </div>
      <div className="CustomerAccountDetails__filters">
        <DetailsFilters filters={filters} onChange={handleChangeFilters} />
      </div>
      <div className="CustomerAccountDetails__list">
        <DataTable<PayRequestOrPayment>
          columns={columns}
          data={data}
          getRowClassName={getRowClassName}
          serverPagination={null}
          defaultSortBy={{ id: 'date', desc: true }}
          fetchData={() => {}}
          isLoading={isLoading}
          error={error}
          withActions={false}
          noDataFoundMessage={ct('payment-requests:no-data')}
          noHeader
        />
        {(serverPagination && pageCount > 0) && (
          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            canPreviousPage={pageIndex > 0}
            canNextPage={pageIndex < (pageCount - 1)}
            pageCount={pageCount}
            totalRecords={serverPagination.totalRecords}
            nextPage={handleNextPage}
            previousPage={handlePreviousPage}
            onChangePageSize={setPageSize}
            onGotoPage={handleGotoPage}
          />
        )}
      </div>
    </div>
  );
};

export default observer(CustomerAccountDetails);
