import './index.scss';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { Payment } from 'types/models';
import ListElement from 'components/ListElement';
import formatIntlNumber from 'utils/formatIntlNumber';
import getI18nPaymentMethod from 'utils/getI18nPaymentMethod';
import getI18nPaymentStatus from 'utils/getI18nPaymentStatus';
import formatIntlDate from 'utils/formatIntlDate';
import ButtonWithUserRights from 'components/ButtonWithUserRights';

type Props = {
  payment: Payment | null,
  onClickEdit(reference: string): void,
};

const PaymentViewModalContent = ({ payment, onClickEdit }: Props): JSX.Element | null => {
  const { t } = useTranslation();

  const handleEdit = useCallback(() => {
    if (!payment) {
      return;
    }
    onClickEdit(payment.reference);
  }, [payment, onClickEdit]);

  if (!payment) {
    return null;
  }

  const {
    client,
    paidAt,
    totalAmountPayment,
    currency,
    mean,
    status,
    amountNotAssigned,
    subject,
    payRequests,
  } = payment;

  return (
    <div className="PaymentViewModalContent">
      <dl className="PaymentViewModalContent__details">
        <ListElement label={t('common:client')}>
          <Link to={`/customer/${client.id}`}>
            {client.name}
          </Link>
        </ListElement>
        <ListElement label={t('common:date')}>
          {formatIntlDate(paidAt, 'dateFull')}
        </ListElement>
        <ListElement label={t('common:amount')}>
          {formatIntlNumber(totalAmountPayment, currency)}
        </ListElement>
        <ListElement label={t('payments:payment-method-short')}>
          {t(getI18nPaymentMethod(mean ?? 'OTHER'))}{' '}
          ({t(getI18nPaymentStatus(status))})
        </ListElement>
        <ListElement label={t('payments:to-impute')}>
          {formatIntlNumber(amountNotAssigned, currency)}
        </ListElement>
        <ListElement label={t('payments:subject')}>
          {subject || '--'}
        </ListElement>
      </dl>
        <div className="PaymentViewModalContent__assignations">
          <div className="PaymentViewModalContent__assignations__title">
            {t('payments:imputation')}
          </div>
          {(!payRequests || payRequests.length === 0) && (
            <div className="PaymentViewModalContent__assignations__no-data">
              {status === 'SUCCESS' && (
                <ButtonWithUserRights
                  variant="outline"
                  onClick={handleEdit}
                  managers={payment.users}
                  action="CREATE"
                  categoryType="PAYMENTS"
                >
                  {t('payments:to-impute')}
                </ButtonWithUserRights>
              )}
              {status !== 'SUCCESS' && (
                <span>{t('payments:not-imputable')} ({t(getI18nPaymentStatus(status))})</span>
              )}
            </div>
          )}
          {payRequests && payRequests.length > 0 && (
            <ul className="PaymentViewModalContent__assignations__list">
              {payRequests.map(({ amountAssigned, id, reference }) => (
                <li key={reference} className="PaymentViewModalContent__assignations__list__item">
                  <Link to={`/payment-request/${id}`}>
                    {reference}
                  </Link>,{' '}
                  {t('payments:for-amount', {
                    amount: formatIntlNumber(Number.parseFloat(amountAssigned.toString()), currency),
                  })}
                </li>
              ))}
            </ul>
          )}
        </div>
    </div>
  );
};

export default PaymentViewModalContent;
