import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import type Misc from 'types/misc';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import Button from 'components/Button';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import type { ToggleAction } from '../../actions';
import getBulkToggleActionI18n from '../getBulkToggleActionI18n';
import UnMuted from '../../Row/UnMuted';

interface Props {
  selection: (number | string)[],
  action: ToggleAction,
  onLoading: (isLoading: boolean) => void,
  onDone: (action: ToggleAction) => void,
  onError: (message: string) => void,
}

const BulkActionsToggle = (props: Props): JSX.Element => {
  const { linesOfBusiness } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);
  const [isShowUnmutedModal, setIsShowUnmutedModal] = React.useState(false);

  const {
    selection,
    action,
    onLoading,
    onDone,
    onError,
  } = props;

  const isMountedRef = useIsMountedRef();
  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const {
    put,
    remove,
    error,
    isLoading,
  } = useApiRequest();

  const actionI18n = React.useMemo(
    () => getBulkToggleActionI18n(t, ct, selection.length)[action],
    [t, ct, selection, action],
  );

  const handleClickAction = React.useCallback(() => {
    const { title, confirmText: text } = actionI18n;
    if (action === 'unmute') {
      setIsShowUnmutedModal(true);
      return;
    }

    showConfirm(title, text);
  }, [action, actionI18n, showConfirm]);

  const doActionPut = React.useCallback(async () => {
    const result = await put<Misc.IdRef>(
      `pay-requests/bulk-manage/${action}`,
      { selection },
    );
    if (result && isMountedRef.current) {
      onDone(action);
    }
  }, [action, selection, put, isMountedRef, onDone]);

  const doActionDelete = React.useCallback(async () => {
    await remove(`pay-requests/bulk-manage?selection=${selection.join(',')}`);
    if (isMountedRef.current) {
      onDone('remove');
    }
  }, [selection, remove, isMountedRef, onDone]);

  const handleSubmit = React.useCallback(() => {
    hideConfirm();
    if (action === 'remove') {
      doActionDelete();
    } else {
      doActionPut();
    }
  }, [hideConfirm, action, doActionPut, doActionDelete]);

  React.useEffect(() => {
    if (error) { onError(error.message); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  React.useEffect(() => {
    onLoading(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <>
      <Button
        variant="outline-light"
        onClick={handleClickAction}
        className={classnames(
          'BulkActionsToggle',
          { 'BulkActionsToggle--delete': action === 'remove' },
        )}
        disabled={isLoading}
      >
        {actionI18n.title}
      </Button>
      <UnMuted
        onDone={onDone}
        onClose={() => { setIsShowUnmutedModal(false); }}
        isShow={isShowUnmutedModal}
        title={actionI18n.title}
        text={actionI18n.confirmText}
        action={action}
        bulk={selection}
      />
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        isShow={isConfirmShowed}
        onConfirm={handleSubmit}
        onCancel={hideConfirm}
        variant={action === 'remove' ? 'danger' : 'primary'}
        confirmButtonText={actionI18n.title}
      />
    </>
  );
};

export default observer(BulkActionsToggle);
