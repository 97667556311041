import './index.scss';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { OrganizationPlanTemplate } from 'types/models';
import getI18nPlanChangeCondition from 'utils/getI18nPlanChangeCondition';

type Props = {
  data: OrganizationPlanTemplate,
};

const ScenarioDetailsInfos = ({ data }: Props) => {
  const { t } = useTranslation();

  const dateReference = useMemo(() => {
    if (!data || data.dateReferenceIsToday) {
      return 'plan-setup';
    }
    return data.group ? 'invoice-oldest-deadline' : 'invoice-deadline';
  }, [data]);

  const planChange = useMemo(() => (
    Array.isArray(data.planChange) ? data.planChange[0] : data.planChange
  ), [data]);

  return (
    <div className="ScenarioDetailsInfos">
      <div className="ScenarioDetailsInfos__group">
        <h4 className="ScenarioDetailsInfos__group__title">
          {t('plans:reference-date')}
        </h4>
        <div className="ScenarioDetailsInfos__group__container">
          {dateReference === 'plan-setup' && t('plans:reference-date-options.plan-setup')}
          {dateReference === 'invoice-deadline' && t('plans:reference-date-options.invoice-deadline')}
          {dateReference === 'invoice-oldest-deadline' && t('plans:reference-date-options.invoice-oldest-deadline')}
        </div>
      </div>
      <div className="ScenarioDetailsInfos__group">
        <h4 className="ScenarioDetailsInfos__group__title">
          {t('plans:grouped-reminder')}
        </h4>
        <div className="ScenarioDetailsInfos__group__container">
          {data?.group ? t('common:yes') : t('common:no')}
        </div>
      </div>
      <div className="ScenarioDetailsInfos__group">
        <h4 className="ScenarioDetailsInfos__group__title">
          {t('plans:conditional-plan-change')}
        </h4>
        <div className="ScenarioDetailsInfos__group__container">
          <div className="ScenarioDetailsInfos__group__container__plan-change">
            {!planChange && (
              <p className="ScenarioDetailsInfos__group__container__plan-change__no-data">
                {t('common:none')}
              </p>
            )}
            {planChange && (
              <Fragment>
                <p className="ScenarioDetailsInfos__group__container__plan-change__new-plan">
                  {planChange.newPlan}
                </p>
                {planChange.conditions.map((condition) => (
                  <p className="ScenarioDetailsInfos__group__container__plan-change__badge" key={condition}>
                    {t(getI18nPlanChangeCondition(condition))}
                  </p>
                ))}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScenarioDetailsInfos;
