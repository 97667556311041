import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import type { Collaborator, RightsCategory } from 'types/models';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import Loading from 'components/Loading';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import DropdownActions from 'components/DropdownActions';
import CollaboratorEditModal from '../CollaboratorEditModal';

interface Props {
  collaborator: Collaborator,
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
}

const CollaboratorRowActions: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const isMountedRef = useIsMountedRef();

  const {
    onActionDone,
    onActionError,
    collaborator,
  } = props;

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const { remove, error, isLoading } = useApiRequest();

  const category = React.useMemo<RightsCategory>(() => {
    switch (collaborator.roles[0]) {
      case 'ROLE_COORDINATOR':
        return 'ORGANIZATION_COLLABORATORS_ROLE_COORDINATOR';
      case 'ROLE_ACCOUNT_MANAGER':
        return 'ORGANIZATION_COLLABORATORS_ROLE_ACCOUNT_MANAGER';
      case 'ROLE_ADMIN':
        return 'ORGANIZATION_COLLABORATORS_ROLE_ADMIN';
      default:
        return 'ORGANIZATION_COLLABORATORS_ROLE_ADMIN';
    }
  }, [collaborator]);

  const [showMyCollaboratorEditModal, hideMyCollaboratorEditModal] = useModal(() => (
    <CollaboratorEditModal
      onClose={hideMyCollaboratorEditModal}
      onActionDone={onActionDone}
      onActionError={onActionError}
      collaborator={collaborator}
    />
  ), [onActionError, onActionDone, collaborator]);

  const handleConfirmDelete = React.useCallback(() => {
    const { name } = collaborator;
    showConfirm(t('collaborators:remove'), t('collaborators:confirm-remove', { name }));
  }, [showConfirm, t, collaborator]);

  const handleSubmitDelete = React.useCallback(async () => {
    hideConfirm();

    const response = await remove(`collaborators/manage/${collaborator.id}`);
    if (isMountedRef && response) {
      const { name } = collaborator;
      onActionDone(t('collaborators:toast.success.collaborator-deleted', { name }));
    }
  }, [hideConfirm, onActionDone, t, collaborator, remove, isMountedRef]);

  React.useEffect(() => {
    if (error) {
      const { name } = collaborator;
      onActionError(error.message || t('collaborators:toast.error.collaborator-deleted', { name }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <div>
      <DropdownActions
        actions={[
          <ButtonWithUserRights
            action="UPDATE"
            category={category}
            variant="list"
            onClick={showMyCollaboratorEditModal}
            title={t('common:edit')}
          >
            {isLoading && <Loading hasNoText />}
            {!isLoading && t('common:edit')}
          </ButtonWithUserRights>,
          <ButtonWithUserRights
            action="DELETE"
            category={category}
            variant="list-danger"
            onClick={handleConfirmDelete}
            title={t('common:remove')}
          >
            {isLoading && <Loading hasNoText />}
            {!isLoading && t('common:remove')}
          </ButtonWithUserRights>,
        ]}
      />
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        entityName={collaborator.name}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitDelete}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={t('common:remove')}
      />
    </div>
  );
};

export default CollaboratorRowActions;
