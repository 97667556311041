import './index.scss';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import type Misc from 'types/misc';
import type { ContactSummaryStep } from 'types/models';
import organizationStore from 'stores/Organization';
import getContactRole from 'utils/getContactRole';

type Props = {
  contacts: ContactSummaryStep[] | null,
  type: Misc.TypeOfHistoryStep,
};

const HistoryStepContactsRecipients = ({ contacts, type }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { contactRoles } = organizationStore;

  return (
    <div className="HistoryStepContactRecipients">
      {contacts && contacts.length <= 0 && type === 'reminder' && (
        <span className="HistoryStepContactRecipients__missing-contacts">
          {t('contacts:missing-contacts')}
        </span>
      )}
      {contacts && contacts.length > 0 && (
        <div className="HistoryStepContactRecipients__summary">
          {contacts.map((contact) => (
            <div className="HistoryStepContactRecipients__item" key={contact.id}>
              {contact.name}
              <span className="HistoryStepContactRecipients__role">
                {getContactRole(contact.contactRole, contactRoles)}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default observer(HistoryStepContactsRecipients);
