import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import { observer } from 'mobx-react';
import useFetch from 'hooks/useFetch';
import { fetchAll } from 'api/categories';
import type { FetchAllParams } from 'api/categories';
import type { Category } from 'types/models';
import organizationStore from 'stores/Organization';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import Button from 'components/Button';
import SettingsSection from '../../Section';
import ModalCreateCategory from './ModalCreateCategory';
import PerimetersTable from './CategoriesTable';

type Props = {
  onShowToast(message: string, status: ToastStatus): void,
  onReload(): void,
  reloadTick: number,
};

const SettingsCollaboratorsPerimeters = (props: Props): JSX.Element => {
  const { onShowToast, reloadTick, onReload } = props;
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const [editCategory, setEditCategory] = useState<Category | undefined>();

  const {
    data: categoriesData,
    isLoading,
  } = useFetch<FetchAllParams, Category[]>(
    {
      cacheKey: 'categories',
      organization: currentOrganization?.reference,
      reloadTick,
    },
    fetchAll,
    { enabled: !!currentOrganization?.reference },
  );

  const [showModalCategory, hideModalCategory] = useModal(() => {
    return <ModalCreateCategory
      category={editCategory}
      categoryData={categoriesData}
      onClose={hideModalCategory}
      onShowToast={onShowToast}
      onReload={onReload}
    />;
  }, [onShowToast, categoriesData, editCategory, onReload]);

  return (
    <SettingsSection
      title={t('perimeters:perimeters')}
      action={(
        <Button onClick={() => { setEditCategory(undefined); showModalCategory(); }} variant="link" withCaretIcon>
          {t('perimeters:create')}
        </Button>
      )}
    >
      <p className='SelectorsListing__description'>{t('perimeters:definition')}</p>
      <PerimetersTable
        categories={categoriesData}
        isLoading={isLoading}
        reloadTick={reloadTick}
        showCategoryModal={showModalCategory}
        setEditCategory={setEditCategory}
        onShowToast={onShowToast}
        onReload={onReload}
      />
    </SettingsSection>
  );
};

export default observer(SettingsCollaboratorsPerimeters);
