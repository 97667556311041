import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import type { PayRequest, TaskPost } from 'types/models';
import type Misc from 'types/misc';
import type Errors from 'types/errors';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import ModalModels from 'components/ModalModels';
import Button from 'components/Button';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import organizationStore from 'stores/Organization';
import Confirm from 'components/Confirm';
import CreateTaskForm from './Form';

type Props = {
  onDone(message: string): void,
  onClose(): void,
  payRequestData: PayRequest | null,
};

const CreateTask = ({ onDone, onClose, payRequestData }: Props): JSX.Element => {
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();

  const { currentOrganization } = organizationStore;

  const [hasChanges, setHasChanges] = React.useState<boolean>(false);
  const [validationErrors, setValidationErrors] = React.useState<Errors.Validation | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = React.useState<boolean>(false);
  const [model, setModel] = React.useState<Misc.PlanTemplateReminder | undefined>();

  const { post, isLoading, error } = useApiRequest();

  const closeSelf = React.useCallback(() => {
    if (!isMountedRef.current) {
      return;
    }

    setShowCancelConfirm(false);
    setHasChanges(false);
    setValidationErrors(null);
    onClose();
  }, [isMountedRef, onClose]);

  const handleCancel = React.useCallback(
    () => {
      if (hasChanges) {
        setShowCancelConfirm(true);
      } else {
        closeSelf();
      }
    },
    [hasChanges, closeSelf],
  );

  const mapFormData = React.useCallback(
    (rawData: ModalFormData): TaskPost => ({
      name: rawData.name as string,
      description: rawData.description as string,
      startAt: rawData.startAt as string,
      group: rawData.group === '1',
      payRequestId: (payRequestData?.id) || 0,
      groupId: payRequestData?.group ? payRequestData.group.id : undefined,
    }),
    [payRequestData],
  );

  const [showModalModels, hideModalModels] = useModal(() => (
    <ModalModels
      type="task"
      onClose={hideModalModels}
      onSelect={setModel}
    />
  ), []);

  const handleSubmit = React.useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData || !currentOrganization) {
        return;
      }

      const data = mapFormData(formData);
      const result = await post<Misc.IdName>('task/new/freeTask', data);

      if (!isMountedRef.current) {
        return;
      }

      if (result?.errors) {
        setValidationErrors(result.errors);
        return;
      }

      if (!result?.id) {
        return;
      }

      closeSelf();
      const { name } = result;
      onDone(t('reminders:freetask.toast.created', { name }));
    },
    [currentOrganization, post, mapFormData, isMountedRef, onDone, t, closeSelf],
  );

  return (
    <ModalForm
      isOpened
      className="CreateTask"
      title={t('reminders:freetask.create')}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
      headerActions={(
        <Button variant="link" onClick={showModalModels}>{t('models:import')}</Button>
      )}
    >
      {error && (<ErrorMessage error={error} />)}
      <CreateTaskForm
        errors={validationErrors}
        hasGroup={payRequestData?.group !== null}
        defaultData={model}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={closeSelf}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(CreateTask);
