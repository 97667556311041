import './index.scss';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

type Props = {
  number: number,
  max: number,
  className?: string
};

const CountCharactersHelper = ({ number, max, className }: Props): JSX.Element => {
  const { t } = useTranslation();

  const classNames = classnames('CountCharactersHelper', className, {
    'CountCharactersHelper--over': number >= max,
  });

  return (
    <div className={classNames}>
      <span className="CountCharactersHelper__count">
        {number}
      </span>
      &nbsp;/&nbsp;
      {t('common:characters', { count: max })}
    </div>
  );
};

export default CountCharactersHelper;
