import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import type { Occupation } from 'types/models';
import getI18nUserRights from 'utils/getI18nUserRights';
import organizationStore from 'stores/Organization';
import useIsMountedRef from 'hooks/useIsMountedRef';
import useApiRequest from 'hooks/useApiRequest';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import Loading from 'components/Loading';
import Button from 'components/Button';
import SettingsList from '../../List';
import ModalEditSignature from './ModalEditSignature';

interface Props {
  onShowToast: (message: string, type: ToastStatus) => void,
}

const SettingsProfileMyOrganization: React.FC<Props> = ({ onShowToast }) => {
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;

  const isMountedRef = useIsMountedRef();

  const [occupation, setOccupation] = React.useState<Occupation>();

  const {
    get,
    isLoading,
    cancel,
  } = useApiRequest();

  const fetchOccupation = React.useCallback(
    async () => {
      if (!currentOrganization) {
        return;
      }

      const response = await get<Occupation>(
        `occupation/${currentOrganization.id}`,
      );

      if (isMountedRef.current && response) {
        setOccupation(response);
      }
    },
    [currentOrganization, get, isMountedRef],
  );

  React.useEffect(() => {
    fetchOccupation();
    return () => { cancel(); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization]);

  const [showModalEditSignatureLetter, hideModalEditSignatureLetter] = useModal(() => (
    occupation ? (
      <ModalEditSignature
        signatureType="letter"
        occupation={occupation}
        onClose={hideModalEditSignatureLetter}
        onShowToast={onShowToast}
      />
    ) : null
  ), [occupation, onShowToast]);

  const [showModalEditSignatureEmail, hideModalEditSignatureeEmail] = useModal(() => (
    occupation ? (
      <ModalEditSignature
        signatureType="email"
        occupation={occupation}
        onClose={hideModalEditSignatureeEmail}
        onShowToast={onShowToast}
      />
    ) : null
  ), [occupation, onShowToast]);

  const listData = React.useMemo(() => {
    if (!occupation) {
      return [];
    }

    const {
      nameFunction,
      identifier,
      roles,
    } = occupation;

    return [
      {
        label: t('contacts:function'),
        value: nameFunction || '-',
      },
      {
        label: t('users:identifier'),
        value: identifier || '-',
      },
      {
        label: t('collaborators:level'),
        value: t(getI18nUserRights(roles[0])) || '-',
      },
      {
        label: t('users:signature-letter'),
        value: (
          <Button variant="link" onClick={showModalEditSignatureLetter}>{t('common:edit')}</Button>
        ),
      },
      {
        label: t('users:signature-email'),
        value: (
          <Button variant="link" onClick={showModalEditSignatureEmail}>{t('common:edit')}</Button>
        ),
      },
    ];
  }, [occupation, t, showModalEditSignatureLetter, showModalEditSignatureEmail]);

  if (isLoading) {
    return <Loading />;
  }

  if (!isLoading && !occupation) {
    return (
      <div className="SettingsProfile__error">
        {t('organizations:select-organization')}
      </div>
    );
  }

  return (
    <div className="SettingsProfileMyOrganization">
      <SettingsList data={listData} />
    </div>
  );
};

export default observer(SettingsProfileMyOrganization);
