import './index.scss';
import { useEffect, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import type Errors from 'types/errors';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormDatePicker from 'components/FormDatePicker';
import FormSwitch from 'components/FormSwitch';
import Wysiwyg from 'components/Wysiwyg';
import FormSelect from 'components/FormSelect';
import FormGroupWithActions from 'components/FormGroupWithActions';
import Button from 'components/Button';
import organizationStore from 'stores/Organization';
import type Misc from 'types/misc';
import getI18nChannel from 'utils/getI18nChannel';
import TagLink from 'components/TagLink';
import HistoryStepContentPreview from 'components/HistoryStepContentPreview';
import FormControlSMSContent from 'components/FormControlSMSContent';

type Props = {
  channel: Misc.Channel,
  errors?: Errors.Validation | null,
  template?: Misc.PlanTemplateReminder,
  withGroup?: boolean,
  onShowModalModels(): void,
};

const CreateReminderEditForm = (props: Props): JSX.Element => {
  const { channel, errors, template, withGroup = false, onShowModalModels } = props;
  const { t } = useTranslation();
  const { contactRoles } = organizationStore;
  const [name, setName] = useState<string>(template?.name || '');
  const [description, setDescription] = useState<string>(template?.description || '');
  const [subject, setSubject] = useState<string>(template?.subject || '');
  const [content, setContent] = useState<string>(template?.content || '');

  useEffect(() => {
    setName(template?.name || '');
    setDescription(template?.description || '');
    setSubject(template?.subject || '');
    setContent(template?.content || '');
  }, [template]);

  const [showPreviewContentModal, hidePreviewContentModal] = useModal(() => (
    <HistoryStepContentPreview
      content={content}
      channel={template?.channel || 'EMAIL'}
      onClose={hidePreviewContentModal}
    />
  ), [template, content]);

  return (
    <div className="CreateReminderEditForm">
      <FormFieldset>
        <FormGroup
          label={t('reminders:sending-date')}
          className="CreateReminderEditForm__date"
          mandatory
          error={errors?.startAt}
        >
          <FormDatePicker
            name="startAt"
            minDate={new Date()}
            isInvalid={!!errors?.startAt}
          />
        </FormGroup>
        <FormGroup label={t('reminders:short-description')} error={errors?.name} mandatory>
          <FormControl
            type="text"
            name="name"
            autoComplete="off"
            placeholder={t<string>('reminders:placeholder.short-description')}
            isInvalid={!!errors?.name}
            value={name}
            onChange={setName}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('reminders:description')} error={errors?.description}>
          <FormControl
            type="text"
            name="description"
            autoComplete="off"
            placeholder={t<string>('reminders:placeholder.description', { channel: t(getI18nChannel(channel)) })}
            isInvalid={!!errors?.description}
            value={description}
            onChange={setDescription}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup
          className="CreateReminderEditForm__need-validation"
          label={t('reminders:need-validation')}
          mandatory
          error={errors?.needValidation}
        >
          <FormSwitch name="needValidation" />
        </FormGroup>
        {withGroup && (
          <FormGroup
            className="CreateReminderEditForm__group-check"
            label={t('reminders:group-check-reminder-description')}
            error={errors?.group}
          >
            <FormSwitch name="group" />
          </FormGroup>
        )}
      </FormFieldset>
      <hr className="CreateReminderEditForm__separator" />
      {contactRoles && (
        <FormFieldset>
          <FormGroup label={t('reminders:recipients')} mandatory error={errors?.roles}>
            <FormSelect
              name="roles"
              placeholder={t('common:please-choose')}
              selectOptions={contactRoles?.map(
                ({ identifier, value }) => ({ label: value, value: identifier }),
              )}
              isInvalid={!!errors?.roles}
              isMultiple
            />
          </FormGroup>
        </FormFieldset>
      )}
      {channel === 'EMAIL' && (
        <FormFieldset>
          <FormGroupWithActions
            error={errors?.subject}
            label={t<string>('reminders:email-subject')}
            action={<Button variant="link" onClick={onShowModalModels}>{t('models:import')}</Button>}
          >
            <FormControl
              type="text"
              name="subject"
              autoComplete="off"
              isInvalid={!!errors?.subject}
              value={subject}
              onChange={setSubject}
              placeholder={t<string>('reminders:placeholder.subject')}
            />
          </FormGroupWithActions>
        </FormFieldset>
      )}
      <FormFieldset>
        <FormGroupWithActions
          label={channel === 'EMAIL' ? t<string>('reminders:email-content') : t<string>('reminders:content')}
          mandatory
          className="CreateReminderEditForm__content"
          action={<TagLink />}
          secondaryAction={(
            <Button
              variant="link"
              onClick={showPreviewContentModal}
            >
              {t('models:preview')}
            </Button>
          )}
          tertiaryAction={channel !== 'EMAIL'
            ? <Button variant="link" onClick={onShowModalModels}>{t('models:import')}</Button>
            : undefined}
          error={errors?.content}
        >
          {channel !== 'SMS' && (
            <Wysiwyg
              name="content"
              defaultValue={template?.content || ''}
              replaceValue={template?.content}
              toBePrinted={channel !== 'EMAIL'}
              onChange={setContent}
            />
          )}
          {channel === 'SMS' && (
            <FormControlSMSContent
              name="content"
              value={content}
              onChange={setContent}
            />
          )}
        </FormGroupWithActions>
      </FormFieldset>
    </div>
  );
};

export default observer(CreateReminderEditForm);
