import './index.scss';
import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

interface Props {
  dueAt: string,
  isActive: boolean,
}

const PaymentRequestDiffDays: React.FC<Props> = ({ dueAt, isActive }) => {
  const { t } = useTranslation();

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const payRequestDate = new Date(dueAt);
  payRequestDate.setHours(0, 0, 0, 0);
  const oneDayTime = 1000 * 60 * 60 * 24;
  const diffDays = Math.ceil((today.getTime() - payRequestDate.getTime()) / oneDayTime);

  const classNames = classnames('PaymentRequestDiffDays', {
    'PaymentRequestDiffDays--due': diffDays >= 0,
    'PaymentRequestDiffDays--not-due': diffDays < 0,
    'PaymentRequestDiffDays--inactive': !isActive,
  });

  let title = t('common:due');
  if (diffDays < 0) {
    title = t('common:not-due');
  }

  return (
    <div className={classNames} title={title}>
      ({t('common:days-first-letter')}{diffDays > 0 ? '+' : ''}{diffDays})
    </div>
  );
};

export default PaymentRequestDiffDays;
