import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import type Misc from 'types/misc';
import organizationStore from 'stores/Organization';
import TooltipProvider from 'components/Tooltip';
import useTooltip from 'components/Tooltip/useTooltip';
import FormCheck from 'components/FormCheck';
import Icon from 'components/Icon';
import DownloadLink from 'components/DownloadLink';
import ImportsFileWizardNav from './Nav';
import ImportsFileWizardStepHeader from './Header';
import ImportsFileWizardStepChoiceType from './ChoiceType';
import ImportsFileWizardStepChoiceFiles from './ChoiceFiles';
import filesReducer from './reducer';

const helpLink = 'https://dunforce.freshdesk.com/support/solutions/articles/6000245403-pr%C3%A9requis-g%C3%A9neraux-des-fichiers-d-import';

export interface RouteParams {
  type: 'csv-xlsx' | 'pdf',
}

const ImportsFileWizardStep: React.FC = () => {
  const { t } = useTranslation();
  const { type } = useParams<RouteParams>();

  const { useDatafix, currentOrganization } = organizationStore;

  const [stepNumber, setStepNumber] = React.useState<number>(type === 'pdf' ? 2 : 1);
  const [choices, setChoices] = React.useState<Misc.ImportWizardChoices | null>(
    type === 'pdf' ? { type: 'PAY_REQUEST_CREATE', files: null } : null,
  );
  const [files, dispatchFiles] = React.useReducer(filesReducer, []);
  const [datafix, setDatafix] = React.useState<boolean>(useDatafix || false);
  const [dispatch, setDispatch] = React.useState<boolean>(false);

  const tooltipDatafix = useTooltip(
    <div className="ImportsFileWizardStep__tooltip">{t('imports:datafix-description')}</div>,
    'clamped',
  );

  const tooltipDispatch = useTooltip(
    <div className="ImportsFileWizardStep__tooltip">{t('imports:dispatch-description')}</div>,
    'clamped',
  );

  const handleSelectType = React.useCallback((choice: Misc.TypeImport) => () => {
    setChoices({ type: choice, files: null });
  }, []);

  const getNavLink = React.useMemo(() => {
    const choice = choices?.type === 'PAY_REQUEST_CREATE' ? 'PAY_REQUEST' : 'PAY_REQUEST_STATUSES';
    return `${currentOrganization?.reference}/empty/${!choices?.type?.startsWith('PAY_REQUEST')
      ? choices?.type
      : choice}`;
  }, [choices, currentOrganization]);

  const handleNavigateNext = React.useCallback(() => {
    if (stepNumber < 3) {
      setStepNumber(stepNumber + 1);
    }
  }, [stepNumber]);

  const handleNavigatePrevious = React.useCallback(() => {
    if (stepNumber === 0) {
      return;
    }
    setStepNumber(stepNumber - 1);
  }, [stepNumber]);

  const handleNavigateTo = React.useCallback((step: number) => {
    if (step <= 0 || step >= 3) {
      return;
    }
    setStepNumber(step);
  }, []);

  const stepInfo = React.useMemo(() => {
    const steps = [
      {
        step: 1,
        title: t('imports:wizard.choice-type.type-file'),
        action: (
          <a
            aria-label="help"
            target="_blank"
            rel="noreferrer"
            href={helpLink}
            className="ImportsFileWizardStep__details__title__link"
          >
            <Icon name="help-circle" />
          </a>
        ),
        description: t('imports:wizard.choice-type.type-description'),
        content: <ImportsFileWizardStepChoiceType
          onSelectType={handleSelectType}
          type={type}
          choices={choices}
        />,
      },
      {
        step: 2,
        title: t('imports:wizard.choice-file.upload'),
        description: t('imports:wizard.choice-file.upload-description'),
        link: !datafix && (
          <DownloadLink path={getNavLink}>
            {t('imports:download-template')}
          </DownloadLink>
        ),
        content: (
          <ImportsFileWizardStepChoiceFiles
            dispatch={dispatch}
            fileType={choices?.type || null}
            step={stepNumber}
            datafix={datafix}
            type={type}
            files={files}
            dispatchFiles={dispatchFiles}
          />
        ),
      },
    ];

    return stepNumber < 2 ? steps[0] : steps[1];
  }, [t, handleSelectType, type, choices, datafix, getNavLink, files, dispatch, stepNumber]);

  return (
    <div className="ImportsFileWizardStep">
      <ImportsFileWizardStepHeader
        type={type}
        choices={choices}
        countFiles={stepNumber === 3 ? files.length : 0}
      />

      <div className="ImportsFileWizardStep__content">
        <div className="ImportsFileWizardStep__side-view">

          <div className="ImportsFileWizardStep__details">
            <div className="ImportsFileWizardStep__details__step-number">
              {type === 'pdf' ? stepInfo.step - 1 : stepInfo.step}
            </div>
            <div className="ImportsFileWizardStep__details__title">
              {stepInfo.title} {stepInfo.action}
            </div>
          </div>

          <div className="ImportsFileWizardStep__description">
            {stepInfo.description} {type === 'csv-xlsx' && stepInfo?.link}
          </div>
          <div className="ImportsFileWizardStep__options">
            {stepNumber === 2 && type !== 'pdf' && (
              <div className="ImportsFileWizardStep__options__check">
                <FormCheck
                  label=""
                  checked={datafix}
                  onChange={setDatafix}
                />
                <p className="ImportsFileWizardStep__options__check__label">
                  {t('imports:datafix')}
                </p>
                <TooltipProvider>
                  <span ref={tooltipDatafix} className="ImportsFileWizardStep__options__check__tooltip">
                    <Icon name="help-circle" />
                  </span>
                </TooltipProvider>
              </div>
            )}
            {stepNumber === 2 && type === 'pdf' && (
              <div className="ImportsFileWizardStep__options__check">
                <FormCheck
                  label=""
                  checked={dispatch}
                  onChange={setDispatch}
                />
                <p className="ImportsFileWizardStep__options__check__label">{t('imports:without-dispatch')}</p>
                <TooltipProvider>
                  <span ref={tooltipDispatch} className="ImportsFileWizardStep__options__check__tooltip">
                    <Icon name="help-circle" />
                  </span>
                </TooltipProvider>
              </div>
            )}
            {stepNumber > 2 && type !== 'pdf' && (
              <p>{t('imports:datafix')} : {datafix ? t('common:yes') : t('common:no')}</p>
            )}
            {stepNumber > 2 && type === 'pdf' && (
              <p>{t('imports:without-dispatch')} : {dispatch ? t('common:yes') : t('common:no')}</p>
            )}
          </div>
        </div>
        <div className="ImportsFileWizardStep__main-view">
          {stepInfo.content}
        </div>
      </div>
      <ImportsFileWizardNav
        type={type}
        stepNumber={stepNumber}
        onNext={handleNavigateNext}
        onPrevious={handleNavigatePrevious}
        onNavigateTo={handleNavigateTo}
        hasSelectedType={choices?.type !== undefined}
        hasSelectedFiles={files.length > 0}
      />
    </div>
  );
};

export default ImportsFileWizardStep;
