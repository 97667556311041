import './index.scss';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import type { DefaultTFuncReturn } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useTable, useSortBy, usePagination, Column, Row } from 'react-table';
import useDebouncedEffect from 'hooks/useDebouncedEffect';
import Config from 'config';
import type Errors from 'types/errors';
import type Misc from 'types/misc';
import useSelection, { SelectionState } from 'hooks/useSelection';
import useMountedEffect from 'hooks/useMountedEffect';
import Loading from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';
import SearchInput from 'components/SearchInput';
import NoResultsFound from 'components/NoResultsFound';
import DataTableFilters from './Filters';
import DataTableActiveFilters from './ActiveFilters';
import DataTableFiltersPortal from './Filters/Sidebar';
import DataTableHead from './Head';
import DataTableBody from './Body';
import Footer from './Footer';
import Export from './Export';

type Props<DataType extends object = {}> = {
  columns: Column<DataType>[],
  data: DataType[] | null,
  serverPagination: Misc.Pagination | null,
  defaultSortBy: { id: string, desc?: boolean },
  statusSelector?: React.ReactNode,
  filtersList?: Misc.FilterDeclarationItem[],
  fetchData: (options: Misc.PaginatedFetchArgs<DataType>) => void,
  isLoading: boolean,
  error: Errors.Request | null,
  onErrorDissmiss?: () => void,
  bulkActions?: JSX.Element,
  withActions?: boolean,
  hasActions?: (row: Row<DataType>) => boolean,
  sidePortal?: HTMLElement | null,
  filtersSettings?: Misc.FiltersSettings,
  noHeader?: boolean,
  noDataFoundMessage?: DefaultTFuncReturn,
  defaultPageSize?: number,
  withPagination?: boolean,
  withNoDataDrawing?: boolean,
  filtering?: Misc.Filter[],
  onChangeFilters?: (filters: Misc.Filter[]) => void,
  onRemoveFilter?: (name: string) => void,
  exportLink?: string,
  withPortalExportLink?: boolean,
  bulkGender?: 'm' | 'f',
  getRowClassName?: (row: Row<DataType>) => string,
  idAccessor?: string,
  onSelectBulk?: (select: SelectionState) => void,
  defaultSelected?: SelectionState,
};

const DataTable = <DataType extends object = {}>(props: Props<DataType>): JSX.Element | null => {
  const { t } = useTranslation();
  const {
    columns,
    data,
    serverPagination,
    defaultSortBy,
    statusSelector,
    filtersList,
    fetchData,
    isLoading,
    error,
    onErrorDissmiss,
    bulkActions,
    withActions = true,
    hasActions = () => true,
    noHeader = false,
    sidePortal,
    filtersSettings,
    noDataFoundMessage,
    defaultPageSize,
    withPagination = true,
    withNoDataDrawing = false,
    filtering,
    onChangeFilters,
    onRemoveFilter,
    exportLink,
    withPortalExportLink = false,
    bulkGender = 'f',
    getRowClassName,
    onSelectBulk,
    idAccessor,
    defaultSelected,
  } = props;

  const defaultPagination = {
    page: 1,
    totalPages: 1,
    totalRecords: 0,
    recordsPerPage: Config.DEFAULT_PAGE_SIZE,
  };

  const {
    totalPages,
    totalRecords,
    recordsPerPage,
  } = serverPagination || defaultPagination;

  const [pendingFilters, setPendingFilters] = useState<Misc.Filter[]>(filtering || []);

  const initialState = useMemo(() => ({
    pageIndex: 0,
    pageSize: defaultPageSize || Config.DEFAULT_PAGE_SIZE,
    sortBy: [defaultSortBy],
  }), [defaultSortBy, defaultPageSize]);

  const tableInstance = useTable<DataType>(
    {
      columns,
      data: data || [],
      initialState,
      pageCount: totalPages,
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: true,
      disableSortRemove: true,
    },
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    prepareRow,
    state: { pageIndex, pageSize, sortBy },
  } = tableInstance;

  const select = useSelection(page, hasActions);

  useEffect(() => {
    if (onSelectBulk) {
      onSelectBulk(select.selection);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [select]);

  const currentPageIndex = useRef<number>(0);

  const handleFetchData = useCallback(() => {
    const options = {
      pageIndex,
      pageSize,
      sort: sortBy[0],
    };
    fetchData(options);
  }, [fetchData, pageIndex, pageSize, sortBy]);

  useEffect(() => {
    if (!defaultSelected) {
      select.clearSelection();
      return;
    }
    defaultSelected.forEach((rowId) => {
      if (!select.isSelected(rowId)) {
        select.toggleSelect(rowId)();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data, defaultSelected]);

  useMountedEffect(() => {
    currentPageIndex.current = pageIndex;
    handleFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageSize]);

  useEffect(() => {
    setPendingFilters(filtering || []);
  }, [filtering]);

  const handleFilterChange = useCallback(() => {
    if (onChangeFilters) {
      onChangeFilters(pendingFilters);
    }
  }, [onChangeFilters, pendingFilters]);

  const handleTmpFilterChange = useCallback((newFilter: Misc.Filter) => {
    setPendingFilters((prevFilters: Misc.Filter[]) => {
      const newFilters = [...prevFilters];
      const index = prevFilters.findIndex(({ name }) => name === newFilter.name);
      if (index > -1) {
        newFilters[index] = newFilter;
      } else {
        newFilters.push(newFilter);
      }
      return newFilters;
    });
  }, []);

  const handleCancelFilterChange = useCallback(() => {
    setPendingFilters(filtering || []);
  }, [filtering]);

  const handleOnSearchChange = useCallback((search: string | null) => {
    if (!onChangeFilters || !onRemoveFilter) {
      return;
    }
    if (search && search.length > 2) {
      onChangeFilters([{ name: 'search', value: search }]);
      return;
    }
    onRemoveFilter('search');
  }, [onChangeFilters, onRemoveFilter]);

  useDebouncedEffect(() => {
    if (currentPageIndex.current !== 0) {
      gotoPage(0);
    } else {
      handleFetchData();
    }
  }, 150, [sortBy, filtering]);

  const searchTerm = useMemo(() => (
    filtering?.find(({ name }) => name === 'search')?.value as string
  ), [filtering]);

  const hasData = !!data && data.length > 0;

  const className = classnames('DataTable', {
    'DataTable--no-header': noHeader,
    'DataTable--no-actions': !withActions,
  });

  return (
    <div className={className}>
      {!noHeader && (
        <header className="DataTable__header">
          {statusSelector && (
            <div className="DataTable__header__status-selector">
              {statusSelector}
            </div>
          )}
          {!sidePortal && (
            <>
              <div className="DataTable__header__search">
                <SearchInput
                  initialSearchTerm={searchTerm}
                  placeholder={t('common:search-in-table')}
                  onChange={handleOnSearchChange}
                />
              </div>
              <div className="DataTable__header__actions">
                {(filtersList && filtersList.length > 0) && (
                  <DataTableFilters
                    filtersList={filtersList}
                    currentFilters={pendingFilters || []}
                    onValidate={handleFilterChange}
                    onCancel={handleCancelFilterChange}
                    onChange={handleTmpFilterChange}
                  />
                )}
              </div>
            </>
          )}
          {exportLink && !withPortalExportLink && (
            <Export exportLink={exportLink} />
          )}
          {(filtersList && filtering && filtering.length > 0 && onRemoveFilter) && (
            <DataTableActiveFilters
              filtersSettings={filtersSettings}
              filtersList={filtersList}
              currentFilters={filtering || []}
              onRemove={onRemoveFilter}
            />
          )}
        </header>
      )}
      {error && <ErrorMessage error={error} onClose={onErrorDissmiss} />}
      <div className="DataTable__table-wrapper">
        {isLoading && <Loading />}
        <table {...getTableProps()} className="DataTable__table">
          {(!isLoading && hasData) && (
            <DataTableHead<DataType>
              headerGroups={headerGroups}
              select={select}
              withSelectAll={withActions}
            />
          )}
          {page.length > 0 && (
            <DataTableBody<DataType>
              tableProps={getTableBodyProps()}
              page={page}
              prepareRow={prepareRow}
              select={select}
              withActions={withActions}
              hasActions={hasActions}
              getRowClassName={getRowClassName}
              idAccessor={idAccessor}
            />
          )}
        </table>
      </div>
      {(!isLoading && !hasData) && (
        withNoDataDrawing
          ? <NoResultsFound />
          : (
            <Fragment>
              <div className="DataTable__no-data">
                {noDataFoundMessage || t('common:no-data-was-found')}
              </div>
              {searchTerm && (
                <div className="DataTable__no-data">
                  {t('common:try-another-search-term')}
                </div>
              )}
              {filtering && filtering.length > 0 && (
                <div className="DataTable__no-data">
                  {t('common:try-modify-filters')}
                </div>
              )}
            </Fragment>
          )
      )}
      {withPagination && (
        <Footer<DataType>
          tableInstance={tableInstance}
          totalRecords={totalRecords}
          select={select}
          bulkActions={bulkActions}
          hasMultiplePages={totalRecords > recordsPerPage}
          bulkGender={bulkGender}
          displaySelectedCount={!onSelectBulk}
        />
      )}
      {sidePortal && filtersList && (
        <DataTableFiltersPortal
          filtersList={filtersList}
          currentFilters={pendingFilters || []}
          initialSearchTerm={searchTerm}
          onSearchChange={handleOnSearchChange}
          onCancel={handleCancelFilterChange}
          onApplyFilters={handleFilterChange}
          onFilterChange={handleTmpFilterChange}
          container={sidePortal}
          filtersSettings={filtersSettings || undefined}
          exportLink={(withPortalExportLink && exportLink) || undefined}
        />
      )}
    </div>
  );
};

export default DataTable;
