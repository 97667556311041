import './index.scss';
import { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import organizationStore from 'stores/Organization';
import type { FetchLastParams, LastStepsResult } from 'api/historySteps';
import { fetchLast } from 'api/historySteps';
import useFetch from 'hooks/useFetch';
import Loading from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';
import ActionButton from 'components/ActionButton';
import Icon from 'components/Icon';
import HomeMyActionsItem from './Item';
import { ReactComponent as NoAction } from './noAction.svg';

type Props = {
  onCountActions(count: number): void,
};

const HomeMyActions = ({ onCountActions }: Props): JSX.Element => {
  const { currentOrganization, walletSelection } = organizationStore;
  const history = useHistory();
  const { t } = useTranslation();

  const filtering = useMemo(() => {
    if (walletSelection.length === 0) {
      return [];
    }
    return [{ name: 'categories', value: walletSelection }];
  }, [walletSelection]);

  const { isLoading, data, error } = useFetch<FetchLastParams, LastStepsResult>(
    {
      cacheKey: 'myActions',
      organization: currentOrganization?.reference,
      filtering,
    },
    fetchLast,
    { refetchOnWindowFocus: false },
  );

  useEffect(() => {
    if (data?.count !== undefined) {
      onCountActions(data.count);
    }
  }, [onCountActions, data]);

  const handleShowView = useCallback(() => {
    history.push('/actions-future');
  }, [history]);

  return (
    <div className="HomeMyActions">
      {isLoading && <Loading hasNoText />}
      {error && <ErrorMessage error={error} />}
      {!isLoading && data?.records && data.records.length > 0 && (
        <ul className="HomeMyActions__list">
          {data.records.map((step) => (
            <HomeMyActionsItem data={step} onShowView={handleShowView} key={step.id} />
          ))}
        </ul>
      )}
      {!isLoading && (!data?.records || data.records.length === 0) && (
        <div className="HomeMyActions__no-data">
          {t('actions:no-action')}
          <div className="HomeMyActions__no-data__drawing">
            <NoAction className="HomeMyActions__no-data__drawing__icon" />
          </div>
          <div className="HomeMyActions__no-data__actions">
            <ActionButton
              icon={<Icon name="list" />}
              title={t('actions:actions-future')}
              onClick={handleShowView}
            />
            <ActionButton
              icon={<Icon name="send" />}
              title={t('actions:actions-done')}
              onClick={() => history.push('/actions-done')}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(HomeMyActions);
