import './index.scss';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useModal } from 'react-modal-hook';
import type { CustomerPayment } from 'types/models';
import formatIntlNumber from 'utils/formatIntlNumber';
import getI18nPaymentStatus from 'utils/getI18nPaymentStatus';
import formatIntlDate from 'utils/formatIntlDate';
import organizationStore from 'stores/Organization';
import useTooltip from 'components/Tooltip/useTooltip';
import Button from 'components/Button';
import Modal from 'components/Modal';

type Props = {
  data: CustomerPayment,
  showEditPaymentModal(reference: string): void,
};

const CustomerAccountDetailsStatusPaymentCell = ({ data, showEditPaymentModal }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { reference, status, date, payRequests, amountNotAssigned } = data;
  const { currency, userRight } = organizationStore;
  const isUserReader = userRight === 'ROLE_READER';
  const isImputable = status === 'SUCCESS';
  const isImputed = payRequests && payRequests.length > 0 && amountNotAssigned < 0.0001;

  const [showImputationsModal, hideImputationsModal] = useModal(() => (
    <Modal
      title={t('payments:imputations')}
      onClose={hideImputationsModal}
      isOpened
    >
      <h4 className="CustomerAccountDetailsStatusPaymentCell__imputations-title">
        {t('payments:invoices-imputed-to-payment-ref', { reference, date: formatIntlDate(date) })}
      </h4>
      <ul className="CustomerAccountDetailsStatusPaymentCell__imputations-list">
          {payRequests?.map(({ id, reference: payRequestReference }) => (
            <li key={id} className="CustomerAccountDetailsStatusPaymentCell__imputations-list__item">
              <Link
                to={`/payment-request/${id}`}
                className="CustomerAccountDetailsStatusPaymentCell__imputations-link"
              >
                {payRequestReference}
              </Link>
            </li>
          ))}
      </ul>
    </Modal>
  ), [reference, date, payRequests, t]);

  const tooltipAmountLeft = useTooltip<HTMLSpanElement>(
    <div className="CustomerAccountDetailsStatusPaymentCell__tooltip">
      {t('payments:amount-left', {
        amount: formatIntlNumber(Math.abs(amountNotAssigned), currency, 2),
      })}
    </div>,
  );

  if (!isImputable) {
    return (
      <Fragment>
        {status === 'FAILED' ? t(getI18nPaymentStatus('FAILED')) : t('payments:payment-not-assignable')}
      </Fragment>
    );
  }

  if (isImputed) {
    return (
      <div
        className="CustomerAccountDetailsStatusPaymentCell CustomerAccountDetailsStatusPaymentCell--imputed"
        onClick={showImputationsModal}
        role="button"
        title={t('payments:see-payment-imputations') ?? undefined}
      >
        {t('payments:imputed')}
      </div>
    );
  }

  return (
    <div className="CustomerAccountDetailsStatusPaymentCell" ref={tooltipAmountLeft} >
      <Button
        onClick={() => { showEditPaymentModal(reference); }}
        variant={isUserReader ? 'outline' : 'outline-white'}
        disabled={isUserReader}
      >
        {t('payments:payment-not-assigned')}
      </Button>
    </div>
  );
};

export default CustomerAccountDetailsStatusPaymentCell;
