import './index.scss';
import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import fetchAll, { FetchAllParams, SearchResultsData } from 'api/globalSearch';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useFetch from 'hooks/useFetch';
import organizationStore from 'stores/Organization';
import ErrorMessage from 'components/ErrorMessage';
import ResultCustomers from './Customers';
import ResultPayRequests from './PayRequests';

type Props = {
  onLoadingChange(loading: boolean): void,
  search: string,
  onEmptySearchChange(isEmpty: boolean): void,
};

const HeaderSearchResults = (props: Props): JSX.Element => {
  const { onEmptySearchChange, onLoadingChange, search } = props;
  const { linesOfBusiness, walletSelection, currentOrganization } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);

  const filtering = useMemo(() => {
    if (walletSelection.length === 0) {
      return [];
    }
    return [{ name: 'categories', value: walletSelection }];
  }, [walletSelection]);

  const {
    data: searchResults,
    isLoading,
    error,
  } = useFetch<FetchAllParams, SearchResultsData>({
    cacheKey: 'globalSearch',
    organization: currentOrganization?.reference,
    filtering,
    searchTerm: search,
  }, fetchAll, { retry: false });

  const isEmptySearchResults = useMemo(() => (
    (!error && (
      !searchResults
      || (
        searchResults.clients.length === 0
        && searchResults.payRequests.length === 0
      )
    ))
  ), [error, searchResults]);

  useEffect(() => {
    onLoadingChange(isLoading);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    onEmptySearchChange(isEmptySearchResults);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmptySearchResults]);

  return (
    <div className="HeaderSearchResults">
      {isEmptySearchResults && (
        <p className="HeaderSearchResults__no-result">{t('common:no-result')}.</p>
      )}
      {error && <ErrorMessage error={error} />}
      {searchResults && searchResults.payRequests.length > 0 && (
        <div className="HeaderSearchResults__pay-requests">
          <h3 className="HeaderSearchResults__title">
            {ct('common:bills')}
            <span className="HeaderSearchResults__count">
              ({searchResults.payRequestsCount})
            </span>
          </h3>
          <ResultPayRequests data={searchResults.payRequests} search={search} />
        </div>
      )}
      {searchResults && searchResults.clients.length > 0 && (
        <div className="HeaderSearchResults__clients">
          <h3 className="HeaderSearchResults__title">
            {ct('common:customers')}
            <span className="HeaderSearchResults__count">
              ({searchResults.clientsCount})
            </span>
          </h3>
          <ResultCustomers data={searchResults.clients} search={search} />
        </div>
      )}
    </div>
  );
};

export default observer(HeaderSearchResults);
