import './index.scss';
import { useTranslation } from 'react-i18next';
import type { Client } from 'types/models';
import CustomerStats from './Stats';

type Props = {
  data: Client,
};

const CustomerAnalysis = ({ data }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="CustomerAnalysis">
      <h2 className="CustomerAnalysis__title">
        {t('common:analysis')}
      </h2>
      <div className="CustomerAnalysis__content">
        {data.statistics && <CustomerStats data={data.statistics} />}
        {!data.statistics && (
          <p className="CustomerAnalysis__no-data">
            {t('common:no-data')}
          </p>
        )}
      </div>
    </div>
  );
};

export default CustomerAnalysis;
