import * as React from 'react';
import { useTranslation } from 'react-i18next';
import type Errors from 'types/errors';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';

interface Props {
  errors?: Errors.Validation | null,
}

const PasswordEditForm: React.FC<Props> = ({ errors }) => {
  const { t } = useTranslation();

  return (
    <div className="PasswordEditForm">
      <FormGroup label={t('users:password-active')} error={errors?.passwordActive}>
        <FormControl
          type="password"
          name="passwordActive"
          autoComplete="off"
          isInvalid={!!errors?.passwordActive}
        />
      </FormGroup>
      <FormGroup label={t('users:password-new')} error={errors?.passwordNew}>
        <FormControl
          type="password"
          name="passwordNew"
          autoComplete="off"
          isInvalid={!!errors?.passwordNew}
        />
      </FormGroup>
      <FormGroup label={t('users:password-new-repeat')} error={errors?.passwordNewRepeat}>
        <FormControl
          type="password"
          name="passwordNewRepeat"
          autoComplete="off"
          isInvalid={!!errors?.passwordNewRepeat}
        />
      </FormGroup>
    </div>
  );
};

export default PasswordEditForm;
