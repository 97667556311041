import type Misc from 'types/misc';

const getI18nPaymentAttribution = (
  paymentAttribution: Misc.OrganizationPaymentAttribution,
): string => {
  if (paymentAttribution === 'AUTOMATIC_NEWER') {
    return 'customization:payment-attributions.automatic-newer';
  } if (paymentAttribution === 'AUTOMATIC_OLDER') {
    return 'customization:payment-attributions.automatic-older';
  }
  return 'customization:payment-attributions.manual';
};

export default getI18nPaymentAttribution;
