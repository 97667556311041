import { TFunction } from 'i18next';
import DataTableFilterItem from 'components/DataTable/Filters/Item';
import type Misc from 'types/misc';
import getI18nActionSelectStatus from 'utils/getI18nActionSelectStatus';
import EventsFilter, { EVENT_FILTERS } from './EventsFilter';

const showAllOrFilters = (
  filter: Misc.Filter,
  badgeAllValues: string[],
  filterValues: string[],
  label: string,
  allSelectedText: string,
  key: string,
  t: TFunction,
): Misc.CurrentFilterItem | null => {
  const badgeValues = [...filterValues.filter(
    (filterValue) => badgeAllValues.includes(filterValue),
  )];

  const badgeLabel = Array.isArray(filter.value)
    ? filter.value?.map((value) => t(getI18nActionSelectStatus(value))).join(', ')
    : t(getI18nActionSelectStatus(filter.value || 'reminder_to_do'));

  if (badgeAllValues.every((value) => badgeValues.includes(value))) {
    return {
      value: allSelectedText,
      name: filter.name,
      getRemovedValue: () => [...filterValues.filter(
        (filterValue) => !badgeAllValues.includes(filterValue),
      )],
      key: `${filter.name}-${key}`,
    };
  }

  if (badgeAllValues.some((value) => badgeValues.includes(value))) {
    return {
      label,
      value: badgeLabel,
      name: filter.name,
      getRemovedValue: () => [...filterValues.filter(
        (filterValue) => !badgeAllValues.includes(filterValue),
      )],
      key: `${filter.name}-${key}`,
    };
  }

  return null;
};

const actionsFiltersSettings: Misc.FiltersSettings = {
  sideKeys: [
    'channel',
    'dunningPlan',
    'amountDueFrom',
    'amountDueTo',
    'dueDebtFrom',
    'dueDebtTo',
    'attribute',
    'attributeValue',
  ],
  renderFilters: (filtersRenderer) => {
    const { filters, getFilterValue } = filtersRenderer;

    const hasCustomPeriod = getFilterValue('period')?.toString() === 'customized';
    const typeFilters = filters.find((filter) => filter.key === 'type');

    if (!typeFilters) {
      return <></>;
    }

    const filtersRendered = [
      <EventsFilter
        key={`${typeFilters.key}-categories`}
        filter={typeFilters}
        {...filtersRenderer}
      />,
      ...filters.filter(({ key }) => (
        ((key !== 'periodA' && key !== 'periodB') || hasCustomPeriod)
        && key !== 'type'
      )).map((filter) => (
        <DataTableFilterItem
          key={filter.key}
          filter={filter}
          {...filtersRenderer}
        />
      )),
    ];

    return <>{filtersRendered}</>;
  },

  getCurrentFilters: (getFilterLabel, filtersList, activeFilters, t) => {
    const specialFilters = ['type'];

    const allBadges = activeFilters
      .filter(({ name }) => !specialFilters.includes(name))
      .map(({ name, value }): Misc.CurrentFilterItem => ({
        label: `${getFilterLabel(name)}`,
        value,
        name,
        key: name,
        getRemovedValue: () => null,
      }));

    const typeFilter = activeFilters.find(({ name }) => name === 'type');
    if (typeFilter) {
      const typeValues = Array.isArray(typeFilter.value) ? typeFilter.value : [typeFilter.value ?? ''];
      const typeBadge = showAllOrFilters(
        typeFilter,
        EVENT_FILTERS,
        typeValues,
        t('actions:type'),
        t('actions:actions-all'),
        'type',
        t,
      );
      if (typeBadge) {
        allBadges.push(typeBadge);
      }
    }

    return allBadges;
  },
};

export default actionsFiltersSettings;
