import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Column } from 'react-table';
import Config from 'config';
import type Misc from 'types/misc';
import type { PayRequestForList } from 'types/models';
import organizationStore from 'stores/Organization';
import authStore from 'stores/Auth';
import userRightsStore from 'stores/UserRights';
import payRequestsFilters from 'stores/FilterStores/PayRequestsFilters';
import { fetchAll } from 'api/pay-requests';
import type { FetchAllParams } from 'api/pay-requests';
import { fetchCount, FetchCountParams } from 'api/pay-requests-draft';
import useFetchPaginated from 'hooks/useFetchPaginated';
import useFetch from 'hooks/useFetch';
import useContextualTranslation from 'hooks/useContextualTranslation';
import PageList from 'components/PageList';
import DataTable from 'components/DataTable';
import Button from 'components/Button';
import ToastNotification from 'components/ToastNotification';
import useToast from 'components/ToastNotification/useToast';
import LinkSwitch from 'components/LinkSwitch';
import { checkIsAllowed } from 'components/ExternallyMonitored';
import Icon from 'components/Icon';
import getFiltersQueryString from 'utils/getFiltersQueryString';
import PaymentRequestEditPage from '../PaymentRequestEdit';
import PayRequestsContext from './context';
import PaymentRequestFilters from './Filters';
import PayRequestColumns from './Columns';
import BulkActions from './Actions/Bulk';
import StatusSelect from './StatusSelect';
import ExportLink from './ExportLink';

export type RouteParams = {
  status?: Misc.PayRequestStatus,
  payRequestRef?: string,
};

const PaymentRequests = (): JSX.Element => {
  const { status, payRequestRef } = useParams<RouteParams>();
  const history = useHistory();
  const {
    currentOrganization,
    walletSelection,
    linesOfBusiness,
    isExternallyMonitored,
  } = organizationStore;
  const { rights } = userRightsStore;
  const { user, isLogged, isFetched } = authStore;
  const mayBeLogged = !!user || (isFetched && isLogged);
  const isSuperAdmin = !!((user && mayBeLogged && user.roles.includes('ROLE_SUPER_ADMIN')));
  const { ct } = useContextualTranslation(linesOfBusiness);
  const [actionsCount, setActionsCount] = useState<number>(0);
  const defaultFetchOptions = {
    pageIndex: 0,
    pageSize: Config.DEFAULT_PAGE_SIZE,
    sort: { id: 'dueAt', desc: true },
  };
  const [fetchOptions, setFetchOptions] = useState<Misc.PaginatedFetchArgs<PayRequestForList>>(
    defaultFetchOptions,
  );

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const {
    filters,
    addOrUpdateFilters,
    removeFilter,
    resetAllFilters,
  } = payRequestsFilters;

  useEffect(() => {
    resetAllFilters();
  }, [resetAllFilters]);

  const filtering = useMemo(() => {
    if (walletSelection.length === 0) {
      return filters;
    }
    return [...filters, { name: 'categories', value: walletSelection }];
  }, [filters, walletSelection]);

  const {
    data,
    serverPagination,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useFetchPaginated<FetchAllParams, PayRequestForList>({
    cacheKey: 'payRequests',
    organization: currentOrganization?.reference,
    filtering,
    fetchOptions,
  }, fetchAll, { enabled: !!currentOrganization });

  const {
    isLoading: isCountLoading,
    data: draftCount,
  } = useFetch<FetchCountParams, Misc.CountRecords>({
    cacheKey: 'draftCount',
    organization: currentOrganization?.reference,
  }, fetchCount, { enabled: !!currentOrganization });

  const handleActionDone = useCallback((message: string) => {
    showToast(message, 'success');
    setActionsCount(actionsCount + 1);
    refetch();
  }, [showToast, refetch, setActionsCount, actionsCount]);

  const handleActionError = useCallback((message: string) => {
    showToast(message, 'error');
  }, [showToast]);

  const columns = useMemo<Column<PayRequestForList>[]>(
    () => PayRequestColumns(ct, handleActionDone, handleActionError),
    [ct, handleActionDone, handleActionError],
  );

  const filtersList = useMemo<Misc.FilterDeclarationItem[]>(
    () => PaymentRequestFilters(ct, {
      status: status as Misc.PayRequestStatus,
    }),
    [ct, status],
  );

  const totalResults = useMemo(() => (
    filters.length > 0 ? serverPagination?.totalRecords : null
  ), [filters, serverPagination]);

  const handleStatusSelector = useCallback((newStatus: Misc.PayRequestStatus) => {
    addOrUpdateFilters([{ name: 'status', value: newStatus }]);
  }, [addOrUpdateFilters]);

  const exportLink = useMemo<string>(
    () => ExportLink(fetchOptions, filters, currentOrganization?.reference || null),
    [currentOrganization, fetchOptions, filters],
  );

  const currentStatus = useMemo(() => (
    filters.find(({ name }) => name === 'status')?.value as Misc.PayRequestStatus || 'IN_PROGRESS'
  ), [filters]);

  const handleSwitchChange = useCallback((listing: string) => {
    if (!listing.startsWith('payment-requests')) {
      history.push(`/${listing}?${getFiltersQueryString(filters, ['search', 'client'])}`);
    }
  }, [history, filters]);

  const switchListing = (
    <LinkSwitch
      valueLabels={[
        { value: 'payment-requests', label: ct('payment-requests:bills') },
        { value: 'payments', label: ct('payment-requests:payments') },
      ]}
      defaultValue="payment-requests"
      onChange={handleSwitchChange}
    />
  );

  return (
    <PayRequestsContext.Provider value={{ currentStatus }}>
      <PageList
        className="PaymentRequests"
        count={totalResults}
        isFetching={isFetching}
        title={ct('payment-requests:title')}
        actions={switchListing}
      >
        {!isCountLoading && draftCount?.totalRecords !== 0 && (
          <Button
            variant="link-danger"
            className="PaymentRequests__draft-link"
            href="payment-requests/draft"
          >
            <Icon name="warning-circle" />
            {ct('payrequests-draft:invoices-to-complete', { count: draftCount?.totalRecords })}
          </Button>
        )}
        <DataTable<PayRequestForList>
          columns={columns}
          data={data}
          serverPagination={serverPagination}
          defaultSortBy={defaultFetchOptions.sort}
          filtering={filters}
          onChangeFilters={addOrUpdateFilters}
          onRemoveFilter={removeFilter}
          statusSelector={(
            <StatusSelect
              refreshTick={actionsCount}
              status={currentStatus}
              onSetFilters={handleStatusSelector}
            />
          )}
          filtersList={filtersList}
          fetchData={setFetchOptions}
          isLoading={isLoading}
          noDataFoundMessage={ct('payment-requests:no-data')}
          error={error}
          withNoDataDrawing
          withActions={
            rights !== null && checkIsAllowed(false, isExternallyMonitored, isSuperAdmin)
          }
          bulkActions={(
            <BulkActions
              onActionDone={handleActionDone}
              onActionError={handleActionError}
            />
          )}
          exportLink={exportLink}
        />
        {payRequestRef && (
          <PaymentRequestEditPage
            onDone={handleActionDone}
            onClose={() => { }}
            finallyRedirectTo="/payment-requests/IN_PROGRESS"
          />
        )}
        <ToastNotification
          text={toastText}
          status={toastStatus}
          isShow={isToastShowed}
          onClose={hideToast}
        />
      </PageList>
    </PayRequestsContext.Provider>
  );
};

export default observer(PaymentRequests);
