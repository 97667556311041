import './index.scss';
import React from 'react';
import type { DefaultTFuncReturn } from 'i18next';
import classnames from 'classnames';

export type Props = {
  /** @default text */
  type?: 'text' | 'number' | 'email' | 'password' | 'search' | 'hidden' | 'textarea' | 'file',
  name?: string,
  value?: string | number | readonly string[],
  defaultValue?: string | number | readonly string[],
  placeholder?: DefaultTFuncReturn,
  autoComplete?: 'off',
  step?: number,
  onChange?: (newValue: string) => void,
  onFileChange?: (newValue: File) => void,
  onMouseEnter?: (e: React.MouseEvent<HTMLInputElement>) => void,
  onMouseOut?: (e: React.MouseEvent<HTMLInputElement>) => void,
  onFocus?: (e: React.SyntheticEvent<HTMLInputElement>) => void,
  onBlur?: (e: React.SyntheticEvent<HTMLInputElement>) => void,
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
  id?: string,
  hasWarning?: boolean,
  className?: string,
  isInvalid?: boolean,
  readOnly?: boolean,
  disabled?: boolean,
  title?: string,
  /** Utile pour le type 'textarea' uniquement (nombre de lignes) */
  size?: number,
  /** Utile pour le type 'file' uniquement */
  acceptFiles?: 'image/*' | '.pdf',
  min?: number,
};

const FormControl = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    type = 'text',
    name,
    value,
    defaultValue,
    placeholder,
    autoComplete,
    step,
    onChange,
    onFileChange,
    onMouseEnter,
    onMouseOut,
    onFocus,
    onBlur,
    onKeyDown,
    id,
    className,
    isInvalid = false,
    hasWarning = false,
    readOnly,
    disabled = false,
    size,
    title,
    acceptFiles,
    min,
  } = props;

  const handleTextAreaChange = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (onChange) {
        onChange(e.currentTarget.value);
      }
    },
    [onChange],
  );

  const handleInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e.currentTarget.value);
      }
    },
    [onChange],
  );

  const handleInputFileChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onFileChange && e.currentTarget.files) {
        onFileChange(e.currentTarget.files[0]);
      }
    },
    [onFileChange],
  );

  const classNames = React.useMemo(() => (
    classnames('FormControl', className, {
      'FormControl--textarea': type === 'textarea',
      'FormControl--hidden': type === 'hidden',
      'FormControl--not-valid': isInvalid,
      'FormControl--warning': hasWarning,
      'FormControl--disabled': disabled,
    })
  ), [className, type, isInvalid, hasWarning, disabled]);

  if (type === 'textarea') {
    return (
      <textarea
        id={id}
        name={name}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder ?? undefined}
        onChange={handleTextAreaChange}
        rows={size}
        readOnly={readOnly}
        disabled={disabled}
        className={classNames}
        title={title}
      // eslint-disable-next-line react/jsx-closing-tag-location
      >{value}</textarea>
    );
  }

  if (type === 'file') {
    return (
      <input
        ref={ref}
        id={id}
        name={name}
        value={value}
        step={step}
        defaultValue={defaultValue}
        autoComplete={autoComplete}
        placeholder={placeholder ?? undefined}
        onChange={handleInputFileChange}
        onMouseEnter={onMouseEnter}
        onMouseOut={onMouseOut}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        type={type}
        readOnly={readOnly}
        disabled={disabled}
        className={classNames}
        title={title}
        accept={acceptFiles}
      />
    );
  }

  return (
    <input
      ref={ref}
      id={id}
      name={name}
      value={value}
      step={step}
      min={min}
      defaultValue={defaultValue}
      autoComplete={autoComplete}
      placeholder={placeholder ?? undefined}
      onChange={handleInputChange}
      onMouseEnter={onMouseEnter}
      onMouseOut={onMouseOut}
      onFocus={onFocus}
      onBlur={onBlur}
      onWheel={(e) => (type === 'number' ? (e.target as HTMLInputElement).blur() : {})}
      onKeyDown={onKeyDown}
      type={type === 'hidden' ? 'text' : type}
      readOnly={readOnly}
      disabled={disabled}
      className={classNames}
      title={title}
    />
  );
});

export default FormControl;
