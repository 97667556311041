import type { PayRequestDraft } from 'types/models';
import type Misc from 'types/misc';
import Request from 'utils/request';

export type FetchAllParams = {
  organization: string | undefined,
  filtering: Misc.Filter[],
  fetchOptions: Misc.PaginatedFetchArgs<PayRequestDraft>,
};

export type FetchCountParams = {
  organization: string | undefined,
};

const fetchAll = async ({ organization, filtering, fetchOptions }: FetchAllParams) => {
  if (!organization) {
    throw new Error('FetchAllPayRequestDraft: Missing organization.');
  }

  const {
    pageIndex,
    pageSize,
    sort,
  } = fetchOptions;

  const queryData = [
    `recordsPerPage=${pageSize}`,
    `page=${pageIndex + 1}`,
  ];

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }) => {
      queryData.push(`filters[${name}]=${encodeURIComponent(
        (Array.isArray(value) ? value.join(',') : value) || '',
      )}`);
    });
  }

  if (sort) {
    const key = sort.id === 'client' ? 'clientName' : sort.id;
    const direction = sort.desc ? 'desc' : 'asc';
    queryData.push(`orderBy=${key}&orderDirection=${direction}`);
  }

  const result = await Request.get<Misc.Listing<PayRequestDraft>>(
    `/pay-requests/${organization}/importFailed?${queryData.join('&')}`,
  );
  return result;
};

const fetchCount = async ({ organization }: FetchCountParams) => {
  if (!organization) {
    throw new Error('FetchCountPayRequestDraft: Missing organization.');
  }

  const result = await Request.get<Misc.CountRecords>(
    `/pay-requests/${organization}/count/draft`,
  );
  return result;
};

export {
  fetchAll,
  fetchCount,
};
