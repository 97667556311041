import type { RightsCategory, RightsCategoryType } from 'types/models';

export type RightsCategories = {
  unassigned: RightsCategory,
  assignedToSomeone: RightsCategory,
  assignedToMe: RightsCategory,
};

export const getCategoriesFromCategoryType = (
  category: RightsCategoryType,
): RightsCategories => {
  switch (category) {
    case 'CLIENTS':
      return ({
        unassigned: 'CLIENTS_UNASSIGNED',
        assignedToSomeone: 'CLIENTS_ASSIGNED_SOMEONE',
        assignedToMe: 'CLIENTS_ASSIGNED_TO_ME',
      });
    case 'ACTIONS':
      return ({
        unassigned: 'ACTIONS_UNASSIGNED',
        assignedToSomeone: 'ACTIONS_ASSIGNED_SOMEONE',
        assignedToMe: 'ACTIONS_ASSIGNED_TO_ME',
      });
    case 'CONTACTS':
      return ({
        unassigned: 'CONTACTS_UNASSIGNED',
        assignedToSomeone: 'CONTACTS_ASSIGNED_SOMEONE',
        assignedToMe: 'CONTACTS_ASSIGNED_TO_ME',
      });
    case 'PAYMENTS':
      return ({
        unassigned: 'PAYMENTS_UNASSIGNED',
        assignedToSomeone: 'PAYMENTS_ASSIGNED_SOMEONE',
        assignedToMe: 'PAYMENTS_ASSIGNED_TO_ME',
      });
    case 'PAYREQUESTS':
      return ({
        unassigned: 'PAYREQUESTS_UNASSIGNED',
        assignedToSomeone: 'PAYREQUESTS_ASSIGNED_SOMEONE',
        assignedToMe: 'PAYREQUESTS_ASSIGNED_TO_ME',
      });
    default:
      return ({
        unassigned: 'PAYREQUESTS_UNASSIGNED',
        assignedToSomeone: 'PAYREQUESTS_ASSIGNED_SOMEONE',
        assignedToMe: 'PAYREQUESTS_ASSIGNED_TO_ME',
      });
  }
};
