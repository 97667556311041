import './index.scss';
import { useTranslation } from 'react-i18next';
import type Errors from 'types/errors';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import Button from 'components/Button';
import Icon from 'components/Icon';

type Props = {
  errors?: Errors.Validation | null,
  index: number,
  displayRemoveButton: boolean,
  defaultValue?: string,
  onChangePossibleValue(newValue: string, index: number): void,
  onRemovePossibleValue(index: number): void,
};

const PossibleValueItem = (props: Props): JSX.Element => {
  const {
    index,
    displayRemoveButton,
    errors,
    defaultValue,
    onChangePossibleValue,
    onRemovePossibleValue,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="PossibleValuesItem">
      <FormFieldset key={index}>
        <FormGroup label={t('common:value')} error={errors?.possibleValues}>
          <div className="PossibleValuesItem__wrapper">
            <div className="PossibleValuesItem__wrapper__form">
              <FormControl
                type="text"
                name={`value${index}`}
                autoComplete="off"
                defaultValue={defaultValue}
                onChange={(newValue) => onChangePossibleValue(newValue, index)}
                isInvalid={!!errors?.possibleValues}
              />
              {displayRemoveButton && (
                <Button variant="outline-danger" onClick={() => onRemovePossibleValue(index)}>
                  <Icon name="close" />
                </Button>
              )}
            </div>
          </div>
        </FormGroup>
      </FormFieldset>
    </div>
  );
};

export default PossibleValueItem;
