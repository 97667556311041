import './index.scss';
import { useCallback } from 'react';
import type { Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import type { Category } from 'types/models';
import DataTable from 'components/DataTable';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import CategoryRowActions from './RowActions';

type Props = {
  onShowToast(message: string, status: ToastStatus): void,
  onReload(): void,
  reloadTick: number,
  categories: Category[] | null,
  isLoading: boolean,
  showCategoryModal(): void,
  setEditCategory(editCategory: Category | undefined): void,
};

const PerimetersTable = (props: Props): JSX.Element => {
  const { onShowToast, categories, isLoading, showCategoryModal, setEditCategory, onReload } = props;
  const { t } = useTranslation();

  const onActionDone = useCallback((message: string) => {
    onShowToast(message, 'success');
  }, [onShowToast]);

  const columns: Column<Category>[] = [
    {
      Header: t<string>('perimeters:category'),
      accessor: 'name',
      disableSortBy: true,
      Cell: ({ value, row }) => (
        <div className="PerimetersTable__name">
          <span className='PerimetersTable__name__name'>{value}</span>
          <span className='PerimetersTable__name__identifier'>{row.original.identifier}</span>
        </div>
      ),
    },
    {
      Header: t<string>('perimeters:values'),
      accessor: 'values',
      disableSortBy: true,
      Cell: ({ value: categoryValues }) => (
        <div className="PerimetersTable__values">
          {categoryValues.map((possibleValue) =>
            <span
              key={possibleValue.id}
              className='PerimetersTable__values__value'
            >
              {possibleValue.value}
            </span>,
          )}
        </div>
      ),
    },
    {
      Header: t<string>('common:commands'),
      accessor: 'id',
      disableSortBy: true,
      Cell: ({ row }) => (
        <div className="PerimetersTable__actions">
          <CategoryRowActions
            category={row.original}
            onActionDone={onActionDone}
            onReload={onReload}
            onActionError={(message: string) => { onShowToast(message, 'error'); }}
            showModal={() => {
              setEditCategory(row.original);
              showCategoryModal();
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="PerimetersTable">
      <DataTable<Category>
        columns={columns}
        data={categories}
        serverPagination={null}
        defaultSortBy={{ id: 'id', desc: false }}
        fetchData={() => { }}
        isLoading={isLoading}
        error={null}
        noHeader
        withActions={false}
      />
    </div>
  );
};

export default observer(PerimetersTable);
