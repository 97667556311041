import type Misc from 'types/misc';
import type { HistoryStep } from 'types/models';

type HistoryStepParams = HistoryStep | { entity: Misc.HistoryStepEntity };

const getHistoryStepType = ({ entity }: HistoryStepParams): Misc.TypeOfHistoryStep => {
  const reminderEntities: Misc.HistoryStepEntity[] = [
    'Reminder',
  ];
  if (reminderEntities.includes(entity)) {
    return 'reminder';
  }

  const taskEntities: Misc.HistoryStepEntity[] = [
    'FreeTask',
    'TaskValidatedAlert',
    'TaskDeletedAlert',
    'ProvideContactInformationsTask',
  ];
  if (taskEntities.includes(entity)) {
    return 'task';
  }

  const noteEntities: Misc.HistoryStepEntity[] = [
    'NoteCreateAlert',
    'NoteUpdateAlert',
    'NoteDeleteAlert',
  ];
  if (noteEntities.includes(entity)) {
    return 'note';
  }

  const alertEntities: Misc.HistoryStepEntity[] = [
    'PayRequestCreationMilestone',
    'StartDunningPlanMilestone',
    'PayRequestDeleteAlert',
    'ReceiveSendAlert',
    'MarkAsPaidAlert',
    'CancelMarkAsPaidAlert',
    'DownloadInvoiceAlert',
    'MuteAlert',
    'CancelAlert',
    'MissingContactAlert',
    'UnmuteAlert',
    'LitigateAlert',
    'CancelLitigateAlert',
    'SwitchPlanAlert',
    'BouncedEmailAlert',
    'UpdateAmountAlert',
    'PartialPaymentAlert',
    'DishonouredPaymentAlert',
    'IncrementAmountAlert',
    'CreditPaymentAlert',
    'ClientCreateAlert',
    'ClientUpdateAlert',
    'ContactCreateAlert',
    'ContactUpdateAlert',
    'ContactDeleteAlert',
    'CreatePromiseToPayAlert',
    'RemovePromiseToPayAlert',
    'UpdatePromiseToPayAlert',
    'ManagerAssignedAlert',
    'PaymentAssignedAlert',
    'PaymentUpdatedAlert',
    'PaymentRemovedAlert',
    'ManagerUnassignedAlert',
    'GroupRestartAlert',
    'CanceledReminderAlert',
    'SentReminderAlert',
    'ActivateAlert',
    'UnActivateAlert',
  ];
  if (alertEntities.includes(entity)) {
    return 'alert';
  }

  return 'other';
};

export default getHistoryStepType;
