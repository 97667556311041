import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { fetchAll, FetchAllParams } from 'api/models';
import type Misc from 'types/misc';
import type { Models } from 'types/models';
import useFetch from 'hooks/useFetch';
import organizationStore from 'stores/Organization';
import Loading from 'components/Loading';
import Modal from 'components/Modal';
import Model from './Model';

type Props = {
  onClose(): void,
  type: 'reminder' | 'task',
  onSelect(model: Misc.PlanTemplateReminder): void,
  channel?: Misc.Channel,
};

const ModalModels = (props: Props): JSX.Element => {
  const { onClose, type, onSelect, channel } = props;
  const { currentOrganization } = organizationStore;
  const { t } = useTranslation();

  const { data, isLoading } = useFetch<FetchAllParams, Models>(
    { cacheKey: 'organizationModels', organization: currentOrganization?.reference },
    fetchAll,
    { enabled: !!currentOrganization, retry: false },
  );

  const handleSelect = useCallback((newModel: Misc.PlanTemplateReminder) => {
    onSelect(newModel);
    onClose();
  }, [onClose, onSelect]);

  const models = useMemo(() => {
    if (type === 'reminder') {
      return data?.templates.reminders.filter(
        ({ channel: reminderChannel }) => channel === reminderChannel,
      );
    }
    return data?.templates.tasks;
  }, [channel, data, type]);

  return (
    <Modal
      isOpened
      title={t('models:models')}
      isLoading={isLoading}
      onClose={onClose}
      className="ModalModels"
    >
      {models && models.map((model) => (
        <Model
          model={model}
          key={`${model.name}-day${model.day}`}
          onSelect={handleSelect}
        />
      ))}
      {!models?.length && !isLoading && (
        <p className="ModalModels__no-data">{t('models:no-template-found')}</p>
      )}
      {isLoading && <Loading />}
    </Modal>
  );
};

export default observer(ModalModels);
