import './index.scss';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import type Misc from 'types/misc';
import type { ReportLine } from 'types/models';
import { fetchAllLinesFromFile } from 'api/imports';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import Loading from 'components/Loading';
import type { LineType } from '..';
import { ReportFileContext } from '..';
import Line from './Line';

type FetchAllOptions = { cacheKey: string, fileId: number, status?: Misc.ReportLineStatus };

const ImportsReportLinesList = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { type } = useParams<{ type: LineType }>();
  const file = useContext(ReportFileContext);
  const fileId = file ? file.id : 0;
  const statuses = {
    error: 'RESULT_ERROR' as Misc.ReportLineStatus,
    created: 'RESULT_CREATED' as Misc.ReportLineStatus,
    updated: 'RESULT_UPDATED' as Misc.ReportLineStatus,
    skipped: 'SKIPPED' as Misc.ReportLineStatus,
  };
  const status = type ? statuses[type] : undefined;

  const { loader, isLoading, isFetching, infiniteData, reset } = useInfiniteScroll<FetchAllOptions, ReportLine>(
    { cacheKey: 'fileLines', fileId, status },
    50,
    fetchAllLinesFromFile,
    { enabled: fileId !== 0 && status !== undefined },
  );

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  if (!fileId || !type) {
    return null;
  }

  return (
    <div className="ImportsReportLinesList">
      {(!(isLoading || isFetching) && infiniteData.length === 0) && (
        <p className="ImportsReportLinesList__not-found">{t('common:no-data-was-found')}</p>
      )}
      {infiniteData.map((line) => (
        <Line key={line.line} line={line} />
      ))}
      <div className="ImportsReportLinesList__loading">
        {isFetching && <Loading />}
      </div>
      <div ref={loader} />
    </div>
  );
};

export default ImportsReportLinesList;
