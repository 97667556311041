import './index.scss';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import { observer } from 'mobx-react';
import type Errors from 'types/errors';
import type { ActionPost } from 'types/models';
import type Misc from 'types/misc';
import organizationStore from 'stores/Organization';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormSelect from 'components/FormSelect';
import FormSwitch from 'components/FormSwitch';
import FormGroupWithActions from 'components/FormGroupWithActions';
import TagLink from 'components/TagLink';
import Wysiwyg from 'components/Wysiwyg';
import Button from 'components/Button';
import FormControlSMSContent from 'components/FormControlSMSContent';
import HistoryStepContentPreview from 'components/HistoryStepContentPreview';
import getI18nChannel from 'utils/getI18nChannel';
import getI18nBeforeAndAfterDateReference from 'utils/getI18nBeforeAndAfterDateReference';
import getContactRole from 'utils/getContactRole';
import getComparableString from '../../utils';

type Props = {
  type: Misc.ActionType,
  defaultData?: ActionPost | null,
  errors?: Errors.Validation | null,
  dateReference: Misc.PlanDateReference,
  channel: Misc.Channel,
};

const AddActionForm = (props: Props): JSX.Element => {
  const { type, defaultData, errors, channel, dateReference } = props;
  const { t } = useTranslation();
  const { contactRoles } = organizationStore;
  const [name, setName] = useState<string>(defaultData?.name || '');
  const [description, setDescription] = useState<string>(defaultData?.description || '');
  const [subject, setSubject] = useState<string>(defaultData?.subject || '');
  const [content, setContent] = useState<string>(defaultData?.content || '');
  const oldDefaultData = useRef(getComparableString(defaultData));

  useEffect(() => {
    if (!defaultData || oldDefaultData.current === getComparableString(defaultData)) {
      return;
    }
    oldDefaultData.current = getComparableString(defaultData);
    setName(defaultData.name || '');
    setDescription(defaultData.description || '');
    setSubject(defaultData.subject || '');
    setContent(defaultData.content || '');
  }, [defaultData]);

  const selectOptions = useMemo(() => [
    {
      label: t(getI18nBeforeAndAfterDateReference('after', dateReference)),
      value: `after-${dateReference}`,
    },
    {
      label: t(getI18nBeforeAndAfterDateReference('before', dateReference)),
      value: `before-${dateReference}`,
    },
  ], [dateReference, t]);

  const roleDefaultValue = useMemo<Misc.Identifier[]>(() => {
    if (!defaultData || !contactRoles) {
      return [];
    }

    return defaultData.contacts?.map((identifier) => ({
      label: getContactRole(identifier, contactRoles),
      identifier,
      value: contactRoles,
    })) || [];
  }, [defaultData, contactRoles]);

  const [showPreviewContentModal, hidePreviewContentModal] = useModal(() => (
    <HistoryStepContentPreview
      content={content}
      channel={defaultData?.channel || 'EMAIL'}
      onClose={hidePreviewContentModal}
    />
  ), [defaultData, content]);

  return (
    <div className="AddActionForm">
      {type === 'REMINDER' && (
        <FormFieldset>
          <div className="AddActionForm__days-group">
            <FormGroup label={t('plans:planned')} mandatory error={errors?.startAt}>
              <FormControl
                type="number"
                min={0}
                name="days"
                className="AddActionForm__days-group__days-input"
                defaultValue={
                  defaultData?.day !== undefined
                    ? Math.abs(defaultData?.day)
                    : undefined
                }
                isInvalid={!!errors?.startAt}
              />
              <p className="AddActionForm__days-group__label">{t('common:days')}</p>
            </FormGroup>
          </div>
          <FormGroup mandatory error={errors?.date}>
            <FormSelect
              name="date"
              selectOptions={selectOptions}
              defaultValue={defaultData && defaultData?.day < 0 ? `before-${dateReference}` : `after-${dateReference}`}
              isInvalid={!!errors?.date}
            />
          </FormGroup>
        </FormFieldset>
      )}
      <FormFieldset>
        <FormGroup label={t('reminders:short-description')} mandatory error={errors?.name}>
          <FormControl
            type="text"
            name="name"
            autoComplete="off"
            value={name}
            placeholder={t('reminders:placeholder.short-description')}
            isInvalid={!!errors?.name}
            onChange={setName}
          />
        </FormGroup>
        {type !== 'DISPATCH' && (
          <FormGroup
            label={t('reminders:need-validation')}
            mandatory
            className="AddActionForm__need-validation"
            error={errors?.needsValidation}
          >
            <FormSwitch name="needsValidation" defaultValue={defaultData?.needsValidation || false} />
          </FormGroup>
        )}
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('reminders:description')} error={errors?.description}>
          <FormControl
            type="text"
            name="description"
            autoComplete="off"
            placeholder={t(
              'reminders:placeholder.description',
              { channel: t(getI18nChannel(defaultData?.channel || 'SMS')) },
            )}
            value={description}
            onChange={setDescription}
            isInvalid={!!errors?.description}
          />
        </FormGroup>
      </FormFieldset>
      <div className="AddActionForm__divider" />
      <FormFieldset>
        <FormGroup label={t('reminders:recipients')} mandatory error={errors?.roles}>
          <FormSelect
            name="roles"
            placeholder={t('common:please-choose')}
            selectOptions={contactRoles?.map(
              ({ identifier, value }) => ({ label: value, value: identifier }),
            )}
            defaultValue={roleDefaultValue}
            isInvalid={!!errors?.roles}
            isMultiple
            isAsync
          />
        </FormGroup>
      </FormFieldset>
      {(defaultData?.subject || channel === 'EMAIL') && (
        <FormFieldset>
          <FormGroup
            label={channel === 'EMAIL' ? t('reminders:email-subject') : t('reminders:subject')}
            error={errors?.subject}
          >
            <FormControl
              type="text"
              name="subject"
              autoComplete="off"
              value={subject}
              onChange={setSubject}
              isInvalid={!!errors?.subject}
              placeholder={t('reminders:placeholder.subject')}
            />
          </FormGroup>
        </FormFieldset>
      )}
      <FormFieldset>
        <FormGroupWithActions
          label={channel === 'EMAIL' ? t('reminders:email-content') : t('reminders:content')}
          mandatory
          action={<TagLink />}
          secondaryAction={(
            <Button
              variant="link"
              onClick={showPreviewContentModal}
            >
              {t('models:preview')}
            </Button>
          )}
        >
          {channel !== 'SMS' && (
            <Wysiwyg
              name="content"
              defaultValue={content}
              toBePrinted={channel !== 'EMAIL'}
              onChange={setContent}
            />
          )}
          {channel === 'SMS' && (
            <FormControlSMSContent
              name="content"
              value={content}
              onChange={setContent}
            />
          )}
        </FormGroupWithActions>
      </FormFieldset>
    </div>
  );
};

export default observer(AddActionForm);
