import './index.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Fragment, useMemo, useState, useEffect } from 'react';
import i18next from 'i18next';
import { observer } from 'mobx-react';
import { convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateFromHTML } from 'draft-js-import-html';
import draftToHtml from 'draftjs-to-html';
import Tags from './plugins/Tags';

type Props = {
  name: string,
  defaultValue: string,
  replaceValue?: string,
  toBePrinted?: boolean,
  onChange?(content: string): void
};

const Wysiwyg = (props: Props): JSX.Element => {
  const { name, defaultValue, replaceValue, toBePrinted = false, onChange } = props;

  const [data, setData] = useState<EditorState>(() => {
    if (!defaultValue) {
      return EditorState.createEmpty();
    }

    const contentState = stateFromHTML(defaultValue);
    if (!contentState) {
      return EditorState.createEmpty();
    }

    return EditorState.createWithContent(contentState);
  });

  useEffect(() => {
    if (!replaceValue) {
      return;
    }

    const contentState = stateFromHTML(replaceValue);
    if (!contentState) {
      return;
    }

    setData(EditorState.createWithContent(contentState));
  }, [replaceValue]);

  const toolbar = useMemo(() => {
    const allOptions = ['history', 'fontSize', 'inline', 'link', 'colorPicker', 'remove', 'textAlign', 'list'];
    const printOptions = allOptions.filter(
      (optionName: string) => !['link'].includes(optionName),
    );

    return {
      options: toBePrinted ? printOptions : allOptions,
      history: {
        inDropdown: false,
        className: 'Wysiwyg__toolbar__button',
        options: ['undo', 'redo'],
      },
      fontSize: {
        inDropdown: true,
        className: 'Wysiwyg__toolbar__dropdown',
        options: [9, 11, 13, 14, 15, 16, 18, 24, 30, 36, 48],
      },
      inline: {
        inDropdown: false,
        className: 'Wysiwyg__toolbar__button',
        options: ['bold', 'underline', 'italic'],
      },
      link: {
        inDropdown: false,
        className: 'Wysiwyg__toolbar__button',
      },
      colorPicker: {
        inDropdown: true,
        className: 'Wysiwyg__toolbar__dropdown',
        colors: [
          '#000000', '#ffffff', '#f7da64', '#f37934',
          '#41a85f', '#2969b0', '#d14841', '#8555ab',
          '#333333', '#666666', '#999999', '#cccccc',
        ],
      },
      remove: {
        inDropdown: false,
        className: 'Wysiwyg__toolbar__button',
      },
      textAlign: {
        inDropdown: true,
        className: 'Wysiwyg__toolbar__dropdown',
        options: ['left', 'center', 'right', 'justify'],
      },
      list: {
        inDropdown: true,
        className: 'Wysiwyg__toolbar__dropdown',
        options: ['unordered', 'ordered', 'indent', 'outdent'],
      },
    };
  }, [toBePrinted]);

  const htmlContent = useMemo<string>(() => {
    const content = convertToRaw(data.getCurrentContent());
    return draftToHtml(content);
  }, [data]);

  useEffect(() => {
    if (onChange) {
      onChange(htmlContent);
    }
  }, [onChange, htmlContent]);

  return (
    <Fragment>
      <Editor
        wrapperClassName="Wysiwyg"
        editorClassName="Wysiwyg__editor"
        toolbarClassName="Wysiwyg__toolbar"
        toolbar={toolbar}
        // @ts-ignore - Ici les props des boutons custom sont passés automatiquement
        toolbarCustomButtons={[<Tags />]}
        localization={{ locale: i18next.language }}
        editorState={data}
        onEditorStateChange={(newState) => { setData(newState); }}
        stripPastedStyles
      />
      <textarea
        name={name}
        className="Wysiwyg__hidden-field"
        value={htmlContent}
        readOnly
      />
    </Fragment>
  );
};
export default observer(Wysiwyg);
