import './index.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { OrganizationPlanTemplate } from 'types/models';
import type Misc from 'types/misc';
import Item from './Item';

type Props = {
  data: OrganizationPlanTemplate,
};

const ScenarioDetailsActions = ({ data }: Props) => {
  const { t } = useTranslation();
  const { tasks, reminders, dispatches, group } = data;

  const orderedActions = useMemo(() => {
    if (tasks.length === 0 && reminders.length === 0) return [];
    return (
      [
        ...tasks.map((task) => ({ ...task, type: 'TASK' as Misc.ActionType })),
        ...reminders.map((reminder) => ({ ...reminder, type: 'REMINDER' as Misc.ActionType })),
      ].sort((elem1, elem2) => elem1.day - elem2.day)
    );
  }, [reminders, tasks]);

  const dateReference = useMemo(() => {
    if (!data || data.dateReferenceIsToday) {
      return 'plan-setup';
    }
    return data.group ? 'invoice-oldest-deadline' : 'invoice-deadline';
  }, [data]);

  return (
    <div className="ScenarioDetailsActions">
      <div className="ScenarioDetailsActions__section">
        <h4 className="ScenarioDetailsActions__section__title">{t('plans:dispatch')}</h4>
        <div className="ScenarioDetailsActions__section__container">
          {(!dispatches || !dispatches.length) && (
            <p className="ScenarioDetailsActions__section__container__no-data">{t('common:none_f')}</p>
          )}
          {!!dispatches && dispatches.map((action) => (
            <Item
              key={`dispatch-${action.day}-${action.identifier}`}
              action={action}
              type="DISPATCH"
              isGroup={!!group}
              dateReference={dateReference}
            />
          ))}
        </div>
      </div>
      <div className="ScenarioDetailsActions__section">
        <h4 className="ScenarioDetailsActions__section__title">{t('common:actions')}</h4>
        <div className="ScenarioDetailsActions__section__container">
          {!tasks.length && !reminders.length && (
            <p className="ScenarioDetailsActions__section__container__no-data">{t('common:none_f')}</p>
          )}
          {orderedActions.map((action) => (
            <Item
              key={`action-${action.day}-${action.identifier}`}
              action={action}
              type={action.type}
              isGroup={!!group}
              dateReference={dateReference}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScenarioDetailsActions;
