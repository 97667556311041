import './index.scss';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportContext } from '../..';
import ReportDetailFile from './File';

const ImportsReportDetails = (): JSX.Element | null => {
  const { t } = useTranslation();
  const report = useContext(ReportContext);

  if (!report) {
    return null;
  }

  const { files } = report;

  return (
    <div className="ImportsReportDetails">
      <h2>{t('common:details')}</h2>
      <div className="ImportsReportDetails__container">
        {files.map((file) => (
          <ReportDetailFile key={file.id} file={file} />
        ))}
      </div>
    </div>
  );
};

export default ImportsReportDetails;
