import './index.scss';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
import getI18nReportLineCreatedUpdated from 'utils/getI18nReportLineCreatedUpdated';

type Props = {
  countError: number,
  countCriticalError: number,
  inProgress?: boolean,
  countUpdated: number,
  countCreated: number,
  countTotal: number,
  type: Misc.ReportItemType,
};

const Status = (props: Props): JSX.Element => {
  const {
    countError,
    countCriticalError,
    inProgress,
    countCreated,
    countUpdated,
    countTotal,
    type,
  } = props;
  const { t } = useTranslation();

  const classNames = classnames('ReportItemStatus', {
    'ReportItemStatus--warning': countError && !countCriticalError,
    'ReportItemStatus--error': countCriticalError,
    'ReportItemStatus--in-progress': inProgress,
  });

  const countIgnored = countTotal - countUpdated - countCreated - countCriticalError - countError;

  if (inProgress) {
    return (
      <div className={classNames}>
        <h3 className="ReportItemStatus__title">
          {t('reports:in-progress')}
        </h3>
      </div>
    );
  }

  if (countCriticalError) {
    return (
      <div className={classNames}>
        <h3 className="ReportItemStatus__title">{t('reports:import-failed')}</h3>
        <p className="ReportItemStatus__count">
          {t('reports:critical-error', { count: countCriticalError })}
        </p>
      </div>
    );
  }

  return (
    <div className={classNames}>
      <h3 className="ReportItemStatus__title">
        {t(getI18nReportLineCreatedUpdated(type, 'created'), { count: countCreated })}
      </h3>
      <p className="ReportItemStatus__count">
        {t(getI18nReportLineCreatedUpdated(type, 'updated'), { count: countUpdated })}
      </p>
      {countIgnored !== 0 && (
        <p className="ReportItemStatus__count">
          {t('reports:count-ignored', { count: countIgnored })}
        </p>
      )}
      {countError !== 0 && (
        <p className="ReportItemStatus__subtitle">
          {t(
            type === 'PAY_REQUEST_CREATE' ? 'reports:error-pdf' : 'reports:error',
            { count: countError },
          )}
        </p>
      )}
    </div>
  );
};

export default Status;
