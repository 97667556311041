import './index.scss';
import type { ReactNode } from 'react';
import type { RightsCategory, RightsAction, RightsCategoryType } from 'types/models';
import Dropdown from 'components/Dropdown';
import Icon from 'components/Icon';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import Button from 'components/Button';

type Props = {
  to?: string,
  onClick?(): void,
  title: string,
  category?: RightsCategory,
  action?: RightsAction,
  categoryType?: RightsCategoryType,
  children: ReactNode,
};

const SplitButtonDropdown = (props: Props): JSX.Element => {
  const { to, title, onClick, category, categoryType, action, children } = props;

  return (
    <div className="SplitButtonDropdown">
      <div className="SplitButtonDropdown__main-link">
        {(category || categoryType) && action && (
          <ButtonWithUserRights
            variant="outline"
            className="SplitButtonDropdown__main-link__button"
            action={action}
            {...props}
            href={to}
          >
            {title}
          </ButtonWithUserRights>
        )}
        {((!category && !categoryType) || !action) && (
          <Button variant="outline" className="SplitButtonDropdown__main-link__button" href={to} onClick={onClick}>
            {title}
          </Button>
        )}
      </div>
      <Dropdown
        variant="outline"
        noIcon
        toggle={<Icon name="dots" />}
      >
        {children}
      </Dropdown>
    </div>
  );
};

export default SplitButtonDropdown;
