import type { EavConstructor, Eav } from 'types/models';

interface FormEavValue {
  [eavId: string]: any,
}

const formatEavsValues = (eavs: EavConstructor[], formEav: FormEavValue) => {
  const formatedEavs: Eav[] = [];

  eavs.forEach(({ identifier, required }) => {
    const value = formEav[`eavs[${identifier}]`];
    if (!required && value === '') {
      return;
    }
    formatedEavs.push({ identifier, value });
  });

  return formatedEavs;
};

export default formatEavsValues;
