import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type Errors from 'types/errors';
import config from 'config';
import getI18nOrganizationSector from 'utils/getI18nOrganizationSector';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import Button from 'components/Button';
import Loading from 'components/Loading';
import { ModalFormData } from 'components/ModalForm';
import FormSelect from 'components/FormSelect';
import FormFieldset from 'components/FormFieldset';

type Props = {
  onSubmit: (formData: ModalFormData | null) => void,
  isLoading?: boolean,
  errors: Errors.Validation | null,
};

const OrganizationCreateForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { onSubmit, isLoading = false, errors } = props;

  const formRef = React.useRef<HTMLFormElement | null>(null);

  const getFormData = React.useCallback(
    (): ModalFormData | null => {
      if (!formRef.current) {
        return null;
      }

      const formData = new FormData(formRef.current);
      return Object.fromEntries(formData.entries());
    },
    [],
  );

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    onSubmit(getFormData());
  };

  return (
    <form className="OrganizationCreateForm" onSubmit={handleSubmit} ref={formRef}>
      <FormGroup controlId="corporateName" label={t('common:corporate-name')} error={errors?.name}>
        <FormControl
          id="corporateName"
          name="corporateName"
          placeholder={t('organization-create:placeholder.corporate-name')}
          isInvalid={!!errors?.name}
        />
      </FormGroup>
      <FormGroup
        label={t('organization-create:organization-sector')}
        error={errors?.linesOfBusiness}
      >
        <FormSelect
          name="linesOfBusiness"
          placeholder={t('organization-create:placeholder.sector')}
          selectOptions={config.ORGANIZATION_SECTORS.map((sector) => ({
            label: t(getI18nOrganizationSector(sector)),
            value: sector,
          }))}
          isInvalid={!!errors?.linesOfBusiness}
        />
      </FormGroup>
      <FormGroup controlId="address1" label={t('common:address')} error={errors?.address1}>
        <FormControl
          id="address1"
          placeholder={t('organization-create:placeholder.address')}
          name="address1"
          isInvalid={!!errors?.address1}
        />
      </FormGroup>
      <FormFieldset>
        <FormGroup
          controlId="postalCode"
          label={t('common:postal-code')}
          error={errors?.postalCode}
        >
          <FormControl
            id="postalCode"
            placeholder={t('organization-create:placeholder.postal-code')}
            name="postalCode"
            isInvalid={!!errors?.postalCode}
          />
        </FormGroup>
        <FormGroup controlId="city" label={t('common:city')} error={errors?.city}>
          <FormControl
            id="city"
            placeholder={t('organization-create:placeholder.city')}
            name="city"
            isInvalid={!!errors?.city}
          />
        </FormGroup>
      </FormFieldset>
      <FormGroup controlId="legalId" label={t('organizations:legal-id')} error={errors?.legalId}>
        <FormControl
          id="legalId"
          placeholder={t('organization-create:placeholder.legal-id')}
          name="legalId"
          isInvalid={!!errors?.legalId}
        />
      </FormGroup>
      <Button
        variant="primary"
        type="submit"
        className="OrganizationCreateForm__submit"
      >
        {isLoading && <Loading />}
        {!isLoading && t('organization-create:create-organization')}
      </Button>
    </form>
  );
};

export default OrganizationCreateForm;
