import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import type Misc from 'types/misc';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useApiRequest from 'hooks/useApiRequest';
import Button from 'components/Button';
import InputModal from 'components/InputModal';

interface Props {
  selection: (number | string)[],
  onLoading: (isLoading: boolean) => void,
  onDone: (message: string) => void,
}

const BulkActionPromiseToPay: React.FC<Props> = (props) => {
  const { linesOfBusiness } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);
  const { selection, onLoading, onDone } = props;

  const { put, error, isLoading } = useApiRequest();

  const handleSubmit = React.useCallback(
    async (value: { [field: string]: string }) => {
      const dates = selection.map((id) => ({ id, date: value.date }));

      const result = await put<Misc.IdRef>(
        'pay-requests/bulk-manage/promise-to-pay',
        { selection: { ...dates } },
      );

      if (!result) {
        return;
      }

      onDone(t(
        'payment-requests:actions.toast.bulk-promise-to-pay-defined',
        { count: selection.length },
      ));
    },
    [put, selection, onDone, t],
  );

  React.useEffect(
    () => { onLoading(isLoading); },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading],
  );

  const [showInputModal, hideInputModal] = useModal(() => (
    <InputModal
      isShow
      title={t('payment-requests:bulk-edit-promise-to-pay')}
      help={ct('payment-requests:bulk-edit-promise-to-pay-for-count', { count: selection.length })}
      type="date"
      name="date"
      isLoading={isLoading}
      error={error}
      minDate={new Date()}
      onSubmit={handleSubmit}
      onCancel={hideInputModal}
    />
  ), [t, ct, selection, isLoading, error, handleSubmit]);

  return (
    <Button
      variant="outline-light"
      onClick={showInputModal}
      className="BulkActionPromiseToPay"
    >
      {t('payment-requests:bulk-edit-promise-to-pay', { count: selection.length })}
    </Button>
  );
};

export default observer(BulkActionPromiseToPay);
