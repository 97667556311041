import { Fragment } from 'react';
import classNames from 'classnames';
import type Misc from 'types/misc';
import FilterItem from '../Item';

interface Props extends Misc.FiltersRenderer {
  filtersSettings?: Misc.FiltersSettings,
  isModal?: boolean,
}

const DataTableFilterItems = (props: Props) => {
  const { filters, currentFilters, getFilterValue, onChange, filtersSettings, isModal = false } = props;
  const RenderFilters = filtersSettings?.renderFilters;

  if (RenderFilters) {
    return (
      <RenderFilters
        filters={filters}
        currentFilters={currentFilters}
        getFilterValue={getFilterValue}
        onChange={onChange}
      />
    );
  }

  return (
    <Fragment>
      {filters.map((filter) => {
        if (filter.isHidden) {
          return null;
        }

        if (isModal) {
          const itemClassName = classNames('DataTableFilters__modal__container__filter', {
            'DataTableFilters__modal__container__filter--full-width': filter.isFullWidth,
          });

          return (
            <div className={itemClassName} key={filter.key}>
              <FilterItem
                filter={filter}
                currentFilters={currentFilters}
                getFilterValue={getFilterValue}
                onChange={onChange}
              />
            </div>
          );
        }

        return (
          <FilterItem
            key={filter.key}
            filter={filter}
            currentFilters={currentFilters}
            getFilterValue={getFilterValue}
            onChange={onChange}
          />
        );
      })}
    </Fragment>
  );
};

export default DataTableFilterItems;
