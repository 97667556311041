import './index.scss';
import { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useApiRequest from 'hooks/useApiRequest';
import Button from 'components/Button';
import Loading from 'components/Loading';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import DropdownActions from 'components/DropdownActions';

type Props = {
  id: number,
  reference: string,
  name: string,
  onActionDone(title: string, message: string): void,
  onActionError(message: string): void,
};

const CustomersRowActions = (props: Props): JSX.Element => {
  const { reference, name, onActionDone, onActionError } = props;
  const { linesOfBusiness, currentOrganization } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);
  const history = useHistory();

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const { remove, error, isLoading } = useApiRequest();

  const handleConfirmDelete = useCallback(() => {
    showConfirm(ct('common:remove'), ct('payments:confirm.remove'));
  }, [showConfirm, ct]);

  const handleSubmitDelete = useCallback(async () => {
    hideConfirm();
    const response = await remove(`payments/manage/${currentOrganization?.reference}/${reference}`);
    if (response) {
      onActionDone(
        ct('payments:toast.deleted'),
        ct('payments:toast.deleted-name', { name }),
      );
    }
  }, [hideConfirm, remove, currentOrganization, reference, onActionDone, ct, name]);

  useEffect(() => {
    if (error) {
      onActionError(error.message || t('errors:unknown-retry'));
    }
  }, [error, onActionError, t]);

  const actions = [
    <Button
      variant="list"
      onClick={() => history.push(`/payments/edit/${reference}`)}
      title={ct('common:edit')}
      disabled={isLoading}
    >
      {isLoading && <Loading hasNoText />}
      {!isLoading && t('common:edit')}
    </Button>,
    <Button
      variant="list-danger"
      onClick={handleConfirmDelete}
      title={ct('common:remove')}
      disabled={isLoading}
    >
      {isLoading && <Loading hasNoText />}
      {!isLoading && t('common:remove')}
    </Button>,
  ];

  return (
    <div className="PaymentsRowActions">
      <DropdownActions
        actions={actions}
      />
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        entityName={name}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitDelete}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={ct('common:remove')}
      />
    </div>
  );
};

export default observer(CustomersRowActions);
