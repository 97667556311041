import './index.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import SettingsList from 'pages/Settings/List';
import organizationStore from 'stores/Organization';
import SettingsSection from '../../Section';
import SendingActions from './Actions';
import SendingCustomerPortal from './CustomerPortal';

interface Props {
  onShowToast: (message: string, status: ToastStatus) => void,
}

const Sending = ({ onShowToast }: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;

  const listData = useMemo(() => {
    if (!currentOrganization) {
      return [];
    }

    const {
      sendingEmail,
      letterHead,
      signaturePdf,
      signatureEmail,
      signatureLetter,
      trackingEmail,
      sendViewPayer,
    } = currentOrganization;

    return [
      {
        label: t('customization:list-sending.sending-email'),
        value: sendingEmail || '-',
      },
      {
        label: t('customization:list-sending.tracking'),
        value: trackingEmail ? t('common:yes') : t('common:no'),
      },
      {
        label: t('customization:list-sending.letter-head'),
        value: <SendingActions
          data={letterHead}
          genreTranslate="m"
          type="pdf"
          title={t('customization:list-sending.letter-head')}
          name="letterHead"
          onShowToast={onShowToast}
        />,
      },
      {
        label: t('customization:list-sending.customer-portal'),
        value: <SendingCustomerPortal
          data={sendViewPayer}
          title={t('customization:list-sending.customer-portal')}
          name="sendViewPayer"
          onShowToast={onShowToast}
        />,
      },
      {
        label: t('customization:list-sending.handwritten-signature'),
        value: <SendingActions
          data={signaturePdf}
          type="img"
          title={t('customization:list-sending.handwritten-signature')}
          name="handwrittenSignature"
          onShowToast={onShowToast}
        />,
      },
      {
        label: t('customization:list-sending.letter-signature'),
        value: <SendingActions
          data={signatureLetter}
          type="text"
          title={t('customization:list-sending.letter-signature')}
          name="letterSignature"
          onShowToast={onShowToast}
        />,
      },
      {
        label: t('customization:list-sending.email-signature'),
        value: <SendingActions
          data={signatureEmail}
          type="text"
          title={t('customization:list-sending.email-signature')}
          name="emailSignature"
          onShowToast={onShowToast}
        />,
      },
    ];
  }, [currentOrganization, t, onShowToast]);

  return (
    <SettingsSection title={t('customization:sending')}>
      <div className="Sending__list">
        <div className="Sending__list__items">
          <SettingsList data={listData.slice(0, 3)} />
        </div>
        <div className="Sending__list__items">
          <SettingsList data={listData.slice(3, 6)} />
        </div>
      </div>
    </SettingsSection>
  );
};

export default observer(Sending);
