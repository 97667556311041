import type Misc from 'types/misc';
import { UserRights } from 'types/models';
import Request from 'utils/request';

export type FetchAllParams = {
  organization: string | undefined,
};

const fetchAll = async ({ organization }: FetchAllParams) => {
  if (!organization) {
    throw new Error('FetchAllUsers: Missing organization.');
  }

  const result = await Request.get<Misc.IdRefName[]>(
    `organization/${organization}/users`,
  );
  return result;
};

const fetchRights = async () => {
  const authToken = window.localStorage.getItem('token');
  if (!authToken) {
    return undefined;
  }

  const { data: userRights } = await Request.get<UserRights[] | undefined | null>('user-rights');
  return userRights;
};

export { fetchAll, fetchRights };
