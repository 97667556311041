import './index.scss';
import { useCallback } from 'react';
import classnames from 'classnames';
import { useModal } from 'react-modal-hook';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
import StepIcon from 'components/StepIcon';
import getI18nChannel from 'utils/getI18nChannel';
import getI18nDayFromReference from 'utils/getI18nDayFromReference';
import ModalAddTask from '../../../ModalAddTask';
import ModalAddAction from '../../../ModalAddAction';

interface Props {
  identifier: string,
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
  action: Misc.PlanTemplateReminder,
  isGroup: boolean,
  type: Misc.ActionType,
  dateReference: Misc.PlanDateReference,
}

const PlanDetailsAction: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const {
    action,
    type,
    dateReference,
    identifier,
    isGroup,
    onActionError,
    onActionDone,
  } = props;

  const className = classnames('ActionsItem', {
    'ActionsItem--reminder': type === 'REMINDER',
  });

  const isTask = type.toUpperCase() === 'TASK';

  const [showModalAddAction, hideModalAddAction] = useModal(() => (
    <ModalAddAction
      identifier={identifier}
      onActionDone={onActionDone}
      onActionError={onActionError}
      onClose={hideModalAddAction}
      channel={action.channel || 'EMAIL'}
      action={{ ...action, type }}
      type={type !== 'TASK' ? type : 'REMINDER'}
      dateReference={dateReference}
      edit
    />
  ), [action, dateReference, identifier, onActionDone, onActionError, type]);

  const [showModalAddTask, hideModalAddTask] = useModal(() => (
    <ModalAddTask
      identifier={identifier}
      onActionDone={onActionDone}
      onActionError={onActionError}
      onClose={hideModalAddTask}
      task={{ ...action, type }}
      dateReference={dateReference}
      edit
    />
  ), [action, dateReference, identifier, onActionDone, onActionError, type]);

  const handleShowModal = useCallback(() => {
    if (isTask) {
      showModalAddTask();
      return;
    }
    showModalAddAction();
  }, [showModalAddAction, showModalAddTask, isTask]);

  return (
    <button onClick={handleShowModal} className={className} type="button">
      <div className="ActionsItem__icon">
        <StepIcon
          data={{
            entity: isTask ? 'FreeTask' : 'Reminder',
            channel: action.channel || 'EMAIL',
          }}
        />
      </div>
      <div className="ActionsItem__description">
        <div className="ActionsItem__description__titles">
          <h4 className="ActionsItem__description__titles__title">{action.name}</h4>
          {type === 'DISPATCH' && isGroup && (
            <span className="ActionsItem__description__titles__solo-badge">
              {t('plans:solo-action')}
            </span>
          )}
        </div>
        <p className="ActionsItem__description__days">
          {type !== 'DISPATCH' && t(
            getI18nDayFromReference(action.day, dateReference),
            { count: Math.abs(action.day || 0) },
          )}
          <span className="ActionsItem__description__days__way">
            {action?.channel && `${type === 'DISPATCH' ? t('plans:dispatch') : ', '} ${t(
              getI18nChannel(action?.channel),
            )}`}
          </span>
        </p>
      </div>
      {action.needsValidation && (
        <div className="ActionsItem__to-validate">
          {t('common:to-validate')}
        </div>
      )}
    </button>
  );
};

export default PlanDetailsAction;
