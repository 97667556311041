import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
import type Errors from 'types/errors';
import type { Selectors } from 'types/models';
import useApiRequest from 'hooks/useApiRequest';
import organizationStore from 'stores/Organization';
import type { ModalFormData } from 'components/ModalForm';
import ModalForm from 'components/ModalForm';
import Form from './Form';

type Props = {
  onClose: () => void,
  edit?: boolean,
  defaultData?: Selectors,
  isGroup?: boolean,
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
};

const ModalSelector = (props: Props): JSX.Element => {
  const {
    onClose,
    edit = false,
    defaultData,
    isGroup = false,
    onActionDone,
    onActionError,
  } = props;
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const [conditions, setConditions] = useState<Misc.SelectorCondition[]>(
    defaultData?.conditions || [],
  );
  const [hasConditionOpen, setHasConditionOpen] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, Errors.ValidationItem> | undefined>();
  const { put, post } = useApiRequest();

  const handleSubmit = async (formData: ModalFormData | null) => {
    if (!currentOrganization || !formData) {
      return;
    }

    if (hasConditionOpen) {
      onActionError(t('selectors:toast.error.validate-conditions'));
      return;
    }

    const request = edit ? put : post;
    const data = {
      association: {
        ...formData,
        name: formData.name || defaultData?.name,
        position: parseInt(formData?.position as string, 10),
        conditions,
      },
      group: isGroup,
    };
    const result = await request(`/organization/${currentOrganization.reference}/association-plans`, data);

    if (!result || result.errors) {
      setErrors(result?.errors);
      onActionError(t(edit ? 'selectors:toast.error.edit' : 'selectors:toast.error.add'));
      return;
    }

    onActionDone(t(
      edit ? 'selectors:toast.success.edit' : 'selectors:toast.success.add',
      { name: data.association.name },
    ));

    onClose();
  };

  const title = useMemo(() => {
    if (isGroup) {
      return edit ? t('selectors:modal.edit-group') : t('selectors:modal.add-group');
    }
    return edit ? t('selectors:modal.edit') : t('selectors:modal.add');
  }, [isGroup, edit, t]);

  return (
    <ModalForm
      isOpened
      onSave={handleSubmit}
      onCancel={onClose}
      title={title}
    >
      <Form
        edit={edit}
        isGroup={isGroup}
        defaultData={defaultData}
        conditions={conditions}
        onUpdateConditions={setConditions}
        onToggleConditionOpen={setHasConditionOpen}
        errors={errors}
        onActionError={onActionError}
      />
    </ModalForm>
  );
};

export default ModalSelector;
