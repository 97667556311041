import './index.scss';
import { useState, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import type Misc from 'types/misc';
import currenciesStore from 'stores/Currencies';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import DropdownActions from 'components/DropdownActions';
import DownloadLink from 'components/DownloadLink';
import ActionButton from 'components/ActionButton';
import Icon from 'components/Icon';
import type { ToggleAction } from '../actions';
import RowActionsToggle from './Toggle';
import getToggleActionI18n from './getToggleActionI18n';
import RowActionNote from './Note';
import RowActionPromiseToPay from './PromiseToPay';
import RowActionCreditNote from './CreditNote';

interface Props {
  id: number,
  reference: string,
  hasGroup: boolean,
  muted: Misc.PayRequestMuted,
  defaultPromiseToPay: string | null,
  currency: Misc.CurrencyCode,
  downloadPath?: string,
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
  status: Misc.PayRequestStatus,
}

const PayRequestRowActions = (props: Props): JSX.Element => {
  const { linesOfBusiness, modePayRequest } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);

  const {
    id,
    reference,
    hasGroup,
    muted,
    defaultPromiseToPay,
    currency,
    downloadPath,
    onActionDone,
    onActionError,
    status,
  } = props;

  const { currencies } = currenciesStore;

  const [isDropdownLoading, setIsDropdownLoading] = useState<boolean>(false);

  const handleToggleActionDone = useCallback(
    (action: ToggleAction) => {
      const { toastText } = getToggleActionI18n(t, ct, reference, hasGroup)[action];
      onActionDone(toastText);
    },
    [t, ct, reference, hasGroup, onActionDone],
  );

  const handleActionError = useCallback(
    (message: string) => {
      onActionError(message);
    },
    [onActionError],
  );

  const currencyModel = useMemo(() => (
    currencies?.find((curr) => curr.code === currency)
  ), [currencies, currency]);

  const otherActions = [];

  if (status === 'IN_PROGRESS' || status === 'LITIGATED') {
    otherActions.push(
      <RowActionCreditNote
        id={id}
        reference={reference}
        currency={currencyModel}
        onLoadingChange={setIsDropdownLoading}
        onDone={onActionDone}
      />,
      <RowActionPromiseToPay
        id={id}
        reference={reference}
        defaultValue={defaultPromiseToPay}
        onLoadingChange={setIsDropdownLoading}
        onDone={onActionDone}
      />,
    );
  }

  if (status === 'CANCELLED') {
    otherActions.push(
      <RowActionsToggle
        id={id}
        reference={reference}
        hasGroup={hasGroup}
        muted={muted}
        action="activate"
        buttonVariant="list"
        onDone={handleToggleActionDone}
        onError={onActionError}
      />,
    );
  }

  if (status === 'IN_PROGRESS') {
    otherActions.push(
      <RowActionsToggle
        id={id}
        reference={reference}
        hasGroup={hasGroup}
        muted={muted}
        action="litigate"
        buttonIcon="warning-circle"
        onDone={handleToggleActionDone}
        onError={handleActionError}
      />,
    );
  }

  otherActions.push(
    <div className="PayRequestRowActions__separated">
      <RowActionNote
        id={id}
        reference={reference}
        onLoadingChange={setIsDropdownLoading}
        onDone={onActionDone}
      />
    </div>,
  );

  if (downloadPath) {
    otherActions.push(
      <DownloadLink
        path={downloadPath}
        className="PayRequestRowActions__download-link"
      >
        <ActionButton
          title={ct('payment-requests:download-receipt')}
          onClick={() => { }}
          icon={<Icon name="arrow-down" />}
        />
      </DownloadLink>,
    );
  }

  if ((status !== 'CANCELLED' && modePayRequest === 'PR_BY_PR')
    || (status === 'IN_PROGRESS' && modePayRequest === 'BALANCE')) {
    otherActions.push(
      <RowActionsToggle
        id={id}
        reference={reference}
        hasGroup={hasGroup}
        muted={muted}
        action="cancel"
        buttonVariant="list-danger"
        onDone={handleToggleActionDone}
        onError={handleActionError}
      />,
    );
  }

  if (status === 'CANCELLED') {
    otherActions.push(
      <RowActionsToggle
        id={id}
        reference={reference}
        hasGroup={hasGroup}
        muted={muted}
        action="remove"
        buttonVariant="list-danger"
        onDone={handleToggleActionDone}
        onError={handleActionError}
      />,
    );
  }

  return (
    <div className="PayRequestRowActions">
      {(status === 'IN_PROGRESS' && muted) && (
        <RowActionsToggle
          id={id}
          reference={reference}
          hasGroup={hasGroup}
          muted={muted}
          action="unmute"
          buttonVariant="list-primary"
          isOnlyIcon
          buttonIcon="bell-muted"
          onDone={handleToggleActionDone}
          onError={handleActionError}
        />
      )}
      {(status === 'IN_PROGRESS' && !muted) && (
        <RowActionsToggle
          id={id}
          reference={reference}
          hasGroup={hasGroup}
          muted={muted}
          action="mute"
          isOnlyIcon
          buttonIcon="bell"
          onDone={handleToggleActionDone}
          onError={handleActionError}
        />
      )}
      {(['IN_PROGRESS', 'LITIGATED'].includes(status)) && (
        <RowActionsToggle
          id={id}
          reference={reference}
          hasGroup={hasGroup}
          muted={muted}
          action="mark-as-paid"
          isOnlyIcon
          buttonIcon="check-circle"
          onDone={handleToggleActionDone}
          onError={handleActionError}
        />
      )}
      {status === 'PAID' && modePayRequest === 'PR_BY_PR' && (
        <RowActionsToggle
          id={id}
          reference={reference}
          hasGroup={hasGroup}
          muted={muted}
          action="cancel-mark-as-paid"
          isOnlyIcon
          buttonIcon="undo"
          onDone={handleToggleActionDone}
          onError={handleActionError}
        />
      )}
      {status === 'LITIGATED' && modePayRequest === 'PR_BY_PR' && (
        <RowActionsToggle
          id={id}
          reference={reference}
          hasGroup={hasGroup}
          muted={muted}
          action="cancel-litigate"
          isOnlyIcon
          buttonIcon="undo"
          onDone={handleToggleActionDone}
          onError={handleActionError}
        />
      )}
      <DropdownActions
        isLoading={isDropdownLoading}
        actions={otherActions}
      />
    </div>
  );
};

export default observer(PayRequestRowActions);
