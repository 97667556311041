import classnames from 'classnames';
import { Cell } from 'react-table';

type Props<DataType extends object = {}> = {
  cell: Cell<DataType, any>,
  showCheck: boolean,
  isSelected(rowId: string | number): boolean,
  rowIdentifier: string | number,
};

const DataTableBodyCell = <DataType extends object = {}>(props: Props<DataType>): JSX.Element => {
  const { cell, showCheck, isSelected, rowIdentifier } = props;

  const classNames = classnames('DataTableBody__cell', {
    'DataTableBody__cell--no-check': !showCheck,
    'DataTableBody__cell--selected': isSelected(rowIdentifier),
  });

  return (
    <td {...cell.getCellProps()} className={classNames}>
      {cell.render('Cell')}
    </td>
  );
};

export default DataTableBodyCell;
