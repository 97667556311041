/* eslint-disable prefer-rest-params */
/* eslint-disable func-names */

import config from '../../config';

const html = document.getElementsByTagName('html')[0];
const body = document.getElementsByTagName('body')[0];

const unloadFreshDeskScript = () => {
  html.removeChild(document.getElementById('loadFreshDeskScript'));

  const container = document.getElementById('freshworks-container');
  if (!container) {
    return;
  }
  body.removeChild(container);

  const frame = document.getElementById('freshworks-frame');
  if (!frame) {
    return;
  }
  frame.parentNode.removeChild(frame);
};

const loadFreshDeskScript = (url) => {
  if (typeof window.FreshworksWidget !== 'function') {
    const n = function () {
      n.q.push(arguments);
    };
    n.q = [];
    window.FreshworksWidget = n;
  }
  if (document.getElementById('loadFreshDeskScript')) {
    unloadFreshDeskScript();
  }
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;
  script.src = url;
  script.id = 'loadFreshDeskScript';
  html.appendChild(script);
};

const setNewLang = (lang) => {
  if (lang === 'es') {
    window.fwSettings = { widget_id: config.FRESHDESK_WIDGET_ID_ES };
    loadFreshDeskScript(config.FRESHDESK_URL_ES);
    return;
  }

  if (lang === 'en') {
    window.fwSettings = { widget_id: config.FRESHDESK_WIDGET_ID_EN };
    loadFreshDeskScript(config.FRESHDESK_URL_EN);
    return;
  }

  window.fwSettings = { widget_id: config.FRESHDESK_WIDGET_ID_FR };
  loadFreshDeskScript(config.FRESHDESK_URL_FR);
};

export default setNewLang;
