import './index.scss';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import parseIsoDate from 'utils/parseIsoDate';
import organizationStore from 'stores/Organization';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import DownloadLink from 'components/DownloadLink';
import { ReportContext } from '../../../..';
import { ReportFileContext } from '..';

const ImportsReportFileHeader = (): JSX.Element | null => {
  const { t } = useTranslation();
  const report = useContext(ReportContext);
  const file = useContext(ReportFileContext);
  const { currentOrganization, isSageOrganization } = organizationStore;
  const { format, date } = report ?? { format: 'other', date: '' };

  const isFreshReport = useMemo(() => {
    const reportDate = parseIsoDate(date);
    if (!reportDate) {
      return false;
    }
    const diffTime = (new Date()).getTime() - reportDate.getTime();
    const twoMonth = (24 * 60 * 60 * 1000) * 60;
    return diffTime < twoMonth;
  }, [date]);

  if (!file) {
    return null;
  }

  const {
    id,
    name,
    nbLine: count,
    nbLineDraft: draftCount,
    nbErrors: errorsCount,
    nbLineCreated: createdCount,
    nbLineUpdated: updatedCount,
    skippedLinesCount: skippedCount,
  } = file;

  return (
    <div className="ImportsReportFileHeader">
      <div className="ImportsReportFileHeader__infos">
        <Icon name="dot" />
        <h4 className="ImportsReportFileHeader__infos__title">
          {name}
        </h4>
        <p className="ImportsReportFileHeader__infos__text">
          {t('reports:line', { count })}
        </p>
      </div>
      <div className="ImportsReportFileHeader__tabs">
        {errorsCount > 0 && (!isSageOrganization || format !== 'pdf') && (
          <NavLink
            to={{ pathname: '/imports/report/error' }}
            className="ImportsReportFileHeader__tabs__tab ImportsReportFileHeader__tabs__tab--error"
            activeClassName="ImportsReportFileHeader__tabs__tab--active"
          >
            {t('reports:error', { count: errorsCount })}
            <Icon name="chevron-down" />
          </NavLink>
        )}
        {createdCount > 0 && (
          <NavLink
            to={{ pathname: '/imports/report/created' }}
            className="ImportsReportFileHeader__tabs__tab ImportsReportFileHeader__tabs__tab--created"
            activeClassName="ImportsReportFileHeader__tabs__tab--active"
          >
            {t('reports:line-created', { count: createdCount })}
            <Icon name="chevron-down" />
          </NavLink>
        )}
        {updatedCount > 0 && (
          <NavLink
            to={{ pathname: '/imports/report/updated' }}
            className="ImportsReportFileHeader__tabs__tab ImportsReportFileHeader__tabs__tab--updated"
            activeClassName="ImportsReportFileHeader__tabs__tab--active"
          >
            {t('reports:line-updated', { count: updatedCount })}
            <Icon name="chevron-down" />
          </NavLink>
        )}
        {skippedCount > 0 && (
          <NavLink
            to={{ pathname: '/imports/report/skipped' }}
            className="ImportsReportFileHeader__tabs__tab ImportsReportFileHeader__tabs__tab--skipped"
            activeClassName="ImportsReportFileHeader__tabs__tab--active"
          >
            {t('reports:line-skipped', { count: skippedCount })}
            <Icon name="chevron-down" />
          </NavLink>
        )}
      </div>
      <div className="ImportsReportFileHeader__actions">
        {(report?.format === 'pdf' && draftCount > 0) && (
          <Button
            variant="primary"
            href="/payment-requests/draft"
            className="ImportsReportFileHeader__actions__complete"
          >
            {t('common:complete')}
          </Button>
        )}
        <div className="ImportsReportFileHeader__actions__web">
          <h5 className="ImportsReportFileHeader__actions__web__text">
            {t('reports:web')}
          </h5>
        </div>
        {isFreshReport && (
          <Dropdown
            toggle={<Icon name="dots" />}
            variant="outline"
            noIcon
            className="ImportsReportFileHeader__actions__menu"
          >
            <DownloadLink
              path={`${currentOrganization?.reference}/import/${id}`}
              className="ImportsReportFileHeader__actions__download"
            >
              <Button variant="list">
                {t('reports:download-file')}
              </Button>
            </DownloadLink>
          </Dropdown>
        )}
      </div>
    </div>
  );
};

export default observer(ImportsReportFileHeader);
