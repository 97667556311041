import type { PhaseId } from 'types/models';

const getI18nPhase = (phaseId: string): string => {
  const phasesI18n = {
    UPCOMING: 'phases:upcoming',
    PENDING: 'phases:near',
    ALMOST_DUE: 'phases:in-progress',
    DUE: 'phases:overdue',
    OVERDUE: 'phases:late',
    LATE: 'phases:suffering',
    DEFAULT: 'phases:unpaid',
    INACTIVE: 'phases:inactive',
  };

  if (!Object.keys(phasesI18n).includes(phaseId)) {
    return 'phases:unknown-phase';
  }

  return phasesI18n[phaseId as PhaseId];
};

export default getI18nPhase;
