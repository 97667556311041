import React from 'react';
import { useTranslation } from 'react-i18next';
import type Errors from 'types/errors';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormSelect from 'components/FormSelect';

interface Props {
  defaultData?: string,
  errors?: Errors.Validation | null,
  isGroup: boolean,
}

const EditPlanForm: React.FC<Props> = ({ defaultData, errors, isGroup }) => {
  const { t } = useTranslation();

  return (
    <FormFieldset>
      <FormGroup label={t('payment-requests:dunning-plan-edit')} error={errors?.plan}>
        <FormSelect
          name="plan"
          placeholder={t('payment-requests:dunning-plan-edit')}
          isAsync
          fetchEntity="plans"
          fetchEntityQuerystring={isGroup ? 'group=1' : ''}
          defaultValue={defaultData}
        />
      </FormGroup>
    </FormFieldset>
  );
};

export default EditPlanForm;
