import './index.scss';
import { useCallback, useEffect, useState } from 'react';
import type { SyntheticEvent } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import authStore from 'stores/Auth';
import useIsMountedRef from 'hooks/useIsMountedRef';
import useApiRequest from 'hooks/useApiRequest';
import type Errors from 'types/errors';
import type { Parser } from 'types/models';
import ToastNotification from 'components/ToastNotification';
import useToast from 'components/ToastNotification/useToast';
import Loading from 'components/Loading';
import formatIntlDate from 'utils/formatIntlDate';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import Button from 'components/Button';
import ErrorMessage from 'components/ErrorMessage';
import BackOfficeSection from '../Section';

const BackOfficeParser = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { user } = authStore;
  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const {
    get,
    put,
    isLoading,
    error: saveError,
  } = useApiRequest();

  const [reload, setReload] = useState<boolean>(false);
  const [parser, setParser] = useState<Parser | null>(null);
  const [identifier, setIdentifier] = useState<string>('');
  const [validationErrors, setValidationErrors] = useState<Errors.Validation | null>(null);

  const isMountedRef = useIsMountedRef();

  const fetchParser = useCallback(async () => {
    const response = await get<Parser>('/back-office/parser');
    if (!response) {
      return;
    }
    if (isMountedRef) {
      setParser(response);
      setIdentifier(response.id);
      setReload(false);
    }
  }, [get, setParser, isMountedRef]);

  const handleSubmit = useCallback(async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }

    const result = await put<Parser>('back-office/parser', { identifier });
    if (!isMountedRef.current) {
      return;
    }

    if (result?.errors) {
      setValidationErrors(result.errors);
      return;
    }

    if (result?.id) {
      setReload(true);
      showToast(t('users:toast.success.settings-update'), 'success');
    }
  }, [isLoading, isMountedRef, put, showToast, t, identifier]);

  useEffect(() => {
    fetchParser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  useEffect(() => {
    if (!user) {
      showToast(t('users:toast.error.user-empty'), 'error');
      setTimeout(() => { authStore.logout(); }, 3000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <div className="BackOfficeParser">
      <BackOfficeSection
        title={t('users:parser')}
      >
        {isLoading && (<Loading />)}
        {saveError && <ErrorMessage error={saveError} />}
        {!isLoading && parser && (
          <div className="BackOfficeParser__form">
            <p>{t('users:back-office.last-update')} {formatIntlDate(parser.updatedAt, 'dateTimeLong')}</p>
            <form className="BackOfficeParser__form__parser" onSubmit={handleSubmit}>
              <FormFieldset>
                <FormGroup label={t('common:identifier')} mandatory error={validationErrors?.identifier}>
                  <FormControl
                    type="text"
                    name="identifier"
                    defaultValue={parser.id}
                    onChange={(value: string) => { setIdentifier(value); }}
                  />
                </FormGroup>
              </FormFieldset>
              <Button type="submit" variant="primary">
                {isLoading && <Loading />}
                {!isLoading && t('common:save')}
              </Button>
            </form>
          </div>
        )}
      </BackOfficeSection>
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </div>
  );
};

export default observer(BackOfficeParser);
