import './index.scss';
import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import type { ToastStatus } from './useToast';

interface Props {
  text: string,
  status?: ToastStatus,
  isShow: boolean,
  onClose: () => void,
}

const ToastNotification: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const {
    text,
    status = 'info',
    isShow,
    onClose,
  } = props;

  const delay = React.useMemo(() => {
    const multiplier = status === 'error' ? 2 : 1;
    return 4000 * multiplier;
  }, [status]);

  React.useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (isShow) {
      timeoutId = setTimeout(() => { onClose(); }, delay);
    }
    return () => { clearTimeout(timeoutId); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow]);

  if (!isShow) {
    return null;
  }

  const classNames = classnames('ToastNotification', {
    'ToastNotification--primary': status === 'primary',
    'ToastNotification--info': status === 'info',
    'ToastNotification--success': status === 'success',
    'ToastNotification--warning': status === 'warning',
    'ToastNotification--error': status === 'error',
  });

  return (
    <div className={classNames}>
      <div className="ToastNotification__wrapper">
        <div className="ToastNotification__content">
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: text }} />
          <Button
            variant="outline-light"
            className="ToastNotification__close-button"
            onClick={onClose}
          >
            {t('common:close')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ToastNotification;
