import { useCallback, useMemo } from 'react';
import { useModal } from 'react-modal-hook';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import type { PayRequest } from 'types/models';
import type { ToggleAction } from 'pages/PaymentRequests/Actions/actions';
import PromiseToPay from 'pages/PaymentRequests/Actions/Row/PromiseToPay';
import ActionsToggle from 'pages/PaymentRequests/Actions/Row/Toggle';
import getToggleActionI18n from 'pages/PaymentRequests/Actions/Row/getToggleActionI18n';
import AddPayment from 'pages/PaymentRequests/Actions/Row/AddPayment';
import CreateTaskForm from 'pages/PaymentRequest/Modals/CreateTask';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import CreateReminder from 'components/CreateReminder';
import BlockSection from 'components/BlockSection';
import ActionsGroup from 'components/ActionsGroup';
import ActionButton from 'components/ActionButton';
import Icon from 'components/Icon';
import DownloadLink from 'components/DownloadLink';
import { ExternallyMonitoredController } from 'components/ExternallyMonitored';
import { UserRightsManagerController } from 'components/UserRights';
import MoreActions from './More';

type Props = {
  data: PayRequest,
  onDone(message: string): void,
  onError(message: string): void,
};

const PaymentRequestSideActions = ({ data, onDone, onError }: Props): JSX.Element | null => {
  const history = useHistory();
  const { linesOfBusiness, modePayRequest, userRight } = organizationStore;
  const isUserReader = userRight === 'ROLE_READER';
  const { t, ct } = useContextualTranslation(linesOfBusiness);
  const hasGroup = !!data?.group;

  const [showCreateTaskModal, hideCreateTaskModal] = useModal(() => (
    <CreateTaskForm
      onDone={onDone}
      onClose={hideCreateTaskModal}
      payRequestData={data}
    />
  ), [onDone, data]);

  const [showCreateReminder, hideCreateReminder] = useModal(() => (
    <CreateReminder
      payRequestData={data}
      onDone={onDone}
      onClose={hideCreateReminder}
    />
  ), [data, onDone]);

  const handleToggleActionDone = useCallback((action: ToggleAction) => {
    if (data === null) {
      return;
    }
    const { reference } = data;
    const { toastText: toggleToastText } = getToggleActionI18n(t, ct, reference, hasGroup)[action];
    onDone(toggleToastText);
    if (action === 'remove') {
      history.push('/payment-requests');
    }
  }, [data, hasGroup, t, ct, onDone, history]);

  const {
    id,
    reference,
    muted,
    status,
    payments,
    user,
    client,
    amount,
    promiseToPayAt,
    invoiceName,
    token,
  } = data;

  const isNotCancelled = status !== 'CANCELLED';
  const isPaid = status === 'PAID';
  const isLitigated = status === 'LITIGATED';
  const isCancelled = status === 'CANCELLED';
  const isInProgress = status === 'IN_PROGRESS';
  const showMoreActions = !isPaid && isNotCancelled;
  const canMarkAsPaid = (
    status !== 'CANCELLED'
    && !(modePayRequest === 'BALANCE' && isPaid)
    && (payments.length === 0 || !isPaid)
  );

  const moreActions = useMemo(() => (
    <MoreActions
      data={data}
      onToggleDone={handleToggleActionDone}
      onDone={onDone}
      onError={onError}
    />
  ), [data, handleToggleActionDone, onDone, onError]);

  if (isUserReader) {
    return null;
  }

  return (
    <BlockSection title={t('common:commands')}>
      <ActionsGroup moreActions={showMoreActions ? moreActions : null}>
        {isLitigated && modePayRequest === 'PR_BY_PR' && (
          <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
            <UserRightsManagerController
              action="UPDATE"
              managers={user}
              categoryType="PAYREQUESTS"
            >
              <ActionsToggle
                id={id}
                reference={reference}
                hasGroup={hasGroup}
                muted={muted}
                action="cancel-litigate"
                buttonIcon="undo"
                onDone={handleToggleActionDone}
                onError={onError}
              />
            </UserRightsManagerController>
          </ExternallyMonitoredController>
        )}
        {canMarkAsPaid && (
            <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
              <UserRightsManagerController action="UPDATE" categoryType="PAYREQUESTS" managers={user}>
                <ActionsToggle
                  id={id}
                  reference={reference}
                  hasGroup={hasGroup}
                  muted={muted}
                  action={isPaid ? 'cancel-mark-as-paid' : 'mark-as-paid'}
                  buttonIcon={isPaid ? 'undo' : 'check-circle'}
                  onDone={handleToggleActionDone}
                  onError={onError}
                />
              </UserRightsManagerController>
            </ExternallyMonitoredController>
        )}
        {!isPaid && isNotCancelled && (
          <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
            <UserRightsManagerController action="CREATE" categoryType="PAYMENTS" managers={user}>
              <AddPayment
                reference={reference}
                id={id}
                customerReference={client.reference}
                maxAmount={amount}
                onDone={onDone}
              />
            </UserRightsManagerController>
          </ExternallyMonitoredController>
        )}
        {(!isPaid && isNotCancelled) && (
          <UserRightsManagerController action="UPDATE" categoryType="PAYREQUESTS" managers={user}>
            <PromiseToPay
              id={id}
              reference={reference}
              defaultValue={promiseToPayAt}
              onLoadingChange={() => {}}
              onDone={onDone}
            />
          </UserRightsManagerController>
        )}
        {isInProgress && (
          <ActionButton
            title={t('payment-requests:add-task')}
            onClick={showCreateTaskModal}
            icon={<Icon name="list" />}
          />
        )}
        {isInProgress && (
          <ActionButton
            title={t('payment-requests:add-dunning')}
            onClick={showCreateReminder}
            icon={<Icon name="message" />}
          />
        )}
        {invoiceName && (
          <DownloadLink
            path={`${id}/${token}`}
            className="PaymentRequestSide__link"
          >
            <ActionButton
              title={ct('payment-requests:download-receipt')}
              onClick={() => { }}
              icon={<Icon name="arrow-down" />}
            />
          </DownloadLink>
        )}
        {!showMoreActions && moreActions}
        {isCancelled && (
          <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
            <UserRightsManagerController action="DELETE" categoryType="PAYREQUESTS" managers={user}>
              <ActionsToggle
                id={id}
                reference={reference}
                hasGroup={hasGroup}
                muted={muted}
                action="remove"
                buttonIcon="warning-circle"
                buttonVariant="danger"
                onDone={handleToggleActionDone}
                onError={onError}
              />
            </UserRightsManagerController>
          </ExternallyMonitoredController>
        )}
      </ActionsGroup>
    </BlockSection>
  );
};

export default observer(PaymentRequestSideActions);
