import './index.scss';
import { useTranslation } from 'react-i18next';
import type { ReportLine } from 'types/models';
import type { FetchAllLinesFromFileParams } from 'api/imports';
import { fetchAllLinesFromFile } from 'api/imports';
import useFetchPaginated from 'hooks/useFetchPaginated';
import Loading from 'components/Loading';

type Props = {
  fileId: number,
};

const ShowErrors = ({ fileId }: Props): JSX.Element => {
  const { t } = useTranslation();

  const {
    isLoading,
    data,
  } = useFetchPaginated<FetchAllLinesFromFileParams, ReportLine>(
    { cacheKey: 'fileLines', fileId, fetchOptions: { pageSize: 50, pageIndex: 1 } },
    fetchAllLinesFromFile,
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="ImportsReportItemShowError">
      {data?.length === 1 && (
        data.map(({ errors, line }) => (
          <p className="ImportsReportItemShowError__field" key={`${line}-${errors.map(({ field }) => field).join(',')}`}>
            {`${t('common:error')}: ${errors.map(({ field }) => field).join(', ')}`}
          </p>
        )))}
    </div>
  );
};

export default ShowErrors;
