import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import type Errors from 'types/errors';
import type Misc from 'types/misc';
import type { PayRequestPost, OrganizationPlan, Client, Contact as ContactType } from 'types/models';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import useFetch from 'hooks/useFetch';
import useApiRequest from 'hooks/useApiRequest';
import formatIntlDate from 'utils/formatIntlDate';
import { fetchOne } from 'api/customers';
import type { FetchOneParams } from 'api/customers';
import { fetchAll as fetchAllUsers } from 'api/users';
import type { FetchAllParams as FetchAllUsersParams } from 'api/users';
import { fetchAllForListContact, FetchAllForListContactParams } from 'api/contacts';
import formatIntlNumber from 'utils/formatIntlNumber';
import recomposeName from 'utils/recomposeName';
import Button from 'components/Button';
import PDFViewer from 'components/PDFViewer';
import ContactEditModal from 'components/ContactEditModal';
import Loading from 'components/Loading';
import useToast from 'components/ToastNotification/useToast';
import ToastNotification from 'components/ToastNotification';
import ListElement from 'components/ListElement';
import Contact from './Contact';

interface Props {
  data: PayRequestPost | null,
  customerId: number,
  receiptName: string | null,
  receipt: string | null,
  onError(errors: Errors.Validation): void,
}

type PostDunningPlanData = OrganizationPlan[] | { [key: string]: OrganizationPlan };

const PaymentRequestPreview = (props: Props): JSX.Element => {
  const {
    data,
    customerId,
    receiptName,
    receipt,
    onError,
  } = props;

  const {
    currentOrganization,
    linesOfBusiness,
    eavs,
  } = organizationStore;

  const { post } = useApiRequest();

  const [plan, setPlan] = React.useState<string>('');

  const { t, ct } = useContextualTranslation(linesOfBusiness);

  const {
    showToast,
    isToastShowed,
    toastStatus,
    toastText,
    hideToast,
  } = useToast();

  const { data: customerData } = useFetch<FetchOneParams, Client>(
    {
      cacheKey: 'customer',
      organization: currentOrganization?.reference,
      id: customerId,
    },
    fetchOne,
  );

  const { data: allManagers } = useFetch<FetchAllUsersParams, Misc.IdRefName[]>(
    { cacheKey: 'managers', organization: currentOrganization?.reference },
    fetchAllUsers,
    {
      enabled: !!currentOrganization?.reference,
      retry: 1,
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: contacts,
    refetch: refetchContacts,
    status: contactsStatus,
    isFetching: isFetchingContacts,
  } = useFetch<FetchAllForListContactParams, ContactType[]>(
    {
      cacheKey: 'contacts',
      organization: currentOrganization?.reference,
      customerId,
      unlimitedNumberOfContacts: true,
    },
    fetchAllForListContact,
  );

  const managers = React.useMemo(() => {
    const usersIdentifier = data?.users.map((user) => user.identifier);
    return allManagers?.filter(({ reference }) => usersIdentifier?.includes(reference));
  }, [allManagers, data]);

  const getDunningPlan = React.useCallback(async () => {
    if (!data) {
      return;
    }

    const dunningPlan = await post<PostDunningPlanData>('pay-requests/picker-simulation', data);
    if (!dunningPlan) {
      return;
    }

    if (dunningPlan.errors) {
      onError(dunningPlan.errors);
      return;
    }

    if (Array.isArray(dunningPlan)) {
      setPlan(dunningPlan[0]?.name);
      return;
    }

    Object.keys(dunningPlan).forEach((key) => {
      if (dunningPlan[key]?.name) {
        setPlan(dunningPlan[key]?.name);
      }
    });
  }, [data, post, onError]);

  React.useEffect(() => {
    if (data) {
      getDunningPlan();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const [showCreateModal, hideCreateModal] = useModal(() => (
    <ContactEditModal
      editType="new"
      defaultCustomerId={customerId}
      customerId={customerId}
      onClose={hideCreateModal}
      onDone={(message) => { showToast(message, 'success'); refetchContacts(); }}
    />
  ), [refetchContacts, showToast, customerId]);

  const labelledEavs = React.useMemo(() => (
    data?.eavs?.map((eav) => ({
      label: eavs.payRequest.find(
        ({ identifier }) => identifier === eav.identifier,
      )?.label || eav.identifier,
      value: eav.value,
      identifier: eav.identifier,
    })) || []
  ), [data, eavs.payRequest]);

  return (
    <dl className="PaymentRequestPreview">
      <ListElement label={ct('common:client')}>
        <span className="PaymentRequestPreview__client">
          {customerData && recomposeName(customerData)}
          {customerData && recomposeName(customerData) !== customerData.reference && (
            <span className="PaymentRequestPreview__client__reference">
              ({customerData.reference})
            </span>
          )}
        </span>
      </ListElement>
      <ListElement label={t('payment-requests:ref')}>{data?.reference}</ListElement>
      <ListElement label={t('common:amount')}>
        {formatIntlNumber(data?.amount, data?.currency)}
      </ListElement>
      <ListElement label={t('payment-requests:issued-on')}>
        {formatIntlDate(data?.issueAt, 'dateLong')}
      </ListElement>
      <ListElement label={t('payment-requests:subject')}>{data?.subject}</ListElement>
      <ListElement label={t('common:due-at')}>
        {formatIntlDate(data?.dueAt, 'dateLong')}
      </ListElement>
      <hr className="PaymentRequestPreview__divider" />
      <ListElement label={t('payment-requests:dunning-plan')}>
        {plan || t('payment-requests:no-plan')}
      </ListElement>
      <ListElement label={t('payment-requests:plan-start-at')}>
        {formatIntlDate(data?.startAt, 'dateLong')}
      </ListElement>
      <ListElement label={t('plans:dispatch')}>
        {data?.sendDispatches ? t('common:yes') : t('common:no')}
      </ListElement>
      <ListElement label={t('common:group')}>
        {data?.groupDunning ? t('common:yes') : t('common:no')}
      </ListElement>
      <ListElement
        label={t('common:contacts')}
        fullWidth
        action={<Button variant="link" onClick={showCreateModal}>{t('common:create')}</Button>}
      >
        {contactsStatus === 'loading' && isFetchingContacts && <Loading hasNoText />}
        {contacts?.map((contact) => (
          <Contact
            key={contact.id}
            contact={contact}
            onActionDone={(message) => { refetchContacts(); showToast(message, 'success'); }}
            onActionError={(message) => { showToast(message, 'error'); }}
          />
        ))}
        {contactsStatus !== 'loading' && !isFetchingContacts && contacts !== null && contacts.length === 0 && (
          <p className="PaymentRequestPreview__no-contacts">{t('contacts:no-data-customer')}</p>
        )}
      </ListElement>
      <ListElement label={t('common:managers')} fullWidth>
        <span className="PaymentRequestPreview__managers">{managers?.map(({ name }) => name).join(', ')}</span>
      </ListElement>
      <ListElement label={t('payment-requests:receipt')} fullWidth>
        <p>{receiptName || t('common:none')}</p>
        {receipt && <PDFViewer base64PDF={receipt} scale={0.4} />}
      </ListElement>
      <ListElement label={t('common:attributes')} fullWidth>
        {labelledEavs.map((eav) => (
          <div key={eav.identifier} className="PaymentRequestPreview__attributes">
            <span className="PaymentRequestPreview__attributes__label">{eav.label}</span>
            <span className="PaymentRequestPreview__attributes__value">{eav.value || '-'}</span>
          </div>
        ))}
      </ListElement>
      <ToastNotification
        isShow={isToastShowed}
        status={toastStatus}
        text={toastText}
        onClose={hideToast}
      />
    </dl>
  );
};

export default observer(PaymentRequestPreview);
