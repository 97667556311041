import deepfreeze from 'deep-freeze';
import type { Accept } from 'react-dropzone';
import type { PhaseId, RightsRole } from 'types/models';
import type Misc from 'types/misc';
import errorsCodes from './errorsCodes';

export default deepfreeze({
  APP_VERSION: process.env.REACT_APP_VERSION,

  IS_PROD: process.env.REACT_APP_STAGE === 'production',

  API_URL: process.env.REACT_APP_API_URL,
  API_AUTH_ID: process.env.REACT_APP_API_AUTH_ID,
  API_AUTH_SECRET: process.env.REACT_APP_API_AUTH_SECRET,

  MERCURE_URL: process.env.REACT_APP_MERCURE_URL,

  STRIPE_TOKEN: process.env.REACT_APP_STRIPE_TOKEN,

  UPLOADS_BASE_URL: process.env.REACT_APP_UPLOADS_BASE_URL,

  CONFIRMATION_EMAIL_COOLDOWN: 60 * 60 * 1000,

  DEFAULT_LOCALE: { locale: 'en' as Misc.Lang },

  SEARCH_MAX_COUNT: 5,
  DEFAULT_PAGE_SIZE: 25,
  PAGE_SIZES: [10, 25, 50, 100],

  SMS_MAX_LENGTH: 160,

  FRESHDESK_URL_FR: 'https://widget.freshworks.com/widgets/6000000158.js',
  FRESHDESK_URL_ES: 'https://euc-widget.freshworks.com/widgets/80000001376.js',
  FRESHDESK_URL_EN: 'https://euc-widget.freshworks.com/widgets/80000001377.js',
  FRESHDESK_WIDGET_ID_FR: 6000000158,
  FRESHDESK_WIDGET_ID_ES: 80000001376,
  FRESHDESK_WIDGET_ID_EN: 80000001377,

  FRESHDESK_TAG_LINKS: {
    fr: 'https://dunforce.freshdesk.com/a/solutions/articles/6000245273',
    en: 'https://dunforce-en.freshdesk.com/a/solutions/articles/80000242359',
    es: 'https://dunforce-es.freshdesk.com/a/solutions/articles/80000349913',
  },

  ORGANIZATION_SECTORS: [
    'marketing-creative-agencies',
    'software-internet',
    'education',
    'health',
    'real-estate-construction',
    'financial-services',
    'media-publishing',
    'industries',
    'consulting',
    'retail-wholesale',
    'other',
  ] as Misc.OrganizationSectors[],

  EAV_FORMATS: {
    number: /^[0-9]+$/,
    date: /^\d{4}-[0-1]{1}[0-9]{1}-[0-3]{1}[0-9]{1}$/,
    decimalNumber: /^[0-9]+(\.[0-9][0-9]?)?$/,
  },

  COMPARISON_OPERATORS: [
    'IS NULL',
    'IS NOT NULL',
    '===',
    '==',
    '<',
    '<=',
    '>',
    '>=',
    'IN',
    'NOT IN',
    '!=',
    '<>',
  ] as Misc.ComparisonOperator[],

  PAYREQUEST_SELECTOR_OPTIONS: [
    '{PAYREQUEST_dishonouredAt}',
    '{PAYREQUEST_dueAt}',
    '{PAYREQUEST_amount}',
    '{PAYREQUEST_reference}',
    '{PAYREQUEST_currency}',
    '{PAYREQUEST_createdAt}',
    '{PAYREQUEST_groupDunning}',
  ],

  CLIENT_SELECTOR_OPTIONS: [
    '{CLIENT_type}',
    '{CLIENT_reference}',
    '{CLIENT_createdAt}',
    '{CLIENT_city}',
    '{CLIENT_country}',
  ],

  PAYMENT_SELECTOR_OPTIONS: [
    '{PAYMENT_status}',
  ],

  DEBT_SELECTOR_OPTIONS: [
    '{PAYREQUEST_totalDebtDue}',
    '{PAYREQUEST_totalDebt}',
  ],

  MANAGERS_SELECTOR_OPTIONS: [
    '{MANAGERS_IDS}',
  ],

  PHASES_ID: [
    'UPCOMING',
    'PENDING',
    'ALMOST_DUE',
    'DUE',
    'OVERDUE',
    'LATE',
    'DEFAULT',
    'INACTIVE',
  ] as PhaseId[],

  PERIODS_ID: [
    'today',
    'next15days',
    'last15days',
    'futur',
    'customized',
    'none',
  ] as const,

  STATUSES_ID: [
    'activities-clients',
    'task_to_do',
    'reminder_to_do',
    'contact_to_do',
    'planned_reminder',
    'task_processed',
    'reminder_processed',
    'contact_processed',
    'sent_reminder',
    'sent_error_reminder',
    'waiting',
  ] as const,

  CHANNELS_ID: [
    'SMS',
    'EMAIL',
    'LETTER',
    'REGISTERED_LETTER',
  ] as Misc.Channel[],

  PLAN_CHANGE_CONDITION: [
    'dishonoured_payment',
  ],

  CHANNEL_COLORS: {
    SMS: '#d0ffcf',
    EMAIL: '#ffefcf',
    LETTER: '#cfffff',
    REGISTERED_LETTER: '#ffcfcf',
    DEFAULT: '#ffddcf',
  },

  // - Colors of phases IDs   | i18n(fr)   |
  PHASES_COLORS: { //-----------------------
    UPCOMING: '#d0ffcf', //   | À venir    |
    PENDING: '#cfffe8', //    | Proche     |
    ALMOST_DUE: '#cfffff', // | En cours   |
    DUE: '#ffefcf', //        | Échue      |
    OVERDUE: '#ffddcf', //    | Retard     |
    LATE: '#ffcfcf', //       | Souffrance |
    DEFAULT: '#e1cfff', //    | Impayée    |
    INACTIVE: '#e5e5e5', //   | Inactive   |
  },

  // - Colors of periods IDs  | i18n(fr)   |
  PERIODS_COLORS: { //----------------------
    INF90: '#ffcfcf', //      | Inf. 90    |
    INF9060: '#e1cfff', //    | Inf. 90-60 |
    INF6030: '#e5e5e5', //    | Inf. 60-30 |
    INF3010: '#ffddcf', //    | Inf. 30-10 |
    INF100: '#ffefcf', //     | Inf. 100   |
    SUP010: '#d0ffcf', //     | Sup. 0-10  |
    SUP1030: '#cfffe8', //    | Sup. 10-30 |
    SUP30: '#cfffff', //      | Sup. 30    |
    DEFAULT: '#e1cfff', //    | Par défaut |
  },

  // - Icons for activities entities
  ACTIVITIES_ICONS: {
    Reminder: 'fas fa-paper-plane',
    Task: 'fas fa-wrench',
    PayerAlert: 'fas fa-crosshairs',
    UserAlert: 'fas fa-crosshairs',
    Milestone: 'fas fa-map-pin',
    PhaseChange: 'fas fa-tachometer-alt',
  },

  ERRORS_CODES: { ...errorsCodes },

  LANGUAGES: [
    { value: 'fr', label: 'Français' },
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Español' },
  ],

  ROLES_COLLABORATORS: [
    'RECOVERY_ADIVSOR',
    'INTERNAL_ACCOUNTANT',
    'INTERNAL_SALES',
  ] as Misc.Role[],

  USER_RIGHTS: [
    'ROLE_SUPER_ADMIN',
    'ROLE_OWNER',
    'ROLE_ADMIN',
    'ROLE_SUPERVISOR',
    'ROLE_COORDINATOR',
    'ROLE_ACCOUNT_MANAGER',
    'ROLE_READER',
  ] as RightsRole[],

  CURRENCIES: [
    'EUR', 'GBP', 'CHF', 'USD', 'CAD', 'AUD', 'JPY',
    'SAR', 'AED', 'BHD', 'ZAR', 'THB', 'SGD', 'PHP', 'NZD', 'MYR',
    'MXN', 'KRW', 'INR', 'ILS', 'IDR', 'HKD', 'CNY', 'BRL', 'TRY',
    'RUB', 'HRK', 'NOK', 'ISK', 'SEK', 'RON', 'PLN', 'HUF', 'DDK',
    'CZK', 'BGN',
  ] as const,

  TYPES_ORGANIZATION: [
    'COMPANY',
    'INDIVIDUAL',
  ] as const,

  TYPES_EXTENSIONS: [
    'PDF',
    'CSV',
    'XLSX',
  ] as const,

  IMPORT_STATUSES: [
    'FILE_PROCESSED',
    'FILE_PROCESSING',
    'FILE_CREATED',
    'FILE_ERROR',
    'FILE_SKIPPED',
  ] as Misc.ImportStatusId[],

  PAYMENT_METHODS: [
    'OTHER',
    'CARD',
    'CHECK',
    'TRANSFER',
    'DIRECT_DEBIT',
    'CASH',
  ] as Misc.PaymentMethod[],

  PAYMENT_TYPES: [
    'PAYMENT',
    'CREDIT_NOTE',
    'REFUND',
  ] as Misc.PaymentType[],

  ORGANIZATION_PAYMENT_ATTRIBUTION: [
    'MANUAL',
    'AUTOMATIC_OLDER',
    'AUTOMATIC_NEWER',
  ] as Misc.OrganizationPaymentAttribution[],
});

export const ACCEPT_FILES_TYPE: Record<'YAML' | 'PDF' | 'SHEET' | 'ANY', Accept> = {
  YAML: {
    'text/yaml': ['.yml', '.yaml'],
    'text/x-yaml': ['.yml', '.yaml'],
    'application/x-yaml': ['.yml', '.yaml'],
  },
  PDF: {
    'application/*': ['.pdf'],
  },
  SHEET: {
    'text/csv': ['.csv'],
    'application/vnd.ms-excel': ['.xls', '.xlsx'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx'],
  },
  ANY: {
    '*': ['*'],
  },
};
