import { Link } from 'react-router-dom';
import { Fragment } from 'react';
import type { TFunction } from 'i18next';
import type { Column } from 'react-table';
import type { PayRequestDraft } from 'types/models';
import formatIntlNumber from 'utils/formatIntlNumber';
import organizationStore from 'stores/Organization';
import PhaseDate from 'components/PhaseDate';
import { ExternallyMonitoredController } from 'components/ExternallyMonitored';
import { UserRightsController } from 'components/UserRights';
import ErrorValidationMessage from 'components/ErrorValidationMessage';
import RowActions from '../Actions/Row';

const PayRequestToCompleteColumns = (
  ct: TFunction,
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
): Column<PayRequestDraft>[] => [
  {
    Header: ct<string>('common:reference'),
    accessor: 'reference',
    Cell: ({ value }) => (
      <>
        {value && <div>{value}</div>}
        {!value && (
          <span className="PaymentRequestsToComplete__columns__unknown-data">
            {ct('payrequests-draft:unknown-data.reference')}
          </span>
        )}
      </>
    ),
  },
  {
    Header: ct<string>('common:client'),
    accessor: 'client',
    Cell: ({ value, row }) => (
      <>
        {value && (
          <div className="PaymentRequestsToComplete__columns__client">
            {row.original.errors?.client !== undefined && value}
            {row.original.errors?.client === undefined && (
              <Link to={`/customer/${row.original.clientId}`}>{value}</Link>
            )}
          </div>
        )}
        {!value && (
          <span className="PaymentRequestsToComplete__columns__unknown-data">
            {ct('payrequests-draft:unknown-data.client')}
          </span>
        )}
      </>
    ),
  },
  {
    Header: ct<string>('common:amount'),
    accessor: 'amount',
    sortType: 'basic',
    sortDescFirst: true,
    Cell: ({ row, value }) => (
      value ? (
        <Fragment>
          {formatIntlNumber(
            value,
            row.original.currency || organizationStore.currency,
          )}
        </Fragment>
      ) : (
        <span className="PaymentRequestsToComplete__columns__unknown-data">
          {ct('payrequests-draft:unknown-data.amount')}
        </span>
      )
    ),
  },
  {
    Header: ct<string>('common:deadline'),
    accessor: 'dueAt',
    sortType: 'datetime',
    sortDescFirst: true,
    Cell: ({ value }) => (value
      ? <PhaseDate date={value} phaseId="UPCOMING" statusId="PAID" />
      : (
        <span className="PaymentRequestsToComplete__columns__unknown-data">
          {ct('payrequests-draft:unknown-data.date')}
        </span>
      )),
  },
  {
    Header: ct<string>('payrequests-draft:error-when-creating'),
    accessor: 'errors',
    sortDescFirst: true,
    Cell: ({ value }) => (
      <>
        <ErrorValidationMessage error={value?.client} />
        <ErrorValidationMessage error={value?.dueAt} fieldName={ct('common:due-at')} />
        <ErrorValidationMessage error={value?.amount} fieldName={ct('common:amount')} />
        <ErrorValidationMessage error={value?.reference} fieldName={ct('common:reference')} />
      </>
    ),
  },
  {
    Header: ct<string>('common:commands'),
    accessor: 'importId',
    disableSortBy: true,
    Cell: ({ value, row }) => (
      <div className="PaymentRequestsToComplete__columns__commands">
        <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
          <UserRightsController action="UPDATE" category="IMPORTS">
            <RowActions
              id={value}
              data={row.original}
              onActionDone={onActionDone}
              onActionError={onActionError}
            />
          </UserRightsController>
        </ExternallyMonitoredController>
      </div>
    ),
  },
];

export default PayRequestToCompleteColumns;
