import './index.scss';
import { useTranslation } from 'react-i18next';
import type Errors from 'types/errors';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormSwitch from 'components/FormSwitch';
import FormSelectUsersWithRights from 'components/FormSelectUsersWithRights';
import organizationStore from 'stores/Organization';

interface Props {
  defaultData?: boolean,
  name: string,
  errors?: Errors.Validation | null,
}

const SendingCustomerPortalEditForm = (props: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;

  const {
    defaultData,
    name,
    errors,
  } = props;

  return (
    <div className="SendingCustomerPortalEditForm">
      <FormFieldset>
        <FormGroup
          label={t('common:active')}
          error={errors?.name}
        >
          <FormSwitch name={name} defaultValue={defaultData} />
        </FormGroup>
        <FormGroup
          label={t('payer:actions.referent')}
          error={errors?.name}
        >
          <FormSelectUsersWithRights
            name="referent"
            placeholder={t('common:please-choose')}
            fetchEntity="users"
            entityValue="reference"
            defaultValue={currentOrganization?.referent?.identifier || null}
            isInvalid={!!errors?.users}
            isAsync
            action="READ"
            categoryType="CONTACTS"
          />
        </FormGroup>
      </FormFieldset>
    </div>
  );
};

export default SendingCustomerPortalEditForm;
