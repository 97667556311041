import './index.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Filler,
} from 'chart.js';
import organizationStore from 'stores/Organization';
import { AmountByPhase } from 'types/models';
import getColorPhase from 'utils/getColorPhase';
import getI18nPhase from 'utils/getI18nPhase';
import defaultChartOptions from '../../defaultChartOptions';

type Props = {
  data: AmountByPhase[],
};

ChartJS.register(BarElement, PointElement, LinearScale, CategoryScale, Tooltip, Filler);

const ReceivableChart = ({ data }: Props): JSX.Element => {
  const { currency } = organizationStore;
  const { t } = useTranslation();

  const sortedData = useMemo(() => {
    const order = [
      'UPCOMING',
      'PENDING',
      'ALMOST_DUE',
      'DUE',
      'OVERDUE',
      'LATE',
      'DEFAULT',
      'INACTIVE',
    ];
    return [...data].sort(({ id: leftId }, { id: rightId }) => {
      const leftIndex = order.findIndex(((orderId) => leftId === orderId));
      const rightIndex = order.findIndex(((orderId) => rightId === orderId));
      if (leftIndex === rightIndex) {
        return 0;
      }
      return leftIndex > rightIndex ? 1 : -1;
    });
  }, [data]);

  const chart = useMemo(() => ({
    labels: sortedData.map(({ id }) => t(getI18nPhase(id))),
    datasets: [{
      label: `${t('common:pay-requests')} (${currency})`,
      data: sortedData.map(({ cost }) => Math.round(cost)),
      fill: true,
      borderJoinStyle: 'round',
      pointRadius: 7,
      pointHoverRadius: 10,
      pointBorderWidth: 2,
      borderColor: sortedData.map(({ id }) => getColorPhase(id)),
      borderWidth: 2,
      backgroundColor: sortedData.map(({ id }) => getColorPhase(id)),
    }],
  }), [currency, sortedData, t]);

  return (
    <div className="ReceivableChart">
      <Bar
        data={chart}
        options={{
          ...defaultChartOptions,
          scales: {
            yAxes: {
              beginAtZero: true,
            },
          },
        }}
      />
    </div>
  );
};

export default observer(ReceivableChart);
