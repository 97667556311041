import type Misc from 'types/misc';

const getI18nChannel = (channel: Misc.Channel): string => {
  const i18nChannel = {
    EMAIL: 'common:channels-name.email',
    SMS: 'common:channels-name.sms',
    LETTER: 'common:channels-name.letter',
    REGISTERED_LETTER: 'common:channels-name.registered-letter',
  };

  return i18nChannel[channel];
};

export default getI18nChannel;
