import type Misc from 'types/misc';

const getI18nChannel = (type: Misc.ActionType): string => {
  const i18nType = {
    DISPATCH: 'plans:dispatch',
    REMINDER: 'plans:reminder',
    TASK: 'plans:task',
  };

  return i18nType[type];
};

export default getI18nChannel;
