import './index.scss';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import type Errors from 'types/errors';
import type Misc from 'types/misc';
import config from 'config';
import organizationStore from 'stores/Organization';
import { fetchAll as fetchAllUsers } from 'api/users';
import type { FetchAllParams as FetchAllUsersParams } from 'api/users';
import useFetch from 'hooks/useFetch';
import { getManagersNames } from 'pages/Settings/Selectors/Columns/utils';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import ErrorValidationMessage from 'components/ErrorValidationMessage';
import FormSelect from 'components/FormSelect';
import getI18nOperatorComparison from 'utils/getI18nOperatorComparison';
import getI18nAttributeType from 'utils/getI18nAttributeType';
import getI18nPaymentStatus from 'utils/getI18nPaymentStatus';
import DropdownActions from 'components/DropdownActions';
import FormControl from 'components/FormControl';
import Button from 'components/Button';
import Condition from './Condition';
import { defaultConditionType, getConditionTypeLabelFromValue } from './utils';

type Props = {
  errors?: Errors.Validation | null,
  defaultCondition?: Misc.SelectorCondition,
  index: number,
  onChange(value: Misc.SelectorCondition, index: number): void,
  onRemove(index: number): void,
  onToggleConditionsOpen(newOpen: boolean): void,
  isGroup: boolean,
  shouldLockConditions?: boolean,
};

const DEFAULT_OPERATOR_FOR_PAYMENT: Misc.ComparisonOperator = '===';
const DEFAULT_OPERATOR_FOR_DEBT: Misc.ComparisonOperator = '>';
const DEFAULT_OPERATOR_FOR_MANAGER: Misc.ComparisonOperator = 'IN';

const Conditions = (props: Props): JSX.Element => {
  const {
    errors,
    defaultCondition,
    index,
    isGroup,
    onToggleConditionsOpen,
    onChange,
    onRemove,
    shouldLockConditions = false,
  } = props;
  const { t } = useTranslation();
  const { currentOrganization, eavs } = organizationStore;

  const [attributeType1, setAttributeType1] = useState<Misc.AttributeType | null>(() => (
    defaultConditionType(defaultCondition?.select1)
  ));
  const [attribute1, setAttribute1] = useState<string | null>(
    defaultCondition?.select1 ?? null,
  );
  const [operator, setOperator] = useState<Misc.ComparisonOperator | null>(
    defaultCondition?.comparisonOperator ?? null,
  );
  const [attributeType2, setAttributeType2] = useState<Misc.AttributeType | null>(() => (
    defaultConditionType(defaultCondition?.select2, attributeType1, attribute1, eavs)
  ));
  const [attribute2, setAttribute2] = useState<string | null>(
    defaultCondition?.select2 ?? null,
  );

  const isCustomerOrPRAttribute = ['clientAttribute', 'payrequestAttribute'].includes(attributeType1 ?? '');
  const isPaymentStatusAttribute = attributeType1 === 'payment' && attribute1 === '{PAYMENT_status}';

  const allFormFilled = useMemo(() => {
    const operatorHasNull = operator === 'IS NULL' || operator === 'IS NOT NULL';
    const withAttribute2Null = (
      operatorHasNull
      || isPaymentStatusAttribute
      || attributeType1 === 'debt'
      || attributeType1 === 'managers'
    );

    return (
      attribute1 !== null
      && operator !== null
      && (attribute2 !== null || operatorHasNull)
      && attributeType1 !== null
      && (attributeType2 !== null || withAttribute2Null)
    );
  }, [
    attribute1,
    operator,
    attribute2,
    attributeType1,
    attributeType2,
    isPaymentStatusAttribute,
  ]);

  const [isEditing, setIsEditing] = useState<boolean>(!allFormFilled);
  const [isOpened, setIsOpened] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (isOpened !== (!allFormFilled || isEditing)) {
      onToggleConditionsOpen(!allFormFilled || isEditing);
      setIsOpened(!allFormFilled || isEditing);
    }
  }, [allFormFilled, isEditing, isOpened, onToggleConditionsOpen]);

  useEffect(() => {
    if (attributeType1 === 'managers') {
      setAttribute1('{MANAGERS_IDS}');
    }
  }, [attributeType1]);

  const comparisonOperatorsOptions = useMemo(() => {
    const operators = config.COMPARISON_OPERATORS.map((value) => ({
      value,
      label: t(getI18nOperatorComparison(value)),
    }));
    if (attributeType1 === 'payment') {
      return operators.filter(({ value }) => value === DEFAULT_OPERATOR_FOR_PAYMENT);
    }
    if (attributeType1 === 'debt') {
      return operators.filter(({ value }) => ['<=', '>'].includes(value));
    }
    if (attributeType1 === 'managers') {
      return operators.filter(({ value }) => ['IN', 'NOT IN'].includes(value));
    }
    return operators;
  }, [attributeType1, t]);


  const { data: managersList } = useFetch<FetchAllUsersParams, Misc.IdRefName[]>(
    { cacheKey: 'managers', organization: currentOrganization?.reference },
    fetchAllUsers,
    {
      enabled: !!currentOrganization?.reference,
      retry: 1,
      refetchOnWindowFocus: false,
    },
  );

  const managersOptions = useMemo(
    () => managersList?.map(({ id, name }) => ({ value: id, label: name })) ?? [],
    [managersList],
  );

  const conditionSentence = useMemo(() => {
    if (attributeType1 === 'payment') {
      return [
        t('selectors:attributes.payment'),
        t('selectors:whose-status-is'),
        t(getI18nPaymentStatus(attribute2 as Misc.PaymentStatus)),
      ].join(' ');
    }

    if (config.DEBT_SELECTOR_OPTIONS.includes(attribute1 ?? '')) {
      return [
        t('selectors:attributes.customer-debt'),
        t(getI18nOperatorComparison(operator!)),
        attribute2,
      ].join(' ');
    }

    if (config.MANAGERS_SELECTOR_OPTIONS.includes(attribute1 ?? '')) {
      return [
        t('selectors:attributes.managers'),
        t(getI18nOperatorComparison(operator!)),
        ':',
        getManagersNames(attribute2 ?? '', managersList),
      ].join(' ');
    }

    const typeText1 = (attributeType1 !== null && attributeType1 !== 'free')
      ? t(getI18nAttributeType(attributeType1))
      : '';
    const text1 = getConditionTypeLabelFromValue(attribute1, attributeType1, t, eavs);
    const operatorText = t(getI18nOperatorComparison(operator!));
    const typeText2 = (attributeType2 !== null && attributeType2 !== 'free')
      ? t(getI18nAttributeType(attributeType2))
      : '';
    const text2 = getConditionTypeLabelFromValue(attribute2, attributeType2, t, eavs);

    return `${typeText1} ${text1} ${operatorText} ${typeText2} ${text2}`;
  }, [
    attribute1,
    attribute2,
    attributeType1,
    attributeType2,
    eavs,
    operator,
    t,
    managersList,
  ]);

  const cannotValidate = useMemo(() => (
    operator === null
    || attribute1 === null
    || (attribute2 === null && operator !== 'IS NULL' && operator !== 'IS NOT NULL')
  ), [attribute1, attribute2, operator]);

  const canChooseAttribute2 = useMemo(() => (
    operator !== null
    && operator !== 'IS NULL'
    && operator !== 'IS NOT NULL'
    && !isPaymentStatusAttribute
    && attributeType1 !== 'debt'
    && attributeType1 !== 'managers'
  ), [operator, isPaymentStatusAttribute, attributeType1]);

  const handleChangeAttributeType1 = useCallback((newValue: Misc.AttributeType | null) => {
    setAttributeType1(newValue);
    if (newValue === 'payment') {
      setOperator(DEFAULT_OPERATOR_FOR_PAYMENT);
      setAttributeType2(null);
    }
    if (newValue === 'debt') {
      setOperator(DEFAULT_OPERATOR_FOR_DEBT);
      setAttributeType2(null);
    }
    if (newValue === 'managers') {
      setOperator(DEFAULT_OPERATOR_FOR_MANAGER);
    }
  }, []);

  const handleChangeOperator = useCallback((name: string, newValue: string | null) => {
    setOperator(newValue as Misc.ComparisonOperator | null);
  }, []);

  const handleChangeManager = useCallback((name: string, newValue: string | null) => {
    setAttribute2(newValue);
  }, []);

  const handleRemove = useCallback(() => {
    if (!isOpened) {
      onToggleConditionsOpen(true);
    }
    onRemove(index);
  }, [index, isOpened, onRemove, onToggleConditionsOpen]);

  const handleValidate = useCallback(() => {
    if (cannotValidate) {
      return;
    }
    setIsEditing(false);
    const newConditionValue: Misc.SelectorCondition = {
      comparisonOperator: operator!,
      select1: attribute1!,
      select2: attribute2 ?? '',
      logicOperator: 'AND',
    };
    onChange(newConditionValue, index);
  }, [cannotValidate, attribute1, attribute2, operator, onChange, index]);

  const className = classNames('ModalSelectorConditions__summary', {
    'ModalSelectorConditions__summary--disabled': shouldLockConditions,
  });

  return (
    <div>
      {!isOpened && (
        <div className={className}>
          {conditionSentence}
          {!shouldLockConditions && (
            <DropdownActions actions={[
              <Button variant="list" onClick={() => setIsEditing(true)}>
                {t('common:edit')}
              </Button>,
              <Button variant="list-danger" onClick={handleRemove}>
                {t('common:remove')}
              </Button>,
            ]} />
          )}
        </div>
      )}
      {isOpened && (
        <Fragment>
          <Condition
            isGroup={isGroup}
            attributeType={attributeType1}
            attribute={attribute1}
            onChangeAttribute={setAttribute1}
            onChangeAttributeType={handleChangeAttributeType1}
          />
          <FormFieldset>
            <FormGroup>
              <FormSelect
                name="operator"
                onSelect={handleChangeOperator}
                value={operator}
                className="ModalSelectorConditions__operator"
                placeholder={t('selectors:choose-operator')}
                selectOptions={comparisonOperatorsOptions}
              />
              <ErrorValidationMessage error={errors?.operator} />
            </FormGroup>
          </FormFieldset>
          {canChooseAttribute2 && (
            <Condition
              isGroup={isGroup}
              attributeType={attributeType2}
              attribute={attribute2}
              onChangeAttribute={setAttribute2}
              onChangeAttributeType={setAttributeType2}
              errors={errors}
              pairedWithAttribute={isCustomerOrPRAttribute ? attribute1 : undefined}
            />
          )}
          {isPaymentStatusAttribute && (
            <FormGroup>
              <FormSelect
                name="paymentAttribute"
                className="ModalSelectorConditions__payment-status-attribute"
                onSelect={() => {
                  setAttributeType2(null);
                  setAttribute2('FAILED');
                }}
                defaultValue="FAILED"
                selectOptions={[
                  { value: 'FAILED', label: t('payments:status.failed') },
                ]}
              />
            </FormGroup>
          )}
          {attributeType1 === 'debt' && (
            <FormGroup>
              <FormControl
                type="number"
                name="attribute"
                value={attribute2 || ''}
                onChange={setAttribute2}
              />
              <ErrorValidationMessage error={errors?.attribute} />
            </FormGroup>
          )}
          {attributeType1 === 'managers' && (
            <FormGroup>
              <FormSelect
                name="attribute"
                value={(attribute2 || '').split(',')}
                onSelect={handleChangeManager}
                selectOptions={managersOptions}
                placeholder={t('common:please-choose')}
                isMultiple
                withClearButton
              />
              <ErrorValidationMessage error={errors?.attribute} />
            </FormGroup>
          )}
          <div className="ModalSelectorConditions__commands">
            <div className="ModalSelectorConditions__commands__remove">
              <Button variant="link" onClick={handleRemove}>
                {t('selectors:remove-condition')}
              </Button>
            </div>
            <div className="ModalSelectorConditions__commands__validate">
              <Button variant="link" onClick={handleValidate} disabled={cannotValidate}>
                {t('common:validate')}
              </Button>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default observer(Conditions);
