import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import userRightsStore from 'stores/UserRights';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormGroupWithActions from 'components/FormGroupWithActions';
import { checkIsAllowed } from 'components/UserRights';
import type { ModelsTask, ModelPost } from 'types/models';
import FormControl from 'components/FormControl';
import organizationStore from 'stores/Organization';
import useIsMountedRef from 'hooks/useIsMountedRef';
import useApiRequest from 'hooks/useApiRequest';
import Confirm from 'components/Confirm';
import ErrorMessage from 'components/ErrorMessage';
import TagLink from 'components/TagLink';
import type Errors from 'types/errors';

interface Props {
  onClose: () => void,
  defaultData?: ModelsTask,
  edit?: boolean,
  onDone: (message: string) => void,
  onError: (message: string) => void,
}

const ModalTask: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const {
    onClose,
    onError,
    defaultData,
    edit = false,
    onDone,
  } = props;
  const { currentOrganization } = organizationStore;
  const { rights } = userRightsStore;

  const [validationErrors, setValidationErrors] = React.useState<Errors.Validation | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = React.useState<boolean>(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState<boolean>(false);

  const {
    post,
    put,
    remove,
    error,
  } = useApiRequest();

  const closeSelf = React.useCallback(() => {
    if (!isMountedRef.current) {
      return;
    }

    setShowCancelConfirm(false);
    setValidationErrors(null);
    onClose();
  }, [isMountedRef, onClose]);

  const mapFormData = React.useCallback(
    (rawData: ModalFormData): ModelPost => ({
      name: rawData.name as string,
      description: rawData.description as string,
      content: rawData.content as string,
      default: true,
      day: 0,
      type: 'task',
      identifier: defaultData?.identifier || rawData.name as string,
      contacts: [],
      needsValidation: false,
    }),
    [defaultData],
  );

  const handleSubmit = React.useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData || !currentOrganization) {
        return;
      }
      const data = mapFormData(formData);
      let result;
      if (edit) {
        result = await put(`organization/templates/${currentOrganization.reference}`, data);
      } else {
        result = await post(`organization/templates/${currentOrganization.reference}`, data);
      }

      if (!isMountedRef.current) {
        return;
      }

      if (result?.errors) {
        setValidationErrors(result.errors);
        return;
      }
      closeSelf();
      if (edit) {
        onDone(t('models:toast.success.edit-model', { name: formData?.name }));
        return;
      }
      onDone(t('models:toast.success.create-model', { name: formData?.name }));
    },
    [closeSelf, currentOrganization, edit, isMountedRef, mapFormData, onDone, post, put, t],
  );

  const handleDelete = React.useCallback(async () => {
    setShowDeleteConfirm(false);
    if (!currentOrganization) {
      return;
    }

    const response = await remove(
      `organization/templates/${currentOrganization.reference}/task?identifier=${defaultData?.identifier}`,
    );
    if (isMountedRef.current && response) {
      onDone(t('models:toast.success.delete-model', { name: defaultData?.name }));
      return;
    }
    onError(t('models:toast.error.delete-model', { name: defaultData?.name }));
  }, [currentOrganization, defaultData, isMountedRef, onDone, onError, remove, t]);

  // TODO: Faire un composant `Form`
  return (
    <ModalForm
      isOpened
      onSave={handleSubmit}
      onCancel={closeSelf}
      title={t('models:modal-task-title')}
      hasDeleteButton={edit}
      buttonsDisabled={rights === null || !checkIsAllowed('UPDATE', 'PREFERENCES_MODELS')}
      onDelete={() => { setShowDeleteConfirm(true); }}
    >
      {error && (<ErrorMessage error={error} />)}
      <FormFieldset>
        <FormGroup label={t('models:short-description')} mandatory>
          <FormControl
            type="text"
            name="name"
            autoComplete="off"
            placeholder={t('models:name-placeholder')}
            defaultValue={defaultData?.name ?? ''}
            isInvalid={!!validationErrors?.name}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroupWithActions
          label={t('models:task-content')}
          action={<TagLink />}
        >
          <FormControl
            type="textarea"
            name="description"
            autoComplete="off"
            defaultValue={defaultData?.description ?? ''}
            isInvalid={!!validationErrors?.description}
          />
        </FormGroupWithActions>
      </FormFieldset>
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={closeSelf}
        onCancel={() => { setShowCancelConfirm(false); }}
      />
      <Confirm
        titleModal={t('common:remove')}
        text={t('models:confirm-delete')}
        variant="danger"
        confirmButtonText={t('common:remove')}
        cancelButtonText={t('common:cancel')}
        entityName={defaultData?.name}
        isShow={showDeleteConfirm}
        onConfirm={handleDelete}
        onCancel={() => { setShowDeleteConfirm(false); }}
      />
    </ModalForm>
  );
};

export default observer(ModalTask);
