import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosResponse } from 'axios';
import type Errors from 'types/errors';
import defaultConfig from 'config/reactQuery';

export type BaseFetchOptions = { cacheKey: string };
export type KeyParams<FetchOptions = {}> = BaseFetchOptions & (FetchOptions | null | undefined);
export type RequestFunctionReturn<TResult> = Promise<AxiosResponse<TResult>> | null;
export type RequestFunction<TResult> = (...params: any) => RequestFunctionReturn<TResult>;

const useFetch = <FetchOptions, TResult extends {}>(
  params: KeyParams<FetchOptions>,
  requestFunction: RequestFunction<TResult>,
  config?: UseQueryOptions<AxiosResponse<TResult>> | undefined,
) => {
  const response = useQuery<AxiosResponse<TResult> | null, Errors.Request>(
    Object.values(params),
    () => requestFunction(params),
    config ? { ...defaultConfig, ...config } : defaultConfig,
  );

  const {
    status,
    isFetching,
    isLoading,
    data: payload,
    error,
    refetch,
  } = response;

  const data = payload ? payload.data : null;

  return {
    status,
    isFetching,
    isLoading,
    data,
    error,
    refetch,
  };
};

export default useFetch;
