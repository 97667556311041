import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import Button from 'components/Button';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import SendingActionsView from './View/index';
import SendingActionsEdit from './Edit/index';

interface Props {
  data?: string,
  type: 'pdf' | 'text' | 'img',
  genreTranslate?: 'm' | 'f',
  title: string,
  name: string,
  onShowToast: (message: string, status: ToastStatus) => void,
}

const SendingActions: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const {
    data,
    type,
    genreTranslate = 'f',
    title,
    name,
    onShowToast,
  } = props;

  const [showModalView, hideModalView] = useModal(() => (
    <SendingActionsView data={data || ''} type={type} title={title} onClose={hideModalView} />
  ), [data, type, title]);

  const [showModalEdit, hideModalEdit] = useModal(() => (
    <SendingActionsEdit
      data={data}
      type={type}
      title={title}
      name={name}
      onClose={hideModalEdit}
      onShowToast={onShowToast}
    />
  ), [data, type, title, name, onShowToast]);

  return (
    <div className="SendingActions">
      {data
        ? (
          <>
            <Button variant="link" className="SendingActions__button" onClick={showModalView}>
              {t('common:display')}
            </Button>
            <ButtonWithUserRights
              action="UPDATE"
              category="PREFERENCES_CUSTOMIZATION_SEND"
              variant="link"
              className="SendingActions__button"
              onClick={showModalEdit}
            >
              {t('common:edit')}
            </ButtonWithUserRights>
          </>
        )
        : (
          <>
            {genreTranslate === 'm' ? t('common:none') : t('common:none_f')}
            <ButtonWithUserRights
              category="PREFERENCES_CUSTOMIZATION_SEND"
              action="UPDATE"
              variant="link"
              className="SendingActions__button"
              onClick={showModalEdit}
            >
              {t('customization:add')}
            </ButtonWithUserRights>
          </>
        )}
    </div>
  );
};

export default SendingActions;
