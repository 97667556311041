import React from 'react';
import organizationStore from 'stores/Organization';
import authStore from 'stores/Auth';

type ControllerProps = {
  children: JSX.Element | JSX.Element[],
  isDisplayedWhenMonitored: boolean,
};

export const checkIsAllowed = (
  isDisplayedWhenMonitoredProps: boolean,
  isExternallyMonitored: boolean,
  isSuperAdmin: boolean,
) => (
  isSuperAdmin
    ? !isDisplayedWhenMonitoredProps
    : isDisplayedWhenMonitoredProps === isExternallyMonitored
);
type ExternallyMonitoredControllerType = (props: ControllerProps) => JSX.Element | null;

export const ExternallyMonitoredController: ExternallyMonitoredControllerType = (props) => {
  const { children, isDisplayedWhenMonitored } = props;
  const { isExternallyMonitored } = organizationStore;
  const { user, isLogged, isFetched } = authStore;
  const mayBeLogged = !!user || (isFetched && isLogged);

  const isSuperAdmin = !!((user && mayBeLogged && user.roles.includes('ROLE_SUPER_ADMIN')));

  if (!checkIsAllowed(isDisplayedWhenMonitored, isExternallyMonitored, isSuperAdmin)) {
    return null;
  }

  return <>{children}</>;
};

type SwitchProps = {
  children: Array<React.ReactElement<ControllerProps, ExternallyMonitoredControllerType>>,
};

export const ExternallyMonitoredSwitch = ({ children }: SwitchProps): JSX.Element | null => {
  const { isExternallyMonitored } = organizationStore;
  const { user, isLogged, isFetched } = authStore;
  const mayBeLogged = !!user || (isFetched && isLogged);

  const isSuperAdmin = !!((user && mayBeLogged && user.roles.includes('ROLE_SUPER_ADMIN')));

  let element: React.ReactNode = null;
  let matched = false;

  React.Children.forEach(children, (child) => {
    if (!matched && React.isValidElement(child) && child.type === ExternallyMonitoredController) {
      element = child;
      matched = checkIsAllowed(
        child.props.isDisplayedWhenMonitored,
        isExternallyMonitored,
        isSuperAdmin,
      );
    }
  });

  if (!matched) {
    return null;
  }

  return element;
};
