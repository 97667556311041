import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import organizationStore from 'stores/Organization';
import SettingsList from 'pages/Settings/List';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import getContactRole from 'utils/getContactRole';
import SettingsSection from '../../Section';
import ModalRoles from './ModalRoles';

interface Props {
  onShowToast: (message: string, status: ToastStatus) => void,
}

const Roles: React.FC<Props> = ({ onShowToast }) => {
  const { t } = useTranslation();
  const { contactRoles } = organizationStore;

  const listData = React.useMemo(() => (
    Array.from({ length: 10 }, (item, index) => ({
      label: `CONTACT_${index}`,
      value: getContactRole(`CONTACT_${index}`, contactRoles),
    }))
  ), [contactRoles]);

  const [showModalRoles, hideModalRoles] = useModal(() => (
    <ModalRoles
      listData={listData}
      onClose={hideModalRoles}
      onShowToast={onShowToast}
    />
  ), [onShowToast, listData]);

  return (
    <SettingsSection
      title={t('customization:roles')}
      action={(
        <ButtonWithUserRights
          action="UPDATE"
          category="PREFERENCES_CUSTOMIZATION_ROLES"
          onClick={showModalRoles}
          variant="link"
          withCaretIcon
        >
          {t('common:edit')}
        </ButtonWithUserRights>
      )}
    >
      <div className="Roles__list">
        <div className="Roles__list__items">
          <SettingsList data={listData.slice(0, 5)} />
        </div>
        <div className="Roles__list__items">
          <SettingsList data={listData.slice(5, 10)} />
        </div>
      </div>
    </SettingsSection>
  );
};

export default observer(Roles);
