import './index.scss';
import { useMemo } from 'react';
import type { PayRequestOrPayment } from 'types/models';
import formatIntlNumber from 'utils/formatIntlNumber';
import organizationStore from 'stores/Organization';

type Props = {
  data: PayRequestOrPayment,
};

const CustomerAccountDetailsBalanceCell = ({ data }: Props): JSX.Element | null => {
  const { balance, currency } = data;

  const displayedBalance = useMemo(() => (
    formatIntlNumber(balance, currency || organizationStore.currency)
  ), [balance, currency]);

  if (balance === null) {
    return null;
  }

  return (
    <div className="CustomerAccountDetailsBalanceCell">
      {displayedBalance}
    </div>
  );
};

export default CustomerAccountDetailsBalanceCell;
