import type Misc from 'types/misc';

const getI18nReportLineStatus = (type: Misc.ReportLineStatus): string => {
  const i18nType = {
    RESULT_ERROR: 'reports:line-status.result-error',
    RESULT_TO_PROCESS: 'reports:line-status.result-to-process',
    RESULT_CREATED: 'reports:line-status.result-created',
    RESULT_UPDATED: 'reports:line-status.result-updated',
    SKIPPED: 'reports:line-status.result-skipped',
  };

  return i18nType[type];
};

export default getI18nReportLineStatus;
