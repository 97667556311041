import './index.scss';
import { useMemo } from 'react';
import classnames from 'classnames';
import type Misc from 'types/misc';
import type { HistoryStep } from 'types/models';
import getHistoryStepType from 'utils/getHistoryStepType';
import ChannelIcon from 'components/ChannelIcon';
import Icon from 'components/Icon';

type Props = {
  data: HistoryStep | {
    entity: Misc.HistoryStepEntity,
    channel: Misc.Channel | null,
  },
};

const ENTITIES_WITH_ICON: Misc.HistoryStepEntity[] = [
  'Reminder',
  'FreeTask',
  'ProvideContactInformationsTask',
  'PayRequestCreationMilestone',
  'PaymentAssignedAlert',
  'PayRequestDeleteAlert',
  'DownloadInvoiceAlert',
];

const StepIcon = ({ data }: Props): JSX.Element | null => {
  const { entity, channel } = data;

  const type = useMemo(() => getHistoryStepType(data), [data]);

  const isBill = useMemo(() => [
    'PayRequestCreationMilestone',
    'PayRequestDeleteAlert',
    'DownloadInvoiceAlert',
  ].includes(entity), [entity]);

  const isCredit = useMemo(() => [
    'PaymentAssignedAlert',
  ].includes(entity), [entity]);

  const isDebit = useMemo(() => [
    'PayRequestCreationMilestone',
    'PayRequestDeleteAlert',
  ].includes(entity), [entity]);

  if (!ENTITIES_WITH_ICON.includes(entity)) {
    return null;
  }

  const classNames = classnames('StepIcon', {
    'StepIcon--reminder': type === 'reminder',
    'StepIcon--task': type === 'task',
    'StepIcon--bill': isBill,
    'StepIcon--debit': isDebit,
    'StepIcon--credit': isCredit,
    'StepIcon--registered-letter': channel === 'REGISTERED_LETTER',
  });

  return (
    <div className={classNames}>
      <div className="StepIcon__icon">
        {type === 'reminder' && <ChannelIcon channel={channel} />}
        {type === 'task' && <Icon name="list" />}
        {isBill && <Icon name="bill" />}
        {isCredit && <Icon name="credit-card" />}
      </div>
    </div>
  );
};

export default StepIcon;
