import { ReactComponent as Apple } from './apple.svg';
import { ReactComponent as ArrowDown } from './arrow-down.svg';
import { ReactComponent as ArrowUp } from './arrow-up.svg';
import { ReactComponent as ArrowUpCircle } from './arrow-up-circle.svg';
import { ReactComponent as BellMuted } from './bell-muted.svg';
import { ReactComponent as Bell } from './bell.svg';
import { ReactComponent as Bookmark } from './bookmark.svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as CaretDown } from './caret-down.svg';
import { ReactComponent as CaretUp } from './caret-up.svg';
import { ReactComponent as Cellphone } from './cellphone.svg';
import { ReactComponent as CheckCircle } from './check-circle.svg';
import { ReactComponent as Check } from './check.svg';
import { ReactComponent as ChevronDown } from './chevron-down.svg';
import { ReactComponent as ChevronFirst } from './chevron-first.svg';
import { ReactComponent as ChevronLast } from './chevron-last.svg';
import { ReactComponent as ChevronLeft } from './chevron-left.svg';
import { ReactComponent as ChevronRightSmall } from './chevron-right-small.svg';
import { ReactComponent as ChevronRight } from './chevron-right.svg';
import { ReactComponent as ChevronUp } from './chevron-up.svg';
import { ReactComponent as CloseSmall } from './close-small.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Comment } from './comment.svg';
import { ReactComponent as CreditCard } from './credit-card.svg';
import { ReactComponent as Dot } from './dot.svg';
import { ReactComponent as DotsSmall } from './dots-small.svg';
import { ReactComponent as Email } from './email.svg';
import { ReactComponent as Envelope } from './envelope.svg';
import { ReactComponent as Dots } from './dots.svg';
import { ReactComponent as Download } from './download.svg';
import { ReactComponent as Flash } from './flash.svg';
import { ReactComponent as List } from './list.svg';
import { ReactComponent as Bill } from './bill.svg';
import { ReactComponent as Loading } from './loading.svg';
import { ReactComponent as LoadingLine } from './loading-line.svg';
import { ReactComponent as Message } from './message.svg';
import { ReactComponent as MinusCircle } from './minus-circle.svg';
import { ReactComponent as Organization } from './organization.svg';
import { ReactComponent as Phone } from './phone.svg';
import { ReactComponent as Sms } from './sms.svg';
import { ReactComponent as Play } from './play.svg';
import { ReactComponent as PlusCircle } from './plus-circle.svg';
import { ReactComponent as PlusSmall } from './plus-small.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as Send } from './send.svg';
import { ReactComponent as Sort } from './sort.svg';
import { ReactComponent as SortAlphaUp } from './sort-alpha-up.svg';
import { ReactComponent as SortAlphaDown } from './sort-alpha-down.svg';
import { ReactComponent as SortAmountDown } from './sort-amount-up.svg';
import { ReactComponent as SortAmountUp } from './sort-amount-down.svg';
import { ReactComponent as SortNumericDown } from './sort-numeric-up.svg';
import { ReactComponent as SortNumericUp } from './sort-numeric-down.svg';
import { ReactComponent as Undo } from './undo.svg';
import { ReactComponent as User } from './user.svg';
import { ReactComponent as WarningCircle } from './warning-circle.svg';
import { ReactComponent as HelpCircle } from './help.svg';
import { ReactComponent as TextCenter } from './text-center.svg';
import { ReactComponent as EmojiHappy } from './emoji-happy.svg';
import { ReactComponent as EmojiLooking } from './emoji-looking.svg';
import { ReactComponent as EmojiProblem } from './emoji-problem.svg';
import { ReactComponent as EmojiBlueOk } from './emoji-blue-ok.svg';
import { ReactComponent as EmojiBlueLooking } from './emoji-blue-looking.svg';
import { ReactComponent as EmojiBlueStudious } from './emoji-blue-studious.svg';
import { ReactComponent as GripLines } from './grip-lines.svg';

export default {
  apple: Apple,
  'arrow-down': ArrowDown,
  'arrow-up': ArrowUp,
  'arrow-up-circle': ArrowUpCircle,
  'bell-muted': BellMuted,
  bell: Bell,
  bookmark: Bookmark,
  calendar: Calendar,
  'caret-down': CaretDown,
  'caret-up': CaretUp,
  cellphone: Cellphone,
  'check-circle': CheckCircle,
  check: Check,
  'chevron-down': ChevronDown,
  'chevron-first': ChevronFirst,
  'chevron-last': ChevronLast,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-right-small': ChevronRightSmall,
  'chevron-up': ChevronUp,
  close: Close,
  'close-small': CloseSmall,
  comment: Comment,
  'credit-card': CreditCard,
  dot: Dot,
  dots: Dots,
  'dots-small': DotsSmall,
  download: Download,
  email: Email,
  envelope: Envelope,
  flash: Flash,
  'help-circle': HelpCircle,
  list: List,
  bill: Bill,
  loading: Loading,
  'loading-line': LoadingLine,
  message: Message,
  'minus-circle': MinusCircle,
  organization: Organization,
  phone: Phone,
  sms: Sms,
  play: Play,
  'plus-circle': PlusCircle,
  'plus-small': PlusSmall,
  search: Search,
  send: Send,
  sort: Sort,
  'sort-alpha-up': SortAlphaDown,
  'sort-alpha-down': SortAlphaUp,
  'sort-amount-up': SortAmountDown,
  'sort-amount-down': SortAmountUp,
  'sort-numeric-up': SortNumericDown,
  'sort-numeric-down': SortNumericUp,
  'text-center': TextCenter,
  undo: Undo,
  user: User,
  'warning-circle': WarningCircle,
  'emoji-happy': EmojiHappy,
  'emoji-looking': EmojiLooking,
  'emoji-problem': EmojiProblem,
  'emoji-blue-ok': EmojiBlueOk,
  'emoji-blue-looking': EmojiBlueLooking,
  'emoji-blue-studious': EmojiBlueStudious,
  'grip-lines': GripLines,
};
