import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { StripeIbanElementChangeEvent, CreateSourceData } from '@stripe/stripe-js';
import { IbanElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Config from 'config';
import checkEmailFormat from 'utils/checkEmailFormat';
import organizationStore from 'stores/Organization';
import useApiRequest from 'hooks/useApiRequest';
import Button from 'components/Button';
import FormGroup from 'components/FormGroup';
import ErrorValidationMessage from 'components/ErrorValidationMessage';
import FormControl from 'components/FormControl';
import Loading from 'components/Loading';
import IbanInput from './IbanInput';

interface Props {
  onComplete: () => void,
}

const StripeModalForm: React.FC<Props> = ({ onComplete }) => {
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;

  const [ibanError, setIbanError] = React.useState<string | null>(null);
  const [isIbanComplete, setIsIbanComplete] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);
  const [emailError, setEmailError] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [bankName, setBankName] = React.useState<string | null>(null);

  const stripe = useStripe();
  const elements = useElements();

  const handleChangeIBAN = React.useCallback((ibanEvent: StripeIbanElementChangeEvent) => {
    setIbanError(ibanEvent.error ? ibanEvent.error.message : null);
    setIsIbanComplete(ibanEvent.complete);
    setBankName(ibanEvent.bankName || null);
  }, []);

  const isSubmitEnabled = React.useMemo(() => (
    !isLoading
    && name.length > 2
    && checkEmailFormat(email)
    && isIbanComplete
  ), [isLoading, name, email, isIbanComplete]);

  const handleEmailBlur = React.useCallback(() => {
    setEmailError(checkEmailFormat(email) ? null : t('common:email-not-valid'));
  }, [email, t]);

  const { put, error: saveError } = useApiRequest();

  React.useEffect(() => {
    if (saveError) {
      setError(saveError.message || t('errors:unknown-retry'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveError]);

  const handleSubmit = React.useCallback(
    async (event: React.SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!currentOrganization || !isSubmitEnabled || !stripe || !Config.STRIPE_TOKEN) {
        return;
      }

      setError(null);
      setIsLoading(true);

      const ibanElement = elements?.getElement(IbanElement);
      if (!ibanElement) {
        return;
      }

      const { reference, currency } = currentOrganization;

      const sourceData: CreateSourceData = {
        type: 'sepa_debit',
        currency,
        owner: { name, email },
        mandate: {
          currency,
          notification_method: 'email',
          acceptance: {
            date: (new Date()).getTime(),
            status: 'accepted',
          },
        },
        usage: 'reusable',
      };

      const resultCreate = await stripe.createSource(ibanElement, sourceData);
      if (!resultCreate || resultCreate.error || !resultCreate.source) {
        setIsLoading(false);
        setError(resultCreate?.error?.message || t('errors:unknown-retry'));
        return;
      }

      await put(`organization/manage/${reference}/stripe`, {
        sourceId: resultCreate.source.id,
        sourceOwnerEmail: email,
        clientSecret: resultCreate.source.client_secret,
      });

      setIsLoading(false);
      onComplete();
    },
    [currentOrganization, isSubmitEnabled, stripe, elements, name, email, put, onComplete, t],
  );

  return (
    <form className="StripeModalForm" onSubmit={handleSubmit}>
      <FormGroup label={t('organizations:sepa-mandate.debtor-name')} mandatory>
        <FormControl
          name="name"
          autoComplete="off"
          value={name}
          onChange={setName}
        />
      </FormGroup>
      <FormGroup label={t('organizations:sepa-mandate.debtor-email')} mandatory error={emailError}>
        <FormControl
          name="email"
          type="email"
          value={email}
          onChange={setEmail}
          onBlur={handleEmailBlur}
          isInvalid={!!emailError}
        />
      </FormGroup>
      <FormGroup label="IBAN" mandatory error={ibanError}>
        <IbanInput onChange={handleChangeIBAN} />
        {bankName && <div className="StripeModalForm__bank-name">{bankName}</div>}
      </FormGroup>
      <div className="StripeModalForm__footer">
        <Button type="submit" disabled={!isSubmitEnabled}>
          {isLoading && <Loading hasNoText />}
          {t('organizations:sepa-mandate.accept-mandate')}
        </Button>
        {error && <ErrorValidationMessage error={error} />}
        <p className="StripeModalForm__notice">
          {t('organizations:sepa-mandate.acceptance-legal-text')}
        </p>
      </div>
    </form>
  );
};

export default observer(StripeModalForm);
