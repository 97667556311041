import './index.scss';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
import Icon from 'components/Icon';
import Button from 'components/Button';
import getI18nChannel from 'utils/getI18nChannel';

type Props = {
  model: Misc.PlanTemplateReminder,
  onSelect(model: Misc.PlanTemplateReminder): void,
};

const Model = ({ model, onSelect }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Button className="ModalModelReminderTask" variant="list" onClick={() => { onSelect(model); }}>
      <div className="ModalModelReminderTask__main">
        <div className="ModalModelReminderTask__main__icon">
          <Icon name="warning-circle" />
        </div>
        <div className="ModalModelReminderTask__main__description">
          <h4 className="ModalModelReminderTask__main__description__title">{model.name}</h4>
          <p className="ModalModelReminderTask__main__description__channel">
            {model.channel ? t(getI18nChannel(model?.channel)) : ''}
          </p>
        </div>
      </div>
    </Button>
  );
};

export default Model;
