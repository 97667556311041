import './index.scss';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useParams, useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import Config from 'config';
import type Misc from 'types/misc';
import type { Client } from 'types/models';
import organizationStore from 'stores/Organization';
import userRightsStore from 'stores/UserRights';
import authStore from 'stores/Auth';
import { fetchAll } from 'api/customers';
import type { FetchAllParams } from 'api/customers';
import useFetchPaginated from 'hooks/useFetchPaginated';
import customerFiltersStore from 'stores/FilterStores/CustomersFilters';
import useContextualTranslation from 'hooks/useContextualTranslation';
import PageList from 'components/PageList';
import { checkIsAllowed } from 'components/ExternallyMonitored';
import DataTable from 'components/DataTable';
import ToastNotification from 'components/ToastNotification';
import useToast from 'components/ToastNotification/useToast';
import LinkSwitch from 'components/LinkSwitch';
import getFiltersQueryString from 'utils/getFiltersQueryString';
import CustomerEditPage, { RouteParams as RouteParamsEdit } from '../CustomerEdit';
import StatusSelect from './StatusSelect';
import CustomersFilters from './Filters';
import CustomersColumns from './Columns';
import BulkActions from './Actions/Bulk';
import ExportLink from './ExportLink';

export type RouteParams = RouteParamsEdit & {
  customerId?: number,
};

const Customers = (): JSX.Element => {
  const history = useHistory();
  const { editType } = useParams<RouteParams>();
  const {
    isExternallyMonitored,
    currentOrganization,
    walletSelection,
    currency,
    linesOfBusiness,
  } = organizationStore;
  const { ct } = useContextualTranslation(linesOfBusiness);
  const { rights } = userRightsStore;
  const { user, isLogged, isFetched } = authStore;
  const mayBeLogged = !!user || (isFetched && isLogged);

  const isSuperAdmin = !!((user && mayBeLogged && user.roles.includes('ROLE_SUPER_ADMIN')));

  const {
    filters,
    addOrUpdateFilters,
    resetAllFilters,
    removeFilter,
  } = customerFiltersStore;

  useEffect(() => {
    resetAllFilters();
  }, [resetAllFilters]);

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const defaultFetchOptions = {
    pageIndex: 0,
    pageSize: Config.DEFAULT_PAGE_SIZE,
    sort: { id: 'name', desc: false },
  };

  const [fetchOptions, setFetchOptions] = useState<Misc.PaginatedFetchArgs<Client>>(
    defaultFetchOptions,
  );

  const filtering = useMemo(() => {
    if (walletSelection.length === 0) {
      return filters;
    }
    return [...filters, { name: 'categories', value: walletSelection }];
  }, [filters, walletSelection]);

  const {
    data,
    serverPagination,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useFetchPaginated<FetchAllParams, Client>(
    {
      cacheKey: 'customers',
      organization: currentOrganization?.reference,
      filtering,
      fetchOptions,
    },
    fetchAll,
    { enabled: !!currentOrganization },
  );

  const [actionsCount, setActionsCount] = useState<number>(0);

  const handleActionDone = useCallback(
    (message: string) => {
      showToast(message, 'success');
      setActionsCount(actionsCount + 1);
      refetch();
    },
    [showToast, refetch, setActionsCount, actionsCount],
  );

  const handleActionError = useCallback(
    (message: string) => { showToast(message, 'error'); },
    [showToast],
  );

  const columns = useMemo<Column<Client>[]>(
    () => CustomersColumns(ct, currency, handleActionDone, handleActionError),
    [ct, currency, handleActionDone, handleActionError],
  );

  const filtersList = useMemo<Misc.FilterDeclarationItem[]>(
    () => CustomersFilters(ct, filters),
    [ct, filters],
  );

  const exportLink = useMemo<string>(
    () => ExportLink(fetchOptions, filters, currentOrganization?.reference || null),
    [currentOrganization, fetchOptions, filters],
  );

  const handleSwitchList = useCallback((listing: string) => {
    if (!listing.startsWith('customers')) {
      history.push(`/${listing}?${getFiltersQueryString(filters)}`);
    }
  }, [filters, history]);

  const handleStatusSelector = useCallback((newStatus: 'ACTIVE' | 'ALL') => {
    addOrUpdateFilters([{ name: 'active', value: newStatus === 'ACTIVE' ? '1' : '0' }]);
  }, [addOrUpdateFilters]);

  const switchListing = (
    editType ? null : (
      <LinkSwitch
        valueLabels={[
          { value: 'customers', label: ct('common:customers') },
          { value: 'contacts', label: ct('common:contacts') },
        ]}
        defaultValue="customers"
        onChange={handleSwitchList}
      />
    )
  );

  const totalResults = useMemo(() => (
    filters.length > 0 ? serverPagination?.totalRecords : null
  ), [filters, serverPagination]);

  return (
    <>
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
      <PageList
        className="Customers"
        count={totalResults}
        isFetching={isFetching}
        title={ct('common:customers')}
        actions={switchListing}
      >
        <DataTable<Client>
          columns={columns}
          defaultSortBy={fetchOptions.sort || defaultFetchOptions.sort}
          filtersList={filtersList}
          filtering={filters}
          statusSelector={(
            <StatusSelect
              status={filters?.find(({ name }) => name === 'active') ? 'ACTIVE' : 'ALL'}
              onSetFilters={handleStatusSelector}
              refreshTick={actionsCount}
            />
          )}
          data={data}
          serverPagination={serverPagination}
          fetchData={setFetchOptions}
          isLoading={isLoading}
          onRemoveFilter={removeFilter}
          onChangeFilters={addOrUpdateFilters}
          error={error}
          noDataFoundMessage={ct('clients:no-data-was-found')}
          withNoDataDrawing
          bulkGender="m"
          withActions={
            rights !== null && checkIsAllowed(false, isExternallyMonitored, isSuperAdmin)
          }
          bulkActions={(
            <BulkActions
              onActionDone={handleActionDone}
              onActionError={handleActionError}
            />
          )}
          exportLink={exportLink}
        />
        {editType && (
          <CustomerEditPage
            onDone={handleActionDone}
            finallyRedirectTo="/customers"
          />
        )}
      </PageList>
    </>
  );
};

export default observer(Customers);
