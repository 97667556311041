import './index.scss';
import classnames from 'classnames';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
import Button from 'components/Button';
import Icon from 'components/Icon';
import FilterValue from './FilterValue';

type Props = {
  filtersSettings?: Misc.FiltersSettings,
  filtersList: Misc.FilterDeclarationItem[],
  currentFilters: Misc.Filter[],
  onRemove: (filterName: string, value: Misc.FilterValue) => void,
};

const DataTableActiveFilters = (props: Props): JSX.Element => {
  const { filtersList, currentFilters, onRemove, filtersSettings } = props;
  const { t } = useTranslation();

  const getFilterLabel = useCallback((name: string) => {
    const foundFilter = filtersList.find(({ key }) => key === name);
    return foundFilter?.title || name;
  }, [filtersList]);

  const isFilterRemovable = useCallback((name: string) => {
    const foundFilter = filtersList.find(({ key }) => key === name);
    return !foundFilter?.isNotRemovable;
  }, [filtersList]);

  const handleRemoveFilter = useCallback((currentFilter: Misc.CurrentFilterItem) => () => {
    const { name, getRemovedValue } = currentFilter;
    if (!isFilterRemovable(name)) {
      return;
    }
    onRemove(name, getRemovedValue());
  }, [isFilterRemovable, onRemove]);

  const currentFilterItems: Misc.CurrentFilterItem[] = useMemo(() => {
    const activeFilters = currentFilters.filter(({ value }) => value && value.length > 0);
    if (filtersSettings?.getCurrentFilters) {
      return filtersSettings.getCurrentFilters(getFilterLabel, filtersList, activeFilters, t);
    }

    return activeFilters.map(({ name, value }) : Misc.CurrentFilterItem => ({
      label: getFilterLabel(name),
      value,
      name,
      key: name,
      getRemovedValue: () => null,
    }));
  }, [currentFilters, filtersList, filtersSettings, getFilterLabel, t]);

  return (
    <div className="DataTableActiveFilters">
      {currentFilterItems.map((currentFilter) => {
        const { key, name, label, value } = currentFilter;
        return (
          <Button
            key={key}
            variant="light"
            className={classnames('DataTableActiveFilters__item', {
              'DataTableActiveFilters__item--not-removable': !isFilterRemovable(name),
            })}
            onClick={handleRemoveFilter(currentFilter)}
          >
            {!!label && `${label} : `}
            <FilterValue
              name={name}
              value={value}
              filtersList={filtersList}
            />
            {isFilterRemovable(name) && (
              <Icon name="close-small" />
            )}
          </Button>
        );
      })}
    </div>
  );
};

export default DataTableActiveFilters;
