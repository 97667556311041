import type Misc from 'types/misc';
import Request from 'utils/request';

export type PayRequestResult = {
  id: number,
  reference: string,
};

export type CustomerResult = {
  id: number,
  reference: string,
  clientFirstName: string,
  clientLastName: string,
  clientCorporateName: string,
};

export interface SearchResultsData {
  clientsCount: number,
  clients: CustomerResult[],
  payRequestsCount: number,
  payRequests: PayRequestResult[],
}

export type FetchAllParams = {
  organization: string | undefined,
  filtering: Misc.Filter[],
  searchTerm: string,
};

const fetchAll = async ({ organization, filtering, searchTerm }: FetchAllParams) => {
  if (!organization) {
    throw new Error('GlobalSearch: Missing organization.');
  }

  if (!searchTerm) {
    throw new Error('GlobalSearch: No search term provided');
  }

  const safeSearchTerm = searchTerm.trim();
  if (safeSearchTerm === '') {
    throw new Error('GlobalSearch: No search term provided (trimmed)');
  }

  const queryData = new URLSearchParams();
  queryData.append('term', encodeURIComponent(safeSearchTerm));

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }) => {
      queryData.append(
        `filters[${name}]`,
        (Array.isArray(value) ? value.join(',') : value) || '',
      );
    });
  }

  const result = await Request.get<SearchResultsData>(
    `search/${organization}?${queryData.toString()}`,
  );
  return result;
};

export default fetchAll;
