import { useTranslation } from 'react-i18next';
import type Errors from 'types/errors';
import FormGroup from 'components/FormGroup';
import Wysiwyg from 'components/Wysiwyg';

type Props = {
  name: 'signatureLetter' | 'signatureEmail',
  defaultData: string | null,
  errors?: Errors.Validation | null,
  onChange?(content: string): void,
};

const SignatureEditForm = (props: Props): JSX.Element => {
  const { name, defaultData, errors, onChange } = props;
  const { t } = useTranslation();

  return (
    <div className="SignatureEditForm">
      <FormGroup label={t('users:content')} error={errors?.signatureLetter || errors?.signatureEmail}>
        <Wysiwyg
          name={name}
          defaultValue={defaultData || ''}
          toBePrinted={name === 'signatureLetter'}
          onChange={onChange}
        />
      </FormGroup>
    </div>
  );
};

export default SignatureEditForm;
