import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import config from 'config';
import type Errors from 'types/errors';
import type Misc from 'types/misc';
import type { EavConstructor } from 'types/models';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormSelect from 'components/FormSelect';
import getEavTypeLabel from 'utils/getEavTypeLabel';
import type { ChosableFormat } from 'utils/isEavValueValid';
import isEavValueValid from 'utils/isEavValueValid';

type Props = {
  errors?: Errors.Validation | null,
  defaultData?: EavConstructor,
  onSelectFormat(name: string, newValue: string | null): void,
  format: ChosableFormat,
  isMandatory: boolean,
};

const FormatForm = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  const {
    errors,
    defaultData,
    onSelectFormat,
    format,
    isMandatory,
  } = props;

  const [defaultValue, setDefaultValue] = useState<string>(defaultData?.defaultValue);

  const [defaultValueValid, setDefaultValueValid] = useState<boolean>(
    isEavValueValid(format, defaultValue, isMandatory),
  );

  useEffect(() => {
    setDefaultValueValid(isEavValueValid(format, defaultValue, isMandatory));
  }, [defaultValue, format, isMandatory]);

  return (
    <div className="FormatForm">
      <FormFieldset>
        <FormGroup label={t('common:format')} error={errors?.format}>
          <FormSelect
            name="format"
            selectOptions={[...Object.keys(config.EAV_FORMATS).map((eavFormat) => (
              { value: eavFormat, label: t(getEavTypeLabel(eavFormat as Misc.EavFormats)) }
            )),
            { value: 'none', label: t('attributes:no-format') }]}
            value={format}
            onSelect={onSelectFormat}
            defaultValue={defaultData?.format}
          />
        </FormGroup>
        <FormGroup label={t('attributes:default-value')} error={errors?.defaultValue}>
          <FormControl
            isInvalid={!defaultValueValid}
            value={defaultValue || ''}
            onChange={setDefaultValue}
            name="defaultValue"
          />
        </FormGroup>
      </FormFieldset>
    </div>
  );
};

export default FormatForm;
