import './index.scss';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import type Errors from 'types/errors';
import type { TaskPost } from 'types/models';
import dateFormatIso from 'utils/dateFormatIso';
import getDaysDiff from 'utils/getDaysDiff';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormDatePicker from 'components/FormDatePicker';
import Icon from 'components/Icon';
import AskMoveScenarioModal from '../../AskMoveScenario';

type Props = {
  defaultData: TaskPost | null,
  errors?: Errors.Validation | null,
};

const TaskForm = ({ defaultData, errors }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<string>(defaultData?.startAt ?? '');
  const [dateMoveScenario, setDateMoveScenario] = useState<'none' | 'move'>('none');
  const [daysShift, setDaysShift] = useState<number>(0);

  const [showAskMoveScenario, hideAskMoveScenario] = useModal(() => (
    <AskMoveScenarioModal
      daysCount={daysShift}
      onChange={(scenario: 'none' | 'move') => { setDateMoveScenario(scenario); }}
      onResetDate={() => { setStartDate(defaultData?.startAt ?? ''); }}
      onClose={hideAskMoveScenario}
    />
  ), [daysShift, defaultData]);

  const handleChangeStartDate = useCallback((noop: string, newDate: string) => {
    setStartDate(newDate);

    if (defaultData && dateFormatIso(new Date(defaultData.startAt)) !== newDate) {
      showAskMoveScenario();
      setDaysShift(Number.parseInt(getDaysDiff(newDate, defaultData?.startAt), 10));
    }
  }, [defaultData, showAskMoveScenario]);

  return (
    <div className="TaskForm">
      <FormFieldset>
        <FormGroup label={t('common:planned-at')} mandatory error={errors?.startAt}>
          <FormDatePicker
            name="startAt"
            value={startDate}
            onChange={handleChangeStartDate}
            defaultValue={defaultData?.startAt}
            isInvalid={!!errors?.startAt}
          />
          <input type="hidden" name="withMove" value={dateMoveScenario} />
        </FormGroup>
        <FormGroup label={t('reminders:task-name')} mandatory error={errors?.name}>
          <FormControl
            type="text"
            name="name"
            autoComplete="off"
            placeholder={t('reminders:placeholder.task-name')}
            defaultValue={defaultData?.name ?? ''}
            isInvalid={!!errors?.name}
          />
        </FormGroup>
      </FormFieldset>
      {dateMoveScenario === 'move' && daysShift > 0 && (
        <p className="TaskForm__daysShift">
          <Icon name="warning-circle" />
          {t('reminders:shifting-other-steps', { count: daysShift })}
        </p>
      )}
      <FormFieldset>
        <FormGroup label={t('reminders:task-description')} mandatory error={errors?.description}>
          <FormControl
            type="textarea"
            name="description"
            autoComplete="off"
            defaultValue={defaultData?.description ?? ''}
            isInvalid={!!errors?.description}
            placeholder={t('reminders:placeholder.task-description')}
          />
        </FormGroup>
      </FormFieldset>
    </div>
  );
};

export default TaskForm;
