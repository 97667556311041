import './index.scss';
import type { TFunction } from 'i18next';
import type { Column } from 'react-table';
import type { OrganizationEavs, Selectors } from 'types/models';
import type Misc from 'types/misc';
import Config from 'config';
import getI18nOperatorComparison from 'utils/getI18nOperatorComparison';
import getI18nPaymentStatus from 'utils/getI18nPaymentStatus';
import SelectorsRowActions from './RowActions';
import { getLabelFromIdentifier, getManagersNames } from './utils';

const SelectorColumns = (
  t: TFunction,
  onActionError: (message: string) => void,
  onActionDone: (message: string) => void,
  isGroup: boolean,
  plans: Misc.IdentifierName[] | null,
  eavs: OrganizationEavs,
  allManagers: Misc.IdRefName[] | null,
): Column<Selectors>[] => [
  {
    Header: t<string>('selectors:name'),
    accessor: 'name',
    disableSortBy: true,
    Cell: ({ value }) => <h4 className="SelectorColumn__title">{value.toLowerCase()}</h4>,
  },
  {
    Header: t<string>('selectors:position'),
    accessor: 'position',
    disableSortBy: true,
    Cell: ({ value }) => <p className="SelectorColumn__title">{value}</p>,
  },
  {
    Header: t<string>('selectors:conditions'),
    accessor: 'conditions',
    disableSortBy: true,
    Cell: ({ value }) => (
      <div>
        {!value && t<string>('common:unknown')}
        {value && value.map((elem, index) => {
          let conditionSentence = [
            getLabelFromIdentifier(elem.select1?.toString() || '', eavs, t),
            t(getI18nOperatorComparison(elem.comparisonOperator)),
            getLabelFromIdentifier(elem.select2?.toString() || '', eavs, t),
          ];
          if (elem.select1 === '{PAYMENT_status}') {
            conditionSentence = [
              t('selectors:attributes.payment'),
              t('selectors:whose-status-is'),
              t(getI18nPaymentStatus(elem.select2 as Misc.PaymentStatus)),
            ];
          }
          if (Config.DEBT_SELECTOR_OPTIONS.includes(elem.select1)) {
            conditionSentence = [
              t('selectors:attributes.customer-debt'),
              t(getI18nOperatorComparison(elem.comparisonOperator)),
              elem.select2,
            ];
          }
          if (Config.MANAGERS_SELECTOR_OPTIONS.includes(elem.select1)) {
            conditionSentence = [
              t('selectors:attributes.managers'),
              t(getI18nOperatorComparison(elem.comparisonOperator)),
              ':',
              getManagersNames(elem.select2, allManagers),
            ];
          }
          return (
            <div key={`${elem.select1}-${elem.comparisonOperator}-${elem.select2}`}>
              <p className="SelectorColumn__values-title">
                {conditionSentence.join(' ')}
              </p>
              {elem.logicOperator && index !== value.length - 1 && (
                <p className="SelectorColumn__values-operator">
                  {elem.logicOperator === 'AND'
                    ? t<string>('selectors:operators.and')
                    : t<string>('selectors:operators.or')}
                </p>
              )}
            </div>
          );
        })}
      </div>
    ),
  },
  {
    Header: t<string>('selectors:associated-scenario'),
    accessor: 'template',
    disableSortBy: true,
    Cell: ({ value }) => (
      <div>
        {plans?.find(({ identifier }) => identifier === value)?.name || value}
      </div>
    ),
  },
  {
    Header: t<string>('common:commands'),
    accessor: 'id',
    disableSortBy: true,
    Cell: ({ row }) => (
      <SelectorsRowActions
        isGroup={isGroup}
        defaultData={row.original}
        id={row.original.name}
        onActionDone={onActionDone}
        onActionError={onActionError}
      />
    ),
  },
];

export default SelectorColumns;
