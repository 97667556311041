import './index.scss';
import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import type { OrganizationStats } from 'types/models';
import type { FetchOrganizationCountHistoryParams } from 'api/organization';
import { countHistory } from 'api/organization';
import useFetch from 'hooks/useFetch';
import useContextualTranslation from 'hooks/useContextualTranslation';
import organizationStore from 'stores/Organization';
import BlockSection from 'components/BlockSection';
import Stats from './Stats';
import MainDebtors from './MainDebtors';
import MyActions from './MyActions';
import HomeHistory from './History';

type Props = {
  stats: OrganizationStats,
};

const HomeContent = ({ stats }: Props): JSX.Element => {
  const [countActions, setCountActions] = useState<number>();

  const { linesOfBusiness, currentOrganization } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);

  const { data: countEvents } = useFetch<FetchOrganizationCountHistoryParams, number>(
    { cacheKey: 'organizationCountHistory', organization: currentOrganization?.reference },
    countHistory,
    { refetchOnWindowFocus: false },
  );

  const handleCountActions = useCallback((count: number) => (
    setCountActions(count)
  ), []);

  return (
    <div className="HomeContent">
      <div className="HomeContent__main">
        <BlockSection
          title={t('home:my-synthesis')}
          linkHref="/payment-requests"
          linkText={ct('home:all-bills')}
        >
          {/* FIXME: En attendant de corriger le retour d'API (qui peut renvoyer un array vide),
              obligé de mettre ce guard pour éviter que l'application plante totalement. */}
          {stats.constructor === Object && (
            <Stats data={stats} />
          )}
        </BlockSection>
        <BlockSection
          title={ct('home:main-debtors')}
          linkHref="/customers"
          linkText={ct('home:all-customers')}
        >
          <MainDebtors />
        </BlockSection>
      </div>
      <div className="HomeContent__side">
        <BlockSection
          title={t('home:my-actions')}
          linkHref="/actions-future"
          linkText={t('home:all-actions')}
          count={countActions}
        >
          <MyActions onCountActions={handleCountActions} />
        </BlockSection>
        <BlockSection
          title={t('home:actions-done')}
          linkHref="/actions-done?type=sent_reminder,sent_error_reminder,activities-clients,task_processed,reminder_processed,contact_processed"
          linkText={t('home:all-actions')}
          count={countEvents}
        >
          <HomeHistory />
        </BlockSection>
      </div>
    </div>
  );
};

export default observer(HomeContent);
