import React from 'react';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
import type Errors from 'types/errors';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import PasswordEditForm from './PasswordEditForm';

export type UserPassword = {
  passwordActive: string,
  passwordNew: string,
  passwordNewRepeat: string,
};

interface Props {
  onClose: () => void,
  onShowToast: (message: string, type: ToastStatus) => void,
}

const ModalEditPassword: React.FC<Props> = ({ onClose, onShowToast }) => {
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();

  const {
    put,
    isLoading,
    cancel,
    error,
  } = useApiRequest();

  const [validationErrors, setValidationErrors] = React.useState<Errors.Validation | null>(null);

  const mapFormData = React.useCallback(
    (rawData: ModalFormData): UserPassword => ({
      passwordActive: rawData?.passwordActive as string,
      passwordNew: rawData?.passwordNew as string,
      passwordNewRepeat: rawData?.passwordNewRepeat as string,
    }),
    [],
  );

  const handleSubmit = React.useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData) {
        return;
      }

      const result = await put<Misc.IdName>('current-user', mapFormData(formData));

      if (!isMountedRef.current) {
        return;
      }

      if (result?.errors) {
        setValidationErrors(result.errors);
        return;
      }

      if (result?.id) {
        onShowToast(t('users:toast.success.password-update'), 'success');
        onClose();
      }
    },
    [mapFormData, put, isMountedRef, onShowToast, onClose, t],
  );

  const handleCancel = React.useCallback(() => {
    onClose();
  }, [onClose]);

  React.useEffect(() => (
    () => { cancel(); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  return (
    <ModalForm
      title={t('users:edit-password')}
      className="ModalEditPassword"
      isOpened
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
    >
      {error && <ErrorMessage error={error} />}
      <PasswordEditForm errors={validationErrors} />
    </ModalForm>
  );
};

export default ModalEditPassword;
