import './index.scss';
import { observer } from 'mobx-react';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import type { DetailTotalByPhase } from 'types/models';
import ListStatsByPhaseItemPercent from './ItemPercent';
import ListStatsByPhaseItemBar from './ItemBar';

type Props = {
  title: string,
  data: DetailTotalByPhase
};

const ListStatsByPhase = ({ title, data }: Props): JSX.Element | null => {
  const { linesOfBusiness } = organizationStore;
  const { ct } = useContextualTranslation(linesOfBusiness);
  const { phases, count } = data;

  if (count === 0) {
    return null;
  }

  return (
    <div className="ListStatsByPhase">
      <div className="ListStatsByPhase__content">
        <div className="ListStatsByPhase__content__phases-percent-bar">
          <h5 className="ListStatsByPhase__content__title">
            {title}
          </h5>
          {phases.map((phase) => (
            <ListStatsByPhaseItemBar phase={phase} key={phase.id} />
          ))}
          <p className="ListStatsByPhase__content__count">
            {ct('home:bill-in-total', { count })}
          </p>
        </div>
        <ul className="ListStatsByPhase__content__phases-percent">
          {phases.map((phase) => (
            <ListStatsByPhaseItemPercent phase={phase} key={phase.id} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default observer(ListStatsByPhase);
