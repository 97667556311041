import './index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import type Misc from 'types/misc';
import type Errors from 'types/errors';
import type { Client, OrganizationEavs } from 'types/models';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormSelect from 'components/FormSelect';
import FormRadios from 'components/FormRadios';
import type { FormRadioValue } from 'components/FormRadios/Item';
import FormEavs from 'components/FormEavs';
import Loading from 'components/Loading';
import FormCheck from 'components/FormCheck';
import TooltipProvider from 'components/Tooltip';
import useTooltip from 'components/Tooltip/useTooltip';
import Icon from 'components/Icon';
import FormSelectUsersWithRights from 'components/FormSelectUsersWithRights';

type Props = {
  defaultData?: Client | null,
  defaultClientName?: string,
  isCreate?: boolean,
  eavs?: OrganizationEavs,
  errors?: Errors.Validation | null,
};

const CustomerEditForm = (props: Props): JSX.Element => {
  const {
    isCreate = true,
    eavs,
    defaultData = null,
    errors = null,
    defaultClientName,
  } = props;
  const { linesOfBusiness, useGroupByDefault } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);

  const tooltip = useTooltip(
    <div className="CustomerEditForm__tooltip">{t('clients:attachment-tooltip')}</div>,
    'clamped',
  );

  const [clientType, setClientType] = useState<Misc.PersonType>(
    defaultData?.type || 'company',
  );

  const handleChangeType = useCallback((newValue: FormRadioValue) => {
    setClientType(newValue as Misc.PersonType);
  }, []);

  useEffect(() => {
    if (defaultData) {
      setClientType(defaultData.type);
    }
  }, [defaultData]);

  const radioItems = useMemo(() => [
    {
      label: t('clients:type-company'),
      value: 'company',
      id: 'client-type-company',
    },
    {
      label: t('clients:type-individual'),
      value: 'individual',
      id: 'client-type-individual',
    },
  ], [t]);

  if (!isCreate && !defaultData) {
    return <Loading />;
  }

  return (
    <div className="CustomerEditForm">
      {isCreate && (
        <FormFieldset>
          <FormGroup label={ct('clients:type')} mandatory error={errors?.type}>
            <FormRadios
              name="type"
              items={radioItems}
              value={clientType}
              onChange={handleChangeType}
            />
          </FormGroup>
        </FormFieldset>
      )}
      {clientType === 'company' && (
        <FormFieldset>
          <FormGroup label={t('common:corporate-name')} mandatory error={errors?.corporateName}>
            <FormControl
              name="corporateName"
              autoComplete="off"
              defaultValue={defaultData?.corporateName || defaultClientName || ''}
              isInvalid={!!errors?.corporateName}
            />
          </FormGroup>
          <FormGroup label={t('clients:legal-id')} error={errors?.legalId}>
            <FormControl
              name="legalId"
              autoComplete="off"
              defaultValue={defaultData?.legalId || ''}
              isInvalid={!!errors?.legalId}
            />
          </FormGroup>
        </FormFieldset>
      )}
      {clientType === 'individual' && (
        <FormFieldset>
          <FormGroup className="CustomerEditForm__civility" label={t('common:civility')} error={errors?.civility}>
            <FormControl
              name="civility"
              autoComplete="off"
              defaultValue={defaultData?.civility || ''}
              isInvalid={!!errors?.civility}
            />
          </FormGroup>
          <FormGroup label={t('common:first-name')} error={errors?.firstName}>
            <FormControl
              name="firstName"
              autoComplete="off"
              defaultValue={defaultData?.firstName || ''}
              isInvalid={!!errors?.firstName}
            />
          </FormGroup>
          <FormGroup label={t('common:last-name')} mandatory error={errors?.lastName}>
            <FormControl
              name="lastName"
              autoComplete="off"
              defaultValue={defaultData?.lastName || ''}
              isInvalid={!!errors?.lastName}
            />
          </FormGroup>
        </FormFieldset>
      )}
      <FormFieldset>
        <FormGroup label={t('common:reference')} mandatory error={errors?.reference}>
          <FormControl
            name="reference"
            autoComplete="off"
            defaultValue={defaultData?.reference || ''}
            isInvalid={!!errors?.reference}
          />
        </FormGroup>
        {clientType === 'company' && (
          <FormGroup label={t('common:iban')} error={errors?.iban}>
            <FormControl
              name="iban"
              autoComplete="off"
              defaultValue={defaultData?.iban || ''}
              isInvalid={!!errors?.iban}
            />
          </FormGroup>
        )}
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('common:phone')} error={errors?.phone}>
          <FormControl
            name="phone"
            autoComplete="off"
            defaultValue={defaultData?.phone || ''}
            isInvalid={!!errors?.phone}
          />
        </FormGroup>
        <FormGroup label={t('common:cell-phone')} error={errors?.cellPhone}>
          <FormControl
            name="cellPhone"
            autoComplete="off"
            defaultValue={defaultData?.cellPhone || ''}
            isInvalid={!!errors?.cellPhone}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('common:email')} error={errors?.email}>
          <FormControl
            name="email"
            type="email"
            autoComplete="off"
            defaultValue={defaultData?.email || ''}
            isInvalid={!!errors?.email}
          />
        </FormGroup>
        <FormGroup label={t('common:managers')} error={errors?.users}>
          <FormSelectUsersWithRights
            name="users"
            placeholder={t('common:please-choose')}
            fetchEntity="users"
            entityValue="reference"
            defaultValue={defaultData?.users}
            isInvalid={!!errors?.users}
            isMultiple
            isAsync
            action="CREATE"
            categoryType="CLIENTS"
          />
          <div className="CustomerEditForm__help">
            {t('clients:let-empty-to-accept-all')}
          </div>
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('common:address')} error={errors?.address1}>
          <FormControl
            name="address1"
            autoComplete="off"
            defaultValue={defaultData?.address1 || ''}
            isInvalid={!!errors?.address1}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={`${t('common:address')} 2`} error={errors?.address2}>
          <FormControl
            name="address2"
            autoComplete="off"
            defaultValue={defaultData?.address2 || ''}
            isInvalid={!!errors?.address2}
          />
        </FormGroup>
        <FormGroup
          className="CustomerEditForm__address3"
          label={`${t('common:address')} 3`}
          error={errors?.address3}
        >
          <FormControl
            name="address3"
            autoComplete="off"
            defaultValue={defaultData?.address3 || ''}
            isInvalid={!!errors?.address3}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup
          className="CustomerEditForm__postal-code"
          label={t('common:postal-code')}
          error={errors?.postalCode}
        >
          <FormControl
            name="postalCode"
            autoComplete="off"
            defaultValue={defaultData?.postalCode || ''}
            isInvalid={!!errors?.postalCode}
          />
        </FormGroup>
        <FormGroup label={t('common:city')} error={errors?.city}>
          <FormControl
            name="city"
            autoComplete="off"
            defaultValue={defaultData?.city || ''}
            isInvalid={!!errors?.city}
          />
        </FormGroup>
        <FormGroup label={t('common:country')} error={errors?.country}>
          <FormSelect
            name="country"
            placeholder={t('common:please-choose')}
            fetchEntity="countries"
            hasFetchOrganizationPrefix={false}
            entityValue="id"
            defaultValue={defaultData?.country}
            isInvalid={!!errors?.country}
            isAsync
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup className="CustomerEditForm__attachment">
          <FormCheck
            name="attachment"
            label={t<string>('clients:with-attachment')}
            defaultChecked={defaultData?.attachment}
          />
          <TooltipProvider>
            <div ref={tooltip}>
              <Icon name="help-circle" />
            </div>
          </TooltipProvider>
        </FormGroup>
        <FormGroup className="CustomerEditForm__grouped-reminder">
          <FormCheck
            name="useGroup"
            label={t<string>('clients:grouped-reminder')}
            defaultChecked={defaultData?.useGroup ?? useGroupByDefault}
          />
        </FormGroup>
      </FormFieldset>
      {eavs && eavs.client.length > 0 && (
        <FormEavs
          eavs={eavs.client}
          defaultValues={defaultData?.eavs}
          errors={errors?.eavs as unknown as Errors.Validation}
        />
      )}
    </div>
  );
};

export default observer(CustomerEditForm);
