import './index.scss';
import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import organizationStore from 'stores/Organization';
import type { PayRequest, HistoryStep } from 'types/models';
import type { FetchAllForPayRequestParams, FetchHistoryParams } from 'api/historySteps';
import { fetchAllForPayRequest, fetchHistory } from 'api/historySteps';
import type Misc from 'types/misc';
import useFetch from 'hooks/useFetch';
import ActionsCollapse from './Actions';
import PaymentRequestInfos from './Infos';

interface Props {
  payRequest: PayRequest,
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
  reloadTick: number,
}

const PaymentRequestContent = (props: Props): JSX.Element => {
  const { currentOrganization } = organizationStore;
  const {
    payRequest,
    onActionDone,
    onActionError,
    reloadTick,
  } = props;

  const {
    data: futureSteps,
    refetch: refetchFuture,
    isFetching: isFetchingFuture,
  } = useFetch<FetchAllForPayRequestParams, Misc.Listing<HistoryStep>>(
    {
      cacheKey: 'payRequestHistorySteps',
      organization: currentOrganization?.reference,
      payRequestId: payRequest.id,
      type: 'future',
    },
    fetchAllForPayRequest,
  );

  const {
    data: waitingSteps,
    refetch: refetchWaiting,
    isFetching: isFetchingWaiting,
  } = useFetch<FetchAllForPayRequestParams, Misc.Listing<HistoryStep>>(
    {
      cacheKey: 'payRequestHistorySteps',
      organization: currentOrganization?.reference,
      payRequestId: payRequest.id,
      type: 'waiting',
    },
    fetchAllForPayRequest,
  );

  const {
    data: historySteps,
    refetch: refetchHistory,
    isFetching: isFetchingHistory,
  } = useFetch<FetchHistoryParams, Misc.Listing<HistoryStep>>(
    {
      cacheKey: 'organizationHistorySteps',
      organization: currentOrganization?.reference,
      payRequestId: payRequest.id,
    },
    fetchHistory,
  );

  useEffect(() => {
    refetchFuture();
    refetchHistory();
    refetchWaiting();
  }, [refetchFuture, refetchHistory, refetchWaiting, reloadTick]);

  const shouldSuggestCreateAction = useMemo<boolean>(() => (
    (payRequest.status === 'IN_PROGRESS'
      && !payRequest.muted
      && (waitingSteps && !waitingSteps.pagination.totalRecords)
      && (futureSteps && !futureSteps.pagination.totalRecords))
      || false
  ), [futureSteps, payRequest.muted, payRequest.status, waitingSteps]);

  return (
    <div className="PaymentRequestContent">
      <PaymentRequestInfos
        payRequest={payRequest}
        onActionDone={onActionDone}
      />
      <ActionsCollapse
        type="future"
        shouldSuggestCreateAction={shouldSuggestCreateAction}
        steps={futureSteps}
        payRequest={payRequest}
        onActionDone={onActionDone}
        onActionError={onActionError}
        isLoading={isFetchingFuture}
      />
      <ActionsCollapse
        type="waiting"
        isDefaultOpen={!!(waitingSteps && waitingSteps.pagination.totalRecords !== 0)}
        steps={waitingSteps}
        payRequest={payRequest}
        onActionDone={onActionDone}
        onActionError={onActionError}
        isLoading={isFetchingWaiting}
      />
      <ActionsCollapse
        type="history"
        isDefaultOpen={(!waitingSteps || waitingSteps.pagination.totalRecords === 0)}
        steps={historySteps}
        payRequest={payRequest}
        onActionDone={onActionDone}
        onActionError={onActionError}
        isLoading={isFetchingHistory}
      />
    </div>
  );
};

export default observer(PaymentRequestContent);
