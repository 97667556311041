import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
import type Errors from 'types/errors';
import organizationStore from 'stores/Organization';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import Confirm from 'components/Confirm';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import SendingActionsEditForm from './Form';

interface Props {
  data?: string,
  type: 'pdf' | 'text' | 'img',
  title: string,
  name: string,
  onClose: () => void,
  onShowToast: (message: string, status: ToastStatus) => void,
}

const SendingActionsEdit: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const isMountedRef = useIsMountedRef();

  const {
    data,
    type,
    title,
    name,
    onClose,
    onShowToast,
  } = props;

  const {
    put,
    postFormData,
    isLoading,
    cancel,
    error,
  } = useApiRequest();

  const [hasChanges, setHasChanges] = React.useState<boolean>(false);
  const [validationErrors, setValidationErrors] = React.useState<Errors.Validation | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = React.useState<boolean>(false);

  const closeSelf = React.useCallback(() => {
    onClose();
    setShowCancelConfirm(false);
    setHasChanges(false);
  }, [onClose]);

  const handleChange = React.useCallback(
    (formData: ModalFormData | null) => {
      setHasChanges(!!formData);
    },
    [],
  );

  const handleCancel = React.useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirm(true);
    } else {
      closeSelf();
    }
  }, [hasChanges, closeSelf]);

  React.useEffect(() => (
    () => { cancel(); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [currentOrganization]);

  const handleSubmit = (async (formData: ModalFormData | null) => {
    if (!formData || !currentOrganization) {
      return;
    }

    let result = null;

    let url = `organization/manage/${currentOrganization.reference}`;
    if (name === 'letterHead') url = `organization/manage/${currentOrganization.reference}/letterhead`;
    if (name === 'handwrittenSignature') url = `organization/manage/${currentOrganization.reference}/signature`;

    if (type === 'pdf' || type === 'img') {
      const formDataFile = new FormData();
      formDataFile.append(name, formData[name]);

      result = await postFormData<Misc.IdName>(url, formDataFile);
    } else {
      result = await put<Misc.IdName>(url, formData);
    }

    if (!isMountedRef.current) {
      return;
    }

    if (result?.errors) {
      setValidationErrors(result.errors);
      return;
    }

    if (result?.id) {
      organizationStore.refresh();
      onClose();
      onShowToast(t('users:toast.success.settings-update'), 'success');
    }
  });

  return (
    <ModalForm
      title={title}
      className="SendingActionsEdit"
      isOpened
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
    >
      {error && <ErrorMessage error={error} />}
      <SendingActionsEditForm
        defaultData={data}
        type={type}
        name={name}
        errors={validationErrors}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={closeSelf}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(SendingActionsEdit);
