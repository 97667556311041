import './index.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import type { StepEvent } from 'types/models';
import type { FetchOrganizationLastHistoryParams } from 'api/organization';
import { lastHistory } from 'api/organization';
import organizationStore from 'stores/Organization';
import useFetch from 'hooks/useFetch';
import ErrorMessage from 'components/ErrorMessage';
import Loading from 'components/Loading';
import HomeHistoryItem from './Item';

const HomeHistory = (): JSX.Element => {
  const { t } = useTranslation();
  const { currentOrganization, walletSelection } = organizationStore;

  const filtering = useMemo(() => {
    if (walletSelection.length === 0) {
      return [];
    }
    return [{ name: 'categories', value: walletSelection }];
  }, [walletSelection]);

  const {
    isLoading,
    data: events,
    error,
  } = useFetch<FetchOrganizationLastHistoryParams, StepEvent[]>(
    {
      cacheKey: 'organizationLastHistory',
      organization: currentOrganization?.reference,
      filtering,
    },
    lastHistory,
  );

  return (
    <div className="HomeHistory">
      {error && <ErrorMessage error={error} />}
      {isLoading && <Loading hasNoText />}
      {events && events.length > 0 && (
        <ul className="HomeHistory__list">
          {events.map((event) => (
            <HomeHistoryItem data={event} key={event.id} />
          ))}
        </ul>
      )}
      {!isLoading && events && events.length === 0 && (
        <div className="HomeHistory__no-data">
          {t('payment-requests:no-history')}
        </div>
      )}
    </div>
  );
};

export default observer(HomeHistory);
