import { observer } from 'mobx-react';
import type { OrganizationHeaderSummary } from 'types/models';
import organizationStore from 'stores/Organization';
import OrganizationItemTitle from './Title';
import OrganizationItemWallet from './Wallet';

type Props = {
  organization: OrganizationHeaderSummary,
  withLogo?: boolean,
  withWallets?: boolean,
};

const OrganizationSelectorItem = ({ organization }: Props): JSX.Element => {
  const { id } = organization;
  const { currentOrganization } = organizationStore;
  const isOpened = currentOrganization?.id === id;

  return (
    <div className="OrganizationSelectorItem">
      <OrganizationItemTitle
        organization={organization}
        isOpened={isOpened}
      />
      {isOpened && <OrganizationItemWallet />}
    </div>
  );
};

export default observer(OrganizationSelectorItem);
