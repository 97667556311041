import { useCallback } from 'react';
import useApiRequest from 'hooks/useApiRequest';
import organizationStore from 'stores/Organization';
import InputModal from 'components/InputModal';
import type Misc from 'types/misc';
import useContextualTranslation from 'hooks/useContextualTranslation';

type Props = {
  paymentRef: string,
  paymentId: number,
  onClose(): void,
  onDone(title: string, messsage: string): void,
};

const PaymentRequestCreateNote = (props: Props): JSX.Element => {
  const { paymentRef, paymentId, onClose, onDone } = props;
  const { linesOfBusiness } = organizationStore;
  const { ct, t } = useContextualTranslation(linesOfBusiness);
  const { post, error, isLoading } = useApiRequest();

  const handleSubmit = useCallback(async (inputModalValue: Record<string, string>) => {
    const result = await post<Misc.IdRef>(
      `pay-requests/manage/${paymentId}/note`,
      inputModalValue,
    );

    if (!result) {
      return;
    }

    onDone(
      t('notes:toast.created'),
      ct('notes:toast.created-invoice', { reference: paymentRef }),
    );
    onClose();
  }, [post, paymentId, onDone, t, ct, paymentRef, onClose]);

  return (
    <InputModal
      isShow
      title={t('payment-requests:add-note')}
      help={ct('payment-requests:add-note-for-ref', { reference: paymentRef })}
      type="textarea"
      name="message"
      isLoading={isLoading}
      error={error}
      onSubmit={handleSubmit}
      onCancel={onClose}
    />
  );
};

export default PaymentRequestCreateNote;
