import type Misc from 'types/misc';
import type { Client } from 'types/models';

const ExportLink = (
  fetchOptions: Misc.PaginatedFetchArgs<Client>,
  filtering: Misc.Filter[],
  organizationReference: string | null,
): string => {
  const { sort } = fetchOptions;

  if (!organizationReference) {
    return '';
  }

  const fetchStatus = filtering?.find(({ name }) => name === 'status')?.value || 'IN_PROGRESS';

  const queryData = ['withEav=1', 'withUser=1'];

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }) => {
      queryData.push(`filters[${name}]=${value}`);
    });
  }

  if (sort) {
    const key = sort.id === 'client' ? 'clientName' : sort.id;
    const direction = sort.desc ? 'desc' : 'asc';
    queryData.push(`orderBy=${key}&orderDirection=${direction}`);
  }

  return `export/pay-requests/${organizationReference}/status/${fetchStatus}?${queryData.join('&')}`;
};

export default ExportLink;
