import { Link } from 'react-router-dom';
import { Fragment } from 'react';
import type { TFunction } from 'i18next';
import type { Column } from 'react-table';
import type { PaymentForList } from 'types/models';
import organizationStore from 'stores/Organization';
import formatIntlDate from 'utils/formatIntlDate';
import formatIntlNumber from 'utils/formatIntlNumber';
import getI18nPaymentMethod from 'utils/getI18nPaymentMethod';
import getI18nPaymentStatus from 'utils/getI18nPaymentStatus';
import ClientName from 'components/ClientName';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import { UserRightsManagerController } from 'components/UserRights';
import { ExternallyMonitoredController } from 'components/ExternallyMonitored';
import RowActions from '../Actions/Row';

const PaymentsColumns = (
  ct: TFunction,
  onActionDone: (title: string, message: string) => void,
  onActionError: (message: string) => void,
  onEdit: (reference: string) => void,
): Column<PaymentForList>[] => [
  {
    Header: ct<string>('payments:reference'),
    accessor: 'reference',
    Cell: ({ row, value }) => (
      <>
        <Link className="Payments__columns__reference" to={`/payments/view/${encodeURIComponent(value)}`}>
          {value}
        </Link>
        <div className="Payments__columns__mean">
          {ct(getI18nPaymentMethod(row.original.method))}
        </div>
      </>
    ),
  },
  {
    Header: ct<string>('clients:client'),
    accessor: 'customer',
    Cell: ({ value }) => (
      <div className="Payments__columns__client">
        <Link to={`/customer/${value.id}`}><ClientName client={value} /></Link>
        <div className="Payments__columns__client__ref">
          {value.reference}
        </div>
      </div>
    ),
  },
  {
    Header: ct<string>('payments:received-the'),
    accessor: 'paidAt',
    Cell: ({ value }) => <Fragment>{formatIntlDate(value, 'dateLong')}</Fragment>,
  },
  {
    Header: ct<string>('common:amount'),
    accessor: 'totalAmount',
    Cell: ({ row, value }) => (
      <Fragment>
        {formatIntlNumber(value, row.original.currency || organizationStore.currency)}
      </Fragment>
    ),
  },
  {
    Header: ct<string>('payments:to-impute'),
    accessor: 'amountNotAssigned',
    disableSortBy: true,
    Cell: ({ value, row }) => {
      if (!value) {
        return null;
      }
      return (
        <Fragment>
          {formatIntlNumber(value, row.original.currency || organizationStore.currency)}
        </Fragment>
      );
    },
  },
  {
    Header: ct<string>('payments:associated-invoices'),
    accessor: 'payRequests',
    disableSortBy: true,
    Cell: ({ value: payRequests, row }) => (
      <div className="Payments__columns__pay-requests">
        {payRequests?.map(({ reference, id, amountAssigned }, index) => (
          <div
            key={`${row.original.id}-${reference}-${amountAssigned}-${id}`}
            className="Payments__columns__pay-requests__item"
          >
            <Link to={`/payment-request/${id}`}>
              {reference}
            </Link>
            {index < payRequests.length - 1 && ', '}
          </div>
        ))}
        {(!payRequests || payRequests.length === 0) && (
          <Fragment>
            {row.original.status !== 'SUCCESS' && (
              <span className="Payments__columns__pay-requests__failed">
                {ct('payments:not-imputable')} ({ct(getI18nPaymentStatus(row.original.status))})
              </span>
            )}
            {row.original.status === 'SUCCESS' && (
              <ButtonWithUserRights
                variant="outline"
                onClick={() => onEdit(`payments/edit/${row.original.reference}`)}
                managers={row.original.managers}
                action="CREATE"
                categoryType="PAYMENTS"
              >
                {ct('payments:to-impute')}
              </ButtonWithUserRights>
            )}
          </Fragment>
        )}
      </div>
    ),
  },
  {
    Header: ct<string>('payments:subject'),
    accessor: 'subject',
    disableSortBy: true,
    Cell: ({ value }) => (
      <div className="Payments__columns__subject">
        {value}
      </div>
    ),
  },
  {
    Header: ct<string>('common:commands'),
    accessor: 'id',
    disableSortBy: true,
    Cell: ({ row, value: id }) => (
      <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
        <UserRightsManagerController action="UPDATE" categoryType="PAYMENTS">
          <RowActions
            id={id}
            name={row.original.subject || row.original.reference}
            reference={row.original.reference}
            onActionDone={onActionDone}
            onActionError={onActionError}
          />
        </UserRightsManagerController>
      </ExternallyMonitoredController>
    ),
  },
];

export default PaymentsColumns;
