import './index.scss';
import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import organizationStore from 'stores/Organization';
import Button from 'components/Button';
import useApiRequest from 'hooks/useApiRequest';
import Loading from 'components/Loading';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';

type Props = {
  selection?: (number | string)[],
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const ActionsBulkActions = (props: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { selection, onActionDone, onActionError } = props;
  const { userRight } = organizationStore;
  const isUserReader = userRight === 'ROLE_READER';

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const { put, error, isLoading } = useApiRequest();

  const [confirmVariant, setConfirmVariant] = useState<'primary' | 'danger'>();
  const [confirmTitleButton, setConfirmTitleButton] = useState<string>();
  const [cancelTitleButton, setCancelTitleButton] = useState<string>();
  const [action, setAction] = useState<'validate' | 'cancel'>();

  const handleConfirmValidate = useCallback(() => {
    setConfirmVariant('primary');
    setConfirmTitleButton(t<string>('actions:confirm.validate'));
    setCancelTitleButton(t<string>('actions:confirm.cancel'));
    setAction('validate');
    showConfirm(
      t('actions:confirm.title-validate'),
      t('actions:bulk.validate', { count: selection?.length }),
    );
  }, [showConfirm, t, setConfirmVariant, setConfirmTitleButton, selection]);

  const handleConfirmCancel = useCallback(() => {
    setConfirmVariant('danger');
    setConfirmTitleButton(t<string>('actions:confirm.remove'));
    setCancelTitleButton(t<string>('actions:confirm.keep'));
    setAction('cancel');
    showConfirm(
      t('actions:confirm.title-remove'),
      t('actions:bulk.remove', { count: selection?.length }),
    );
  }, [showConfirm, t, setConfirmVariant, setConfirmTitleButton, selection]);

  const handleSubmit = useCallback(async () => {
    hideConfirm();

    if (!action) {
      throw new Error('Action non définie.');
    }

    // Il faudrait pouvoir faire ceci avec une seul requête
    selection?.forEach(async (select) => {
      const actions = {
        validate: {
          url: `task/manage/${select}/activate`,
          onDoneText: 'Les Actions ont bien été validés',
        },
        cancel: {
          url: `task/manage/${select}/remove`,
          onDoneText: 'Les Actions ont bien été annulés',
        },
      };

      if (!actions[action]) {
        throw new Error('Action non reconnue.');
      }

      const { url, onDoneText } = actions[action];
      await put(url);

      hideConfirm();
      onActionDone(onDoneText);
    });
  }, [hideConfirm, action, selection, put, onActionDone]);

  useEffect(() => {
    if (error) {
      hideConfirm();
      onActionError(error.message || t('errors:unknown-retry'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  if (isUserReader || !selection) {
    return null;
  }

  return (
    <div className="CustomersBulkActions">
      {isLoading && <Loading text={t('common:saving')} isOverlay />}
      <Button
        variant="outline-light"
        onClick={handleConfirmValidate}
        disabled={isLoading}
        className="ActionsBulkActions__validate"
      >
        {t('common:validate')}
      </Button>
      <Button
        variant="outline-light"
        onClick={handleConfirmCancel}
        className="ActionsBulkActions__cancel"
        disabled={isLoading}
      >
        {t('common:cancel')}
      </Button>
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        isShow={isConfirmShowed}
        onConfirm={handleSubmit}
        onCancel={hideConfirm}
        variant={confirmVariant}
        confirmButtonText={confirmTitleButton}
        cancelButtonText={cancelTitleButton}
      />
    </div>
  );
};

export default observer(ActionsBulkActions);
