import { Fragment } from 'react';
import type { Column } from 'react-table';
import type { TFunction } from 'i18next';
import type { Collaborator, RightsRole } from 'types/models';
import getI18nUserRights from 'utils/getI18nUserRights';
import RowActions from '../RowActions';

const CollaboratorsColumns = (
  t: TFunction,
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
): Column<Collaborator>[] => [
  {
    Header: t<string>('common:name'),
    accessor: 'name',
    disableSortBy: true,
    Cell: ({ row, value }) => (
      <Fragment>
        <div className="CollaboratorsTable__name">
          {value}
        </div>
        <div className="CollaboratorsTable__roles">
          {t(row.original.roles.map((role: RightsRole) => getI18nUserRights(role)).join(', '))}
        </div>
      </Fragment>
    ),
  },
  {
    Header: t<string>('perimeters:perimeters'),
    accessor: 'categories',
    disableSortBy: true,
    Cell: ({ value: categories }) => (
      <div className="CollaboratorsTable__perimeters">
        {categories.map((category) => category.values.map(({ value }) => value)).flat().join(', ')}
      </div>
    ),
  },
  {
    Header: t<string>('collaborators:identifier'),
    accessor: 'identifier',
    disableSortBy: true,
    Cell: ({ value }) => (
      <div className="CollaboratorsTable__identifier">
        <span className="CollaboratorsTable__identifier__title">
          {value}
        </span>
      </div>
    ),
  },
  {
    Header: t<string>('common:email'),
    accessor: 'email',
    disableSortBy: true,
  },
  {
    Header: t<string>('common:commands'),
    accessor: 'id',
    disableSortBy: true,
    Cell: ({ row }) => (
      <div className="CollaboratorsTable__actions">
        <RowActions
          collaborator={row.original as Collaborator}
          onActionDone={onActionDone}
          onActionError={onActionError}
        />
      </div>
    ),
  },
];

export default CollaboratorsColumns;
