import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import Button from 'components/Button';
import Loading from 'components/Loading';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import DropdownActions from 'components/DropdownActions';

type Props = {
  id: number,
  reference: string,
  name: string,
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const CollaboratorsInvitedRowActions = (props: Props): JSX.Element => {
  const { t } = useTranslation();

  const isMountedRef = useIsMountedRef();

  const {
    id,
    name,
    onActionDone,
    onActionError,
  } = props;

  const { remove } = useApiRequest();

  const {
    isConfirmShowed,
    showConfirm,
    hideConfirm,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const { error, isLoading } = useApiRequest();

  const handleConfirmDelete = useCallback(() => {
    showConfirm(t('collaborators:remove-invite'), t('collaborators:confirm-remove-invite', { name }));
  }, [showConfirm, t, name]);

  const handleSubmitDelete = useCallback(async () => {
    hideConfirm();
    const response = await remove(`/collaborators/manage/invitations/${id}`);
    if (isMountedRef && response) {
      onActionDone(t('collaborators:toast.success.collaborator-invite-deleted', { name }));
    }
  }, [hideConfirm, onActionDone, t, id, name, remove, isMountedRef]);

  useEffect(() => {
    if (error) {
      onActionError(error.message || t('collaborators:toast.error.collaborator-invite-deleted', { name }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <>
      <DropdownActions
        actions={[
          <Button
            variant="list-danger"
            onClick={handleConfirmDelete}
            title={t('common:remove')}
            disabled={isLoading}
          >
            {isLoading && <Loading hasNoText />}
            {!isLoading && t('common:remove')}
          </Button>,
        ]}
      />
      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        entityName={name}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitDelete}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={t('common:remove')}
      />
    </>
  );
};

export default CollaboratorsInvitedRowActions;
