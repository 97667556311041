import './index.scss';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import customerContext from '../../customerContext';

type Props = {
  basePath: string,
};

const CustomerMainMenu = ({ basePath }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { data } = useContext(customerContext);
  const { steps, history } = data?.recentCount ?? { steps: null, history: null };

  return (
    <nav className="CustomerMainMenu">
      <NavLink to={`${basePath}/summary`} className="CustomerMainMenu__item">
        {t('common:menu.summary')}
        {(steps !== null && steps > 0) && (
          <span className="CustomerMainMenu__item__count">
            {steps > 99 ? '99+' : steps}
          </span>
        )}
      </NavLink>
      <NavLink to={`${basePath}/history`} className="CustomerMainMenu__item">
        {t('common:menu.history')}
        {(history !== null && history > 0) && (
          <span className="CustomerMainMenu__item__count">
            {history > 99 ? '99+' : history}
          </span>
        )}
      </NavLink>
      <NavLink to={`${basePath}/infos-contacts`} className="CustomerMainMenu__item">
        {t('common:menu.infos')} / {t('common:menu.contacts')}
      </NavLink>
      <NavLink to={`${basePath}/analysis`} className="CustomerMainMenu__item">
        {t('common:menu.analysis')}
      </NavLink>
    </nav>
  );
};

export default CustomerMainMenu;
