import './index.scss';
import { useMemo, Fragment, useState, useCallback } from 'react';
import type { KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import type { Collaborator } from 'types/models';
import type { Category } from 'types/models';
import Config from 'config';
import useIsMountedRef from 'hooks/useIsMountedRef';
import useApiRequest from 'hooks/useApiRequest';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormSelect from 'components/FormSelect';
import getI18nUserRights from 'utils/getI18nUserRights';
import Button from 'components/Button';
import Perimeter from 'components/Perimeter';
import FormPerimeter from 'components/FormPerimeter';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';

type Props = {
  collaborator: Collaborator,
  perimeter: Category[],
  availableCategories: Category[],
  onCanSave(canSave: boolean): void,
  onCategoriesUpdate(updatedCategories: Category[]): void,
  onActionDone(test: string): void,
  onActionError(test: string): void,
};

const ModalEditCollaboratorsForm = (props: Props): JSX.Element => {
  const {
    collaborator,
    perimeter,
    availableCategories,
    onCanSave,
    onCategoriesUpdate,
    onActionError,
    onActionDone,
  } = props;

  const { t } = useTranslation();
  const [showPerimeterForm, setShowPerimeterForm] = useState(false);
  const { put } = useApiRequest();
  const isMountedRef = useIsMountedRef();
  const {
    showConfirm,
    hideConfirm,
    isConfirmShowed,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const userRightsOptions = useMemo(() => (
    Config.USER_RIGHTS.map((right) => ({
      label: t(getI18nUserRights(right)),
      value: right,
    }))
  ), [t]);

  const handleShowPerimeterForm = useCallback((isShown: boolean) => {
    setShowPerimeterForm(isShown);
    onCanSave(isShown);
  }, [onCanSave]);

  const handleConfirmDelete = useCallback(() => {
    showConfirm(t('perimeters:confirm.category-delete-title'), t('perimeters:confirm.perimeter-delete'));
  }, [showConfirm, t]);

  const handleSubmitDelete = useCallback(async () => {
    hideConfirm();
    const result = await put(
      `/collaborators/manage/${collaborator.id}`,
      { ...collaborator, categories: [] },
    );

    if (!isMountedRef) {
      return;
    }

    const { name } = collaborator;
    if (!result) {
      onActionError(t('collaborators:toast.error.collaborator-edit', { name }));
      return;
    }

    onActionDone(t('collaborators:toast.success.collaborator-edit', { name }));
    onCategoriesUpdate([]);
  }, [collaborator, isMountedRef, hideConfirm, put, onActionError, onActionDone, t, onCategoriesUpdate]);

  const perimeterActions = [
    <Button variant='list' onClick={() => { handleShowPerimeterForm(true); }}>{t('common:edit')}</Button>,
    <Button variant='list-danger' onClick={() => { handleConfirmDelete(); }}>{t('common:remove')}</Button>,
  ];

  const showAddPerimeter = (availableCategories && availableCategories.length > 0) && perimeter.length === 0;
  const showEditPerimeter = (availableCategories && availableCategories.length > 0) && perimeter.length > 0;

  return (
    <Fragment>
      <FormFieldset>
        <FormGroup label={t('collaborators:identifier')}>
          <FormControl
            name="identifier"
            className="ModalEditCollaboratorsForm"
            defaultValue={collaborator?.identifier}
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => { e.stopPropagation(); }}
            autoComplete="off"
          />
        </FormGroup>
        <FormGroup label={t('collaborators:level')}>
          <FormSelect
            name="roles"
            className="ModalEditCollaboratorsForm"
            placeholder={t('common:please-choose')}
            selectOptions={userRightsOptions}
            defaultValue={collaborator.roles[0]}
          />
        </FormGroup>
      </FormFieldset>
      <div className="ModalEditCollaboratorsForm__perimeter">
        <div className="ModalEditCollaboratorsForm__perimeter__header">
          <div className="ModalEditCollaboratorsForm__perimeter__title">
            {t('perimeters:perimeters')}
          </div>
          {showEditPerimeter && !showPerimeterForm && (
            <Button variant="link" onClick={() => { handleShowPerimeterForm(true); }}>
              {t('perimeters:modify-perimeter')}
            </Button>
          )}
          {showAddPerimeter && !showPerimeterForm && (
            <Button variant="link" onClick={() => { handleShowPerimeterForm(true); }}>
              {t('perimeters:add-perimeter')}
            </Button>
          )}
        </div>
        {!availableCategories || availableCategories.length === 0 && (
          <div className="ModalEditCollaboratorsForm__perimeter__association_error">
            {t('perimeters:errors.no-association-possible-title')}
            <div className="ModalEditCollaboratorsForm__perimeter__association_error__content">
              {t('perimeters:errors.no-association-possible-content')}
            </div>
          </div>
        )}
        {perimeter.length > 0 && !showPerimeterForm && (
          <Perimeter perimeter={perimeter} actions={perimeterActions} />
        )}
        {showPerimeterForm && (
          <FormPerimeter
            availableCategories={availableCategories}
            perimeter={perimeter}
            onClose={() => { handleShowPerimeterForm(false); }}
            onChange={onCategoriesUpdate}
          />
        )}
        <Confirm
          titleModal={confirmTitle}
          text={confirmText}
          entityName={collaborator.name}
          isShow={isConfirmShowed}
          onConfirm={handleSubmitDelete}
          onCancel={hideConfirm}
          variant="danger"
          confirmButtonText={t('common:remove')}
        />
      </div>
    </Fragment>
  );
};

export default ModalEditCollaboratorsForm;
