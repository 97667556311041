import React from 'react';
import { useTranslation } from 'react-i18next';
import type Misc from 'types/misc';
import getI18nActionSelectStatus from 'utils/getI18nActionSelectStatus';
import SelectGroupCheckAll from 'components/SelectGroupCheckAll';
import SelectGroupItem from 'components/SelectGroupItem';

export const REMINDERS_FILTERS = ['sent_reminder', 'sent_error_reminder', 'activities-clients'];

const ReminderFilters: React.FC<Misc.FilterCustomComponent> = (props) => {
  const {
    filter,
    getFilterValue,
    onChange,
  } = props;

  const { t } = useTranslation();

  const getFilterArray = React.useCallback(
    (key: string): string[] => {
      const filterValue = getFilterValue(key);
      if (!filterValue) {
        return [];
      }
      return Array.isArray(filterValue) ? filterValue : [filterValue];
    }, [getFilterValue],
  );

  const isChecked = React.useCallback(
    (key: string) => {
      const collapseValue = getFilterArray(filter.key);
      return collapseValue.includes(key);
    },
    [getFilterArray, filter],
  );

  const isAllChecked = React.useMemo(() => {
    const collapseValue = getFilterArray(filter.key);
    return REMINDERS_FILTERS.every((value) => collapseValue.includes(value));
  }, [getFilterArray, filter]);

  const setChecked = React.useCallback(
    (key: string, checked: boolean) => {
      const collapseValue = getFilterArray(filter.key);
      const newCollapseValue = [...collapseValue.filter((value) => value !== key)];
      if (checked) {
        newCollapseValue.push(key);
      }
      onChange({ name: filter.key, value: newCollapseValue });
    },
    [filter, getFilterArray, onChange],
  );

  const handleOnChange = React.useCallback((newIsAllChecked: boolean) => {
    const collapseValue = getFilterArray(filter.key);
    if (newIsAllChecked) {
      onChange({
        name: filter.key,
        value: [
          ...collapseValue.filter((value) => !REMINDERS_FILTERS.includes(value)),
          ...REMINDERS_FILTERS,
        ],
      });
      return;
    }
    const newCollapseValue = [
      ...collapseValue.filter((value) => !REMINDERS_FILTERS.includes(value)),
    ];
    onChange({ name: filter.key, value: newCollapseValue });
  }, [filter, getFilterArray, onChange]);

  return (
    <SelectGroupCheckAll
      isAllChecked={isAllChecked}
      onChange={handleOnChange}
      name={t('actions:activities')}
      title={t('actions:reminders-history')}
      subtitle={t('actions:reminders-all')}
      isDefaultOpen={!isAllChecked}
      withoutTitle
    >
      {REMINDERS_FILTERS.map((filterItem) => (
        <SelectGroupItem
          key={filterItem}
          filterKey={filterItem}
          title={t(getI18nActionSelectStatus(filterItem))}
          isChecked={isChecked}
          onCheck={setChecked}
        />
      ))}
    </SelectGroupCheckAll>
  );
};

export default ReminderFilters;
