import { Fragment, useMemo } from 'react';
import { observer } from 'mobx-react';
import type { PayRequest } from 'types/models';
import type { ToggleAction } from 'pages/PaymentRequests/Actions/actions';
import CreditNote from 'pages/PaymentRequests/Actions/Row/CreditNote';
import ActionNote from 'pages/PaymentRequests/Actions/Row/Note';
import ActionsToggle from 'pages/PaymentRequests/Actions/Row/Toggle';
import organizationStore from 'stores/Organization';
import currenciesStore from 'stores/Currencies';
import { ExternallyMonitoredController } from 'components/ExternallyMonitored';
import { UserRightsManagerController } from 'components/UserRights';

type Props = {
  data: PayRequest,
  onToggleDone(action: ToggleAction): void,
  onDone(message: string): void,
  onError(message: string): void,
};

const PaymentRequestSideMoreActions = (props: Props): JSX.Element | null => {
  const { data, onToggleDone, onDone, onError } = props;
  const { currency, modePayRequest } = organizationStore;
  const { currencies } = currenciesStore;
  const { id, reference, muted, group, user } = data;

  const currencyModel = useMemo(() => (
    currencies?.find((currencyItem) => (
      currencyItem.code === data.currency || currencyItem.code === currency
    ))
  ), [currencies, data, currency]);

  const hasGroup = !!group;
  const isNotCancelled = data?.status !== 'CANCELLED';
  const isPaid = data?.status === 'PAID';
  const isLitigated = data?.status === 'LITIGATED';
  const isInProgress = data?.status === 'IN_PROGRESS';

  return (
    <Fragment>
      <ActionNote
        id={id}
        reference={reference}
        onLoadingChange={() => {}}
        onDone={onDone}
      />
      <UserRightsManagerController
        action="UPDATE"
        managers={user}
        categoryType="PAYREQUESTS"
      >
        <Fragment>
          {!isPaid && isNotCancelled && !isLitigated && (
            <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
              <ActionsToggle
                id={id}
                reference={reference}
                hasGroup={hasGroup}
                muted={muted}
                action={muted ? 'unmute' : 'mute'}
                buttonIcon={muted ? 'bell' : 'bell-muted'}
                onDone={onToggleDone}
                onError={onError}
              />
            </ExternallyMonitoredController>
          )}
          {isInProgress && (
            <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
              <ActionsToggle
                id={id}
                reference={reference}
                hasGroup={hasGroup}
                muted={muted}
                action="litigate"
                buttonIcon="warning-circle"
                onDone={onToggleDone}
                onError={onError}
              />
            </ExternallyMonitoredController>
          )}
          {!isPaid && isNotCancelled && (
            <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
              <CreditNote
                id={id}
                reference={reference}
                currency={currencyModel}
                onLoadingChange={() => { }}
                onDone={onDone}
              />
            </ExternallyMonitoredController>
          )}
          {!isNotCancelled && (
            <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
              <ActionsToggle
                id={id}
                reference={reference}
                hasGroup={hasGroup}
                muted={muted}
                action="activate"
                onDone={onToggleDone}
                onError={onError}
              />
            </ExternallyMonitoredController>
          )}
          {isNotCancelled && !(modePayRequest === 'BALANCE' && (isPaid || isLitigated)) && (
            <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
              <ActionsToggle
                id={id}
                reference={reference}
                hasGroup={hasGroup}
                muted={muted}
                action="cancel"
                buttonVariant="danger"
                onDone={onToggleDone}
                onError={onError}
              />
            </ExternallyMonitoredController>
          )}
        </Fragment>
      </UserRightsManagerController>
    </Fragment>
  );
};

export default observer(PaymentRequestSideMoreActions);
