import './index.scss';
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import type Errors from 'types/errors';
import type Misc from 'types/misc';
import type { Contact, Client } from 'types/models';
import recomposeName from 'utils/recomposeName';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormSelect from 'components/FormSelect';
import FormSelectCustomer from 'components/FormSelectCustomer';
import Loading from 'components/Loading';

type Props = {
  defaultData?: Contact | null,
  isCreate: boolean,
  customerId?: number,
  allRoles: Misc.IdentifierValue[] | null,
  errors?: Errors.Validation | null,
  defaultCustomerId?: number,
  defaultRole?: string,
  defaultCustomerData?: Client | null,
};

const ContactForm = (props: Props): JSX.Element => {
  const { linesOfBusiness } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);

  const {
    allRoles,
    isCreate,
    defaultData = null,
    errors = null,
    defaultCustomerData,
    defaultCustomerId,
    defaultRole,
  } = props;

  const rolesOptions = useMemo(() => (
    allRoles?.map(({ identifier, value }) => ({
      label: value,
      value: identifier,
    }))
  ), [allRoles]);

  if (!isCreate && !defaultData) {
    return <Loading />;
  }

  return (
    <div className="ContactForm">
      <FormFieldset>
        <FormGroup label={ct('common:client')} mandatory error={errors?.client}>
          {isCreate && (
            <FormSelectCustomer
              name="client"
              defaultValue={defaultCustomerId}
              isInvalid={!!errors?.client}
            />
          )}
          {!isCreate && (
            <FormControl
              type="text"
              value={defaultData?.client ? (recomposeName(defaultData.client) ?? '') : ''}
              readOnly
            />
          )}
        </FormGroup>
        <FormGroup label={t('contacts:role')} mandatory error={errors?.contactRole}>
          <FormSelect
            name="contactRole"
            placeholder={t('common:please-choose')}
            selectOptions={rolesOptions}
            defaultValue={defaultRole || defaultData?.contactRole?.identifier}
            isInvalid={!!errors?.contactRole}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup
          className="ContactForm__civility"
          label={t('common:civility')}
          error={errors?.civility}
        >
          <FormControl
            type="text"
            name="civility"
            autoComplete="off"
            defaultValue={defaultData?.civility || ''}
            isInvalid={!!errors?.civility}
          />
        </FormGroup>
        <FormGroup label={t('common:first-name')} error={errors?.firstName}>
          <FormControl
            type="text"
            name="firstName"
            autoComplete="off"
            defaultValue={defaultData?.firstName || ''}
            isInvalid={!!errors?.firstName}
          />
        </FormGroup>
        <FormGroup label={t('common:last-name')} error={errors?.lastName}>
          <FormControl
            type="text"
            name="lastName"
            autoComplete="off"
            defaultValue={defaultData?.lastName || ''}
            isInvalid={!!errors?.lastName}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('common:address')} error={errors?.address1}>
          <FormControl
            type="text"
            name="address1"
            autoComplete="off"
            defaultValue={defaultData?.address1 || defaultCustomerData?.address1 || ''}
            isInvalid={!!errors?.address1}
          />
        </FormGroup>
        <FormGroup label={t('common:phone')} error={errors?.phone}>
          <FormControl
            type="text"
            name="phone"
            autoComplete="off"
            defaultValue={defaultData?.phone || ''}
            isInvalid={!!errors?.phone}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={`${t('common:address')} 2`} error={errors?.address2}>
          <FormControl
            type="text"
            name="address2"
            autoComplete="off"
            defaultValue={defaultData?.address2 || defaultCustomerData?.address2 || ''}
            isInvalid={!!errors?.address2}
          />
        </FormGroup>
        <FormGroup label={t('common:cell-phone')} error={errors?.cellPhone}>
          <FormControl
            type="text"
            name="cellPhone"
            autoComplete="off"
            defaultValue={defaultData?.cellPhone || ''}
            isInvalid={!!errors?.cellPhone}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={`${t('common:address')} 3`} error={errors?.address3}>
          <FormControl
            type="text"
            name="address3"
            autoComplete="off"
            defaultValue={defaultData?.address3 || defaultCustomerData?.address3 || ''}
            isInvalid={!!errors?.address3}
          />
        </FormGroup>
        <FormGroup label={t('common:email')} error={errors?.email}>
          <FormControl
            name="email"
            type="email"
            autoComplete="off"
            defaultValue={defaultData?.email || ''}
            isInvalid={!!errors?.email}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup
          className="ContactForm__postal-code"
          label={t('common:postal-code')}
          error={errors?.postalCode}
        >
          <FormControl
            type="text"
            name="postalCode"
            autoComplete="off"
            defaultValue={defaultData?.postalCode || defaultCustomerData?.postalCode || ''}
            isInvalid={!!errors?.postalCode}
          />
        </FormGroup>
        <FormGroup label={t('common:city')} error={errors?.city}>
          <FormControl
            type="text"
            name="city"
            autoComplete="off"
            defaultValue={defaultData?.city || defaultCustomerData?.city || ''}
            isInvalid={!!errors?.city}
          />
        </FormGroup>
        <FormGroup label={t('common:country')} error={errors?.country}>
          <FormSelect
            name="country"
            placeholder={t('common:please-choose')}
            fetchEntity="countries"
            hasFetchOrganizationPrefix={false}
            entityValue="id"
            defaultValue={defaultData?.country || defaultCustomerData?.country}
            isInvalid={!!errors?.country}
            isAsync
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('contacts:function')} error={errors?.function}>
          <FormControl
            type="text"
            name="function"
            autoComplete="off"
            defaultValue={defaultData?.function || ''}
            isInvalid={!!errors?.function}
          />
        </FormGroup>
        <FormGroup label={t('contacts:department')} error={errors?.department}>
          <FormControl
            type="text"
            name="department"
            autoComplete="off"
            defaultValue={defaultData?.department || ''}
            isInvalid={!!errors?.department}
          />
        </FormGroup>
      </FormFieldset>
    </div>
  );
};

export default observer(ContactForm);
