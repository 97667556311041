import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import type Misc from 'types/misc';
import ModalAddTask from '../ModalAddTask';
import ModalChooseChannel from '../ModalChooseChannel';
import ModalChooseType from '../ModalChooseType';
import ModalAddAction from '../ModalAddAction';

interface Props {
  name: string,
  identifier: string,
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
  dateReference: Misc.PlanDateReference,
}

const SettingsPlan: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const {
    dateReference,
    identifier,
    onActionDone,
    onActionError,
  } = props;

  const [type, setType] = React.useState<Misc.ActionType>('REMINDER');
  const [channel, setChannel] = React.useState<Misc.Channel>('EMAIL');

  const [showModalAddTask, hideModalAddTask] = useModal(() => (
    <ModalAddTask
      identifier={identifier}
      onActionError={onActionError}
      onActionDone={onActionDone}
      onClose={hideModalAddTask}
      task={null}
      dateReference={dateReference}
    />
  ), [dateReference, identifier, onActionDone, onActionError]);

  const [showModalAddAction, hideModalAddAction] = useModal(() => (
    <ModalAddAction
      identifier={identifier}
      onActionDone={onActionDone}
      onActionError={onActionError}
      onClose={hideModalAddAction}
      channel={channel}
      action={null}
      type={type !== 'TASK' ? type : 'REMINDER'}
      dateReference={dateReference}
    />
  ), [channel, dateReference, identifier, onActionDone, onActionError, type]);

  const handleSelectedChannel = React.useCallback((newChannel: Misc.Channel) => {
    setChannel(newChannel);
    showModalAddAction();
  }, [showModalAddAction]);

  const [showModalChooseChannel, hideModalChooseChannel] = useModal(() => (
    <ModalChooseChannel
      onSelectChannel={handleSelectedChannel}
      onClose={hideModalChooseChannel}
      type={type !== 'TASK' ? type : 'REMINDER'}
    />
  ), [handleSelectedChannel, type]);

  const handleSelectType = React.useCallback((selectedType: Misc.ActionType) => {
    setType(selectedType);
    if (selectedType === 'TASK') {
      showModalAddTask();
      return;
    }
    showModalChooseChannel();
  }, [showModalAddTask, showModalChooseChannel]);

  const [showModalChooseActionType, hideModalChooseActionType] = useModal(() => (
    <ModalChooseType
      onSelectType={handleSelectType}
      onClose={hideModalChooseActionType}
    />
  ), [handleSelectType]);

  return (
    <div>
      <ButtonWithUserRights
        action="CREATE"
        category="PREFERENCES_PLANS"
        variant="link"
        className="PlanAddActionButton"
        onClick={showModalChooseActionType}
      >
        {t('plans:add-action')}
      </ButtonWithUserRights>
    </div>
  );
};

export default SettingsPlan;
