import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { FetchAllParams, fetchAllRisks } from 'api/analysis';
import useFetch from 'hooks/useFetch';
import organizationStore from 'stores/Organization';
import type { AnalysisRiskChartData } from 'types/models';
import Loading from 'components/Loading';
import NoDataGraph from '../NoDataGraph';
import GraphSection from '../GraphSection';
import DebtChart from './DebtChart';

const Risk = (): JSX.Element => {
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;

  const {
    data,
    isLoading,
  } = useFetch<FetchAllParams, AnalysisRiskChartData>(
    { cacheKey: 'debtChart', organization: currentOrganization?.reference },
    fetchAllRisks,
  );

  const averageDelayIsEmpty = useMemo(() => {
    if (!data) {
      return true;
    }
    return !data.averageDelayOfPaymentByMonth.find((elem) => elem.day !== null);
  }, [data]);

  return (
    <div>
      <GraphSection
        title={t('analysis:risk.average-delay-evolution')}
        description={t('analysis:risk.description')}
      >
        {isLoading && <Loading />}
        {data && !averageDelayIsEmpty && (
          <DebtChart data={data} />
        )}
        {(!data || averageDelayIsEmpty) && !isLoading && (
          <NoDataGraph />
        )}
      </GraphSection>
    </div>
  );
};

export default observer(Risk);
