import './index.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { HistoryStepSummary } from 'types/models';
import ListItemButton from 'components/ListItemButton';
import StepIcon from 'components/StepIcon';
import ClientName from 'components/ClientName';

type Props = {
  data: HistoryStepSummary,
  onShowView(): void,
};

const HomeMyActionsItem = ({ data, onShowView }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { name, payRequest, group, client } = data;

  return (
    <ListItemButton onShowView={onShowView}>
      <div className="HomeMyActionsItem__header">
        <StepIcon data={data} />
        <div className="HomeMyActionsItem__name">
          {name}
          <div className="HomeMyActionsItem__pay-request">
            {group && (
              <span className="HomeMyActionsItem__pay-request__grouped-action">
                {t('reminders:dunning-group')},
              </span>
            )}
            {(!group && payRequest) && (
              <Link
                to={`/payment-request/${payRequest.id}`}
                className="HomeMyActionsItem__pay-request__link"
              >
                {payRequest.reference},
              </Link>
            )}
            <Link
              to={`/customer/${client.id}`}
              className="HomeMyActionsItem__pay-request__client"
              onClick={(e) => { e.stopPropagation(); }}
            >
              <ClientName client={client} />
            </Link>
          </div>
        </div>
      </div>
    </ListItemButton>
  );
};

export default HomeMyActionsItem;
