import type Misc from 'types/misc';
import type { PayRequestOrPayment } from 'types/models';
import Request from 'utils/request';

export type CustomerAccountDetailsFilters = {
  debits: boolean | null,
  credits: boolean | null,
  current: boolean | null,
  balanced: boolean | null,
  future: boolean | null,
};

export type FetchAllForCustomerParams = {
  organization: string | undefined,
  customerId: number,
  fetchOptions: {
    pageIndex: number,
    pageSize: number,
    filters: CustomerAccountDetailsFilters,
  },
};

const fetchAllForCustomer = async (params: FetchAllForCustomerParams) => {
  const { organization, customerId, fetchOptions } = params;
  if (!organization) {
    throw new Error('FetchAllForCustomerPaymentPayRequest: Missing organization.');
  }

  const { pageIndex, pageSize, filters } = fetchOptions;
  const queryData = new URLSearchParams();
  queryData.append('recordsPerPage', pageSize.toString());
  queryData.append('page', (pageIndex + 1).toString());

  Object.entries(filters).forEach(([key, value]) => {
    if (value === null) {
      return;
    }
    queryData.append(`filters[${key}]`, value ? '1' : '0');
  });

  const result = await Request.get<Misc.Listing<PayRequestOrPayment>>(
    `client/${organization}/${customerId}/payments?${queryData.toString()}`,
  );
  return result;
};

// eslint-disable-next-line import/prefer-default-export
export { fetchAllForCustomer };
