import './index.scss';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import type { OrganizationHeaderSummary } from 'types/models';
import organizationStore from 'stores/Organization';
import getUploadImageUrl from 'utils/getUploadImageUrl';
import Icon from 'components/Icon';
import Button from 'components/Button';

type Props = {
  isOpened: boolean,
  organization: OrganizationHeaderSummary,
};

const OrganizationItemTitle = ({ organization, isOpened }: Props): JSX.Element => {
  const { reference, name, logo, isInProduction } = organization;
  const { setCurrent } = organizationStore;
  const { t } = useTranslation();
  const history = useHistory();
  const logoUrl = useMemo(() => !!logo ? getUploadImageUrl(logo) : null, [logo]);

  const handleSelectOrganisation = useCallback(async () => {
    await setCurrent(reference);
    history.push('/');
  }, [history, reference, setCurrent]);

  return (
    <Button
      variant="list"
      onClick={handleSelectOrganisation}
      className="OrganizationSelectorItemTitle"
    >
      {!!logoUrl && (
        <img className="OrganizationSelectorItemTitle__logo" src={logoUrl} alt="" />
      )}
      <h3 className="OrganizationSelectorItemTitle__name">{name}</h3>
      {!isInProduction && (
        <span className="OrganizationSelectorItemTitle__not-prod">
          {t('common:demo-organization')}
        </span>
      )}
      <Icon
        name={isOpened ? 'caret-up' : 'caret-down'}
        className="OrganizationSelectorItemTitle__open-icon"
      />
    </Button>
  );
};

export default observer(OrganizationItemTitle);
