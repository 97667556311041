import './index.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import organizationStore from 'stores/Organization';
import useOnClickOutside from 'hooks/useOnClickOutside';
import SearchInput from 'components/SearchInput';
import SearchResults from './Results';

const HeaderSearch = (): JSX.Element => {
  const { t } = useTranslation();
  const searchRef = useRef<HTMLDivElement>(null);
  const { currentOrganization } = organizationStore;
  const [showResults, setShowResults] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEmptySearchResults, setIsEmptySearchResults] = useState<boolean>(true);

  const resetSearch = useCallback(() => {
    setShowResults(false);
  }, []);

  useEffect(() => {
    if (!isEmptySearchResults) {
      setShowResults(true);
    }
  }, [isEmptySearchResults]);

  useEffect(() => {
    resetSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization]);

  const handleFocus = useCallback(
    ({ isLongEnough }: { isLongEnough: boolean }) => {
      setShowResults(isLongEnough);
    },
    [],
  );

  const handleCloseResults = useCallback(() => {
    setShowResults(false);
  }, []);

  useOnClickOutside(searchRef, handleCloseResults);

  return (
    <div className="HeaderSearch" ref={searchRef}>
      <SearchInput
        placeholder={t('common:search-bar.placeholder')}
        isLoading={isLoading}
        onChange={(value) => { setSearch(value || ''); setShowResults(true); }}
        onFocus={handleFocus}
        onReset={resetSearch}
      />
      {search && showResults && (
        <SearchResults
          onLoadingChange={setIsLoading}
          onEmptySearchChange={setIsEmptySearchResults}
          search={search}
        />
      )}
    </div>
  );
};

export default observer(HeaderSearch);
