import './index.scss';
import { observer } from 'mobx-react';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import Misc from 'types/misc';
import ListStatsByPeriodItemPercent from './ItemPercent';
import ListStatsByPeriodItemBar from './ItemBar';

type Props = {
  title: string,
  count: number,
  percent: number,
  periods: Misc.ListStatItem[],
};

const ListStatsByPeriod = (props: Props): JSX.Element | null => {
  const { title, count, percent, periods } = props;
  const { linesOfBusiness } = organizationStore;
  const { ct } = useContextualTranslation(linesOfBusiness);

  if (count === 0) {
    return null;
  }

  return (
    <div className="ListStatsByPeriod">
      <div className="ListStatsByPeriod__content">
        <div className="ListStatsByPeriod__content__periods-percent-bar">
          <h5 className="ListStatsByPeriod__content__title">
            {title}
          </h5>
          {periods.map((period) => (
            <ListStatsByPeriodItemBar period={period} key={period.id} />
          ))}
          <p className="ListStatsByPeriod__content__count">
            {ct('home:bill-in-total', { count })}
          </p>
          <p className="ListStatsByPeriod__content__percent">
            {ct('home:bill-in-percent', { count: percent })}
          </p>
        </div>
        <ul className="ListStatsByPeriod__content__periods-percent">
          {periods.map((period) => (
            <ListStatsByPeriodItemPercent period={period} key={period.id} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default observer(ListStatsByPeriod);
