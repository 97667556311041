import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import type { TFunction } from 'i18next';
import type { Column } from 'react-table';
import type { Contact } from 'types/models';
import recomposeName from 'utils/recomposeName';
import Button from 'components/Button';
import ContactChannelsIcons from 'components/ContactChannelsIcons';
import { UserRightsManagerController } from 'components/UserRights';
import { ExternallyMonitoredController } from 'components/ExternallyMonitored';
import RowActions from '../Actions/Row';

const ContactsColumns = (
  ct: TFunction,
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
  onHandleShowView: (contactId: number) => void,
): Column<Contact>[] => [
  {
    Header: ct<string>('common:last-name'),
    accessor: 'lastName',
    Cell: ({ value, row }) => (
      <Button
        onClick={() => onHandleShowView(row.original.id)}
        variant="link"
        className="Contacts__columns__link"
      >
        {`${value}`}
      </Button>
    ),
  },
  {
    Header: ct<string>('common:first-name'),
    accessor: 'firstName',
    Cell: ({ value }) => <Fragment>{value}</Fragment>,
  },
  {
    Header: ct<string>('common:client'),
    accessor: 'client',
    id: 'clientName',
    Cell: ({ row }) => (
      <Link
        to={`/customer/${row.original.client.id}`}
        className="Contacts__columns__link"
      >
        {recomposeName(row.original.client)}
      </Link>
    ),
  },
  {
    Header: ct<string>('common:client-reference'),
    accessor: 'client',
    id: 'customerReference',
    Cell: ({ value }) => <Fragment>{value.reference}</Fragment>,
  },
  {
    Header: ct<string>('common:role'),
    accessor: 'contactRole',
    Cell: ({ value }) => <Fragment>{value.value}</Fragment>,
  },
  {
    Header: ct<string>('common:email'),
    accessor: 'email',
    Cell: ({ value }) => (
      <a href={`mailto:${value}`} className="Contacts__columns__link">
        {value}
      </a>
    ),
  },
  {
    Header: ct<string>('common:channels'),
    accessor: 'address1',
    disableSortBy: true,
    Cell: ({ row }) => (
      <ContactChannelsIcons contact={row.original} />
    ),
  },
  {
    Header: ct<string>('common:commands'),
    accessor: 'id',
    disableSortBy: true,
    Cell: ({ row, value: id }) => (
      <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
        <UserRightsManagerController action="UPDATE" managers={row.original.users} categoryType="CONTACTS">
          <RowActions
            id={id}
            name={recomposeName(row.original) ?? ''}
            onActionDone={onActionDone}
            onActionError={onActionError}
          />
        </UserRightsManagerController>
      </ExternallyMonitoredController>
    ),
  },
];

export default ContactsColumns;
