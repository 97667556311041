import React from 'react';
import equal from 'deep-equal';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import type { Occupation } from 'types/models';
import type Errors from 'types/errors';
import organizationStore from 'stores/Organization';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import Confirm from 'components/Confirm';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import MyOrganizationEditForm from './MyOrganizationEditForm';

export type UserCurrentOrganization = {
  nameFunction: string,
  identifier: string,
  roles: string,
};

interface Props {
  onClose: () => void,
  onShowToast: (message: string, type: ToastStatus) => void,
}

const ModalEditMyOrganization: React.FC<Props> = ({ onClose, onShowToast }) => {
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const isMountedRef = useIsMountedRef();
  const initialData = React.useRef<UserCurrentOrganization | null>(null);

  const mapFormData = React.useCallback(
    (rawData: ModalFormData): UserCurrentOrganization => ({
      nameFunction: rawData?.nameFunction as string,
      identifier: rawData?.identifier as string,
      roles: rawData?.roles as string,
    }),
    [],
  );

  const [occupation, setOccupation] = React.useState<Occupation>();
  const [hasChanges, setHasChanges] = React.useState<boolean>(false);
  const [validationErrors, setValidationErrors] = React.useState<Errors.Validation | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = React.useState<boolean>(false);

  const handleInit = React.useCallback((formData: ModalFormData | null) => {
    initialData.current = formData ? mapFormData(formData) : null;
  }, [initialData, mapFormData]);

  const closeSelf = React.useCallback(() => {
    onClose();
    setShowCancelConfirm(false);
    setHasChanges(false);
  }, [onClose]);

  const handleChange = React.useCallback(
    (formData: ModalFormData | null) => {
      setHasChanges(!!formData && !equal(initialData.current, mapFormData(formData)));
    },
    [initialData, mapFormData],
  );

  const handleCancel = React.useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirm(true);
    } else {
      closeSelf();
    }
  }, [hasChanges, closeSelf]);

  const {
    get,
    put,
    isLoading,
    cancel,
    error,
  } = useApiRequest();

  const fetchOccupation = React.useCallback(
    async () => {
      if (!currentOrganization) {
        return;
      }

      const response = await get<Occupation>(
        `occupation/${currentOrganization.id}`,
      );

      if (isMountedRef.current && response) {
        setOccupation(response);
      }
    },
    [currentOrganization, get, isMountedRef],
  );

  const handleSubmit = React.useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData || !currentOrganization) {
        return;
      }

      const result = await put<Occupation>(`occupation/manage/${currentOrganization.id}`, mapFormData(formData));

      if (!isMountedRef.current) {
        return;
      }

      if (result?.errors) {
        setValidationErrors(result.errors);
        return;
      }

      if (result?.identifier) {
        organizationStore.refresh();
        onShowToast(t('users:toast.success.settings-update'), 'success');
        onClose();
      }
    },
    [currentOrganization, mapFormData, put, isMountedRef, onShowToast, onClose, t],
  );

  React.useEffect(() => {
    fetchOccupation();
    return () => { cancel(); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization]);

  return (
    <ModalForm
      title={t('users:in-my-organization')}
      className="ModalEditMyOrganization"
      isOpened
      onInit={handleInit}
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
    >
      {error && <ErrorMessage error={error} />}
      <MyOrganizationEditForm
        defaultData={occupation}
        errors={validationErrors}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={() => { closeSelf(); }}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(ModalEditMyOrganization);
