import { useCallback } from 'react';
import { observer } from 'mobx-react';
import organizationStore from 'stores/Organization';
import WalletItem from './Item';

const OrganizationItemWallet = (): JSX.Element => {
  const { walletCategoriesValues, walletSelectedValues } = organizationStore;

  const isSelected = useCallback((walletId: number) => (
    walletSelectedValues.includes(walletId)
  ), [walletSelectedValues]);

  return (
    <div className="OrganizationItemWallet">
      {walletCategoriesValues.map((categoryValue) => (
        <WalletItem
          key={categoryValue.id}
          data={categoryValue}
          isSelected={isSelected(categoryValue.id)}
        />
      ))}
    </div>
  );
};

export default observer(OrganizationItemWallet);
