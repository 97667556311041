import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';
import Config from 'config';
import useFetch from 'hooks/useFetch';
import { fetchOne, FetchOneParams } from 'api/stripe';
import organizationStore from 'stores/Organization';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Loading from 'components/Loading';
import ToastNotification from 'components/ToastNotification';
import useToast from 'components/ToastNotification/useToast';
import SepaMandateLink from './SepaMandateLink';
import StripeModalForm from './StripeModalForm';

loadStripe.setLoadParameters({ advancedFraudSignals: false });
const stripePromise = loadStripe(Config.STRIPE_TOKEN || '');

const SEPAMandateAcceptance: React.FC = () => {
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;

  const {
    isToastShowed,
    showToast,
    hideToast,
    toastStatus,
    toastText,
  } = useToast();

  const { isFetching, data, refetch } = useFetch<FetchOneParams, any>(
    { cacheKey: 'organizationStripe', organization: currentOrganization?.reference },
    fetchOne,
    { refetchOnWindowFocus: false },
  );

  const [isOpened, setIsOpened] = React.useState<boolean>(false);

  const handleComplete = React.useCallback(() => {
    showToast(t('organizations:sepa-mandate.thanks'), 'success');
    refetch();
    setIsOpened(false);
  }, [showToast, refetch, t]);

  return (
    <div className="SEPAMandateAcceptance">
      <dt className="SEPAMandateAcceptance__label">
        {t('organizations:sepa-mandate.label')}
      </dt>
      <dd className="SEPAMandateAcceptance__stripe">
        {isFetching && <Loading hasNoText />}
        {!isFetching && (
          <>
            <SepaMandateLink data={data} />
            {(data && data.customerId && !data.sourceId) && (
              <Button onClick={() => { setIsOpened(true); }}>
                {t('organizations:sepa-mandate.accept-button')}
              </Button>
            )}
          </>
        )}
      </dd>
      <Elements stripe={stripePromise}>
        <Modal
          isOpened={isOpened}
          title={t('organizations:sepa-mandate.label')}
          onClose={() => { setIsOpened(false); }}
        >
          <StripeModalForm onComplete={handleComplete} />
        </Modal>
      </Elements>
      <ToastNotification
        text={toastText}
        status={toastStatus}
        isShow={isToastShowed}
        onClose={hideToast}
      />
    </div>
  );
};

export default observer(SEPAMandateAcceptance);
