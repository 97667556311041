import './index.scss';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import type { Plan } from 'types/models';
import type { FetchAllParams } from 'api/plans';
import { fetchAll } from 'api/plans';
import useApiRequest from 'hooks/useApiRequest';
import useFetch from 'hooks/useFetch';
import organizationStore from 'stores/Organization';
import FormRadios from 'components/FormRadios';
import type { FormRadioValue } from 'components/FormRadios/Item';
import FormSelect from 'components/FormSelect';
import FormGroup from 'components/FormGroup';

type Props = {
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const SettingsSelectors = ({ onActionError, onActionDone }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { useGroupByDefault, currentOrganization } = organizationStore;

  const { data, refetch } = useFetch<FetchAllParams, Plan[]>(
    {
      cacheKey: 'organizationPlans',
      organization: currentOrganization?.reference,
      isDefault: true,
    },
    fetchAll,
    { enabled: !!currentOrganization },
  );

  const { put } = useApiRequest();

  const handleSubmitReminderMode = useCallback(async (newValue: FormRadioValue) => {
    const result = await put(`/organization/manage/${currentOrganization?.reference}/is-group/${newValue}`);
    if (result?.errors) {
      onActionError(t('errors:unknown-retry'));
      return;
    }
    onActionDone(t(
      newValue === '1'
        ? 'selectors:update-grouped-by-default'
        : 'selectors:update-solo-by-default',
    ));
    organizationStore.refresh();
  }, [currentOrganization, onActionDone, onActionError, put, t]);

  const handleSubmitDefaultPlan = useCallback(async (
    name: string, newValue: string | null, group: boolean,
  ) => {
    const result = await put(`/manage-plans/${currentOrganization?.reference}`, {
      identifier: newValue,
      name,
      group,
      dateReferenceIsToday: true,
      default: true,
      planChange: null,
    });
    if (result?.errors) {
      onActionError(t('errors:unknown-retry'));
      return;
    }
    onActionDone(t(
      group
        ? 'selectors:update-grouped-selector'
        : 'selectors:update-solo-selector',
    ));
    refetch();
  }, [currentOrganization, onActionDone, onActionError, put, refetch, t]);

  const defaultSoloPlan = useMemo(() => {
    if (!data || !data.length) {
      return null;
    }
    return data.find(({ isGroup }) => !isGroup)?.identifier || null;
  }, [data]);

  const defaultGroupedPlan = useMemo(() => {
    if (!data || !data.length) {
      return null;
    }
    return data.find(({ isGroup }) => isGroup)?.identifier || null;
  }, [data]);

  return (
    <div className="SelectorsDefaults">
      <div className="SelectorsDefaults__row">
        <div className="SelectorsDefaults__row__box">
          <h4 className="SelectorsDefaults__row__box__subtitle">
            {t('selectors:default-reminder-mode')}
          </h4>
          <p className="SelectorsDefaults__row__box__description">
            {t('selectors:default-reminder-description')}
          </p>
          <FormRadios
            name="defaultReminder"
            defaultValue={useGroupByDefault ? '1' : '0'}
            items={[
              { value: '0', label: t('selectors:default-reminder-radios.individual') },
              { value: '1', label: t('selectors:default-reminder-radios.group') },
            ]}
            onChange={handleSubmitReminderMode}
          />
        </div>
        <div className="SelectorsDefaults__row__box">
          <h4 className="SelectorsDefaults__row__box__subtitle">
            {t('selectors:default-scenario')}
          </h4>
          <p className="SelectorsDefaults__row__box__description">
            {t('selectors:default-scenario-description')}
          </p>
          <FormGroup label={t('selectors:default-scenario-solo')}>
            <FormSelect
              name="dunningPlan"
              value={defaultSoloPlan}
              placeholder={t('common:please-choose')}
              fetchEntity="plans"
              onSelect={(name, value) => { handleSubmitDefaultPlan(name, value, false); }}
              isAsync
            />
          </FormGroup>
          <FormGroup label={t('selectors:default-scenario-grouped')}>
            <FormSelect
              name="dunningPlan"
              value={defaultGroupedPlan}
              placeholder={t('common:please-choose')}
              fetchEntity="plans"
              onSelect={(name, value) => { handleSubmitDefaultPlan(name, value, true); }}
              fetchEntityQuerystring="group=1"
              isAsync
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

export default observer(SettingsSelectors);
