import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import currenciesStore from 'stores/Currencies';
import type Misc from 'types/misc';
import type { Currency } from 'types/models';
import type Errors from 'types/errors';
import FormGroup from 'components/FormGroup';
import InputGroup from 'components/InputGroup';
import FormControl from 'components/FormControl';
import FormSelect from 'components/FormSelect';
import ErrorValidationMessage from 'components/ErrorValidationMessage';

interface Props {
  amountFormName?: string,
  currencyFormName?: string,
  defaultCurrency?: Misc.CurrencyCode | null,
  defaultAmount?: string | number | string[],
  maxAmount?: number,
  amount?: string | number,
  currency?: Misc.CurrencyCode,
  onChangeAmount?: (amount: string) => void,
  onChangeCurrency?: (currency: Misc.CurrencyCode) => void,
  hasCurrencyDisabled?: boolean,
  className?: string,
  currencyError?: Errors.ValidationItem | null,
  amountError?: Errors.ValidationItem | null,
  shouldDisplayErrorUnderInput?: boolean,
}

const FormAmountCurrency: React.FC<Props> = (props) => {
  const {
    currencyFormName = 'currency',
    amountFormName = 'amount',
    defaultAmount,
    defaultCurrency,
    onChangeAmount,
    onChangeCurrency,
    hasCurrencyDisabled = false,
    amount,
    currency,
    className,
    amountError,
    maxAmount,
    currencyError,
    shouldDisplayErrorUnderInput = false,
  } = props;

  const { t } = useTranslation();
  const {
    currencies,
    currenciesOptions,
    isBusy,
    getAll,
  } = currenciesStore;

  const [activeCurrency, setActiveCurrency] = React.useState<Misc.CurrencyCode | null>(
    currency || defaultCurrency || null,
  );
  const hasRefetched = React.useRef<boolean>(false);

  React.useMemo(() => {
    if (!currenciesOptions && !isBusy && !hasRefetched.current) {
      getAll();
      hasRefetched.current = true;
    }
  }, [currenciesOptions, getAll, isBusy]);

  const currentCurrency = React.useMemo<Currency | undefined>(
    () => currencies?.find(
      (currencyData: Currency) => currencyData.code === activeCurrency,
    ),
    [activeCurrency, currencies],
  );

  React.useEffect(() => {
    if (currency) {
      setActiveCurrency(currency);
    }
  }, [currency]);

  const classNames = React.useMemo(() => (
    classnames('FormAmountCurrency', className, {
      'FormAmountCurrency--with-error-under-input': shouldDisplayErrorUnderInput
        && (currencyError || amountError),
    })
  ), [amountError, className, currencyError, shouldDisplayErrorUnderInput]);

  const isAmountBigger = React.useMemo(() => (
    (amount !== undefined && maxAmount !== undefined && parseInt(amount.toString(), 10) > maxAmount)
    || false
  ), [amount, maxAmount]);

  const handleChangeCurrency = React.useCallback(
    (noopname: string, newValue: string | null) => {
      setActiveCurrency(newValue as Misc.CurrencyCode | null);
      if (onChangeCurrency) {
        onChangeCurrency(newValue as Misc.CurrencyCode);
      }
    },
    [onChangeCurrency],
  );

  const handleChangeAmount = React.useCallback((newValue: string) => {
    if (onChangeAmount) {
      onChangeAmount(newValue);
    }
  }, [onChangeAmount]);

  return (
    <div className={classNames}>
      <div className="FormAmountCurrency__container">
        {!shouldDisplayErrorUnderInput && (
          <>
            <ErrorValidationMessage error={amountError} />
            <ErrorValidationMessage error={currencyError} />
          </>
        )}
        <div className="FormAmountCurrency__container__forms">
          <FormGroup
            label={t('common:amount')}
            mandatory
            className="FormAmountCurrency__container__forms__amount"
          >
            <InputGroup
              helper={currentCurrency?.symbol}
              helperPosition={currentCurrency && currentCurrency.isBefore ? 'left' : 'right'}
              isInvalid={!!amountError}
            >
              <FormControl
                type="number"
                step={0.01}
                name={amountFormName}
                value={amount === undefined ? undefined : amount}
                autoComplete="off"
                onChange={handleChangeAmount}
                defaultValue={amount !== undefined ? undefined : defaultAmount}
                isInvalid={!!amountError}
                hasWarning={isAmountBigger}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup className="FormAmountCurrency__container__forms__currency">
            {currenciesOptions && (
              <FormSelect
                className="FormAmountCurrency__container__forms__currency__field"
                name={currencyFormName}
                value={currency || activeCurrency}
                defaultValue={currency || activeCurrency}
                onSelect={handleChangeCurrency}
                withClearButton={false}
                disabled={hasCurrencyDisabled}
                selectOptions={currenciesOptions.map(({ value }) => ({ value, label: value }))}
                isInvalid={!!currencyError}
              />
            )}
          </FormGroup>
        </div>
        {isAmountBigger && (
          <p className="FormAmountCurrency__container__warning">{t('payments:amount-bigger')}</p>
        )}
      </div>
      {shouldDisplayErrorUnderInput && (
        <>
          <ErrorValidationMessage error={amountError} />
          <ErrorValidationMessage error={currencyError} />
        </>
      )}
    </div>
  );
};

export default observer(FormAmountCurrency);
