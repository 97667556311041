import './index.scss';
import { useContext, useMemo, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
// import { useModal } from 'react-modal-hook';
import Config from 'config';
import type Misc from 'types/misc';
import type { HistoryStep } from 'types/models';
import { fetchHistory } from 'api/customers';
import type { FetchHistoryParams } from 'api/customers';
import dateIsToday from 'utils/dateIsToday';
import dateIsLastTwoDays from 'utils/dateIsLastTwoDays';
import formatIntlDate from 'utils/formatIntlDate';
import organizationStore from 'stores/Organization';
import useFetchPaginated from 'hooks/useFetchPaginated';
import customerContext from 'pages/Customer/customerContext';
import Loading from 'components/Loading';
// import Button from 'components/Button';
// import Icon from 'components/Icon';
import ErrorMessage from 'components/ErrorMessage';
import Pagination from 'components/Pagination';
import CustomerMainLayout from '../Layout';
// import CustomerDownloadHistoryModal from './CustomerDownloadHistoryModal';
import Filters from './Filters';
import CustomerHistoryDayList from './DayList';

type ItemByDate = {
  title: string,
  items: HistoryStep[],
};

const DEFAULT_FILTERS = {
  type: ['sent_reminder', 'task_processed', 'sent_error_reminder', 'pay_request', 'payment', 'note', 'data'],
};

const getDefaultTypeFilters = (search: string): string[] => {
  const queryFilters = new URLSearchParams(search.replace('?', ''));
  const filters = queryFilters.getAll('type');
  if (filters.length > 0) {
    return filters;
  }
  return DEFAULT_FILTERS.type;
};

const CustomerHistory = (): JSX.Element => {
  const { data: customer, reloadTick, onActionDone } = useContext(customerContext);
  const customerId = customer?.id;
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const { search } = useLocation();
  const [pageSize, setPageSize] = useState<number>(Config.DEFAULT_PAGE_SIZE);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [filtering, setFiltering] = useState<Misc.Filter[]>(() => [{
    name: 'type',
    value: getDefaultTypeFilters(search),
  }]);

  const {
    isLoading,
    data,
    serverPagination,
    error,
    refetch,
  } = useFetchPaginated<FetchHistoryParams, HistoryStep>(
    {
      cacheKey: 'customerHistory',
      organization: currentOrganization?.reference,
      customerId,
      fetchOptions: { pageIndex, pageSize, filtering },
    },
    fetchHistory,
    { staleTime: 5000 },
  );

  const handleNextPage = useCallback(() => {
    setPageIndex((prevIndex) => prevIndex + 1);
  }, []);

  const handlePreviousPage = useCallback(() => {
    setPageIndex((prevIndex) => prevIndex - 1);
  }, []);

  const handleGotoPage = useCallback((page: number) => {
    setPageIndex(page);
  }, []);

  useEffect(() => {
    refetch();
  }, [refetch, reloadTick]);

  // const [showDownloadModal, hideDownloadModal] = useModal(() => (
  //   <CustomerDownloadHistoryModal
  //     customerId={customerId}
  //     onClose={hideDownloadModal}
  //   />
  // ), [customerId]);

  const handleChangeFilters = useCallback((filters: Misc.Filter[]) => {
    setFiltering(filters);
  }, []);

  const handleActionDone = useCallback((message: string) => {
    if (onActionDone) {
      onActionDone(message);
    }
  }, [onActionDone]);

  const pageCount = useMemo(() => serverPagination?.totalPages ?? 1, [serverPagination]);

  const itemsByDate = useMemo(() => {
    const itemsMap = new Map<string, ItemByDate>();
    if (!data) {
      return itemsMap;
    }

    data.forEach((item) => {
      const { at, doneAt, remove, validation } = item;
      const displayDate = doneAt ?? remove?.at ?? validation?.at ?? at.at;
      const day = displayDate.substring(0, 10);

      let title = formatIntlDate(displayDate, 'dateLong');
      if (dateIsToday(day)) {
        title = t('common:today');
      } else if (dateIsLastTwoDays(displayDate)) {
        title = t('common:yesterday');
      }

      let items = [item];
      if (itemsMap.has(day)) {
        title = itemsMap.get(day)!.title;
        const prevItems = itemsMap.get(day)!.items;
        items = [...prevItems, item];
      }

      items.sort((itemA, itemB) => {
        const { at: atA, doneAt: doneAtA, remove: removeA, validation: validationA } = itemA;
        const dateA = new Date(doneAtA ?? removeA?.at ?? validationA?.at ?? atA.at);
        const { at: atB, doneAt: doneAtB, remove: removeB, validation: validationB } = itemB;
        const dateB = new Date(doneAtB ?? removeB?.at ?? validationB?.at ?? atB.at);
        if (dateA == dateB) {
          return 0;
        }
        return dateA < dateB ? 1 : -1;
      });

      itemsMap.set(day, { title, items });
    });

    return itemsMap;
  }, [data, t]);

  const recentItemsCount = useMemo(() => (
    data?.reduce((prevCount, { at, doneAt, remove, validation }) => {
      const usedDate = doneAt ?? remove?.at ?? validation?.at ?? at.at;
      return dateIsLastTwoDays(usedDate) ? prevCount + 1 : prevCount;
    }, 0) ?? 0
  ), [data]);

  return (
    <CustomerMainLayout
      sidebar={(
        <Filters
          onChange={handleChangeFilters}
          defaultFilters={getDefaultTypeFilters(search)}
        />
      )}
    >
      <div className="CustomerHistory">
        <div className="CustomerHistory__header">
          <h2 className="CustomerHistory__title">
            {t('common:history')}
          </h2>
          {/* <Button
            variant="link"
            className="CustomerHistory__download"
            onClick={showDownloadModal}
          >
            <Icon name="download" />
            {t('common:download')}
          </Button> */}
          {recentItemsCount > 0 && (
            <div className="CustomerHistory__recent-count">
              {t('common:count-recent-events', { count: recentItemsCount })}
            </div>
          )}
        </div>
        {isLoading && <Loading />}
        {data && Array.from(itemsByDate).map(([day, { title, items }]) => (
          <CustomerHistoryDayList
            key={day}
            title={title}
            items={items}
            onActionDone={handleActionDone}
            onActionError={handleActionDone}
          />
        ))}
        {(!isLoading && (!data || data.length === 0)) && (
          <p className="CustomerHistory__no-data">
            {t('common:no-data-was-found')}
          </p>
        )}
        {(serverPagination && pageCount > 1) && (
          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            canPreviousPage={pageIndex > 0}
            canNextPage={pageIndex < (pageCount - 1)}
            pageCount={pageCount}
            totalRecords={serverPagination.totalRecords}
            nextPage={handleNextPage}
            previousPage={handlePreviousPage}
            onChangePageSize={setPageSize}
            onGotoPage={handleGotoPage}
          />
        )}
        {error && <ErrorMessage error={error} />}
      </div>
    </CustomerMainLayout>
  );
};

export default CustomerHistory;
