import type Misc from 'types/misc';

const getI18nTypeImport = (type: Misc.TypeImport): string => {
  const i18nTypeImport = {
    CLIENT: 'imports:type-import.client',
    CONTACT: 'imports:type-import.contact',
    PAY_REQUEST_CREATE: 'imports:type-import.pay-request-create',
    PAY_REQUEST_UPDATE: 'imports:type-import.pay-request-update',
    CLIENT_CONTACT: 'imports:type-import.client-contact',
    PAYMENT: 'imports:type-import.payment',
  };

  return i18nTypeImport[type];
};

export default getI18nTypeImport;
