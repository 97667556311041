import './index.scss';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import SendingCustomerPortalEdit from './Edit';

interface Props {
  data: boolean,
  title: string,
  name: string,
  onShowToast: (message: string, status: ToastStatus) => void,
}

const SendingCustomerPortal = (props: Props): JSX.Element | null => {
  const {
    data,
    title,
    name,
    onShowToast,
  } = props;

  const { t } = useTranslation();
  const [showModalEdit, hideModalEdit] = useModal(() => (
    <SendingCustomerPortalEdit
      data={data}
      title={title}
      name={name}
      onClose={hideModalEdit}
      onShowToast={onShowToast}
    />
  ), [data, title, name, onShowToast]);

  return (
    <div className="SendingCustomerPortal">
      <div className="SendingCustomerPortal__value">
        {data ? t('common:yes') : t('common:no')}
      </div>
      <ButtonWithUserRights
        action="UPDATE"
        category="PREFERENCES_CUSTOMIZATION_SEND"
        variant="link"
        className="SendingActions__button"
        onClick={showModalEdit}
      >
        {t('common:edit')}
      </ButtonWithUserRights>
    </div>
  );
};

export default SendingCustomerPortal;
