import type { TFunction } from 'i18next';
import Config from 'config';
import type Misc from 'types/misc';
import getI18nChannel from 'utils/getI18nChannel';
import getI18nActionSelectStatus from 'utils/getI18nActionSelectStatus';

const ActionsFilters = (
  ct: TFunction,
  filters: Misc.Filter[],
): Misc.FilterDeclarationItem[] => [
  {
    title: ct('common:type'),
    emptyText: ct('common:filters-selected.all-tasks'),
    key: 'type',
    type: 'select',
    selectData: Config.STATUSES_ID.map((status) => (
      { value: status, label: ct(getI18nActionSelectStatus(status)) }
    )),
    isNotRemovable: true,
    defaultValue: filters.find(({ name }) => name === 'type')?.value || undefined,
  },
  {
    title: ct('common:period'),
    emptyText: ct('common:filters-selected.all-periods'),
    key: 'period',
    type: 'select',
    selectData: Config.PERIODS_ID
      .filter((period) => !['futur', 'next15days'].includes(period))
      .map((period) => (
        { value: period, label: ct(`common:select-period.${period}`) }
      )),
    isNotRemovable: true,
    defaultValue: filters.find(({ name }) => name === 'period')?.value || 'today',
  },
  {
    title: ct('common:select-period.from'),
    key: 'periodA',
    type: 'dateInput',
  },
  {
    title: ct('common:select-period.to'),
    key: 'periodB',
    type: 'dateInput',
  },
  {
    title: ct('common:client'),
    emptyText: ct('clients:all-clients'),
    key: 'client',
    type: 'selectClient',
    defaultValue: filters.find(({ name }) => name === 'client')?.value || undefined,
  },
  {
    title: ct('common:manager'),
    emptyText: ct('payment-requests:filters.all-managers'),
    key: 'manager',
    type: 'selectAsync',
    fetchEntity: 'users',
  },
  {
    title: ct('common:channel'),
    emptyText: ct('common:filters-selected.all-channels'),
    key: 'channel',
    type: 'select',
    selectData: Config.CHANNELS_ID.map((status) => (
      { value: status, label: ct(getI18nChannel(status)) }
    )),
  },
  {
    title: ct('clients:filters.dunning-plans'),
    key: 'dunningPlan',
    type: 'selectScenario',
    emptyText: ct('clients:filters.all-dunning-plans'),
  },
  {
    title: ct('actions:filters.min-total-debt'),
    key: 'amountDueFrom',
    type: 'numberInput',
    defaultValue: filters.find(({ name }) => name === 'amountDueFrom')?.value ?? undefined,
    withCurrencyAddon: true,
  },
  {
    title: ct('actions:filters.max-total-debt'),
    key: 'amountDueTo',
    type: 'numberInput',
    defaultValue: filters.find(({ name }) => name === 'amountDueTo')?.value ?? undefined,
    withCurrencyAddon: true,
  },
  {
    title: ct('actions:filters.min-due-debt'),
    key: 'dueDebtFrom',
    type: 'numberInput',
    defaultValue: filters.find(({ name }) => name === 'totalDebt')?.value ?? undefined,
    withCurrencyAddon: true,
  },
  {
    title: ct('actions:filters.max-due-debt'),
    key: 'dueDebtTo',
    type: 'numberInput',
    defaultValue: filters.find(({ name }) => name === 'totalDebt')?.value ?? undefined,
    withCurrencyAddon: true,
  },
  {
    title: ct('actions:filters.attribute'),
    emptyText: ct('common:please-choose'),
    key: 'attribute',
    type: 'selectAsync',
    fetchEntity: 'eavs/client',
  },
  {
    title: ct('actions:filters.attribute-value'),
    key: 'attributeValue',
    type: 'textInput',
    defaultValue: filters.find(({ name }) => name === 'attributeValue')?.value ?? undefined,
  },
  {
    title: ct('common:searching'),
    key: 'search',
    type: 'custom',
    isHidden: true,
  },
];

export default ActionsFilters;
