import type { Client } from 'types/models';
import MuteButton from './Mute';
import UnMuteButton from './UnMute';

export type CustomerMuteButtonProps = {
  onActionDone?(title: string, message: string): void,
  onActionError?(message: string): void,
  customerData: Client,
  showText?: boolean,
};

const CustomerMuteButton = (props: CustomerMuteButtonProps): JSX.Element => {
  const { customerData } = props;
  const isMuted = customerData.mute ?? false;

  return isMuted
    ? <UnMuteButton {...props} />
    : <MuteButton {...props} />;
};

export default CustomerMuteButton;
