import './index.scss';
import { useTranslation } from 'react-i18next';
import getColorPeriod from 'utils/getColorPeriod';
import getI18nPeriod from 'utils/getI18nPeriod';
import Misc from 'types/misc';

type Props = {
  period: Misc.ListStatItemPercentPeriod,
};

const ListStatsByPeriodItemPercent = ({ period }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <li className="ListStatsByPeriodItemPercent">
      <span
        className="ListStatsByPeriodItemPercent__bullet"
        style={{ backgroundColor: getColorPeriod(period.id) }}
      />
      <span className="ListStatsByPeriodItemPercent__name">
        {t(getI18nPeriod(period.id))}
      </span>
      <span className="ListStatsByPeriodItemPercent__percent">
        {Number(period.percentage).toFixed(2)}%
      </span>
    </li>
  );
};

export default ListStatsByPeriodItemPercent;
