import './index.scss';
import { createContext } from 'react';
import { Switch } from 'react-router-dom';
import { AnalyzableRoute } from 'components/Routes';
import type { ReportFile } from 'types/models';
import LinesList from './LinesList';
import Header from './Header';

export type LineType = 'error' | 'created' | 'updated' | 'skipped';

type Props = {
  file: ReportFile,
};

export const ReportFileContext = createContext<ReportFile | null>(null);

const ImportsReportDetailFile = ({ file }: Props): JSX.Element => {
  return (
    <ReportFileContext.Provider value={file}>
      <div className="ImportsReportDetailFile">
        <Header />
        <Switch>
          <AnalyzableRoute path="/imports/report/:type" component={LinesList} routeName="Report file details" />
        </Switch>
      </div>
    </ReportFileContext.Provider>
  );
};

export default ImportsReportDetailFile;
