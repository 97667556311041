import './index.scss';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ReservedAccess from '../ReservedAccess';
import BackOfficeHeader from './Header';
import BackOfficeNav from './Nav';
import BackOfficeParser from './Parser';
import MyOrganization from './MyOrganization';

const BackOffice: React.FC = () => (
  <div className="BackOffice">
    <BackOfficeHeader />
    <div className="BackOffice__content">
      <div className="BackOffice__side-view">
        <BackOfficeNav />
      </div>
      <div className="BackOffice__main-view">
        <Switch>
          <Route path="/back-office" component={BackOfficeParser} exact />
          <Route path="/back-office/organization" component={MyOrganization} exact />
          <Route component={ReservedAccess} />
        </Switch>
      </div>
    </div>
  </div>
);

export default BackOffice;
