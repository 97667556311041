import './index.scss';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Config from 'config';
import type Errors from 'types/errors';
import type { Occupation } from 'types/models';
import getI18nRole from 'utils/getI18nRole';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormSelect from 'components/FormSelect';

interface Props {
  defaultData?: Occupation,
  errors?: Errors.Validation | null,
}

const MyOrganizationEditForm: React.FC<Props> = ({ defaultData, errors }) => {
  const { t } = useTranslation();

  const rolesOptions = React.useMemo(() => (
    Config.ROLES_COLLABORATORS.map((role) => ({
      label: t(getI18nRole([role])),
      value: role,
    }))
  ), [t]);

  const allRoles = React.useMemo(() => (
    defaultData?.roles.map((role) => ({
      identifier: role,
      value: role,
    }))
  ), [defaultData]);

  return (
    <div className="MyOrganizationEditForm">
      <FormFieldset>
        <FormGroup label={t('contacts:function')} error={errors?.nameFunction}>
          <FormControl
            name="nameFunction"
            autoComplete="off"
            defaultValue={defaultData?.nameFunction || ''}
            isInvalid={!!errors?.nameFunction}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup label={t('users:identifier')} error={errors?.identifier}>
          <FormControl
            name="identifier"
            autoComplete="off"
            defaultValue={defaultData?.identifier || ''}
            isInvalid={!!errors?.identifier}
          />
        </FormGroup>
        <FormGroup label={t('contacts:role')} error={errors?.roles}>
          <FormSelect
            name="roles"
            placeholder={t('common:please-choose')}
            selectOptions={rolesOptions}
            defaultValue={allRoles || ''}
            isInvalid={!!errors?.roles}
            isAsync
          />
        </FormGroup>
      </FormFieldset>
    </div>
  );
};

export default MyOrganizationEditForm;
