import './index.scss';
import { useCallback, useMemo, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import type Errors from 'types/errors';
import type { HistoryStep } from 'types/models';
import type Misc from 'types/misc';
import organizationStore from 'stores/Organization';
import dateFormatIso from 'utils/dateFormatIso';
import getDaysDiff from 'utils/getDaysDiff';
import getContactRole from 'utils/getContactRole';
import getI18nChannel from 'utils/getI18nChannel';
import FormFieldset from 'components/FormFieldset';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import Icon from 'components/Icon';
import FormSelect from 'components/FormSelect';
import FormDatePicker from 'components/FormDatePicker';
import FormSwitch from 'components/FormSwitch';
import FormGroupWithActions from 'components/FormGroupWithActions';
import Button from 'components/Button';
import TagLink from 'components/TagLink';
import HistoryStepContentPreview from 'components/HistoryStepContentPreview';
import FormControlSMSContent from 'components/FormControlSMSContent';
import AskMoveScenarioModal from '../../AskMoveScenario';
import ReminderEditFormContentEdit from './Content';

type Props = {
  defaultData?: HistoryStep | null,
  errors?: Errors.Validation | null,
};

const ReminderEditForm = ({ defaultData, errors }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { contactRoles } = organizationStore;
  const [content, setContent] = useState<string>(defaultData?.content ?? '');
  const [startDate, setStartDate] = useState<string>(defaultData?.at.at ?? '');
  const [dateMoveScenario, setDateMoveScenario] = useState<'none' | 'move'>('none');
  const [daysShift, setDaysShift] = useState<number>(0);

  const roleDefaultValue = useMemo<Misc.Identifier[]>(() => {
    if (!defaultData || !contactRoles) {
      return [];
    }

    const defaultContactRoles = defaultData.contacts?.map(({ contactRole }) => (
      ({
        label: getContactRole(contactRole, contactRoles),
        identifier: contactRole,
        value: contactRoles,
      })
    )) || [];

    if (!defaultData.missingContactRoles) {
      return defaultContactRoles;
    }

    const defaultMissingContactRoles = defaultData.missingContactRoles?.map((role) => (
      ({
        label: getContactRole(role, contactRoles),
        identifier: role,
        value: contactRoles,
      })
    )) || [];

    return [...defaultContactRoles, ...defaultMissingContactRoles];
  }, [defaultData, contactRoles]);

  const subjectLabel = defaultData?.channel === 'EMAIL'
    ? t('reminders:email-subject')
    : t('reminders:subject');

  const [showAskMoveScenario, hideAskMoveScenario] = useModal(() => (
    <AskMoveScenarioModal
      daysCount={daysShift}
      onChange={(scenario: 'none' | 'move') => { setDateMoveScenario(scenario); }}
      onResetDate={() => { setStartDate(defaultData?.at.at ?? ''); }}
      onClose={hideAskMoveScenario}
    />
  ), [daysShift, defaultData]);

  const handleChangeStartDate = useCallback((noop: string, newDate: string) => {
    setStartDate(newDate);

    if (defaultData && dateFormatIso(new Date(defaultData.at.at)) !== newDate) {
      showAskMoveScenario();
      setDaysShift(Number.parseInt(getDaysDiff(newDate, defaultData?.at.at), 10));
    }
  }, [defaultData, showAskMoveScenario]);

  const [showPreviewContentModal, hidePreviewContentModal] = useModal(() => (
    <HistoryStepContentPreview
      content={content}
      channel={defaultData?.channel || 'EMAIL'}
      onClose={hidePreviewContentModal}
    />
  ), [defaultData, content]);

  return (
    <div className="ReminderEditForm">
      <FormFieldset>
        <FormGroup label={t('reminders:sending-date')} mandatory error={errors?.startAt}>
          <FormDatePicker
            name="startAt"
            value={startDate}
            onChange={handleChangeStartDate}
            isInvalid={!!errors?.startAt}
          />
          <input type="hidden" name="withMove" value={dateMoveScenario} />
        </FormGroup>
        <FormGroup label={t('reminders:short-description')} error={errors?.name}>
          <FormControl
            type="text"
            name="name"
            autoComplete="off"
            defaultValue={defaultData?.name ?? ''}
            placeholder={t('reminders:placeholder.short-description')}
            isInvalid={!!errors?.name}
          />
        </FormGroup>
      </FormFieldset>
      {dateMoveScenario === 'move' && daysShift > 0 && (
        <p className="ReminderEditForm__daysShift">
          <Icon name="warning-circle" />
          {t('reminders:shifting-other-steps', { count: daysShift })}
        </p>
      )}
      <FormFieldset>
        <FormGroup label={t('reminders:description')} error={errors?.description}>
          <FormControl
            type="text"
            name="description"
            autoComplete="off"
            defaultValue={defaultData?.description ?? ''}
            placeholder={t(
              'reminders:placeholder.description',
              { channel: t(getI18nChannel(defaultData?.channel || 'SMS')) },
            )}
            isInvalid={!!errors?.description}
          />
        </FormGroup>
      </FormFieldset>
      <FormFieldset>
        <FormGroup
          label={t('reminders:need-validation')}
          mandatory
          className="ReminderEditForm__need-validation"
          error={errors?.needValidation}
        >
          <FormSwitch name="needValidation" defaultValue={!defaultData?.isInitialActive} />
        </FormGroup>
      </FormFieldset>
      <div className="ReminderEditForm__divider" />
      <FormFieldset>
        <FormGroup label={t('reminders:recipients')} mandatory error={errors?.roles}>
          <FormSelect
            name="roles"
            placeholder={t('common:please-choose')}
            selectOptions={contactRoles?.map(
              ({ identifier, value }) => ({ label: value, value: identifier }),
            )}
            defaultValue={roleDefaultValue}
            isInvalid={!!errors?.roles}
            isMultiple
            isAsync
          />
        </FormGroup>
      </FormFieldset>
      {(defaultData?.subject || defaultData?.channel === 'EMAIL') && (
        <FormFieldset>
          <FormGroup label={subjectLabel} error={errors?.subject}>
            <FormControl
              type="text"
              name="subject"
              autoComplete="off"
              defaultValue={defaultData?.subject ?? ''}
              isInvalid={!!errors?.subject}
              placeholder={t('reminders:placeholder.subject')}
            />
          </FormGroup>
        </FormFieldset>
      )}
      <FormFieldset>
        <FormGroupWithActions
          label={defaultData?.channel === 'EMAIL' ? t('reminders:email-content') : t('reminders:content')}
          mandatory
          className="ReminderEditForm__content"
          action={<TagLink />}
          secondaryAction={(
            <Button
              variant="link"
              onClick={showPreviewContentModal}
            >
              {t('models:preview')}
            </Button>
          )}
        >
          {defaultData && (
            <Fragment>
              {defaultData.channel !== 'SMS' && (
                <ReminderEditFormContentEdit
                  reminder={defaultData}
                  onChange={setContent}
                />
              )}
              {defaultData.channel === 'SMS' && (
                <FormControlSMSContent
                  name="content"
                  value={content}
                  onChange={setContent}
                />
              )}
            </Fragment>
          )}
        </FormGroupWithActions>
      </FormFieldset>
    </div>
  );
};

export default observer(ReminderEditForm);
