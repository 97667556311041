import type Misc from 'types/misc';

const getEavTypeLabel = (eavFormat: Misc.EavFormats) => {
  const eavFormatTable = {
    number: 'common:number',
    date: 'common:date-format-ymd',
    decimalNumber: 'common:decimal-number',
  };

  return eavFormatTable[eavFormat];
};

export default getEavTypeLabel;
