import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import { useTranslation } from 'react-i18next';
import equal from 'deep-equal';
import type { ActionPost } from 'types/models';
import type Errors from 'types/errors';
import type Misc from 'types/misc';
import userRightsStore from 'stores/UserRights';
import getI18nChannel from 'utils/getI18nChannel';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import { checkIsAllowed } from 'components/UserRights';
import useIsMountedRef from 'hooks/useIsMountedRef';
import useApiRequest from 'hooks/useApiRequest';
import organizationStore from 'stores/Organization';
import Confirm from 'components/Confirm';
import ModalModels from 'components/ModalModels';
import Button from 'components/Button';
import ReminderForm from './Form';

type Props = {
  identifier: string,
  action: ActionPost | null,
  onActionDone(message: string): void,
  onActionError(message: string): void,
  onClose(): void,
  channel: Misc.Channel,
  edit?: boolean,
  type: 'REMINDER' | 'DISPATCH',
  dateReference: Misc.PlanDateReference,
};

const PlanModalAddAction = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const {
    identifier,
    action,
    onActionDone,
    onActionError,
    channel,
    onClose,
    edit = false,
    type,
    dateReference,
  } = props;
  const { currentOrganization } = organizationStore;
  const { rights } = userRightsStore;

  const { remove, post, put } = useApiRequest();

  const [hasChanges, setHasChanges] = React.useState<boolean>(false);
  const [validationErrors, setValidationErrors] = React.useState<Errors.Validation | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = React.useState<boolean>(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState<boolean>(false);
  const [model, setModel] = React.useState<Misc.PlanTemplateReminder | undefined>();

  const roleDefaultValue = React.useMemo<string[]>(() => (
    action ? action.contacts?.map((contactRole) => (contactRole)) || [] : []
  ), [action]);

  const initialData = React.useRef<ActionPost>({
    day: action?.day || 0,
    name: action?.name || '',
    description: action?.description || '',
    subject: action?.subject || '',
    content: action?.content || '',
    contacts: roleDefaultValue,
    needsValidation: action?.needsValidation || false,
    type,
  });

  const mapFormData = React.useCallback(
    (rawData: ModalFormData): ActionPost | null => {
      if (!currentOrganization) {
        return null;
      }

      return {
        day: parseInt(rawData.day as string, 10),
        name: rawData.name as string,
        description: rawData.description as string,
        subject: action?.channel === 'EMAIL' ? rawData.subject as string : '',
        content: rawData.content as string,
        contacts: (rawData.roles as string).split(','),
        needsValidation: rawData.needsValidation === '1',
        type,
      };
    },
    [action, currentOrganization, type],
  );

  const [showModalModels, hideModalModels] = useModal(() => (
    <ModalModels
      type="reminder"
      onClose={hideModalModels}
      onSelect={setModel}
      channel={channel}
    />
  ), [channel]);

  const closeSelf = React.useCallback(() => {
    if (!isMountedRef.current) {
      return;
    }

    setShowCancelConfirm(false);
    setHasChanges(false);
    setValidationErrors(null);
    onClose();
  }, [isMountedRef, onClose]);

  const handleCancel = React.useCallback(
    () => {
      if (hasChanges) {
        setShowCancelConfirm(true);
      } else {
        closeSelf();
      }
    },
    [hasChanges, closeSelf],
  );

  const handleChange = React.useCallback(
    (formData: ModalFormData | null) => {
      setHasChanges(
        !!formData && !equal(initialData.current, mapFormData(formData)),
      );
    },
    [initialData, mapFormData],
  );

  const handleSubmitCreateOrEdit = React.useCallback(async (formData: ModalFormData | null) => {
    if (!currentOrganization || (!action && edit) || !formData) {
      return;
    }
    let result;
    const roles = formData.roles as string;
    let day = parseInt(formData.days as string, 10);
    const date: string = formData.date as string;
    if (type === 'REMINDER') {
      day = date.startsWith('before') ? -day : day;
    }
    const body = {
      identifier,
      type: type.toLowerCase(),
      step: {
        name: formData.name,
        day: (type === 'REMINDER' ? day : undefined),
        channel,
        description: formData.description,
        needsValidation: formData.needsValidation !== '0',
        contacts: roles.split(','),
        subject: formData.subject,
        content: formData.content,
      },
    };
    if (edit) {
      result = await put(`/manage-plans/${currentOrganization?.reference}/step`, {
        ...body,
        current: {
          name: action?.name,
          channel: action?.channel,
          day: (type === 'REMINDER' ? action?.day : undefined),
        },
        new: body.step,
        step: undefined,
      });
    } else {
      result = await post(`/manage-plans/${currentOrganization?.reference}/step`, body);
    }
    if (!result) {
      onActionError(edit ? t('plans:toast.error.edited-action') : t('plans:toast.error.added-action'));
      return;
    }
    onClose();
    onActionDone(t(edit ? 'plans:toast.success.edited-action' : 'plans:toast.success.added-action', { name: action?.name }));
  }, [
    currentOrganization,
    action,
    edit,
    onClose,
    onActionDone,
    t,
    put,
    identifier,
    post,
    onActionError,
    type,
    channel,
  ]);

  const handleSubmitDelete = React.useCallback(async () => {
    if (!currentOrganization || !action) {
      return;
    }
    const result = await remove(
      `/manage-plans/${currentOrganization?.reference}/step?identifier=${identifier}&type=${type.toLowerCase()}&name=${action.name}`
      + `${type === 'REMINDER' ? `&day=${action.day}` : `&channel=${action.channel}`}`,
    );
    if (!result) {
      onActionError(t('plans:toast.error.deleted-action'));
      return;
    }
    setShowDeleteConfirm(false);
    closeSelf();
    onActionDone(t('plans:toast.success.deleted-action', { name: action.name }));
  }, [
    action,
    closeSelf,
    currentOrganization,
    identifier,
    onActionDone,
    onActionError,
    remove,
    type,
    t,
  ]);

  const modalTitle = React.useMemo(() => {
    if (edit) {
      return t('plans:edit-action', { channel: t(getI18nChannel(channel)) });
    }
    return type === 'DISPATCH'
      ? t('plans:add-dispatch', { channel: t(getI18nChannel(channel)) })
      : t('plans:add-reminder', { channel: t(getI18nChannel(channel)) });
  }, [channel, edit, t, type]);

  return (
    <ModalForm
      isOpened
      className="PlanModalAddAction"
      title={modalTitle}
      subTitle={action?.name ?? ''}
      buttonsDisabled={rights === null || !checkIsAllowed('UPDATE', 'PREFERENCES_PLANS')}
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmitCreateOrEdit}
      onCancel={handleCancel}
      isLoading={false}
      hasDeleteButton={edit}
      onDelete={() => setShowDeleteConfirm(true)}
      headerActions={(
        <Button variant="link" onClick={showModalModels}>{t('models:import')}</Button>
      )}
    >
      {type === 'DISPATCH' && (
        <p className="PlanModalAddAction__dispatch-description">
          {t('plans:dispatch-description')}
        </p>
      )}
      <ReminderForm
        type={type}
        defaultData={model ? { ...model, type } : action}
        errors={validationErrors}
        dateReference={dateReference}
        channel={channel}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={closeSelf}
        onCancel={() => { setShowCancelConfirm(false); }}
      />
      <Confirm
        titleModal={t('common:remove')}
        entityName={action?.name}
        text={t('plans:confirm-remove-action')}
        variant="danger"
        confirmButtonText={t('common:remove')}
        cancelButtonText={t('common:cancel')}
        isShow={showDeleteConfirm}
        onConfirm={handleSubmitDelete}
        onCancel={() => { setShowDeleteConfirm(false); }}
      />
    </ModalForm>
  );
};

export default observer(PlanModalAddAction);
