import type { AnalysisRiskChartData } from 'types/models';
import Request from 'utils/request';

export type FetchAllParams = { organization: string | undefined };

const fetchAllRisks = async ({ organization }: FetchAllParams) => {
  if (!organization) {
    throw new Error('FetchAllRisksAnalysis: Missing organization.');
  }

  const result = await Request.get<AnalysisRiskChartData>(
    `organization/${organization}/analyse/risks`,
  );

  return result;
};

const fetchAllActivity = async ({ organization }: FetchAllParams) => {
  if (!organization) {
    throw new Error('FetchAllActivityAnalysis: Missing organization.');
  }

  const result = await Request.get(
    `organization/${organization}/analyse/activities`,
  );

  return result;
};

const fetchAllFinances = async ({ organization }: FetchAllParams) => {
  if (!organization) {
    throw new Error('FetchAllFinancesAnalysis: Missing organization.');
  }

  const result = await Request.get(
    `organization/${organization}/analyse/finances`,
  );

  return result;
};

export { fetchAllRisks, fetchAllActivity, fetchAllFinances };
