import './index.scss';
import { useCallback, useMemo } from 'react';
import { EditorState, Modifier } from 'draft-js';
import organizationStore from 'stores/Organization';
import FormSelect from 'components/FormSelect';

type Props = {
  onChange(newState: EditorState): void,
  editorState: EditorState,
};

const TagsPlugin = ({ onChange, editorState }: Props) => {
  const { tags } = organizationStore;

  const tagsOptions = useMemo(() => (
    tags
      .sort((a, b) => a.identifier.localeCompare(b.identifier))
      .map(({ identifier }) => ({ label: identifier, value: identifier }))
  ), [tags]);

  const addTag = useCallback((name: string, value: string | null) => {
    if (!value) {
      return;
    }

    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      value,
      editorState.getCurrentInlineStyle(),
    );

    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  }, [editorState, onChange]);

  return (
    <FormSelect
      className="TagsPlugin"
      name="editor-tags-plugin"
      placeholder="{tag}"
      value={null}
      selectOptions={tagsOptions}
      onSelect={addTag}
    />
  );
};

export default TagsPlugin;
