import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import type Misc from 'types/misc';
import type { OrganizationPlanTemplate } from 'types/models';
import type { FetchOneParams } from 'api/plans';
import { fetchOne } from 'api/plans';
import organizationStore from 'stores/Organization';
import useIsMountedRef from 'hooks/useIsMountedRef';
import useFetch from 'hooks/useFetch';
import Loading from 'components/Loading';
import Alert from 'components/Alert';
import PlanDetailsActions from './Actions';
import PlanDetailsInfo from './Info';

type Props = {
  identifier: string,
  reloadTick: number,
  onGetDateReference(string: Misc.PlanDateReference): void,
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const PlanDetails = (props: Props): JSX.Element | null => {
  const { identifier, reloadTick, onGetDateReference, onActionDone, onActionError } = props;
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const isMountedRef = useIsMountedRef();

  const {
    data,
    isLoading,
    error,
    refetch,
  } = useFetch<FetchOneParams, OrganizationPlanTemplate>(
    {
      cacheKey: 'organizationPlanTemplate',
      organization: currentOrganization?.reference,
      identifier,
    },
    fetchOne,
  );

  const dateReference = useMemo(() => {
    if (!data || data.dateReferenceIsToday) {
      return 'plan-setup';
    }
    return data.group ? 'invoice-oldest-deadline' : 'invoice-deadline';
  }, [data]);

  useEffect(() => {
    if (isMountedRef.current) {
      onGetDateReference(dateReference);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateReference]);

  useEffect(() => {
    refetch();
  }, [refetch, reloadTick]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Alert variant="error">{t('errors:unknown-retry')}</Alert>;
  }

  if (!data) {
    return null;
  }

  return (
    <div className="PlanDetails">
        <PlanDetailsActions
          dateReference={dateReference}
          identifier={identifier}
          tasks={data.tasks || []}
          dispatches={data.dispatches || []}
          reminders={data.reminders || []}
          onActionDone={onActionDone}
          onActionError={onActionError}
          isGroup={!!data.group}
        />
        <PlanDetailsInfo
          group={data.group}
          dateReference={dateReference}
          planChange={
            Array.isArray(data.planChange) ? data.planChange[0] : data.planChange
          }
        />
    </div>
  );
};

export default observer(PlanDetails);
