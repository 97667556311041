import './index.scss';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import formatIntlDate from 'utils/formatIntlDate';
import customerContext from 'pages/Customer/customerContext';

const CustomerInformations = (): JSX.Element | null => {
  const { data: customer } = useContext(customerContext);
  const { t } = useTranslation();

  if (!customer) {
    return null;
  }

  return (
    <dl className="CustomerInformations">
      <div className="CustomerInformations__item">
        <dt className="CustomerInformations__label">
          {t('clients:dunning-mode')}
        </dt>
        <dd className="CustomerInformations__value">
          {customer.useGroup ? t('common:grouped') : t('common:individual')}
        </dd>
      </div>
      <div className="CustomerInformations__item">
        <dt className="CustomerInformations__label">
          {t('clients:attachment')}
        </dt>
        <dd className="CustomerInformations__value">
          {customer.attachment ? t('common:yes') : t('common:no')}
        </dd>
      </div>
      <div className="CustomerInformations__item">
        <dt className="CustomerInformations__label">
          {t('common:created-at')}
        </dt>
        <dd className="CustomerInformations__value">
          {formatIntlDate(customer.createdAt, 'dateLong') || '-'}
        </dd>
      </div>
      <div className="CustomerInformations__item">
        <dt className="CustomerInformations__label">
          {t('common:updated-at')}
        </dt>
        <dd className="CustomerInformations__value">
          {formatIntlDate(customer.updatedAt, 'dateLong') || '-'}
        </dd>
      </div>
    </dl>
  );
};

export default CustomerInformations;
