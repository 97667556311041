import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import type { TFunction } from 'i18next';
import type { Column } from 'react-table';
import type Misc from 'types/misc';
import type { LastCustomerMainDebtor } from 'types/models';
import formatIntlNumber from 'utils/formatIntlNumber';
import ClientName from 'components/ClientName';
import ListHistorySummary from 'components/ListHistorySummary';

const MainDebtorsColumns = (
  ct: TFunction,
  currency: Misc.CurrencyCode | null,
): Column<LastCustomerMainDebtor>[] => [
  {
    Header: ct<string>('common:client'),
    accessor: 'customer',
    id: 'reference',
    Cell: ({ value }) => (
      <div className="MainDebtors__columns__name">
        <Link to={`/customer/${value.id}`}>
          <ClientName client={value} />
        </Link>
        {value.corporateName !== value.reference && (
          <div className="MainDebtors__columns__ref">
            {value.reference}
          </div>
        )}
      </div>
    ),
  },
  {
    Header: ct<string>('common:total-outstanding-amount'),
    accessor: 'statistics',
    id: 'totalToCollect',
    sortType: 'basic',
    sortDescFirst: true,
    Cell: ({ value }) => (
      <div className="MainDebtors__columns__in-progress">
        {formatIntlNumber(value.totalToCollect, currency)}
        <div className="MainDebtors__columns__count">
          {ct(
            'common:bills-count',
            { count: value.dueCount + value.notDueCount },
          )}
        </div>
      </div>
    ),
  },
  {
    Header: ct<string>('common:not-due'),
    accessor: 'statistics',
    id: 'totalNotDue',
    sortType: 'basic',
    sortDescFirst: true,
    Cell: ({ value }) => (
      <Fragment>
        {formatIntlNumber(value.totalNotDue, currency)}
      </Fragment>
    ),
  },
  {
    Header: ct<string>('common:late'),
    accessor: 'statistics',
    id: 'totalDue',
    sortType: 'basic',
    sortDescFirst: true,
    Cell: ({ value }) => (
      <Fragment>
        {formatIntlNumber(value.totalDue, currency)}
      </Fragment>
    ),
  },
  {
    Header: ct<string>('common:evolution'),
    accessor: 'statistics',
    id: 'progression',
    sortType: 'basic',
    sortDescFirst: true,
    Cell: ({ value }) => <Fragment>{value.progression || 0} %</Fragment>,
  },
  {
    Header: ct<string >('common:next-action'),
    accessor: 'statistics',
    disableSortBy: true,
    Cell: ({ value }) => {
      const { nextSending, lastSending } = value;
      return (
        <ListHistorySummary data={{ nextSending, lastSending }} />
      );
    },
  },
];

export default MainDebtorsColumns;
