import type { TFunction } from 'i18next';
import type { Column } from 'react-table';
import type { HistoryStep as HistoryStepType } from 'types/models';
import HistoryStep from 'components/HistoryStep';

const ActionsColumns = (
  t: TFunction,
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
): Column<HistoryStepType>[] => [
  {
    Header: (t('common:select-all') as string),
    disableSortBy: true,
    accessor: 'id',
    id: 'id',
    Cell: ({ row }) => (
      <HistoryStep
        data={row.original}
        showClientName
        showRelativeDateRef
        key={row.original.id}
        onActionDone={onActionDone}
        onActionError={onActionError}
      />
    ),
  },
];

export default ActionsColumns;
