import './index.scss';
import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import Loading from 'components/Loading';
import PayRequestsContext from '../../context';
import type { ToggleAction } from '../actions';
import getBulkToggleActionI18n from './getBulkToggleActionI18n';
import BulkActionsToggle from './Toggle';
import BulkActionPromiseToPay from './PromiseToPay';

interface Props {
  selection?: (number | string)[],
  onActionDone: (message: string) => void,
  onActionError: (message: string) => void,
}

const PayRequestBulkActions: React.FC<Props> = (props) => {
  const { linesOfBusiness, modePayRequest } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);

  const { selection, onActionDone, onActionError } = props;
  const { currentStatus } = React.useContext(PayRequestsContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleToggleActionDone = React.useCallback(
    (action: ToggleAction) => {
      const { toastText } = getBulkToggleActionI18n(t, ct, selection?.length || 0)[action];
      onActionDone(toastText);
    },
    [t, ct, selection, onActionDone],
  );

  const handleActionLoading = React.useCallback(
    (isLoad: boolean) => { setIsLoading(isLoad); },
    [],
  );

  const handleActionError = React.useCallback(
    (message: string) => {
      onActionError(message);
    },
    [onActionError],
  );

  if (!selection) {
    return null;
  }

  const classNames = classnames('PayRequestBulkActions', {
    'PayRequestBulkActions--loading': isLoading,
  });

  if (modePayRequest === 'BALANCE') {
    return (
      <div className={classNames}>
        {isLoading && <Loading text={t('common:saving')} />}
        {(currentStatus === 'IN_PROGRESS') && (
          <>
            <BulkActionsToggle
              selection={selection}
              action="unmute"
              onLoading={handleActionLoading}
              onDone={handleToggleActionDone}
              onError={handleActionError}
            />
            <BulkActionsToggle
              selection={selection}
              action="mute"
              onLoading={handleActionLoading}
              onDone={handleToggleActionDone}
              onError={handleActionError}
            />
          </>
        )}
        {(['IN_PROGRESS', 'LITIGATED'].includes(currentStatus)) && (
          <>
            <BulkActionsToggle
              selection={selection}
              action="mark-as-paid"
              onLoading={handleActionLoading}
              onDone={handleToggleActionDone}
              onError={handleActionError}
            />
            <BulkActionPromiseToPay
              selection={selection}
              onLoading={handleActionLoading}
              onDone={onActionDone}
            />
          </>
        )}
        {(currentStatus === 'IN_PROGRESS') && (
          <>
            <BulkActionsToggle
              selection={selection}
              action="cancel"
              onLoading={handleActionLoading}
              onDone={handleToggleActionDone}
              onError={handleActionError}
            />
            <BulkActionsToggle
              selection={selection}
              action="litigate"
              onLoading={handleActionLoading}
              onDone={handleToggleActionDone}
              onError={handleActionError}
            />
          </>
        )}
        {(currentStatus === 'CANCELLED') && (
          <BulkActionsToggle
            selection={selection}
            action="remove"
            onLoading={handleActionLoading}
            onDone={handleToggleActionDone}
            onError={handleActionError}
          />
        )}
      </div>
    );
  }

  return (
    <div className={classNames}>
      {isLoading && <Loading text={t('common:saving')} />}
      {(currentStatus === 'IN_PROGRESS') && (
        <>
          <BulkActionsToggle
            selection={selection}
            action="unmute"
            onLoading={handleActionLoading}
            onDone={handleToggleActionDone}
            onError={handleActionError}
          />
          <BulkActionsToggle
            selection={selection}
            action="mute"
            onLoading={handleActionLoading}
            onDone={handleToggleActionDone}
            onError={handleActionError}
          />
        </>
      )}
      {(['IN_PROGRESS', 'LITIGATED'].includes(currentStatus)) && (
        <>
          <BulkActionsToggle
            selection={selection}
            action="mark-as-paid"
            onLoading={handleActionLoading}
            onDone={handleToggleActionDone}
            onError={handleActionError}
          />
          <BulkActionPromiseToPay
            selection={selection}
            onLoading={handleActionLoading}
            onDone={onActionDone}
          />
          <BulkActionsToggle
            selection={selection}
            action="cancel"
            onLoading={handleActionLoading}
            onDone={handleToggleActionDone}
            onError={handleActionError}
          />
        </>
      )}
      {(currentStatus === 'IN_PROGRESS') && (
        <BulkActionsToggle
          selection={selection}
          action="litigate"
          onLoading={handleActionLoading}
          onDone={handleToggleActionDone}
          onError={handleActionError}
        />
      )}
      {(currentStatus === 'PAID') && (
        <>
          <BulkActionsToggle
            selection={selection}
            action="cancel-mark-as-paid"
            onLoading={handleActionLoading}
            onDone={handleToggleActionDone}
            onError={handleActionError}
          />
          <BulkActionsToggle
            selection={selection}
            action="cancel"
            onLoading={handleActionLoading}
            onDone={handleToggleActionDone}
            onError={handleActionError}
          />
        </>
      )}
      {(currentStatus === 'CANCELLED') && (
        <BulkActionsToggle
          selection={selection}
          action="remove"
          onLoading={handleActionLoading}
          onDone={handleToggleActionDone}
          onError={handleActionError}
        />
      )}
      {(currentStatus === 'LITIGATED') && (
        <BulkActionsToggle
          selection={selection}
          action="cancel-litigate"
          onLoading={handleActionLoading}
          onDone={handleToggleActionDone}
          onError={handleActionError}
        />
      )}
    </div>
  );
};

export default observer(PayRequestBulkActions);
