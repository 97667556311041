import { fetchReminderContent } from 'api/historySteps';
import useFetch from 'hooks/useFetch';
import Loading from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';
import Wysiwyg from 'components/Wysiwyg';

import type { HistoryStep } from 'types/models';
import type { FetchReminderContentParams } from 'api/historySteps';

type Props = {
  reminder: HistoryStep,
  onChange(newValue: string): void,
};

const ReminderEditFormContentEdit = ({ reminder, onChange }: Props): JSX.Element | null => {
  const { data, isLoading, error } = useFetch<FetchReminderContentParams, string>(
    {
      cacheKey: 'reminderContent',
      reminderId: reminder.id ?? null,
    },
    fetchReminderContent,
    { refetchOnWindowFocus: false, retry: false },
  );

  if (isLoading || !!error) {
    return (
      <div className="ReminderEditFormContentEdit">
        {error ? <ErrorMessage error={error} /> : <Loading />}
      </div>
    );
  }

  return (
    <div className="ReminderEditFormContentEdit">
      <Wysiwyg
        name="content"
        defaultValue={data ?? ''}
        toBePrinted={reminder.channel !== 'EMAIL'}
        onChange={onChange}
      />
    </div>
  );
};

export default ReminderEditFormContentEdit;
