import config from 'config';

type Callback<Payload> = (payload: Payload) => void;

const subscribeToMercure = <Payload>(topic: string, callback: Callback<Payload>) => {
  const url = new URL(config.MERCURE_URL || '');
  const authToken = window.localStorage.getItem('token');

  const topicUrl = `https://mercure.dunforce.io/${topic}/${authToken}`;
  url.searchParams.append('topic', topicUrl);

  // eslint-disable-next-line no-console
  console.info('Subscribing to EventSource', topicUrl);

  const eventSource = new EventSource(url.toString());
  eventSource.onmessage = ({ data }: MessageEvent<string>) => {
    try {
      const payload = JSON.parse(data) as Payload;
      callback(payload);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return eventSource;
};

export default subscribeToMercure;
