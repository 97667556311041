import './index.scss';
import { useTranslation } from 'react-i18next';
import type { HistoryStep, HistoryStepSMS, HistoryStepLetter, HistoryStepEmail } from 'types/models';
import formatIntlDate from 'utils/formatIntlDate';
import HistoryStepWebHookStatus from './WebHookStatus';

type Props = {
  data: HistoryStep | HistoryStepSMS | HistoryStepLetter | HistoryStepEmail,
};

const HistoryStepDetailsHistory = ({ data }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { at, validation, remove } = data;

  const dateTimeFormat = 'dateTimeFullShortMonth';

  return (
    <div className="HistoryStepDetailsHistory">
      <div className="HistoryStepDetailsHistory__date">
        {t('common:planned-at')} {formatIntlDate(at.at, dateTimeFormat)}
      </div>
      {!!validation && (
        <div className="HistoryStepDetailsHistory__date">
          {t('common:validated-at-date-by', {
            date: formatIntlDate(validation.at, dateTimeFormat),
            by: validation.userName,
          })}
        </div>
      )}
      {!!remove && (
        <div className="HistoryStepDetailsHistory__date">
          {t('common:remove-at-date-by', {
            date: formatIntlDate(remove.at, dateTimeFormat),
            by: remove.userName,
          })}
        </div>
      )}
      <HistoryStepWebHookStatus data={data} />
    </div>
  );
};

export default HistoryStepDetailsHistory;
