import type { ManagerType, RightsCategoryType, RightsCategory } from 'types/models';

const getCategoryFromCategoryType = (
  managers: ManagerType[] | null | undefined,
  userIdentifier: string | null,
  categoryType: RightsCategoryType,
): RightsCategory => {
  const isNotAssigned = !managers || !managers.length;
  const isAssignedToUser = managers && managers.length > 0 && managers.some(
    ({ id, identifier }) => identifier === userIdentifier || `${id}` === userIdentifier,
  );

  switch (categoryType) {
    case 'CLIENTS':
      if (isNotAssigned) {
        return 'CLIENTS_UNASSIGNED';
      }
      return isAssignedToUser ? 'CLIENTS_ASSIGNED_TO_ME' : 'CLIENTS_ASSIGNED_SOMEONE';
    case 'ACTIONS':
      if (isNotAssigned) {
        return 'ACTIONS_UNASSIGNED';
      }
      return isAssignedToUser ? 'ACTIONS_ASSIGNED_TO_ME' : 'ACTIONS_ASSIGNED_SOMEONE';
    case 'CONTACTS':
      if (isNotAssigned) {
        return 'CONTACTS_UNASSIGNED';
      }
      return isAssignedToUser ? 'CONTACTS_ASSIGNED_TO_ME' : 'CONTACTS_ASSIGNED_SOMEONE';
    case 'PAYMENTS':
      if (isNotAssigned) {
        return 'PAYMENTS_UNASSIGNED';
      }
      return isAssignedToUser ? 'PAYMENTS_ASSIGNED_TO_ME' : 'PAYMENTS_ASSIGNED_SOMEONE';
    case 'PAYREQUESTS':
      if (isNotAssigned) {
        return 'PAYREQUESTS_UNASSIGNED';
      }
      return isAssignedToUser ? 'PAYREQUESTS_ASSIGNED_TO_ME' : 'PAYREQUESTS_ASSIGNED_SOMEONE';
    default:
      return 'UNKNOWN_UNASSIGNED';
  }
};

export default getCategoryFromCategoryType;
