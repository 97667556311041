import './index.scss';
import { useMemo } from 'react';
import type { ReportItem } from 'types/models';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import formatIntlDate from 'utils/formatIntlDate';

type Props = {
  report: ReportItem,
};

const SecondaryInfos = ({ report }: Props): JSX.Element => {
  const { linesOfBusiness } = organizationStore;
  const { t } = useContextualTranslation(linesOfBusiness);
  const { date, files } = report;

  const maxTimeStamp = Math.max(...files.map((file) => Date.parse(file.processedAt)));
  const max = files.find(
    (file) => maxTimeStamp === Date.parse(file.processedAt),
  )?.processedAt;

  const reportDate = useMemo(() => {
    // - Cette ligne sert à supprimer le suffixe introduit par l'API
    //   @see https://gitlab.com/dunforce/api/-/issues/1055#note_1124775346
    const safeDate = date.replace(/_\d+$/, '');
    return formatIntlDate(safeDate, 'dateTimeFullShortMonth');
  }, [date]);

  return (
    <div className="ReportInfosSecondary">
      <div className="ReportInfosSecondary__section">
        <div className="ReportInfosSecondary__section__box">
          <h4 className="ReportInfosSecondary__section__box__title">
            {t('reports:beggining-of-import')}
          </h4>
          <p className="ReportInfosSecondary__section__box__subtitle">
            {reportDate}
          </p>
        </div>
        <div className="ReportInfosSecondary__section__box">
          <h4 className="ReportInfosSecondary__section__box__title">
            {t('reports:ending-of-import')}
          </h4>
          <p className="ReportInfosSecondary__section__box__subtitle">
            {formatIntlDate(max, 'dateTimeFullShortMonth')}
          </p>
        </div>
      </div>
      <div className="ReportInfosSecondary__section">
        <div className="ReportInfosSecondary__section__box">
          <h4 className="ReportInfosSecondary__section__box__title">
            {t('reports:files')}
          </h4>
          <p className="ReportInfosSecondary__section__box__subtitle">
            {files.length}
            {files.length > 0 && files[0].dataFix && (
              <>{', '}<span className="ReportInfosSecondary__datafix">Datafix</span></>
            )}
          </p>
        </div>
        <div className="ReportInfosSecondary__section__box">
          <h4 className="ReportInfosSecondary__section__box__title">
            {t('common:user')}
          </h4>
          <p className="ReportInfosSecondary__section__box__subtitle">
            {files.length > 0 && `${files[0].user.firstName} ${files[0].user.lastName}`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SecondaryInfos;
