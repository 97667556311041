import './index.scss';
import { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useModal } from 'react-modal-hook';
import type Errors from 'types/errors';
import type { PayRequestDraft } from 'types/models';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import FormGroup from 'components/FormGroup';
import FormControl from 'components/FormControl';
import FormSelect from 'components/FormSelect';
import FormSelectCustomerWithDefault from 'components/FormSelectCustomer/WithDefault';
import FormDatePicker from 'components/FormDatePicker';
import FormSwitch from 'components/FormSwitch';
import PDFViewer from 'components/PDFViewer';
import FormAmountCurrency from 'components/FormAmountCurrency';
import FormSelectUsersWithRights from 'components/FormSelectUsersWithRights';
import FormFieldset from 'components/FormFieldset';
import FormEavs from 'components/FormEavs';
import CustomerEdit from 'components/CustomerEdit';
import FormGroupWithActions from 'components/FormGroupWithActions';
import Button from 'components/Button';

type Props = {
  data?: PayRequestDraft,
  errors?: Errors.Validation | null,
  pdf: string | null,
  onActionDone(message: string): void,
};

const DraftInvoiceForm = ((props: Props): JSX.Element => {
  const { errors, data, pdf, onActionDone } = props;
  const {
    currentOrganization,
    currency: organizationCurrency,
    useGroupByDefault,
    linesOfBusiness,
    useDynamicPlanSwitch,
    eavs,
  } = organizationStore;

  const { t, ct } = useContextualTranslation(linesOfBusiness);

  const [isGroup, setIsGroup] = useState<boolean>(useGroupByDefault);
  const [refreshTick, setRefreshTick] = useState<number>(0);

  const handleAddCustomerDone = useCallback((
    message: string,
    hideModal: () => void,
    value: number,
  ) => {
    hideModal();
    onActionDone(message);
    setRefreshTick(value + 1);
  }, [onActionDone]);

  const customerInitialError = useMemo(() => (
    !errors ? data?.errors?.client : undefined
  ), [errors, data]);

  const [showCustomerModal, hideCustomerModal] = useModal(() => (
    <CustomerEdit
      editType="new"
      onDone={(message) => { handleAddCustomerDone(message, hideCustomerModal, refreshTick); }}
      customerId={undefined}
      defaultClientName={data?.client}
      onClose={hideCustomerModal}
    />
  ), [handleAddCustomerDone, refreshTick, data]);

  return (
    <div className="DraftInvoiceForm">
      <div className="DraftInvoiceForm__form">
        <div className="DraftInvoiceForm__form__fields">
          <FormGroupWithActions
            label={ct('common:client')}
            mandatory
            shouldDisplayErrorUnderInput
            error={customerInitialError || errors?.client}
            action={data?.errors?.client && (
              <Button
                variant="link"
                onClick={showCustomerModal}
                withCaretIcon
                className="DraftInvoiceForm__form__fields__create-client"
              >
                {t('common:create')}
              </Button>
            )}
          >
            <FormSelectCustomerWithDefault
              name="customerReference"
              customerReference={!customerInitialError ? data?.client : undefined}
              refreshTick={refreshTick}
              isInvalid={!!customerInitialError || !!errors?.client}
            />
          </FormGroupWithActions>
          <FormGroup
            label={t('payment-requests:reference')}
            mandatory
            shouldDisplayErrorUnderInput
            error={(!errors && data?.errors?.reference) || errors?.reference}
          >
            <FormControl
              name="reference"
              autoComplete="off"
              defaultValue={data?.reference}
              isInvalid={!!((!errors && data?.errors?.reference) || errors?.reference)}
            />
          </FormGroup>
          <FormAmountCurrency
            shouldDisplayErrorUnderInput
            defaultAmount={data?.amount}
            defaultCurrency={data?.currency || organizationCurrency}
            amountError={(!errors && data?.errors?.amount) || errors?.amount}
            currencyError={(!errors && data?.errors?.currency) || errors?.currency}
          />
          <FormGroup
            label={t('common:due-at')}
            shouldDisplayErrorUnderInput
            mandatory
            error={(!errors && data?.errors?.dueAt) || errors?.dueAt}
          >
            <FormDatePicker
              name="dueAt"
              defaultValue={data?.dueAt}
              isInvalid={!!((!errors && data?.errors?.dueAt) || errors?.dueAt)}
            />
          </FormGroup>
          <FormGroup
            shouldDisplayErrorUnderInput
            label={t('payment-requests:subject')}
            error={(!errors && data?.errors?.subject) || errors?.subject}
          >
            <FormControl
              name="subject"
              autoComplete="off"
              defaultValue={data?.subject || ''}
              isInvalid={!!(errors?.subject || (!errors && data?.errors?.subject))}
            />
          </FormGroup>
        </div>
        {pdf && (
          <PDFViewer
            base64PDF={pdf}
            scale={0.5}
            downloadUrl={`${currentOrganization?.reference}/import/${data?.importLogId}`}
            isClickable
          />
        )}
      </div>
      <div>
        <FormFieldset>
          <FormGroup
            label={t('payment-requests:dunning-plan')}
            shouldDisplayErrorUnderInput
            error={(!errors && data?.errors?.dunningPlan) || errors?.dunningPlan}
          >
            {!useDynamicPlanSwitch && (
              <FormSelect
                name="templateDunningPlan"
                defaultValue={data?.templateDunningPlan}
                placeholder={t('common:please-choose')}
                fetchEntity="plans"
                fetchEntityQuerystring={isGroup ? 'group=1' : ''}
                isInvalid={!!(errors?.dunningPlan || (!errors && data?.errors?.dunningPlan))}
                isAsync
              />
            )}
            {useDynamicPlanSwitch && (
              <p className="DraftInvoiceForm__dunning-plan-info">
                {t('payment-requests:plan-assigned-automatically')}
              </p>
            )}
          </FormGroup>
          <FormGroup
            label={t('payment-requests:dispatch')}
            mandatory
            shouldDisplayErrorUnderInput
            className="DraftInvoiceForm__check"
            error={(!errors && data?.errors?.sendDispatches) || errors?.sendDispatches}
          >
            <FormSwitch
              name="sendDispatches"
              defaultValue={!(data?.sendDispatches === undefined)}
            />
          </FormGroup>
        </FormFieldset>
        <FormFieldset>
          <FormGroup
            label={t('payment-requests:plan-start-at')}
            mandatory
            shouldDisplayErrorUnderInput
            error={(!errors && data?.errors?.startAt) || errors?.startAt}
          >
            <FormDatePicker
              name="startAt"
              defaultValue={data?.startAt}
              isInvalid={!!(errors?.startAt || (!errors && data?.errors?.startAt))}
            />
          </FormGroup>
          <FormGroup
            label={t('payment-requests:add-to-dunning-group')}
            mandatory
            shouldDisplayErrorUnderInput
            className="DraftInvoiceForm__check"
            error={(!errors && data?.errors?.groupDunning) || errors?.groupDunning}
          >
            <FormSwitch
              name="groupDunning"
              value={isGroup}
              onChange={setIsGroup}
            />
          </FormGroup>
        </FormFieldset>
        <FormGroup
          label={t('common:managers')}
          error={(!errors && data?.errors?.users) || errors?.users}
          shouldDisplayErrorUnderInput
        >
          <FormSelectUsersWithRights
            name="users"
            placeholder={t('common:please-choose')}
            fetchEntity="users"
            entityValue="reference"
            isInvalid={!!(errors?.users || (!errors && data?.errors?.users))}
            isMultiple
            isAsync
            action="CREATE"
            categoryType="PAYREQUESTS"
          />
          <p className="DraftInvoiceForm__helper">{t('payment-requests:leave-user-empty')}</p>
        </FormGroup>
        {eavs && eavs.payRequest?.length > 0 && (
          <FormEavs
            eavs={eavs.payRequest}
            defaultValues={data?.eavs}
            errors={(
              errors?.eavs || (!errors && data?.errors?.eavs)
            ) as unknown as Errors.Validation}
          />
        )}
      </div>
    </div>
  );
});

export default observer(DraftInvoiceForm);
