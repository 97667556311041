import { observer } from 'mobx-react';
import { useCallback, useContext } from 'react';
import { useModal } from 'react-modal-hook';
import type { ManagerType } from 'types/models';
import useContextualTranslation from 'hooks/useContextualTranslation';
import { UserRightsManagerController } from 'components/UserRights';
import customerContext from 'pages/Customer/customerContext';
import organizationStore from 'stores/Organization';
import Button from 'components/Button';
import PaymentRequestEdit from 'pages/PaymentRequestEdit';
type Props = {
  managers: ManagerType[] | null | undefined,
};

const CustomerActionNewContact = ({ managers }: Props): JSX.Element => {
  const { linesOfBusiness } = organizationStore;
  const { ct } = useContextualTranslation(linesOfBusiness);
  const { data, onActionDone } = useContext(customerContext);

  const handleActionDone = useCallback((message: string) => {
    if (onActionDone) {
      onActionDone(message);
    }
  }, [onActionDone]);

  const [showPayRequestModal, hidePayRequestModal] = useModal(() => (
    <PaymentRequestEdit
      customerId={data?.id}
      onClose={hidePayRequestModal}
      onDone={(message) => { hidePayRequestModal(); handleActionDone(message); }}
    />
  ), [data, handleActionDone]);

  return (
    <UserRightsManagerController
      action="CREATE"
      categoryType="PAYREQUESTS"
      managers={managers}
    >
      <Button
        variant="list"
        className="CustomerActionNewPayRequest"
        onClick={showPayRequestModal}
      >
        {ct('common:new-bill')}
      </Button>
    </UserRightsManagerController>
  );
};

export default observer(CustomerActionNewContact);
