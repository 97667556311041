import { useCallback, useState, useRef } from 'react';
import { SelectSearchOption } from 'react-select-search';
import type Misc from 'types/misc';
import { fetchAllClients } from 'api/selectOptions';
import organizationStore from 'stores/Organization';
import useApiRequest from 'hooks/useApiRequest';
import { DefaultValue } from 'components/FormSelect/utils';

type UseSelectCustomerSettings = {
  withId: boolean,
  defaultValue?: DefaultValue,
  refreshTick?: number,
};

const useSelectCustomer = (
  handleChoseValue: (selectedValue: string) => void,
  settings: UseSelectCustomerSettings,
) => {
  const { withId, defaultValue, refreshTick = 0 } = settings;
  const { currentOrganization } = organizationStore;
  const [options, setOptions] = useState<SelectSearchOption[]>([]);
  const previousQuery = useRef<string>('');

  const { get } = useApiRequest();

  const getOptions = useCallback(async (query: string): Promise<SelectSearchOption[]> => {
    if ((!query || query === previousQuery.current) && refreshTick === 0) {
      return options;
    }
    previousQuery.current = query;

    try {
      const result = await fetchAllClients(
        'selectClientOptions',
        currentOrganization?.reference,
        query,
      );
      if (!result) {
        return [];
      }

      const { data } = result;
      const optionsData = data?.map(({ id, name: entryName, reference }) => ({
        name: entryName,
        value: withId ? `${id}` : reference,
      }));

      setOptions(optionsData);
      return optionsData;
    } catch {
      return [];
    }
  }, [currentOrganization, options, withId, refreshTick]);

  const findDefaultOption = useCallback(async () => {
    if (!defaultValue) {
      return;
    }

    const tempOptions = await getOptions(defaultValue.toString());
    if (tempOptions.length > 0) {
      handleChoseValue(tempOptions[0].value.toString());
      return;
    }

    setOptions([{ name: `${defaultValue}`, value: `${defaultValue}` }]);
  }, [defaultValue, getOptions, handleChoseValue]);

  const getDefaultOption = useCallback(
    async () => {
      if (!defaultValue || !currentOrganization) {
        return;
      }

      const url = `client/${currentOrganization.reference}/id/${defaultValue}`;
      const result = await get<Misc.IdRefName>(url);
      if (result?.name) {
        setOptions([{ name: result.name, value: withId ? result.id : result.reference }]);
        handleChoseValue(withId ? `${result.id}` : result.reference);
      }
    },
    [currentOrganization, defaultValue, get, handleChoseValue, withId],
  );

  return {
    getOptions,
    options,
    getDefaultOption,
    findDefaultOption,
  };
};

export default useSelectCustomer;
