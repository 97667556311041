import { useEffect, useMemo, StrictMode } from 'react';
import { Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { observer } from 'mobx-react';
import { ModalProvider } from 'react-modal-hook';
import history from 'utils/history';
import authStore from 'stores/Auth';
import currenciesStore from 'stores/Currencies';
import 'stores/Organization';
import TooltipProvider from 'components/Tooltip';
import Layout from './layout';
import AppRoutes, { PUBLIC_ROUTES } from './AppRoutes';

const queryClient = new QueryClient();

const App = (): JSX.Element => {
  const { pathname } = window.location;

  const isPublicRoute = useMemo(() => (
    PUBLIC_ROUTES.find((route) => pathname.startsWith(route)) !== undefined
  ), [pathname]);

  useEffect(() => {
    if (!authStore.isFetched && !isPublicRoute) {
      authStore.fetchUser();
    }

    if (!currenciesStore.isFetched && !isPublicRoute) {
      currenciesStore.getAll();
    }
  }, [isPublicRoute]);

  return (
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <StrictMode>
          <TooltipProvider>
            <ModalProvider>
              <Layout>
                <AppRoutes />
              </Layout>
            </ModalProvider>
          </TooltipProvider>
        </StrictMode>
      </Router>
    </QueryClientProvider>
  );
};

export default observer(App);
