import type { TFunction } from 'i18next';
import type Misc from 'types/misc';

const CustomersFilters = (
  t: TFunction,
  filters: Misc.Filter[],
): Misc.FilterDeclarationItem[] => [
  {
    title: t('clients:filters.manager'),
    emptyText: t('clients:filters.all-managers'),
    key: 'users',
    type: 'selectAsync',
    fetchEntity: 'users',
  },
  {
    title: t('clients:filters.dunning-plans'),
    key: 'dunningPlan',
    type: 'selectScenario',
    emptyText: t('clients:filters.all-dunning-plans'),
  },
  {
    title: t('clients:filters.min-total-debt'),
    key: 'amountDueFrom',
    type: 'numberInput',
    defaultValue: filters.find(({ name }) => name === 'amountDueFrom')?.value ?? undefined,
    withCurrencyAddon: true,
  },
  {
    title: t('clients:filters.max-total-debt'),
    key: 'amountDueTo',
    type: 'numberInput',
    defaultValue: filters.find(({ name }) => name === 'amountDueTo')?.value ?? undefined,
    withCurrencyAddon: true,
  },
  {
    title: t('clients:filters.attribute'),
    emptyText: t('common:please-choose'),
    key: 'attribute',
    type: 'selectAsync',
    fetchEntity: 'eavs/client',
  },
  {
    title: t('clients:filters.attribute-value'),
    key: 'attributeValue',
    type: 'textInput',
    defaultValue: filters.find(({ name }) => name === 'attributeValue')?.value ?? undefined,
  },
  {
    title: t('clients:filters.notes'),
    type: 'select',
    key: 'note',
    selectData: [
      { value: '1', label: t('clients:filters.with-notes') },
      { value: '0', label: t('clients:filters.without-notes') },
    ],
    emptyText: t('common:please-choose'),
    defaultValue: filters.find(({ name }) => name === 'note')?.value ?? undefined,
  },
  {
    title: t('clients:filters.muted'),
    type: 'select',
    key: 'mute',
    selectData: [
      { value: '1', label: t('clients:filters.is-muted') },
      { value: '0', label: t('clients:filters.not-muted') },
    ],
    emptyText: t('common:please-choose'),
    defaultValue: filters.find(({ name }) => name === 'mute')?.value ?? undefined,
  },
  {
    title: t('common:searching'),
    key: 'search',
    type: 'custom',
    isHidden: true,
  },
];

export default CustomersFilters;
