import './index.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Config from 'config';
import type Misc from 'types/misc';
import type { ClientStats } from 'types/models';
import organizationStore from 'stores/Organization';
import formatIntlNumber from 'utils/formatIntlNumber';
import StatBlock from 'components/StatBlock';
import useTooltip from 'components/Tooltip/useTooltip';
import ListStatsByPhase from 'components/ListStatsByPhase';

interface Props {
  data: ClientStats,
}

const CustomerAnalysisStats = ({ data }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { currency } = organizationStore;

  const monthlyValues = useMemo(() => (
    data.totalCollectedByMonth.map((entry) => entry.amount || 0)
  ), [data]);

  const monthlyLabels = useMemo(() => (
    data.totalCollectedByMonth.map(({ month }: Misc.MonthStat) => {
      const monthNumber = Number.parseInt(month.substring(5), 10);
      return t(`months:short.${monthNumber}`);
    })
  ), [data, t]);

  const tooltipMore30DaysLate = useTooltip(
    <ListStatsByPhase title={t('home:more-than-30-days-late.tooltip-title')} data={data.detailsMore30DaysLate} />,
    'statBlock',
  );

  const tooltipLess30DaysLate = useTooltip(
    <ListStatsByPhase title={t('home:less-than-30-days-late.tooltip-title')} data={data.detailsByPhaseDue} />,
    'statBlock',
  );

  const tooltipTotalFuture = useTooltip(
    <ListStatsByPhase title={t('home:total-future')} data={data.detailsByPhaseNotDue} />,
    'statBlock',
  );

  return (
    <div className="CustomerAnalysisStats">
      <div className="CustomerAnalysisStats__group">
        <StatBlock
          title={t('home:more-than-30-days-late.title')}
          ref={tooltipMore30DaysLate}
          colors={[
            Config.PHASES_COLORS.OVERDUE,
            Config.PHASES_COLORS.LATE,
            Config.PHASES_COLORS.DEFAULT,
          ]}
        >
          <h3 className="CustomerAnalysisStats__value">
            {formatIntlNumber(data.detailsMore30DaysLate.total, currency)}
          </h3>
        </StatBlock>
        <StatBlock
          ref={tooltipLess30DaysLate}
          title={t('home:less-than-30-days-late.title')}
          colors={[Config.PHASES_COLORS.DUE]}
        >
          <h3 className="CustomerAnalysisStats__value">
            {formatIntlNumber(data.detailsByPhaseDue.total, currency)}
          </h3>
        </StatBlock>
        <StatBlock
          ref={tooltipTotalFuture}
          title={t('home:total-future')}
          colors={[Config.PHASES_COLORS.PENDING, Config.PHASES_COLORS.UPCOMING]}
        >
          <h3 className="CustomerAnalysisStats__value">
            {formatIntlNumber(data.detailsByPhaseNotDue.total, currency)}
          </h3>
        </StatBlock>
      </div>
      <div className="CustomerAnalysisStats__group">
        <StatBlock
          title={t('home:total-recovered')}
          className="CustomerAnalysisStats__recovered"
          chartData={monthlyValues}
          chartLabels={monthlyLabels}
        >
          <h3 className="CustomerAnalysisStats__value">
            {formatIntlNumber(data.totalCollected, currency)}
          </h3>
        </StatBlock>
        <StatBlock
          title={t('home:average-payment-time')}
        >
          <h3 className="CustomerAnalysisStats__value">
            {data.averageDelayOfPayment > 0 ? t('common:days-count', { count: data.averageDelayOfPayment }) : '-'}
          </h3>
        </StatBlock>
      </div>
    </div>
  );
};

export default CustomerAnalysisStats;
