import React from 'react';
import { observer } from 'mobx-react';
import type Misc from 'types/misc';
import type Errors from 'types/errors';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import useContextualTranslation from 'hooks/useContextualTranslation';
import ErrorMessage from 'components/ErrorMessage';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import organizationStore from 'stores/Organization';
import Confirm from 'components/Confirm';
import EditPlanForm from './Form';

interface Props {
  onDone: (message: string) => void,
  onClose: () => void,
  plan: string,
  payrequestId: number,
  payrequestReference: string,
  groupId: number | null,
}

const EditPlan: React.FC<Props> = (props) => {
  const { currentOrganization, linesOfBusiness } = organizationStore;
  const { ct, t } = useContextualTranslation(linesOfBusiness);

  const {
    onDone,
    onClose,
    plan,
    payrequestReference,
    payrequestId,
    groupId,
  } = props;

  const isMountedRef = useIsMountedRef();

  const [hasChanges, setHasChanges] = React.useState<boolean>(false);
  const [validationErrors, setValidationErrors] = React.useState<Errors.Validation | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = React.useState<boolean>(false);

  const { put, isLoading, error } = useApiRequest();

  const closeSelf = React.useCallback(() => {
    if (!isMountedRef.current) {
      return;
    }

    setShowCancelConfirm(false);
    setHasChanges(false);
    setValidationErrors(null);
    onClose();
  }, [isMountedRef, onClose]);

  const handleCancel = React.useCallback(
    () => (hasChanges ? setShowCancelConfirm(true) : closeSelf()),
    [hasChanges, closeSelf],
  );

  const mapFormData = React.useCallback(
    (rawData: ModalFormData): { newPlan: string } => ({
      newPlan: rawData.plan as string,
    }),
    [],
  );

  const handleSubmit = React.useCallback(
    async (formData: ModalFormData | null) => {
      if (!formData || !currentOrganization) {
        return;
      }

      const data = mapFormData(formData);
      const result = await put<Misc.IdName>((
        groupId ? `group/manage/${groupId}/switch-plan` : `pay-requests/manage/${payrequestId}/switch-plan`
      ), data);

      if (!isMountedRef.current || !result) {
        return;
      }
      if (result?.errors) {
        setValidationErrors(result.errors);
        return;
      }

      closeSelf();
      onDone(t('payment-requests:dunning-plan-toast.edited', { name: result?.name }));
    },
    [
      currentOrganization,
      mapFormData,
      groupId,
      isMountedRef,
      closeSelf,
      onDone,
      t,
      put,
      payrequestId,
    ],
  );

  return (
    <ModalForm
      isOpened
      title={ct(
        groupId ? 'payment-requests:dunning-plan-edit-title-group' : 'payment-requests:dunning-plan-edit-title',
        { bill: payrequestReference },
      )}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
    >
      {error && (<ErrorMessage error={error} />)}
      <EditPlanForm
        isGroup={!!groupId}
        defaultData={plan}
        errors={validationErrors}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={closeSelf}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(EditPlan);
