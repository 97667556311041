import './index.scss';
import { Fragment, useMemo } from 'react';
import { observer } from 'mobx-react';
import type { HistoryStep, HistoryStepSMS, HistoryStepLetter, HistoryStepEmail } from 'types/models';
import getHistoryStepType from 'utils/getHistoryStepType';
import getI18nChannel from 'utils/getI18nChannel';
import organizationStore from 'stores/Organization';
import useContextualTranslation from 'hooks/useContextualTranslation';
import Button from 'components/Button';
import HistoryStepDetailsItem from './Item';
import HistoryStepDetailsContacts from './Contacts';
import HistoryStepDetailsPayRequest from './PayRequest';
import HistoryStepPayRequestsGroup from './PayRequestsGroup';
import HistoryStepDetailsHistory from './History';

type Props = {
  data: HistoryStep | HistoryStepSMS | HistoryStepLetter | HistoryStepEmail,
  onShowEdit(): void,
  onShowReminder(): void,
};

const HistoryStepDetails = ({ data, onShowEdit, onShowReminder }: Props): JSX.Element => {
  const { linesOfBusiness } = organizationStore;
  const { t, ct } = useContextualTranslation(linesOfBusiness);

  const type = useMemo(() => getHistoryStepType(data), [data]);

  const {
    description,
    channel,
    group,
    payRequest,
    content,
    isEditable,
    subject,
    doneAt,
    contacts,
    missingContactRoles,
  } = data;

  const hasGroupPayRequests = !!group?.payRequests && group.payRequests.length > 0;

  return (
    <div className="HistoryStepDetails">
      <div className="HistoryStepDetails__infos">
        <HistoryStepDetailsItem label={t('reminders:description')}>
          {description}
        </HistoryStepDetailsItem>
        {(type === 'reminder' && !!channel) && (
          <HistoryStepDetailsItem label={t('reminders:channel')}>
            {t(getI18nChannel(channel))}
          </HistoryStepDetailsItem>
        )}
        {hasGroupPayRequests && (
          <HistoryStepDetailsItem label={ct('reminders:bill', { count: group.payRequests!.length })}>
            <HistoryStepPayRequestsGroup data={group.payRequests!} />
          </HistoryStepDetailsItem>
        )}
        {(!hasGroupPayRequests && !!payRequest) && (
          <HistoryStepDetailsItem label={ct('reminders:bill', { count: 1 })}>
            <HistoryStepDetailsPayRequest data={payRequest} />
          </HistoryStepDetailsItem>
        )}
      </div>
      {(type === 'task' && isEditable) && (
        <div className="HistoryStepDetails__actions">
          <Button
            variant="light"
            small
            title={t('common:edit')}
            onClick={onShowEdit}
          >
            {t('common:edit')}
          </Button>
        </div>
      )}
      {type === 'reminder' && (
        <Fragment>
          <div className="HistoryStepDetails__infos">
            {subject && (
              <HistoryStepDetailsItem label={t('reminders:subject')}>
                {subject}
              </HistoryStepDetailsItem>
            )}
            {content && (
              <Fragment>
                <HistoryStepDetailsItem label={t('reminders:content')}>
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                </HistoryStepDetailsItem>
                <div className="HistoryStepDetails__actions">
                  {isEditable && (
                    <Button
                      variant="light"
                      small
                      title={t('common:edit')}
                      onClick={onShowEdit}
                    >
                      {t('common:edit')}
                    </Button>
                  )}
                  {(!!contacts && !!contacts[0]) && (
                    <Button variant="light" small onClick={onShowReminder}>
                      {t('common:preview')}
                    </Button>
                  )}
                </div>
              </Fragment>
            )}
          </div>
          <div className="HistoryStepDetails__infos">
            <HistoryStepDetailsItem
              label={doneAt ? t('reminders:sent-to') : t('reminders:recipients')}
            >
              <HistoryStepDetailsContacts
                contacts={contacts}
                channel={channel}
                missingRoles={missingContactRoles}
                type={type}
                action={doneAt ? 'sent-to' : 'recipients'}
              />
            </HistoryStepDetailsItem>
            <HistoryStepDetailsItem label={t('reminders:missing-roles')}>
              <HistoryStepDetailsContacts
                contacts={contacts}
                missingRoles={missingContactRoles}
                channel={channel}
                type={type}
                action="missing-roles"
              />
            </HistoryStepDetailsItem>
          </div>
        </Fragment>
      )}
      <div className="HistoryStepDetails__history">
        <HistoryStepDetailsItem label={t('common:history')}>
          <HistoryStepDetailsHistory data={data} />
        </HistoryStepDetailsItem>
      </div>
    </div>
  );
};

export default observer(HistoryStepDetails);
