import './index.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import authStore from 'stores/Auth';
import Alert from 'components/Alert';
import Button from 'components/Button';
import Icon from 'components/Icon';
import MiddleNavButtons from './MiddleNavButtons';

interface Props {
  stepNumber: number,
  onNext: () => void,
  onPrevious: () => void,
  onNavigateTo: (step: number) => void,
  hasSelectedType: boolean,
  hasSelectedFiles: boolean,
  type: 'pdf' | 'csv-xlsx',
}

const ImportsFileWizardNav: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { isDemo } = authStore;

  const {
    stepNumber,
    hasSelectedType,
    hasSelectedFiles,
    onNext,
    onPrevious,
    onNavigateTo,
    type,
  } = props;

  const nextButtonLabel = React.useMemo(() => {
    if (stepNumber === 1) {
      return t('imports:wizard.nav.next');
    }
    return (stepNumber === 2) ? t('imports:wizard.nav.start-import') : t('imports:wizard.nav.finish');
  }, [stepNumber, t]);

  return (
    <div className="ImportsFileWizardNav">
      <div className="ImportsFileWizardNav__button-prev">
        {stepNumber === 2 && (
          <Button
            variant="outline-light"
            className="ImportsFileWizardNav__button-prev__link"
            onClick={onPrevious}
          >
            <Icon name="chevron-left" />
            {t('imports:wizard.nav.back')}
          </Button>
        )}
      </div>
      <MiddleNavButtons
        type={type}
        step={stepNumber}
        onNavigateTo={onNavigateTo}
        hasSelectedFiles={hasSelectedFiles}
        hasSelectedType={hasSelectedType}
      />
      {isDemo && (
        <Alert variant="warning">
          {t('common:demo.warning')} {t('common:demo.you-cannot-perform-this-action')}
        </Alert>
      )}
      <div className="ImportsFileWizardNav__button-next">
        <Button
          variant="primary"
          className="ImportsFileWizardNav__button-next__link"
          onClick={onNext}
          disabled={isDemo
            || (stepNumber === 1 && !hasSelectedType)
            || (stepNumber === 2 && !hasSelectedFiles)}
        >
          {nextButtonLabel}
          <Icon name="chevron-right" />
        </Button>
      </div>
    </div>
  );
};

export default ImportsFileWizardNav;
