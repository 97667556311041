import queryString from 'query-string';
import type Misc from 'types/misc';
import type { PayRequest, PayRequestForList } from 'types/models';
import Request from 'utils/request';

export type FetchAllParams = {
  organization: string | undefined,
  customerReference?: string,
  filtering: Misc.Filter[],
  fetchOptions: Misc.PaginatedFetchArgs<PayRequestForList>,
};

export type FetchAllForCustomerParams = {
  organization: string | undefined,
  customerReference: string,
  options: Misc.PaginatedFetchArgs<PayRequest>,
};

export type FetchOneParams = {
  organization: string | undefined,
  reference: string,
  withEav?: boolean,
};

export type FetchCountForStatusParams = {
  organization: string | undefined,
  categories?: string[],
  status: Misc.PayRequestStatus,
};

const fetchOne = async ({ organization, reference, withEav }: FetchOneParams) => {
  if (!organization) {
    throw new Error('FetchOnePayRequest: Missing organization.');
  }

  const url = `pay-requests/${organization}/${reference}?withEav=${withEav ? '1' : '0'}`;
  const result = await Request.get<PayRequest>(url);
  return result;
};

const fetchAll = async ({ organization, fetchOptions, filtering }: FetchAllParams) => {
  if (!organization) {
    throw new Error('FetchAllPayRequest: Missing organization.');
  }

  const { pageIndex, pageSize, sort } = fetchOptions;

  const queryData = new URLSearchParams();
  queryData.append('recordsPerPage', pageSize.toString());
  queryData.append('page', (pageIndex + 1).toString());

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }) => {
      queryData.append(
        `filters[${name}]`,
        (Array.isArray(value) ? value.join(',') : value) || '',
      );
    });
  }

  if (sort) {
    const key = sort.id === 'client' ? 'clientName' : sort.id;
    const direction = sort.desc ? 'desc' : 'asc';
    queryData.append('orderBy', key);
    queryData.append('orderDirection', direction);
  }

  const fetchStatus = filtering?.find(({ name }) => name === 'status')?.value || 'IN_PROGRESS';

  const result = await Request.get<Misc.Listing<PayRequestForList>>(
    `pay-requests/${organization}/listing/${fetchStatus}?${queryData.toString()}`,
  );
  return result;
};

const fetchAllForCustomer = async ({
  organization,
  options,
  customerReference,
}: FetchAllForCustomerParams) => {
  if (!organization) {
    throw new Error('FetchAllForCustomerPayRequest: Missing organization.');
  }

  if (!customerReference) {
    throw new Error('FetchAllForCustomerPayRequest: Missing customer reference.');
  }

  const { pageIndex, pageSize, sort } = options;

  const query = queryString.stringify({
    recordsPerPage: pageSize,
    page: pageIndex,
    orderBy: sort?.id === 'client' ? 'clientName' : sort?.id,
    orderDirection: sort?.desc ? 'desc' : 'asc',
    'filters[lite]': 0,
    'filters[status]': 'IN_PROGRESS',
  });

  const result = await Request.get<Misc.Listing<PayRequest>>(
    `client/${organization}/${customerReference}/pay-requests?${query}`,
  );
  return result;
};

const fetchCountByStatus = async ({ organization, categories, status }: FetchCountForStatusParams) => {
  if (!organization) {
    throw new Error('FetchCountByStatusPayRequest: Missing organization.');
  }

  const queryData = new URLSearchParams();
  if (categories && categories.length > 0) {
    queryData.append('filters[categories]', categories.join(','));
  }

  const result = await Request.get<Misc.CountRecords>(
    `pay-requests/${organization}/count/${status}?${queryData.toString()}`,
  );
  return result;
};

export {
  fetchOne,
  fetchAll,
  fetchCountByStatus,
  fetchAllForCustomer,
};
