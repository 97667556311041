import './index.scss';
import { useCallback, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import equal from 'deep-equal';
import type Errors from 'types/errors';
import type { HistoryStep, ReminderPut } from 'types/models';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import organizationStore from 'stores/Organization';
import Confirm from 'components/Confirm';
import ReminderForm from './Form';

type Props = {
  reminder: HistoryStep,
  onDone: (title: string, message: string) => void,
  onClose(): void,
};

const ReminderEdit = ({ reminder, onDone, onClose }: Props): JSX.Element => {
  const { t } = useTranslation();
  const isMountedRef = useIsMountedRef();
  const { currentOrganization } = organizationStore;
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Errors.Validation | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);
  const { put, isLoading, error } = useApiRequest();

  const roleDefaultValue = useMemo<string[]>(() => {
    const initialContactRoles = reminder.contacts?.map(({ contactRole }) => contactRole) || [];

    if (!reminder.missingContactRoles) {
      return initialContactRoles;
    }

    const initialContactMissingRoles = reminder.missingContactRoles?.map((role) => role) || [];

    return [...initialContactRoles, ...initialContactMissingRoles];
  }, [reminder]);

  const initialData = useRef<ReminderPut>({
    startAt: reminder.at.at.substring(0, 10),
    name: reminder.name,
    description: reminder.description,
    subject: reminder.subject || '',
    content: reminder.content || '',
    roles: roleDefaultValue.map((role) => ({ identifier: role })),
    needValidation: reminder.isActive,
    type: 'reminder',
  });

  const mapFormData = useCallback((rawData: ModalFormData): ReminderPut | null => {
    if (!currentOrganization) {
      return null;
    }

    const data: ReminderPut = {
      startAt: rawData.startAt as string,
      name: rawData.name as string,
      description: rawData.description as string,
      subject: reminder.channel === 'EMAIL' ? rawData.subject as string : '',
      content: rawData.content as string,
      roles: (rawData.roles as string).split(',').map((role) => ({ identifier: role })),
      needValidation: rawData.needValidation === '1',
      type: 'reminder',
    };

    if (rawData.withMove === 'move') {
      data.scenario = 'move';
    }

    return data;
  }, [currentOrganization, reminder]);

  const closeSelf = useCallback(() => {
    if (!isMountedRef.current) {
      return;
    }

    setShowCancelConfirm(false);
    setHasChanges(false);
    setValidationErrors(null);
    onClose();
  }, [isMountedRef, onClose]);

  const handleCancel = useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirm(true);
    } else {
      closeSelf();
    }
  }, [hasChanges, closeSelf]);

  const handleChange = useCallback((formData: ModalFormData | null) => {
    const newData = formData ? mapFormData(formData) : null;
    setHasChanges(!equal(initialData.current, newData));
  }, [initialData, mapFormData]);

  const handleSubmit = useCallback(async (formData: ModalFormData | null) => {
    if (!formData || !currentOrganization) {
      return;
    }

    const data = mapFormData(formData);
    if (!data) {
      return;
    }

    const result = await put<HistoryStep>(`task/manage/${reminder.id}/edit`, data);

    if (!isMountedRef.current) {
      return;
    }

    if (result?.errors) {
      setValidationErrors(result.errors);
      return;
    }

    if (!result?.id) {
      return;
    }

    closeSelf();
    const { name } = result;
    onDone(
      t('reminders:actions.toast.edited'),
      t('reminders:actions.toast.edited-name', { name }),
    );
  }, [currentOrganization, put, mapFormData, isMountedRef, onDone, t, closeSelf, reminder]);

  return (
    <ModalForm
      isOpened
      className="ReminderEdit"
      title={t('reminders:actions.edit')}
      subTitle={reminder.name ?? ''}
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
    >
      {error && (<ErrorMessage error={error} />)}
      <ReminderForm
        defaultData={reminder}
        errors={validationErrors}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={closeSelf}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(ReminderEdit);
