import PublicLayout from 'layout/PublicLayout';
import React from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import Loading from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';
import { fetchOne, FetchOneParams } from 'api/sendToken';
import useFetch from 'hooks/useFetch';

export interface RouteParams {
  token: string,
}

const ConfirmedEmail: React.FC = () => {
  const { token } = useParams<RouteParams>();
  const history = useHistory();

  const { data, error, isLoading } = useFetch<FetchOneParams, {}>(
    {
      cacheKey: 'sendToken',
      token,
    },
    fetchOne,
  );

  React.useEffect(() => {
    if (data && !error) {
      history.push('/login', { confirmedEmail: true });
    } else if (!isLoading) {
      history.push('/login', { error: true });
    }
  }, [data, error, history, isLoading]);

  return (
    <PublicLayout>
      {error && <ErrorMessage error={error} />}
      <Loading />
    </PublicLayout>
  );
};

export default observer(ConfirmedEmail);
