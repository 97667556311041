import './index.scss';
import { Fragment, useCallback, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import organizationStore from 'stores/Organization';
import authStore from 'stores/Auth';
import getUploadImageUrl from 'utils/getUploadImageUrl';
import getI18nTypeOrganization from 'utils/getI18nTypeOrganization';
import getI18nLineOfBusiness from 'utils/getI18nLineOfBusiness';
import useApiRequest from 'hooks/useApiRequest';
import Loading from 'components/Loading';
import FormControl from 'components/FormControl';
import Button from 'components/Button';
import ErrorMessage from 'components/ErrorMessage';
import { UserRightsController, UserRightsSwitch } from 'components/UserRights';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import FormSwitch from 'components/FormSwitch';
import SettingsList from '../../List';
import SEPAMandateAcceptance from './SepaMandateAcceptance';

type Props = {
  onShowToast(message: string, type: ToastStatus): void,
};

const SettingsCurrentOrganizationContent = ({ onShowToast }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { currentOrganization, linesOfBusiness } = organizationStore;
  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const {
    postFormData,
    put,
    error,
    resetError,
    isLoading,
  } = useApiRequest();

  const handleUploadLogo = useCallback(() => {
    if (inputFileRef?.current) {
      inputFileRef.current.click();
    }
  }, []);

  const handleLogoChange = useCallback(async (newLogo: File) => {
    if (!currentOrganization) {
      return;
    }

    const formDataFile = new FormData();
    formDataFile.append('logo', newLogo);

    const response = await postFormData(
      `organization/manage/${currentOrganization.reference}/logo`,
      formDataFile,
    );

    if (!response) {
      onShowToast(t('users:toast.error.settings-update'), 'error');
      return;
    }

    organizationStore.refresh();
    authStore.fetchUser();
    onShowToast(t('users:toast.success.settings-update'), 'success');
  }, [currentOrganization, postFormData, onShowToast, t]);

  const listData = useMemo(() => {
    if (!currentOrganization) {
      return [];
    }

    const { name, legalId, currency, type, address, city, country, postalCode } = currentOrganization;
    return [
      {
        label: t('organizations:corporate-name'),
        value: name || '-',
      },
      {
        label: t('organizations:legal-id'),
        value: legalId || '-',
      },
      {
        label: t('organizations:currency'),
        value: currency || '-',
      },
      {
        label: t('organizations:type'),
        value: t(getI18nTypeOrganization(type)) || '-',
      },
      {
        label: t('organizations:line-of-business'),
        value: getI18nLineOfBusiness(t, linesOfBusiness),
      },
      {
        label: t('organizations:postal-address'),
        value: <Fragment>{address}<br />{postalCode} {city} <br />{country}</Fragment> || '-',
      },
    ];
  }, [t, currentOrganization, linesOfBusiness]);

  const handleToggleOrgaInProd = useCallback(async (newIsInProduction: boolean) => {
    if (!currentOrganization) {
      return;
    }

    const response = await put(
      `organization/manage/${currentOrganization.reference}/production/${newIsInProduction ? '1' : '0'}`,
    );

    if (response?.errors) {
      onShowToast(t('users:toast.error.settings-update'), 'error');
      return;
    }

    organizationStore.refresh();
    authStore.fetchUser();
    onShowToast(t('users:toast.success.settings-update'), 'success');
  }, [currentOrganization, onShowToast, put, t]);

  if (!currentOrganization) {
    return (
      <div className="SettingsCurrentOrganizationContent">
        <div className="SettingsCurrentOrganizationContent__empty">
          {t('organizations:select-organization')}
        </div>
      </div>
    );
  }

  const { name, logo } = currentOrganization;

  return (
    <div className="SettingsCurrentOrganizationContent">
      {error && <ErrorMessage error={error} onClose={resetError} />}
      <div className="SettingsCurrentOrganizationContent__header">
        <Button
          variant="outline"
          onClick={handleUploadLogo}
          className="SettingsCurrentOrganizationContent__header__logo"
        >
          <div className="SettingsCurrentOrganizationContent__header__logo__text">
            {t('common:edit')}
          </div>
          <FormControl
            ref={inputFileRef}
            onFileChange={handleLogoChange}
            type="file"
            name="logo"
            className="SettingsCurrentOrganizationContent__header__logo__form"
          />
          {isLoading && (<Loading hasNoText />)}
          {!isLoading && logo && (
            <img
              className="SettingsCurrentOrganizationContent__header__logo__image"
              src={getUploadImageUrl(logo)}
              alt={name}
            />
          )}
          {!isLoading && !logo && (
            <div className="SettingsCurrentOrganizationContent__header__logo__initials">
              {name[0]} {name[1]}
            </div>
          )}
        </Button>
        <h3 className="SettingsCurrentOrganizationContent__header__name">
          {name}
        </h3>
      </div>
      <SettingsList data={listData} />
      <div className="SettingsCurrentOrganizationContent__in-production">
        <dt className="SettingsCurrentOrganizationContent__in-production__label">
          {t('organizations:in-production')}
        </dt>
        <dd className="SettingsCurrentOrganizationContent__in-production__content">
          <UserRightsSwitch>
            <UserRightsController action="UPDATE" category="SETTINGS_ORGANIZATION_MODE_PRODUCTION">
              <FormSwitch
                name="isInProduction"
                value={currentOrganization.isInProduction}
                onChange={handleToggleOrgaInProd}
              />
            </UserRightsController>
            <UserRightsController
              action="UPDATE"
              category="SETTINGS_ORGANIZATION_MODE_PRODUCTION"
              displayedWhenNotAllowed
            >
              {currentOrganization.isInProduction ? t<string>('common:yes') : t<string>('common:no')}
            </UserRightsController>
          </UserRightsSwitch>
        </dd>
      </div>
      {/* TODO: Utiliser nouveau champ "is_owner" pour ne pas afficher le bouton */}
      <SEPAMandateAcceptance />
    </div>
  );
};

export default observer(SettingsCurrentOrganizationContent);
