import './index.scss';
import classnames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { PayRequestOrPayment } from 'types/models';
import formatIntlNumber from 'utils/formatIntlNumber';
import organizationStore from 'stores/Organization';
import useTooltip from 'components/Tooltip/useTooltip';

type Props = {
  data: PayRequestOrPayment,
  withoutTooltip?: boolean,
  useAmountNotAssigned?: boolean,
};

const CustomerAccountDetailsAmountCell = ({ data, withoutTooltip, useAmountNotAssigned }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { entity, status, amount, amountNotAssigned, currency } = data;
  const isFailedPayment = entity === 'payment' && status === 'FAILED';
  const isPositivePayment = entity === 'payment' && amount > 0;
  const isNegativePayment = entity === 'payment' && amount <= 0;
  const isRejectUnpaid = status === 'FAILED' && amountNotAssigned > 0;
  const type = entity === 'payment' ? data.type : '';
  const isRefund = type === 'REFUND';

  const displayedAmount = useMemo(() => {
    const formattedAmount = formatIntlNumber(
      Math.abs(amount),
      currency || organizationStore.currency,
    );

    if (isRefund) {
      return `+${formattedAmount}`;
    }

    const formattedNotAssigned = formatIntlNumber(
      amountNotAssigned,
      currency || organizationStore.currency,
    );

    if (entity === 'payment') {
      if (useAmountNotAssigned) {
        return `-${formattedNotAssigned}`;
      }

      if (!isFailedPayment) {
        return `-${formattedAmount}`;
      }
    }

    if (useAmountNotAssigned) {
      return formattedNotAssigned;
    }

    return formattedAmount;
  }, [useAmountNotAssigned, amount, currency, isRefund, entity, isFailedPayment, amountNotAssigned]);

  const className = classnames('CustomerAccountDetailsAmountCell', {
    'CustomerAccountDetailsAmountCell--payment-error': isFailedPayment,
    'CustomerAccountDetailsAmountCell--reject-unpaid': isRejectUnpaid,
    'CustomerAccountDetailsAmountCell--payment-positive': isPositivePayment,
    'CustomerAccountDetailsAmountCell--payment-negative': isNegativePayment || isRefund,
  });

  const tooltip = useTooltip(
    <div className="CustomerAccountDetailsAmountCell__tooltip">
      <div className="CustomerAccountDetailsAmountCell__tooltip__title">
        {t('common:remaining-balance')}
      </div>
      <div className="CustomerAccountDetailsAmountCell__tooltip__amount">
        {formatIntlNumber(amountNotAssigned, currency || organizationStore.currency)}
      </div>
    </div>,
    'center',
    entity !== 'payRequest' || amountNotAssigned === 0,
  );

  return (
    <div className={className} ref={withoutTooltip ? undefined : tooltip}>
      {displayedAmount}
    </div>
  );
};

export default CustomerAccountDetailsAmountCell;
