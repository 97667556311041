import './index.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import type { PayRequest } from 'types/models';
import formatIntlDate from 'utils/formatIntlDate';
import organizationStore from 'stores/Organization';

type Props = {
  data: PayRequest,
};

const PaymentRequestInfos = ({ data }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { eavs } = organizationStore;

  const payRequestEavs = useMemo(() => (
    data.eav?.map((eav) => ({
      ...eav,
      label: eavs.payRequest.find(
        ({ identifier }) => identifier === eav.identifier,
      )?.label || eav.identifier,
    }))
  ), [data, eavs]);

  return (
    <dl className="PaymentRequestSideInfos">
      <div className="PaymentRequestSideInfos__item">
        <dt className="PaymentRequestSideInfos__label">
          {t('common:managers')}
        </dt>
        <dd className="PaymentRequestSideInfos__value">
          {data.user && data.user.length > 0
            ? data.user.map(({ id, name }) => <div key={id}>{name}</div>)
            : '-'}
        </dd>
      </div>
      <div className="PaymentRequestSideInfos__item">
        <dt className="PaymentRequestSideInfos__label">
          {t('payment-requests:issue-date')}
        </dt>
        <dd className="PaymentRequestSideInfos__value">
          {formatIntlDate(data.issueAt, 'dateLong')}
        </dd>
      </div>
      {payRequestEavs && payRequestEavs.map((eav) => (
        <div className="PaymentRequestSideInfos__item" key={eav.identifier}>
          <dt className="PaymentRequestSideInfos__label">
            {eav.label}
          </dt>
          <dd className="PaymentRequestSideInfos__value">
            {eav.value || '-'}
          </dd>
        </div>
      ))}
    </dl>
  );
};

export default observer(PaymentRequestInfos);
