import './index.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { HistoryStep } from 'types/models';
import dateIsToday from 'utils/dateIsToday';
import dateIsLastTwoDays from 'utils/dateIsLastTwoDays';
import formatIntlDate from 'utils/formatIntlDate';

type Props = {
  data: HistoryStep,
};

const CustomerLastActivitiesItem = ({ data }: Props): JSX.Element => {
  const { entity, name, description, payRequest } = data;
  const { t } = useTranslation();

  const date = useMemo<string>(() => {
    const { doneAt, remove, validation, at } = data;
    const displayDate = doneAt ?? remove?.at ?? validation?.at ?? at.at;

    if (dateIsToday(displayDate)) {
      return t('common:today-shortened');
    }
    if (dateIsLastTwoDays(displayDate)) {
      return t('common:yesterday-shortened');
    }
    return formatIntlDate(displayDate, 'dayMonthShort');
  }, [data, t]);

  return (
    <li className="CustomerLastActivitiesItem">
      <div className="CustomerLastActivitiesItem__date">
        {date}
      </div>
      <div className="CustomerLastActivitiesItem__content">
        <div className="CustomerLastActivitiesItem__content__title">
          {entity === 'FreeTask' ? name : description}
        </div>
        <div className="CustomerLastActivitiesItem__content__data">
          {payRequest && (
            <Link
              to={`/payment-request/${payRequest.id}`}
              className="CustomerLastActivitiesItem__content__link"
            >
              {payRequest.reference}
            </Link>
          )}
        </div>
      </div>
    </li>
  );
};

export default CustomerLastActivitiesItem;
