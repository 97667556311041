import './index.scss';
import classnames from 'classnames';
import { Fragment, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import type { HistoryStep } from 'types/models';
import getHistoryStepType from 'utils/getHistoryStepType';
import dateIsLastTwoDays from 'utils/dateIsLastTwoDays';
import formatIntlNumber from 'utils/formatIntlNumber';
import getI18nChannel from 'utils/getI18nChannel';
import organizationStore from 'stores/Organization';
import ScenarioDetails from 'components/ScenarioDetails/index';
import Badge from 'components/Badge';
import ClientName from 'components/ClientName';
import Button from 'components/Button';
import PaymentViewModal from 'components/PaymentViewModal';
import PaymentEditModal from 'components/PaymentEditModal';

type Props = {
  data: HistoryStep,
  showCustomer: boolean,
  onActionDone(message: string): void,
  onActionError?(message: string): void,
};

const HistoryStepInfosHeader = (props: Props): JSX.Element => {
  const { data, showCustomer, onActionDone } = props;
  const { name, entity, at, group, customer, channel, scenario, payRequest, payment } = data;
  const { t } = useTranslation();
  const { currency } = organizationStore;
  const isRecent = useMemo(() => dateIsLastTwoDays(at.at), [at]);
  const type = useMemo(() => getHistoryStepType(data), [data]);

  const amountFormated = useMemo(() => (
    (payment && !Array.isArray(payment))
      ? formatIntlNumber(parseFloat(payment.amount), currency)
      : null
  ), [payment, currency]);

  const isBill = useMemo(() => [
    'PayRequestCreationMilestone',
    'PayRequestDeleteAlert',
    'DownloadInvoiceAlert',
  ].includes(entity), [entity]);

  const isPayment = useMemo(() => [
    'PaymentAssignedAlert',
  ].includes(entity), [entity]);

  const displayPayRequests = useMemo<'payRequest' | 'group' | 'none'>(() => {
    if (!group && payRequest) {
      return 'payRequest';
    }
    if ((group?.payRequests && group.payRequests.length > 0) && type !== 'alert') {
      return 'group';
    }
    return 'none';
  }, [type, group, payRequest]);

  const [showScenarioDetails, hideScenarioDetails] = useModal(() => (
    scenario ? (
      <ScenarioDetails
        scenario={scenario}
        onClose={hideScenarioDetails}
      />
    ) : null
  ), [scenario]);

  const [showPaymentEditModal, hidePaymentEditModal] = useModal(() => (
    payment ? (
      <PaymentEditModal
        onClose={hidePaymentEditModal}
        onDone={() => {
          hidePaymentEditModal();
          onActionDone(
            t('payments:toast.modified-reference', { reference: payment.reference }),
          );
        }}
        reference={payment.reference}
      />
    ) : null
  ));

  const [showPaymentViewModal, hidePaymentViewModal] = useModal(() => (
    payment ? (
      <PaymentViewModal
        onClose={hidePaymentViewModal}
        onClickEdit={() => {
          hidePaymentViewModal();
          showPaymentEditModal();
        }}
        reference={payment.reference}
      />
    ) : null
  ));

  const hasBadge = useMemo(() => (
    ['reminder', 'note', 'task'].includes(type)
    || isBill
    || isPayment
  ), [type, isBill, isPayment]);

  const detailsClassNames = classnames('HistoryStepInfosHeader__details', {
    'HistoryStepInfosHeader__details--note': type === 'note',
  });

  return (
    <div className="HistoryStepInfosHeader">
      <div className="HistoryStepInfosHeader__titles">
        <span className="HistoryStepInfosHeader__titles__text">
          {name}
        </span>
        {scenario && (
          <Button
            variant="link"
            className="HistoryStepInfosHeader__titles__scenario"
            onClick={showScenarioDetails}
          >
            {scenario.name}
          </Button>
        )}
      </div>
      <div className={detailsClassNames}>
        {hasBadge && (
          <Badge variant="NOTE" isRecent={isRecent}>
            {(type === 'reminder' && channel) && t(getI18nChannel(channel))}
            {type === 'note' && t('common:note')}
            {type === 'task' && t('common:task')}
            {isBill && t('common:debit')}
            {isPayment && t('common:credit')}
          </Badge>
        )}
        {(!hasBadge && isRecent) && (
          <Badge variant="NOTE" isRecent>{t('common:recent')}</Badge>
        )}
        {(!showCustomer && displayPayRequests === 'group' && !!group) && (
          <span className="HistoryStepInfosHeader__details__dunning-group">
            {t('common:group')}
          </span>
        )}
        {(!showCustomer && displayPayRequests === 'payRequest' && !!payRequest) && (
          <Link
            to={`/payment-request/${payRequest.id}`}
            className="HistoryStepInfosHeader__details__pay-request"
          >
            {payRequest.reference}
          </Link>
        )}
        {showCustomer && (
          <Fragment>
            <Link
              to={`/customer/${customer.id}`}
              className="HistoryStepInfosHeader__details__link"
            >
              <ClientName client={customer} showReference />,
            </Link>
            <span className="HistoryStepInfosHeader__details__total-amount">
              {formatIntlNumber(customer.totalAmount, currency)}
            </span>
            <span className="HistoryStepInfosHeader__details__total-amount-due">
              {formatIntlNumber(customer.totalAmountDue, currency)}
            </span>
          </Fragment>
        )}
        {!!payment && !Array.isArray(payment) && (
          <Button
            variant='link'
            className="HistoryStepInfosHeader__details__link"
            onClick={showPaymentViewModal}
          >
            {t('payments:payment-assigned')} {amountFormated} {t('common:via')} {payment.reference}
          </Button>
        )}
        {type === 'note' && (
          <p className="HistoryStepInfosHeader__details__note-content">
            {data.content}
          </p>
        )}
      </div>
    </div>
  );
};

export default HistoryStepInfosHeader;
