import queryString from 'query-string';
import type Misc from 'types/misc';
import type { PaymentForList, Payment } from 'types/models';
import Request from 'utils/request';

export type FetchAllParams = {
  organization: string | undefined,
  filtering: Misc.Filter[],
  fetchOptions: Misc.PaginatedFetchArgs<PaymentForList>,
};

export type FetchOneParams = {
  organization: string | undefined,
  reference: string,
};

const fetchAll = async (params: FetchAllParams) => {
  const { organization, filtering, fetchOptions } = params;
  if (!organization) {
    throw new Error('FetchAllPayments: Missing organization.');
  }

  const { pageIndex, pageSize, sort } = fetchOptions;

  const queryData: Record<string, string | number> = {
    recordsPerPage: pageSize,
    page: pageIndex + 1,
  };

  if (filtering && filtering.length > 0) {
    filtering.forEach(({ name, value }) => {
      if (value) {
        queryData[`filters[${name}]`] = Array.isArray(value) ? value.join(',') : value;
      }
    });
  }

  if (sort) {
    queryData.orderBy = sort.id;
    queryData.orderDirection = sort.desc ? 'desc' : 'asc';
  }

  const query = queryString.stringify(queryData);

  const result = await Request.get<Misc.Listing<PaymentForList>>(
    `payments/${organization}/listing?${query}`,
  );
  return result;
};

const fetchOne = async ({ organization, reference }: FetchOneParams) => {
  if (!organization) {
    throw new Error('FetchOnePayment: Missing organization.');
  }

  if (!reference) {
    throw new Error('FetchOnePayment: No reference provided');
  }

  const result = await Request.get<Payment>(`payments/${organization}/${reference}`);
  return result;
};

export {
  fetchAll,
  fetchOne,
};
